<template>
  <div>
    <ProcessingCreate/>
  </div>
</template>

<script>
import ProcessingCreate from "@/components/production_process/processing_raw_materials/ProcessingCreate";
export default {
  name: "Processing_create",
  components: {
    ProcessingCreate
  }
}
</script>

<style scoped>

</style>