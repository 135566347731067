import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    unitList: (page, size, search) => {
        return HTTP.get(`/unit/unitlist?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    addUnit: (data) => {
        return HTTP.post('/unit/addunit', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    editUnit: (id, data) => {
        return HTTP.post('/unit/editunit/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    unitDetail: (id) => {
        return HTTP.get('/unit/detailunit/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    deleteUnit: (id) => {
        return HTTP.post('/unit/deleteunit/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    list: () => {
        return HTTP.get('/unit/list',{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
}