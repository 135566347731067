<template>
  <div>
    <ProductionConfirmEdit/>
  </div>
</template>

<script>
import ProductionConfirmEdit from "@/components/production_process/processing_raw_materials/ProductionConfirmEdit";
export default {
  name: "Production_confirm_edit",
  components: {ProductionConfirmEdit}
}
</script>

<style scoped>

</style>