<template>
    <div>
        <SetRawMaterialEditPage />
    </div>
</template>
  
<script>
import SetRawMaterialEditPage from '@/components/stock/setting_stock/SetRawMaterialEdit'
export default {
    name: 'SetRawMaterialEdit',
    components: {
        SetRawMaterialEditPage,
    }
}
</script>