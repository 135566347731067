<template>
    <div>
      <ProcessingPackaging/>
    </div>
  </template>
  
  <script>
  import ProcessingPackaging from "@/components/production_process/procressing_packaging/ProcressingPackaging";
  export default {
    name: "Processing_packaging",
    components: {
      ProcessingPackaging
    }
  }
  </script>
  
  <style scoped>
  
  </style>