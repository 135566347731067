<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ตั้งค่าหน่วยนับ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" v-if="$store.state.profileDetail.per_unit == 1 || $store.state.profileDetail.permission == 1">
                <v-card elevation="4" class="card-radius">
                    <v-card-title class="justify-space-between">
                        <b>{{ unit_detail.id ? 'แก้ไขหน่วยนับ' : 'เพิ่มหน่วยนับ' }}</b>
                        <div class="text-name-title" v-if="unit_detail.id">
                            ผู้แก้ไขล่าสุด : <span class="text-fullname-title">{{ unit_detail.user_edit }}</span>
                            <span class="text-date-title"> วันที่ :</span> 
                            <span class="text-datetime-title">
                                {{ `${(unit_detail.updated_at.split('/')[0]).split(' ')[0]}-${unit_detail.updated_at.split('/')[1]}-${unit_detail.updated_at.split('/')[2]}` }}
                            </span>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form_add_or_edit" @submit.prevent="unitAddOrEditSuccess()">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="36">
                                        <v-icon dark>
                                            mdi-database
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">ข้อมูลหน่วยนับ</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <v-form-group>
                                                <v-text-field type="text" class="input-radius" label="ชื่อหน่วยนับ"
                                                    v-model="unit_detail.name" color="#FFBC15"
                                                    :rules="[v => !!v || 'กรุณากรอกชื่อหน่วยนับ']" hide-details="auto"
                                                     dense outlined rounded required>
                                                </v-text-field>
                                            </v-form-group>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการหน่วยนับ</b>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field color="#D9D9D9" class="input-radius-search mt-3" prepend-inner-icon="mdi-magnify"
                            @keyup="searchList()" v-model="search" placeholder="ค้นหาที่นี่..." hide-details="auto"
                            single-line outlined dense>
                        </v-text-field>
                        <div class="table-header-unit-data display-pc mt-3" style="color: #b1adad;">
                            <div class="table-header-unit-data-text"></div>
                            <div class="table-header-unit-data-text">
                                ชื่อหน่วยนับ
                            </div>
                            <div class="table-header-unit-data-text text-center">
                                จัดการ
                            </div>
                        </div>

                        <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in data" :key="index">
                            <div class="grid-unit-items">
                                <div class="w-border"></div>
                                <div>{{ detail.unit_name }}</div>
                                <div>
                                    <v-menu rounded="b-xl" offset-y>
                                        <template v-slot:activator="{ attrs, on }" v-if="$store.state.profileDetail.per_unit == 1 || $store.state.profileDetail.permission == 1">
                                            <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:activator="{ on, on_arr }" v-else>
                                            <v-btn elevation="0" color="#D9D9D9" v-bind="on" v-on="on_arr" fab small>
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list v-if="$store.state.profileDetail.per_unit == 1 || $store.state.profileDetail.permission == 1">
                                            <v-list-item link @click="unitDetail(detail.id)">
                                                <v-list-item-title class="f-14" style="color:#FEA06C">
                                                    <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                    แก้ไขข้อมูล
                                                </v-list-item-title>
                                            </v-list-item>
                                            <!-- <v-list-item link @click="confirmDeleteUnit(detail.id)">
                                                <v-list-item-title class="f-14" style="color:#F28080" @click="unit_delete">
                                                    <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                    ลบ
                                                </v-list-item-title>
                                            </v-list-item> -->
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                        </v-card>

                        <div class="text-center mt-5">
                            <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                color="#FFBC15"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- ลบข้อมูล -->
        <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="deleteUnitSuccess()">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:14px;" text @click="confirm_delete_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->
    </div>
</template>
<script>
import { onlyForNumber } from "@/helper/handler";
import unit from '@/api/warehouse/unit.js'
import navigationmenu from '@/components/menu/VerticalMenu.vue';
export default {
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลอื่น ๆ',
                    disabled: false,
                },
                {
                    text: 'หน่วยนับ',
                    disabled: false,
                    href: '#',
                },
            ],
            page: 1,
            pageSize: 1,
            confirm_delete_dialog: false,
            drawer: true,
            data: [],
            unit_detail: {
                id: null,
                name: '',
                user_edit: null,
                updated_at: null,
            },
            unit_delete_id: null,
            items: [
                { title: 'Home', icon: 'mdi-home-city' },
                { title: 'My Account', icon: 'mdi-account' },
                { title: 'Users', icon: 'mdi-account-group-outline' },
            ],
            mini: true,
            search: '',
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        unit_delete() {
            this.confirm_delete_dialog = true;
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
        async unitList() {
            this.data = []
            await unit.unitList(this.page, '', this.search).then((response) => {
                if (response.data.successful === true) {
                    this.loading_page = false
                    this.data = response.data.unitList
                    this.pageSize = response.data.pages
                    this.page = parseInt(response.data.currentPage)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async unitDetail(id) {
            const unitDetail = this.data.find((detail) => { return detail.id == id })
            this.unit_detail = {
                id: unitDetail.id,
                name: unitDetail.unit_name,
                user_edit: unitDetail.user_edit ? unitDetail.user_edit : '-',
                updated_at: unitDetail.user_edit ? unitDetail.updated_at : '-',
            }
        },
        async confirmDeleteUnit(id) {
            this.confirm_delete_dialog = true
            this.unit_delete_id = id
        },
        async deleteUnitSuccess() {
            this.loading_page = true
            unit.deleteUnit(this.unit_delete_id).then((response) => {
                if (response.data.successful === true) {
                    this.confirm_delete_dialog = false
                    this.unit_detail = {
                        id: null,
                        name: ''
                    }
                    this.unit_delete_id = null
                    this.alert_message_success = 'ลบหน่วยนับ เรียบร้อยแล้ว'
                    this.loading_page = false
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                    this.unitList()
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async unitAddOrEditSuccess() {
            if (this.$refs.form_add_or_edit.validate()) {
                this.loading_page = true
                if (!this.unit_detail.id) {
                    const data = {
                        unit_name: this.unit_detail.name,
                    }
                    unit.addUnit(data).then((response) => {
                        if (response.data.successful === true) {
                            this.unit_detail = {
                                id: null,
                                name: '',
                                user_edit: null,
                                updated_at: null,
                            }
                            this.alert_message_success = 'เพิ่มหน่วยนับ เรียบร้อยแล้ว'
                            this.loading_page = false
                            this.alert_success = true
                            setTimeout(() => this.alert_success = false, 5000)
                            this.unitList()
                        }
                        if (response.data.successful === false) {
                            if (response.data.message == 'This unit name is already taken') {
                                this.alert_message_error = 'ชื่อหน่วยนับนี้มีอยู่ในระบบแล้ว'
                                this.alert_error = true
                                setTimeout(() => this.alert_error = false, 5000)
                                this.loading_page = false
                            }
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$store.commit('logOutSuccess')
                            this.$router.push('/login')
                        }
                    })
                }
                if (this.unit_detail.id) {
                    const data = {
                        unit_name: this.unit_detail.name,
                    }
                    unit.editUnit(this.unit_detail.id, data).then((response) => {
                        if (response.data.successful === true) {
                            this.unit_detail = {
                                id: null,
                                name: '',
                                user_edit: null,
                                updated_at: null,
                            }
                            this.alert_message_success = 'แก้ไขหน่วยนับ เรียบร้อยแล้ว'
                            this.alert_success = true
                            setTimeout(() => this.alert_success = false, 5000)
                            this.unitList()
                        }
                        if (response.data.successful === false) {
                            if (response.data.message == 'This unit name is already taken') {
                                this.alert_message_error = 'ชื่อหน่วยนับนี้มีอยู่ในระบบแล้ว'
                                this.alert_error = true
                                setTimeout(() => this.alert_error = false, 5000)
                                this.loading_page = false
                            }
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$store.commit('logOutSuccess')
                            this.$router.push('/login')
                        }
                    })
                }
            }
        },
        async searchList() {
            this.data = []
            this.page = 1
            await unit.unitList(this.page, '', this.search).then((response) => {
                this.data = response.data.unitList
                this.pageSize = response.data.pages
                 this.page = parseInt(response.data.currentPage)
            })
        }
    },
    components: {
        navigationmenu
    },
    mounted() {
        this.unitList()
    },
    watch: {
        page() {
            this.unitList()
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.table-header-unit-data {
    display: grid;
    grid-template-columns: 1% 60% 17%;
    padding: 5px 0px 5px 30px;
}

.table-header-unit-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-unit-items {
    display: grid;
    grid-template-columns: 9% 60% 20%;
    align-items: center;
}
</style>