<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">เพิ่มสินค้า</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>

    <v-stepper v-model="stepImport" style="padding: 30px">
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <p class="team-text-title-card">ขั้นตอนการเพิ่มข้อมูล</p>
          <div class="d-flex justify-space-between">
            <div class="text-center">
              <v-btn color="#FFBC15" fab x-small outlined dark style="font-size: 14px" :complete="stepImport > 1"
                step="1">
                1
              </v-btn>
              <p class="my-2" style="font-size: 16px; font-weight: 600; color: #ffbc15">
                ข้อมูลสินค้า
              </p>
            </div>
            <div class="text-center">
              <v-btn :color="color_step2" fab x-small outlined dark style="font-size: 14px" :complete="stepImport > 2"
                step="2">
                2
              </v-btn>
              <p class="my-2" :style="'font-size:16px;font-weight:600;color:' + color_step2">
                ข้อมูลขนาดสินค้าและบรรจุภัณฑ์
              </p>
            </div>
            <div class="text-center">
              <v-btn :color="color_step3" fab x-small outlined dark style="font-size: 14px" :complete="stepImport > 3"
                step="3">
                3
              </v-btn>
              <p class="my-2" :color="color_step3" :style="'font-size:16px;font-weight:600;color:' + color_step3">
                ข้อมูลการผลิต
              </p>
            </div>
            <div class="text-center">
              <v-btn :color="color_step4" fab x-small outlined dark style="font-size: 14px" :complete="stepImport > 4"
                step="4">
                4
              </v-btn>
              <p class="my-2" :color="color_step4" :style="'font-size:16px;font-weight:600;color:' + color_step4">
                เกณฑ์การตรวจสอบ
              </p>
            </div>
          </div>
          <v-progress-linear style="background-color: #d9d9d9" :color="color_step2" rounded height="9"
            :value="process_step">
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-stepper-items>
        <v-row>
          <!-- ข้อมูลสินค้า -->
          <v-stepper-content step="1" class="pl-2 pr-2">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-package-variant-closed </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">ข้อมูลสินค้า</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row>
                    <v-col cols="12" md="12" lg="12">
                      <span class="ml-3 text-font-title">รูปภาพสินค้า</span>
                    </v-col>

                    <v-col cols="12" md="5" lg="5">
                      <v-row>
                        <v-col cols="12">
                          <div class="image_preview text-center">
                            <v-avatar class="mt-2" style="border-radius: 10%" size="210">
                              <img v-if="imagePreviewURL" :src="imagePreviewURL" />
                              <div class="upload_image">
                                <!-- <v-btn v-if="!profile_picture" class="white--text" color="#FD8F16" depressed fab :loading="isSelectingImage" @click="handleFileImport"><v-icon>mdi-pencil</v-icon></v-btn> -->

                                <v-file-input class="d-none" id="uploader" accept="image/*"
                                  v-model="step_one_profile_picture" @change="onFileChange"></v-file-input>
                              </div>
                            </v-avatar>
                            <v-file-input class="" id="uploader" accept="image/*" color="#ffbc15"
                              placeholder="เลือกรูปภาพสินค้า" v-model="step_one_profile_picture"
                              @change="onFileChange"></v-file-input>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="7" lg="7">
                      <v-row>
                        <v-col cols="12">
                          <v-form-group>
                            <v-text-field type="text" class="input-radius" label="รหัสผลิตภัณฑ์"
                              placeholder="รหัสผลิตภัณฑ์" color="#ffbc15" v-model="step_one.one_product_code"
                              :rules="[(v) => !!v || 'กรุณากรอกรหัสผลิตภัณฑ์']" hide-details="auto" dense outlined rounded
                              required>
                            </v-text-field>
                          </v-form-group>
                        </v-col>

                        <v-col cols="12">
                          <v-form-group>
                            <v-text-field type="text" class="input-radius" label="ชื่อสินค้า" placeholder="ชื่อสินค้า"
                              color="#ffbc15" v-model="step_one.one_product_name"
                              :rules="[(v) => !!v || 'กรุณากรอกชื่อสินค้า']" hide-details="auto" dense outlined rounded
                              required>
                            </v-text-field>
                          </v-form-group>
                        </v-col>

                        <v-col cols="12" lg="7" md="7" sm="7">
                          <v-form-group>
                            <v-autocomplete :items="product_type_data" color="#ffbc15" item-text="pro_type_name" item-value="id"
                              v-model="step_one.one_type_product" label="ประเภทสินค้า"
                              :rules="[(v) => !!v || 'กรุณาเลือกประเภทสินค้า']" hide-details="auto" dense outlined rounded
                              required></v-autocomplete>
                          </v-form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <div class="mt-5 div-submit-data">
                <v-btn outlined type="button" class="button-submit-data" @click="colorstep(2)" :disabled="!isStep1Valid"
                  style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
              </div>
            </v-col>
          </v-stepper-content>
          <!-- ข้อมูลสินค้า -->

          <!-- ข้อมูลขนาดสินค้าและบรรจุภัณฑ์ -->
          <v-stepper-content step="2" class="pl-2 pr-2">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-form ref="step_2" @input="submitStep2()">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-arrow-expand </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">ข้อมูลขนาดสินค้า</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row class="px-10">
                    <v-col cols="12" md="6" lg="6">
                      <v-form-group>
                        <v-autocomplete :items="product_size_data" color="#FFBC15" item-text="pro_size_name" item-value="id"
                          v-model="step_two_size_list" label="ขนาดสินค้า" @input="add_product_size(step_two_size_list)"
                          hide-details="auto" dense outlined rounded required>
                        </v-autocomplete>
                      </v-form-group>
                    </v-col>

                    <v-col cols="12" md="6" lg="6"></v-col>

                    <!-- loop ขนาดสินค้า -->
                    <v-col cols="12" v-for="(step_two_loop, index) in step_two" :key="index">
                      <v-card class="radius-20">
                        <v-card-title class="">
                          <v-row>
                            <v-col cols="6" lg="8">
                              <v-avatar color="#34230F" size="32">
                                <v-icon dark>
                                  mdi-archive-search-outline
                                </v-icon>
                              </v-avatar>
                              <span class="ml-3 text-font-title">ขนาดสินค้า
                                {{ step_two_loop.step_two_pro_size_name }}</span>
                            </v-col>
                            <v-col class="text-right" cols="6" lg="4">
                              <div class="error--text" style="cursor: pointer" @click="
                                setproductsize_delete(step_two_loop.loop_id)
                                ">
                                <v-icon dark color="error" size="28">
                                  mdi-delete-forever
                                </v-icon>
                                <span class="ml-3 f-14">ลบขนาดสินค้า</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <v-divider class="mb-3" color="#686868"></v-divider>

                          <v-row class="pl-10">
                            <v-col cols="12" md="6" lg="6">
                              <v-form-group>
                                <label for="">ราคาขาย</label>
                                <v-text-field type="text" class="input-radius mt-4" label="ราคาขาย" placeholder="ราคาขาย"
                                  color="#FFBC15" v-model="step_two_loop.step_two_sell_price"
                                  :rules="[(v) => !!v || 'กรุณากรอก ราคาขาย']" hide-details="auto" dense outlined rounded
                                  required>
                                </v-text-field>
                              </v-form-group>
                            </v-col>
                            <v-col cols="12" md="6" lg="6"></v-col>

                            <v-col cols="12" md="6" lg="6">
                              <v-form-group>
                                <label for="">การผูกสูตรบรรจุภัณฑ์</label>
                                <v-autocomplete :items="package_formula_data" class="mt-4" item-text="mat_name" item-value="id"
                                  color="#FFBC15" v-model="step_two_sub_size_list"
                                  label="เลือกวัตถุดิบในการผูกสูตรบรรจุภัณฑ์" @input="
                                    add_product_sub_packaging(
                                      index,
                                      step_two_sub_size_list
                                    )
                                    " hide-details="auto" dense outlined rounded required></v-autocomplete>
                              </v-form-group>
                            </v-col>
                            <v-col cols="12" md="6" lg="6"></v-col>
                          </v-row>

                          <v-row class="px-15">
                            <v-col cols="6">วัตถุดิบ </v-col>
                            <v-col cols="6">จำนวนที่ตัดวัตถุดิบต่อ 1 หน่วยสินค้า
                            </v-col>
                          </v-row>

                          <v-row class="px-15" v-for="(
                              step_two_sub_loop, index_sub
                            ) in step_two_loop.step_two_list" :key="index_sub">
                            <v-col cols="6">{{
                              step_two_sub_loop.step_two_sub_name
                            }}</v-col>
                            <v-col cols="6">
                              <v-form-group class="flex">
                                <v-text-field type="text" class="input-radius mr-2"
                                  label="จำนวนที่ตัดวัตถุดิบต่อ 1 หน่วยสินค้า"
                                  placeholder="จำนวนที่ตัดวัตถุดิบต่อ 1 หน่วยสินค้า" color="#FFBC15" v-model="step_two_sub_loop.two_sub_raw_mat_qty
                                      " :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกจำนวน']" hide-details="auto" dense outlined
                                  rounded required>
                                </v-text-field>
                                <v-btn type="button" @click="
                                  setproducts_mat_delete(
                                    index,
                                    step_two_sub_loop.loop_sub_id
                                  )
                                  " outlined color="#F28080" elevation="1" class="radius-10">
                                  <v-icon dark color="error" size="28">
                                    mdi-delete-forever
                                  </v-icon>
                                </v-btn>
                              </v-form-group>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <!-- loop ขนาดสินค้า -->
                  </v-row>
                </v-card-text>
              </v-card>

              <div class="d-flex justify-center">
                <div class="mt-5 div-submit-data mr-1">
                  <v-btn outlined type="button" class="button-submit-data" @click="stepImport = 1, process_step = 0, color_step2 = '#7D7D7D'"
                    style="color: #ffbc15 !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-left</v-icon> ย้อนกลับ
                  </v-btn>
                </div>
                <div class="mt-5 div-submit-data">
                  <v-btn v-if="step_two.length < 1 || step_two.find(o => o.step_two_list.length === 0)" outlined class="button-submit-data"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large disabled>
                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                  </v-btn>
                  <v-btn v-else type="button" outlined class="button-submit-data" @click="colorstep(3)"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                  </v-btn>
                </div>
              </div>
              </v-form>
            </v-col>
          </v-stepper-content>
          <!-- ข้อมูลขนาดสินค้าและบรรจุภัณฑ์ -->

          <!-- ข้อมูลการผลิต -->
          <v-stepper-content step="3" class="pl-2 pr-2">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
             <v-form ref="step_3" @input="submitStep3()">
               <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-cog </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">ข้อมูลสูตรการผลิต</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row class="px-10">
                    <v-col cols="12" md="6" lg="6">
                      <v-form-group>
                        <label for="">ปริมาณที่ได้จากสูตรการผลิต</label>
                        <v-text-field type="text" class="input-radius mt-4" label="ปริมาณที่ได้จากสูตรการผลิต"
                          placeholder="ปริมาณที่ได้จากสูตรการผลิต" color="#FFBC15" :rules="[
                            (v) =>
                              (!!v && parseFloat(v) > 0) || 'กรุณากรอก ปริมาณที่ได้จากสูตรการผลิต',
                          ]" hide-details="auto" dense outlined rounded v-model="pro_formula" required>
                        </v-text-field>
                      </v-form-group>
                    </v-col>

                    <v-col cols="12" md="6" lg="6"></v-col>
                    <!-- loop ขนาดสินค้า -->
                    <v-col cols="12" v-for="(step_three_loop, index) in step_three" :key="index">
                      <v-card class="radius-20">
                        <v-card-title class="">
                          <v-row>
                            <v-col cols="6" lg="8">
                              <v-avatar color="#34230F" size="32">
                                <v-icon dark> mdi-cog-sync </v-icon>
                              </v-avatar>
                              <span class="ml-3 text-font-title">การผลิตส่วนที่ {{ index + 1 }}</span>
                            </v-col>
                            <v-col class="text-right" cols="6" lg="4">
                              <div class="error--text" style="cursor: pointer" @click="
                                confirmDeleteProductionCheck(
                                  step_three_loop.no_ppl_id
                                )
                                " v-if="index >= 1">
                                <v-icon dark color="error" size="28">
                                  mdi-delete-forever
                                </v-icon>
                                <span class="ml-3 f-14">ลบรายการตรวจสอบการผลิต</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <v-divider class="mb-3" color="#686868"></v-divider>

                          <v-row class="pl-10">
                            <v-col cols="12" md="12" lg="12">
                              <v-form-group>
                                <v-autocomplete :items="raw_material_product_List" class="mt-4" @input="
                                  add_raw_material_list(
                                    index,
                                    step_three_raw_material_data
                                  )
                                  " item-text="mat_name" item-value="id"  color="#FFBC15" v-model="step_three_raw_material_data"  :menu-props="{ top: true, offsetY: true }"
                                  label="เลือกวัตถุดิบในการผลิต" hide-details="auto" dense outlined rounded></v-autocomplete>
                              </v-form-group>
                            </v-col>
                          </v-row>

                          <v-row class="px-15">
                            <v-col cols="6">วัตถุดิบ</v-col>
                            <v-col cols="6">จำนวนวัตถุดิบในสูตร</v-col>
                          </v-row>

                          <v-row class="px-15" v-for="(
                              step_three_sub_loop, index_sub
                            ) in step_three_loop.step_three_list" :key="index_sub">
                            <v-col cols="6">{{
                              step_three_sub_loop.step_three_sub_name
                            }}</v-col>
                            <v-col cols="6">
                              <v-form-group class="flex">
                                <v-text-field type="text" class="input-radius mr-2" label="จำนวนวัตถุดิบในสูตร"
                                  placeholder="จำนวนวัตถุดิบในสูตร" color="#FFBC15"
                                  :rules="[(v) => (!!v && parseFloat(v) > 0) || 'กรุณากรอก ราคาขาย']" hide-details="auto" dense outlined rounded
                                  v-model="step_three_sub_loop.step_three_sub_sell_price
                                    " required>
                                </v-text-field>
                                <v-btn type="button" @click="
                                  confirmDeleteProductionMaterial(
                                    step_three_sub_loop.loop_id,
                                    index
                                  )
                                  " outlined color="#F28080" elevation="1" class="radius-10">
                                  <v-icon dark color="error" size="28">
                                    mdi-delete-forever
                                  </v-icon>
                                </v-btn>
                              </v-form-group>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <!-- loop ขนาดสินค้า -->
                  </v-row>
                </v-card-text>

                <div class="mt-3 div-submit-data">
                  <v-btn outlined type="button" class="button-submit-data" @click="plusPPL()"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                    <v-icon left>mdi-plus</v-icon> เพิ่มส่วนการแปรรูป
                  </v-btn>
                </div>
                <br />
              </v-card>
              <div class="d-flex justify-center">
                <div class="mt-5 div-submit-data mr-1">
                  <v-btn outlined type="button" class="button-submit-data" @click="stepImport = 2, process_step = 25, color_step3 = '#7D7D7D'"
                    style="color: #ffbc15 !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-left</v-icon> ย้อนกลับ
                  </v-btn>
                </div>
                <div class="mt-5 div-submit-data">
                  <v-btn v-if="step_three[0].step_three_list.length < 1 || step_three.find(item => item.step_three_list.length == 0)" outlined type="button" class="button-submit-data"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large disabled>
                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                  </v-btn>
                  <v-btn outlined v-else type="button" class="button-submit-data" @click="colorstep(4)"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                  </v-btn>
                </div>
              </div>
             </v-form>
            </v-col>
          </v-stepper-content>
          <!-- ข้อมูลการผลิต -->

          <!-- เกณฑ์การตรวจสอบ -->
          <v-stepper-content step="4" class="pl-2 pr-2">
            <!-- เกณฑ์การตรวจสอบคุณภาพ -->
           <v-form ref="step_3_list" @submit.prevent="addProduct()">
             <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-cog </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row class="px-10">
                    <v-col cols="12" md="12" lg="12">
                      <v-form-group>
                        <v-autocomplete :items="product_quality_list" :item-text="concatenatedText" item-value="id"
                          class="input-radius mt-4" label="เกณฑ์การตรวจสอบคุณภาพ" placeholder="เกณฑ์การตรวจสอบคุณภาพ"
                          color="#ffbc15" :rules="[
                            (v) => dataProductQualityList.length > 0 || 'กรุณากรอก เกณฑ์การตรวจสอบคุณภาพ',
                          ]" dense filled multiple rounded solo chips deletable-chips hide-details=""
                          @change="getProductQuality">
                        </v-autocomplete>
                      </v-form-group>
                    </v-col>

                    <!-- loop ขนาดสินค้า -->
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                      <div class="table-header-setstep-4-product-box-data display-pc" style="color: #b1adad">
                        <div class="table-header-setstep-4-product-box-data-text">
                          ชื่อเกณฑ์ (English)
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          ชื่อเกณฑ์ (ภาษาไทย)
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          Unit
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          Aim
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          Green
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          Yellow
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          Red
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          Value
                        </div>
                        <div class="table-header-setstep-4-product-box-data-text">
                          ค่าอัตโนมัติ
                        </div>
                      </div>
                    </v-col>
                    <!-- loop ขนาดสินค้า -->
                    <v-col>
                      <v-expansion-panels>
                        <v-expansion-panel v-for="(
                            quality_data, index
                          ) in dataProductQualityList" :key="index"
                          :class="{ border_left_yellow: quality_data.isActive }" class="radius-10 mt-3"
                          @click="toggleActive(index)">
                          <v-expansion-panel-header class="radius-10 border-yellow">
                            {{ quality_data.pq_title.pq_name_eng }}   {{ quality_data.pq_title.pq_name_thai }}  
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="">
                            <div class="mt-4" v-for="(
                                quality_sub_data, index
                              ) in quality_data.pq_list" :key="index">
                              <div class="table-header-setstep-4-product-box-data display-pc mt-3" style="color: #b1adad">
                                <div class="table-header-setstep-4-product-box-data-text">
                                  {{ quality_sub_data.pq_list_eng }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text">
                                  {{ quality_sub_data.pq_list_thai }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text">
                                  {{ quality_sub_data.unit }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text">
                                  {{ quality_sub_data.pq_list_aim }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text">
                                  {{ quality_sub_data.pq_list_green }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text">
                                  {{ quality_sub_data.pq_list_yellow }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text">
                                  {{ quality_sub_data.pq_list_red }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text text-center">
                                  <v-icon color="#88b358" v-if="quality_sub_data.pq_value_status === 1
                                    ">mdi-check-circle</v-icon>
                                  {{
                                    quality_sub_data.pq_value_status === 0
                                    ? "-"
                                    : ""
                                  }}
                                </div>
                                <div class="table-header-setstep-4-product-box-data-text text-center">
                                  <v-icon color="#FFBC15" v-if="quality_sub_data.pq_value_boolean === 1
                                      ">mdi-check-circle</v-icon>
                                  {{
                                    quality_sub_data.pq_value_boolean === 0
                                    ? "-"
                                    : ""
                                  }}
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- เกณฑ์การตรวจสอบการผลิต -->
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-cog </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบการผลิต</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row class="px-10">
                    <v-col cols="12" md="12" lg="12">
                      <v-form-group>
                        <v-autocomplete :items="factory_quality_list" item-text="fq_name" item-value="id"
                          class="input-radius mt-4" label="เกณฑ์การตรวจสอบการผลิต"
                          placeholder="เกณฑ์การตรวจสอบการผลิต"  color="#FFBC15" :rules="[
                            (v) =>
                              dataFactoryQualityList.length > 0 || 'กรุณากรอก เกณฑ์การตรวจสอบการผลิต',
                          ]" dense filled multiple rounded solo chips deletable-chips hide-details=""
                          @change="getFactoryQuality">
                        </v-autocomplete>
                      </v-form-group>
                    </v-col>

                    <!-- loop ขนาดสินค้า -->
                    <v-col class="mb-0 pb-0" xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                      <div class="table-header-setstep-4-product-quality-box-data display-pc" style="color: #b1adad">
                        <div class="table-header-setstep-4-product-quality-box-data-text">
                          ชื่อเกณฑ์
                        </div>
                        <div class="table-header-setstep-4-product-quality-box-data-text">
                          ระยะค่าในเกณฑ์
                        </div>
                        <div class="table-header-setstep-4-product-quality-box-data-text">
                          Value
                        </div>
                        <div class="table-header-setstep-4-product-quality-box-data-text text-center">
                          ค่าอัตโนมัติ
                        </div>
                      </div>
                    </v-col>

                    <v-col>
                      <v-expansion-panels>
                        <v-expansion-panel v-for="(
                            production_data, index
                          ) in dataFactoryQualityList" :key="index" :class="{
                            border_left_yellow: production_data.isActive,
                          }" class="radius-10 mt-3" @click="toggleActive_production(index)">
                          <v-expansion-panel-header class="radius-10 border-yellow">
                            {{ production_data.fq_title.fq_name }}
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <div class="mt-4" v-for="(
                                production_sub_data, index
                              ) in production_data.fq_list" :key="index">
                              <div class="table-header-setstep-4-product-quality-box-data display-pc mt-3"
                                style="color: #b1adad">
                                <div class="table-header-setstep-4-product-quality-box-data-text">
                                  {{ production_sub_data.fq_list_name }}
                                </div>
                                <div class="table-header-setstep-4-product-quality-box-data-text">
                                  {{ production_sub_data.fq_list_guide }}
                                </div>
                                <div class="table-header-setstep-4-product-quality-box-data-text">
                                    <span v-if="production_sub_data.fq_value_status === 1">
                                        <span v-if="production_sub_data.fq_list_type === 1">
                                            {{ production_sub_data.fq_value }}
                                        </span>
                                        <span v-else>
                                            <span v-if="production_sub_data.fq_value_boolean === 1">
                                                <v-icon color="#88b358">mdi-check-circle</v-icon>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div class="table-header-setstep-4-product-quality-box-data-text text-center">
                                    <span v-if="production_sub_data.fq_value_status === 1">
                                        <span v-if="production_sub_data.fq_value_boolean === 1">
                                            <v-icon color="#FFBC15">mdi-check-circle</v-icon>
                                        </span>
                                    </span>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>

                    <!-- loop ขนาดสินค้า -->
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <div class="mt-3 div-submit-data">
                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                  <v-icon left>mdi-content-save</v-icon> บันทึก
                </v-btn>
              </div>
            </v-col>
           </v-form>
          </v-stepper-content>
          <!-- เกณฑ์การตรวจสอบ -->
        </v-row>
      </v-stepper-items>
    </v-stepper>

    <!-- ลบข้อมูล -->
    <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
      style="border-radius: 20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <center>
                <img src="@/assets/bin.png" />
                <h2 style="color: #000000">ลบข้อมูลขนาดสินค้า</h2>
                <h3 class="mt-2">คุณต้องการลบข้อมูลขนาดสินค้าใช่หรือไม่</h3>
                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="deletePPL(get_delete_id)">
                  <v-icon style="font-size: 28px" left>mdi-delete-forever</v-icon>
                  ลบข้อมูล
                </v-btn>
              </center>
              <div class="text-center mt-3">
                <v-btn style="font-size: 14px" text @click="confirm_delete_dialog = false">
                  ยกเลิก
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ลบข้อมูล -->

    <!-- ลบข้อมูลวัตถุดิบ -->
    <v-dialog v-model="confirm_delete_mat_dialog" transition="dialog-top-transition" max-width="383"
      style="border-radius: 20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="confirm_delete_mat_dialog = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <center>
                <img src="@/assets/bin.png" />
                <!-- {{ step_two }} -->
                <h2 style="color: #000000">ลบข้อมูลวัตถุดิบ</h2>
                <h3 class="mt-2">คุณต้องการลบข้อมูลวัตถุดิบใช่หรือไม่</h3>
                <v-btn class="btn-delete-data" elevation="4" rounded x-large
                  @click="deletePPL_mat(get_delete_mat_key, get_delete_mat_id)">
                  <v-icon style="font-size: 28px" left>mdi-delete-forever</v-icon>
                  ลบข้อมูล
                </v-btn>
              </center>
              <div class="text-center mt-3">
                <v-btn style="font-size: 14px" text @click="confirm_delete_mat_dialog = false">
                  ยกเลิก
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ลบข้อมูลวัตถุดิบ -->

    <!-- ลบข้อมูลการผลิต -->
    <v-dialog v-model="confirm_delete_productionCheck" transition="dialog-top-transition" max-width="383"
      style="border-radius: 20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="confirm_delete_productionCheck = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <center>
                <img src="@/assets/bin.png" />
                <h2 style="color: #000000">ลบข้อมูลวัตถุดิบ</h2>
                <h3 class="mt-2">คุณต้องการลบข้อมูลการผลิตใช่หรือไม่</h3>
                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="deleteProductionCheck()">
                  <v-icon style="font-size: 28px" left>mdi-delete-forever</v-icon>
                  ลบข้อมูล
                </v-btn>
              </center>
              <div class="text-center mt-3">
                <v-btn style="font-size: 14px" text @click="confirm_delete_productionCheck = false">
                  ยกเลิก
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ลบข้อมูลวัตถุดิบ -->

    <!-- ลบข้อมูลการผลิต - วัตถุดิบในสูตร -->
    <v-dialog v-model="confirm_delete_productionMaterial" transition="dialog-top-transition" max-width="383"
      style="border-radius: 20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="confirm_delete_productionMaterial = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <center>
                <img src="@/assets/bin.png" />
                <h2 style="color: #000000">ลบข้อมูลวัตถุดิบ</h2>
                <h3 class="mt-2">คุณต้องการลบข้อมูลวัตถุดิบในสูตรใช่หรือไม่</h3>
                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="deleteProductioncMaterial()">
                  <v-icon style="font-size: 28px" left>mdi-delete-forever</v-icon>
                  ลบข้อมูล
                </v-btn>
              </center>
              <div class="text-center mt-3">
                <v-btn style="font-size: 14px" text @click="confirm_delete_productionMaterial = false">
                  ยกเลิก
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ลบข้อมูลวัตถุดิบ -->
  </div>
</template>
<script>
import before_image from "@/assets/groceries.png";
import { isTelephone } from "@/helper/handler";
import productType from "@/api/warehouse/productType.js";
import productSize from "@/api/warehouse/productSize.js";
import material from "@/api/warehouse/materials.js";
import qualityinspectioncriteria from "@/api/warehouse/qualityinspectioncriteria.js";
import inspectioncriteriaproduction from "@/api/warehouse/inspectioncriteriaproduction.js";
import product from "@/api/warehouse/product.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      test: [],
      loading_page: false,
      stepImport: 1,
      imagePreviewURL: before_image,
      confirm_delete_dialog: false,
      confirm_delete_mat_dialog: false,
      color_step1: "#7D7D7D",
      color_step2: "#7D7D7D",
      color_step3: "#7D7D7D",
      color_step4: "#7D7D7D",
      process_step: 0,
      no_ppl_id: 1,
      product_size_id: 0,
      product_sub_id: 0,
      raw_material_product_id: 0,
      get_delete_id: null,
      get_delete_mat_id: null,
      get_delete_mat_key: null,

      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "ตั้งค่าข้อมูลสินค้า",
          disabled: false,
          href: "/stock/setting-stock/set-product-component",
        },
        {
          text: "ตั้งสินค้า",
          disabled: false,
          href: "/stock/setting-stock/set-product-component",
        },
        {
          text: "เพิ่มสินค้า",
          disabled: false,
          href: "#",
        },
      ],

      unit: [
        { id: 1, name: "แพ็ค 5" },
        { id: 2, name: "แพ็ค 10" },
        { id: 3, name: "แพ็ค 15" },
      ],

      product_type_data: [
        {
          id: null,
          pro_type_name: "",
          color_code: "",
        },
      ],

      product_size_data: [
        {
          id: null,
          pro_amount: "",
          unit_name: "",
          fullname_pro: "",
        },
      ],

      items: ["ผงปรุงรส", "ผลิตภัณฑ์เหลว"],

      quality_list: [
        {
          name_main: "Strup coating",
          isActive: false,
          quality_sub: [
            {
              name_en: "Homogeneous",
              name_th: "ความเข้ากัน(ไม่เป็นก้อน)",
              unit: "-",
              aim: "-",
              green: "เป็นเนื้อเดียวกัน",
              yellow: "เป็นก้อน",
              red: "-",
            },
            {
              name_en: "Plain Crispy RiceWeight",
              name_th: "น้ำหนักความพองก่อนเคลือบ",
              unit: "Kg",
              aim: "-",
              green: "-",
              yellow: "-",
              red: "-",
            },
            {
              name_en: "Syrup weight",
              name_th: "น้ำหนักน้ำเคลือบ",
              unit: "Kg",
              aim: "-",
              green: "-",
              yellow: "-",
              red: "-",
            },
          ],
        },
        {
          name_main: "Drying 2",
          isActive: false,
          quality_sub: [
            {
              name_en: "Plain Crispy RiceWeight",
              name_th: "น้ำหนักความพองก่อนเคลือบ",
              unit: "Kg",
              aim: "-",
              green: "-",
              yellow: "-",
              red: "-",
            },
          ],
        },
      ],

      production_list: [
        {
          name_main_criterion: "Fryer",
          isActive: false,
          production_sub: [
            {
              name_criterion: "Time",
              range_criterion: "200-220 C",
              value_criterion: "",
            },
            {
              name_criterion: "Appearance",
              range_criterion: "ไม่ไหม้ สีสม่ำเสมอ",
              value_criterion: "ผ่าน",
            },
            {
              name_criterion: "Texture",
              range_criterion: "กรอบ ไม่แข็งเป็นไต",
              value_criterion: "ผ่าน",
            },
          ],
        },
        {
          name_main_criterion: "FG Spec",
          isActive: false,
          production_sub: [
            {
              name_criterion: "Bulk Density Fried Rice (Jasmine BR)",
              range_criterion: "0.23-0.29",
              value_criterion: "ผ่าน",
            },
          ],
        },
      ],

      // ตัวแปลข้อมูล step 1
      step_one_type_list: [],
      step_one_profile_picture: null,
      step_one: [
        {
          one_product_code: "",
          one_product_name: "",
          one_type_product: "",
        },
      ],

      // ตัวแปลข้อมูล step 2
      package_formula_data: [],
      step_two_size_list: "",

      step_two: [],
      step_two_list: [],
      step_two_sub_size_list: "",

      // ตัวแปลข้อมูล step 3
      //  production information
      pro_formula: "",
      step_three: [
        {
          no_ppl_id: 1,
          step_three_list: [],
        },
      ],
      delete_three_id: null,
      confirm_delete_productionCheck: false,
      delete_threeIndexMat: null,
      delete_threeMaterial_id: null,
      confirm_delete_productionMaterial: false,
      // step_three_list:[],
      step_three_raw_material_data: "",
      raw_material_product_List: [],

      // ตัวแปลข้อมูล step 4
      product_quality_list: [],
      factory_quality_list: [],
      dataProductQualityList: [],
      dataFactoryQualityList: [],
      step2_status: false,
      step3_status: false,
    };
  },

  methods: {
    concatenatedText(item) {
      // Concatenate the two properties you want here for each item
      return item.pq_name_eng + '  ' + item.pq_name_thai;
    },
    submitStep2(){
       if (this.$refs.step_2.validate()) {
        if(this.step_two.length != 0){
          for(let i = 0; i < this.step_two[i].step_two_list.length; i++){
            if(this.step_two[i].step_two_list.length != 0){
              this.step2_status = true;  
            }
          }  
        }
       }
    },
    submitStep3(){
       if (this.$refs.step_3.validate()) {
        if(this.step_three.length != 0){
          for(let i = 0; i < this.step_three[i].step_three_list.length; i++){
            if(this.step_three[i].step_three_list.length != 0){
              this.step3_status = true;  
            }
          }  
        }
       }
    },
    toggleActive(index) {
      let clickedButton = this.quality_list[index];
      if (!clickedButton.isActive) {
        this.quality_list.forEach((loops) => {
          loops.isActive = false;
        });
      }
      clickedButton.isActive = !clickedButton.isActive;
      if (this.quality_list.every((loops) => !loops.isActive)) {
        this.quality_list.forEach((loops) => {
          loops.isActive = false;
        });
      }
    },

    toggleActive_production(index) {
      let click_pro = this.production_list[index];
      if (!click_pro.isActive) {
        this.production_list.forEach((loopro) => {
          loopro.isActive = false;
        });
      }
      click_pro.isActive = !click_pro.isActive;
      if (this.production_list.every((loopro) => !loopro.isActive)) {
        this.production_list.forEach((loopro) => {
          loopro.isActive = false;
        });
      }
    },

    concatenateItemText(item) {
      return item.pro_amount + " " + item.unit_name;
    },

    link(link) {
      this.$router.push(link);
    },

    async plusPPL() {
      this.no_ppl_id += 1;
      this.step_three.push({
        no_ppl_id: this.no_ppl_id,
        step_three_list: [],
      });
    },

    async listProductType() {
      await productType
        .dataList_all()
        .then((response) => {
          if (response.data.successful === true) {
            this.product_type_data = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$store.commit("logOutSuccess");
            this.$router.push("/login");
          }
        });
    },

    async productSizeList() {
      await productSize
        .dataListAll()
        .then((response) => {
          (this.product_size_data = response.data.data),
            (this.product_size_data.fullname_pro =
              response.data.pdSizeList.pro_amount +
              response.data.pdSizeList.unit_name);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$store.commit("logOutSuccess");
            this.$router.push("/login");
          }
        });
    },

    async PackageList() {
      await material.list_status_two_new().then((response) => {
        this.package_formula_data = response.data.materialList;
      });
    },

    async Raw_material_product_List() {
      await material.list_status_three().then((response) => {
        this.raw_material_product_List = response.data.materialList;
      });
    },

    async product_factory_list() {
      await qualityinspectioncriteria.list_type(2).then((response) => {
        this.product_quality_list = response.data.data;
      });
      await inspectioncriteriaproduction.list_type(0).then((response) => {
        this.factory_quality_list = response.data.data;
      });
    },

    async getProductQuality(value) {
      this.dataProductQualityList = [];
      for (let index = 0; index < value.length; index++) {
        await qualityinspectioncriteria
          .qualityInspectionCriteriaDetail(value[index])
          .then((response) => {
            this.dataProductQualityList.push(response.data.detail);
          });
      }
    },

    async getFactoryQuality(value) {
      this.dataFactoryQualityList = [];
      for (let index = 0; index < value.length; index++) {
        await inspectioncriteriaproduction
          .detailInspectionCriteriaProduction(value[index])
          .then((response) => {
            this.dataFactoryQualityList.push(response.data.detail);
          });
      }
    },

    async deletePPL(delete_id) {
      this.step_two = this.step_two.filter((detail) => {
        return detail.loop_id != delete_id;
      });
      this.confirm_delete_dialog = false;
    },

    async deletePPL_mat(delete_key, delete_value) {
      let step_two_list = this.step_two[delete_key].step_two_list;
      if (Array.isArray(step_two_list)) {
        let filtered_list = step_two_list.filter((item) => {
          console.log(item.loop_sub_id);
          console.log(delete_value);
          return item.loop_sub_id != delete_value;
        });
        this.step_two[delete_key].step_two_list = filtered_list;
      }
      this.confirm_delete_mat_dialog = false;
    },

    async setproductsize_delete(id) {
      this.get_delete_id = id;
      this.confirm_delete_dialog = true;
      this.loading_detail_page = false;
    },

    async setproducts_mat_delete(keys, id) {
      this.get_delete_mat_id = id;
      this.get_delete_mat_key = keys;
      this.confirm_delete_mat_dialog = true;
      this.loading_detail_page = false;
    },

    async confirmDeleteProductionCheck(id) {
      this.delete_three_id = id;
      this.confirm_delete_productionCheck = true;
    },

    async deleteProductionCheck() {
      this.step_three = this.step_three.filter((detail) => {
        return detail.no_ppl_id != this.delete_three_id;
      });
      this.confirm_delete_productionCheck = false;
    },

    async confirmDeleteProductionMaterial(id, index) {
      this.delete_threeIndexMat = index;
      this.delete_threeMaterial_id = id;
      this.confirm_delete_productionMaterial = true;
    },

    async deleteProductioncMaterial() {
      this.step_three[this.delete_threeIndexMat].step_three_list =
        this.step_three[this.delete_threeIndexMat].step_three_list.filter(
          (detail) => {
            return detail.loop_id != this.delete_threeMaterial_id;
          }
        );
      this.confirm_delete_productionMaterial = false;
    },

    async handleFileImport() {
      this.isSelectingImage = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingImage = false;
        },
        { once: true }
      );
      document.getElementById("uploader").click();
    },

    async onFileChange(payload) {
      const file = payload;
      let extall = "png,PNG,jpeg,JPEG,jpg,JPG";
      let file_ext = file.name;
      let ext = file_ext.split(".").pop().toLowerCase();
      if (parseFloat(extall.indexOf(ext)) < 0) {
        this.imagePreviewURL = before_image;
        this.step_one_profile_picture = null;
        this.alert_message_error =
          "อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG) เท่านั้น";
        this.alert_error = true;
        setTimeout(() => (this.alert_error = false), 5000);
      } else {
        if (file) {
          this.imagePreviewURL = URL.createObjectURL(file);
          URL.revokeObjectURL(file);
        } else {
          this.imagePreviewURL = before_image;
        }
      }
    },

    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    colorstep(id) {
      if (this.stepImport == 1) {
        if (
          this.step_one.one_product_code != "" &&
          this.step_one.one_product_name != "" &&
          this.step_one.one_type_product != null
        ) {
          this.stepImport = id;
          this.process_step = 25;
          this.color_step2 = "#FFBC15";
        }
      } else if (this.stepImport == 2) {
         if(this.$refs.step_2.validate()){
          this.stepImport = id;
          this.process_step = 60;
          this.color_step3 = "#FFBC15";
         }
      } else if (this.stepImport == 3) {
        if(this.$refs.step_3.validate()){
        this.stepImport = id;
        this.process_step = 100;
        this.color_step4 = "#FFBC15";
        }
      } else {
        this.stepImport = id;
        this.process_step = 100;
        this.color_step5 = "#FFBC15";
      }
    },

    async add_product_size(pro_id) {
      let value = this.product_size_data.filter((res) => {
        return res.id == pro_id;
      });
      this.alert_success = true;
      setTimeout(() => (this.alert_success = false), 5000);
      let check = this.step_two.find(o => o.step_two_id === pro_id)
      if(check == null){
         value.forEach((details) => {
        let loop_id = (this.product_size_id += 1);
        this.step_two.push({
          test: details,
          loop_id: loop_id,
          step_two_id: details.id,
          step_two_sell_price: null,
          step_two_pro_amount: details.pro_amount,
          step_two_unit_name: details.unit_name,
          step_two_pro_size_name: details.pro_size_name,
          step_two_list: [],
        });
      });
      await this.$nextTick(() => {
        this.step_two_size_list = null;
      });
      }
    },

    async add_product_sub_packaging(step_two_index, pro_id) {
      let value = this.package_formula_data.filter((res) => {
        return res.id == pro_id;
      });

      this.alert_success = true;
      setTimeout(() => (this.alert_success = false), 5000);
      value.forEach((details) => {
        let loop_sub_id = (this.product_sub_id += 1);
        let step_two_object = this.step_two[step_two_index];
        step_two_object.step_two_list.push({
          loop_sub_id: loop_sub_id,
          step_two_sub_id: details.id,
          step_two_sub_name: details.mat_name,
          step_two__sub_sell_price: null,
        });
      });
      await this.$nextTick(() => {
        this.step_two_sub_size_list = null;
      });
    },

    async add_raw_material_list(step_three_index, pro_id) {
      let value = this.raw_material_product_List.filter((res) => {
        return res.id == pro_id;
      });

      this.alert_success = true;
      setTimeout(() => (this.alert_success = false), 5000);
      value.forEach((details) => {
        let loop_id = (this.raw_material_product_id += 1);
        let step_three_object = this.step_three[step_three_index];
        console.log(step_three_object);

        step_three_object.step_three_list.push({
          loop_id: loop_id,
          step_three_sub_id: details.id,
          step_three_sub_name: details.mat_name,
          step_three_sub_sell_price: null,
        });
      });
      await this.$nextTick(() => {
        this.step_three_raw_material_data = null;
      });
    },

    async addProduct() {
      if(this.$refs.step_3_list.validate()){
        try {
        this.loading_page = true;
        const data = new FormData();
        //step 1
        data.append("pro_code", this.step_one.one_product_code);
        data.append("pro_name", this.step_one.one_product_name);
        data.append("product_type_id", this.step_one.one_type_product);
        data.append("pro_pic", this.step_one_profile_picture);
        //step 2
        const dataStepTwo = [];
        this.step_two.forEach((detailTwo) => {
          let metarialList = [];
          detailTwo.step_two_list.forEach((detail) => {
            metarialList.push({
              materials_id: detail.step_two_sub_id,
              mat_cut_qty: parseFloat(detail.two_sub_raw_mat_qty),
            });
          });
          dataStepTwo.push({
            product_size_id: detailTwo.step_two_id,
            price: parseFloat(detailTwo.step_two_sell_price),
            metarial_list: metarialList,
          });
        });
        const product_size_list = JSON.stringify(dataStepTwo);
        data.append("product_size_list", product_size_list);
        // step 3
        const dataStepThree = [];
        this.step_three.forEach((detailThree, index) => {
          let materials_id = index + 1;
          detailThree.step_three_list.forEach((detail) => {
            dataStepThree.push({
              materials_id: parseFloat(detail.step_three_sub_id),
              part_formula: materials_id,
              materials_qty: parseFloat(detail.step_three_sub_sell_price),
            });
          });
        });
        const product_formula_list = JSON.stringify(dataStepThree);
        data.append("pro_formula", this.pro_formula);
        data.append("product_formula_list", product_formula_list);
        // step 4
        const dataStepFourProduct = [];
        this.dataProductQualityList.forEach((data) => {
          Object.entries(data.pq_title).forEach((val) => {
            if (val[0] === "id") {
              dataStepFourProduct.push({
                product_quality_id: val[1],
              });
            }
          });
        });
        const product_product_quality_list =
          JSON.stringify(dataStepFourProduct);

        const dataStepFourFactory = [];
        this.dataFactoryQualityList.forEach((data) => {
          Object.entries(data.fq_title).forEach((val) => {
            if (val[0] === "id") {
              dataStepFourFactory.push({
                factory_quality_id: val[1],
              });
            }
          });
        });
        const product_factory_quality_list =
          JSON.stringify(dataStepFourFactory);

        data.append(
          "product_product_quality_list",
          product_product_quality_list
        );
        data.append(
          "product_factory_quality_list",
          product_factory_quality_list
        );
        const response = await product.addProduct(data);
        if (response.data.successful == true) {
          this.loading_page = false;
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/stock/setting-stock/set-product-component");
            }
          });
        }
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
      }
    },
  },

  computed: {
    isStep1Valid() {
      return (
        this.step_one.one_product_code &&
        this.step_one.one_product_name &&
        this.step_one.one_type_product
      );
    },
    isStep2Valid() {
      return (
        this.step_two_size_list &&
        this.step_two_sub_size_list
      );
    },
  },


  mounted() {
    this.listProductType();
    this.productSizeList();
    this.PackageList();
    this.Raw_material_product_List();
    this.product_factory_list();
  },
  created() { },
};
</script>
<style scoped>
.input-group {
  display: flex;
  gap: 10px;
}

.form-select {
  width: 150px;
}

.form-control {
  flex: 1;
}

.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.padding-text-title {
  padding: 8px !important;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 20px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-create-data {
  display: grid;
  grid-template-columns: 4% 4% 16% 20% 20% 20% 16%;
  color: #686868 !important;
}

.table-header-create-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-create-items {
  display: grid;
  grid-template-columns: 4% 4% 17% 20% 20% 21% 14%;
  align-items: center;
}

.table-header-import-step3-data {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 5px 0px 5px 30px;
  color: #686868 !important;
}

.table-header-import-step3-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-step3-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: center;
}

.v-btn[disabled] {
  background-color: #f5f5f5;
  color: #a9a9a9;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0 6px;
}

.table-header-setstep-4-product-box-data {
  display: grid;
  grid-template-columns: 15% 15% 10% 10% 10% 10% 10% 10% 10%;
  padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-box-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.table-header-setstep-4-product-quality-box-data {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-quality-box-data-text {
  color: #888888;
  font-size: 14px;
  margin-left: 20px;
  font-weight: bold;
}

.border-yellow {
  border-bottom: 1px solid #ffbc15;
}

.border_left_yellow {
  border-left: 4px solid #ffbc15;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
  border-right: 1px solid #ffbc15;
  border-radius: 10px;
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 10px 15px !important;
}
</style>