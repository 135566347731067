<template>
    <div>
        <Warehouse_page />
    </div>
</template>
  
<script>
import Warehouse_page from '@/components/stock/warehouse_stock/WarehouseRawmaterial'
export default {
    name: 'WarehouseRawmaterial',
    components: {
        Warehouse_page,
    }
}
</script>
