<template>
  <div>
    <ProductionProductGoodResultCreate/>
  </div>
</template>

<script>
import ProductionProductGoodResultCreate from "@/components/production_process/processing_product_good/ProductionProductGoodResultCreate";
export default {
  name: "ProductionProductGoodResultCreate_Page",
  components: {ProductionProductGoodResultCreate}
}
</script>

<style scoped>

</style>