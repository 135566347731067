<template>
    <div>
        <ReportStockPage />
    </div>
</template>
  
<script>
import ReportStockPage from '@/components/stock/report_stock/ReportStock'
export default {
    name: 'Reportstockpage',
    components: {
        ReportStockPage,
    }
}
</script>