<template>
    <div>
        <div class="d-flex">
            <div class="topic_processing_header border_right">สร้างรอบการบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2">การบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
            <div class="topic_processing_content my-2">สร้างรอบการบรรจุภัณฑ์</div>
        </div>
        <div class="mt-3">
            <v-card elevation="2" class="card-radius">
                <v-card-title class="justify-space-between">
                    <b>ขั้นตอนที่ 7 : ยืนยันการผลิตสำเร็จ</b>
                    <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด :
                        <span class="text-fullname-title"> ชลิตวรรณ นาคทอง</span> <span class="text-date-title">วันที่
                            :</span>
                        <span class="text-datetime-title">21/03/2022 09:40</span>
                    </div>
                </v-card-title>
                <v-card-text>

                    <!-- ขั้นตอนที่ 1 : สร้างใบสั่งบรรจุภัณฑ์ -->
                    <v-card elevation="4" class="py-2 my-5" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/process (2).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 1 : สร้างใบสั่งบรรจุภัณฑ์</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="ml-15">
                                <div>
                                    <v-row>
                                        <v-col xl="3" lg="3">
                                            <div class="d-flex">
                                                <div class="mr-2">วันที่ใบสั่งบรรจุภัณฑ์ :</div>
                                                <div class="black--text font-weight-bold">13/02/2023</div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="">
                                        <v-col xl="12" lg="12">
                                            <b class="mb-n5 black--text">รายละเอียดการบรรจุภัณฑ์สินค้า</b>
                                        </v-col>
                                        <v-col xl="3" lg="3">
                                            <div class="tag-title-step my-3">
                                                <b class="black--text">การบันทึกเวลาในการแปรรูป</b>
                                            </div>
                                        </v-col>
                                        <v-col xl="3" lg="3">
                                            <div class="my-3">
                                                <b class="black--text">จำนวน : 150,000.00 กรัม</b>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col xl="12" lg="12">
                                            <div class="divider-table my-2"></div>
                                        </v-col>
                                        <v-col xl="12" lg="12">
                                            <div class="table-header-processing mt-4" style="color: #b1adad">
                                                <div class="table-sub-header-processing">
                                                    ลำดับ
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    รายละเอียด
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    จำนวน
                                                </div>
                                            </div>
                                            <v-card elevation="0" class="grid-processing-items ma-4"
                                                v-for="(detail, index) in firstStepList" :key="index">
                                                <div>{{ detail.no }}</div>
                                                <div>{{ detail.product }}</div>
                                                <div>{{ detail.lots }}</div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>

                    <!-- ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ -->
                    <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/flour (4).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ</span>
                        </v-card-title>
                        <v-card-text>

                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>

                            <v-row class="">
                                <v-col v-for="(loop_header, index_header) in mock_header" :key="index_header"
                                    class="card-table-radius-sub" cols="12" lg="6" xl="6">
                                    <v-card elevation="2" class="border-card">
                                        <v-row class="ma-1 go-to-card">

                                            <v-col cols="6 flex-center-header">
                                                <div class="tag-title-step-card">
                                                    <b class="black--text">{{ loop_header.header_unit }}</b>
                                                </div>
                                                <span> {{ loop_header.header_name }} </span>
                                            </v-col>
                                            <v-col cols="6 text-right flex-center-header-two">
                                                <span>จำนวน {{ loop_header.header_qty }} {{
                                                    loop_header.header_unit_name }}</span>
                                            </v-col>

                                            <v-col cols="12 py-1 col-harder">
                                                <v-divider class="border-main pt-1" dark color=""></v-divider>
                                            </v-col>

                                            <v-col cols="4 pb2">
                                                <span>วัตถุดิบ </span>
                                            </v-col>
                                            <v-col cols="4 pb-2">
                                                <span>เลข Lot </span>
                                            </v-col>
                                            <v-col cols="4 px-0 pb-2 ">
                                                <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                                            </v-col>

                                        </v-row>

                                        <v-row class="ma-1 " v-for="(loop_sub, index) in loop_header.mock_data"
                                            :key="index">
                                            <v-col cols="4 pt-0 ">
                                                <span> {{ loop_sub.data_name }}</span>
                                            </v-col>

                                            <v-col cols="8  pt-0">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <div>{{ loop_sub.data_lot_list_lot }}</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div>{{ loop_sub.data_lot_list_qty }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <!-- ขั้นตอนที่ 3 : บันทึกผลการผลิต -->
                    <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/flour (4).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 3 : บันทึกผลการผลิต</span>
                        </v-card-title>
                        <v-card-text>

                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>

                            <v-row class="">
                                <v-col v-for="(loop_header, index_header) in mock_header" :key="index_header"
                                    class="card-table-radius-sub" cols="12" lg="6" xl="6">
                                    <v-card elevation="2" class="border-card">
                                        <v-row class="ma-1 go-to-card">

                                            <v-col cols="6 flex-center-header">
                                                <div class="tag-title-step-card">
                                                    <b class="black--text">{{ loop_header.header_unit }}</b>
                                                </div>
                                                <span> {{ loop_header.header_name }} </span>
                                            </v-col>
                                            <v-col cols="6 text-right flex-center-header-two">
                                                <span>จำนวน {{ loop_header.header_qty }} {{
                                                    loop_header.header_unit_name }}</span>
                                            </v-col>

                                            <v-col cols="12 py-1 col-harder">
                                                <v-divider class="border-main pt-1" dark color=""></v-divider>
                                            </v-col>

                                            <v-col cols="4 pb2">
                                                <span>วัตถุดิบ </span>
                                            </v-col>
                                            <v-col cols="4 pb-2">
                                                <span>เลข Lot </span>
                                            </v-col>
                                            <v-col cols="4 px-0 pb-2 ">
                                                <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                                            </v-col>

                                        </v-row>

                                        <v-row class="ma-1 " v-for="(loop_sub, index) in loop_header.mock_data"
                                            :key="index">
                                            <v-col cols="4 pt-0 ">
                                                <span> {{ loop_sub.data_name }}</span>
                                            </v-col>

                                            <v-col cols="8  pt-0">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <div>{{ loop_sub.data_lot_list_lot }}</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div>{{ loop_sub.data_lot_list_qty }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <!-- ขั้นตอนที่ 4 : สินค้า FG คงเหลือและบรรจุภัณฑ์ที่เสียหาย -->
                    <v-card elevation="4" class="py-2 px-3" style="border-radius:20px;">

                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>mdi-timer-alert</v-icon>
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 4 : สินค้า FG
                                คงเหลือและบรรจุภัณฑ์ที่เสียหาย</span>
                        </v-card-title>

                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <v-row class="ma-4">
                                <v-col xl="3" lg="3">
                                    <div class="black--text font-weight-bold ma-2">
                                        จำนวนสินค้า FG ที่คงเหลือจากการบรรจุภัณฑ์ : 414.00 กรัม</div>
                                    <v-divider class="mb-3" dark></v-divider>
                                    <div class="black--text font-weight-bold ma-2">
                                        วัตถุดิบที่เสียหายหรือทิ้งไปจากการบรรจุภัณฑ์รอบนี้</div>
                                    <v-select item-text="value" item-value="id" class="input-radius-search mt-3"
                                        label="วัตถุดิบที่เสียหายหรือทิ้ง" hide-details="auto" outlined dense></v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>

                    <!-- ขั้นตอนที่ 5 : การตรวจสอบบรรจุภัณฑ์ -->
                    <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/shield(2).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 5 : การตรวจสอบบรรจุภัณฑ์</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="ml-15">
                                <div class="table-header-processing-steps mt-6" style="color: #b1adad;">
                                    <div class="table-sub-header-processing">ชื่อเกณฑ์ (English)</div>
                                    <div class="table-sub-header-processing">ชื่อเกณฑ์ (ภาษาไทย)</div>
                                    <div class="table-sub-header-processing ml-1">Unit</div>
                                    <div class="table-sub-header-processing ml-1" style="color:#ffb98a">Aim</div>
                                    <div class="table-sub-header-processing ml-n1" style="color:#13b65d">Green</div>
                                    <div class="table-sub-header-processing ml-n1" style="color:#ddbb55">Yellow</div>
                                    <div class="table-sub-header-processing ml-n3" style="color:#c00000">Red</div>
                                    <div class="table-sub-header-processing ml-n3">Value</div>
                                </div>
                                <v-card elevation="4" class="my-5" style="border-radius:20px">
                                    <div class="table-header-processing-steps py-4 pb-0">
                                        <div class="table-sub-header-processing black--text font-weight-bold">Steaming</div>
                                        <div class="table-sub-header-processing black--text font-weight-bold">การนึ่ง</div>
                                    </div>
                                    <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                                    <div class="grid-processing-items-steps ma-2 pl-3 pr-3"
                                        v-for="(detail, index) in dataList" :key="index">
                                        <div class="black--text  pa-1 mb-3">{{ detail.eng }}</div>
                                        <div class="black--text ">{{ detail.th }}</div>
                                        <div>{{ detail.unit }}</div>
                                        <div>{{ detail.aim }}</div>
                                        <div>{{ detail.green }}</div>
                                        <div>{{ detail.yellow }}</div>
                                        <div>{{ detail.red }}</div>
                                        <div class="ml-1">{{ detail.value }}</div>
                                    </div>
                                </v-card>
                                <v-card elevation="4" class="my-5" style="border-radius:20px">
                                    <div class="table-header-processing-steps py-4 pb-0">
                                        <div class="table-sub-header-processing black--text font-weight-bold">Sensory</div>
                                        <div class="table-sub-header-processing black--text font-weight-bold">ชิมข้าวนึ่ง
                                        </div>
                                    </div>
                                    <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                                    <div class="grid-processing-items-steps ma-2 mt-n5 mb-0 pl-3 pr-3"
                                        v-for="(detail, index) in secondList" :key="index">
                                        <div class="black--text  pa-1">{{ detail.eng }}</div>
                                        <div class="black--text ">{{ detail.th }}</div>
                                        <div>{{ detail.unit }}</div>
                                        <div>{{ detail.aim }}</div>
                                        <div>{{ detail.green }}</div>
                                        <div>{{ detail.yellow }}</div>
                                        <div>{{ detail.red }}</div>
                                        <div>
                                            <!-- <v-checkbox color="#FFBC15" v-model="detail.value"></v-checkbox> -->
                                            <v-icon class="my-5" v-if="detail.value == '0'"
                                                color="#F28080">mdi-close-circle</v-icon>
                                            <v-icon class="my-5" v-if="detail.value == '1'"
                                                color="#88B358">mdi-check-circle</v-icon>
                                        </div>
                                    </div>
                                </v-card>
                                <v-card elevation="4" class="my-5" style="border-radius:20px">
                                    <div class="table-header-processing-steps py-4 pb-0">
                                        <div class="table-sub-header-processing black--text font-weight-bold">Drying</div>
                                        <div class="table-sub-header-processing black--text font-weight-bold">การตากแห้ง
                                            (โดม)</div>
                                    </div>
                                    <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                                    <div class="grid-processing-items-steps ma-2 pl-3 pr-3"
                                        v-for="(detail, index) in dataList" :key="index">
                                        <div class="black--text  pa-1  mb-3">{{ detail.eng }}</div>
                                        <div class="black--text ">{{ detail.th }}</div>
                                        <div>{{ detail.unit }}</div>
                                        <div>{{ detail.aim }}</div>
                                        <div>{{ detail.green }}</div>
                                        <div>{{ detail.yellow }}</div>
                                        <div>{{ detail.red }}</div>
                                        <div class="ml-1">{{ detail.value }}</div>
                                    </div>
                                </v-card>
                            </div>
                        </v-card-text>
                    </v-card>
                    <!-- ขั้นตอนที่ 6 : การตรวจสอบกระบวนการบรรจุภัณฑ์ -->
                    <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/quality (1).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 6 : การตรวจสอบกระบวนการบรรจุภัณฑ์</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="ml-15">
                                <div class="table-header-processing-recheck mt-6" style="color: #b1adad">
                                    <div class="table-sub-header-processing">ชื่อเกณฑ์</div>
                                    <div class="table-sub-header-processing ml-2">เกณฑ์คุณภาพ</div>
                                    <div class="table-sub-header-processing ml-n3">Value</div>
                                </div>
                                <v-card elevation="4" class="my-5 pb-4" style="border-radius:20px">
                                    <div class="table-header-processing-recheck py-4 pb-0">
                                        <div class="table-sub-header-processing black--text font-weight-bold">Steamer</div>
                                    </div>
                                    <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                                    <div class="grid-processing-items-recheck ma-2 pl-3 pr-3"
                                        v-for="(detail, index) in qualityList" :key="index">
                                        <div class="black--text  pa-1">{{ detail.name }}</div>
                                        <div>{{ detail.quality }}</div>
                                        <div v-if="detail.value != 1">{{ detail.value }}</div>
                                        <div v-else> <v-icon class="" color="#88B358">mdi-check-circle</v-icon></div>
                                    </div>
                                </v-card>
                                <v-card elevation="4" class="my-5 pb-4" style="border-radius:20px">
                                    <div class="table-header-processing-recheck py-4 pb-0">
                                        <div class="table-sub-header-processing black--text font-weight-bold">Solar Dome
                                        </div>
                                    </div>
                                    <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                                    <div class="grid-processing-items-recheck ma-2 pl-3 pr-3"
                                        v-for="(detail, index) in solarList" :key="index">
                                        <div class="black--text  pa-1 ">{{ detail.name }}</div>
                                        <div>{{ detail.detail }}</div>
                                        <div>{{ detail.value }}</div>
                                    </div>
                                </v-card>
                            </div>
                        </v-card-text>
                    </v-card>
                    <!-- ขั้นตอนที่ 7 : ยืนยันการบรรจุภัณฑ์สำเร็จ -->
                    <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/approved.png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 7 : ยืนยันการบรรจุภัณฑ์สำเร็จ</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col lg="3" xl="3">
                                    วันที่ดำเนินการ : 24/02/2023
                                </v-col>
                                <v-col lg="3" xl="3">
                                    ผู้ดำเนินการ : พงศ์กมล
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-card-text>
                <div class="mt-3 text-center">
                    <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="modal = true">
                        <v-icon left>mdi-content-save</v-icon> ยืนยันการบรรจุภัณฑ์สำเร็จ
                    </v-btn>
                </div>
            </v-card>
        </div>

        <v-dialog v-model="modal" transition="dialog-top-transition" max-width="400" style="border-radius:20px">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="modal = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <div class="d-flex flex-column align-center">
                        <img class="icon-image mb-3" src="@/assets/buying.png">
                    </div>
                    <div class="text-center">
                        <h1 class="black--text">ยืนยันการบรรจุภัณฑ์สำเร็จ</h1>
                        <div class="my-2">คุณต้องการยืนยันการบรรจุภัณฑ์ใช่หรือไม่</div>
                    </div>
                    <div class="mt-3 text-center">
                        <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="linkTo">
                            <v-icon left>mdi-content-save</v-icon> ตกลง
                        </v-btn>
                        <div class="ma-4" style="cursor: pointer">ยกเลิก</div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
  
<script>
export default {
    name: "ProductionConfirm",
    data() {
        return {
            modal: false,
            firstStepList: [
                {
                    no: 1,
                    product: 'Krispy Rice Milk Flavor 210 กรัม',
                    lots: '22 ถุง',
                },
                {
                    no: 2,
                    product: 'Krispy Rice Milk Flavor  500 กรัม',
                    lots: '10 ถุง',
                },
                {
                    no: 3,
                    product: 'Krispy Rice Milk Flavor  1,000 กรัม',
                    lots: '0 ถุง',
                },
            ],
            batchList: [
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
            ],
            timeList: [
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
                {
                    sTime: 12.34,
                    eTime: 23.30,
                },
            ],
            dataList: [
                {
                    eng: 'Steaming temp',
                    th: 'อุณหภูมิในการนึ่ง',
                    unit: 'C',
                    aim: '100',
                    green: '90-110',
                    yellow: '<90>110',
                    red: '-',
                    value: 324,
                },
                {
                    eng: 'Steaming time',
                    th: 'เวลาในการนึ่ง',
                    unit: 'min',
                    aim: '50',
                    green: '45-55',
                    yellow: '<45>55',
                    red: '-',
                    value: 50,
                },
            ],
            secondList: [
                {
                    eng: 'Appearance',
                    th: 'ข้าวเป็นเม็ดสวยไม่แฉะ',
                    unit: '-',
                    aim: '-',
                    green: '-',
                    yellow: '-',
                    red: '-',
                    value: true,
                },
                {
                    eng: 'Texture',
                    th: 'ไม่เละไม่เป็นไต',
                    unit: '-',
                    aim: '-',
                    green: '-',
                    yellow: '-',
                    red: '-',
                    value: false,
                },
                {
                    eng: 'Flavor',
                    th: 'ไม่มีกลิ่นเหม็นอับ',
                    unit: '-',
                    aim: '-',
                    green: '-',
                    yellow: '-',
                    red: '-',
                    value: true,
                },
            ],
            qualityList: [
                {
                    name: 'Rice: Water',
                    quality: 'Jasmin 32:24/Berry 11:54',
                    value: 23,
                },
                {
                    name: 'จำนวนถาดที่หุ้ง',
                    quality: '-',
                    value: 23,
                },
                {
                    name: 'Temperature',
                    quality: '100C',
                    value: 1,
                },
                {
                    name: 'Time Start',
                    quality: '-',
                    value: 1,
                },
                {
                    name: 'Time End',
                    quality: '-',
                    value: 23,
                },
                {
                    name: 'Time',
                    quality: '50 Min - 60 Min',
                    value: 23,
                },
            ],
            solarList: [
                {
                    name: 'Temp',
                    detail: '45.0 - 70.0 C',
                    value: 'ok',
                },
                {
                    name: 'DateStart',
                    detail: '-',
                    value: 'Pass',

                },
                {
                    name: 'TimeStart',
                    detail: '-',
                    value: 'ไม่รั่ว',

                },
                {
                    name: 'DateEnd',
                    detail: '-',
                    value: '500.2',

                },
                {
                    name: 'TimeEnd',
                    detail: '-',
                    value: '500',
                },
                {
                    name: 'Time',
                    detail: '>6.0 hr',
                    value: '500',

                },
                {
                    name: 'Moisture : Dried Rice',
                    detail: '9.0 - 11.0',
                    value: '689',

                },
            ],
            mock_header: [
                {
                    header_name: 'Krispy Rice Milk Flavor',
                    header_unit: '210 กรัม',
                    header_qty: '22',
                    header_unit_name: 'ถุง',
                    mock_data: [
                        {
                            data_name: 'อะลูมิเนียมฟอยด์ 210 g',
                            data_lot_list_lot: '20-202302201500',
                            data_lot_list_qty: 22
                        },
                        {
                            data_name: 'ซิปล๊อค',
                            data_lot_list_lot: '21-202302201500',
                            data_lot_list_qty: 22
                        },
                        {
                            data_name: 'ฉลากพิมพ์สี',
                            data_lot_list_lot: '22-202302201500',
                            data_lot_list_qty: 22
                        },
                    ],
                },
                {
                    header_name: 'Krispy Rice Milk Flavor',
                    header_unit: '500 กรัม',
                    header_qty: '10',
                    header_unit_name: 'ถุง',
                    mock_data: [
                        {
                            data_name: 'อะลูมิเนียมฟอยด์ 210 g',
                            data_lot_list_lot: '20-202302201500',
                            data_lot_list_qty: 22
                        },
                        {
                            data_name: 'ซิปล๊อค',
                            data_lot_list_lot: '21-202302201500',
                            data_lot_list_qty: 22
                        },
                        {
                            data_name: 'ฉลากพิมพ์สี',
                            data_lot_list_lot: '22-202302201500',
                            data_lot_list_qty: 22
                        },
                    ],
                },

            ],
        }
    },
    methods: {
        linkTo() {
            this.$router.push('/prm/processing_materials')
        }
    }
}
</script>
  
<style scoped>
.border_right {
    border-right: 2px solid #000000;
    padding-right: 10px;
}

.topic_processing_header {
    font-size: 24px;
    color: #000000;
}

.topic_processing_content {
    color: #7E7E7E;
    padding-left: 10px;
}

.header-png {
    position: absolute;
    top: -15px;
    left: 20px;
}

.icon-image {
    width: 120px;
    height: 120px;
    text-align: center;
}

.icon-image-logo {
    width: 24px;
    height: 24px;
    vertical-align: center;
}

.tag-title-step {
    background-color: #FFF;
    border: 1px solid #000000;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.tag-title-step-table {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.table-header-processing {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.table-header-processing-second {
    display: grid;
    grid-template-columns: 5% 20% 25% 25% 25%;
}

.table-header-processing-details {
    display: grid;
    grid-template-columns: 10% 25% 25% 40%;
}

.table-header-processing-steps {
    display: grid;
    grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
}

.table-header-processing-recheck {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: baseline;
}

.grid-processing-items-second {
    display: grid;
    grid-template-columns: 5% 20% 25% 25% 25%;
    align-items: baseline;
}

.grid-processing-items-details {
    display: grid;
    grid-template-columns: 10% 25% 25% 40%;
    align-items: baseline;
}

.grid-processing-items-steps {
    display: grid;
    grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
    align-items: baseline;
}

.grid-processing-items-recheck {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    align-items: baseline;
}

.summarize-header {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
    inline-size: fit-content;
}

.summarize {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
}

.divider-table {
    border: 3px solid #34230F;
    border-radius: 3px;
}

/* Part 1 */
.w-border-header-first {
    background: #ffbc15;
    border-top: 15px solid #ffbc15;
    border-radius: 36px;
    width: 120%;
}

.inline-box-specific {
    background: #fff8e8;
    border-top: 1px solid #ffbc15;
    border-bottom: 1px solid #ffbc15;
}

.w-border-header-sub-last {
    background: #34230F;
    border: 7px solid #34230F;
    border-radius: 36px;
    width: auto;
}

.card-main-table {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding: 15px;
    width: 120%;
    height: 99%;
}

.card-main-table .header-card {}

.card-main-table .content-card {}

.card-main-table .footer-card {
    position: absolute;
    bottom: 0;
    width: 85%;
}

.card-sub-table-last {
    border: 1px solid #34230F;
    border-radius: 15px;
    padding: 15px;
    max-width: 100%;
    width: 100%;
    height: 99%;
    transition: 0.5s;
}

.card-sub-table-last:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-last {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu-last .last {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-right: 3%;
}

.scroll-menu-last section:first-child {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: sticky;
    left: 0;
    margin-right: 6%;
    background: #FFFFFF;
    z-index: 1;
}

/* Part 1 */

/* Part 2 */
.card-main-table-time {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding-bottom: 15px;
    width: 120%;
    height: 99%;
}

.card-sub-table-time {
    border: 1px solid #34230F;
    border-radius: 15px;
    max-width: 100%;
    width: 200px;
    height: 99%;
    transition: 0.5s;
}

.card-sub-table-time:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-time {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu-time .time {
    display: inline-block;
    vertical-align: top;
    margin-right: 3%;
}

.scroll-menu-time section:first-child {
    display: inline-block;
    position: sticky;
    left: 0;
    bottom: auto;
    margin-right: 6%;
    background: #FFFFFF;
    z-index: 1;
}

.inline-box-specific-time {
    background: #d6d3cf;
}

.footer-total {
    position: absolute;
    bottom: 1%;
    border-top: 2px solid #ffbc15;
    background-color: #fff2d0;
    padding: 11px;
    width: 120%;
}

.footer-gams {
    background-color: #000000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

/* Part 2 */

.text-green {
    color: #88B358;
}


.flex-center-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-header-two {
    display: flex;
    align-items: center;
    justify-content: end;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.flex-center-header>.tag-title-step-card {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.flex-center-header>.tag-title-step-card {
    border: 1px solid #34230F;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(even)>.border-card {
    border-radius: 20px;
    border: 1px solid #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card {
    border-radius: 20px;
    border: 1px solid #34230F;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #34230F;
}
</style>