<template>
  <div>
    <ProductionProductGoodProcessStepsEdit/>
  </div>
</template>

<script>
import ProductionProductGoodProcessStepsEdit from "@/components/production_process/processing_product_good/ProductionProductGoodProcessStepsEdit";
export default {
  name: "ProductionProductGoodProcessStepsEdit_Page",
  components: {ProductionProductGoodProcessStepsEdit}
}
</script>

<style scoped>

</style>