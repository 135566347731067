<template>
    <div>
      <ProductImportPage/>
    </div>
  </template>
  
  <script>
  import ProductImportPage from '@/components/stock/product_import/ProductImportListComponent'
  export default {
    name: 'Productimage',
    components:{
      ProductImportPage,
    }
  }
  </script>