<template>
    <div>
        <WarehouseDetailHistoryRawMaterialStockProduct_page />
    </div>
</template>
  
<script>
import WarehouseDetailHistoryRawMaterialStockProduct_page from '@/components/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialStockProduct'
export default {
    name: 'WarehouseDetailHistoryRawMaterialStockProduct',
    components: {
        WarehouseDetailHistoryRawMaterialStockProduct_page,
    }
}
</script>