
<template>
    <div>
        <SetProductAddPage />
    </div>
</template>
  
<script>
import SetProductAddPage from '@/components/stock/setting_stock/SetProductAdd'
export default {
    name: 'SetProductAdd',
    components: {
        SetProductAddPage,
    }
}
</script>