<template>
    <div>
        <QualityInspectionReportDetailPage />
    </div>
</template>
  
<script>
import QualityInspectionReportDetailPage from '@/components/stock/quality_inspection_report/QualityInspectionReportDetail'
export default {
    name: 'Reportstockpage',
    components: {
        QualityInspectionReportDetailPage,
    }
}
</script>