<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายละเอียดเกณฑ์การตรวจสอบการผลิต </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายละเอียดเกณฑ์การตรวจสอบการผลิต</b>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center ">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-check-decagram
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">หัวข้อรายการตรวจสอบการผลิต</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 text-name-title">ชื่อหัวข้อเกณฑ์การตรวจสอบการผลิต :
                                            <span class="text-fullname-title">{{ fq_title.fq_name }}</span>
                                        </div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 text-name-title">ประเภทการตรวจสอบ :
                                            <span class="text-fullname-title">{{ fq_title.fq_type_name }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card class="mt-8" elevation="4" style="border-radius:20px;overflow-wrap: unset !important;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-format-list-bulleted
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายการตรวจสอบการผลิต</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <div style="width: 100%;">
                                            <div class="table-header-production-box-data display-pc "
                                                style="color: #b1adad;">
                                                <div class="table-header-production-box-data-text">
                                                    ชื่อเกณฑ์
                                                </div>
                                                <div class="table-header-production-box-data-text">
                                                    ระยะค่าในเกณฑ์
                                                </div>
                                                <div class="table-header-production-box-data-text">
                                                    Value
                                                </div>
                                                <div class="table-header-production-box-data-text">
                                                    ค่าอัตโนมัติ
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" v-for="(fq_detail,index) in fq_list" :key="index">
                                        <v-card elevation="0" class="card-table-radius">
                                            <div class="grid-production-box-items">
                                                <div>{{ fq_detail.fq_list_name }}</div>
                                                <div>{{ fq_detail.fq_list_guide == '' ? '-' : fq_detail.fq_list_guide  }}</div>
                                                <div>
                                                    <div v-if="fq_detail.fq_list_type == 0 && fq_detail.fq_value_status == 1  && fq_detail.fq_value_boolean == 1">ผ่าน</div>
                                                    <div v-else-if="fq_detail.fq_list_type == 0 && fq_detail.fq_value_status == 1 && fq_detail.fq_value_boolean == 0"> ไม่ผ่าน </div>
                                                    <div v-else-if="fq_detail.fq_list_type == 1 && fq_detail.fq_value_status == 1"> {{ fq_detail.fq_value }} </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <div><v-icon v-if="fq_detail.fq_value_status == 1" color="#FFBC15">mdi-check-circle</v-icon></div>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import inspectioncriteriaproduction from '@/api/warehouse/inspectioncriteriaproduction.js';
import navigationmenu from '@/components/menu/VerticalMenu.vue';
export default {
    data() {
        return {
            loading_page: false,
            width: null,
            long: null,
            height: null,
            search: null,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าเกณฑ์การตรวจสอบ',
                    disabled: false,
                    href:'/stock/setting-stock/inspection-criteria-production'
                },
                {
                    text: 'เกณฑ์การตรวจสอบการผลิต',
                    disabled: false,
                    href:'/stock/setting-stock/inspection-criteria-production'
                },
                {
                    text: 'รายละเอียดเกณฑ์การตรวจสอบการผลิต',
                    disabled: false,
                    href: '#',
                },
            ],
            fq_title: {
                fq_name: '',
                fq_type: null,
                fq_type_name: null,

            },
            fq_list: [
                {
                    fq_list_name: '',
                    fq_list_guide: '',
                    fq_list_type: 1,
                    fq_value_status: 1,
                    fq_value_boolean: 1,
                    fq_value: '',
                }
            ]
        }
    },

    methods: {
        async inspectionCriteriaProductionDetail(){
            this.loading_page = true
            this.fq_title = {
                fq_name: '',
                fq_type: null,
                fq_type_name: null,
            }
            this.fq_list = []
            inspectioncriteriaproduction.detailInspectionCriteriaProduction(this.$route.params.id).then((response) => {
                if (response.data.successful === true) {
                    this.fq_id = response.data.detail.fq_title.id
                    this.fq_title.fq_name = response.data.detail.fq_title.fq_name
                    this.fq_title.fq_type = response.data.detail.fq_title.fq_type
                    this.fq_title.fq_type_name = response.data.detail.fq_title.fq_type_name
                    
                    const num_arr = response.data.detail.fq_list.length
                    for (let index = 0; index < num_arr; index++) {
                        this.fq_list.push({
                            fq_list_name: response.data.detail.fq_list[index].fq_list_name,
                            fq_list_guide: response.data.detail.fq_list[index].fq_list_guide,
                            fq_list_type: response.data.detail.fq_list[index].fq_list_type,
                            fq_value_status: response.data.detail.fq_list[index].fq_value_status,
                            fq_value_boolean: response.data.detail.fq_list[index].fq_value_boolean,   
                            fq_value: response.data.detail.fq_list[index].fq_value,
                        })
                    }

                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    components: {
        navigationmenu
    },
    created() {
        this.inspectionCriteriaProductionDetail()
    },

}
</script>
<style scoped>
.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}


.table-header-production-box-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 10px;
}

.table-header-production-box-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-production-box-items {
    display: grid;
    grid-template-columns: 25% 25% 27% 23%;
    align-items: center;
    padding: 15px;
}

.v-card {
    overflow-wrap: unset !important;
}
</style>