<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายงานการตรวจสอบคุณภาพวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>รายงานการตรวจสอบคุณภาพวัตถุดิบ</b>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-btn @click="link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterial')" class="ma-2 tab-button-disable "
                            type="button"
                            color="#909090"  large
                            outlined>รายงานการตรวจสอบคุณภาพ</v-btn>
                        <v-btn @click="link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction')" class="ma-2 tab-button active"
                            type="button" elevation="2" large outlined
                            color="#FFBC15">รายงานการตรวจสอบคุณภาพการผลิต</v-btn>
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center ">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-file
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายละเอียด Krispy Rice MILK Flavor LOT 1-202209051351</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">เลขที่ใบสั่งแปรรูป : 
                                            <span color="6C7FD8" class="text-fullname-title text-blud "><a href="#" @click="link('warehousedetail-history-raw-material')"> OP-202301001 </a></span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">ผู้ดำเนินการ : 
                                            <span class="text-fullname-title">ชลิตวรรณ นาคทอง</span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                    </v-col>
                                    
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card class="mt-8" elevation="4" style="border-radius:20px;overflow-wrap: unset !important;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายงานการตรวจสอบคุณภาพการผลิต</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pb-0">
                                        <div style="width: 100%;">
                                            <div class="table-header-lotoutstock-process-data display-pc pb-0 " style="color: #b1adad;">
                                                <div class="table-header-lotoutstock-process-data-text pb-0"></div>
                                                <div class="table-header-lotoutstock-process-data-text pb-0">
                                                    รายการตรวจสอบคุณภาพ
                                                </div>
                                                <div class="table-header-lotoutstock-process-data-text pb-0">
                                                    เกณฑ์คุณภาพ
                                                </div>
                                                <div class="table-header-lotoutstock-process-data-text pb-0">
                                                    ผลการตรวจสอบ
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" v-for="(quality_loop,index) in quality_list" :key="index">
                                        <v-card elevation="2" class=" display-pc b-radius-20 py-4 mx-5 mb-3">
                                            <div class="table-header-lotoutstock-process-data pb-2" >
                                                <div class="table-header-lotoutstock-process-data-text"></div>
                                                <div class="table-header-lotoutstock-process-data-text bold">{{ quality_loop.quality_head_name_en }}</div>
                                            </div>

                                            <div class="mx-5 ">
                                                <v-divider class="mb-2" color="#686868"></v-divider>
                                            </div>
                                            
                                            <div class="table-header-lotoutstock-process-data pb-0" v-for="(quality_loop_list,index_sub) in quality_loop.data_mat" :key="index_sub">
                                                <div class="table-header-lotoutstock-process-data-text"></div>
                                                <div class="table-header-lotoutstock-process-data-text">{{ quality_loop_list.quality_name }}</div>
                                                <div class="table-header-lotoutstock-process-data-text">{{ quality_loop_list.quality_qty }}</div>
                                                <div class="table-header-lotoutstock-process-data-text">{{ quality_loop_list.quality_value }}</div>
                                                <!-- <div class="table-header-lotoutstock-process-data-text">
                                                    <v-icon color="#88B358">mdi-check-circle</v-icon>
                                                </div> -->
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency} from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',

            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังวัตถุดิบ',
                    disabled: false,
                },
                {
                    text: '(นมข้นจืด)ดึงข้อมูล',
                    disabled: false,
                },
                {
                    text: 'รายละเอียดวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
                {
                    text: '(LOT 1-202209051351)ดึงข้อมูล',
                    disabled: false,
                    href: '#',
                },
                {
                    text: 'รายงานการตรวจสอบคุณภาพวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            items: [
                {
                    id: 1,
                    name: 'กล่องไปรษณีย์',
                },
                {
                    id: 2,
                    name: 'กล่องของบริษัท',
                },
                {
                    id: 3,
                    name: 'กล่องลูกค้า',
                }
            ],
            page: 1,
            data: [],
            quality_list:[
                {
                    quality_head_name_en:'Steaming',
                    data_mat:[
                        {
                            quality_name:'Rice : Water',
                            quality_qty:'Jasmine 3.10:2.85 / Riceberry 3.10:2.85',
                            quality_value:'3.10:2.85 ',
                        },
                        {
                            quality_name:'จำนวนถาดที่หุง',
                            quality_qty:'-',
                            quality_value:'24',
                        },   
                        {
                            quality_name:'Temperature',
                            quality_qty:'100 C',
                            quality_value:'100',
                        }, 
                        {
                            quality_name:'Time Start',
                            quality_qty:'-',
                            quality_value:'7:30',
                        },                       
                    ],
                },
                {
                    quality_head_name_en:'Solar Dome',
                    data_mat:[
                        {
                            quality_name:'Temp',
                            quality_qty:'45.0 - 70.0 C',
                            quality_value:'50-65 ',
                        },
                        {
                            quality_name:'Date Start',
                            quality_qty:'-',
                            quality_value:'26/01/2023',
                        },   
                        {
                            quality_name:'Time Start',
                            quality_qty:'-',
                            quality_value:'100',
                        }, 
                        {
                            quality_name:'Date End',
                            quality_qty:'-',
                            quality_value:'9:00',
                        },                       
                    ],
                }
            ]
        }
    },
    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
    },
    mounted() {
        
    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.text-aim{
    color:#FFB98A;
}

.text-green{
    color:#00B050;
}

.text-yellow{
    color:#DBB84D;
}

.text-red{
    color:#C00000
}

.text-blud{
    color:#6C7FD8
}
.b-radius-20{
    border-radius:20px;
}

.table-header-lotoutstock-process-data {
    display: grid;
    grid-template-columns: 3% 30% 30% 30%  ;
    padding: 10px;
}

.table-header-lotoutstock-process-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-lotoutstock-process-items {
    display: grid;
    grid-template-columns: 3% 30% 30% 30% ;
    align-items: center;
    padding: 15px;
}
</style>