// การแปรรูปวัตถุดิบ
import Processing_materials from "@/views/production_process/processing_raw_materials/Processing_materials";
import Processing_create from "@/views/production_process/processing_raw_materials/Processing_create";
import Processing_raw_materials from "@/views/production_process/processing_raw_materials/Processing_raw_materials";
import Processing_raw_material_details
    from "@/views/production_process/processing_raw_materials/Processing_raw_material_details";
import Production_result_create from "@/views/production_process/processing_raw_materials/Production_result_create";
import Production_process_steps from "@/views/production_process/processing_raw_materials/Production_process_steps";
import Production_process_recheck from "@/views/production_process/processing_raw_materials/Production_process_recheck";
import Production_confirm from "@/views/production_process/processing_raw_materials/Production_confirm";
import Processing_steps from "@/views/production_process/processing_raw_materials/Processing_steps";
import Production_confirm_details from "@/views/production_process/processing_raw_materials/Production_confirm_details";
import Production_confirm_edit from "@/views/production_process/processing_raw_materials/Production_confirm_edit";
import Processing_edit from "@/views/production_process/processing_raw_materials/Processing_edit";
import Processing_raw_materials_edit
    from "@/views/production_process/processing_raw_materials/Processing_raw_materials_edit";
import Production_result_edit from "@/views/production_process/processing_raw_materials/Production_result_edit";
import Production_process_steps_edit
    from "@/views/production_process/processing_raw_materials/Production_process_steps_edit";
import Production_process_recheck_edit
    from "@/views/production_process/processing_raw_materials/Production_process_recheck_edit";

// การผลิตสินค้า
import ProcessingProductGood from "@/views/production_process/processing_product_good/ProcessingProductGood";
import ProcessingProductGoodSteps from "@/views/production_process/processing_product_good/ProcessingProductGoodSteps";
import ProcessingProductGoodCreate from "@/views/production_process/processing_product_good/ProcessingProductGoodCreate";
import ProcessingProductGoodCreatePO from "@/views/production_process/processing_product_good/ProcessingProductGoodCreatePO";
import ProcessingProductGoodEdit from "@/views/production_process/processing_product_good/ProcessingProductGoodEdit";
import ProcessingProductGoodProduction from "@/views/production_process/processing_product_good/ProcessingProductGoodProduction";
import ProcessingProductGoodProductionEdit from "@/views/production_process/processing_product_good/ProcessingProductGoodProductionEdit";
import ProcessingProductGoodProductionDetail from "@/views/production_process/processing_product_good/ProcessingProductGoodProductionDetail";
import ProductionProductGoodResultCreate from "@/views/production_process/processing_product_good/ProductionProductGoodResultCreate";
import ProductionProductGoodResultEdit from "@/views/production_process/processing_product_good/ProductionProductGoodResultEdit";
import ProductionProductGoodPackage from "@/views/production_process/processing_product_good/ProductionProductGoodPackage";
import ProductionProductGoodPackageEdit from "@/views/production_process/processing_product_good/ProductionProductGoodPackageEdit";
import ProductionProductGoodProcessSteps from "@/views/production_process/processing_product_good/ProductionProductGoodProcessSteps";
import ProductionProductGoodProcessStepsEdit from "@/views/production_process/processing_product_good/ProductionProductGoodProcessStepsEdit";
import ProductionProductGoodProcessRecheck from "@/views/production_process/processing_product_good/ProductionProductGoodProcessRecheck";
import ProductionProductGoodProcessRecheckEdit from "@/views/production_process/processing_product_good/ProductionProductGoodProcessRecheckEdit";
import ProductionProductGoodConfirm from "@/views/production_process/processing_product_good/ProductionProductGoodConfirm";
import ProductionProductGoodConfirmDetail from "@/views/production_process/processing_product_good/ProductionProductGoodConfirmDetail";
import ProductionProductGoodConfirmEdit from "@/views/production_process/processing_product_good/ProductionProductGoodConfirmEdit";

// การบรรจุภัณฑ์
import ProcressingPackaging from "@/views/production_process/procressing_packaging/ProcressingPackaging";
import ProcressingPackagingDetail from "@/views/production_process/procressing_packaging/ProcressingPackagingDetail";
import ProcressingPackagingEdit from "@/views/production_process/procressing_packaging/ProcressingPackagingEdit";
import ProcressingPackagingStepAll from "@/views/production_process/procressing_packaging/ProcressingPackagingStepAll";
import ProcressingPackagingStepCreate from "@/views/production_process/procressing_packaging/ProcressingPackagingStepCreate";
import ProcressingPackagingStepProduction from "@/views/production_process/procressing_packaging/ProcressingPackagingStepProduction";
import ProcressingPackagingStepPackage from "@/views/production_process/procressing_packaging/ProcressingPackagingStepPackage";
import ProcressingPackagingStepDamaged from "@/views/production_process/procressing_packaging/ProcressingPackagingStepDamaged";
import ProcressingPackagingStepProcress from "@/views/production_process/procressing_packaging/ProcressingPackagingStepProcress";
import ProcressingPackagingStepRecheck from "@/views/production_process/procressing_packaging/ProcressingPackagingStepRecheck";
import ProcressingPackagingStepConfirm from "@/views/production_process/procressing_packaging/ProcressingPackagingStepConfirm";

export default [
    {
        path: "/prm/processing_materials",
        name: "processing_materials",
        component: Processing_materials,
    },
    {
        path: "/prm/processing_create",
        name: "processing_create",
        component: Processing_create,
    },
    {
        path: "/prm/processing_create/:id",
        name: "processing_create",
        component: Processing_create,
    },
    {
        path: "/prm/processing_raw_materials/:id",
        name: "processing_raw_materials",
        component: Processing_raw_materials,
    },
    {
        path: "/prm/processing_raw_materials/:id/:status",
        name: "processing_raw_materials",
        component: Processing_raw_materials,
    },
    {
        path: "/prm/processing_raw_material_details",
        name: "processing_raw_material_details",
        component: Processing_raw_material_details,
    },

    // ขั้นตอนที่ 3 : บันทึกผลการแปรรูป
    {
        path: "/prm/production_result_create/:id",
        name: "processing_create",
        component: Production_result_create,
    },

    // ขั้นตอนที่ 4 : การตรวจสอบกระบวนการแปรรูป
    {
        path: "/prm/production_process_recheck/:id",
        name: "production_process_recheck",
        component: Production_process_recheck,
    },


    // ขั้นตอนที่ 5 : การตรวจสอบกระบวนการแปรรูป
    {
        path: "/prm/production_process_steps/:id",
        name: "production_process_steps",
        component: Production_process_steps,
    },

    // ขั้นตอนที่ 6 : ยืนยันการแปรรูปสำเร็จ
    {
        path: "/prm/production_confirm/:id",
        name: "production_confirm",
        component: Production_confirm,
    },
    {
        path: "/prm/production_confirm/:id/:status",
        name: "production_confirm",
        component: Production_confirm,
    },

    // ขั้นตอนการแปรรูป Step
    {
        path: "/prm/processing_steps",
        name: "processing_steps",
        component: Processing_steps,
    },
    {
        path: "/prm/processing_steps/:id",
        name: "processing_steps",
        component: Processing_steps,
    },

    // รายละเอียดการแปรรูปวัตถุดิบ เลขที่ OF-202301005
    {
        path: "/prm/production_confirm_details/:id",
        name: "production_confirm_details",
        component: Production_confirm_details,
    },

    // แก้ไข ขั้นตอนที่ 6 : ยืนยันการแปรรูปสำเร็จ
    {
        path: "/prm/production_confirm_edit",
        name: "production_confirm_edit",
        component: Production_confirm_edit,
    },

    // ขั้นตอนที่ 1 : แก้ไข สร้างใบสั่งแปรรูป
    {
        path: "/prm/processing_edit/:id",
        name: "processing_edit",
        component: Processing_edit,
    },

    // ขั้นตอนที่ 2 : แก้ไข สร้างใบเตรียมวัตถุดิบ
    {
        path: "/prm/processing_raw_materials_edit",
        name: "processing_raw_materials_edit",
        component: Processing_raw_materials_edit,
    },

    // ขั้นตอนที่ 3 : บันทึกผลการแปรรูป
    {
        path: "/prm/production_result_edit",
        name: "production_result_edit",
        component: Production_result_edit,
    },

     // ขั้นตอนที่ 4 : การตรวจสอบกระบวนการแปรรูป
    {
        path: "/prm/production_process_recheck_edit",
        name: "production_process_recheck_edit",
        component: Production_process_recheck_edit,
    },

    // ขั้นตอนที่ 5 : การตรวจสอบกระบวนการแปรรูป
    {
        path: "/prm/production_process_steps_edit",
        name: "production_process_steps_edit",
        component: Production_process_steps_edit,
    },

   

    ////////////////// รายการรอบการผลิตสินค้า //////////////////

    // รายการรอบการผลิตสินค้า
    {
        path: "/pro/processing_product_good",
        name: "processing_product_good",
        component: ProcessingProductGood,
    },

    // ขั้นตอนการผลิต : ขั้นตอนทั้งหมด
    {
        path: "/pro/processing_product_good_steps/:id",
        name: "processing_product_good_step",
        component: ProcessingProductGoodSteps,
    },

    // ขั้นตอนที่ 1 : สร้างใบสั่งผลิต
    {
        path: "/pro/processing_product_good_create/:id",
        name: "processing_product_good_create",
        component: ProcessingProductGoodCreate,
    },
    {
        path: "/pro/processing_product_good_createPO/:id",
        name: "processing_product_good_createPO",
        component: ProcessingProductGoodCreatePO,
    },

    // ขั้นตอนที่ 1 : แก้ไขใบสั่งผลิต
    {
        path: "/pro/processing_product_good_edit",
        name: "processing_product_good_edit",
        component: ProcessingProductGoodEdit,
    },

    // ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ
    {
        path: "/pro/processing_product_good_production/:id",
        name: "processing_product_good_production",
        component: ProcessingProductGoodProduction,
    },
    {
        path: "/pro/processing_product_good_production/:id/:status",
        name: "processing_product_good_production",
        component: ProcessingProductGoodProduction,
    },

    // ขั้นตอนที่ 2 : แก้ไขใบเตรียมวัตถุดิบ
    {
        path: "/pro/processing_product_good_production_edit",
        name: "processing_product_good_production_edit",
        component: ProcessingProductGoodProductionEdit,
    },


    ////////////////// การบรรจุภัณฑ์ //////////////////

    // การบรรจุภัณฑ์ : รายการบรรจุภัณฑ์สินค้า
    {
        path: "/pac/processing-packaging",
        name: "processing_packaging",
        component: ProcressingPackaging,
    },
    // การบรรจุภัณฑ์ : รายละเอียดการบรรจุภัณฑ์
    {
        path: "/pac/processing-packaging-detail",
        name: "processing_packaging_detail",
        component: ProcressingPackagingDetail,
    },
     // การบรรจุภัณฑ์ : แก้ไขการบรรจุภัณฑ์
     {
        path: "/pac/processing-packaging-edit",
        name: "processing_packaging_edit",
        component: ProcressingPackagingEdit,
    },
    // การบรรจุภัณฑ์ : สร้างรอบการบรรจุภัณฑ์
    {
        path: "/pac/processing-packaging-step/:id",
        name: "processing_packaging_step_all",
        component: ProcressingPackagingStepAll,
    },
    // การบรรจุภัณฑ์ : ขั้นตอนที่ 1 : สร้างใบสั่งบรรจุภัณฑ์
    {
        path: "/pac/processing-packaging-step-create/:id",
        name: "processing_packaging_step_create",
        component: ProcressingPackagingStepCreate,
    },
    // การบรรจุภัณฑ์ : ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ
    {
        path: "/pac/processing-packaging-step-production/:id",
        name: "processing_packaging_step_production",
        component: ProcressingPackagingStepProduction,
    },
    {
        path: "/pac/processing-packaging-step-production/:id/:status",
        name: "processing_packaging_step_production",
        component: ProcressingPackagingStepProduction,
    },

    // การบรรจุภัณฑ์ : ขั้นตอนที่ 3 : บันทึกผลการบรรจุภัณฑ์
    {
        path: "/pac/processing-packaging-step-package/:id",
        name: "processing_packaging_step_package",
        component: ProcressingPackagingStepPackage,
    },

    // การบรรจุภัณฑ์ : ขั้นตอนที่ 4 : สินค้า FG คงเหลือและบรรจุภัณฑ์ที่เสียหาย
    {
        path: "/pac/processing-packaging-step-damaged/:id",
        name: "processing_packaging_step_damaged",
        component: ProcressingPackagingStepDamaged,
    },
    // การบรรจุภัณฑ์ :  ขั้นตอนที่ 5 : การตรวจสอบบรรจุภัณฑ์
    {
        path: "/pac/processing-packaging-step-procress/:id",
        name: "processing_packaging_step_procress",
        component: ProcressingPackagingStepProcress,
    },

    // การบรรจุภัณฑ์ : ขั้นตอนที่ 6 : การตรวจสอบกระบวนการบรรจุภัณฑ์
    {
        path: "/pac/processing-packaging-step-recheck/:id",
        name: "processing_packaging_step_recheck",
        component: ProcressingPackagingStepRecheck,
    },

    // การบรรจุภัณฑ์ : ขั้นตอนที่ 7 : ยืนยันการบรรจุภัณฑ์สำเร็จ
    {
        path: "/pac/processing-packaging-step-confirm/:id",
        name: "processing_packaging_step_confirm",
        component: ProcressingPackagingStepConfirm,
    },
    {
        path: "/pac/processing-packaging-step-confirm/:id/:status",
        name: "processing_packaging_step_confirm",
        component: ProcressingPackagingStepConfirm,
    },


    // ขั้นตอนที่ 2 : รายละเอียดการเตรียมวัตถุดิบสำหรับการผลิต 
    {
        path: "/pro/processing_product_good_production_detail/:id",
        name: "processing_product_good_production_detail",
        component: ProcessingProductGoodProductionDetail,
    },

    // ขั้นตอนที่ 2 : รายละเอียดการเตรียมวัตถุดิบสำหรับการผลิต 
    {
        path: "/pro/processing_product_good_production_detail/:id/:status",
        name: "processing_product_good_production_detail",
        component: ProcessingProductGoodProductionDetail,
    },

    // ขั้นตอนที่ 3 : บันทึกผลการผลิต
    {
        path: "/pro/processing_product_good_result_create/:id",
        name: "processing_product_good_result_create",
        component: ProductionProductGoodResultCreate,
    },

    // ขั้นตอนที่ 3 : แก้ไข บันทึกผลการผลิต
    {
        path: "/pro/processing_product_good_result_edit",
        name: "processing_product_good_result_edit",
        component: ProductionProductGoodResultEdit,
    },

    // ขั้นตอนที่ 4 : การบรรจุภัณฑ์
    {
        path: "/pro/processing_product_good_package/:id",
        name: "processing_product_good_package",
        component: ProductionProductGoodPackage,
    },

    ////////////////// การบรรจุภัณฑ์ //////////////////


    // ขั้นตอนที่ 4 : แก้ไข การบรรจุภัณฑ์
    {
        path: "/pro/processing_product_good_package_edit",
        name: "processing_product_good_package_edit",
        component: ProductionProductGoodPackageEdit,
    },

    // ขั้นตอนที่ 5 : การตรวจสอบกระบวนการผลิต
    {
        path: "/pro/processing_product_good_process_steps/:id",
        name: "processing_product_good_process_steps",
        component: ProductionProductGoodProcessSteps,
    },

    // ขั้นตอนที่ 5 : แก้ไข การตรวจสอบกระบวนการผลิต
    {
        path: "/pro/processing_product_good_process_steps_edit",
        name: "processing_product_good_process_steps_edit",
        component: ProductionProductGoodProcessStepsEdit,
    },

    // ขั้นตอนที่ 6 : การตรวจสอบสินค้าที่ผลิตสำเร็จ
    {
        path: "/pro/processing_product_good_process_recheck/:id",
        name: "processing_product_good_process_recheck",
        component: ProductionProductGoodProcessRecheck,
    },

      // ขั้นตอนที่ 6 : แก้ไข การตรวจสอบสินค้าที่ผลิตสำเร็จ
    {
        path: "/pro/processing_product_good_process_recheck_edit",
        name: "processing_product_good_process_recheck_edit",
        component: ProductionProductGoodProcessRecheckEdit,
    },

    // ขั้นตอนที่ 7 : ยืนยันการผลิตสำเร็จ
    {
        path: "/pro/processing_product_good_confirm/:id",
        name: "processing_product_good_confirm",
        component: ProductionProductGoodConfirm,
    },
    {
        path: "/pro/processing_product_good_confirm/:id/:status",
        name: "processing_product_good_confirm",
        component: ProductionProductGoodConfirm,
    },
    {
        path: "/pro/processing_product_good_confirm_detail/:id",
        name: "processing_product_good_confirm_detail",
        component: ProductionProductGoodConfirmDetail,
    },

    // ขั้นตอนที่ 7 : ยืนยันการผลิตสำเร็จ
    {
        path: "/pro/processing_product_good_confirm_edit",
        name: "processing_product_good_confirm_edit",
        component: ProductionProductGoodConfirmEdit,
    },
    
];