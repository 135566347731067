<template>
    <div>
      <ProductCreatePurchaseOrderEditPage/>
    </div>
  </template>
  
  <script>
  import ProductCreatePurchaseOrderEditPage from '@/components/stock/product_import/ProductCreatePurchaseOrderEditComponent'
  export default {
    name: 'Productcreatepurchaseorderedit',
    components:{
      ProductCreatePurchaseOrderEditPage,
    }
  }
  </script>