<template>
    <div>
        <QualityInspectionCriteriaAddPage />
    </div>
</template>
  
<script>
import QualityInspectionCriteriaAddPage from '@/components/stock/setting_stock/QualityInspectionCriteriaAddComponent'
export default {
    name: 'QualityInspectionCriteriaAdd',
    components: {
        QualityInspectionCriteriaAddPage,
    }
}
</script>