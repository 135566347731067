<template>
    <div>
      <AddOrderListPage/>
    </div>
  </template>

<script>
import AddOrderListPage from '@/components/stock/order/AddOrderListComponent'
export default {
    name: 'addorderlist',
    components:{
        AddOrderListPage,
    }
}
</script>