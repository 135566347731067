<template>
    <div>
      <ProcessingPackagingStepPackage/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepPackage from "@/components/production_process/procressing_packaging/ProcressingPackagingStepPackage";
  export default {
    name: "Processing_packaging_step_package",
    components: {
      ProcessingPackagingStepPackage
    }
  }
  </script>
  
  <style scoped>
  
  </style>