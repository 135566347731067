<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">สร้างรายการเพิ่มสินค้าเข้าคลัง </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>

        <v-stepper v-model="stepImport" style="padding:30px;">
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <p class="team-text-title-card">สร้างรายการเพิ่มสินค้าเข้าคลัง</p>
                    <div class="d-flex justify-space-between">
                        <div class="text-center">
                            <v-btn color="#FFBC15" fab x-small :outlined="stepImport == 1" dark style="font-size:14px;"
                                :complete="stepImport > 1" step="1">
                                1
                            </v-btn>
                            <p class="my-2" style="font-size:16px;font-weight:600;color:#FFBC15;">
                                ข้อมูลสินค้า</p>
                        </div>
                        <div class="text-center">
                            <v-btn :color="stepImport == 1 ? '#7D7D7D' : '#ffbc15'" fab x-small outlined dark
                                style="font-size:14px;" :complete="stepImport > 2" step="2">
                                2
                            </v-btn>
                            <p class="my-2"
                                :style="stepImport == 1 ? 'font-size:16px;font-weight:600;color:#FFBC15;' : 'font-size:16px;font-weight:600;color:#FFBC15;'">
                                ตรวจสอบคุณภาพสินค้า</p>
                        </div>
                    </div>
                    <v-progress-linear style="background-color:#D9D9D9;" :color="stepImport == 1 ? '#D9D9D9' : '#ffbc15'"
                        rounded height="9" :value="stepImport == 1 ? 0 : 80">
                    </v-progress-linear>
                </v-col>
            </v-row>
            <v-stepper-items>
                <v-row>
                    <v-stepper-content step="1">
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-package-variant-closed
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">เลือกสินค้าที่ต้องการนำเข้าสินค้า</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-row>
                                            <v-col xs="6" sm="6" md="6" lg="6" xl="6" cols="6">
                                            <v-form-group>
                                                <v-autocomplete color="#686868" :items="select_box" item-text="pro_name"
                                                    item-value="id" v-model="id" @change="getUnitList" ref="selectProduct"
                                                    :rules=" data.length  == 0 ? [v => !!v || 'กรุณาเลือกสินค้าที่ต้องการเพิ่มเข้าคลัง'] : ''"
                                                    label="เลือกสินค้าที่ต้องการเพิ่มเข้าคลัง" hide-details="auto" dense
                                                    outlined rounded required></v-autocomplete>
                                            </v-form-group>
                                        </v-col>
                                        <v-col xs="4" sm="4" md="4" lg="4" xl="4" cols="4">
                                            <v-form-group>
                                                <v-autocomplete color="#686868" :items="unit_list" item-text="pro_size_name"
                                                    item-value="product_size_id" v-model="unit_id" ref="selectUnit"
                                                    :rules=" data.length  == 0 ? [v => !!v || 'กรุณาเลือกหน่วยนับสินค้า'] : ''"
                                                    label="เลือกหน่วยนับสินค้า" hide-details="auto" dense
                                                    outlined rounded required></v-autocomplete>
                                            </v-form-group>
                                        </v-col>
                                        <v-col xs="2" sm="2" md="2" lg="2" xl="2" cols="2">
                                            <v-btn type="button" class="button-submit-data" @click="addProduct()"
                                            style="color: rgb(136, 179, 88) !important;" elevation="4" rounded large>
                                            <v-icon left>mdi-arrow-down</v-icon> เพิ่ม
                                            </v-btn>
                                        </v-col>
                                        </v-row>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">

                                            <div class="table-header-create-data display-pc" style="color: #b1adad;">
                                                <div class="table-header-create-data-text">ลบ</div>
                                                <div class="table-header-create-data-text">
                                                    #
                                                </div>
                                                <div class="table-header-create-data-text">
                                                    สินค้า
                                                </div>
                                                <div class="table-header-create-data-text">
                                                    วันที่หมดอายุ
                                                </div>
                                                <div class="table-header-create-data-text">
                                                    จำนวน
                                                </div>
                                                <div class="table-header-create-data-text">
                                                    ราคาต่อหน่วย
                                                </div>
                                                <div class="table-header-create-data-text text-center">
                                                    ราคารวม
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-form ref="step1" @input="checkValidate()">
                                    <div v-for="(item, index) in data" :key="item">
                                    <v-card-title class="padding-text-title align-items-center"
                                        style="background: rgba(255, 188, 21, 0.1);border: 1px solid #FFBC15;border-radius: 20px;margin: 0 0 15px 15px;">
                                        <span class="ml-3 text-font-title" style="font-size: 14px;">{{ item.typeName }}</span>
                                    </v-card-title>
                                    <v-card-text v-for="(detail, i) in item.List" :key="detail">
                                        <v-card elevation="0" class="card-table-radius">
                                            <div class="grid-create-items">
                                                <div class="text-center">
                                                    <v-icon dark color="error" size="32" @click='remove(index, i)'>
                                                        mdi-delete-forever
                                                    </v-icon>
                                                </div>
                                                <div class="text-center">{{i+1}}</div>
                                                <div>{{ detail.pro_name }} <br> ({{ detail.pro_size_name }})</div>
                                                <div style="padding: 10px;">
                                                    <v-menu v-model="detail.menu" :close-on-content-click="false"
                                                        :nudge-right="40" transition="scale-transition" offset-y
                                                        min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="detail.lot_exp" label="วันที่หมดอายุ"
                                                                prepend-inner-icon="mdi-calendar" hide-details="auto"
                                                                :rules="[v => !!v || 'กรุณาเลือกวันที่หมดอายุ']"
                                                                v-bind="attrs" dense outlined rounded required readonly
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="detail.lot_exp" @input="detail.menu = false"
                                                            no-title scrollable required></v-date-picker>
                                                    </v-menu>
                                                </div>
                                                <div style="padding: 10px;">
                                                        <v-form-group>
                                                        <v-text-field class="input-radius" color="#FFBC15"
                                                            label="จำนวน" placeholder="จำนวน"
                                                            v-model="detail.qty"
                                                            :rules="[v => !!v || 'กรุณากรอกจำนวน']"
                                                            @input="formatInput(index, i), calculate_input(i, index)"
                                                            @keypress="isOnlyNumber($event, detail.qty)"
                                                            hide-details="auto" :suffix="detail.unit_name" dense outlined rounded
                                                            required></v-text-field>
                                                    </v-form-group>
                                                </div>
                                                <div style="padding: 10px;">
                                                    <v-form-group>
                                                        <v-text-field class="input-radius" color="#FFBC15"
                                                            label="ราคาต้นทุน" placeholder="ราคาต้นทุน"
                                                            v-model="detail.retail_price"
                                                            :rules="[v => !!v || 'กรุณากรอกราคาต้นทุน']"
                                                            @input="formatInput(index, i), calculate_input(i, index)"
                                                            @keypress="isOnlyNumber($event, detail.retail_price)"
                                                            hide-details="auto" suffix="บาท" dense outlined rounded
                                                            required></v-text-field>
                                                    </v-form-group>
                                                </div>
                                                <div class="text-center">{{ formatPrice(detail.total_price) }}</div>
                                            </div>
                                        </v-card>
                                    </v-card-text>
                                </div>
                                </v-form>
                                <div class="d-flex justify-end">
                                    <div class="d-flex justify-space-between ma-3"
                                        style="padding:20px;background: rgba(136, 179, 88, 0.15);border: 1px solid #88B358;border-radius: 15px;">
                                        <h2 style="color:#000;margin-right:100px;">รวมเงิน</h2>
                                        <h2 style="color:#88B358;">{{ formatPrice(total) }}</h2>
                                    </div>
                                </div>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn v-if="step1_status == false" outlined type="button" class="button-submit-data"
                                    style="color: rgb(136, 179, 88) !important;" elevation="4" rounded large disabled>
                                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                                </v-btn>
                                <v-btn v-if="step1_status == true" outlined type="button" class="button-submit-data" @click="getProductQuery()"
                                    style="color: rgb(136, 179, 88) !important;" elevation="4" rounded large>
                                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                                </v-btn>
                            </div>
                        </v-col>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                           <v-form ref="input" @submit.prevent="saveData()">
                             <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-shield-check
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <div class="table-header-import-step3-data display-pc mt-3"
                                                style="color: #b1adad;">
                                                <div class="table-header-import-step3-data-text">
                                                    ชื่อสินค้า
                                                </div>
                                                <div class="table-header-import-step3-data-text">
                                                    รายละเอียดการตรวจสอบ
                                                </div>
                                                <div class="table-header-import-step3-data-text">
                                                    Value
                                                </div>
                                                <div class="table-header-import-step3-data-text">
                                                    หมายเหตุ/รายละเอียดการแก้ไข
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text>
                                    <v-card elevation="0" class="card-table-radius py-8" style="border: 1px solid #D9D9D9;"
                                        v-for="item in product_query" :key="item">
                                        <div class="grid-import-step3-items">
                                            <div style="padding: 10px;">
                                                <p style="color:#000;font-weight:600;">{{ item.pro_name }}</p>
                                                <p style="color:#000;font-weight:600;">Lot : {{ item.product_lot }}</p>
                                            </div>
                                            <v-row class="">
                                                <v-col cols="12">

                                                    <v-row v-for="detail in item.pq_list" :key="detail">
                                                        <v-col cols="6 py-0">
                                                            <div style="margin-top: 15px">
                                                                <p class="mb-0" style="color: #000; font-weight: 600">
                                                                    {{ detail.pq_list_thai }}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="6 py-0">
                                                            <div style="margin-top: 15px">
                                                                <p class="mb-0"><v-icon
                                                                        @click="detail.pq_value_boolean = 0, detail.pq_value = 0"
                                                                        color="#88B358"
                                                                        v-if="detail.pq_list_type == 0 && detail.pq_value_boolean == 1">mdi-check-circle</v-icon>
                                                                </p>
                                                                <p class="mb-0"><v-icon
                                                                        @click="detail.pq_value_boolean = 1, detail.pq_value = 1"
                                                                        color="#F28080"
                                                                        v-if="detail.pq_list_type == 0 && detail.pq_value_boolean == 0">mdi-close-circle</v-icon>
                                                                </p>
                                                                <v-text-field class="input-radius" color="#FFBC15"
                                                                    label=" Value" placeholder="Value"
                                                                    v-if="detail.pq_list_type == 1" :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                                                                    v-model="detail.pq_value" hide-details="auto" dense
                                                                    outlined rounded></v-text-field>
                                                            </div>
                                                        </v-col>
                                                    </v-row>

                                                </v-col>


                                            </v-row>
                                            <div style="padding: 10px;">
                                                <v-textarea class="input-radius" label="หมายเหตุ/รายละเอียดการแก้ไข"
                                                    color="#FFBC15" outlined rounded rows="7" row-height="25"
                                                    v-model="item.note">
                                                </v-textarea>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large
                                   >
                                    <v-icon left>mdi-content-save</v-icon> นำเข้าคลัง
                                </v-btn>
                            </div>
                           </v-form>
                        </v-col>
                    </v-stepper-content>
                </v-row>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>
<script>
import { isTelephone, onlyForCurrency, formatPrice } from "@/helper/handler";
// import Producttype from "@/api/warehouse/Products_types.js";
import Product from "@/api/import_product/productAdd.js";
import Swal from "sweetalert2";
export default {
    data() {
        return {
            loading_page: false,
            stepImport: 1,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'นำเข้าสินค้า',
                    disabled: false,
                    href: '/stock/product-import',
                },
                {
                    text: 'เพิ่มสินค้าเข้าคลัง',
                    disabled: false,
                },
                {
                    text: 'สร้างรายการเพิ่มสินค้าเข้าคลัง',
                    disabled: false,
                    href: '#',
                },
            ],
            unit: [
                { id: 1, name: 'แพ็ค 5' },
                { id: 2, name: 'แพ็ค 10' },
                { id: 3, name: 'แพ็ค 15' },
            ],
            tab: null,
            items: [
                'ผงปรุงรส', 'ผลิตภัณฑ์เหลว'
            ],
            quality_list: [
                {
                    name_main: 'Strup coating',
                    isActive: false,
                    quality_sub: [
                        {
                            name_en: 'Homogeneous',
                            name_th: 'ความเข้ากัน(ไม่เป็นก้อน)',
                            unit: '-',
                            aim: '-',
                            green: 'เป็นเนื้อเดียวกัน',
                            yellow: 'เป็นก้อน',
                            red: '-',
                        },
                        {
                            name_en: 'Plain Crispy RiceWeight',
                            name_th: 'น้ำหนักความพองก่อนเคลือบ',
                            unit: 'Kg',
                            aim: '-',
                            green: '-',
                            yellow: '-',
                            red: '-',
                        },
                        {
                            name_en: 'Syrup weight',
                            name_th: 'น้ำหนักน้ำเคลือบ',
                            unit: 'Kg',
                            aim: '-',
                            green: '-',
                            yellow: '-',
                            red: '-',
                        },
                    ],
                },
                {
                    name_main: 'Drying 2',
                    isActive: false,
                    quality_sub: [
                        {
                            name_en: 'Plain Crispy RiceWeight',
                            name_th: 'น้ำหนักความพองก่อนเคลือบ',
                            unit: 'Kg',
                            aim: '-',
                            green: '-',
                            yellow: '-',
                            red: '-',
                        },
                    ],
                },
            ],
            toggle_exclusive: 2,
            page: 1,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,
            select_box: [],
            data: [],
            data_list: [],
            id: null,
            unit_id: null,
            test: {},
            note: [],
            unit_list: [],

            product_query: [],
            total: 0,

            step1_status: false,


        }
    },

    methods: {
        checkValidate(){
            if(this.$refs.step1.validate()){
                if(this.data.length != 0){
                    this.step1_status = true;
                }else{
                    this.step1_status = false;
                }
                
            }else{  
                this.step1_status = false;
            }
            

        },
        formatInput(index, i) {
          this.loading_page = true;
          if((this.data[index].List[i].qty+'').length > 3){
             this.data[index].List[i].qty = (this.data[index].List[i].qty+'').replace(/,/g, '');
              this.data[index].List[i].qty = (this.data[index].List[i].qty+'').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
          if(((this.data[index].List[i].retail_price == null || this.data[index].List[i].retail_price == '' ? 0 : this.data[index].List[i].retail_price)+'').length > 3){
             this.data[index].List[i].retail_price = ((this.data[index].List[i].retail_price == null || this.data[index].List[i].retail_price == '' ? 0 : this.data[index].List[i].retail_price) +'').replace(/,/g, '');
              this.data[index].List[i].retail_price = ((this.data[index].List[i].retail_price == null || this.data[index].List[i].retail_price == '' ? 0 : this.data[index].List[i].retail_price)+'').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
      this.loading_page = false;
    },
        link(link) {
            this.$router.push(link)
        },
        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
        formatPrice(number) {
            return formatPrice(number)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        remove(index, i) {
            if (this.data[index].List.length == 1) {
                this.data.splice(index, 1);
            } else {
                this.data[index].List.splice(i, 1);
            }
        },
        calculate_input(i = null, index = null) {
            
                        // this.data[index].List[i].qty = this.data[index].List[i].qty == '' ? 0 : this.data[index].List[i].qty
                        this.data[index].List[i].total_price = parseFloat(((this.data[index].List[i].qty == null || this.data[index].List[i].qty == '' ? 0 : this.data[index].List[i].qty)+'').replace(/,/g, '')) * parseFloat(((this.data[index].List[i].retail_price == null || this.data[index].List[i].retail_price == '' ? 0 : this.data[index].List[i].retail_price)+'').replace(/,/g, ''));
                       
            this.total = 0;
            for (let x = 0; x < this.data.length; x++) {
                for (let j = 0; j < this.data[x].List.length; j++) {
                    this.total += parseFloat(this.data[x].List[j].total_price);
                }
            }
              this.data[index].List[i].total_price = (this.data[index].List[i].total_price+'')
        },
        getProductQuery() {
            for (let i = 0; i < this.data.length; i++) {
                for (let x = 0; x < this.data[i].List.length; x++) {
                    this.product_query.push({
                        id: this.data[i].List[x].id,
                        pro_name: this.data[i].List[x].pro_name,
                        qty: this.data[i].List[x].qty,
                        lot_exp: this.data[i].List[x].lot_exp,
                        product_type_id: this.data[i].List[x].product_type_id,
                        product_size_id: this.data[i].List[x].product_size_id,
                        product_lot: this.data[i].List[x].product_lot,
                        pro_instock: this.data[i].List[x].pro_instock,
                        pro_amount: this.data[i].List[x].pro_amount,
                        retail_price: this.data[i].List[x].retail_price,
                        price: this.data[i].List[x].retail_price,
                        total_price: this.data[i].List[x].total_price,
                        pq_list: this.data[i].List[x].pq_list,
                        note: null
                    })
                }
            }
            this.stepImport = 3
        },
        getUnitList(id){
            let data = this.select_box.find((item) => item.id === id)
            this.unit_list = data.size_list
        },
        addProduct() {
         if(this.unit_list.length > 0 && this.unit_id != null){
               this.loading_page =true;
            let select = this.select_box.find(o => o.id === this.id)
            const type = this.data_list[select.index_group];
            const Product = type.List[select.count_select];
            const item = Product.size_list.find(o => o.product_size_id === this.unit_id);
            let check = this.data.find(o => o.product_type_id === Product.product_type_id)
            const dt = new Date();
            const hours = dt.getHours().toString().padStart(2, '0');
            const minutes = dt.getMinutes().toString().padStart(2, '0');
            let product_lot = `${dt.getFullYear()}${String(dt.getMonth() + 1).padStart(2, '0')}${String(dt.getDate()).padStart(2, '0')}${hours}${minutes}`;
            if (check == null) {
                this.data.push({
                    typeName: type.typeName,
                    product_type_id: Product.product_type_id,
                    product_size_id: item.product_size_id,
                    index_group: this.data.length,
                    List: []
                });
                let select = this.data.find(o => o.typeName === type.typeName)
                let select_list = this.data[select.index_group].List.find(o => o.pro_name === Product.pro_name)
                if(select_list == null){
               
                    this.data[select.index_group].List.push({
                        id: Product.id,
                        pro_name: Product.pro_name,
                        pro_size_name: item.pro_size_name,
                        product_size_id: item.product_size_id,
                        qty: null,
                        lot_exp: null,
                        product_type_id: Product.product_type_id,
                        product_lot: Product.product_type_id + '-' + product_lot,
                        pro_instock: Product.pro_instock,
                        retail_price: null,
                        price: item.price,
                        pro_amount: item.pro_amount,
                        unit_name: item.unit_name,
                        total_price: 0,
                        menu:false,
                        note: null,
                        pq_list: Product.pq_list
                    });
            
                }
            } else {
                let select = this.data.find(o => o.typeName === type.typeName)
                let select_list = this.data[select.index_group].List.find(o => o.pro_name === Product.pro_name && o.product_size_id === item.product_size_id) 
                if(select_list == null){
                        this.data[select.index_group].List.push({
                         id: Product.id,
                        pro_name: Product.pro_name,
                        pro_size_name: item.pro_size_name,
                        product_size_id: item.product_size_id,
                        qty: null,
                        lot_exp: null,
                        product_type_id: Product.product_type_id,
                        product_lot: Product.product_type_id + '-' + product_lot,
                        pro_instock: Product.pro_instock,
                        retail_price: null,
                        price: item.price,
                        pro_amount: item.pro_amount,
                        unit_name: item.unit_name,
                        total_price: 0,
                        menu:false,
                        note: null,
                        pq_list: Product.pq_list
                    });
         
                }
            }
             this.$refs.selectProduct.reset();
             this.$refs.selectUnit.reset();
             this.unit_list = []
              this.loading_page =false;
         }
        },
        getProductSelectbox() {
            this.loading_page = true;
            Product.getProductSelectbox().then((response) => {
                if (response.data.successful === true) {
                  let select_box = response.data.resultList;
                  this.data_list = response.data.resultList;
                    for (let i = 0; i < select_box.length; i++) {
                        for (let x = 0; x < select_box[i].List.length; x++) {
                            this.select_box.push({
                                id: select_box[i].List[x].id,
                                pro_name: select_box[i].List[x].pro_name,
                                product_type_id: select_box[i].List[x].product_type_id,
                                size_list: select_box[i].List[x].size_list,
                                count_select: x,
                                index_group: i
                            })
                        }
                    }
                    this.loading_page = false;
                }
            })
        },
         saveData() {
           if(this.$refs.input.validate()){
             let list_array = []
            let pq_list = []
            for (let i = 0; i < this.product_query.length; i++) {
                pq_list = []
                for (let j = 0; j < this.product_query[i].pq_list.length; j++) {
                    pq_list.push({
                        pq_list_id: this.product_query[i].pq_list[j].pq_list_id,
                        pq_id: this.product_query[i].pq_list[j].pq_id,
                        pq_list_type: this.product_query[i].pq_list[j].pq_list_type,
                        value: this.product_query[i].pq_list[j].pq_value+'' == '' ? '1' : this.product_query[i].pq_list[j].pq_value+'',
                    })
                }
                list_array.push({
                    product_id: this.product_query[i].id,
                    product_lot: this.product_query[i].product_lot,
                    product_size_id: this.product_query[i].product_size_id,
                    qty: parseFloat((this.product_query[i].qty+'').replace(/,/g, '')),
                    price: parseFloat(this.product_query[i].retail_price.replace(/,/g, '')),
                    pro_amount: parseFloat((this.product_query[i].qty+'').replace(/,/g, '')),
                    cost_pergram: parseFloat(this.product_query[i].retail_price.replace(/,/g, '')),
                    total_price: parseFloat(this.product_query[i].total_price.replace(/,/g, '')),
                    lot_exp: this.product_query[i].lot_exp,
                    note: this.product_query[i].note,
                    pq_list: pq_list,
                })
            }
             
            let data = {
                total_price: this.total,
                list: list_array
            }
            console.log(data)
            this.test = data;
            Product.add(data).then((response) => {
                if (response.data.successful === true) {
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            this.$router.push("/stock/product-import");
                        }
                    });
                }
            })
           }
        }
    },
    mounted() {
        this.getProductSelectbox();
    }
}
</script>
<style scoped>
.input-group {
    display: flex;
    gap: 10px;
}

.form-select {
    width: 150px;
}

.form-control {
    flex: 1;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.padding-text-title {
    padding: 8px !important;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 20px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-create-data {
    display: grid;
    grid-template-columns: 5% 4% 16% 20% 20% 20% 15%;
    color: #686868 !important;
}

.table-header-create-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-create-items {
    display: grid;
    grid-template-columns: 5% 4% 17% 20% 20% 20% 14%;
    align-items: center;
}

.table-header-import-step3-data {
    display: grid;
    grid-template-columns: 21% 25% 24% 30%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-step3-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-step3-items {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    align-items: center;
}</style>