<template>
    <div>
        <div class="d-flex">
            <div class="topic_processing_header border_right">สร้างรอบการบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2">การบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
            <div class="topic_processing_content my-2">สร้างรอบการบรรจุภัณฑ์</div>
        </div>
        <div class="mt-3">

            <v-card elevation="2" class="card-radius">
                <v-card-title>
                    <b>ขั้นตอนที่ 3 : บันทึกผลการบรรจุภัณฑ์</b>
                </v-card-title>
                <v-card-text>
                    <v-card elevation="4" class="py-2 px-3" style="border-radius:20px;">

                        <v-card-title>
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-notebook-edit-outline
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 title-card-text">การบันทึกผลการบรรจุภัณฑ์</span>
                        </v-card-title>

                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>

                            <v-row class="">

                                <v-col v-for="(loop_header, index_header) in mock_header" :key="index_header"
                                    class="card-table-radius-sub" cols="12" lg="6" xl="6">
                                    <v-card elevation="2" class="border-card">
                                        <v-row class="ma-1 go-to-card">

                                            <v-col cols="6 flex-center-header">
                                                <div class="tag-title-step">
                                                    <b class="black--text">{{ loop_header.pro_size_name }}</b>
                                                </div>
                                                <span> {{ loop_header.pro_name }} </span>
                                            </v-col>
                                            <v-col cols="6 text-right flex-center-header-two">
                                                <span>จำนวน {{ loop_header.qty }} {{ loop_header.unit_name }}</span>
                                            </v-col>

                                            <v-col cols="12 py-1 col-harder">
                                                <v-divider class="border-main pt-1" dark color=""></v-divider>
                                            </v-col>

                                            <v-col cols="4 pb-0">
                                                <span>วัตถุดิบ </span>
                                            </v-col>
                                            <v-col cols="4 pb-0">
                                                <span>เลข Lot </span>
                                            </v-col>
                                            <v-col cols="4 px-0 pb-0 ">
                                                <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                                            </v-col>

                                        </v-row>

                                        <v-row class="ma-1 " v-for="(loop_sub, index) in loop_header.material_list"
                                            :key="index">
                                            <v-col cols="4 ">
                                                <span> {{ loop_sub.mat_name }} </span>
                                            </v-col>

                                            <v-col cols="8">
                                                <v-row v-for="(loop_sub_list, index_sub) in loop_sub.stock_list"
                                                    :key="index_sub">
                                                    <!-- <v-col cols="2 px-0" v-if="index_sub != 0">
                                                        <v-btn class="" color="#F28080" elevation="2" outlined rounded>
                                                            <v-icon>mdi-delete-forever</v-icon>
                                                        </v-btn>
                                                    </v-col> -->
                                                    <!-- <v-col cols="2"></v-col> -->
                                                    <v-col cols="5">
                                                        <!-- <v-select :items="mock_lot" color="#ffbc15" item-text="lot"
                                                            item-value="lot_id" label="เลข Lot"
                                                            :rules="[(v) => !!v || 'กรุณาเลือก เลข Lot']"
                                                            hide-details="auto" dense outlined rounded required disabled></v-select> -->
                                                            <v-text-field type="text" class="input-radius"
                                                                label="เลข Lot"
                                                                placeholder="เลข Lot"
                                                                v-model="loop_sub_list.material_lot" color="#ffbc15"
                                                                :rules="[(v) => !!v || 'กรุณากรอกบรรจุภัณฑ์']"
                                                                hide-details="auto" dense outlined rounded required disabled>
                                                            </v-text-field>
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <v-form-group>
                                                            <v-text-field type="text" class="input-radius"
                                                                label="วัตถุดิบที่ใช้ในการบรรจุภัณฑ์"
                                                                placeholder="ชื่อสินค้า"
                                                                v-model="loop_sub_list.quantity_cut" color="#ffbc15"
                                                                :rules="[(v) => !!v || 'กรุณากรอกบรรจุภัณฑ์']"
                                                                hide-details="auto" dense outlined rounded required disabled>
                                                            </v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                            <v-col cols="4 text-center py-0"></v-col>
                                            <!-- <v-col cols="8 py-0 pb-2">
                                                <btn type="button" @click="plusLot(index_header, index)" class="text-green">
                                                    <v-icon color="#88B358">mdi-plus</v-icon> เพิ่ม Lot วัตถุดิบ</btn>
                                            </v-col> -->

                                        </v-row>

                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <div class="mt-3 text-center">
                    <v-btn type="button" class="white--text" color="#88B358" elevation="4" rounded large @click="saveStepThree()">
                        <v-icon left>mdi-content-save</v-icon> บันทึก
                    </v-btn>
                </div>
            </v-card>
        </div>
    </div>
</template>
  
<script>
import process_package from '@/api/process_package/package.js'
import Swal from "sweetalert2";
export default {
    name: "ProcessingCreate",
    data() {
        return {
            sTime: '00 : 00',

            dataList: [
                {
                    product_size: 'ขนาด 210 กรัม',
                    product_qty: 22,
                    product_result: '4,759.00',
                },
                {
                    product_size: 'ขนาด 500 กรัม',
                    product_qty: 22,
                    product_result: '4,759.00',
                },
            ],
            mock_lot: [
                {
                    lot_id: 1,
                    lot: '20-202302201500'
                },
                {
                    lot_id: 2,
                    lot: '21-202302201500'
                },
            ],

            mock_header: [],
            no_lot: 1,
            lot_list: [
                {
                    no_lot: 1,
                    lot_number: [],
                    lot_qty: null,
                }
            ]
        }
    },
    methods: {
        plusLot(indexHeader, index) {
            this.mock_header[indexHeader].mock_data[index].data_lot_list.push({
                data_lot_list_lot: null,
                data_lot_list_qty: 0
            });
        },

        linkToSteps() {
            this.$router.push('/prm/processing_raw_materials')
        },
        async getStepThreeListOne() {
        let id = this.$route.params.id;
      await process_package.getStepThreeListOne(id).then((response) => {
        if (response.data.successful === true) {
          this.loading_page = false
          this.mock_header = response.data.data
        }
      }).catch((error) => {
             if(error.response.status === 502){
                window.location.reload()
              }
          })
    },
        async saveStepThree() {
            this.loading_page = true;
        let id = this.$route.params.id;
      await process_package.saveStepThree(id).then((response) => {
        if (response.data.successful === true) {
          this.loading_page = false
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/pac/processing-packaging-step/"+this.$route.params.id);
            }
          });
        }
      }).catch((error) => {
             if(error.response.status === 502){
                window.location.reload()
              }
          })
    },
    },
    mounted(){
        this.getStepThreeListOne()
    }
}
</script>
  
<style scoped>
.border_right {
    border-right: 2px solid #000000;
    padding-right: 10px;
}

.topic_processing_header {
    font-size: 24px;
    color: #000000;
}

.topic_processing_content {
    color: #7E7E7E;
    padding-left: 10px;
}

.table-header-processing {
    display: grid;
    grid-template-columns: 10% 36% 29% 15%;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 23% 23% 29% 15%;
    align-items: baseline;
}

.summarize {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
}

.text-green {
    color: #88B358;
}



.flex-center-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-header-two {
    display: flex;
    align-items: center;
    justify-content: end;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.flex-center-header>.tag-title-step {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.flex-center-header>.tag-title-step {
    border: 1px solid #34230F;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(even)>.border-card {
    border-radius: 20px;
    border: 1px solid #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card {
    border-radius: 20px;
    border: 1px solid #34230F;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #34230F;
}
</style>