<template>
    <div>
        <WarehouseDetail_Unpackaged_LotUsed_page />
    </div>
</template>
  
<script>
import WarehouseDetail_Unpackaged_LotUsed_page from '@/components/stock/warehouse_stock/WarehouseDetailUnpackagedDetailLotUsed'
export default {
    name: 'WarehouseDetailUnpackagedDetailLotUsed',
    components: {
        WarehouseDetail_Unpackaged_LotUsed_page,
    }
}
</script>