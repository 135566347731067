<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">คลังสินค้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>คลังสินค้า</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="">
                                    <v-btn class="mt-2 ml-0 mx-5 radius-15 text-16" elevation="2" large outlined
                                        color="#FFBC15">
                                        คลังวัตถุดิบ
                                    </v-btn>
                                    <v-btn class="mt-2 mx-5 radius-15 text-grey text-16" elevation="" large color="#fff"
                                        @click="link('/stock/warehouse-stock/warehouse-unpackaged')">
                                        คลังสินค้าที่ยังไม่ได้บรรจุภัณฑ์
                                    </v-btn>
                                    <v-btn class="mt-2 mx-5 radius-15 text-grey text-16" elevation="" large color="#fff"
                                        @click="link('/stock/warehouse-stock/warehouse-packaged')">
                                        คลังสินค้าที่บรรจุภัณฑ์แล้วคงเหลือ
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search" prepend-inner-icon="mdi-magnify"
                                    v-model="search" placeholder="ค้นหาที่นี่..." hide-details="auto" single-line outlined
                                    dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                                <div>
                                    <v-autocomplete class="input-radius-search" color="#686868" :items="select_category"
                                        v-model="status" item-text="name" item-value="id" @change="meterialList()"
                                        label="กลุ่มวัตถุดิบ" hide-details="auto" outlined dense></v-autocomplete>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-warehouse-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-warehouse-box-data-text"></div>
                                    <div class="table-header-warehouse-box-data-text">
                                        ชื่อวัตถุดิบ
                                    </div>
                                    <div class="table-header-warehouse-box-data-text">
                                        จำนวน
                                    </div>
                                    <div class="table-header-warehouse-box-data-text">
                                        กลุ่มวัตถุดิบ
                                    </div>
                                    <div class="table-header-warehouse-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in meterial_data"
                                    :key="index">
                                    <div class="grid-warehouse-box-items">
                                        <div class="w-border"></div>
                                        <div>{{ detail.mat_name }}</div>
                                        <div>{{ formatPrice(detail.mat_instock)}} {{detail.unit_name}}</div>
                                        <div>{{ detail.mat_stauts_name }}</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#6C7FD8"
                                                            @click="link('/stock/warehouse-stock/warehousedetail/' + detail.id)">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียดวัตถุดิบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link>
                                                        <!-- วัตถุดิบแปรรูป == 1 / วัตถุดิบปกติ/บรรจุภัฒน์ == 2 -->
                                                        <v-list-item-title style="color:#34230F"
                                                            @click="link('/stock/warehouse-stock/warehousedetail-history-raw-material/' + detail.id + '?status=' + (detail.mat_stauts_name == 'วัตถุดิบแปรรูป' ? '1' : '2') )"> 
                                                            <v-icon left style="color:#34230F">mdi-clock</v-icon>
                                                            ประวัติวัตถุดิบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link v-if="$store.state.profileDetail.per_inventory_report == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item-title style="color:#88B358" 
                                                            @click="detail.mat_stauts_name == 'วัตถุดิบแปรรูป' ? link('/stock/warehouse-stock/warehousedetail-history-raw-material-audit-inspection/' + detail.id) : link('/stock/warehouse-stock/warehousedetail-history-raw-material-audit/' + detail.id)">
                                                            <v-icon left style="color:#88B358">mdi-calendar-month</v-icon>
                                                            รายงานการตรวจสอบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="all_page" :total-visible="all_page"
                                         color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import meterial from '@/api/warehouse/materials.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            select_category_active: ['ทั้งหมด'],
            select_category: [
                {
                    id: 0,
                    name: 'ทั้งหมด',
                },
                {
                    id: 1,
                    name: 'วัตถุดิบปกติ',
                },
                {
                    id: 2,
                    name: 'วัตถุดิบแปรรูป',
                },
                {
                    id: 3,
                    name: 'วัตถุดิบบรรจุภัณฑ์',
                },
            ],
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
            ],
            items: [
                {
                    id: 1,
                    name: 'กล่องไปรษณีย์',
                },
                {
                    id: 2,
                    name: 'กล่องของบริษัท',
                },
                {
                    id: 3,
                    name: 'กล่องลูกค้า',
                }
            ],
            page: 1,
            all_page: 1,
            search: '',
            status: 0,

            meterial_data: [],
            ware_house_data: [
                {
                    name: 'นมข้นจืด',
                    price: '796 กรัม',
                    unit: 'วัตถุดิบปกติ',
                },
                {
                    name: 'ข้าวขาวแห้ง',
                    price: '123,000 กรัม',
                    unit: 'วัตถุดิบแปรรูป',
                },
                {
                    name: 'น้ำเปล่า',
                    price: '35,000 กรัม',
                    unit: 'วัตถุดิบปกติ',
                },
            ],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        meterialList() {
            meterial.meterialList(this.page, '', this.search, this.status).then((response) => {
                if (response.data.successful === true) {
                    this.meterial_data = response.data.data;
                    this.all_page = response.data.pages;
                    this.page = parseFloat(response.data.currentPage);

                }
            })

        },
    },
    mounted() {
        this.meterialList();
    },
    components: {
        navigationmenu
    },
    watch:{
        page(){
            this.meterialList()
        },
        search(){
            this.page = 1 
            this.meterialList()
        },
        
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-warehouse-box-data {
    display: grid;
    grid-template-columns: 2% 25% 25% 25% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-warehouse-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-warehouse-box-items {
    display: grid;
    grid-template-columns: 5% 25% 24% 34% 12%;
    align-items: center;
}
</style>