import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    provinceList: () => {
        return HTTP.get('/address/listprovince', {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    amphuresList: (id) => {
        return HTTP.get('/address/listampur/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    districtList: (id) => {
        return HTTP.get('/address/listdistrict/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    editData: (data, id) => {
        return HTTP.post('/company/editCompany/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => response)
    },
    dataList: () => {
        return HTTP.get('/company/companyList', {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    dataListNotAuth: () => {
        return HTTP.get('/company/companyListWithoutAuth')
            .then(response => response)
    }

}