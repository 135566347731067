<template>
    <div>
      <SummaryAddOrderListPage/>
    </div>
  </template>

<script>
import SummaryAddOrderListPage from '@/components/stock/order/SummaryAddOrderListComponent'
export default {
    name: 'summaryaddorderlist',
    components:{
        SummaryAddOrderListPage,
    }
}
</script>