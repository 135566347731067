<template>
    <div>
      <AdminPage/>
    </div>
  </template>
  
  <script>
  import AdminPage from '@/components/teams/AdminComponent'
  export default {
    name: 'Admin',
    components:{
      AdminPage,
    }
  }
  </script>