<template>
  <div>
    <ProductionProcessStepsEdit/>
  </div>
</template>

<script>
import ProductionProcessStepsEdit
  from "@/components/production_process/processing_raw_materials/ProductionProcessStepsEdit";
export default {
  name: "Production_process_steps_edit",
  components: {ProductionProcessStepsEdit}
}
</script>

<style scoped>

</style>