<template>
  <div class="loading_all_page" v-if="loading_page === true">
      <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
      <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2">การแปรรูปวัตถุดิบ</div>
    </div>

    <!-- <div class="d-flex justify-space-around">
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/processing_steps')">
          หน้าขั้นตอนการแปรรูป
        </v-btn>
      </div>
    </div> -->

    <!-- <div class="d-flex justify-space-around">
      
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/production_confirm_details')">
          หน้ารายละเอียดการแปรรูปวัตถุดิบ
        </v-btn>
      </div>
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/production_confirm_edit')">
          หน้าแก้ไขการแปรรูปวัตถุดิบ
        </v-btn>
      </div>
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/processing_edit')">
          หน้าแก้ไขรอบการแปรรูปวัตถุดิบ
        </v-btn>
      </div>
    </div> -->
    <!-- <div class="d-flex justify-space-around">
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/processing_raw_materials_edit')">
          หน้าแก้ไขใบเตรียมวัตถุดิบ
        </v-btn>
      </div>
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/production_result_edit')">
          หน้าแก้ไขบันทึกผลการแปรรูป
        </v-btn>
      </div>
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/production_process_steps_edit')">
          หน้าการตรวจสอบกระบวนการแปรรูป
        </v-btn>
      </div>
      <div class="text-center">
        <v-btn class="mt-2 white--text" large rounded color="#88B358" @click="linkPage('/prm/production_process_recheck_edit')">
          หน้าการตรวจสอบกระบวนการแปรรูป(ต่อ)
        </v-btn>
      </div>
    </div> -->

    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>รายการรอบการแปรรูปวัตถุดิบ</b>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div class="search-input">
              <v-text-field color="#D9D9D9" class="input-radius-search mt-3" prepend-inner-icon="mdi-magnify" placeholder="ค้นหาที่นี่..." hide-details="auto" single-line outlined dense></v-text-field>
            </div>
            <div>
              <v-btn class="mt-2" large rounded outlined color="#88B358" @click="linkToCreate"  v-if="$store.state.profileDetail.per_process_add == 1 || $store.state.profileDetail.permission == 1">
                <v-icon left>mdi-plus</v-icon>สร้างรอบการแปรรูปวัตถุดิบ
              </v-btn>
              <v-btn class="mt-2" large rounded  color="#D9D9D9" v-else>
                <v-icon left>mdi-plus</v-icon>สร้างรอบการแปรรูปวัตถุดิบ
              </v-btn>
            </div>
          </div>
          <div class="table-header-processing mt-5" style="color: #b1adad;">
            <div class="table-sub-header-processing"></div>
            <div class="table-sub-header-processing">
              รหัสการแปรรูป
            </div>
            <div class="table-sub-header-processing">
              วันที่แปรรูป
            </div>
            <div class="table-sub-header-processing">
              วัตถุดิบที่ต้องการแปรรูป
            </div>
            <div class="table-sub-header-processing text-left ml-1">
              สถานะการแปรรูป
            </div>
            <div class="table-sub-header-processing text-left ml-5">
              ผู้สั่งแปรรูป
            </div>
            <div class="table-sub-header-processing text-center">
              จัดการ
            </div>
          </div>

          <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in dataList" :key="index">
            <div class="grid-processing-items">
              <div class="w-border"></div>
              <div class="d-flex ml-10">
                <div class="ml-2">{{ detail.process_code }}</div>
                <div v-if="detail.note_msg" class="ml-5">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#a3a3a3" dark v-bind="attrs" v-on="on">
                        mdi-message-bulleted
                      </v-icon>
                    </template>
                    <div class="grid-msg">
                      <div class="text--left">01/01/2023 09:30</div>
                      <div class="text--center">หมายเหตุการยกเลิกการผลิต: {{ detail.note_msg.note }}</div>
                      <div style="text-align: end">โดย {{ detail.note_msg.user_create }}</div>
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="ml-11">{{ `${detail.processing_date.split('/')[0]}-${detail.processing_date.split('/')[1]}-${detail.processing_date.split('/')[2]}` }}</div>
              <div class="ml-11">{{ detail.mat_name }}</div>
              <div v-if="detail.status_process !== 7" class="ml-2">
                <v-progress-linear class="progress-color" color="#3f9629" rounded v-model="detail.status_process_percent" height="25"><strong style="color: #FFFFFF">{{ Math.ceil(detail.status_process_percent) }}%</strong></v-progress-linear>
              </div>
              <!-- {{ detail }} -->
              <div v-else class="ml-2">
                <v-chip class="white--text" color="#F28080">ยกเลิกการแปรรูปวัตถุดิบ</v-chip>
              </div>
              <div class="text-left ml-8">{{ detail.user_approve_name }}</div>
              <div class="text-center ml-5">
                <v-menu rounded="b-xl" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="f-14" style="color: #6c7fd8" @click="linkPage('/prm/production_confirm/'+detail.id+'/0')">
                        <v-icon left style="color:#6c7fd8">mdi-alert-circle-outline</v-icon>
                        ดูรายละเอียด
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="detail.status_process <= 5">
                      <v-list-item-title class="f-14" style="color:#FEA06C" @click="linkPage('/prm/processing_steps/'+detail.id)">
                        <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                        แก้ไขข้อมูล
                      </v-list-item-title>
                    </v-list-item>
                    <span v-if="$store.state.profileDetail.per_process_cancel == 1 || $store.state.profileDetail.permission == 1">
                      <v-list-item v-if="detail.status_process <= 3">
                        <v-list-item-title class="f-14" style="color:#F28080" @click="open_model_cancel(detail.id, detail.process_code)">
                          <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                          ลบ
                        </v-list-item-title>
                      </v-list-item>
                    </span>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-card>

          <div class="text-center mt-5">
            <v-pagination v-model="page" :length="pageSize" :total-visible="7" color="#FFBC15"></v-pagination>
          </div>

        </v-card-text>
      </v-card>
    </div>

    <v-dialog v-model="add_dialog" transition="dialog-top-transition" max-width="800" style="border-radius:20px">
     <v-form ref="form_cancel" @submit.prevent="getProcessCancel()">
       <v-card>
        <div class="d-flex justify-space-between">
          <v-card-title><b>ยกเลิกรายการแปรรูป</b></v-card-title>
          <v-btn type="button" icon class="ma-4" @click="add_dialog = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-card elevation="4" style="border-radius:20px">
            <v-card-title class="d-flex align-items-center">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/warning1.png">
              </v-avatar>
              <span class="ml-3 f-18">เหตุผลการยกเลิกรายการแปรรูป {{cancel_producttion_code}}</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" color="#686868"></v-divider>
              <div class="ma-4">
                <v-textarea color="#686868" placeholder="เหตุผลการยกเลิกการแปรรูป" hide-details="auto"  :rules="[v => !!v || 'กรุณากรอกข้อมูล']" outlined rounded required></v-textarea>
              </div>
            </v-card-text>
          </v-card>
          <div class="mt-3 text-center">
            <v-btn type="submit" class="white--text" color="#88B358" elevation="4" rounded large @click="getProcessCancel()">
              <v-icon left>mdi-content-save</v-icon> บันทึก
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
     </v-form>
    </v-dialog>

  </div>
</template>

<script>
import process from '@/api/process/meterial_process.js'
import Swal from "sweetalert2";
export default {
  name: "ProcessingMaterials",
  data() {
    return {
      search: '',
      page: 1,
      pageSize: 1,
      add_dialog: false,
      loading_page: false,
      dataList: [],
      cancel_id: null,
      cancel_status_process: null,
      cancel_producttion_code: null,
    }
  },
  methods: {
    linkToCreate() {
      this.$router.push('/prm/processing_create')
    },
    linkPage(link) {
      this.$router.push(link)
    },
     open_model_cancel(id, name){
      this.add_dialog = true
      this.cancel_id = id
      this.cancel_status_process = 7
      this.cancel_producttion_code = name;
    },
      async getProcessCancel() {

      if (this.$refs.form_cancel.validate()) {

        let data = {
          id:this.cancel_id,
          status_process:this.cancel_status_process,
          cancel_reason:this.cancel_reason,
        };

        this.test = data

        process.getProcessCancel(data).then((response) => {

          if (response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                  this.add_dialog = false
                  // this.$router.push('/pro/processing_product_good/')
                   window.location.reload();

                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.add_dialog = false
                // this.$router.push('/prm/processing_materials')
                window.location.reload();

              }
            });
          }
        })
      }
      },
    async getProcessingList() {
     
     await process.getProcessingList(this.page,'', this.search).then((response) => {
      if(response.status == 502){
        window.location.reload()
      }
        this.loading_page = false
        if (response.data.successful == true) {
          this.dataList = response.data.data
          this.pageSize = response.data.pages
          this.page = parseFloat(response.data.currentPage)
        }else{
          this.loading_page = true
        }
      })
    },
  },
  mounted() {
    this.getProcessingList()
  },
  watch: {
      page() {
          this.getProcessingList()
      }
  },
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}
.search-input {
  width: 25%;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 1% 14% 15% 20% 20% 15% 15%;
  padding: 5px 0 5px 30px;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 1% 14% 15% 20% 20% 15% 15%;
  align-items: center;
}
.w-border-pre-black {
  height: 67px;
  background: #34230F;
  border: 1px solid #34230F;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}
.w-border-pre-yellow {
  height: 67px;
  background: #FFBC15;
  border: 1px solid #FFBC15;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}
.v-progress-linear--rounded {
  border-radius: 20px;
}
.progress-color {
  border: 1px solid #3f9629;
}
.grid-msg {
  display: grid;
  grid-template-columns: 100%;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
</style>