<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">แก้ไขข้อมูลใบสั่งซื้อ</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-form ref="form" @submit.prevent="materialEdit()">
          <v-card class="card-radius" elevation="4">
            <v-card-title>
              <p class="team-text-title-card">
                แก้ไขรายการสั่งซื้อเลขที่ {{ header_detail.po_code }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-food-variant </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">กรุณาเลือกวัตถุดิบที่ต้องการซื้อ</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                      <v-form-group>
                        <div class="d-flex">
                          <v-checkbox label="เลือกทั้งหมด" hide-details="auto" color="#FFBC15" v-model="check_all"
                            @change="checkAll()"></v-checkbox>
                        </div>
                      </v-form-group>
                    </v-col>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                      <div class="table-header-import-data display-pc mt-3" style="color: #b1adad; padding-left: 10px">
                        <div class="table-header-import-data-text">#</div>
                        <div class="table-header-import-data-text">
                          วัตถุดิบ
                        </div>
                        <div class="table-header-import-data-text text-center">
                          คงเหลือ
                        </div>
                        <div class="table-header-import-data-text text-center">
                          ซื้อ
                        </div>
                        <div class="table-header-import-data-text text-center">
                          ราคาต่อหน่วย
                        </div>
                        <div class="table-header-import-data-text text-center">
                          ราคารวม
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

                <div v-for="(detail, index) in data" :key="index">
                  <v-card-title class="padding-text-title align-items-center" style="
                      background: rgba(255, 188, 21, 0.1);
                      border: 1px solid #ffbc15;
                      border-radius: 20px;
                      margin: 0 15px 15px 15px;
                    ">
                    <span class="ml-3 text-font-title" style="font-size: 14px">{{ detail.typeName }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-card elevation="0" class="card-table-radius" v-for="(mat_detail, index_list) in detail.List"
                      :key="index_list">
                      <div class="grid-import-items">
                        <div class="d-flex justify-center">
                          <v-checkbox class="ma-0 pa-0" v-model="mat_detail.checkbox" hide-details="auto"
                            color="#FFBC15"></v-checkbox>
                        </div>
                        <div>{{ mat_detail.mat_name }}</div>
                        <div class="text-center">
                          {{ formatPrice(mat_detail.mat_instock) }}
                        </div>
                        <div class="text-center" style="padding: 10px">
                          <div class="d-flex">
                            <v-autocomplete style="border-radius: 20px 0 0 20px" :items="mat_detail.unit_other != null ? [
                              {
                                id: mat_detail.unit_main_id,
                                name: mat_detail.unit_main,
                                price: mat_detail.unit_other_qty,
                                retail_price: mat_detail.retail_price,
                                type: 'main',
                              },
                              {
                                id: mat_detail.unit_other_id,
                                name: mat_detail.unit_other,
                                price: mat_detail.unit_other_qty,
                                retail_price: mat_detail.other_price,
                                type: 'other',
                              },
                            ]:
                            [
                              {
                                id: mat_detail.unit_main_id,
                                name: mat_detail.unit_main,
                                price: mat_detail.unit_other_qty,
                                retail_price: mat_detail.retail_price,
                                type: 'main',
                              },
                            ]
                            " color="#FFBC15" label="จำนวนที่ต้องการซื้อ" :rules="[(v) => !!v || 'กรุณาเลือกจำนวน']"
                              hide-details="auto" item-text="name" return-object v-model="mat_detail.unit" dense outlined
                              required @change="calculate(mat_detail.unit, index_list, index)">
                            </v-autocomplete>

                            <v-text-field class="input-radius" color="#FFBC15" style="border-radius: 0 20px 20px 0"
                              :rules="[(v) => !!v.replace(/,/g, '') || 'กรุณากรอกจำนวน']" v-model="mat_detail.qty"
                              @keypress="
                                onlyForCurrency($event, mat_detail.qty)"
                              @input="calculate_input(index_list, index), formatInput(index, index_list)"
                              hide-details="auto" dense outlined required>
                            </v-text-field>
                          </div>
                        </div>
                        <div class="text-center">
                          {{
                            mat_detail.unit
                            ? formatPrice(mat_detail.unit.retail_price)
                            : (0).toFixed(2)
                          }}
                        </div>
                        <div class="text-center">
                          {{
                            formatPrice(
                              parseFloat(mat_detail.unit.price) *
                              (mat_detail.qty == '' ? 0 : parseFloat(mat_detail.qty.replace(/,/g, '')))
                            )
                          }}
                        </div>
                      </div>
                    </v-card>
                    <div class="d-flex justify-end" v-if="data.length - 1 == index">
                      <div class="d-flex justify-space-between" style="
                          padding: 20px;
                          background: rgba(136, 179, 88, 0.15);
                          border: 1px solid #88b358;
                          border-radius: 15px;
                        ">
                        <h2 style="color: #000; margin-right: 100px">
                          รวมเงิน
                        </h2>
                        <h2 style="color: #88b358">
                          {{ formatPrice(total_price_) }}
                        </h2>
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </v-card-text>
            <div class="mt-3 div-submit-data">
              <v-btn type="submit" class="button-submit-data mr-1" color="#88B358" elevation="4" rounded large>
                <v-icon left>mdi-content-save</v-icon> บันทึก
              </v-btn>
              <v-btn type="button" class="button-cancel-data ml-1" elevation="4" rounded large
                style="color: #f28080; border: 1px #f28080 solid" @click="open_cancel_order = true">
                <v-icon color="#F28080" left>mdi-trash-can</v-icon> ยกเลิก
              </v-btn>
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <!-- ยกเลิกใบสั่งซื้อ -->
    <v-dialog v-model="open_cancel_order" transition="dialog-top-transition" max-width="667" style="border-radius: 20px">
      <v-card>
        <div class="d-flex justify-space-between">
          <v-card-title>
            <b>ยกเลิกใบสั่งซื้อ</b>
          </v-card-title>
          <v-btn type="button" icon class="ma-4" @click="open_cancel_order = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form ref="form_cancel" @submit.prevent="materialCancel">
          <v-card-text>
            <v-card elevation="4" style="border-radius: 20px">
              <v-card-title class="d-flex align-items-center">
                <v-avatar color="#34230F" size="32">
                  <v-icon dark> mdi-alert-circle </v-icon>
                </v-avatar>
                <span class="ml-3 f-18">เหตุผลการยกเลิกใบสั่งซื้อ</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <v-row style="padding: 15px 70px 0 70px !important">
                  <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <v-form-group>
                      <v-textarea class="input-radius" label="เหตุผลการยกเลิกใบสั่งซื้อ" hide-details="auto"
                        color="#686868" dense outlined rounded rows="8" row-height="25" :rules="[
                          (v) => !!v || 'กรุณากรอกเหตุผลการยกเลิกใบสั่งซื้อ',
                        ]" v-model="note">
                      </v-textarea>
                    </v-form-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <div class="mt-3 div-submit-data">
              <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                <v-icon left>mdi-content-save</v-icon> บันทึก
              </v-btn>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import order from "@/api/order/order.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading_page: true,
      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "ใบสั่งซื้อ",
          disabled: false,
          href: '/stock/order-list',
        },
        {
          text: "แก้ไขข้อมูลใบสั่งซื้อ",
          disabled: false,
          href: "#",
        },
      ],
      check_all: false,
      header_detail: null,
      data: [],
      open_cancel_order: false,
      note: "",
      total_price_: 0,
    };
  },

  methods: {
    formatInput(index, i) {
      this.loading_page = true;
      if (this.data[index].List[i].qty.length > 3) {
        this.data[index].List[i].qty = this.data[index].List[i].qty.replace(/,/g, '');
        this.data[index].List[i].qty = this.data[index].List[i].qty.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      this.loading_page = false;
    },
    link(link) {
      this.$router.push(link);
    },
    onlyForCurrency: function (evt, number) {
      return onlyForCurrency(evt, number);
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    async checkAll() {
      this.data.forEach((detail) => {
        detail.List.forEach((detail_unit) => {
          detail_unit.checkbox = false;
        });
      });
      if (this.check_all) {
        this.data.forEach((detail) => {
          detail.List.forEach((detail_unit) => {
            detail_unit.checkbox = true;
          });
        });
      }
    },
    async materialBeforeAdd() {
      this.loading_page = true;
      this.header_detail = null;
      this.data = [];
      const response = await order.materialBeforeAdd();
      if (response.data.successful == true) {
        this.data = response.data.list;
        this.data.forEach((detail) => {
          detail.List.forEach((detail_unit) => {
            detail_unit.checkbox = false;
            detail_unit.qty = "0.00";
            detail_unit.unit = {
              id: detail_unit.unit_main_id,
              name: detail_unit.unit_main,
              price: this.formatPrice(detail_unit.retail_price),
              retail_price: detail_unit.retail_price,
              type: "main",
            };
          });
        });
        console.log(this.data);
      }

      const response_detail = await order.materialDetail(this.$route.params.id);
      if (response_detail.data.successful == true) {
        this.header_detail = response_detail.data.data.detail;
        const data_material = response_detail.data.data.list;
        this.data.forEach((detail) => {
          detail.List.forEach((detail_unit) => {
            data_material.forEach((detail_material) => {
              detail_material.List.forEach((detail_unit_material) => {
                if (detail_unit.id == detail_unit_material.material_id) {
                  detail_unit.edit_id = detail_unit_material.id;
                  detail_unit.checkbox = true;
                  detail_unit.qty = formatPrice(detail_unit_material.qty);
                  if (
                    detail_unit.unit_main_id == detail_unit_material.unit_id
                  ) {
                    detail_unit.unit = {
                      id: detail_unit.unit_main_id,
                      name: detail_unit.unit_main,
                      price: detail_unit.retail_price,
                      retail_price: detail_unit.retail_price,
                      type: "main",
                    };
                  }
                  if (
                    detail_unit.unit_other_id == detail_unit_material.unit_id
                  ) {
                    detail_unit.unit = {
                      id: detail_unit.unit_other_id,
                      name: detail_unit.unit_other,
                      price: detail_unit.other_price,
                      retail_price: detail_unit.retail_price,
                      type: "other",
                    };
                  }
                }
              });
            });
          });
        });
        this.total_price();
        this.loading_page = false;
      }
    },
    async materialEdit() {
      if (this.$refs.form.validate()) {
        let po_list = [];
        let total_price = 0;
        let status = 0;
        this.data.forEach((all) => {
          if (all.List.length > 0) {
            all.List.forEach((detail) => {
              if (detail.checkbox == true) {
                if (parseFloat(detail.qty.replace(/,/g, '')) == 0) {
                  let timerInterval;
                  Swal.fire({
                    title: "คำเตือน",
                    icon: "error",
                    html: "กรุณาระบุจำที่ต้องการสั่งด้วยค่ะ.",
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      const b = Swal.getHtmlContainer().querySelector("b");
                      timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    },
                  });
                } else {
                  if (detail.edit_id) {
                    po_list.push({
                      id: detail.edit_id,
                      po_id: parseFloat(this.$route.params.id),
                      material_id: detail.id,
                      sup_id: detail.supplier_id,
                      qty: parseFloat((detail.qty + '').replace(/,/g, '')),
                      unit_id: detail.unit.id,
                      price: parseFloat((detail.unit.retail_price + '').replace(/,/g, '')),
                      total_price:
                        parseFloat((detail.unit.retail_price + '').replace(/,/g, '')) * parseFloat((detail.qty + '').replace(/,/g, '')),
                    });
                  } else {
                    po_list.push({
                      po_id: parseFloat(this.$route.params.id),
                      material_id: detail.id,
                      sup_id: detail.supplier_id,
                      qty: parseFloat((detail.qty + '').replace(/,/g, '')),
                      unit_id: detail.unit.id,
                      price: parseFloat((detail.unit.retail_price + '').replace(/,/g, '')),
                      total_price:
                        parseFloat((detail.unit.retail_price + '').replace(/,/g, '')) * parseFloat((detail.qty + '').replace(/,/g, '')),
                    });
                  }
                  total_price +=
                    parseFloat((detail.unit.retail_price + '').replace(/,/g, '')) * parseFloat((detail.qty + '').replace(/,/g, ''));
                }
              } else {
                if (detail.checkbox == false) {
                  if (detail.edit_id) {
                    po_list.push({
                      id: detail.edit_id,
                      po_id: parseFloat(this.$route.params.id),
                      material_id: detail.id,
                      sup_id: detail.supplier_id,
                      qty: parseFloat(detail.qty.replace(/,/g, '')),
                      unit_id: detail.unit.id,
                      price: parseFloat(detail.unit.retail_price.replace(/,/g, '')),
                      total_price:
                        parseFloat(detail.unit.retail_price.replace(/,/g, '')) * parseFloat(detail.qty.replace(/,/g, '')),
                      is_delete: 1
                    });
                  }
                }
              }
            });
          }
        });
        if (status == 0) {
          if (po_list.length > 0) {
            const data = {
              po_list: po_list,
              total_price: total_price,
            };

            const response = await order.materialEdit(
              this.$route.params.id,
              data
            );
            if (response.data.successful == true) {
              let timerInterval;
              Swal.fire({
                title: "ทำรายการเสร็จสิ้น",
                icon: "success",
                html: "ระบบได้แก้ไขข้อมูลเรียบร้อยแล้ว.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const b = Swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  this.$router.push("/stock/order-list");
                }
              });
            }
          }
        } else {
          let timerInterval;
          Swal.fire({
            title: "คำเตือน",
            icon: "error",
            html: "กรุณาทำเครื่องหมายหน้ารายการที่ต้องการกรอกจำนวน",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
      }
    },
    async materialCancel() {
      if (this.$refs.form_cancel.validate()) {
        const data = {
          note: this.note,
        };
        const response = await order.materialCancel(
          this.$route.params.id,
          data
        );
        if (response.data.successful == true) {
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ยกเลิกข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push('/stock/order-list/detail/' + this.$route.params.id)
            }
          });
        }
      }
    },
    calculate_input(i = null, index = null) {
      console.log(i, index);
      this.loading_page = true
      //      this.total_price = 0;
      //     let sum = 0;
      //     for(let i =0; i < this.data.length; i++){
      //       for(let x =0; x < this.data[i].List.length; x++){
      //           sum += (this.data[i].List[x].qty * this.data[i].List[x].retail_price);
      //       }
      //     }
      // this.total_price = sum;
      this.total_price();
      this.loading_page = false
    },
    calculate(unit, i = null, index = null) {
      this.loading_page = true;
      if (unit != null) {
        switch (unit.type) {
          case 'main':
            this.data[index].List[i].qty = (parseFloat(unit.price) * parseFloat(this.data[index].List[i].qty.replace(/,/g, '')) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            this.data[index].List[i].qty = this.data[index].List[i].qty = parseFloat(this.data[index].List[i].qty).toFixed(2);

            break;
          case 'other':
            this.data[index].List[i].qty = (parseFloat(this.data[index].List[i].qty.replace(/,/g, '')) / parseFloat(unit.price) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            this.data[index].List[i].qty = this.data[index].List[i].qty = parseFloat(this.data[index].List[i].qty).toFixed(2);

            break;
        }
        this.total_price();
        // let sum = 0;
        // for(let i =0; i < this.data.length; i++){
        //   for(let x =0; x < this.data[i].List.length; x++){
        //       sum += (this.data[i].List[x].qty * this.data[i].List[x].retail_price);
        //   }
        // }
        // this.total_price = sum;
        this.loading_page = false;
      }
    },
    total_price() {
      let price = 0;
      this.data.forEach((all) => {
        all.List.forEach((detail) => {
          if (detail.checkbox === true) {
            price += parseFloat(detail.unit.retail_price) * parseFloat(detail.qty == null || detail.qty == '' ? '0' : detail.qty.replace(/,/g, ''));
          }
        });
      });
      this.total_price_ = parseFloat(price);
    },
  },
  mounted() {
    this.materialBeforeAdd();
  },
};
</script>
<style scoped>
.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 22px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-import-data {
  display: grid;
  grid-template-columns: 5% 21% 17% 28% 16% 13%;
  color: #686868 !important;
}

.table-header-import-data-text {
  color: #686868;
  font-size: 14px;
}

.grid-import-items {
  display: grid;
  grid-template-columns: 5% 21% 17% 28% 16% 13%;
  align-items: center;
}
</style>