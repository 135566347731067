<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page"> {{ meterial_data.pq_name_eng }} {{meterial_data.pq_name_thai}} </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12">
                                <b>รายงานการตรวจสอบคุณภาพการผลิต หัวข้อ {{ meterial_data.pq_name_eng }} {{meterial_data.pq_name_thai}} </b>
                            </v-col>

                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-autocomplete color="#D9D9D9" class="input-radius-search mt-3" :items="selectbox" item-text="name" item-value="id"
                                    prepend-inner-icon="mdi-magnify" placeholder="ค้นหาที่นี่..." hide-details="auto" v-model="item_id"
                                    single-line outlined dense></v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                <v-menu v-model="menu2" :close-on-content-click="false" class="input-radius-search mt-3"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="start_date" label="วัน/เดือน/ปี เริ่มต้น" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto" class="mt-3"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี เริ่มต้น']" v-bind="attrs" dense outlined readonly
                          rounded required v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="start_date" @input="menu2 = false" @change="getDashboardExportHistory()"  no-title scrollable></v-date-picker>
                    </v-menu>
                            </v-col>
                            <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                               <v-menu v-model="menu2" :close-on-content-click="false" class="input-radius-search mt-3"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="end_date" label="วัน/เดือน/ปี สิ้นสุด" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto" class="mt-3"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี เริ่มต้น']" v-bind="attrs" dense outlined readonly
                          rounded required v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="end_date" @input="menu2 = false" @change="getDashboardExportHistory()"  no-title scrollable></v-date-picker>
                    </v-menu>
                            </v-col>

                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" class="card-radius pt-0">

                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th rowspan="2">วันที่ตรวจสอบ</th>
                                            <th rowspan="2">ชื่อสินค้า</th>
                                            <th rowspan="2">Lot</th>

                                            <th colspan="5" class="text-center"
                                                style="border-bottom: 1px solid #cdcdcd;height: 40px ;"
                                               >
                                                {{ meterial_data.pq_name_eng }} {{ meterial_data.pq_name_eng }}
                                            </th>

                                        </tr>

                                        <tr>
                                            <th class="text-center white-s" style="height: 40px;"
                                                v-for="(header) in meterial_header" :key="header">
                                                {{ header.pq_list_eng }}
                                            </th>
                                        </tr>


                                    </thead>

                                    <tbody>
                                        <tr v-for="(dataDetail, index) in List" :key="index" style="white-space: nowrap;">
                                            <td style="border-bottom: 0px" class="white-s">
                                                <div>
                                                    {{
                                                        `${dataDetail.created_at.split('-')[2].split(' ')[0]}-${dataDetail.created_at.split('-')[1]}-${dataDetail.created_at.split('-')[0]}`
                                                    }}
                                                </div>
                                            </td>
                                            <td>{{ dataDetail.item_name }}</td>
                                            <td style="border-bottom: 0px" class="white-s">
                                                <div>
                                                    {{ dataDetail.item_lot }}
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon color="#000000" dark v-bind="attrs" v-on="on">
                                                                mdi-information-variant-circle
                                                            </v-icon>
                                                        </template>
                                                        <v-row style="width: 270px;padding: 10px 0;">
                                                            <v-col cols="12 py-1 px-1">ผู้ดำเนินการ : 
                                                                {{ dataDetail.name == null ? '-' : dataDetail.name }}</v-col>
                                                        </v-row>
                                                    </v-tooltip>
                                                </div>
                                            </td>

                                            <td v-for="(dataDetail_sub, index_sub) in dataDetail.pq_list"
                                                :key="index_sub" class="text-center " style="border-bottom: 0px">
                                                <div class=""
                                                    v-if="dataDetail_sub.pq_list_type == 0 && dataDetail_sub.value == '1'">
                                                    <v-icon color="#88B358">mdi-check-circle</v-icon>
                                                </div>
                                                <div class=""
                                                    v-if="dataDetail_sub.pq_list_type == 0 && dataDetail_sub.value == '0'">
                                                    <v-icon color="#F28080">mdi-close-circle</v-icon>
                                                </div>
                                                <div class="" v-if="dataDetail_sub.pq_list_type == 1">
                                                    {{ dataDetail_sub.value }}
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>


                            <div class="text-center mt-5">
                                <v-pagination v-model="model" :length="1" :total-visible="7" color="#FFBC15"></v-pagination>
                            </div>
                        </v-card>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import report from '@/api/reportQuality/quality_quality.js';
// import { watch } from 'vue';
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'รายงานการตรวจสอบคุณภาพ',
                    disabled: false,
                    href: '/stock/quality-inspection-production-report-list',
                },
                {
                    text: 'รายงานการตรวจสอบกระบวนการผลิต',
                    disabled: false,
                    href: '/stock/quality-inspection-production-report-list',
                },
                {
                    text: '',
                    disabled: false,
                    href: '#',
                },
            ],
            headers: [
                {
                    text: 'วันที่ตรวจสอบ',
                    align: 'start',
                    sortable: false,
                    value: '',
                    width: '150px',
                },
                {
                    text: 'LOT',
                    align: 'start',
                    sortable: false,
                    value: '',
                    width: '200px',
                    class: 'fixed_header'
                },
                {
                    text: 'สี',
                    align: 'start',
                    sortable: false,
                    width: '100px',
                    value: '',
                },
                {
                    text: 'กลิ่น',
                    align: 'start',
                    sortable: false,
                    width: '100px',
                    value: '',
                },
                {
                    text: 'ความสะอาดไม่มีสิ่งปลอมปน',
                    align: 'center',
                    sortable: false,
                    width: '170px',
                    value: '',
                },
                {
                    text: 'น้ำหนัก (จำนวน)',
                    align: 'center',
                    sortable: false,
                    width: '150px',
                    value: '',
                },
                {
                    text: 'COA',
                    align: 'center',
                    sortable: false,
                    width: '100px',
                    value: '',
                },


            ],
            header_data: [
                {
                    header_one: "ตรวจสอบคุณภาพฉลากและสติ๊กเกอร์",
                    header_one_sub: [
                        {
                            header_one_sub_detail: "จำนวน"
                        },
                        {
                            header_one_sub_detail: "จำนวน"
                        },
                        {
                            header_one_sub_detail: "จำนวน"
                        }
                    ]
                }
            ],
            data_list: [
                {
                    date: '05/09/2022',
                    lot: '3-202209051354',
                    data_sub: [
                        {
                            data_check: 1,
                        },
                        {
                            data_check: 'ปกติ',
                        },
                        {
                            data_check: 1,
                        },
                        {
                            data_check: 1,
                        },
                        {
                            data_check: 1,
                        },
                        {
                            data_check: 1,
                        },
                        {
                            data_check: 'ปกติ',
                        },
                        {
                            data_check: 1,
                        },
                        {
                            data_check: 1,
                        },
                        {
                            data_check: 1,
                        },

                    ],
                },

            ],
            data_date: [
                {
                    id: 2023,
                    value: 'ปี 2023',
                },
                {
                    id: 2022,
                    value: 'ปี 2022',
                },
                {
                    id: 2021,
                    value: 'ปี 2021',
                },
            ],
            data_month: [
                {
                    id: 1,
                    value: 'เดือน มกราคม',
                    month_id: '01',
                },
                {
                    id: 2,
                    value: 'เดือน กุมภาพันธ์',
                    month_id: '02',
                },
                {
                    id: 3,
                    value: 'เดือน มีนาคม',
                    month_id: '03',
                },
                {
                    id: 4,
                    value: 'เดือน เมษายน',
                    month_id: '04',
                },
                {
                    id: 5,
                    value: 'เดือน พฤษภาคม',
                    month_id: '05',
                },
                {
                    id: 6,
                    value: 'เดือน มิถุนายน',
                    month_id: '06',
                },
                {
                    id: 7,
                    value: 'เดือน กรกฏาคม',
                    month_id: '07',
                },
                {
                    id: 8,
                    value: 'เดือน สิงหาคม',
                    month_id: '08',
                },
                {
                    id: 9,
                    value: 'เดือน กันยายน',
                    month_id: '09',
                },
                {
                    id: 10,
                    value: 'เดือน ตุลาคม',
                    month_id: '10',
                },
                {
                    id: 11,
                    value: 'เดือน พฤศจิกายน',
                    month_id: '11',
                },
                {
                    id: 12,
                    value: 'เดือน ธันวาคม',
                    month_id: '12',
                },
            ],
            year: parseFloat(new Date().getUTCFullYear()),
            month: parseFloat(new Date().getMonth()) + 1,
            meterial_data: [],
            meterial_header: [],
            selectbox: [{
                id: 0,
                name: 'ทั้งหมด',
                type_mat_or_pro: 0
            }],
            start_date: new Date().toISOString().substr(0, 10),
            end_date: new Date().toISOString().substr(0, 10),
            item_id: 0,
            List:[]
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        getIndex(index) {
            alert(index);
        },
       async getDetail() {
        let data = this.selectbox.find(o => o.id === this.item_id)
            let id = this.$route.query.id
            await report.getDetail(id,this.item_id, this.start_date, this.end_date, data== null ? 0 : data.type_mat_or_pro).then((response) => {
                if (response.data.successful === true) {
                    this.meterial_data = response.data.fq;
                    this.sub_menu[3].text = this.meterial_data.pq_name_eng+' '+this.meterial_data.pq_name_thai
                    this.List = response.data.list;
                    // this.sub_menu[2].text = this.meterial_data.pro_name;
                    this.meterial_header.splice(0);
                    for (let index = 0; index < this.meterial_data.pq_list.length; index++) {
                            this.meterial_header.push({pq_list_eng: this.meterial_data.pq_list[index].pq_list_eng+' '+this.meterial_data.pq_list[index].pq_list_thai});
                    }
                }
            })
        },
       async getSelectBox() {
            let fq_type = parseFloat(this.$route.query.fq_type);
            let id = parseFloat(this.$route.query.id);
           await report.getReport(id, fq_type).then((response) => {
                if (response.data.successful === true) {
                   let data = response.data.data
                   data.forEach(item => {
                        this.selectbox.push({
                            id: item.item_id,
                            name: item.name,
                            type_mat_or_pro: item.type_mat_or_pro
                        })
                   });
                }
            })
        }
    },
    mounted() {
        this.getSelectBox();
        this.getDetail();
    },
    components: {
        navigationmenu
    },
    watch:{
        item_id(){
            this.getDetail()
        },
        start_date(){
            this.getDetail()
        },
        end_date(){
            this.getDetail()
        },
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.tooltip-style {
    width: 400px;
    border-radius: 10px;
    background-color: #34230F;
    margin-left: -15px;
    margin-right: -15px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.left-fixed-columns .v-data-table__wrapper {
    overflow-x: auto;
    position: relative;
}

.left-fixed-columns .v-data-table__wrapper table {
    position: relative;
}


.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.table-row {
    display: table-row;
}

.table-header {
    font-weight: bold;
}

.table-cell {
    display: table-cell;
    padding: 8px;
    border-bottom: 1px solid #cdcdcd;
}

.text-center {
    text-align: center;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltip-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border: 1px solid #cdcdcd;
    border-radius: 50%;
    margin-left: 5px;
    font-style: normal;
}

.tooltip .tooltip-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 220px;
    background-color: #f9f9f9;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    padding: 8px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

.icon-check {
    color: #88B358;
}

.icon-cross {
    color: #F28080;
}

.scroll-table {
    white-space: nowrap;
    overflow-x: scroll;
}

.white-s {
    white-space: nowrap;
}
</style>