<template>
    <div>
        <CustomerPage />
    </div>
</template>
  
<script>
import CustomerPage from '@/components/sale/CustomerComponent'
export default {
    name: 'Customer',
    components: {
        CustomerPage,
    }
}
</script>