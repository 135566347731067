<template>
    <div>
        <SetRawMaterialDetailPage />
    </div>
</template>
  
<script>
import SetRawMaterialDetailPage from '@/components/stock/setting_stock/SetRawMaterialDetail'
export default {
    name: 'SetRawMaterialDetail',
    components: {
        SetRawMaterialDetailPage,
    }
}
</script>