<template>
  <div>
    <div class="loading_all_page" v-if="loading_page === true">
      <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
      <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
      }}</v-alert>
      <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
    </v-slide-x-reverse-transition>
    <!-- Desktop -->
    <div class="nav-header-desktop">
      <div class="top-menu">
        <div class="d-flex">
          <img class="logo-menu" :src="imagePreviewURL === null ? '@/assets/Rectangle 9.png' : imagePreviewURL ">
          <div class="title-menu">
            <div class="title-company-menu">{{company.name}}</div>
            <div class="title-company-email-menu">{{company.email}}</div>
          </div>
        </div>
        <div class="d-flex">
          <img class="logo-menu-user" src="@/assets/Rectangle 17.png">
          <v-menu open-on-hover offset-y rounded="b-xl">
            <template v-slot:activator="{ on, attrs }">
              <div class="title-menu" v-bind="attrs" v-on="on">
                <div class="title-company-menu">{{ profile_detail.name }} <v-icon>mdi-menu-down</v-icon>
                </div>
                <div class="title-company-email-menu">{{ profile_detail.permission == 1 ? 'ผู้ดูแลระบบ' :
                  'ทีมงาน' }}</div>
              </div>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title class="dropdown-header-menu-text" style="color:#FEA06C" @click="profile">
                  <v-icon style="color:#FEA06C">mdi-account-edit</v-icon>
                  จัดการข้อมูลส่วนตัว
                </v-list-item-title>

              </v-list-item>
              <v-list-item link>
                <v-list-item-title class="dropdown-header-menu-text" style="color:#F28080" @click="logout"><v-icon
                    style="color:#F28080">mdi-power</v-icon>
                  ออกจากระบบ</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="nav-header-menu-desktop">
        <v-app-bar elevation="0" color="#FFFFFF">

          <v-toolbar-title class="mr-3 menu-bar link-menu" @click="link('/dashboard')" v-if="profile_detail.per_dashboard == 1 || $store.state.profileDetail.permission == 1"
            :class="{ 'active': $route.path.substring(1).split('/')[0] == 'dashboard' }">
            <span class="nav-item-title"><v-icon>mdi-chart-line</v-icon>
              Dashboard</span>
          </v-toolbar-title>

          <!-- <router-link class="link-menu" to="/dashboard" v-if="profile_detail.per_dashboard == 1 || $store.state.profileDetail.permission == 1" exact>
          </router-link> -->

          <template v-if="$store.state.profileDetail.per_process_add == 1 || $store.state.profileDetail.per_process_cancel == 1 || $store.state.profileDetail.per_production == 1 || $store.state.profileDetail.per_production_cancel == 1 || $store.state.profileDetail.per_pack_add == 1  || $store.state.profileDetail.per_pack_cancel == 1 || $store.state.profileDetail.permission == 1">
            <div class="text-center">
              <v-menu open-on-hover offset-y rounded>
                <template v-slot:activator="{ on, attrs }">
                  <div class="mr-3 menu-bar" v-bind="attrs" v-on="on"
                    :class="{ 'active': $route.path.substring(1).split('/')[0] == 'prm' || $route.path.substring(1).split('/')[0] == 'pro' || $route.path.substring(1).split('/')[0] == 'pac' }">
                    <span class="nav-item-title"><v-icon>mdi-robot-industrial-outline</v-icon>การผลิตและการแปรรูป</span>
                  </div>
                </template>
                <v-list>
                  <v-list-item link @click="link('/prm/processing_materials')" v-if="($store.state.profileDetail.per_process_add == 1 || $store.state.profileDetail.per_process_cancel == 1) || $store.state.profileDetail.permission == 1">
                    <v-list-item-icon v-if="$route.path.substring(1).split('/')[0] == 'prm'">
                      <img class="icon-image-logo" src="@/assets/Rectangle171.png">
                    </v-list-item-icon>
                    <v-list-item-icon v-else>
                      <img class="icon-image-logo" src="@/assets/Rectangle172.png">
                    </v-list-item-icon>
                    <v-list-item-title class="nav-item-title"
                      :class="{ 'active-list': $route.path.substring(1).split('/')[0] == 'prm' }">การแปรรูปวัตถุดิบ</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="link('/pro/processing_product_good')"
                    v-if="($store.state.profileDetail.per_production == 1 || $store.state.profileDetail.per_production_cancel == 1) || $store.state.profileDetail.permission == 1">
                    <v-list-item-icon v-if="$route.path.substring(1).split('/')[0] == 'pro'">
                      <img class="icon-image-logo" src="@/assets/food-pack (1).png">
                    </v-list-item-icon>
                    <v-list-item-icon v-else>
                      <img class="icon-image-logo" src="@/assets/food-pack.png">
                    </v-list-item-icon>
                    <v-list-item-title class="nav-item-title"
                      :class="{ 'active-list': $route.path.substring(1).split('/')[0] == 'pro' }">การผลิตสินค้า</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="link('/pac/processing-packaging')" v-if="($store.state.profileDetail.per_pack_add == 1 || $store.state.profileDetail.per_pack_cancel == 1) || $store.state.profileDetail.permission == 1">
                    <v-list-item-icon v-if="$route.path.substring(1).split('/')[0] == 'pac'">
                      <img class="icon-image-logo" src="@/assets/package (2).png">
                    </v-list-item-icon>
                    <v-list-item-icon v-else>
                      <img class="icon-image-logo" src="@/assets/package.png">
                    </v-list-item-icon>
                    <v-list-item-title class="nav-item-title"
                      :class="{ 'active-list': $route.path.substring(1).split('/')[0] == 'pac' }">การบรรจุภัณฑ์</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <!--          <router-link class="link-menu" to="/dashboard" exact>-->
          <!--            <v-toolbar-title class="mr-3 menu-bar">-->
          <!--                            <span class="nav-item-title"><v-icon>mdi-robot-industrial-outline</v-icon>-->
          <!--                                 การผลิตและการแปรรูป</span>-->
          <!--            </v-toolbar-title>-->
          <!--          </router-link>-->

          <v-toolbar-title class="mr-3 menu-bar link-menu" @click="link('/stock/warehouse-stock/warehouse')"
            :class="{ 'active': $route.path.substring(1).split('/')[0] == 'stock' }">
            <span class="nav-item-title"><v-icon>mdi-package-variant-closed</v-icon>
              คลัง</span>
          </v-toolbar-title>

          <!-- <router-link class="link-menu" to="/stock/warehouse-stock/warehouse" exact>
          </router-link> -->

          <v-toolbar-title class="mr-3 menu-bar link-menu" @click="link('/sale/order-list')" 
            :class="{ 'active': $route.path.substring(1).split('/')[0] == 'sale' }">
            <span class="nav-item-title"><v-icon>mdi-hand-coin-outline</v-icon>
              การขาย</span>
          </v-toolbar-title>

          <!-- <router-link class="link-menu" to="/sale/order-list" exact >
          </router-link> -->

          <!-- <router-link class="link-menu" to="/accounting/accounting-list" exact>
            <v-toolbar-title class="mr-3 menu-bar"
                             :class="{ 'active': $route.path.substring(1).split('/')[0] == 'accounting' }">
                            <span class="nav-item-title"><v-icon>mdi-calculator</v-icon>
                                 บัญชี</span>
            </v-toolbar-title>
          </router-link> -->

          <v-toolbar-title class="mr-3 menu-bar link-menu" @click="link('/teams/teams')" v-if="profile_detail.per_user == 1 || $store.state.profileDetail.permission == 1"
            :class="{ 'active': $route.path.substring(1).split('/')[0] == 'teams' }">
            <span class="nav-item-title"><v-icon>mdi-account-multiple-plus-outline</v-icon>
              ทีมงาน</span>
          </v-toolbar-title>

          <!-- <router-link class="link-menu" to="/teams/teams" exact >
          </router-link> -->

            <v-toolbar-title class="mr-3 menu-bar link-menu" @click="link('/company-setting')" v-if="profile_detail.per_edit_company == 1 || $store.state.profileDetail.permission == 1"
              :class="{ 'active': $route.path.substring(1).split('/')[0] == 'company-setting' }">
              <span class="nav-item-title"><v-icon>mdi-domain</v-icon>
                ตั้งค่าข้อมูลบริษัท</span>
            </v-toolbar-title>

          <!-- <router-link class="link-menu" to="/company-setting" v-if="profile_detail.per_edit_company == 1 || $store.state.profileDetail.permission == 1" exact>
          </router-link> -->

        </v-app-bar>
      </div>
    </div>
    <!-- End Desktop -->
    <!-- IPAD -->
    <div class="nav-header-ipad">
      <v-app-bar color="#FFBC15" dark>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="yellow" v-bind="attrs" v-on="on">
                  <v-avatar>
                      <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
                  </v-avatar>
              </v-btn>
          </template> -->

          <v-list>
            <v-list-item link>
              <v-list-item-title style="color:#FEA06C" @click="profile">
                <v-icon style="color:#FEA06C">mdi-account-edit</v-icon>
                จัดการข้อมูลส่วนตัว
              </v-list-item-title>

            </v-list-item>
            <v-list-item link>
              <v-list-item-title style="color:#F28080" @click="logout"><v-icon style="color:#F28080">mdi-power</v-icon>
                ออกจากระบบ</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer app width="300px" v-model="drawer" color="#FFFFFF">
        <div class="logo_list_header_mobile">
          <div class="logo_menu_header_mobile">
            <img src="@/assets/Rectangle 9.png">
          </div>
        </div>
        <div class="menu_list_header_mobile">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel color="#FFBC15" @click="link('/dashboard')" v-if="profile_detail.per_dashboard == 1 || $store.state.profileDetail.permission == 1">
              <v-expansion-panel-header @click="panel = !panel">
                <div>Dashboard</div>
              </v-expansion-panel-header>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header @click="panel = !panel">
                <div>การผลิตและการแปรรูป</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content @click="link('/prm/processing_materials')">
                <a href="/prm/processing_materials">การแปรรูปวัตถุดิบ</a>
              </v-expansion-panel-content>
              <v-expansion-panel-content @click="link('/pro/processing_product_good')">
                <a href="/pro/processing_product_good">การผลิตสินค้า</a>
              </v-expansion-panel-content>
              <v-expansion-panel-content @click="link('/pac/processing-packaging')">
                <a href="/pac/processing-packaging">การบรรจุภัณฑ์</a>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel @click="link('/stock/setting-stock/unit')">
              <v-expansion-panel-header @click="panel = !panel">
                <div>คลัง</div>
              </v-expansion-panel-header>
            </v-expansion-panel>
            <v-expansion-panel @click="link('/sale/customer-list')">
              <v-expansion-panel-header @click="panel = !panel">
                <div>การขาย</div>
              </v-expansion-panel-header>
            </v-expansion-panel>
            <!-- <v-expansion-panel @click="link('/accounting/accounting-list')">
              <v-expansion-panel-header>
                <div>บัญชี</div>
              </v-expansion-panel-header>
            </v-expansion-panel> -->
            <v-expansion-panel @click="link('/teams/teams')" v-if="profile_detail.per_user == 1 || $store.state.profileDetail.permission == 1">
              <v-expansion-panel-header @click="panel = !panel">
                <div>ทีมงาน</div>
              </v-expansion-panel-header>
            </v-expansion-panel>
            <v-expansion-panel @click="link('/company-setting')">
              <v-expansion-panel-header @click="panel = !panel">
                <div>ตั้งค่าข้อมูลบริษัท</div>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-navigation-drawer>
    </div>
    <!-- END IPAD -->
    <v-dialog v-model="confirm_logout" transition="dialog-top-transition" max-width="383" style="border-radius:20px;">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="confirm_logout = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <center>
                <img src="@/assets/image 19.png">
                <h2 style="color:#000000">ออกจากระบบ</h2>
                <h3 class="mt-2">คุณต้องการออกจากระบบใช่หรือไม่</h3>
                <v-btn class="btn-logout" elevation="4" rounded x-large @click="logOutSuccess()">
                  <v-icon style="font-size:28px;" left>mdi-power</v-icon>
                  ออกจากระบบ
                </v-btn>
              </center>
              <div class="text-center mt-3">
                <v-btn style="font-size:16px;" text @click="confirm_logout = false">
                  ยกเลิก
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="profile_dialog" transition="dialog-top-transition" max-width="667" style="border-radius:20px;">
      <v-card>
        <div class="d-flex justify-space-between">
          <v-card-title>
            <b>จัดการข้อมูลส่วนตัว</b>
          </v-card-title>
          <v-btn type="button" icon class="ma-4" @click="profile_dialog = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-form ref="form" @submit.prevent="editProfile()">
            <v-card elevation="4" style="border-radius:20px;">
              <v-card-title class="d-flex align-items-center">
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>
                    mdi-account-edit
                  </v-icon>
                </v-avatar>
                <span class="ml-3">ข้อมูลส่วนตัว</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <v-row style="padding: 15px 70px 0 70px !important;">
                  <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <v-form-group>
                      <v-text-field type="text" class="input-radius" label="ชื่อ - นามสกุล"
                        v-model="profile_detail.name_edit" color="#686868"
                        :rules="[v => !!v || 'กรุณากรอกชื่อ - นามสกุล']"  outlined rounded
                        required></v-text-field>
                    </v-form-group>
                    <v-form-group>
                      <v-text-field type="tel" class="input-radius" label="เบอร์โทรศัพท์สำหรับเข้าสู่ระบบ"
                        v-model="profile_detail.tel_edit" value="0123456789" color="#686868" minlength="10" maxlength="10"
                        @keypress="isNumber($event, tel)" :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                         outlined rounded required></v-text-field>
                    </v-form-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mt-5" elevation="4" style="border-radius:20px;">
              <v-card-title class="d-flex align-items-center">
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>
                    mdi-lock
                  </v-icon>
                </v-avatar>
                <span class="ml-3">แก้ไขรหัสผ่าน</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <v-row style="padding: 15px 70px 0 70px !important;">
                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="6">
                    <v-form-group>
                      <v-text-field class="input-radius" color="#686868" label="รหัสผ่าน"
                        v-model="profile_detail.password_edit" outlined rounded placeholder="รหัสผ่าน" hide-details="auto"
                        required :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_password ? 'text' : 'password'"
                        @click:append="show_password = !show_password"></v-text-field>
                    </v-form-group>
                  </v-col>
                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="6">
                    <v-form-group>
                      <v-text-field class="input-radius" color="#686868" label="ยืนยันรหัสผ่าน"
                        v-model="profile_detail.confirm_password_edit" outlined rounded placeholder="ยืนยันรหัสผ่าน"
                        hide-details="auto" required :append-icon="show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_confirm_password ? 'text' : 'password'"
                        @click:append="show_confirm_password = !show_confirm_password"></v-text-field>
                    </v-form-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div class="mt-3 div-submit-data">
              <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                <v-icon left>mdi-content-save</v-icon> บันทึก
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- BEGIN: Main Menu-->
    <!-- เมนูคลัง -->
    <v-navigation-drawer v-if="$route.path.substring(1).split('/')[0] == 'stock'" class="main-menu main-menu-pc" permanent
      absolute style=" background-color:transparent !important;">
      <v-list-item class="px-2">
        <v-list-item-title class="text-center">เมนูคลัง</v-list-item-title>
      </v-list-item>

      <v-list rounded dense shaped>
        <v-list-item-group color="#FFBC15" >

          <!-- v-if="$store.state.profileDetail.per_inventory == 1 || $store.state.profileDetail.permission == 1 " -->
          <v-list-group color="#FFBC15"  no-action v-if="($store.state.profileDetail.per_warehouse == 1 || $store.state.profileDetail.per_warehouse_rawmaterial == 1) || $store.state.profileDetail.permission == 1">
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/Rectangle 73.png" alt="">
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> คลังสินค้า </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link @click="linklive('/stock/warehouse-stock/warehouse')" v-if="$store.state.profileDetail.per_warehouse == 1 || $store.state.profileDetail.permission == 1">
              <v-list-item-content>
                <v-list-item-title> คลัง </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="linklive('/stock/warehouse-stock/WarehouseRawmaterial')" v-if="$store.state.profileDetail.per_warehouse_rawmaterial == 1 || $store.state.profileDetail.permission == 1">
              <v-list-item-content>
                <v-list-item-title> รายการวัตถุดิบ/สินค้าที่นำออกจากคลัง</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item link @click="linklive('/stock/report-stock');" v-if="$store.state.profileDetail.per_stock_report == 1 || $store.state.profileDetail.permission == 1">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 160.png" alt="">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> รายงานคลัง</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="linklive('/stock/quality-inspection-report-list');"
            v-if="$store.state.profileDetail.per_stock_history == 1 || $store.state.profileDetail.permission == 1">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 159.png" alt="">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> รายงานการตรวจสอบคุณภาพ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="linklive('/stock/order-list')">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 75.png" alt="">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> ใบสั่งซื้อ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



          <v-list-item link @click="linklive('/stock/material-import')">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 74.png" alt="">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> นำเข้าวัตถุดิบ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="linklive('/stock/product-import')">
            <v-list-item-icon>
              <img src="@/assets/Rectangle172.png" alt="">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> นำเข้าสินค้า</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link @click="linklive('/stock/product-import-fg')">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 74.png" alt="">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> นำเข้าสินค้าสำเร็จ</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/Rectangle 24.png" alt="">
              </v-list-item-icon>
              <v-list-item-title>ตั้งค่าข้อมูลเกี่ยวกับคลัง</v-list-item-title>
            </template>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ตั้งค่าข้อมูลสินค้า</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link @click="linklive('/stock/setting-stock/product-type')">
                <v-list-item-title>- ตั้งค่าประเภทสินค้า</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="linklive('/stock/setting-stock/product-size')">
                <v-list-item-title>- ตั้งค่าขนาดสินค้า</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="linklive('/stock/setting-stock/set-product-component')">
                <v-list-item-title>- ตั้งสินค้า</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ตั้งค่าข้อมูลวัตถุดิบ</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link @click="linklive('/stock/setting-stock/supplier')"
                v-if="$store.state.profileDetail.per_supplier == 1 || $store.state.profileDetail.permission == 1">
                <v-list-item-title>- ซัพพลายเออร์</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="linklive('/stock/setting-stock/raw-material-type')">
                <v-list-item-title>- ตั้งค่ากลุ่มวัตถุดิบ</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="linklive('/stock/setting-stock/setting-raw-material')">
                <v-list-item-title>- ตั้งวัตถุดิบ</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>เกณฑ์การตรวจสอบ</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link @click="linklive('/stock/setting-stock/quality-inspection-criteria')">
                <v-list-item-title>-
                  เกณฑ์การตรวจสอบคุณภาพ</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="linklive('/stock/setting-stock/inspection-criteria-production')">
                <v-list-item-title>-
                  เกณฑ์การตรวจสอบการผลิต</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ตั้งค่าข้อมูลอื่น ๆ</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link @click="linklive('/stock/setting-stock/unit')">
                <v-list-item-title>- หน่วยนับ</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item link @click="link('/stock/setting-stock/transport-box')">
                <v-list-item-title>-
                  กล่องขนส่ง</v-list-item-title>
              </v-list-item> -->
            </v-list-group>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- สิ้นสุดเมนูคลัง -->
    <!-- เมนูการขาย -->
    <v-navigation-drawer v-if="$route.path.substring(1).split('/')[0] == 'sale'" class="main-menu main-menu-pc" permanent
      absolute style=" background-color:transparent !important;">
      <v-list-item class="px-2">
        <v-list-item-title class="text-center"> เมนูการขาย</v-list-item-title>
      </v-list-item>

      <v-list rounded dense shaped>
        <v-list-item-group color="#FFBC15" v-model="selectedItem">
          <v-list-item link @click="linklive('/sale/customer-list' , 0)"
            v-if="($store.state.profileDetail.per_sell_add_cus == 1 || $store.state.profileDetail.per_sell_edit_cus == 1) || $store.state.profileDetail.permission == 1 ">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 1559.png" alt="ข้อมูลลูกค้า">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> ข้อมูลลูกค้า</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="linklive('/sale/order-list' , 1)"  >
            <v-list-item-icon>
              <img src="@/assets/Rectangle 77.png" alt="จัดการคำสั่งซื้อ">
            </v-list-item-icon>

            <v-list-item-content >
              <v-list-item-title> จัดการคำสั่งซื้อ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- สิ้นสุดเมนูการขาย -->
    <!-- เมนูบัญชี -->
    <!-- <v-navigation-drawer v-if="$route.path.substring(1).split('/')[0] == 'accounting'" class="main-menu main-menu-pc"
                         permanent absolute style=" background-color:transparent !important;">
      <v-list-item class="px-2">
        <v-list-item-title class="text-center"> เมนูบัญชี</v-list-item-title>
      </v-list-item>

      <v-list rounded dense shaped>
        <v-list-item-group color="#FFBC15">
          <v-list-item link @click="link('/accounting/accounting-list')" v-if="profile_detail.per_account_report == 1">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 75 (2).png" alt="รายงานบัญชี">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> รายงานบัญชี</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="link('/accounting/accounting-save')" v-if="profile_detail.per_account == 1">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 77.png" alt="บันทึกบัญชี">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> บันทึกบัญชี</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="link('/accounting/accounting-setting')" v-if="profile_detail.per_account_setting == 1">
            <v-list-item-icon>
              <img src="@/assets/Rectangle 90.png" alt="ตั้งค่าประเภทบัญชี">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> ตั้งค่าประเภทบัญชี</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
    <!-- สิ้นสุดเมนูบัญชี -->
    <!-- END: Main Menu-->
  </div>
</template>

<script>
import { isTelephone } from "@/helper/handler";
// import Swal from "sweetalert2";
// import profile from '@/api/user_profile/profile.js'
import company from '@/api/company/company.js'
import users from '@/api/users/users.js'
export default {
  data() {
    return {
      loading_page: false,
      alert_success: false,
      alert_message_success: '',
      alert_error: false,
      alert_message_error: '',
      confirm_logout: false,
      profile_dialog: false,
      show_password: false,
      show_confirm_password: false,
      selectedItem: 1,
      selectedstock: 1,
      imagePreviewURL: null,
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
      company:{},
      profile_detail: {
        id: null,
        name: '',
        name_edit: '',
        tel_edit: '',
        password_edit: '',
        confirm_password_edit: '',
        permission: null,
        per_dashboard: null,
        per_user: null,
        per_account: null,
        per_account_report: null,
        per_account_setting: null,
        per_stock: null,
        per_materials_formula: null,
        per_product_formula: null,
        per_stock_report: null,
        per_stock_in: null,
        per_stock_history: null,
        per_po: null,
        per_production: null,
        per_production_history: null,
        // per_customer: null,
        // per_order: null,
        per_edit_company: null,
      },
      drawer: false,
      group: null,
      panel: true
    }
  },
  methods: {
    link(link , active = null) {
      // this.$router.push(link)
      window.location.href = link;
      this.selectedItem = active
    },
    linklive(link , active = null) {
      this.$router.push(link)
      this.selectedItem = active
    },
    logout() {
      this.confirm_logout = true;
    },
    logOutSuccess() {
      this.$store.commit('logOutSuccess')
      this.$router.push('/login')
    },
    profile() {
      this.profileDetail()
      this.profile_dialog = true;
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    async onInit() {
      this.loading_page = true;
      await company.dataList().then((response) => {
        if (response.data.successful === true) {

          //Form
          this.company.name = response.data.companyList.company_name
          this.company.email = response.data.companyList.company_email
          this.company.tel = response.data.companyList.company_tel

          this.profile_picture = {
            name: response.data.companyList.company_logo
          }
          this.imagePreviewURL = response.data.companyList.company_logo

          this.loading_page = false;
        }


      })
    },
    // async profileDetail() {
    //   // this.loading_page = true
    //   await profile.profileDetail().then(async (response) => {
    //     if (response.data.successful === true) {
    //       const profileDetail = response.data.profileDetail;
    //       this.$store.commit('setProfileDetail', profileDetail);

    //       this.profile_detail.id = await response.data.profileDetail.id
    //       this.profile_detail.name = await response.data.profileDetail.name
    //       this.profile_detail.name_edit = await response.data.profileDetail.name
    //       this.profile_detail.tel_edit = await response.data.profileDetail.tel
    //       this.profile_detail.permission = await response.data.profileDetail.permission

    //       //บัญชี
    //       // this.profile_detail.per_account = await response.data.profileDetail.per_account //บันทึกบัญชี
    //       // this.profile_detail.per_account_report = await response.data.profileDetail.per_account_report //รายงานบัญชี
    //       // this.profile_detail.per_account_setting = await response.data.profileDetail.per_account_setting //ตั้งค่าประเภทบัญชี

    //       //การขาย
    //       // this.profile_detail.per_customer = await response.data.profileDetail.per_customer  //การขาย -> ข้อมูลลูกค้า
    //       // this.profile_detail.per_order = await response.data.profileDetail.per_order //จัดการคำสั่งซื้อ

    //       this.profile_detail.per_dashboard = await response.data.profileDetail.per_dashboard //Dashboard
    //       this.profile_detail.per_edit_company = await response.data.profileDetail.per_edit_company //ตั้งค่าข้อมูลบริษัท
    //       this.profile_detail.per_po = await response.data.profileDetail.per_po  //สร้างใบสั่งซื้อวัตถุดิบ

    //       this.profile_detail.per_product_formula = await response.data.profileDetail.per_product_formula  //ดู/แก้ไข สูตรการผลิต
    //       this.profile_detail.per_materials_formula = await response.data.profileDetail.per_materials_formula  //ดู/แก้ไข สูตรการแปรรูป

    //       this.profile_detail.per_production = await response.data.profileDetail.per_production  //จัดการสั่งผลิต/แปรรูป
    //       this.profile_detail.per_production_history = await response.data.profileDetail.per_production_history //ดูประวัติการผลิต

    //       this.profile_detail.per_stock = await response.data.profileDetail.per_stock  //ตั้งค่าข้อมูลคลัง
    //       this.profile_detail.per_stock_report = await response.data.profileDetail.per_stock_report  //ดูรายงานคลัง
    //       this.profile_detail.per_stock_in = await response.data.profileDetail.per_stock_in  //นำวัตถุดิบเข้าคลัง

    //       this.profile_detail.per_stock_history = await response.data.profileDetail.per_stock_history  //ดูประวัติเข้าออกคลัง

    //       // เมนูจัดการพนักงาน
    //       this.profile_detail.per_user = await response.data.profileDetail.per_user  //จัดการข้อมูลทีมงาน
    //       // this.loading_page = false
    //     }
    //   }).catch((error) => {
    //     if (error.response.status === 401) {
    //       this.$store.commit('logOutSuccess')
    //       this.$router.push('/login')
    //     }
    //     if (error.response.status === 502 && this.$store.state.access_token != null || this.$store.state.access_token != '') {
    //       window.location.reload()
    //     }
    //   })
    // },
    async editProfile() {
      if (this.$refs.form.validate()) {
        if (this.profile_detail.password_edit != this.profile_detail.confirm_password_edit) {
          this.alert_message_error = 'รหัสผ่านใหม่และยืนยันรหัสผ่านไม่ตรงกัน กรุณาตรวจสอบใหม่อีกครั้ง'
          this.alert_error = true
          setTimeout(() => this.alert_error = false, 5000)
        }
        if (this.profile_detail.password_edit == this.profile_detail.confirm_password_edit) {
          this.profile_dialog = false
          let data
          // this.loading_page = true
          if (this.profile_detail.confirm_password_edit != '') {
            data = {
              name: this.profile_detail.name_edit,
              tel: this.profile_detail.tel_edit,
              password: this.profile_detail.confirm_password_edit,
            }
          } else {
            data = {
              name: this.profile_detail.name_edit,
              tel: this.profile_detail.tel_edit
            }
          }
          await users.editUser(this.profile_detail.id, data).then(async (response) => {
            if (response.data.successful === true) {

              this.profile_detail.name = this.profile_detail.name_edit
              this.profile_detail.tel = this.profile_detail.tel_edit
              // this.loading_page = false
              this.alert_message_success = 'แก้ไขข้อมูลส่วนตัวเรียบร้อยแล้ว'
              this.alert_success = true
              setTimeout(() => this.alert_success = false, 5000)

            }
            if (response.data.successful === false) {
              if (response.data.message == 'This phone number has already been taken') {
                this.alert_message_error = 'เบอร์โทรนี้มีผู้ใช้งานแล้ว'
                this.alert_error = true
                setTimeout(() => this.alert_error = false, 5000)
              }
              if (response.data.message == 'This name has already been taken') {
                this.alert_message_error = 'ชื่อ - นามสกุลนี้มีผู้ใช้งานแล้ว'
                this.alert_error = true
                setTimeout(() => this.alert_error = false, 5000)
              }
            }
          }).catch((error) => {
            if (error.response.status === 401) {
              this.$store.commit('logOutSuccess')
              this.$router.push('/login')
            }
          })
        }
      }
    },
  },
  created() {
    this.profileDetail()
    this.onInit()
  },
   watch:{
    panel(){
        this.profileDetail()
    } 
   }
}
</script>

<style scoped>

.nav-header-desktop {
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  z-index: 9;
  width: 100%;
}

.nav-header-menu-desktop {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu-left-ipad {
  z-index: 9;
}

.nav-header-ipad {
  position: fixed;
  background: #FFFFFF !important;
  border: 1px solid #D9D9D9;
  z-index: 10;
  width: 100%;
}

.nav-header-menu-ipad {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-menu {
  margin: 6px 14px 6px 34px;
  width: 50px;
  height: 50px;
}

.logo-menu-user {
  margin: 11px 0px 11px 0px;
  width: 40px;
  height: 40px;
}

.title-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.title-company-menu {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.title-company-email-menu {
  font-weight: 500;
  font-size: 14px;
  color: #7D7D7D;
}

.menu-bar {
  background: transparent;
  border: 1px solid transparent;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  cursor: pointer;
}

.menu-bar.active {
  background: #FFBC15;
  border: 1px solid #FFBC15;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  cursor: pointer;
}

.nav-item-title.active-list {
  color: #FFBC15;
}

.color-icon {
  color: #FFBC15 !important;
}

.menu-bar.active .nav-item-title {
  color: #FFFFFF;
  font-size: 16px;
}

.menu-bar.active .nav-item-title i {
  color: #FFFFFF;
  font-size: 16px;
}

.nav-item-title {
  color: #7D7D7D;
  font-weight: 500;
  font-size: 16px;
}

.nav-item-title i {
  font-size: 16px;
}

.link-menu {
  margin: 0 15px 0px 15px;
  text-decoration: none;
  color: transparent;
}

.btn-logout {
  font-size: 18px;
  margin-top: 20px;
  background-color: #F28080 !important;
  color: #FFFFFF;
}


.logo_list_header_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 15px;
}


.logo_list_header_mobile>.logo_menu_header_mobile>img {
  max-width: 100%;
}

.menu_list_header_mobile {
  padding: 0 10px;
}

.link_secondary_menu_header_mobile {
  text-decoration: none;
}

.dropdown-header-menu-text {
  font-size: 14px;
}

.v-application .rounded {
  border-radius: 15px !important;
}

.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}


@media only screen and (min-width: 960px) and (max-width:1024px) {
  .nav-header-desktop {
    display: block !important;
  }

  .nav-header-ipad {
    display: none !important;
  }

  .title-company-menu,
  .title-company-email-menu {
    font-size: 14px;
  }

  .logo-menu {
    margin: 6px 14px 6px 14px;
    width: 50px;
    height: 50px;
  }

  .logo-menu-user {
    margin: 14px 0px 14px 0px;
    width: 40px;
    height: 40px;
  }

  span.nav-item-title {
    font-size: 16px !important;
  }

  .link-menu {
    margin: 0 10px 0px 10px;
  }
}

@media only screen and (max-width: 959.5px) {
  .nav-header-desktop {
    display: none !important;
  }

  .nav-header-ipad {
    display: flex !important;
  }
}</style>
