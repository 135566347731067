<template>
    <div>
      <EditOrderListPage/>
    </div>
  </template>

<script>
import EditOrderListPage from '@/components/stock/order/EditOrderListComponent'
export default {
    name: 'editorderlist',
    components:{
        EditOrderListPage,
    }
}
</script>