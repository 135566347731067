<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ประวัติวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>สต๊อกวัตถุดิบ</b>
                            </v-col>
                            <v-col cols="6 text-right">
                                <b>{{ data_list.mat_name }} {{ formatPrice(data_list.mat_instock) }}</b>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" v-model="search"
                                    prepend-inner-icon="mdi-magnify" placeholder="ค้นหาที่นี่..." hide-details="auto"
                                    single-line outlined dense></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                                <v-select :items="data_date" item-text="year_name" item-value="year" v-model="year"
                                    @change="getMeterialStock()" class="input-radius-search mt-3" color="#FFBC15" style="font-size: 13px"
                                    prepend-inner-icon="mdi-calendar" label="ปี" hide-details="auto" outlined
                                    dense></v-select>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="left-fixed-columns" >
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            วันที่
                                        </th>
                                        <th class="text-left">
                                            LOT
                                        </th>
                                        <th class="text-left">
                                            Supplier (ผู้ขาย)
                                        </th>
                                        <th class="text-left">
                                            รับเข้า จำนวน(ชุด)
                                        </th>
                                        <th class="text-left">
                                            เบิกจ่าย จำนวน(ชุด)
                                        </th>
                                        <th class="text-left">
                                            ผู้ดำเนินการ
                                        </th>
                                        <th class="text-left">
                                            คงเหลือ จำนวน(ชุด)
                                        </th>
                                        <th class="text-left">
                                            หมายเหตุ
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- style="white-space: nowrap;" -->
                                    <tr style="white-space: nowrap;" v-for="(dataDetail, index) in data_list.stockList" :key="index">
                                        <td>
                                            <div>
                                                {{
                                                    `${dataDetail.create_date.split('/')[0]}-${dataDetail.create_date.split('/')[1]}-${dataDetail.create_date.split('/')[2]}`
                                                }}
                                            </div>
                                        </td>
                                        <td>
                                            <div>{{ dataDetail.material_lot != null ? dataDetail.material_lot : '-' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div>{{ dataDetail.sup_name }}</div>
                                        </td>
                                        <td>
                                            <div style="color:#88B358">{{ dataDetail.count_import == '-' ? '-' :
                                                formatPrice(dataDetail.count_import) }}</div>
                                        </td>
                                        <td>
                                            <div style="color:#F28080">{{ dataDetail.count_export == '-' ? '-' :
                                                formatPrice(dataDetail.count_export) }}</div>
                                        </td>
                                        <td>
                                            <div>{{ dataDetail.user_name }}</div>
                                        </td>
                                        <td>
                                            <div>{{ dataDetail.mat_instock_remain != null ?
                                                formatPrice(dataDetail.mat_instock_remain) : '-' }}</div>
                                        </td>
                                        <td>
                                            <div>{{ dataDetail.note }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div class="text-center mt-5">
                            <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                color="#FFBC15"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
              
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import meterial from '@/api/warehouse/materials.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังวัตถุดิบ',
                    disabled: false,
                    href: '/stock/warehouse-stock/warehouse',
                },
                {
                    text: '',
                    disabled: false,
                },
                {
                    text: 'ประวัติวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            headers: [
                {
                    text: 'วันที่',
                    align: 'start',
                    sortable: false,
                    value: 'create_date',
                    width: '150px',
                },
                {
                    text: 'LOT',
                    align: 'start',
                    sortable: false,
                    value: 'material_lot',
                    width: '200px',
                    class: 'fixed_header'
                },
                {
                    text: 'Supplier (ผู้ขาย)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: 'sup_name',
                },
                {
                    text: 'รับเข้า จำนวน(ชุด)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: '',
                },
                {
                    text: 'เบิกจ่าย จำนวน(ชุด)',
                    align: 'start',
                    sortable: false,
                    width: '200px',
                    value: '',
                },
                {
                    text: 'ผู้ดำเนินการ',
                    align: 'start',
                    sortable: false,
                    width: '200px',
                    value: '',
                },
                {
                    text: 'คงเหลือ จำนวน(ชุด)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: '',
                },
                {
                    text: 'หมายเหตุ',
                    align: 'start',
                    sortable: false,
                    width: '200px',
                    value: '',
                },

            ],
            data_list: [],
            data_date: [
                {
                    year:0,
                    year_name:'ดูทั้งหมด'
                }
            ],
            year: 0,
            search: '',
            page: 1,
            pageSize: 1,
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        getMeterialStock(page_cilck) {
            let status = parseFloat(this.$route.query.status);
            let id = parseFloat(this.$route.params.id);

            if (status == 1) {
                meterial.getMeterialStock(id, parseFloat(this.year) ,this.search ,page_cilck ? page_cilck : this.page).then((response) => {
                if (response.data.successful === true) {
                        this.data_list = response.data.data;
                        let for_year = response.data.year_list
                        // this.data_date = response.data.year_list;
                        for_year.forEach(item => {
                            this.data_date.push({
                                year: item.year,
                                year_name: item.year
                            })
                        });
                        this.sub_menu[2].text = response.data.data.mat_name
                        this.pageSize = response.data.pages
                        this.page = parseFloat(response.data.currentPage)
                    }
                })
            }else{
                meterial.getMeterialStock_normal(id, parseFloat(this.year),this.search ,page_cilck ? page_cilck : this.page).then((response) => {
                    if (response.data.successful === true) {
                        this.data_list = response.data.data;
                        let for_year = response.data.year_list
                        // this.data_date = response.data.year_list;
                        for_year.forEach(item => {
                            this.data_date.push({
                                year: item.year,
                                year_name: item.year
                            })
                        });
                        this.sub_menu[2].text = response.data.data.mat_name
                        this.pageSize = response.data.pages
                        this.page = parseFloat(response.data.currentPage)
                    }
                })
            }
           
        }
    },
    mounted() {
        this.getMeterialStock();
    },
    components: {
        navigationmenu
    },
    created() {

    },
    watch:{
        search(){
            this.getMeterialStock(1)
        },
        page() {
            this.getMeterialStock()
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.left-fixed-columns .v-data-table__wrapper {
    overflow-x: auto;
    position: relative;
}

.left-fixed-columns .v-data-table__wrapper table {
    position: relative;
}
</style>