<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ตั้งค่าประเภทบัญชี </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="5" xl="5" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title class="justify-space-between">
                        <b>เพิ่มประเภทบัญชี</b>
                        <div class="text-name-title">
                            ผู้แก้ไขล่าสุด : <span class="text-fullname-title">ชลิตวรรณ นาคทอง</span>
                            <span class="text-date-title"> วันที่ :</span> <span class="text-datetime-title">21/03/2022
                                09:40</span>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form_add_or_edit" @submit.prevent="unitAddOrEditSuccess()">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="36">
                                        <v-icon dark>
                                            mdi-database
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">ประเภทบัญชี</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <v-form-group>
                                                <v-text-field type="text" class="input-radius" label="ชื่อประเภทบัญชี"
                                                    color="#686868" :rules="[v => !!v || 'กรุณากรอกชื่อประเภทบัญชี']"
                                                    hide-details="auto"  outlined rounded required>
                                                </v-text-field>
                                            </v-form-group>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <v-form-group>
                                                <v-autocomplete class="input-radius" :items="type_list" item-text="name" item-value="id" color="#FFBC15" label="สถานะประเภทบัญชี"
                                                    hide-details="auto" outlined rounded></v-autocomplete>
                                            </v-form-group>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="12" lg="7" xl="7" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการประเภทบัญชี</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3"
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-accounting-setting-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-accounting-setting-box-data-text"></div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        ชื่อประเภทบัญชี
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        ประเภทรับ - จ่าย
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius">
                                    <div class="grid-accounting-setting-box-items">
                                        <div class="w-border"></div>
                                        <div>ค่าน้ำ-ค่าไฟ</div>
                                        <div>รายจ่าย</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#FEA06C">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <v-card elevation="0" class="card-table-radius">
                                    <div class="grid-accounting-setting-box-items">
                                        <div class="w-border"></div>
                                        <div>รายได้จากการขาย</div>
                                        <div>รายรับ</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#FEA06C">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForNumber } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenuAccounting.vue';
export default {
    data() {
        return {
            loading_page: false,
            sub_menu: [
                {
                    text: 'บัญชี',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าประเภทบัญชี',
                    disabled: false,
                    href: '#',
                },
            ],
            type_list: [
                { id: 1, name: 'รายรับ' },
                { id: 2, name: 'รายจ่าย' },
                { id: 3, name: 'เงินทุนหมุนเวียน' },
                { id: 4, name: 'คืนงินหมุนเวียน' },
            ],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },

    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-accounting-setting-box-data {
    display: grid;
    grid-template-columns: 1% 45% 19% 35%;
    padding: 5px 0px 5px 30px;
}

.table-header-accounting-setting-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-accounting-setting-box-items {
    display: grid;
    grid-template-columns: 7% 44% 31% 18%;
    align-items: center;
}
</style>