<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>

        <navigationmenu />

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ตั้งวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการวัตถุดิบ</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" @keyup="searchList()"
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                <div class="d-flex justify-end" v-if="$store.state.profileDetail.per_mat == 1 || $store.state.profileDetail.permission == 1">
                                    <v-btn class="mt-2" large rounded outlined color="#88B358"
                                        @click="link('/stock/setting-stock/setting-raw-material-add')">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มวัตถุดิบ
                                    </v-btn>
                                </div>
                                <div class="d-flex justify-end" v-else>
                                    <v-btn class="mt-2" large rounded color="#d0d0d0">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มวัตถุดิบ
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-setraw-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-setraw-box-data-text"></div>
                                    <div class="table-header-setraw-box-data-text">
                                        รูปภาพ
                                    </div>
                                    <div class="table-header-setraw-box-data-text">
                                        ชื่อวัตถุดิบ
                                    </div>
                                    <div class="table-header-setraw-box-data-text">
                                        บาร์โค้ด
                                    </div>
                                    <div class="table-header-setraw-box-data-text">
                                        กลุ่มวัตถุดิบ
                                    </div>
                                    <div class="table-header-setraw-box-data-text">
                                        สถานะ
                                    </div>
                                    <div class="table-header-setraw-box-data-text">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(list, index) in dataList" :key="index">
                                    <div class="grid-setraw-box-items">
                                        <div class="w-border"></div>
                                        <div>
                                            <v-img class="my-1" width="75" v-if="list.mat_pic" :lazy-src="list.mat_pic" :src="list.mat_pic"></v-img>
                                            <v-img class="my-1" width="75" v-else :lazy-src="img_rice" :src="img_rice"></v-img>
                                        </div>
                                        <div>{{ list.mat_name }}</div>
                                        <div>{{ list.barcode }}</div>
                                        <div>{{ list.mat_status }}</div>
                                        <div>
                                            <v-switch class="mt-0" v-model="list.status" color="success"
                                                @change="changeStatus(list.id, list.status)" hide-details></v-switch>
                                        </div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link >
                                                        <v-list-item-title class="f-14" style="color:#6C7FD8"
                                                            @click="link('/stock/setting-stock/setting-raw-material-detail/' + list.id);">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียด
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link v-if="$store.state.profileDetail.per_mat == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item-title class="f-14"
                                                            style="color:#FEA06C"
                                                            @click="link('/stock/setting-stock/setting-raw-material-edit/' + list.id); ">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                     <v-list-item link  v-if="$store.state.profileDetail.per_mat == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item-title class="f-14" style="color:#F28080"
                                                            @click="parseFloat(list.mat_instock) <= 0 ? material_delete(list.id): no_delete() ">
                                                            <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                            ลบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model=" page " :length=" pageSize " :total-visible=" 7 "
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- ลบข้อมูล -->
        <v-dialog v-model=" confirm_delete_dialog " transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <div v-if=" loading_detail_page === true ">
                <v-sheet :color=" `grey ${theme.isDark ? 'darken-2' : 'lighten-4'}` " class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if=" loading_detail_page === false ">
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click=" confirm_delete_dialog = false ">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click=" confirmDelete() ">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:14px;" text @click=" confirm_delete_dialog = false ">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->
    </div>
</template>
<script>
// import setrawmaterial from '@/api/warehouse/set_rawmaterial.js'
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import img_rice from '@/assets/rice (1).png';
import material from '@/api/warehouse/materials.js'
import Swal from 'sweetalert2'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            loading_detail_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            search: '',
            img_rice: img_rice,

            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลวัตถุดิบ',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            detail: [
                {
                    id: 1,
                    raw_name: 'น้ำตาลทรายขาว',
                    raw_barcode: '8850332129045',
                    raw_type: 'วัตถุดิบปกติ',
                },
                {
                    id: 2,
                    raw_name: 'ข้าวขาวแห้ง',
                    raw_barcode: '-',
                    raw_type: 'วัตถุดิบแปรรูป ',
                },
                {
                    id: 3,
                    raw_name: 'น้ำเปล่า',
                    raw_barcode: '-',
                    raw_type: 'วัตถุดิบปกติ  ',
                }
            ],
            page: 1,
            pageSize: 1,
            confirm_delete_dialog: false,
            delete_id: null,
            dataList: [],
        }
    },

    methods: {
        no_delete(){
            Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'warning',
                            html: 'กรุณานำวัตถุดิบออกจากคลัง!',
                            confirmButtonText:"ตกลง",
                        })
        },
        link(link) {
            this.$router.push(link)
        },
        async materialsList() {
            await material.dataList(this.page, '', this.search).then((response) => {
                this.loading_page = false;
                this.dataList = response.data.materialList
                this.pageSize = response.data.pages
                this.page = parseFloat(response.data.currentPage)

                for (let prop in this.dataList) {
                    switch (this.dataList[prop].status) {
                        case 1:
                            this.dataList[prop].status = true;
                            break;
                        case 0:
                            this.dataList[prop].status = false;
                            break;
                        default:
                            break;
                    }
                }
                for (let prop in this.dataList) {
                    switch (this.dataList[prop].mat_status) {
                        case 1:
                            this.dataList[prop].mat_status = 'วัตถุดิบปกติ';
                            break;
                        case 2:
                            this.dataList[prop].mat_status = 'วัตถุดิบแปรรูป';
                            break;
                        case 3:
                            this.dataList[prop].mat_status = 'บรรจุภัณฑ์';
                            break;
                    }
                }
            })
            this.loading_page = false
        },
        material_delete(id) {
            this.delete_id = id
            this.confirm_delete_dialog = true
        },
        async confirmDelete() {
            material.delete(this.delete_id).then((response) => {
                if (response.data.successful === true) {
                    this.alert_message_success = 'ลบข้อมูลวัตถุดิบเสร็จสิ้น'
                    this.materialsList()
                    this.confirm_delete_dialog = false
                    this.loading_page = false
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            })
        },
        async changeStatus(id, status) {
            const data = {
                'status': status
            }
            material.updateStatus(id, data).then((response) => {
                if (response.data.successful === true) {
                    this.alert_message_success = 'เปลี่ยนสถานะเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async searchList() {
            this.page = 1
            this.dataList = []
            await material.dataList(this.page,'', this.search).then((response) => {
                this.dataList = response.data.materialList
                this.pageSize = response.data.pages
                this.page = parseFloat(response.data.currentPage)

                for (let prop in this.dataList) {
                    switch (this.dataList[prop].status) {
                        case 1:
                            this.dataList[prop].status = true;
                            break;
                        case 0:
                            this.dataList[prop].status = false;
                            break;
                        default:
                            break;
                    }
                }
                for (let prop in this.dataList) {
                    switch (this.dataList[prop].mat_status) {
                        case 1:
                            this.dataList[prop].mat_status = 'วัตถุดิบปกติ';
                            break;
                        case 2:
                            this.dataList[prop].mat_status = 'วัตถุดิบแปรรูป';
                            break;
                        case 3:
                            this.dataList[prop].mat_status = 'บรรจุภัณฑ์';
                            break;
                        default:
                            break;
                    }
                }
            })
        },
    },
    components: {
        navigationmenu
    },
    created() {
        this.materialsList()
    },
    watch: {
        page() {
            this.materialsList()
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.table-header-setraw-box-data {
    display: grid;
    grid-template-columns: 0% 12% 20% 20% 21% 15% 10%;
    padding: 5px 0px 5px 30px;
}

.table-header-setraw-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-setraw-box-items {
    display: grid;
    grid-template-columns: 2% 13% 20% 20% 20% 15% 10%;
    align-items: center;
}
</style>