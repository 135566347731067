import { HTTP } from "@/axios.js";
import store from "@/store/index.js";
export default {
    importListAll(page = "", size = "", search = "") {
        return new Promise((resolve, reject) => {
          HTTP.get(`/importpo/import/product/list?page=${page}&size=${size}&search=${search}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
};
