<template>
    <div>
        <Warehouse_page_unpackaged_detail_history_raw_material_audit />
    </div>
</template>
  
<script>
import Warehouse_page_unpackaged_detail_history_raw_material_audit from '@/components/stock/warehouse_stock/WarehouseUnpackagedDetailHistoryRawMaterialAudit'
export default {
    name: 'WarehouseUnpackagedDetailHistoryRawMaterialAudit',
    components: {
        Warehouse_page_unpackaged_detail_history_raw_material_audit,
    }
}
</script>