import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {

  getStepOneList(id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/production/getDetailStepOne/` + id,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getProductionSize: (id) => {
    return HTTP.get('/production/list/product/size/' + id, {
      headers: {
        "Authorization": `Bearer ${store.state.access_token}`
      }
    })
      .then(response => response)
  },
  saveStepOne: (data, id) => {
    return HTTP.post('/production/saveStepOne/' + id, data, {
      headers: {
        "Authorization": `Bearer ${store.state.access_token}`
      }
    })
      .then(response => response)
  },
  saveStepOnePO: (data, id) => {
    return HTTP.post('/production/update/step/one/by/order/' + id, data, {
      headers: {
        "Authorization": `Bearer ${store.state.access_token}`
      }
    })
      .then(response => response)
  }
}

