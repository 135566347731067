<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">รายละเอียดการเตรียมวัตถุดิบสำหรับการผลิต </div>
      <div class="topic_processing_content my-2">การผลิตสินค้า</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OP-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">รายละเอียดการผลิต {{ header_3.product_name }}</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>รายละเอียดการเตรียมวัตถุดิบสำหรับการผลิต {{ header_3.product_name }}</b>
        </v-card-title>
        <v-card elevation="4" class="py-2" style="border-radius:20px">
          <div class="header-png">
            <v-avatar color="#34230F" size="36">
              <img class="icon-image-logo" src="@/assets/flour (4).png">
            </v-avatar>
          </div>
          <div class="d-flex justify-space-between mt-8  ml-5 mr-5">
            <b>ใบเตรียมวัตถุดิบ</b>
            <div class="d-flex">
              <div class="mr-2">วันที่ใบเตรียมวัตถุดิบ : </div>
              <div class="black--text">{{ header_3.production_date }}</div>
            </div>
          </div>
          
          <v-card-text>
            <v-divider class="mb-3" dark color="#a3a3a3"/>
            <div class="ml-15 mt-4">
              <div class="divider-table my-2"></div>
              <div class="scroll-menu-last mt-4">
                <section>
                  <div class="w-border-header-first mb-n3"/>
                  <div class="card-main-table">
                    <div class="py-3 d-flex justify-space-between">
                      <div class="black--text font-weight-bold">วัตถุดิบ</div>
                      <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                    </div>
                    <v-row class="pt-2">
                      <v-col xl="12" lg="12" class="inline-box-specific">
                        <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                      </v-col>
                    </v-row>

                    <v-row class="pt-2" v-for="(item) in meterial_group" :key="item">
                     
                      <v-col xl="12" lg="12">
                        <div class="tag-title-step">
                          <b class="black--text">{{ item.mat_group }}</b> 
                        </div>
                      </v-col>
                      <v-row class="pb-2" v-for="(item_sub) in item.material_list" :key="item_sub">
                        <v-col xl="6" lg="6" class="text-center">
                          <div class="black--text font-weight-bold">{{item_sub.mat_name}}</div>
                          <!-- <div class="font-weight-bold">Lot ข้าวสารขาว</div> -->
                        </v-col>
                        <v-col xl="6" lg="6">
                          <div class="black--text font-weight-bold">{{item_sub.materials_qty}} กรัม</div>
                        </v-col>
                      </v-row>
                    </v-row>

                    <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                    
                    <v-row class="pt-2">
                      <v-col xl="6" lg="6">
                        <div class="black--text font-weight-bold">รวม (FG)</div>
                      </v-col>
                      <v-col xl="6" lg="6">
                        <div class="black--text font-weight-bold">{{}}</div>
                      </v-col>
                    </v-row>

                  </div>
                </section>
                <section class="last" v-for="(detail, index) in batchList_3" :key="index">
                  <div class="w-border-header-sub-last mb-n3"/>
                  <div class="card-sub-table-last">
                    <div class="py-3">
                      <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                    </div>
                    <div class="black--text font-weight-bold text-center">{{ detail.percent }}</div>
                    <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                    <v-row class="pt-2" v-for="(detail_list, i) in detail.batch_list" :key="i">
                      <v-col xl="12" lg="12">
                        <div class="black--text font-weight-bold text-center">{{ detail_list.length != 0 ? detail_list.mat_qty : '0' }} กรัม</div>
                        <div class="font-weight-bold text-center">{{ detail_list.length != 0 ? detail_list.material_lot : 'No Lot' }}</div>
                      </v-col>
                    </v-row>
                    
                    <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                    <v-row class="pt-2">
                      <v-col xl="12" lg="12">
                        <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">{{ detail.batch_qty }} กรัม</div>
                      </v-col>
                    </v-row>
                  </div>
                </section>
              </div>
              <div class="summarize my-5 pa-5">
                <h3 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป {{ total_result }} หรือ
                            {{ total_result/1000 }} กิโลกรัม</h3>
              </div>
            </div>
          </v-card-text>

        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
import production from "@/api/product_good/product_process_step_two.js";
export default {
  name: "ProcessingRawMaterialDetails",
  data() {
    return {
        e1: 1,
        route_id: this.$route.params.id,
        loading_page: false,

        //step 3
        header_3: {},
        meterial_group:[],
        batchList_3:[],
        sum_3: [],
        total_result:0,
    }
  },
  methods: {
    getPrepareDeatail(){
      this.loading_page = true;
      let id = this.$route.params.id;
        production.getPrepareDeatail(id).then((response) => {
          if(response.data.successful === true){
            this.header_3 = response.data.data;
            this.meterial_group = response.data.data.mat_group_list;
            let batch = response.data.data.batchList;
            for(let i =0; i < batch.length; i++){
                this.sum_3[i] = 0;
                this.batchList_3[i]={
                  id:  batch[i].id,
                  percent: batch[i].percent,
                  batch_qty: batch[i].batch_qty,
                  batch_list: [],
                };
                for(let a = 0; a < batch[i].batch_list.length; a++){
                  for(let x = 0; x < batch[i].batch_list[a].stock_list.length; x++ ){    
                    this.sum_3[i] =parseFloat(this.sum_3[i]) + parseFloat(batch[i].batch_list[a].stock_list[x].quantity_cut)
                      this.batchList_3[i].batch_list.push(batch[i].batch_list[a].stock_list[x]);
                }
            }
          }
          this.loading_page = false;
        }
      })
    },
  },
  mounted() {
    this.getPrepareDeatail()
  },
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.header-png {
  position: absolute;
  top: -15px;
  left: 20px;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}
.tag-title-step {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}
.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: 120%;
}
.inline-box-specific {
  background: #fff8e8;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
}
.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}
.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding: 15px;
  width: 120%;
  height: 99%;
}
.card-main-table .header-card {}
.card-main-table .content-card {}
.card-main-table .footer-card {
  position: absolute;
  bottom: 0;
  width: 85%;
}
.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 115%;
  width: 115%;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-last .last {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 3%;
}
.scroll-menu-last section:first-child {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: sticky;
  left: 0;
  margin-right: 5%;
  background: #FFFFFF;
  z-index: 1;
}
</style>