<template>
  <div>
    <ProcessingProductGoodProduction/>
  </div>
</template>

<script>
import ProcessingProductGoodProduction from "@/components/production_process/processing_product_good/ProcessingProductGoodProduction";
export default {
  name: "ProcessingProductGoodProduction_Page",
  components: {ProcessingProductGoodProduction}
}
</script>

<style scoped>

</style>