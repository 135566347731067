<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">สร้างใบสั่งซื้อ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-form ref="form" @submit.prevent="materialAdd()">
                    <v-card class="card-radius" elevation="4">
                        <v-card-title>
                            <p class="team-text-title-card">สร้างใบสั่งซื้อ</p>
                        </v-card-title>
                        <v-card-text>
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-food-variant
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">กรุณาเลือกวัตถุดิบที่ต้องการซื้อ</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <v-form-group>
                                                <div class="d-flex">
                                                    <v-checkbox label="เลือกทั้งหมด" hide-details="auto" color="#FFBC15"
                                                        v-model="check_all" @change="checkAll()"></v-checkbox>
                                                </div>
                                            </v-form-group>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <div class="table-header-import-data display-pc mt-3"
                                                style="color: #b1adad; padding-left: 10px;">
                                                <div class="table-header-import-data-text">#</div>
                                                <div class="table-header-import-data-text">
                                                    วัตถุดิบ
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    คงเหลือ
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    ซื้อ
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    ราคาต่อหน่วย
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    ราคารวม
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <div v-for="(detail, index) in data" :key="detail.id">
                                    <v-card-title class="padding-text-title align-items-center"
                                        style="background: rgba(255, 188, 21, 0.1);border: 1px solid #FFBC15;border-radius: 20px;margin: 0 15px 15px 15px;">
                                        <span class="ml-3 text-font-title" style="font-size: 14px;">{{ detail.typeName
                                        }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-card elevation="0" class="card-table-radius"
                                            v-for="(mat_detail, index_list) in detail.List" :key="mat_detail.id">
                                            <div class="grid-import-items">
                                                <div class="d-flex justify-center">
                                                    <v-checkbox class="ma-0 pa-0" v-model="mat_detail.checkbox"
                                                        hide-details="auto" color="#FFBC15"></v-checkbox>
                                                </div>
                                                <div>{{ mat_detail.mat_name }}</div>
                                                <div class="text-center">{{ mat_detail.mat_instock }}</div>
                                                <div class="text-center" style="padding: 10px;">
                                                    <div class="d-flex">
                                                        <v-autocomplete style="border-radius:20px 0 0 20px;" :items="mat_detail.unit_other != null ? [
                                                            {
                                                                id: mat_detail.unit_main_id,
                                                                name: mat_detail.unit_main,
                                                                price: mat_detail.unit_other_qty,
                                                                retail_price: mat_detail.retail_price,
                                                                type: 'main'
                                                            },
                                                            {
                                                                id: mat_detail.unit_other_id,
                                                                name: mat_detail.unit_other,
                                                                price: mat_detail.unit_other_qty,
                                                                retail_price: mat_detail.other_price,
                                                                type: 'other'
                                                            }
                                                        ] :
                                                            [
                                                                {
                                                                    id: mat_detail.unit_main_id,
                                                                    name: mat_detail.unit_main,
                                                                    price: mat_detail.unit_other_qty,
                                                                    retail_price: mat_detail.retail_price,
                                                                    type: 'main'
                                                                },
                                                            ]" color="#FFBC15" label="จำนวนที่ต้องการซื้อ"
                                                            :rules="[v => !!v || 'กรุณาเลือกจำนวน']" hide-details="auto"
                                                            item-value="type" item-text="name" return-object
                                                            @change="calculate(mat_detail.unit, index_list, index)"
                                                            v-model="mat_detail.unit" dense outlined required>
                                                        </v-autocomplete>

                                                        <v-text-field class="input-radius" color="#FFBC15"
                                                            style="border-radius:0 20px 20px 0;"
                                                            :rules="[v => !!v || 'กรุณากรอกจำนวน']" v-model="mat_detail.qty"
                                                            @input="calculate_input(mat_detail.unit, index_list, index)"
                                                            hide-details="auto" dense outlined required>
                                                        </v-text-field>
                                                        <!-- @change="onlyForCurrency($event, mat_detail.qty)" -->

                                                    </div>
                                                </div>
                                                <div class="text-center">{{ mat_detail.unit ?
                                                    parseFloat(mat_detail.unit.retail_price).toFixed(2) : (0).toFixed(2) }}
                                                </div>
                                                <div class="text-center">{{ formatPrice(mat_detail.total) }}</div>
                                                <!-- {{ mat_detail }} -->
                                            </div>
                                        </v-card>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </v-card-text>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-img src="@/assets/saveorderlist.png" :width="20" class="mr-2"></v-img>
                                สรุปรายการสั่งซื้อ
                            </v-btn>
                        </div>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>

        <!-- ลบข้อมูลการผลิต - วัตถุดิบในสูตร -->
        <v-dialog v-model="check_data" transition="dialog-top-transition" max-width="383" style="border-radius: 20px">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="check_data = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/notice.png" width="100px" />
                                <h2 style="color: #000000">กรุณาเลือกวัตถุดิบ</h2>
                                <h3 class="mt-2">คุณต้องเลือกวัตถุอย่างน้อย 1 อย่าง</h3>
                            </center>
                            <div class="text-center mt-5"></div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูลวัตถุดิบ -->

    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import order from '@/api/order/order.js'
export default {
    data() {
        return {
            loading_page: true,
            check_data: false,
            total_result: 0,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ใบสั่งซื้อ',
                    disabled: false,
                    href: '/stock/order-list',
                },
                {
                    text: 'สร้างใบสั่งซื้อ',
                    disabled: false,
                    href: '#',
                },
            ],
            check_all: false,
            data: [],
            test: []
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        onlyForCurrency: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        async checkAll() {
            this.data.forEach((detail) => {
                detail.List.forEach((detail_unit) => {
                    detail_unit.checkbox = false
                })
            })
            if (this.check_all) {
                this.data.forEach((detail) => {
                    detail.List.forEach((detail_unit) => {
                        detail_unit.checkbox = true
                    })
                })
            }
        },
        async materialBeforeAdd() {
            this.loading_page = true
            this.data = []
            this.qtymain = 0
            this.qtyother = 0
            const response = await order.materialBeforeAdd()
            if (response.data.successful == true) {
                this.data = response.data.list
                this.data.forEach((detail) => {

                    detail.List.forEach((detail_unit) => {
                        detail_unit.checkbox = false
                        detail_unit.unit_fix = 1,
                            detail_unit.unit_list = [
                                {
                                    id: 1,
                                    name: detail_unit.unit_main
                                },
                                {
                                    id: 2,
                                    name: detail_unit.unit_other
                                },
                            ],
                            detail_unit.qty = ((parseFloat(detail_unit.max == null ? 0 : detail_unit.max)) - detail_unit.mat_instock_nm) < 0 || ((parseFloat(detail_unit.max)) - detail_unit.mat_instock_nm) == 'Infinity' ? '0' : ((parseFloat(detail_unit.max)) - detail_unit.mat_instock_nm).toFixed(2)
                        detail_unit.qtymain = detail_unit.qty
                        detail_unit.qty_main_old = null
                        detail_unit.qty_other_old = null

                        if (parseFloat(detail_unit.mat_instock_nm) <= parseFloat(detail_unit.min == null ? 0 : detail_unit.min)) {
                            detail_unit.checkbox = true
                        }
                        detail_unit.total = detail_unit.retail_price * detail_unit.qty
                        detail_unit.unit = {
                            id: detail_unit.unit_main_id,
                            name: detail_unit.unit_main,
                            price: detail_unit.retail_price,
                            retail_price: detail_unit.retail_price,
                            type: 'main'
                        }
                    })
                })
                this.loading_page = false
            }
        },
        async materialAdd() {
            if (this.$refs.form.validate()) {
                let po_list = []
                let total_price = 0
                this.data.forEach((all) => {
                    let typeName = null
                    let List = []
                    const list_checkbox = all.List.filter((detail_checkbox) => { return detail_checkbox.checkbox === true })
                    if (list_checkbox.length > 0) {
                        typeName = all.typeName
                        list_checkbox.forEach((detail) => {
                            List.push({
                                material_id: detail.id,
                                sup_id: detail.supplier_id,
                                qty: parseFloat(detail.qty),
                                unit_id: detail.unit.id,
                                price: parseFloat(detail.unit.retail_price),
                                total_price: parseFloat(detail.unit.price) * parseFloat(detail.qty),
                                mat_name: detail.mat_name,
                                mat_instock: detail.mat_instock,
                                unit_name: detail.unit.name,


                            })
                            total_price += parseFloat(detail.unit.price) * parseFloat(detail.qty)
                        })
                        po_list.push({
                            typeName: typeName,
                            List: List
                        })
                    } else {
                        this.check_data = true
                    }
                })
                if (po_list.length > 0) {
                    const data = {
                        po_list: po_list,
                        total_price: total_price
                    }
                    this.$store.commit('addStockOrder', data)
                    this.link('/stock/order-list/add/summary')
                }
            }
        },

        calculate_input(unit, i = null, index = null) {
            this.loading_page = true
            this.loading_page = false
            switch (unit.type) {
                case 'main':
                    this.data[index].List[i].total = 0
                    this.data[index].List[i].qty_main_old = this.data[index].List[i].qty
                    this.data[index].List[i].total = this.data[index].List[i].qty == '' ? 0 : parseFloat(this.data[index].List[i].qty) * parseFloat(this.data[index].List[i].retail_price);
                    break;
                case 'other':
                    this.data[index].List[i].total = 0
                    this.data[index].List[i].qty_other_old = this.data[index].List[i].qty
                    this.data[index].List[i].total = this.data[index].List[i].qty == '' ? 0 : parseFloat(this.data[index].List[i].qty) * parseFloat(this.data[index].List[i].other_price);
                    break;
            }
        },

        calculate(unit, i = null, index = null) {
            this.loading_page = true;
            if (unit != null) {
                switch (unit.type) {
                    case 'main':
                        this.data[index].List[i].qty = this.data[index].List[i].qty_main_old != null && this.data[index].List[i].qty_main_old != '' ? this.data[index].List[i].qty_main_old == '' ? 0 : parseFloat(this.data[index].List[i].qty_main_old) : Math.round(parseFloat(this.data[index].List[i].qtymain));
                        this.data[index].List[i].qty = parseFloat(this.data[index].List[i].qty).toFixed(2);
                        this.data[index].List[i].total = parseFloat(this.data[index].List[i].qty) * parseFloat(this.data[index].List[i].retail_price);
                        break;
                    case 'other':
                        this.data[index].List[i].qty = this.data[index].List[i].qty_other_old != null && this.data[index].List[i].qty_other_old != '' ? this.data[index].List[i].qty_other_old == '' ? 0 : parseFloat(this.data[index].List[i].qty_other_old) : (parseFloat(this.data[index].List[i].qty) / parseFloat(unit.price) == "Infinity" ? 1 : Math.round(parseFloat(this.data[index].List[i].qty) / parseFloat(unit.price)));
                        this.data[index].List[i].qty = parseFloat(this.data[index].List[i].qty).toFixed(2);
                        this.data[index].List[i].total = parseFloat(this.data[index].List[i].qty) * parseFloat(this.data[index].List[i].other_price);
                        break;
                }
                this.loading_page = false;


            }
        },
    },
    mounted() {
        this.materialBeforeAdd()
    },

}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-import-data {
    display: grid;
    grid-template-columns: 5% 21% 17% 28% 16% 13%;
    color: #686868 !important;
}

.table-header-import-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-import-items {
    display: grid;
    grid-template-columns: 5% 21% 17% 28% 16% 13%;
    align-items: center;
}
</style>