<template>
  <div>
    <ProcessingProductGoodProductionEdit/>
  </div>
</template>

<script>
import ProcessingProductGoodProductionEdit from "@/components/production_process/processing_product_good/ProcessingProductGoodProductionEdit";
export default {
  name: "ProcessingProductGoodProductionEdit_Page",
  components: {ProcessingProductGoodProductionEdit}
}
</script>

<style scoped>

</style>