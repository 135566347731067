import { HTTP } from "@/axios.js";
import store from "@/store/index.js";
export default {
  dataList: (page, size, search) => {
    return HTTP.get(`/product/?page=${page}&size=${size}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  updateStatus: (status) => {
    return HTTP.post("/product/update/status", status, {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  deleteProduct: (id) => {
    return new Promise((resolve, reject) => {
      HTTP.get("/product/delete/" + id, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  addProduct(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("/product/create", data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  editProduct(data) {
    let config = {}
    if (data.type == 1) {
      config = {
        "Authorization": `Bearer ${store.state.access_token}`,
        "Content-Type": "multipart/form-data"
      }
    } else {
      config = {
        "Authorization": `Bearer ${store.state.access_token}`,
      }
    }

    return new Promise((resolve, reject) => {
      HTTP.post("/product/update/product", data, {
        headers:
          config
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getProduct(id, data) {
    return new Promise((resolve, reject) => {
      HTTP.post("/product/" + id, data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getProductList(page, size, search, type) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/inventory/product?page=${page}&size=${size}&search=${search}&type=${type}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getProductSizeList() {
    return new Promise((resolve, reject) => {
      HTTP.get("/productsize/list/all", {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getProductDetail(id) {
    return new Promise((resolve, reject) => {
      HTTP.get("/product/detail/" + id, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getProductLotList(id, page, size, search, type) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/inventory/product/${id}?page=${page}&size=${size}&search=${search}&type=${type}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getProductPackagingList(id, page, size, search, type) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/inventory/product/packing/${id}?page=${page}&size=${size}&search=${search}&type=${type}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getMapSizeList(id) {
    return new Promise((resolve, reject) => {
      HTTP.get('/product/get/mapsize/' + id, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getProductLotDetail(id, type) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/inventory/product/lot/detail/${id}?type=${type}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getProductPackList(id, year, search, product_size_id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/inventory/product/packing/stock/${id}?year=${year}&product_size_id=${product_size_id}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getProductStockList(id, year, search) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/inventory/product/stock/${id}?year=${year}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  exportProduct(data) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/inventory/product/export`, data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  exportProductPacked(data) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/inventory/product/packing/export`, data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getReportInspection(id, year, month, search, type) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/inventory/product/report/quality/${id}?year=${year}&month=${month}&type=${type}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  exportLot(data) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/inventory/product/packing/export`, data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });

  }
};
