<template>
    <div>
        <Warehouse_page_packaged_detail_raw_material_procress />
    </div>
</template>
  
<script>
import Warehouse_page_packaged_detail_raw_material_procress from '@/components/stock/warehouse_stock/WarehousePackagedRawMaterialProcress'
export default {
    name: 'WarehousePackagedRawMaterialProcress',
    components: {
        Warehouse_page_packaged_detail_raw_material_procress,
    }
}
</script>