<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">Dashboard</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card class="card-radius" elevation="4">
          <v-card-text>
            <v-row>
              <v-col xs="12" sm="12" md="10" lg="10" xl="10" cols="12">
                <div class="pa-2 mr-auto" style="font-size:20px;color:#000;">ยอดขายประจำเดือน{{data_month.find(o => o.id === date_month).value}}</div>
              </v-col>
              <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                <div class="d-flex flex-column align-center">
                  <v-btn class="" color="#34230F" elevation="4" rounded dark x-large @click="link('/sale/order-list')">
                    <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                  </v-btn>
                </div>
              </v-col>

              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <h2 class="text-center">รวมยอดขายประจำเดือน{{data_month.find(o => o.id === date_month).value}} <span class="sum_instock">{{
                  (sum_total_sell) }}</span> บาท</h2>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="d-flex justify-center align-center">
                  <canvas id="myChartStock"></canvas>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card class="card-radius" elevation="4">
          <v-card-text>
            <v-row>
              <v-col xs="12" sm="12" md="10" lg="10" xl="10" cols="12">
                <div class="pa-2 mr-auto" style="font-size:20px;color:#000;">กราฟเปรียบเทียบยอดขาย</div>
              </v-col>
              <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                <v-select :items="data_date" item-text="value" item-value="id" color="#FFBC15"
                  class="input-radius-search mt-3 pa-2" prepend-inner-icon="mdi-calendar" label="ปีกราฟเปรียบเทียบยอดขาย"
                  @change="getProcessing()" v-model="date_year" hide-details="auto" outlined dense></v-select>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="d-flex justify-center align-center">
                  <canvas id="myChart"></canvas>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="3" xl="3" cols="12">
        <v-row>
          <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
            <v-card class="card-radius h-100" elevation="4">
              <v-card-text>
                <v-row>
                  <v-col xs="12" sm="12" md="12" lg="12" xl="8" cols="12">
                    <p class="text-title-card" style="line-height: 75px">จำนวนลูกค้าทั้งหมด</p>
                    <p class="text-count">{{count_customer}} <span class="text-count-word">รายการ</span></p>
                  </v-col>
                  <v-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12" class="px-0">
                    <div class="d-flex flex-column align-center">
                      <img class="icon-image mb-3" src="@/assets/image 48.png" alt="คลังวัตถุดิบ คงเหลือ">
                      <v-btn class="w-100" color="#88B358" elevation="4" rounded block dark x-large @click="link('/sale/customer-list')">
                        <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
            <v-card class="card-radius" elevation="4">
              <v-card-text>
                <p class="text-title-card-warehouse">มูลค่าสินค้าในคลัง</p>
                <v-card class="card-radius">
                  <v-card-text>
                    <p class="text-title-warehouse">คลังสินค้า (FG) </p>
                    <div class="text-center">
                      <img class="icon-image mb-3" src="@/assets/image 43.png" alt="คลังสินค้า (FG)">
                    </div>
                    <p class="text-count-warehouse">{{ formatPrice(product_value) }} <span class="text-count-word-warehouse">บาท</span></p>
                    <div class="text-center">
                      <v-btn class="" color="#140567" elevation="4" rounded dark x-large @click="link('/stock/warehouse-stock/warehouse-packaged')">
                        <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-text>
                <v-card class="card-radius">
                  <v-card-text>
                    <p class="text-title-warehouse">คลังวัตถุดิบ</p>
                    <div class="text-center">
                      <img class="icon-image mb-3" src="@/assets/image 44.png" alt="คลังวัตถุดิบ">
                    </div>
                    <p class="text-count-warehouse">{{ formatPrice(material_value) }} <span class="text-count-word-warehouse">บาท</span></p>
                    <div class="text-center">
                      <v-btn class="" color="#140567" elevation="4" rounded dark x-large @click="link('/stock/warehouse-stock/warehouse')">
                        <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="9" xl="9" cols="12">
        <v-row>
          <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
            <v-card class="card-radius" elevation="4">
              <v-card-text>
                <p class="text-title-card-warehouse">คำสั่งซื้อประจำเดือน</p>
                <v-row class="align-center">
                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <v-card>
                      <v-card-title>
                        จำนวนคำสั่งซื้อที่รอดำเนินการ
                      </v-card-title>
                      <v-card-text>
                        <hr class="mb-5" style="background-color:#6C7FD8;height:5px;">

                        <div class="box-report-stock">
                          <v-row>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12" class="d-flex align-center">
                              <b class="text-count-round">{{ count_order_wait_confirm }} <span
                                  class="text-count-word">คำสั่งซื้อ</span></b>
                            </v-col>
                            <v-col class="text-end" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                              <v-btn class="w-100" color="#6C7FD8" elevation="4" rounded dark x-large
                                @click="link('/sale/order-list')">
                                <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <v-card>
                      <v-card-title>
                        จำนวนคำสั่งซื้อทั้งหมด
                      </v-card-title>
                      <v-card-text>
                        <hr class="mb-5" style="background-color:#6C7FD8;height:5px;">
                        <div class="box-report-stock">
                          <v-row>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12" class="d-flex align-center">
                              <b class="text-count-production">{{ count_order }} <span
                                  class="text-count-word">คำสั่งซื้อ</span></b>
                            </v-col>
                            <v-col class="text-end" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                              <v-btn class="w-100" color="#140567" elevation="4" rounded dark x-large
                                @click="link('/sale/order-list')">
                                <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
            <v-card class="card-radius" elevation="4">
              <v-card-text>
                <v-row>
                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <div class="pa-2 mr-auto" style="font-size:20px;color:#000;">มูลค่าวัตถุดิบ เข้า – ออก คลัง</div>
                  </v-col>
                  <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                    <v-menu v-model="menu2" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="startOfMonth" label="วัน/เดือน/ปี เริ่มต้น" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี เริ่มต้น']" v-bind="attrs" dense outlined readonly
                          rounded required v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="startOfMonth" @input="menu2 = false" @change="getDashboardExportHistory()"  no-title scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">

                    <v-menu v-model="menu1" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="end_date" label="วัน/เดือน/ปี สิ้นสุด" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี สิ้นสุด']" v-bind="attrs" dense outlined readonly
                          rounded required v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="end_date" @input="menu1 = false" @change="getDashboardExportHistory()"  no-title scrollable></v-date-picker>
                    </v-menu>

                  </v-col>
                  <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <div class="d-flex justify-center align-center">
                      <canvas id="mychartImport"></canvas>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card class="card-radius" elevation="4">
          <v-card-text>
            <v-row>
              <v-col xs="12" sm="12" md="10" lg="10" xl="10" cols="12">
                <div class="pa-2 mr-auto" style="font-size:20px;color:#000;">กราฟเปรียบเทียบยอดขาย</div>
              </v-col>
              <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                <v-select :items="data_date" item-text="value" item-value="id" color="#FFBC15"
                  class="input-radius-search mt-3 pa-2" prepend-inner-icon="mdi-calendar" label="ปีกราฟเปรียบเทียบยอดขาย"
                  @change="getProcessing()" v-model="date_year" hide-details="auto" outlined dense></v-select>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="d-flex justify-center align-center">
                  <canvas id="mychartSales"></canvas>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { isTelephone, formatPrice } from "@/helper/handler";
import Chart from "chart.js/auto";
import myChartStock from "chart.js/auto";
import ExportImport from "chart.js/auto";
import mySales from "chart.js/auto";
import dashboard from "@/api/dashboard/dashboard.js";
export default {
  data() {
    return {
      loading_page: false,
      sub_menu: [
        {
          text: "Dashboard",
          disabled: false,
        },
      ],
      data: [],
      search: "",
      menu1: false,
      menu2: false,
      page: 1,
      size: 4,
      pages: 1,
      data_month: [
        {
          id: 1,
          value: 'มกราคม',
          month_id: '01',
        },
        {
          id: 2,
          value: 'กุมภาพันธ์',
          month_id: '02',
        },
        {
          id: 3,
          value: 'มีนาคม',
          month_id: '03',
        },
        {
          id: 4,
          value: 'เมษายน',
          month_id: '04',
        },
        {
          id: 5,
          value: 'พฤษภาคม',
          month_id: '05',
        },
        {
          id: 6,
          value: 'มิถุนายน',
          month_id: '06',
        },
        {
          id: 7,
          value: 'กรกฏาคม',
          month_id: '07',
        },
        {
          id: 8,
          value: 'สิงหาคม',
          month_id: '08',
        },
        {
          id: 9,
          value: 'กันยายน',
          month_id: '09',
        },
        {
          id: 10,
          value: 'ตุลาคม',
          month_id: '10',
        },
        {
          id: 11,
          value: 'พฤศจิกายน',
          month_id: '11',
        },
        {
          id: 12,
          value: 'ธันวาคม',
          month_id: '12',
        },
      ],
      data_date: [
        {
          id: 2023,
          value: 'ปี 2023',
        },
        {
          id: 2022,
          value: 'ปี 2022',
        },
        {
          id: 2021,
          value: 'ปี 2021',
        },
      ],
      date_month: new Date().getMonth() + 1,
      date_year: new Date().getFullYear(),
      material: null,
      productUnpack: null,
      productPack: null,
      processing_count: null,
      production_count: null,
      startOfMonth : new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().substr(0, 10),
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      export_start_date: new Date().toISOString().substr(0, 10),
      export_end_date: new Date().toISOString().substr(0, 10),
      import_value: null,
      export_value: null,
      matType: [],
      matDataType: [],
      count_import: [],
      matTypeExport: [],
      matTypeExport_List: [],
      count_export: [],
      sum_total_sell: null,
      count_customer: null,
      count_order_wait_confirm: null,
      count_order: null,
      material_value: null,
      product_value: null,
      type_name: []
    };
  },

  methods: {
    link(link) {
      this.$router.push(link);
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    yearOptions() {

    },
    async getDashboard() {
      // this.loading_page = true
      // dashboard.getDashboardInventory().then((response) => {
      //   if (response.data.successful === true) {
      //     this.material = response.data.material
      //     this.productUnpack = response.data.productUnpack
      //     this.productPack = response.data.productPack
      //     this.loading_page = false
      //   }
      // })
    },
    async getProcessing() {
      // this.loading_page = true
      dashboard.getDashboardProcessing(this.date_year).then((response) => {
        if (response.data.successful === true) {
          let typeExport = []
          this.matTypeExport_List = response.data.sale_order_list
          for (let index = 0; index < this.matTypeExport_List.length; index++) {
            typeExport.push(parseFloat(this.matTypeExport_List[index].order_total));
          }
          this.matTypeExport_List = typeExport;
          this.loading_page = true
          this.chartstock()
          this.chartSales()
          this.loading_page = false
        }
      })
    },
    async getDashboardImportHistory() {
      dashboard.getDashboardImportHistoryShow().then((response) => {
        if (response.data.successful === true) {
          let type = [];
          let count_import = [];
          this.import_value = response.data.material_value
          this.sum_total_sell = response.data.sum_total_sell,
          this.count_customer = response.data.count_customer,
          this.count_order_wait_confirm = response.data.count_order_wait_confirm,
          this.count_order = response.data.count_order,
          this.material_value = response.data.material_value
          if(response.data.product_value !== "0" || response.data.product_value !== 0) {
            this.product_value = response.data.product_value
          }else{
            this.product_value = 0
          }
          this.matType = response.data.total_sell,
          this.data = response.data.total_sell
          for (let index = 0; index < this.matType.length; index++) {
            type.push(parseFloat(this.matType[index].order_total));
            count_import.push(parseFloat(this.matType[index].count_day))
          }
          this.matDataType = type;
          this.count_import = count_import;
          this.loading_page = true
          this.chart()
          this.loading_page = false
        }
      })
    },
    async getDashboardExportHistory() {
      // this.loading_page = true
      const data = {
        start_date: this.startOfMonth,
        end_date: this.end_date,
      }
      const typeExport = [];
      const type_name = [];
      let count_export = [];
      dashboard.getDashboardExportHistoryShow(data).then((response) => {
        if (response.data.successful === true) {
          this.matTypeExport = response.data.data
          for (let index = 0; index < this.matTypeExport.length; index++) {
            typeExport.push(parseFloat(this.matTypeExport[index].count_import));
            type_name.push(this.matTypeExport[index].mat_type_name);
            count_export.push(parseFloat(this.matTypeExport[index].count_export))
          }
          this.matTypeExport = typeExport;
          this.count_export = count_export;
          this.type_name = type_name;
          // this.chartstock()
          this.loading_page = true
          this.chartImport()
          // this.chartSales()
          this.loading_page = false
        }
      })
    },
    chart() {
      let data = {
        labels: this.count_import,
        datasets: [
          {
            label: 'ยอดขายประจำเดือน',
            data: this.matDataType,
            borderColor: '#88B358',
            backgroundColor: '#88B358',
            fill: false,
          },
        ]
      }
      let config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: true,
          height: 90  //set the height to 500 pixels
        }
      }
      // if (this.myChartCost) {
      //   this.myChartCost.destroy();
      // }
      const ctx = document.getElementById('myChartStock').getContext('2d');

      this.myChartCost = new myChartStock(ctx, config)
    },
    chartstock() {
      let data = {
        labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
        datasets: [
          {
            label: 'กราฟเปรียบเทียบยอดขาย',
            data: this.matTypeExport_List,
            borderColor: '#FFBC15',
            backgroundColor: '#FFBC15',
            fill: false,
          },
        ]
      }
      const config = {
        type: 'bar',
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        },
      };
      if (this.myChart) {
        this.myChart.destroy();
      }
      const ctx = document.getElementById('myChart').getContext('2d');
      this.myChart = new Chart(ctx, config)
    },
    chartImport() {
      let data = {
        labels: this.type_name,
        datasets: [
          {
            label: 'วัตถุดิบเข้าคลัง',
            data: this.matTypeExport,
            borderColor: '#88B358',
            backgroundColor: '#88B358',
            fill: false,
          },
          {
            label: 'วัตถุดิบออกคลัง',
            data: this.count_export,
            borderColor: '#F28080',
            backgroundColor: '#F28080',
            fill: false,
          }
        ]
      }
      let config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: true,
          height: 90  //set the height to 500 pixels
        }
      }
      if (this.mychartImport) {
        this.mychartImport.destroy();
      }
      const ctx = document.getElementById('mychartImport').getContext('2d');

      this.mychartImport = new ExportImport(ctx, config)
    },
    chartSales() {
      let color = []
       this.matTypeExport_List.forEach(item => {
              if(item < 0){
                color.push('#F28080')
              }else{
                color.push('#88B358')
              }
            })
      let data = {
        labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
        datasets: [
          {
            label: 'กำไร - ขาดทุน',
            data: this.matTypeExport_List,
            borderColor: color,
            backgroundColor: color,
            fill: false,
          }
        ]
      }
      let config = {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: true,
          height: 90  //set the height to 500 pixels
        }
      }
      if (this.mychartSales) {
        this.mychartSales.destroy();
      }
      const ctx = document.getElementById('mychartSales').getContext('2d');

      this.mychartSales = new mySales(ctx, config)
    },
  },
  // computed: {
  //   formattedProductValue() {
  //     return this.product_value.toFixed(2); // Formats to 2 decimal places
  //   },
  //   formattedMaterialValue() {
  //     return this.material_value.toFixed(2); // Formats to 2 decimal places
  //   },
  // },
  mounted() {
    this.getDashboard()
    this.getProcessing()
    this.getDashboardImportHistory()
    this.getDashboardExportHistory()
  },
  watch: {
    page() {
      this.chart()
      this.chartstock()
      this.chartImport()
      this.chartSales()
      this.getDashboard()
      this.getProcessing()
      this.getDashboardImportHistory()
      this.getDashboardExportHistory()
    },
  },
};
</script>
<style scoped>
.icon-image {
  margin-bottom: 40px !important;
}

.sum_instock {
  color: #88B358;
}

.sum_instock_throw {
  color: #F28080;
}

.box-report-stock {
  padding: 20px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-count-round {
  font-weight: 700;
  font-size: 24px;
  color: #6C7FD8;
}

.text-count-production {
  font-weight: 700;
  font-size: 24px;
  color: #140567;
}

.text-title-card {
  font-weight: 600;
  font-size: 22px;
  color: #34230F;
  margin-bottom: 30px !important;
}

.text-count {
  font-weight: 700;
  font-size: 24px;
  color: #88B358;
}

.text-title-card-warehouse {
  font-weight: 600;
  font-size: 22px;
  color: #34230F;
  margin-bottom: 40px !important;
}

.text-title-warehouse {
  font-weight: 600;
  font-size: 22px;
  color: #34230F;
  margin-bottom: 40px !important;
}

.text-count-warehouse {
  font-weight: 700;
  font-size: 24px;
  color: #140567;
  text-align: center;
}

.text-count-word-warehouse {
  font-weight: 600;
  font-size: 18px;
  color: #686868;
}

.text-title-card-round {
  font-weight: 600;
  font-size: 24px;
  color: #34230F;
  margin-bottom: 30px !important;
}



.text-count-word {
  font-weight: 600;
  font-size: 20px;
  color: #686868;
}

.text-date {
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #6C7FD8 !important;
  margin-bottom: 0 !important;
}

.text-date-word {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #686868 !important;
  margin-bottom: 0 !important;
}

.text-step {
  font-weight: 500;
  font-size: 18px;
  color: #686868 !important;
}

.text-step-word {
  color: #FFFFFF !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
</style>