<template>
    <div>
      <MaterialImportPurchaseOrderDetailPage/>
    </div>
  </template>
  
  <script>
  import MaterialImportPurchaseOrderDetailPage from '@/components/stock/material_import/MaterialImportPurchaseOrderDetailComponent'
  export default {
    name: 'materialimagepurchaseorderdetail',
    components:{
      MaterialImportPurchaseOrderDetailPage,
    }
  }
  </script>