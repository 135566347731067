<template>
    <div>
        <CompanySettingPage />
    </div>
</template>
  
<script>
import CompanySettingPage from '@/components/company_setting/CompanySettingComponent'
export default {
    name: 'CompanySetting',
    components: {
        CompanySettingPage,
    }
}
</script>