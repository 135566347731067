import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    profileDetail: () => {
        return HTTP.get('/auth/profile' , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
}