<template>
    <div>
        <QualityInspectionReportListPage />
    </div>
</template>
  
<script>
import QualityInspectionReportListPage from '@/components/stock/quality_inspection_report/QualityInspectionReportList'
export default {
    name: 'Reportstockpage',
    components: {
        QualityInspectionReportListPage,
    }
}
</script>