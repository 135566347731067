<template>
    <div>
      <MaterialImportPurchaseOrderEditPage/>
    </div>
  </template>
  
  <script>
  import MaterialImportPurchaseOrderEditPage from '@/components/stock/material_import/MaterialImportPurchaseOrderEditComponent'
  export default {
    name: 'materialimagepurchaseorderedit',
    components:{
      MaterialImportPurchaseOrderEditPage,
    }
  }
  </script>