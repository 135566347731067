<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">แก้ไขรอบการผลิตสินค้า</div>
      <div class="topic_processing_content my-2">การผลิตสินค้า</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OP-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">แก้ไขรอบการผลิตสินค้า</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนที่ 1 : สร้างใบสั่งผลิต</b>
        </v-card-title>
        <v-card-text>
          <v-card elevation="4" class="py-2 px-3" style="border-radius:20px;">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <v-icon dark>
                  mdi-note-text-outline
                </v-icon>
              </v-avatar>
              <span class="ml-3 title-card-text">ข้อมูลการผลิตสินค้า</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <v-row class="ma-4">
                <v-col xl="12" lg="12">
                  <v-row>
                    <v-col xl="3" lg="3" md="3">
                      <v-select item-text="value" item-value="id" class="input-radius-search " prepend-inner-icon="mdi-calendar" label="วันที่ใบสั่งผลิตสินค้า" hide-details="auto" outlined dense></v-select>
                    </v-col>
                    <v-col xl="2" lg="2" md="3">
                      <v-text-field v-model="sTime" label="เวลาสั่งผลิต" append-icon="mdi-clock-time-four-outline" outlined dense rounded required hide-details="auto"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="ma-4">
                <b class="ml-3 mb-n5 black--text">ชื่อสินค้า : Krispy Rice Milk Flavor</b>
                <v-col xl="12" lg="12">
                  <v-row>
                    <v-col xl="3" lg="3">
                      <v-select item-text="value" item-value="id" class="input-radius-search mt-3" label="ขนาดสินค้า" hide-details="auto" outlined dense></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pl-8">
              <div class="table-header-processing mt-n6 " style="color: #b1adad;">
                <div class="table-sub-header-processing">
                  ขนาดสินค้า
                </div>
                <div class="table-sub-header-processing text-center">
                  จำนวนต่อหน่วย
                </div>
                <div class="table-sub-header-processing text-center">
                  จำนวนกรัมการผลิต
                </div>
                <div class="table-sub-header-processing text-center">
                  ลบ
                </div>
              </div>
              <v-card elevation="0" class="grid-processing-items ma-5" v-for="(detail, index) in dataList" :key="index">
                <div>{{ detail.product_size }}</div>
                <div>
                  <v-text-field label="จำนวนต่อหน่วย" class="input-radius" color="#FFBC15" v-model="detail.product_qty" dense outlined rounded required hide-details="auto"></v-text-field>
                </div>
                <div class="text-center">{{ detail.product_result }}</div>
                <div style="text-align: center">
                  <v-btn elevation="1" class="ml-6 radius-15" color="#f2abab" outlined>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-card>
              <div class="summarize my-5  pa-5 my-19">
                <div class="text-18 text-center text-green">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป 71,9847.64 กรัม หรือ 71.98 กิโลกรัม</div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px;">
            
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <v-icon dark>
                  mdi-account-supervisor
                </v-icon>
              </v-avatar>
              <span class="ml-3 title-card-text">ข้อมูลผู้รับผิดชอบ</span>
            </v-card-title>

            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ma-4 mb-n7">
                <b class="ml-3 black--text">ผู้อนุมัติสั่งผลิต</b>
              </div>

              <v-row class="ma-4">
                <v-col xl="3" lg="3">
                  <v-select item-text="value" item-value="id" class="input-radius-search mt-3" label="ผู้อนุมัตสั่งผลิต" hide-details="auto" outlined dense></v-select>
                </v-col>
              </v-row>

              <div class="ma-4 mb-n5">
                <b class="ml-3 black--text">ขั้นตอนการสั่งผลิตและผู้รับชอบ</b>
              </div>

              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">สั่งผลิต</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="value" item-value="id" class="input-radius-search  " label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search  "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15"
                                prepend-inner-icon="mdi-calendar" hide-details="auto"
                                :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense readonly
                                outlined rounded required v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="print_date" @input="menu3 = false" :min="minDate" no-title
                            scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" value="-" hide-details="auto" outlined rounded dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>

              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">เตรียมวัตถุดิบ</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="value" item-value="id" class="input-radius-search " label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15"
                                prepend-inner-icon="mdi-calendar" hide-details="auto"
                                :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense readonly
                                outlined rounded required v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="print_date" @input="menu3 = false" :min="minDate" no-title
                            scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" value="-" hide-details="auto" outlined rounded dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>

              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">บันทึกการผลิต</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="value" item-value="id" class="input-radius-search " label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15"
                                prepend-inner-icon="mdi-calendar" hide-details="auto"
                                :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense readonly
                                outlined rounded required v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="print_date" @input="menu3 = false" :min="minDate" no-title
                            scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" value="-" hide-details="auto" outlined rounded dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>

              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">บรรจุภัณฑ์</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="value" item-value="id" class="input-radius-search " label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15"
                                prepend-inner-icon="mdi-calendar" hide-details="auto"
                                :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense readonly
                                outlined rounded required v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="print_date" @input="menu3 = false" :min="minDate" no-title
                            scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" value="-" hide-details="auto" outlined rounded dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>

              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">ตรวจสอบการผลิต</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="value" item-value="id" class="input-radius-search " label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15"
                                prepend-inner-icon="mdi-calendar" hide-details="auto"
                                :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense readonly
                                outlined rounded required v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="print_date" @input="menu3 = false" :min="minDate" no-title
                            scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" value="-" hide-details="auto" outlined rounded dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>

              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">ตรวจสอบคุณภาพสินค้า</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="value" item-value="id" class="input-radius-search " label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15"
                                prepend-inner-icon="mdi-calendar" hide-details="auto"
                                :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense readonly
                                outlined rounded required v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="print_date" @input="menu3 = false" :min="minDate" no-title
                            scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" value="-" hide-details="auto" outlined rounded dense></v-text-field>
                  </v-col>
                </v-row>
              </section>

            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="linkToSteps">
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessingCreate",
  data() {
    return {
      sTime: '00 : 00',
      dataList: [
        {
          product_size: 'ขนาด 210 กรัม',
          product_qty: 22,
          product_result: '4,759.00',
        },
        {
          product_size: 'ขนาด 500 กรัม',
          product_qty: 22,
          product_result: '4,759.00',
        },
      ],
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/processing_raw_materials')
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 10% 36% 29% 15%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 23% 23% 29% 15%;
  align-items: baseline;
}
.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}

.text-green{
  color: #88B358 ;
}
</style>