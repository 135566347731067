<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ประวัติวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="">
                                    <span v-for="(dataMaps, index) in mapsize" :key="index" >
                                        <v-btn v-if="dataMaps.product_size_id == check_button"
                                            @click="getMapChangeButton(dataMaps.product_size_id)"
                                            class="mt-2 ml-0 mx-5 radius-15 text-16" elevation="2" large outlined
                                            color="#FFBC15">
                                            {{ dataMaps.pro_size_name }}
                                        </v-btn>
                                        <v-btn v-else
                                            @click="getMapChangeButton(dataMaps.product_size_id)"
                                            class="mt-2 ml-0 mx-5 text-grey radius-15 text-16" elevation="0" large 
                                            color="#fff">
                                            {{ dataMaps.pro_size_name }}
                                        </v-btn>
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <b>สต๊อกวัตถุดิบ</b>
                            </v-col>
                            <v-col cols="6 text-right">
                                <b>{{ data_list.pro_name }} <span class="ml-3 mr-3">{{ formatPrice(data_list.instock)
                                }}</span>
                                    กรัม</b>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" v-model="search"
                                    prepend-inner-icon="mdi-magnify" placeholder="ค้นหาที่นี่..." hide-details="auto"
                                    single-line outlined dense></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                                <v-select :items="data_date" item-text="value" item-value="id" v-model="year"
                                    @change="getMapChange(year)" class="input-radius-search mt-3" color="#FFBC15"
                                    prepend-inner-icon="mdi-calendar" label="ปี" hide-details="auto" outlined style="font-size: 13px"
                                    dense></v-select>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="left-fixed-columns" :headers="headers" :items="data_list.stockList"
                             :footer-props="footer_Props" mobile-breakpoint="0" st>
                            <template v-slot:item="dataDetail">
                                <tr style="white-space: nowrap;">
                                    <td>
                                        <div>{{ dataDetail.item.create_date }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.product_lot != null ? dataDetail.item.product_lot : '-' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div style="color:#88B358">{{ dataDetail.item.count_import != '-' ?
                                            formatPrice(dataDetail.item.count_import) : '-' }}</div>
                                    </td>
                                    <td>
                                        <div style="color:#F28080">{{ dataDetail.item.count_export != '-' ?
                                            formatPrice(dataDetail.item.count_export) : '-' }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.user_name }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.mat_instock_remain != null ?
                                            dataDetail.item.mat_instock_remain : '-' }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.note }}</div>
                                    </td>

                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import product from '@/api/warehouse/product.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังสินค้าที่ยังไม่ได้บรรจุภัณฑ์',
                    href: '/stock/warehouse-stock/warehouse-packaged',
                    disabled: false,
                },
                {
                    text: '',
                    disabled: false,
                },
                {
                    text: 'ประวัติสินค้า',
                    disabled: false,
                    href: '#',
                },
            ],
            formatPrice(number) {
                return formatPrice(number);
            },
            headers: [
                {
                    text: 'วันที่',
                    align: 'start',
                    sortable: false,
                    value: 'create_date',
                    width: '150px',
                },
                {
                    text: 'LOT',
                    align: 'start',
                    sortable: false,
                    value: 'product_lot',
                    width: '200px',
                    class: 'fixed_header'
                },
                {
                    text: 'ผลิตจำนวน(ถุง)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: 'count_import',
                },
                {
                    text: 'เบิกจ่ายจำนวน(ถุง)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: 'count_export',
                },
                {
                    text: 'ผู้ดำเนินการ',
                    align: 'start',
                    sortable: false,
                    width: '200px',
                    value: 'user_name',
                },
                {
                    text: 'คงเหลือจำนวน(ถุง)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: 'pro_instock_remain',
                },
                {
                    text: 'หมายเหตุ',
                    align: 'start',
                    sortable: false,
                    width: '200px',
                    value: 'note',
                },

            ],
            data_list: [],
            data_date: [
                {
                    id: 0,
                    value: 'ทั้งหมด',
                },
            ],
            year: 0,
            search: '',
            check_button:null,
            mapsize: [],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        async getMapSizeList() {
           await product.getMapSizeList(this.$route.params.id).then((response) => {
                if (response.data.successful === true) {
                    this.mapsize = response.data.mapsize;
                    this.check_button = this.mapsize[0].product_size_id
                    product.getProductPackList(this.$route.params.id, parseFloat(this.year), this.search, this.mapsize[0].product_size_id).then((res) => {
                        if (res.data.successful === true) {
                            this.data_list = res.data.data
                            this.sub_menu[2].text = this.data_list.pro_name
                        }
                    })
                    // this.data_list = response.data
                }
            })
        },
        async getMapChange() {
           await product.getProductPackList(this.$route.params.id, parseFloat(this.year), this.search, this.mapsize[0].product_size_id).then((res) => {
                if (res.data.successful === true) {
                    this.data_list = res.data.data
                    let data = res.data.year_list;
                    data.forEach(item => {
                        this.data_date.push({
                            id: item.year,
                            value: item.year
                        })
                    })
                }
            })
        },
        async getMapChangeButton(product_size_id) {
           await product.getProductPackList(this.$route.params.id, parseFloat(this.year), this.search, product_size_id).then((res) => {
                if (res.data.successful === true) {
                    this.data_list = res.data.data
                    this.check_button = product_size_id
                }
            })
        }
        // getProductStockList(){
        //      let id = parseFloat(this.$route.params.id);
        //      product.getProductStockList(id , parseFloat(this.year)).then((response) => {
        //         if(response.data.successful === true){
        //             this.data_list = response.data.data;
        //         }
        //     })
        // },
        // getProductStock(evt) {
        //     let id = parseFloat(this.$route.params.id);
        //     product.getProductStockList(id , parseFloat(evt)).then((response) => {
        //         if(response.data.successful === true){
        //             this.data_list = response.data.data;
        //         }
        //     })
        // },
    },
    mounted() {
        this.getMapSizeList();
    },
    components: {
        navigationmenu
    },
    created() {

    },
    watch: {
        page() {
            this.getMapChangeButton()
        },
        search(){
            this.getMapChange();
        },
        year(){
            this.getMapChange();
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.left-fixed-columns .v-data-table__wrapper {
    overflow-x: auto;
    position: relative;
}

.left-fixed-columns .v-data-table__wrapper table {
    position: relative;
}
</style>