<template>
    <div>
      <ProcessingPackagingStepConfirm/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepConfirm from "@/components/production_process/procressing_packaging/ProcressingPackagingStepConfirm";
  export default {
    name: "Processing_packaging_step_confirm",
    components: {
      ProcessingPackagingStepConfirm
    }
  }
  </script>
  
  <style scoped>
  
  </style>