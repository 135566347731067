<template>
    <div>
        <QualityInspectionProductionReportListPage />
    </div>
</template>
  
<script>
import QualityInspectionProductionReportListPage from '@/components/stock/quality_inspection_report/QualityInspectionProductionReportList'
export default {
    name: 'QualityInspectionproductionreportlistpage',
    components: {
        QualityInspectionProductionReportListPage,
    }
}
</script>