<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">เพิ่มเกณฑ์การตรวจสอบการผลิต </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>เพิ่มเกณฑ์การตรวจสอบการผลิต</b>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;margin-bottom:25px">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">หัวข้อรายการตรวจสอบการผลิต</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">

                                        <v-text-field type="text" class="input-radius" label="ชื่อหัวข้อเกณฑ์การตรวจสอบการผลิต"
                                            v-model="fq_title.fq_name" color="#FFBC15"
                                            :rules="[v => !!v || 'กรุณากรอกชื่อหัวข้อเกณฑ์การตรวจสอบการผลิต']" hide-details="auto"
                                             dense outlined rounded required></v-text-field>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-autocomplete color="#FFBC15" :items="items" v-model="fq_title.fq_type"
                                            item-text="name" item-value="id"
                                            :rules="[v => !!v || 'กรุณาเลือกประเภทการตรวจสอบ']" label="ประเภทการตรวจสอบ"
                                            hide-details="auto" dense outlined rounded required></v-autocomplete>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-form ref="form" @submit.prevent="inspectionCriteriaProductionAddSuccess()">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-format-list-bulleted
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">รายการตรวจสอบการผลิต</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-card elevation="2" style="border-radius:20px;margin:20px;"
                                        v-for="(fq_detail, index) in fq_list" :key="index">
                                        <v-card-title class="d-flex align-items-center justify-space-between">
                                            <div>
                                                <v-icon dark color="#34230F" size="32">
                                                    mdi-certificate
                                                </v-icon>
                                                <span class="ml-3 text-font-title">รายการตรวจสอบการผลิตที่ {{ index +
                                                    1 }}</span>
                                            </div>
                                            <div class="error--text" style="cursor:pointer;"
                                                @click="deleteFQ(fq_detail.no_fq_id)" v-if="index >= 1">
                                                <v-icon dark color="error" size="28">
                                                    mdi-delete-forever
                                                </v-icon>
                                                <span class="ml-3 f-14">ลบรายการตรวจสอบการผลิต</span>
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-divider class="mb-5" color="#686868"></v-divider>
                                            <span class="ml-3"
                                                style="font-size:14px;color:#000;">ข้อมูลรายการตรวจสอบการผลิต</span>
                                            <v-row class="mb-3">
                                                <v-col xs="12" sm="12" md="12" lg="12" cols="12"></v-col>
                                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                                    <v-text-field type="text" class="input-radius"
                                                        label="ชื่อรายการตรวจสอบการผลิต" v-model="fq_detail.fq_list_name"
                                                        color="#FFBC15"
                                                        :rules="[v => !!v || 'กรุณากรอกชื่อรายการตรวจสอบการผลิต']"
                                                        hide-details="auto"  dense outlined rounded
                                                        required></v-text-field>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                                    <v-text-field type="text" class="input-radius" label="ระยะค่าในเกณฑ์"
                                                        v-model="fq_detail.fq_list_guide" color="#FFBC15"
                                                        :rules="[v => !!v || 'กรุณากรอกระยะค่าในเกณฑ์']" hide-details="auto"
                                                         dense outlined rounded></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="ml-1">
                                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                                    <span style="font-size:14px;color:#000;">ประเภทการตรวจสอบข้อมูล</span>
                                                    <v-radio-group v-model="fq_detail.fq_list_type"
                                                        @change="refreshFQListType(index)" row>
                                                        <v-radio label="Checkbox" :value="0" color='#FFBC15'></v-radio>
                                                        <v-radio label="กรอกข้อความ" :value="1" color='#FFBC15'></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <span style="font-size:14px;color:#000;">การใส่ค่าอัตโนมัติ</span>
                                                    <div class="d-flex">
                                                        <v-checkbox label="ใส่ค่าอัตโนมัติ" color="#FFBC15"
                                                            v-model="fq_detail.fq_value_status"
                                                            @change="refreshFQValueStatus(index)"></v-checkbox>
                                                    </div>
                                                </v-col>

                                                <v-col v-if="fq_detail.fq_list_type == 0 && fq_detail.fq_value_status == 1"
                                                    xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-radio-group v-model="fq_detail.fq_value_boolean" row
                                                        style="margin-top: 40px;">
                                                        <v-radio label="ผ่าน" :value="1" color='#FFBC15'></v-radio>
                                                        <v-radio label="ไม่ผ่าน" :value="0" color='#FFBC15'></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col v-if="fq_detail.fq_list_type == 1 && fq_detail.fq_value_status == 1"
                                                    xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-text-field type="text" class="input-radius input-for-center"
                                                        label="ใส่คำนี้อัตโนมัติ" v-model="fq_detail.fq_value"
                                                        color="#FFBC15" :rules="[v => !!v || 'กรุณากรอกใส่คำนี้อัตโนมัติ']"
                                                        hide-details="auto"  dense outlined rounded
                                                        required></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <!-- กดเพิ่มมาถึงจะขึ้นตรงนี้ -->

                                    <!-- สิ้นสุดกดเพิ่มมาถึงจะขึ้นตรงนี้ -->
                                    <div class="d-flex justify-center mt-3">
                                        <v-btn type="button" class="mt-2" large rounded outlined color="#88B358"
                                            @click="plusFQ()">
                                            <v-icon left>mdi-plus</v-icon>
                                            รายการตรวจสอบคุณภาพ
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" @click="link('/#')"
                                    elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import inspectioncriteriaproduction from '@/api/warehouse/inspectioncriteriaproduction.js';
import navigationmenu from '@/components/menu/VerticalMenu.vue';
export default {
    data() {
        return {
            loading_page: false,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าเกณฑ์การตรวจสอบ',
                    disabled: false,
                    href:'/stock/setting-stock/inspection-criteria-production'
                },
                {
                    text: 'เกณฑ์การตรวจสอบคุณภาพ',
                    disabled: false,
                    href:'/stock/setting-stock/inspection-criteria-production'
                },
                {
                    text: 'เพิ่มเกณฑ์การตรวจสอบการผลิต',
                    disabled: false,
                    href: '#',
                },
            ],
            items: [
                {
                    id: 2,
                    name: 'ตรวจสอบการแปรรูป',
                },
                {
                    id: 1,
                    name: 'ตรวจสอบการผลิต',
                },
                {
                    id: 3,
                    name: 'ตรวจสอบการบรรจุภัณฑ์',
                }
            ],
            fq_title: {
                fq_name: '',
                fq_type: null
            },
            no_fq_id: 1,
            fq_list: [
                {
                    no_fq_id: 1,
                    fq_list_name: '',
                    fq_list_guide: '',
                    fq_list_type: 1,
                    fq_value_status: 1,
                    fq_value: null,
                    fq_value_boolean: 1,
                }
            ],
        }
    },

    methods: {
        async plusFQ() {
            this.no_fq_id += 1
            this.fq_list.push({
                no_fq_id: this.no_fq_id,
                fq_list_name: '',
                fq_list_guide: '',
                fq_list_type: 1,
                fq_value_status: 1,
                fq_value: null,
                fq_value_boolean: 1,
            })
        },
        async deleteFQ(no_fq_id) {
            this.fq_list = this.fq_list.filter((detail) => { return detail.no_fq_id != no_fq_id })
        },
        async refreshFQListType(key) {
            this.fq_list[key].fq_value_boolean = 1
            this.fq_list[key].fq_value = ''
        },
        async refreshFQValueStatus(key) {
            this.fq_list[key].fq_value_boolean = 1
            this.fq_list[key].fq_value = ''
        },
        async inspectionCriteriaProductionAddSuccess() {
            if (this.$refs.form.validate()) {
                this.loading_page = true
                
                for(let i=0; i<this.fq_list.length; i++){
                delete this.fq_list[i].no_fq_id;
                }

                const data = {
                    fq_title: this.fq_title,
                    fq_list: this.fq_list,
                }

                inspectioncriteriaproduction.addInspectionCriteriaProduction(data).then((response) => {
                    if (response.data.successful === true) {
                        this.$router.push('/stock/setting-stock/inspection-criteria-production')
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        }
    },
    components: {
        navigationmenu
    },
    
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-transport-box-data {
    display: grid;
    grid-template-columns: 2% 25% 25% 25% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-transport-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-transport-box-items {
    display: grid;
    grid-template-columns: 6% 25% 24% 33% 12%;
    align-items: center;
}

.input-for-center {
    margin-top: 30px;
}</style>