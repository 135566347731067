<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">รายงานคลัง</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card class="card-radius" elevation="4">
          <v-card-title class="font-bold">รายงานคลัง</v-card-title>
          <v-card-text>
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
                <v-card class="card-radius h-100" elevation="4">
                  <v-card-text>
                    <v-row>
                      <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                        <p class="text-title-card" style="line-height: 40px">คลังวัตถุดิบ<br>
                          คงเหลือ</p>
                        <p class="text-count">{{ material }} <span class="text-count-word">รายการ</span></p>
                      </v-col>
                      <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12" class="px-0">
                        <div class="d-flex flex-column align-center">
                          <img class="icon-image mb-3" src="@/assets/image_raw.png" alt="คลังวัตถุดิบ คงเหลือ">
                          <v-btn class="w-100" color="#88B358" elevation="4" rounded block dark x-large
                            @click="link('/stock/warehouse-stock/warehouse')">
                             ดูข้อมูล
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
                <v-card class="card-radius h-100" elevation="4">
                  <v-card-text>
                    <v-row>
                      <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                        <p class="text-title-card" style="line-height: 40px">คลังสินค้ายังไม่ได้บรรจุภัณฑ์<br>
                          คงเหลือ</p>
                        <p class="text-count">{{ productUnpack }} <span class="text-count-word">รายการ</span>
                        </p>
                      </v-col>
                      <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12" class="px-0">
                        <div class="d-flex flex-column align-center">
                          <img class="icon-image mb-3" src="@/assets/image_unpackage.png"
                            alt="คลังสินค้ายังไม่ได้บรรจุภัณฑ์ คงเหลือ">
                          <v-btn class="w-100" color="#88B358" elevation="4" rounded block dark x-large
                            @click="link('/stock/warehouse-stock/warehouse-unpackaged')">
                            ดูข้อมูล
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
                <v-card class="card-radius h-100" elevation="4">
                  <v-card-text >
                    <v-row >
                      <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                        <p class="text-title-card" style="line-height: 40px">คลังสินค้า<br>
                          คงเหลือ</p>
                        <p class="text-count">{{ productPack }} <span class="text-count-word">รายการ</span>
                        </p>
                      </v-col>
                      <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12" class="px-0">
                        <div class="d-flex flex-column align-center">
                          <img class="icon-image mb-3" src="@/assets/image_stock.png" alt="คลังสินค้า คงเหลือ">
                          <v-btn class="w-100" color="#88B358" elevation="4" rounded block dark x-large
                            @click="link('/stock/warehouse-stock/warehouse-packaged')"> ดูข้อมูล
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card class="card-radius" elevation="4">
                  <v-card-text>
                    <v-row class="align-center">
                      <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <div class="pa-2 mr-auto" style="font-size:20px;color:#000;">จำนวนรอบการผลิต</div>
                      </v-col>
                      <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <v-select :items="data_month" item-text="value" item-value="id" color="#FFBC15"
                          class="input-radius-search mt-3 pa-2" prepend-inner-icon="mdi-calendar" label="เดือน"
                          @change="getProcessing()" v-model="date_month" hide-details="auto" outlined dense></v-select>
                      </v-col>
                      <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <v-select :items="data_date" item-text="value" item-value="id" color="#FFBC15"
                          class="input-radius-search mt-3 pa-2" prepend-inner-icon="mdi-calendar" label="ปี"
                          @change="getProcessing()" v-model="date_year" hide-details="auto" outlined dense></v-select>
                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="6" xl="6" cols="12">
                        <v-card>
                          <v-card-title>
                            การแปรรูปวัตถุดิบ
                          </v-card-title>
                          <v-card-text>
                            <hr class="mb-5" style="background-color:#6C7FD8;height:5px;">

                            <div class="box-report-stock">
                              <v-row>
                                <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                  <p class="text-count-round">{{ processing_count }} <span
                                      class="text-count-word">รอบการแปรรูป</span></p>
                                </v-col>
                                <v-col class="text-end" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                  <v-btn class="w-100" color="#6C7FD8" elevation="4" rounded dark x-large
                                    @click="link('/prm/processing_materials')">
                                    <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="6" xl="6" cols="12">
                        <v-card>
                          <v-card-title>
                            การผลิตสินค้า
                          </v-card-title>
                          <v-card-text>
                            <hr class="mb-5" style="background-color:#6C7FD8;height:5px;">
                            <div class="box-report-stock">
                              <v-row>
                                <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                  <p class="text-count-production">{{ production_count }} <span
                                      class="text-count-word">รอบการผลิต</span></p>
                                </v-col>
                                <v-col class="text-end" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                  <v-btn class="w-100" color="#140567" elevation="4" rounded dark x-large
                                    @click="link('/pro/processing_product_good')">
                                    <v-icon class="mr-3">mdi-magnify</v-icon> ดูข้อมูล
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card class="card-radius" elevation="4">
                  <v-card-text>
                    <v-row>
                      <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                        <div class="pa-2 mr-auto" style="font-size:20px;color:#000;">การนำวัตถุดิบเข้าคลัง</div>
                      </v-col>
                      <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                        <v-menu v-model="menu2" :close-on-content-click="false" class="input-radius-search "
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startOfMonth" label="วัน/เดือน/ปี เริ่มต้น" color="#FFBC15"
                              prepend-inner-icon="mdi-calendar" hide-details="auto"
                              :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี เริ่มต้น']" v-bind="attrs" dense outlined readonly
                              rounded required v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="startOfMonth" @input="menu2 = false" @change="getDashboardImportHistory()" no-title scrollable></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">

                        <v-menu v-model="menu1" :close-on-content-click="false" class="input-radius-search "
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="end_date" label="วัน/เดือน/ปี สิ้นสุด" color="#FFBC15"
                              prepend-inner-icon="mdi-calendar" hide-details="auto"
                              :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี สิ้นสุด']" v-bind="attrs" dense outlined readonly
                              rounded required v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="end_date" @input="menu1 = false" @change="getDashboardImportHistory()" no-title scrollable></v-date-picker>
                        </v-menu>

                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <h2 class="text-center">รวมมูลค่าวัตถุดิบในคลัง <span class="sum_instock">{{
                          formatPrice(import_value) }}</span> บาท</h2>
                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <div class="d-flex justify-center align-center">
                          <canvas id="myChartStock"></canvas>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card class="card-radius" elevation="4">
                  <v-card-text>
                    <v-row>
                      <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                        <div class="pa-2 mr-auto" style="font-size:20px;color:#000;">มูลค่าวัตถุดิบทิ้ง</div>
                      </v-col>
                      <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                        <v-menu v-model="menu6" :close-on-content-click="false" class="input-radius-search "
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="export_startOfMonth" label="วัน/เดือน/ปี เริ่มต้น" color="#FFBC15"
                              prepend-inner-icon="mdi-calendar" hide-details="auto"
                              :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี เริ่มต้น']" v-bind="attrs" dense outlined readonly
                              rounded required v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="export_startOfMonth" @input="menu6 = false" @change="getDashboardExportHistory()" no-title
                            scrollable></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                        <v-menu v-model="menu5" :close-on-content-click="false" class="input-radius-search "
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="export_end_date" label="วัน/เดือน/ปี สิ้นสุด" color="#FFBC15"
                              prepend-inner-icon="mdi-calendar" hide-details="auto"
                              :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี สิ้นสุด']" v-bind="attrs" dense outlined readonly
                              rounded required v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="export_end_date" @input="menu5 = false" @change="getDashboardExportHistory()" no-title
                            scrollable></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <h2 class="text-center">รวมมูลค่าวัตถุดิบทิ้ง <span class="sum_instock_throw">{{
                          formatPrice(export_value) }}</span> บาท
                        </h2>
                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <div class="d-flex justify-center align-center">
                          <canvas id="myChart"></canvas>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { isTelephone, formatPrice } from "@/helper/handler";
import Chart from "chart.js/auto";
import myChartStock from "chart.js/auto";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import inventory from "@/api/dashboard/inventory.js";
export default {
  data() {
    return {
      loading_page: false,
      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "รายงานคลัง",
          disabled: false,
          href: "#",
        },
      ],
      data: [],
      search: "",
      page: 1,
      size: 4,
      pages: 1,
      data_month: [
        {
          id: 1,
          value: 'เดือน มกราคม',
          month_id: '01',
        },
        {
          id: 2,
          value: 'เดือน กุมภาพันธ์',
          month_id: '02',
        },
        {
          id: 3,
          value: 'เดือน มีนาคม',
          month_id: '03',
        },
        {
          id: 4,
          value: 'เดือน เมษายน',
          month_id: '04',
        },
        {
          id: 5,
          value: 'เดือน พฤษภาคม',
          month_id: '05',
        },
        {
          id: 6,
          value: 'เดือน มิถุนายน',
          month_id: '06',
        },
        {
          id: 7,
          value: 'เดือน กรกฏาคม',
          month_id: '07',
        },
        {
          id: 8,
          value: 'เดือน สิงหาคม',
          month_id: '08',
        },
        {
          id: 9,
          value: 'เดือน กันยายน',
          month_id: '09',
        },
        {
          id: 10,
          value: 'เดือน ตุลาคม',
          month_id: '10',
        },
        {
          id: 11,
          value: 'เดือน พฤศจิกายน',
          month_id: '11',
        },
        {
          id: 12,
          value: 'เดือน ธันวาคม',
          month_id: '12',
        },
      ],
      data_date: [
        {
          id: 2023,
          value: 'ปี 2023',
        },
        {
          id: 2022,
          value: 'ปี 2022',
        },
        {
          id: 2021,
          value: 'ปี 2021',
        },
      ],
      date_month: new Date().getMonth()+1,
      date_year: new Date().getFullYear(),
      material: null,
      productUnpack: null,
      productPack: null,
      processing_count: null,
      production_count: null,
      startOfMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().substr(0, 10),
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      export_startOfMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().substr(0, 10),
      export_start_date: new Date().toISOString().substr(0, 10),
      export_end_date: new Date().toISOString().substr(0, 10),
      import_value: null,
      export_value: null,
      matType: [],
      matDataType: [],
      count_import: [],
      matTypeExport: [],
      count_export: [],
    };
  },

  methods: {
    link(link) {
      this.$router.push(link);
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    async getDashboard() {
      // this.loading_page = true
      inventory.getDashboardInventory().then((response) => {
        if (response.data.successful === true) {
          this.material = response.data.material
          this.productUnpack = response.data.productUnpack
          this.productPack = response.data.productPack
          this.loading_page = false
        }
      })
    },
    async getProcessing() {
      // this.loading_page = true
      inventory.getDashboardProcessing(this.date_month, this.date_year).then((response) => {
        if (response.data.successful === true) {
          this.processing_count = response.data.processing_count
          this.production_count = response.data.production_count
          this.loading_page = false
        }
      })
    },
    async getDashboardImportHistory() {
      // this.loading_page = true
      const data = {
        start_date: this.startOfMonth,
        end_date: this.end_date,
      }
      inventory.getDashboardImportHistoryShow(data).then((response) => {
        if (response.data.successful === true) {
          let type = [];
          let count_import = [];
          this.import_value = response.data.import_value
          this.matType = response.data.matType
          for (let index = 0; index < this.matType.length; index++) {
            type.push(this.matType[index].mat_type_name);
            count_import.push(parseInt(this.matType[index].count_import))
          }
          this.matDataType = type;
          this.count_import = count_import;
          this.loading_page = true
          this.getDashboardExportHistory()
          this.chart()
          this.loading_page = false
        }
      })
    },
    async getDashboardExportHistory() {
      // this.loading_page = true
      const data = {
        start_date: this.export_startOfMonth,
        end_date: this.export_end_date,
      }
      const typeExport = [];
      let count_export = [];
      inventory.getDashboardExportHistoryShow(data).then((response) => {
        if (response.data.successful === true) {
          this.export_value = response.data.export_value
          this.matTypeExport = response.data.matType
          for (let index = 0; index < this.matTypeExport.length; index++) {
            typeExport.push(this.matTypeExport[index].mat_type_name);

            count_export.push(parseInt(this.matTypeExport[index].count_export))
          }
          this.matTypeExport = typeExport;
          this.count_export = count_export;
          this.chartstock()
          this.loading_page = false
        }
      })
    },
    chart() {
      let data = {
        labels: this.matDataType,
        datasets: [
          {
            label: 'มูลค่าวัตถุดิบนำเข้า',
            data: this.count_import,
            borderColor: '#FFBC15',
            backgroundColor: '#FFBC15',
            fill: false,
          },
        ]
      }
      let config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: true,
          height: 90  //set the height to 500 pixels
        }
      }
      if(this.myChartCost){
         this.myChartCost.destroy();
      }
      const ctx = document.getElementById('myChartStock').getContext('2d');
      
      this.myChartCost = new myChartStock(ctx, config)
    },
    chartstock() {
      let data = {
        labels: this.matTypeExport,
        datasets: [
          {
            label: 'มูลค่าวัตถุดิบทิ้ง',
            data: this.count_export,
            borderColor: '#F28080',
            backgroundColor: '#F28080',
            fill: false,
          },
        ]
      }
      let config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: true,
          height: 90  //set the height to 500 pixels
        }
      }
      if(this.myChart){
         this.myChart.destroy();
      }
      const ctx = document.getElementById('myChart').getContext('2d');
      this.myChart = new Chart(ctx, config)
    },
  },
  mounted() {
    this.getDashboard()
    this.getProcessing()
    this.getDashboardImportHistory()
  },
  watch: {
    page() {
      this.chart()
      this.chartstock()
      this.getDashboard()
      this.getProcessing()
      this.getDashboardImportHistory()
      this.getDashboardExportHistory()
    },
  },
  components: {
      navigationmenu
  },
};
</script>
<style scoped>
.icon-image {
  margin-bottom: 40px !important;
}

.sum_instock {
  color: #FFBC15;
}

.sum_instock_throw {
  color: #F28080;
}

.box-report-stock {
  padding: 20px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-count-round {
  font-weight: 700;
  font-size: 24px;
  color: #6C7FD8;
}

.text-count-production {
  font-weight: 700;
  font-size: 24px;
  color: #140567;
}

.text-title-card {
  font-weight: 600;
  font-size: 22px;
  color: #34230F;
  margin-bottom: 30px !important;
}

.text-count {
  font-weight: 700;
  font-size: 24px;
  color: #88B358;
}

.text-title-card-warehouse {
  font-weight: 600;
  font-size: 22px;
  color: #34230F;
  margin-bottom: 40px !important;
}

.text-title-warehouse {
  font-weight: 600;
  font-size: 22px;
  color: #34230F;
  margin-bottom: 40px !important;
}

.text-count-warehouse {
  font-weight: 700;
  font-size: 24px;
  color: #140567;
  text-align: center;
}

.text-count-word-warehouse {
  font-weight: 600;
  font-size: 18px;
  color: #686868;
}

.text-title-card-round {
  font-weight: 600;
  font-size: 24px;
  color: #34230F;
  margin-bottom: 30px !important;
}



.text-count-word {
  font-weight: 600;
  font-size: 20px;
  color: #686868;
}

.text-date {
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #6C7FD8 !important;
  margin-bottom: 0 !important;
}

.text-date-word {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #686868 !important;
  margin-bottom: 0 !important;
}

.text-step {
  font-weight: 500;
  font-size: 18px;
  color: #686868 !important;
}

.text-step-word {
  color: #FFFFFF !important;
}
.w-100
{
  width: 100%;
}
.h-100{
  height: 100%;
}
</style>