import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    getDashboardImportHistoryShow() {
        return new Promise((resolve, reject) => {
            HTTP.get('/dashboard', {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getDashboardExportHistoryShow(data) {
        return new Promise((resolve, reject) => {
            HTTP.post('/dashboard/materail/transection/history', data, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getDashboardProcessing(year) {
        return new Promise((resolve, reject) => {
            HTTP.get('/dashboard/sale/order/?year='+year, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
}

