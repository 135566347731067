<template>
  <div class="loading_all_page" v-if="loading_page === true">
      <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
      <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/prm/processing_materials')">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนที่ 1 : แก้ไขใบสั่งแปรรูป</b>
        </v-card-title>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px;">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <v-icon dark>
                  mdi-note-text-outline
                </v-icon>
              </v-avatar>
              <span class="ml-3 title-card-text">ข้อมูลการแปรรูปวัตถุดิบ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <v-row class="ma-4 mt-n3 mb-0">
                <v-col xl="12" lg="12">
                  <v-row>
                    <v-col cols="">
                      <label for="">วันที่ใบสั่งแปรรูป</label>
                      <p class="mb-0 black--text">{{ processing_date }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- <v-row class="ma-4">
                <b class="ml-3 mb-n5 black--text">รายการวัตถุดิบแปรรูป</b>
                <v-col xl="12" lg="12">
                  <v-row v-if="status > 1">
                    <v-col xl="3" lg="3">
                      <v-select item-text="name" item-value="id" :items="unit_list" v-model="unit_id" 
                        class="input-radius-search mt-3" label="หน่วย" hide-details="auto" outlined dense disabled></v-select>
                    </v-col>
                    <v-col xl="9" lg="9">
                      <v-select item-text="mat_name" @change="addProduct()" v-model="meterial_id" item-value="id"
                        :items="dataList" class="input-radius-search mt-3" label="เลือกวัตถุดิบแปรรูป" hide-details="auto" ref="dataList"
                        outlined dense disabled></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col xl="3" lg="3">
                      <v-select item-text="name" item-value="id" :items="unit_list" v-model="unit_id"
                        class="input-radius-search mt-3" label="หน่วย" hide-details="auto" outlined dense disabled></v-select>
                    </v-col>
                    <v-col xl="9" lg="9">
                      <v-select item-text="mat_name" @change="addProduct()" v-model="meterial_id" item-value="id"
                        :items="dataList" class="input-radius-search mt-3" label="เลือกวัตถุดิบแปรรูป" hide-details="auto" ref="dataList"
                        outlined dense></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row> -->
            </v-card-text>
            <v-card-text>
              <div class="table-header-processing mt-n6" style="color: #b1adad;">
                <div class="table-sub-header-processing pl-3">
                  วัตถุดิบแปรรูป
                </div>
                <div class="table-sub-header-processing text-center">
                  {{ unit_id == 0 ? 'กรัม g' : unit_id == 1 ? 'กิโลกรัม kg' : '-' }}
                </div>
                <div class="table-sub-header-processing text-center">
                  g FG/Batch
                </div>
                <div class="table-sub-header-processing text-center">
                  No.of Batch
                </div>
                <!-- <div class="table-sub-header-processing text-center" v-if="status <= 1">
                  ลบ
                </div> -->
              </div>
              <v-card elevation="0" class="grid-processing-items ma-4" v-for="(detail, index) in data" :key="index">
                <div class="pl-3">{{ detail.mat_name }}</div>
                <div>
                  <p>{{ formatPrice(detail.grams) }}</p>
                </div>
                <div class="text-center">{{ formatPrice(detail.batch_qty) }}</div>
                <div class="text-center">{{ adjustDecimal(detail.grams == 0 ? 0 : unit_id == 0 ? parseFloat(detail.grams) /
                  parseFloat(detail.batch_qty) : unit_id == 1 ? parseFloat(detail.grams * 1000) / (parseFloat(detail.batch_qty)) : 0) }}</div>
                <!-- <div style="text-align: center" v-if="status <= 1">
                  <v-btn class="ml-6 radius-15" color="#f2abab" outlined @click="removeProduct(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div> -->
              </v-card>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px;">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <v-icon dark>
                  mdi-account-supervisor
                </v-icon>
              </v-avatar>
              <span class="ml-3 title-card-text">ข้อมูลผู้รับผิดชอบ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ma-4 mb-n7 mt-10">
                <b class="ml-3 black--text">ผู้อนุมัติสั่งแปรรูป</b>
              </div>
              <v-row class="ma-4">
                <v-col xl="3" lg="3">

                    <v-select item-text="name" item-value="id" v-model="user_approve" :items="userList"
                      class="input-radius-search mt-3" label="" append-icon=""  hide-details="auto"  disabled
                      dense rounded></v-select>
                </v-col>
              </v-row>
              <div class="ma-4 mb-n5">
                <b class="ml-3 black--text">ขั้นตอนการสั่งแปรรูปและผู้รับผิดชอบ</b>
              </div>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">สั่งแปรรูป</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="name" item-value="id" :items="userList" v-model="user_order_process" disabled
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" append-icon="" dense rounded></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu2" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="order_process_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto" style="font-size: 18px;!important"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded required disabled 
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="order_process_date" @input="menu2 = false" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-2 mt-3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="order_process_detail" hide-details="auto"  rounded disabled
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">สั่งปริ้นใบเตรียมวัตถุดิบ</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="name" item-value="id" v-model="user_print" :items="userList" disabled
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" rounded append-icon="" dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15" 
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense rounded required disabled
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="print_date" @input="menu3 = false" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="print_detail" hide-details="auto"  rounded disabled
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>

              <section>
                <v-row class="ma-4 mb-0 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">เตรียมวัตถุดิบในการผลิต</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="name" item-value="id" v-model="user_prepare_mat" :items="userList" disabled
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" rounded append-icon="" dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu4" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="prepare_mat_date" label="วัน/เดือน/ปี" color="#FFBC15" 
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense rounded required disabled
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="prepare_mat_date" @input="menu3 = false" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="prepare_mat_detail" hide-details="auto"  rounded disabled
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>

              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">แปรรูปวัตถุดิบ</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="name" item-value="id" v-model="user_process" :items="userList" disabled
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" rounded append-icon="" dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu5" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="process_date" label="วัน/เดือน/ปี" color="#FFBC15" 
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense rounded required disabled
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="process_date" @input="menu5 = false" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="process_detail" hide-details="auto"  rounded disabled
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">QA Process</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="name" item-value="id" v-model="user_qa_process" :items="userList" disabled
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" rounded append-icon="" dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu6" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="qa_process_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded required disabled
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="qa_process_date" @input="menu6 = false" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="qa_process_detail" hide-details="auto" rounded disabled
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">QA Material</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select item-text="name" item-value="id" v-model="user_qa_material" :items="userList" disabled
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" rounded append-icon="" dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu7" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="qa_material_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded required disabled
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="qa_material_date" @input="menu7 = false" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="qa_material_detail" hide-details="auto"  rounded disabled
                      dense></v-text-field>
                  </v-col>
                </v-row>
              </section>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center" v-if="status == 0">
          <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="saveProduct()">
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/helper/handler";
import meterial from '@/api/warehouse/materials.js'
import process from '@/api/process/meterial_process.js'
import Swal from "sweetalert2";
export default {
  name: "ProcessingCreate",
  data() {
    return {
      loading_page: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      unit_list: [
        {
          id: 0,
          name: 'กรัม'
        },
        {
          id: 1,
          name: 'กิโลกรัม'
        },
      ],
      dataList: [],
      userList: [],
      data: [],
      unit_id: null,
      meterial_id: null,
      meterial_id_save: null,
      processing_date: null,
      user_approve: null,

      user_order_process: null,
      order_process_date: null,
      order_process_detail: null,

      user_print: null,
      print_date: null,
      print_detail: null,

      user_prepare_mat: null,
      user_prepare_mat_name: null,
      prepare_mat_date: null,
      prepare_mat_detail: null,

      user_process: null,
      process_date: null,
      process_detail: null,

      user_qa_process: null,
      qa_process_date: null,
      qa_process_detail: null,

      user_qa_material: null,
      qa_material_date: null,
      qa_material_detail: null,
      status: null,

      //test
      test: {},
    }
  },
  methods: {
    adjustDecimal(number) {
      if(number != 0 && number.toFixed(2) == 0){
        return 0.01
      }
      return formatPrice(number.toFixed(2));
    },
    linkToSteps() {
      this.$router.push('/prm/processing_raw_materials')
    },
    formatPrice(number) {
      return formatPrice(number);
    },
   async getMeterialList() {
     await meterial.getMeterialList().then((response) => {
        if(response.status == 502){
        window.location.reload()
      }
        if (response.data.successful === true) {
          this.dataList = response.data.data;
        }
      })
    },
    async getUserList() {
      await meterial.getUserList().then((response) => {
        if(response.status == 502){
        window.location.reload()
      }
        if (response.data.successful === true) {
          this.userList = response.data.data;
        }
      })
    },
    addProduct(qty = null) {
      let check = this.dataList.find(o => o.id === this.meterial_id);
      if (check != null) {
        this.data.splice(0)
        this.data.push(
          {
            id: check.id,
            mat_name: check.mat_name,
            batch_qty: check.batch_qty,
            grams: this.unit_id == 1 ? qty != null ? qty / 1000 : 0 : qty != null ? qty : 0
          }
        );
      }
       this.meterial_id_save = this.meterial_id
      this.$refs.dataList.reset()
    },
   async getEdit() {
      let id = this.$route.params.id;
      await process.getDatatoEdit(id).then((response) => {
        if(response.status == 502){
        window.location.reload()
      }
        if (response.data.successful === true) {
          this.unit_id = response.data.data.weight_type;
          this.meterial_id = response.data.data.material_id;
          this.processing_date = response.data.data.processing_date;
          this.processing_date = this.processing_date.split('/')[2] + '-' + this.processing_date.split('/')[1] + '-' + this.processing_date.split('/')[0];
          this.user_approve = response.data.data.user_approve;
          this.user_order_process = response.data.data.user_order_process;
          this.order_process_date = response.data.data.order_process_date;
          this.order_process_date = this.order_process_date.split('/')[2] + '-' + this.order_process_date.split('/')[1] + '-' + this.order_process_date.split('/')[0];
          this.order_process_detail = response.data.data.order_process_detail;

          this.user_print = response.data.data.user_print;
          this.print_date = response.data.data.print_date;
          this.print_date = this.print_date.split('/')[2] + '-' + this.print_date.split('/')[1] + '-' + this.print_date.split('/')[0];
          this.print_detail = response.data.data.print_detail;

          this.user_prepare_mat = response.data.data.user_prepare_mat;
          this.prepare_mat_date = response.data.data.prepare_mat_date;
          this.prepare_mat_date = this.prepare_mat_date.split('/')[2] + '-' + this.prepare_mat_date.split('/')[1] + '-' + this.prepare_mat_date.split('/')[0];
          this.prepare_mat_detail = response.data.data.prepare_mat_detail;

          this.user_process = response.data.data.user_process;
          this.process_date = response.data.data.process_date;
          this.process_date = this.process_date.split('/')[2] + '-' + this.process_date.split('/')[1] + '-' + this.process_date.split('/')[0];
          this.process_detail = response.data.data.process_detail;

          this.user_qa_process = response.data.data.user_qa_process;
          this.qa_process_date = response.data.data.qa_process_date;
          this.qa_process_date = this.qa_process_date.split('/')[2] + '-' + this.qa_process_date.split('/')[1] + '-' + this.qa_process_date.split('/')[0];
          this.qa_process_detail = response.data.data.qa_process_detail;

          this.user_qa_material = response.data.data.user_qa_material;
          this.qa_material_date = response.data.data.qa_material_date;
          this.qa_material_date = this.qa_material_date.split('/')[2] + '-' + this.qa_material_date.split('/')[1] + '-' + this.qa_material_date.split('/')[0];
          this.qa_material_detail = response.data.data.qa_material_detail;
          let qty = response.data.data.desired_qty;
          this.status = response.data.data.status_process;
          this.addProduct(qty);
        }
      })
    },
    saveProduct() {
      this.loading_page = true;
      let data = {
        id: parseFloat(this.$route.params.id),
        processing_date: this.processing_date,
        production_id: null,
        weight_type: this.unit_id,
        material_id: this.meterial_id_save,

        desired_qty: this.unit_id == 1 ? (parseFloat(this.data[0].grams) * 1000)+'' : this.unit_id == 0 ? this.data[0].grams : 0,
        batch_qty: this.data[0].batch_qty,
        batch_count: this.unit_id == 0 ? parseFloat(this.data[0].grams) / parseFloat(this.data[0].batch_qty) : this.unit_id == 1 ? parseFloat(this.data[0].grams * 1000) / (parseFloat(this.data[0].batch_qty)) : 0,

        user_approve: this.user_approve,

        user_order_process: this.user_order_process,
        order_process_date: this.order_process_date,
        order_process_detail: this.order_process_detail,

        user_print: this.user_print,
        print_date: this.print_date,
        print_detail: this.print_detail,

        user_prepare_mat: this.user_prepare_mat,
        prepare_mat_date: this.prepare_mat_date,
        prepare_mat_detail: this.prepare_mat_detail,

        user_process: this.user_process,
        process_date: this.process_date,
        process_detail: this.process_detail,

        user_qa_process: this.user_qa_process,
        qa_process_date: this.qa_process_date,
        qa_process_detail: this.qa_process_detail,

        user_qa_material: this.user_qa_material,
        qa_material_date: this.qa_material_date,
        qa_material_detail: this.qa_material_detail
      }
      this.test = data;
      process.edit(data).then((response) => {
        if (response.data.successful === true) {
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/prm/processing_steps/" + this.$route.params.id);
            }
          });
        }
      })
    },
    removeProduct(index) {
      this.data.splice(index, 1);
    },
    async getFuction(){
       await this.getMeterialList();
       await this.getUserList();
       await this.getEdit();
    },
  },
  mounted() {
    this.getFuction();
  }
}
</script>

<style scoped>
.v-input__icon{
  display: none !important;
}
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 10% 36% 20% 24% 10%;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 26% 23% 15% 27% 10%;
  align-items: baseline;
}</style>