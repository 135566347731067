<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">
        สร้างรอบการผลิตสินค้า
      </div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/pro/processing_product_good')">การผลิตสินค้า</div>
      <div class="topic_processing_content my-2">
        <v-icon>mdi-chevron-right</v-icon>
      </div>
      <div class="topic_processing_content my-2">สร้างรอบการผลิตสินค้า</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนที่ 7 : ยืนยันการผลิตสำเร็จ</b>
        </v-card-title>
        <v-card-text>
          <!-- ขั้นตอนที่ 1 : สร้างใบสั่งผลิต -->
          <v-card elevation="4" class="py-2 my-5" style="border-radius: 20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/process (2).png" />
              </v-avatar>
            </div>
            <v-card-title>
              <v-row>
                <v-col cols="10">
                  <span class="ml-3 title-card-text"
                    >ขั้นตอนที่ 1 : สร้างใบสั่งผลิต</span
                  >
                </v-col>
                <v-col cols="2" class="d-right-flex">
                  <v-btn
                    v-if="$route.params.status"
                    color="#88B358"
                    elevation="4"
                    @click="
                      getPDF(
                        base_url + 'production/pdf/stepOne/' + $route.params.id
                      )
                    "
                    rounded
                    outlined
                  >
                    <v-icon left>mdi-printer-outline</v-icon>
                    พิมพ์ใบสั่งผลิต
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <v-row>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">วันที่ใบสั่งผลิต :</div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.production_date }}
                        </div>
                      </div>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">เวลาสั่งผลิต :</div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.production_time }} น.
                        </div>
                      </div>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">อนุมัติสั่งผลิต :</div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.user_approve_name }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="tag-title-step my-3">
                  <b class="black--text"
                    >รายการขนาดสินค้า {{ firstStepList.pro_name }}</b
                  >
                </div>
                <div>
                  <div
                    class="table-header-processing mt-4"
                    style="color: #b1adad"
                  >
                    <div class="table-sub-header-processing">ขนาดสินค้า</div>
                    <div class="table-sub-header-processing">จำนวนต่อหน่วย</div>
                    <div class="table-sub-header-processing">
                      จำนวนกรัมการผลิต
                    </div>
                  </div>
                  <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                  <div
                    class="grid-processing-items ml-3"
                    v-for="item in firstStepList.size_list"
                    :key="item"
                  >
                    <div class="black--text">ขนาด {{ item.pro_size_name }}</div>
                    <div class="black--text">{{ item.qty }}</div>
                    <div class="black--text">
                      {{ formatPrice(item.qty_gram) }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div></div>
                    <div class="summarize-header my-5 pa-3">
                      <h3 class="text-center text-green" style="width: 400px">
                        จำนวนกรัมการผลิต
                        {{ formatPrice(firstStepList.desired_qty) }} กรัม /
                        {{ firstStepList.batch_count }} Batch
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="tag-title-step my-3">
                  <b class="black--text">ขั้นตอนการสั่งแปรรูปและผู้รับผิดชอบ</b>
                </div>
                <div>
                  <div
                    class="table-header-processing-second mt-4"
                    style="color: #b1adad"
                  >
                    <div class="table-sub-header-processing">No.</div>
                    <div class="table-sub-header-processing">ขั้นตอน</div>
                    <div class="table-sub-header-processing">ผู้รับผิดชอบ</div>
                    <div class="table-sub-header-processing">วัน/เดือน/ปี</div>
                    <div class="table-sub-header-processing">รายละเอียด</div>
                  </div>
                  <div>
                    <div>
                      <div class="grid-processing-items-second ml-3">
                        <div class="black--text font-weight-bold py-1">1.</div>
                        <div class="black--text font-weight-bold">สั่งผลิต</div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.user_order_production_name }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.order_production_date }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.order_production_detail }}
                        </div>
                      </div>
                      <div class="grid-processing-items-second ml-3">
                        <div class="black--text font-weight-bold py-1">2.</div>
                        <div class="black--text font-weight-bold">
                          เตรียมวัตถุดิบ
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.user_prepare_mat_name }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.prepare_mat_date }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.prepare_mat_detail }}
                        </div>
                      </div>
                      <div class="grid-processing-items-second ml-3">
                        <div class="black--text font-weight-bold py-1">3.</div>
                        <div class="black--text font-weight-bold">
                          บันทึกการผลิต
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.user_save_name }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.save_date }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.save_detail }}
                        </div>
                      </div>
                      <div class="grid-processing-items-second ml-3">
                        <div class="black--text font-weight-bold py-1">4.</div>
                        <div class="black--text font-weight-bold">
                          บรรจุภัณฑ์
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.user_package_name }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.package_date }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.package_detail }}
                        </div>
                      </div>
                      <div class="grid-processing-items-second ml-3">
                        <div class="black--text font-weight-bold py-1">5.</div>
                        <div class="black--text font-weight-bold">
                          ตรวจสอบการผลิต
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.user_qa_production_name }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.qa_production_date }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.qa_production_detail }}
                        </div>
                      </div>
                      <div class="grid-processing-items-second ml-3">
                        <div class="black--text font-weight-bold py-1">6.</div>
                        <div class="black--text font-weight-bold">
                          ตรวจสอบคุณภาพสินค้า
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.user_qa_product_name }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.qa_product_date }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ firstStepList.qa_product_detail }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <!-- ขั้นตอนที่ 2 : ใบเตรียมวัตถุดิบ -->
          <v-card
            elevation="4"
            class="py-2 my-10"
            style="border-radius: 20px"
            v-if="batchList.batch.length != 0"
          >
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/flour (4).png" />
              </v-avatar>
            </div>
            <v-card-title>
              <v-row>
                <v-col cols="10">
                  <span class="ml-3 title-card-text"
                >ขั้นตอนที่ 2 : ใบเตรียมวัตถุดิบ</span
              >
                </v-col>
                <v-col cols="2" class="d-right-flex">
                  <v-btn
                    v-if="$route.params.status"
                    color="#88B358"
                    elevation="4"
                    @click="
                      getPDF(
                        base_url + 'production/pdf/stepTwo/' + $route.params.id
                      )
                    "
                    rounded
                    outlined
                  >
                    <v-icon left>mdi-printer-outline</v-icon>
                     พิมพ์ใบเตรียมวัตถุดิบ 
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <v-row>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">วันที่ใบเตรียมวัตถุดิบ :</div>
                        <div class="black--text font-weight-bold">
                          {{ batchList.prepare_mat_date }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <div class="mt-2">
                    <div class="divider-table my-2"></div>
                    <div class="scroll-menu-last mt-4">
                      <section>
                        <div class="w-border-header-first mb-n3" />
                        <div class="card-main-table">
                          <div class="header-card">
                            <div class="py-3 d-flex justify-space-between">
                              <div class="black--text font-weight-bold">
                                วัตถุดิบ
                              </div>
                              <div class="black--text font-weight-bold">
                                จำนวน (สููตรต่อ Batch)
                              </div>
                            </div>
                          </div>
                          <div class="content-card">
                            <v-row class="pt-2">
                              <v-col
                                xl="12"
                                lg="12"
                                class="inline-box-specific"
                              >
                                <div
                                  class="black--text font-weight-bold text-center"
                                >
                                  การ weight % จากสููตร
                                </div>
                              </v-col>
                            </v-row>
                            <v-row
                              v-for="item in batchList.materail_list"
                              :key="item"
                            >
                              <v-col cols="12">
                                <!-- <v-row>
                                  <div class="tag-title-step-table mt-2">
                                    <b class="black--text">ส่วนที่ {{ i + 1 }}</b>
                                  </div>
                                </v-row> -->
                                <v-row class="pt-2">
                                  <v-col
                                    xl="6"
                                    lg="6"
                                    style="
                                      margin-top: 2px;
                                      white-space: initial;
                                    "
                                  >
                                    <div class="black--text font-weight-bold">
                                      {{ item.mat_name }}
                                    </div>
                                  </v-col>
                                  <v-col xl="6" lg="6" style="margin-top: 2px">
                                    <div class="black--text font-weight-bold">
                                      {{ formatPrice(item.receipt_qty) }} กรัม
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-divider
                                  class="ml-2 mr-2 mt-2"
                                  dark
                                  color="#d9d9d9"
                                ></v-divider>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-card my-5">
                            <v-row class="pt-2" style="align-self: flex-end">
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">
                                  รวม (FG)
                                </div>
                              </v-col>
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">
                                  {{ formatPrice(batchList.batch_qty) }} กรัม
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                      <section
                        class="last pe"
                        style="margin-right: 2%"
                        v-for="(detail, index) in batchList.batch"
                        :key="index"
                      >
                        <div class="w-border-header-sub-last mb-n3" />
                        <div class="card-sub-table-last">
                          <div>
                            <div class="py-3">
                              <div class="black--text font-weight-bold">
                                Batch {{ index + 1 }}
                              </div>
                            </div>
                            <div
                              class="black--text font-weight-bold text-center"
                            >
                              {{ parseFloat(detail.percent).toFixed(2) }} %
                            </div>
                          </div>
                          <div>
                            <v-divider
                              class="ml-2 mr-2 mt-5"
                              dark
                              color="#d9d9d9"
                            ></v-divider>
                            <v-row
                              v-for="item in detail.batch_list"
                              :key="item"
                            >
                              <v-col cols="12">
                                <v-row>
                                  <v-col cols="12">
                                    <v-row class="pt-2">
                                      <v-col xl="12" lg="12">
                                        <div
                                          class="black--text font-weight-bold text-center"
                                        >
                                          {{ formatPrice(item.mat_qty) }} กรัม
                                        </div>
                                        <!-- <div class="font-weight-bold text-center">{{ sub.material_lot }}</div> -->
                                      </v-col>
                                    </v-row>
                                    <v-divider
                                      class="ml-2 mr-2 mt-2"
                                      dark
                                      color="#d9d9d9"
                                    ></v-divider>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-card-last">
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div
                                  class="black--text font-weight-bold text-center"
                                  style="vertical-align: bottom"
                                >
                                  รวม {{ formatPrice(detail.batch_qty) }} กรัม
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div class="summarize my-5 pa-5">
                      <h3 class="black--text font-weight-bold text-center">
                        ผลลัพธ์ที่จะได้รับจากการผลิต
                        {{ formatPrice(total_result) }} กรัม หรือ
                        {{ formatPrice(total_result / 1000) }} กิโลกรัม
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <!-- ขั้นตอนที่ 3 : บันทึกผลการผลิต (วันหมดอายุของสินค้า FG : 23/10/2023) -->
          <v-card
            elevation="4"
            class="py-2 my-10"
            style="border-radius: 20px"
            v-if="timeList.exp_date != null"
          >
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/editing(2).png" />
              </v-avatar>
            </div>
            <v-card-title>
              <v-row>
                <v-col cols="10">
                  <span class="ml-3 title-card-text"
                >ขั้นตอนที่ 3 : บันทึกผลการผลิต (วันหมดอายุของสินค้า FG :
                {{ timeList.exp_date }})</span
              >
                </v-col>
                <v-col cols="2" class="d-right-flex">
                  <v-btn
                    v-if="$route.params.status"
                    color="#88B358"
                    elevation="4"
                    @click="
                      getPDF(
                        base_url + 'production/pdf/stepThree/' + $route.params.id
                      )
                    "
                    rounded
                    outlined
                  >
                    <v-icon left>mdi-printer-outline</v-icon>
                     พิมพ์ใบรายงานการผลิด 
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">ขั้นตอนที่ 3 : บันทึกผลการผลิต</b>
                  </div>
                  <div class="divider-table my-2" />
                  <div class="scroll-menu-time mt-2">
                    <section class="mb-3">
                      <div class="w-border-header-first mb-n3" />
                      <div class="card-main-table-time" style="padding: 15px">
                        <v-col xl="12" lg="12">
                          <div
                            class="black--text font-weight-bold text-center ma-2 mt-0"
                          >
                            เวลาที่ใช้ในการแปรรูป
                          </div>
                        </v-col>
                        <v-divider
                          class="mb-3"
                          dark
                          color="#a3a3a3"
                        ></v-divider>
                        <v-col xl="12" lg="12">
                          <div
                            class="black--text font-weight-bold text-center ma-2 mt-0"
                          >
                            เวลาเริ่มต้นการแปรรูป
                          </div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div
                            class="black--text font-weight-bold text-center ma-2 mt-0"
                          >
                            เวลาสิ้นสุดการแปรรูป
                          </div>
                        </v-col>
                      </div>
                    </section>
                    <section
                      class="mb-3 time"
                      v-for="(detail, index) in timeList.batch"
                      :key="index"
                    >
                      <div class="w-border-header-sub-last mb-n3" />
                      <div class="card-sub-table-time" style="padding: 15px">
                        <div class="pt-3 pb-5">
                          <div class="black--text font-weight-bold">
                            Batch {{ index + 1 }}
                          </div>
                        </div>
                        <v-divider
                          class="mb-3"
                          dark
                          color="#a3a3a3"
                        ></v-divider>
                        <v-col xl="12" lg="12">
                          <div
                            class="black--text font-weight-bold text-center ma-2 mt-0"
                          >
                            {{ detail.start_date }}
                          </div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div
                            class="black--text font-weight-bold text-center ma-2 mt-0"
                          >
                            {{ detail.end_date }}
                          </div>
                        </v-col>
                      </div>
                    </section>
                  </div>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">การบันทึกผลการผลิต</b>
                  </div>
                  <div class="divider-table my-2" />
                  <div class="ml-5">
                    <b class="black--text">ส่วนที่ 1</b>
                  </div>
                  <div class="scroll-menu-time mt-2">
                    <section>
                      <div class="w-border-header-first mb-n3" />
                      <div class="card-main-table">
                        <div class="header-card">
                          <div class="py-3 d-flex justify-space-between">
                            <div class="black--text font-weight-bold">
                              วัตถุดิบ
                            </div>
                            <div class="black--text font-weight-bold">
                              จำนวน (สููตรต่อ Batch)
                            </div>
                          </div>
                        </div>
                        <div class="content-card">
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12" class="inline-box-specific">
                              <div
                                class="black--text font-weight-bold text-center"
                              >
                                การ weight % จากสููตร
                              </div>
                            </v-col>
                          </v-row>
                          <v-row
                            v-for="item in batchList.materail_list"
                            :key="item"
                          >
                            <v-col cols="12">
                              <!-- <v-row>
                                <div class="tag-title-step-table mt-2">
                                  <b class="black--text">ส่วนที่ {{ i + 1 }}</b>
                                </div>
                              </v-row> -->
                              <v-row class="pt-2">
                                <v-col
                                  xl="6"
                                  lg="6"
                                  style="margin-top: 2px; white-space: initial"
                                >
                                  <div class="black--text font-weight-bold">
                                    {{ item.mat_name }}
                                  </div>
                                </v-col>
                                <v-col xl="6" lg="6" style="margin-top: 2px">
                                  <div class="black--text font-weight-bold">
                                    {{ formatPrice(item.receipt_qty) }} กรัม
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider
                                class="ml-2 mr-2 mt-2"
                                dark
                                color="#d9d9d9"
                              ></v-divider>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="footer-card my-5">
                          <v-row class="pt-2" style="align-self: flex-end">
                            <v-col xl="6" lg="6">
                              <div class="black--text font-weight-bold">
                                รวม (FG)
                              </div>
                            </v-col>
                            <v-col xl="6" lg="6">
                              <div class="black--text font-weight-bold">
                                {{ formatPrice(batchList.batch_qty) }} กรัม
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </section>
                    <section
                      class="last pe"
                      style="margin-right: 2%"
                      v-for="(detail, index) in timeList.batch"
                      :key="index"
                    >
                      <div class="w-border-header-sub-last mb-n3" />
                      <div class="card-sub-table-last">
                        <div>
                          <div class="py-3">
                            <div class="black--text font-weight-bold">
                              Batch {{ index + 1 }}
                            </div>
                          </div>
                          <div class="black--text font-weight-bold text-center">
                            {{ parseFloat(detail.percent).toFixed(2) }} %
                          </div>
                        </div>
                        <div>
                          <v-divider
                            class="ml-2 mr-2 mt-5"
                            dark
                            color="#d9d9d9"
                          ></v-divider>
                          <v-row v-for="item in detail.batch_list" :key="item">
                            <v-col cols="12">
                              <v-row v-for="sub in item.stock_list" :key="sub">
                                <v-col cols="12">
                                  <v-row class="pt-2">
                                    <v-col xl="12" lg="12">
                                      <div
                                        class="black--text font-weight-bold text-center"
                                      >
                                        {{ formatPrice(sub.quantity_cut) }} กรัม
                                      </div>
                                      <div class="font-weight-bold text-center">
                                        {{ sub.material_lot }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-divider
                                    class="ml-2 mr-2 mt-2"
                                    dark
                                    color="#d9d9d9"
                                  ></v-divider>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="footer-card-last">
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12">
                              <div
                                class="black--text font-weight-bold text-center"
                                style="vertical-align: bottom"
                              >
                                รวม
                                {{
                                  formatPrice(detail.qty_after_production)
                                }}
                                กรัม
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="summarize my-5 pa-5">
                  <h3 class="black--text font-weight-bold text-center">
                    ผลลัพธ์ที่จะได้รับจากการผลิต
                    {{ formatPrice(total_result_next) }} กรัม หรือ
                    {{ formatPrice(total_result_next / 1000) }} กิโลกรัม
                  </h3>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text"
                      >วัตถุดิบที่เสียหายหรือทิ้งไปจากการผลิตรอบนี้</b
                    >
                  </div>
                  <div class="divider-table my-2" />
                  <div>
                    <div
                      class="table-header-processing-details mt-4"
                      style="color: #b1adad"
                    >
                      <div class="table-sub-header-processing">No.</div>
                      <div class="table-sub-header-processing">
                        ชื่อวัตถุดิบ
                      </div>
                      <div class="table-sub-header-processing">เลข Lot</div>
                      <div class="table-sub-header-processing">จำนวนกรัม</div>
                    </div>
                    <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                    <div
                      v-for="(item, i) in timeList.material_drop_list"
                      :key="item"
                    >
                      <div class="grid-processing-items-details ml-3">
                        <div class="black--text font-weight-bold py-1">
                          {{ i + 1 }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ item.mat_name }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ item.material_lot }}
                        </div>
                        <div class="black--text font-weight-bold">
                          {{ formatPrice(item.drop_qty) }} กรัม
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <!-- ขั้นตอนที่ 4 : การบรรจุภัณฑ์ -->
          <v-card
            elevation="4"
            class="py-2 px-3"
            style="border-radius: 20px"
            v-if="mock_header.size_list.find(item => item.material_list.length > 0)"
          >
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/cereal (1).png" />
              </v-avatar>
            </div>
            <v-card-title>
               <v-row>
                <v-col cols="10">
                  <span class="ml-3 title-card-text"
                >ขั้นตอนที่ 4 : การบรรจุภัณฑ์</span
              >
                </v-col>
                <v-col cols="2" class="d-right-flex">
                  <v-btn
                    v-if="$route.params.status"
                    color="#88B358"
                    elevation="4"
                    @click="
                      getPDF(
                        base_url + 'production/pdf/stepFour/' + $route.params.id
                      )
                    "
                    rounded
                    outlined
                  >
                    <v-icon left>mdi-printer-outline</v-icon>
                     พิมพ์ใบรายงานการบรรจุภัณฑ์ 
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
            <v-card-text class="pl-12">
              <v-row class="mb-2">
                <v-col
                  v-for="(loop_header, index_header) in mock_header.size_list"
                  :key="index_header"
                  class="card-table-radius-sub"
                  cols="12"
                  lg="6"
                  xl="6"
                >
                  <v-card elevation="2" class="border-card">
                    <v-row class="ma-1 go-to-card">
                      <v-col cols="6 flex-center-header">
                        <div class="tag-title-step-card">
                          <b class="black--text">{{
                            formatPrice(loop_header.pro_amount)
                          }}</b>
                        </div>
                        <span> {{ loop_header.pro_name }} </span>
                      </v-col>
                      <v-col cols="6 text-right flex-center-header-two">
                        <span
                          >จำนวน {{ formatPrice(loop_header.qty) }}
                          {{ loop_header.unit_name }}</span
                        >
                      </v-col>

                      <v-col cols="12 py-1 col-harder">
                        <v-divider
                          class="border-main pt-1"
                          dark
                          color=""
                        ></v-divider>
                      </v-col>

                      <v-col cols="4 pb2">
                        <span>วัตถุดิบ </span>
                      </v-col>
                      <v-col cols="4 pb-2">
                        <span>เลข Lot </span>
                      </v-col>
                      <v-col cols="4 px-0 pb-2 ">
                        <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="ma-1"
                      v-for="(loop_sub, index) in loop_header.material_list"
                      :key="index"
                    >
                      <v-col cols="4 pt-0 ">
                        <span> {{ loop_sub.mat_name }}</span>
                      </v-col>

                      <v-col cols="8  pt-0">
                        <v-row
                          v-for="loop_lot in loop_sub.stock_list"
                          :key="loop_lot"
                        >
                          <v-col cols="6">
                            <div>{{ loop_lot.material_lot }}</div>
                          </v-col>
                          <v-col cols="6">
                            <div>{{ formatPrice(loop_lot.quantity_cut) }}</div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <div class="black--text font-weight-bold ma-2">
                จำนวนสินค้า FG ที่คงเหลือจากการบรรจุภัณฑ์ :
                {{ formatPrice(mock_header.product_qty_remain) }} กรัม
              </div>
            </v-card-text>
          </v-card>

          <!-- ขั้นตอนที่ 5 : การตรวจสอบกระบวนการผลิต -->
          <v-card
            elevation="4"
            class="py-2 my-10"
            style="border-radius: 20px"
            v-if="dataList.length != 0"
          >
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/shield(2).png" />
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text"
                >รายละเอียดขั้นตอนที่ 5 : การตรวจสอบกระบวนการผลิต</span
              >
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div
                  class="table-header-processing mt-6"
                  style="color: #b1adad"
                >
                  <div class="table-sub-header-processing">ชื่อเกณฑ์</div>
                  <div class="table-sub-header-processing">เกณฑ์คุณภาพ</div>
                  <div class="table-sub-header-processing">Value</div>
                </div>
                <v-card
                  elevation="4"
                  class="my-5 py-2"
                  style="border-radius: 20px"
                  v-for="(item, i) in dataList"
                  :key="item"
                >
                  <div class="table-header-processing py-4 pb-0">
                    <div
                      class="table-sub-header-processing black--text font-weight-bold"
                    >
                      {{ item.fq_name }}
                    </div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div
                    class="grid-processing-items ma-5 pl-3 pr-3"
                    v-for="(detail, index) in item.quality_list"
                    :key="index"
                  >
                    <div class="black--text font-weight-bold">
                      {{ detail.fq_list_name }}
                    </div>
                    <div class="black--text font-weight-bold">
                      {{ detail.fq_list_guide }}
                    </div>
                    <div class="pl-3">
                      <p
                        v-if="
                          detail.fq_list_type == 0 &&
                          detail.fq_value_status == 1 &&
                          detail.fq_value_boolean == null
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 1)" color="#88B358"
                          >mdi-checkbox-blank-circle-outline</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.fq_list_type == 0 &&
                          detail.fq_value_status == 0 &&
                          detail.fq_value_boolean == 0
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 1)" color="#F28080"
                          >mdi-close-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.fq_list_type == 0 &&
                          detail.fq_value_status == 0 &&
                          detail.fq_value_boolean == 1
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 0)" color="#88B358"
                          >mdi-check-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.fq_list_type == 0 &&
                          detail.fq_value_status == 1 &&
                          detail.fq_value_boolean == 0
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 1)" color="#F28080"
                          >mdi-close-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.fq_list_type == 0 &&
                          detail.fq_value_status == 1 &&
                          detail.fq_value_boolean == 1
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 0)" color="#88B358"
                          >mdi-check-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="detail.fq_list_type == 1"
                        class="input-radius font-16"
                        color="#FFBC15"
                        label="value"
                      >
                        {{ detail.value }}
                      </p>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            elevation="4"
            class="py-2 my-10"
            style="border-radius: 20px"
            v-if="qualityList.length != 0"
          >
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/quality (1).png" />
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text"
                >รายละเอียดขั้นตอนที่ 6 :
                การตรวจสอบวัตถุดิบที่ผลิตสำเร็จ</span
              >
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div
                  class="table-header-processing-step6 mt-6"
                  style="color: #b1adad"
                >
                  <div class="table-sub-header-processing">
                    ชื่อเกณฑ์ (English)
                  </div>
                  <div class="table-sub-header-processing">
                    ชื่อเกณฑ์ (ภาษาไทย)
                  </div>
                  <div class="table-sub-header-processing">Unit</div>
                  <div
                    class="table-sub-header-processing ml-3"
                    style="color: #ffb98a"
                  >
                    Aim
                  </div>
                  <div
                    class="table-sub-header-processing ml-n1"
                    style="color: #13b65d"
                  >
                    Green
                  </div>
                  <div
                    class="table-sub-header-processing ml-n2"
                    style="color: #ddbb55"
                  >
                    Yellow
                  </div>
                  <div
                    class="table-sub-header-processing ml-n3"
                    style="color: #c00000"
                  >
                    Red
                  </div>
                  <div class="table-sub-header-processing ml-n3">Value</div>
                </div>
                <v-card
                  elevation="4"
                  class="my-10"
                  style="border-radius: 20px"
                  v-for="(item, i) in qualityList"
                  :key="item"
                >
                  <div class="table-header-processing-step6 py-4 pb-0">
                    <div
                      class="table-sub-header-processing black--text font-weight-bold"
                    >
                      {{ item.pq_name_eng }}
                    </div>
                    <div
                      class="table-sub-header-processing black--text font-weight-bold"
                    >
                      {{ item.pq_name_thai }}
                    </div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div
                    class="grid-processing-items-step6 mt-0 mb-n4 pl-3 pr-3"
                    v-for="(detail, index) in item.quality_list"
                    :key="index"
                  >
                    <div class="black--text font-weight-bold py-5">
                      {{ detail.pq_list_eng }}
                    </div>
                    <div class="black--text font-weight-bold">
                      {{ detail.pq_list_thai }}
                    </div>
                    <div>{{ detail.unit }}</div>
                    <div class="ml-3">{{ detail.pq_list_aim }}</div>
                    <div>{{ detail.pq_list_green }}</div>
                    <div>{{ detail.pq_list_yellow }}</div>
                    <div>{{ detail.pq_list_red }}</div>
                    <div>
                      <p
                        v-if="
                          detail.pq_list_type == 0 &&
                          detail.pq_value_status == 1 &&
                          detail.pq_value_boolean == null
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 1)" color="#88B358"
                          >mdi-checkbox-blank-circle-outline</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.pq_list_type == 0 &&
                          detail.pq_value_status == 0 &&
                          detail.pq_value_boolean == 0
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 1)" color="#F28080"
                          >mdi-close-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.pq_list_type == 0 &&
                          detail.pq_value_status == 0 &&
                          detail.pq_value_boolean == 1
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 0)" color="#88B358"
                          >mdi-check-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.pq_list_type == 0 &&
                          detail.pq_value_status == 1 &&
                          detail.pq_value_boolean == 0
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 1)" color="#F28080"
                          >mdi-close-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="
                          detail.pq_list_type == 0 &&
                          detail.pq_value_status == 1 &&
                          detail.pq_value_boolean == 1
                        "
                        class="mb-0"
                      >
                        <v-icon @click="getcheck(i, index, 0)" color="#88B358"
                          >mdi-check-circle</v-icon
                        >
                      </p>
                      <p
                        v-if="detail.pq_list_type == 1"
                        class="input-radius font-16"
                      >
                        {{ detail.value }}
                      </p>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            elevation="4"
            class="py-2 my-10"
            style="border-radius: 20px"
            v-if="step7.user_edit != null"
          >
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/approved(2).png" />
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text"
                >รายละเอียดขั้นตอนที่ 7 : ยืนยันการผลิตสำเร็จ</span
              >
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <v-row>
                  <v-col xl="4" lg="4">
                    <div class="d-flex">
                      <div class="ml-2">วันที่ดำเนินการ :</div>
                      <div class="black--text">{{ step7.user_edit }}</div>
                    </div>
                  </v-col>
                  <v-col xl="4" lg="4">
                    <div class="d-flex">
                      <div class="ml-2">ผู้ดำเนินการ :</div>
                      <div class="black--text">
                        {{
                          `${step7.success_date.split("/")[0]}-${
                            step7.success_date.split("/")[1]
                          }-${step7.success_date.split("/")[2]}`
                        }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn
            class="white--text"
            color="#88B358"
            elevation="4"
            rounded
            large
            @click="modal = true"
            v-if="!$route.params.status"
          >
            <v-icon left>mdi-content-save</v-icon> ยืนยันการผลิตสำเร็จ
          </v-btn>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-model="modal"
      transition="dialog-top-transition"
      max-width="400"
      style="border-radius: 20px"
    >
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="modal = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="d-flex flex-column align-center">
            <img class="icon-image mb-3" src="@/assets/buying.png" />
          </div>
          <div class="text-center">
            <h1 class="black--text">ยืนยันการผลิตสินค้าสำเร็จ</h1>
            <div class="my-2">
              คุณต้องการยืนยันการผลิตสินค้าสำเร็จใช่หรือไม่
            </div>
          </div>
          <div class="mt-3 text-center">
            <v-btn
              class="white--text"
              color="#88B358"
              elevation="4"
              rounded
              large
              @click="saveData()"
            >
              <v-icon left>mdi-content-save</v-icon> ตกลง
            </v-btn>
            <div class="ma-4" style="cursor: pointer">ยกเลิก</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import production from "@/api/product_good/product_process_step_seven.js";
import { formatPrice } from "@/helper/handler";
import Swal from "sweetalert2";
export default {
  name: "ProductionConfirm",
  data() {
    return {
      modal: false,
      firstStepList: [],
      batchList: [],
      timeList: [],
      dataList: [],
      secondList: [
        {
          eng: "Appearance",
          th: "ข้าวเป็นเม็ดสวยไม่แฉะ",
          unit: "-",
          aim: "-",
          green: "-",
          yellow: "-",
          red: "-",
          value: true,
        },
        {
          eng: "Texture",
          th: "ไม่เละไม่เป็นไต",
          unit: "-",
          aim: "-",
          green: "-",
          yellow: "-",
          red: "-",
          value: false,
        },
        {
          eng: "Flavor",
          th: "ไม่มีกลิ่นเหม็นอับ",
          unit: "-",
          aim: "-",
          green: "-",
          yellow: "-",
          red: "-",
          value: true,
        },
      ],
      qualityList: [],
      solarList: [
        {
          name: "Temp",
          detail: "45.0 - 70.0 C",
          value: "ok",
        },
        {
          name: "DateStart",
          detail: "-",
          value: "Pass",
        },
        {
          name: "TimeStart",
          detail: "-",
          value: "ไม่รั่ว",
        },
        {
          name: "DateEnd",
          detail: "-",
          value: "500.2",
        },
        {
          name: "TimeEnd",
          detail: "-",
          value: "500",
        },
        {
          name: "Time",
          detail: ">6.0 hr",
          value: "500",
        },
        {
          name: "Moisture : Dried Rice",
          detail: "9.0 - 11.0",
          value: "689",
        },
      ],
      mock_header: [],
      step7: {},
      base_url: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    getPDF(text) {
      window.open(text, '_blank');
    },
    linkTo() {
      this.$router.push("/prm/processing_materials");
    },
    link(link) {
      this.$router.push(link)
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    //step 1
    getDetail_step1() {
      let id = parseFloat(this.$route.params.id);
      this.loading_page = true;
      production
        .getDetail_step1(id)
        .then((response) => {
          if (response.status == 502) {
            window.location.reload();
          }
          if (response.data.successful === true) {
            this.firstStepList = response.data.step_1;
            this.batchList = response.data.step_2;
            this.timeList = response.data.step_3;
            this.mock_header = response.data.step_4;
            this.dataList = response.data.step_5.quality_list;
            this.qualityList = response.data.step_6.quality_list;
            this.step7 = response.data.step_7;
            this.loading_page = false;
            this.getPrepare();
          }
        })
        .catch((error) => {
          if (error.response.status === 502) {
            window.location.reload();
          }
        });
    },
    getPrepare() {
      let totalall = 0;
      for (let index = 0; index < this.batchList.batch.length; index++) {
        totalall += parseFloat(this.batchList.batch[index].batch_qty);
        // for(let a = 0; a < this.materail_list[index].batch.length; a++){
        //   totalall += parseFloat(this.materail_list[index].batch[a].batch_qty)
        // }
      }
      this.total_result = totalall;
      totalall = 0;
      for (let index = 0; index < this.timeList.batch.length; index++) {
        totalall += parseFloat(this.timeList.batch[index].qty_after_production);
        // for(let a = 0; a < this.materail_list[index].batch.length; a++){
        //   totalall += parseFloat(this.materail_list[index].batch[a].batch_qty)
        // }
      }
      this.total_result_next = totalall;
    },
    confirmSave() {
      this.modal = true;
    },
    saveData() {
      this.loading_page = true;
      let id = parseFloat(this.$route.params.id);
      this.loading_page = true;
      production.saveStep6(id).then((response) => {
        if (response.data.successful === true) {
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.href = "/pro/processing_product_good";
            }
          });
          this.loading_page = false;
        }
      });
    },
  },
  mounted() {
    this.getDetail_step1();
  },
};
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7e7e7e;
  padding-left: 10px;
}

.header-png {
  position: absolute;
  top: -15px;
  left: 20px;
}

.icon-image {
  width: 120px;
  height: 120px;
  text-align: center;
}

.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}

.tag-title-step {
  background-color: #fff8e8;
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.tag-title-step-table {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.table-header-processing-step6 {
  display: grid;
  grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
}

.table-header-processing-second {
  display: grid;
  grid-template-columns: 5% 20% 25% 25% 25%;
}

.table-header-processing-details {
  display: grid;
  grid-template-columns: 10% 25% 25% 40%;
}

.table-header-processing-steps {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
}

.table-header-processing-recheck {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: baseline;
}

.grid-processing-items-step6 {
  display: grid;
  grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
  align-items: baseline;
}

.grid-processing-items-second {
  display: grid;
  grid-template-columns: 5% 20% 25% 25% 25%;
  align-items: baseline;
}

.grid-processing-items-details {
  display: grid;
  grid-template-columns: 10% 25% 25% 40%;
  align-items: baseline;
}

.grid-processing-items-steps {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
  align-items: baseline;
}

.grid-processing-items-recheck {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  align-items: baseline;
}

.summarize-header {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
  inline-size: fit-content;
}

.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}

.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}

/* Part 1 */
.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: 120%;
}

.inline-box-specific {
  background: #fff8e8;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
}

.w-border-header-sub-last {
  background: #34230f;
  border: 7px solid #34230f;
  border-radius: 36px;
  width: auto;
}

.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding: 15px;
  width: 120%;
  height: 99%;
  background: #fff;
}

.card-main-table .header-card {
}

.card-main-table .content-card {
}

.card-main-table .footer-card {
  position: inherit;
  bottom: 0;
  width: 85%;
}

.card-sub-table-last {
  border: 1px solid #34230f;
  border-radius: 15px;
  padding: 15px;
  max-width: 100%;
  width: 100%;
  height: 99%;
  transition: 0.5s;
}

.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}

.scroll-menu-last .last {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 3%;
}

.scroll-menu-last section:first-child {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: sticky;
  left: 0;
  margin-right: 6%;
  background: #ffffff;
  z-index: 1;
}

/* Part 1 */

/* Part 2 */
.card-main-table-time {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding-bottom: 15px;
  width: 120%;
  height: 99%;
}

.card-sub-table-time {
  border: 1px solid #34230f;
  border-radius: 15px;
  max-width: 100%;
  width: 200px;
  height: 99%;
  transition: 0.5s;
}

.card-sub-table-time:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-time {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}

.scroll-menu-time .time {
  display: inline-block;
  vertical-align: top;
  margin-right: 3%;
}

.scroll-menu-time section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  bottom: auto;
  margin-right: 6%;
  background: #ffffff;
  z-index: 1;
}

.inline-box-specific-time {
  background: #d6d3cf;
}

.footer-total {
  position: absolute;
  bottom: 1%;
  border-top: 2px solid #ffbc15;
  background-color: #fff2d0;
  padding: 11px;
  width: 120%;
}

.footer-gams {
  background-color: #000000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* Part 2 */

.text-green {
  color: #88b358;
}

.flex-center-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-header-two {
  display: flex;
  align-items: center;
  justify-content: end;
}

.card-table-radius-sub:nth-child(even)
  > .border-card
  > .go-to-card
  > .flex-center-header
  > .tag-title-step-card {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.card-table-radius-sub:nth-child(odd)
  > .border-card
  > .go-to-card
  > .flex-center-header
  > .tag-title-step-card {
  border: 1px solid #34230f;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.card-table-radius-sub:nth-child(even) > .border-card {
  border-radius: 20px;
  border: 1px solid #ffbc15;
}

.card-table-radius-sub:nth-child(odd) > .border-card {
  border-radius: 20px;
  border: 1px solid #34230f;
}

.card-table-radius-sub:nth-child(even)
  > .border-card
  > .go-to-card
  > .col-harder
  > .border-main {
  background-color: #ffbc15;
}s

.card-table-radius-sub:nth-child(odd)
  > .border-card
  > .go-to-card
  > .col-harder
  > .border-main {
  background-color: #34230f;
}
.d-right-flex{
        display: flex !important;
    justify-content: flex-end;
}
</style>