<template>
    <div>
        <OrderShippingDetail />
    </div>
</template>
  
<script>
import OrderShippingDetail from '@/components/sale/OrderShippingDetail'
export default {
    name: 'Ordershippingdetail',
    components: {
        OrderShippingDetail,
    }
}
</script>