import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {

  getProductionList(page = "", size = "", search = "") {
    return new Promise((resolve, reject) => {
      HTTP.get(
        `/production?page=${page}&size=${size}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getProductDialogList(search) {
    return new Promise((resolve, reject) => {
      HTTP.get(`production/listProduct?search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getProductionCancel: (data) => {
    return HTTP.post('/production/cancel', data, {
      headers: {
        "Authorization": `Bearer ${store.state.access_token}`
      }
    })
      .then(response => response)
  },

  getProductionAdd: (data, id) => {
    return HTTP.post('/production/createStepProcess/' + id, data, {
      headers: {
        "Authorization": `Bearer ${store.state.access_token}`
      }
    })
      .then(response => response)
  },
  getProductionAddPO: (id, data) => {
    return HTTP.post('/production/createStepProcess/by/order/' + id, data, {
      headers: {
        "Authorization": `Bearer ${store.state.access_token}`
      }
    })
      .then(response => response)
  },
  getStepProduction(id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/production/get/step/production/`+id,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getPO() {
    return new Promise((resolve, reject) => {
      HTTP.get(`/production/listProduct/by/order/?search`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
}

