<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายงานบัญชี </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>งบกำไร - ขาดทุน</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="grid_half">
                                    <div class="ma-2">
                                        <v-autocomplete class="input-radius-search" :items="type_list" color="#FFBC15"
                                            label="ประเภท" hide-details="auto" item-text="name" item-value="id"
                                            v-model="type_list" outlined>
                                        </v-autocomplete>
                                    </div>
                                    <div class="ma-2">
                                        <v-autocomplete class="input-radius-search" :items="limit_time_list" color="#FFBC15"
                                            label="ระยะเวลา" hide-details="auto" value="2" item-text="name" item-value="id"
                                            v-model="limit_time" outlined>
                                        </v-autocomplete>
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="text-right" style="font-size:14px;color:#000000;"
                                                    v-for="data in table_header" :key="data.text">
                                                    {{ data.date }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="table-border-none text-left" style="font-size:14px;color:#000000;font-weight:600;">
                                                    รายได้</td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none">
                                                    รายได้จากการขายสินค้า
                                                </td>
                                                <td class="table-border-none text-right">
                                                    295,714.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    838,952.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    900,986.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    772,831.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    839,083.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    937,947.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none">
                                                    เงินปันผลรับ
                                                </td>
                                                <td class="table-border-none text-right">
                                                    12,000.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    89,972.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    120,208.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    115,960.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    73,947.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    75,821.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none"
                                                    style="font-size:14px;color:#000000;font-weight:600;">
                                                    รวมรายได้
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    307,714.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    928,924.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    1,021,194.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    888,791.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    913,030.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    1,013,768.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none"
                                                    style="font-size:14px;color:#000000;font-weight:600;">
                                                    รายได้
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none">
                                                    ต้นทุนขายสินค้า
                                                </td>
                                                <td class="table-border-none text-right">
                                                    210,802.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    50,706.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    87,533.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    77,122.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    61,854.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    87,239.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none">
                                                    ค่าใช้จ่ายในการบริหาร
                                                </td>
                                                <td class="table-border-none text-right">
                                                    10,241.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    9,972.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    9,208.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    5,960.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    3,947.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    5,821.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none">
                                                    ต้นทุนทางการเงิน
                                                </td>
                                                <td class="table-border-none text-right">
                                                    93,829.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    73,329.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    87,039.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    45,938.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    80,038.00
                                                </td>
                                                <td class="table-border-none text-right">
                                                    46,029.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none"
                                                    style="font-size:14px;color:#000000;font-weight:600;">
                                                    รวมรายได้
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    314,872.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    134,007.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    183,780.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    129,020.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    145,839.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    139,089.00
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="table-border-none"
                                                    style="font-size:18px;color:#000000;font-weight:600;">
                                                    กำไรสุทธิ
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    -7,158.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    794,917.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    837,414.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    759,771.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    767,191.00
                                                </td>
                                                <td class="table-border-none text-right" style="font-size:14px;color:#000000;font-weight:600;">
                                                    874,679.00
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForNumber } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenuAccounting.vue';
export default {
    data() {
        return {
            loading_page: false,
            table_header: [
                { id: 1, date: '31 มกราคม 2023' },
                { id: 2, date: '31 ธันวาคม 2022' },
                { id: 3, date: '30 พฤศจิกายน 2022' },
                { id: 4, date: '31 ตุลาคม 2022' },
                { id: 5, date: '30 กันยายน 2022' },
                { id: 6, date: '31 สิงหาคม 2022' },
            ],
            sub_menu: [
                {
                    text: 'บัญชี',
                    disabled: false,
                },
                {
                    text: 'รายงานบัญชี',
                    disabled: false,
                    href: '#',
                },
            ],
            type_list: [
                { id: 1, name: 'รายเดือน' },
                { id: 2, name: 'รายปี' },
            ],
            limit_time_list: [
                { id: 1, name: '3 เดือน' },
                { id: 2, name: '6 เดือน' },
                { id: 3, name: '9 เดือน' },
                { id: 4, name: '12 เดือน' },
            ],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-unit-data {
    display: grid;
    grid-template-columns: 1% 80% 19%;
    padding: 5px 0px 5px 30px;
}

.table-header-unit-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-unit-items {
    display: grid;
    grid-template-columns: 9% 81% 10%;
    align-items: center;
}

.table-border-none {
    border-bottom: none !important;
}
</style>