
<template>
    <div>
        <SetProductEditPage />
    </div>
</template>
  
<script>
import SetProductEditPage from '@/components/stock/setting_stock/SetProductEdit'
export default {
    name: 'SetProductEdit',
    components: {
        SetProductEditPage,
    }
}
</script>