<template>
    <div>
        <v-navigation-drawer v-if="$route.path.substring(1).split('/')[0] == 'sale'" class="menu-left-ipad" permanent
            expand-on-hover absolute>
            <v-list-item class="px-2">
                <v-list-item-title class="text-center">เมนูการขาย</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense shaped>
                <v-list-item-group>
                    <v-list-item link @click="link('/sale/customer-list')">
                        <v-list-item-icon>
                            <img src="@/assets/Rectangle 75 (3).png" alt="ข้อมูลลูกค้า">
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>ข้อมูลลูกค้า</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="link('/accounting/accounting-save')">
                        <v-list-item-icon>
                            <img src="@/assets/Rectangle 1559.png" alt="จัดการคำสั่งซื้อ">
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>จัดการคำสั่งซื้อ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            admins: [
                ['Management', 'mdi-account-multiple-outline'],
                ['Settings', 'mdi-cog-outline'],
            ],
            cruds: [
                ['Create', 'mdi-plus-outline'],
                ['Read', 'mdi-file-outline'],
                ['Update', 'mdi-update'],
                ['Delete', 'mdi-delete'],
            ],
        }
    },
    methods: {
        link(link) {
            this.$router.push(link)
        },
    }
}
</script>