<template>
<div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการผลิตสินค้า</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/pro/processing_product_good')">การผลิตสินค้า</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการผลิตสินค้า</div>
    </div>
    <div class="mt-3">
      <v-form ref="form_add_step_one" @submit.prevent="addStepOne()">
        <v-card elevation="2" class="card-radius">
          <v-card-title>
            <b>ขั้นตอนที่ 1 : สร้างใบสั่งผลิต</b>
          </v-card-title>
          <v-card-text>
            <v-card elevation="4" class="py-2 px-3" style="border-radius:20px;">
              <v-card-title>
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>
                    mdi-note-text-outline
                  </v-icon>
                </v-avatar>
                <span class="ml-3 title-card-text">ข้อมูลการผลิตสินค้า</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                <v-row class="ma-4">

                  <v-col xl="12" lg="12" v-if="dataMain.status_process == 0 || dataMain.status_process == null">
                    <v-row>
                      <v-col xl="3" lg="3" md="3">
                        <v-menu v-model="menu1" @input="menu1 = !menu1" class="input-radius-search "
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dataMain.production_date" label="วัน/เดือน/ปี" color="#FFBC15"
                              prepend-inner-icon="mdi-calendar" hide-details="auto"
                              :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded readonly
                              required v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="dataMain.production_date" @input="menu1 = false, setDate()" no-title
                            scrollable></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col xl="2" lg="2" md="3">
                        <v-select :items="sTime" item-text="formattedTime" item-value="formattedTime" color="#FFBC15"
                          v-model="dataMain.production_time" append-icon="mdi-clock-time-four-outline"
                          :rules="[v => !!v || 'กรุณาเลือกเวลาสั่งผลิต']" class="input-radius-search" label="เวลาสั่งผลิต"
                          outlined dense rounded required hide-details="auto"></v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col xl="12" lg="12" v-else>
                    <v-row>
                      <v-col xl="3" lg="3" md="3">
                        <b class="black--text">วัน/เดือน/ปี</b>
                        <p>{{ dataMain.production_date }}</p>
                      </v-col>
                      <v-col xl="2" lg="2" md="3">
                        <b class="black--text">เวลาสั่งผลิต</b>
                        <p>{{ dataMain.production_time }}</p>
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>

                <v-row class="ma-4">
                  <b class="ml-3 mb-3 mb-n5 black--text">ชื่อสินค้า : {{ dataMain.product_name }}</b>
                  <v-col xl="12" lg="12"  v-if="dataMain.status_process == 0 || dataMain.status_process == null">
                    <v-row>
                      <v-col xl="3" lg="3">
                        <v-select :items="production_size_list" item-text="pro_size_name" item-value="product_size_id"
                          color="#FFBC15" class="input-radius-search mt-3" label="ขนาดสินค้า" hide-details="auto" ref="production_size_list"
                          v-model="production_size_list.id" @change="addSize(production_size_list.id)" outlined rounded
                          dense></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pl-8">
                <div class="table-header-processing mt-n6 " style="color: #b1adad;">
                  <div class="table-sub-header-processing">
                    ขนาดสินค้า
                  </div>
                  <div class="table-sub-header-processing text-center">
                    จำนวนต่อหน่วย
                  </div>
                  <div class="table-sub-header-processing text-center">
                    จำนวนกรัมการผลิต
                  </div>
                  <div class="table-sub-header-processing text-center" v-if="dataMain.status_process == 0 || dataMain.status_process == null">
                    ลบ
                  </div>
                </div>
                <v-card elevation="0" v-for="(detail, index) in list_size" :key="index"
                  :class="detail.is_delete == 1 ? 'd-none' : 'grid-processing-items ma-5'">
                  <div>{{ detail.pro_size_name }}</div>
                  <div v-if="dataMain.status_process == 0 || dataMain.status_process == null">
                    <v-text-field label="จำนวนต่อหน่วย" class="input-radius" color="#FFBC15" v-model="detail.qty"
                      :rules="[v => !!v || 'กรุณากรอกจำนวนต่อหน่วย']" @keypress="isOnlyNumber($event, detail.qty)" dense
                      outlined rounded required @keyup="calculate_input(index)" hide-details="auto"></v-text-field>
                  </div>
                  <div v-else>
                    <p class="pl-12">{{ detail.qty }}</p>
                  </div>
                  <div class="text-center">{{ (detail.qty_gram > 0) ? formatPrice(detail.qty_gram) :
                    formatPrice(detail.pro_amount) }}</div>
                  <div style="text-align: center" v-if="dataMain.status_process == 0 || dataMain.status_process == null">
                    <v-btn elevation="1" class="ml-6 radius-15" color="#f2abab" outlined @click="removeProduct(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
                <div class="summarize my-5  pa-5 my-19">
                  <div class="text-18 text-center text-green">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป {{
                    formatPrice(total)
                  }} กรัม /
                    <!-- {{ (parseFloat(detail.grams) / parseFloat(detail.batch_qty)) * 1000 }} -->
                    {{ formatPrice(dataMain.batch_count) }} Batch</div>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-text>
            <v-card elevation="4" class="py-2" style="border-radius:20px;" v-if="dataMain.status_process == 0 || dataMain.status_process == null">
              
              <v-card-title>
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>
                    mdi-account-supervisor
                  </v-icon>
                </v-avatar>
                <span class="ml-3 title-card-text">ข้อมูลผู้รับผิดชอบ</span>
              </v-card-title>

              <v-card-text>
                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                <div class="ma-4 mb-n7">
                  <b class="ml-3 black--text">ผู้อนุมัติสั่งผลิต</b>
                </div>

                <v-row class="ma-4">
                  <v-col xl="3" lg="3">
                    <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search mt-3"
                      :rules="[v => !!v || 'กรุณาเลือกผู้อนุมัตสั่งผลิต']" color="#FFBC15" v-model="dataMain.user_approve" 
                      label="ผู้อนุมัติสั่งผลิต" hide-details="auto"  outlined dense rounded></v-select>
                  </v-col>
                </v-row>

                <div class="ma-4 mb-n5">
                  <b class="ml-3 black--text">ขั้นตอนการสั่งผลิตและผู้รับชอบ</b>
                </div>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">สั่งผลิต</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_order_production" hide-details="auto"  outlined rounded dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu2" @click="menu2 = !menu2" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.order_production_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded outlined
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.order_production_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.order_production_detail" outlined rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">เตรียมวัตถุดิบ</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_prepare_mat" hide-details="auto"  rounded outlined dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu4" @input="menu4 = !menu4" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.prepare_mat_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense rounded outlined
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.prepare_mat_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.prepare_mat_detail" outlined rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">บันทึกการผลิต</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_save" hide-details="auto"  outlined rounded  dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu5" @input="menu5 = !menu5" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.save_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded outlined
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.save_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" v-model="dataMain.save_detail"
                      outlined rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">บรรจุภัณฑ์</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_package" hide-details="auto"   rounded dense outlined></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu6" @input="menu6 = !menu6" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.package_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded outlined
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.package_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" v-model="dataMain.package_detail"
                      outlined rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">ตรวจสอบการผลิต</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_qa_production" hide-details="auto"  outlined rounded dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu7" @input="menu7 = !menu7" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.qa_production_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded outlined
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.qa_production_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.qa_production_detail" outlined rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">ตรวจสอบคุณภาพสินค้า</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_qa_product" hide-details="auto"  outlined dense rounded></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu8" @input="menu8 = !menu8" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.qa_product_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense rounded outlined
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.qa_product_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.qa_product_detail" outlined rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                </section>

              </v-card-text>
            </v-card>
            <v-card elevation="4" class="py-2" style="border-radius:20px;" v-else>
              
              <v-card-title>
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>
                    mdi-account-supervisor
                  </v-icon>
                </v-avatar>
                <span class="ml-3 title-card-text">ข้อมูลผู้รับผิดชอบ</span>
              </v-card-title>

              <v-card-text>
                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                <div class="ma-4 mb-n7">
                  <b class="ml-3 black--text">ผู้อนุมัติสั่งผลิต</b>
                </div>

                <v-row class="ma-4">
                  <v-col xl="3" lg="3">
                    <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search mt-3"
                      :rules="[v => !!v || 'กรุณาเลือกผู้อนุมัตสั่งผลิต']" color="#FFBC15" v-model="dataMain.user_approve" readonly
                      label="" hide-details="auto" append-icon=""  dense rounded></v-select>
                  </v-col>
                </v-row>

                <div class="ma-4 mb-n5">
                  <b class="ml-3 black--text">ขั้นตอนการสั่งผลิตและผู้รับชอบ</b>
                </div>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">สั่งผลิต</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_order_production" hide-details="auto" append-icon="" disabled rounded dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu2" @click="menu2 = !menu2" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.order_production_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded disabled
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.order_production_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.order_production_detail" disabled rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">เตรียมวัตถุดิบ</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_prepare_mat" hide-details="auto" append-icon="" rounded disabled dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu4" @input="menu4 = !menu4" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.prepare_mat_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense rounded disabled
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.prepare_mat_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.prepare_mat_detail" disabled rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">บันทึกการผลิต</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_save" hide-details="auto" append-icon="" disabled rounded  dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu5" @input="menu5 = !menu5" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.save_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded disabled
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.save_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" v-model="dataMain.save_detail"
                      disabled rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">บรรจุภัณฑ์</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_package" hide-details="auto" append-icon=""  rounded dense disabled></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu6" @input="menu6 = !menu6" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.package_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded disabled
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.package_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" v-model="dataMain.package_detail"
                      disabled rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">ตรวจสอบการผลิต</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_qa_production" hide-details="auto" append-icon="" disabled rounded dense></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu7" @input="menu7 = !menu7" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.qa_production_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense  rounded disabled
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.qa_production_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.qa_production_detail" disabled rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
                </section>

                <section>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3" style="align-self: center">
                      <b class="ml-3 black--text">ตรวจสอบคุณภาพสินค้า</b>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-select :items="userList" item-text="name" item-value="id" class="input-radius-search"
                        :rules="[v => !!v || 'กรุณาเลือกผู้รับผิดชอบ']" label="ผู้รับผิดชอบ" color="#FFBC15"
                        v-model="dataMain.user_qa_product" hide-details="auto" append-icon="" disabled dense rounded></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu8" @input="menu8 = !menu8" class="input-radius-search "
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dataMain.qa_product_date" label="วัน/เดือน/ปี" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense rounded disabled
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataMain.qa_product_date" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ma-4 ml-10">
                    <v-col xl="3" lg="3"></v-col>
                    <v-col xl="9" lg="9">
                      <v-text-field label="รายละเอียด" value="-" hide-details="auto" color="#FFBC15"
                        v-model="dataMain.qa_product_detail" disabled rounded dense></v-text-field>
                    </v-col>
                  </v-row>
                </section>

              </v-card-text>
            </v-card>
          </v-card-text>
          <div class="mt-3 text-center" v-if="dataMain.status_process == 0 || dataMain.status_process == null">
            <v-btn type="submit" class="white--text" color="#88B358" elevation="4" rounded large>
              <v-icon left>mdi-content-save</v-icon> บันทึก
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import production_one from "@/api/product_good/product_process_step_one.js";
import materials from "@/api/warehouse/materials.js";
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import Swal from "sweetalert2";
export default {
  name: "ProcessingCreate",
  data() {
    return {
      loading_page: false,
      sTime: [],
      dataMain: null,
      production_size_list: [],
      dataProductSize: [],
      dataUserList: [],
      dataProductionSizeList: [],
      list_size: [],
      userList: [],
      total: 0,
      batch: 0,
      batch_qty: 0,
    }
  },
  methods: {
    formatPrice(number) {
      return formatPrice(number);
    },

    isOnlyNumber: function (evt, number) {
      return onlyForCurrency(evt, number)
    },
    linkToSteps() {
      this.$router.push('/prm/processing_raw_materials')
    },
    link(link) {
      this.$router.push(link)
    },
    removeProduct(index) {
      this.loading_page = true;
      if(this.list_size[index].id == null){
        this.list_size.splice(index, 1);
      }else{
        this.list_size[index] = {
          ...this.list_size[index],
          is_delete: 1
        }
      }
      // this.list_size.splice(index, 1);

      // this.list_size[index].qty_gram = (parseFloat(this.list_size[index].qty) * parseFloat(this.list_size[index].pro_amount)) + ((3 / 100) * (parseFloat(this.list_size[index].qty) * parseFloat(this.list_size[index].pro_amount)));
      this.total = 0
      for (let j = 0; j < this.list_size.length; j++) {
        if (this.list_size[j].is_delete == 0) {
          this.total += parseFloat(this.list_size[j].qty_gram == null || this.list_size[j].qty_gram == '' ? 0 : this.list_size[j].qty_gram);
        }


      }
      this.dataMain.batch_count = this.total / this.dataMain.pro_formula
      this.dataMain.batch_qty = this.dataMain.pro_formula
      this.dataMain.desired_qty = this.total
      this.loading_page = false;
    },
    async setDate(){
            this.loading_page = true
            this.dataMain.qa_product_date = await this.dataMain.qa_product_date == null ? this.dataMain.production_date : this.dataMain.qa_product_date
            this.dataMain.qa_production_date = await this.dataMain.qa_production_date == null ? this.dataMain.production_date : this.dataMain.qa_production_date
            this.dataMain.package_date = await this.dataMain.package_date == null ? this.dataMain.production_date : this.dataMain.package_date
            this.dataMain.save_date = await this.dataMain.save_date == null ? this.dataMain.production_date : this.dataMain.save_date
            this.dataMain.order_production_date = await this.dataMain.order_production_date == null ? this.dataMain.production_date : this.dataMain.order_production_date
            this.dataMain.prepare_mat_date = await this.dataMain.prepare_mat_date == null ? this.dataMain.production_date : this.dataMain.prepare_mat_date
            this.dataMain.production_time = await this.dataMain.production_time == null ? this.dataMain.production_date : this.dataMain.production_time
            this.loading_page = false;
    },
    async getStepOneList() {
      this.loading_page = true;
   
      await production_one.getStepOneList(this.$route.params.id).then(async (response) => {
      if(response.status == 502){
        window.location.reload()
      }
              this.loading_page = false
        this.dataMain = response.data.data
        let data = response.data.data.production_time
        let data_production_date = response.data.data.production_date
        let order_production_date = response.data.data.order_production_date
        let prepare_mat_date = response.data.data.save_date
        let save_date = response.data.data.save_date
        let package_date = response.data.data.package_date
        let qa_production_date = response.data.data.qa_production_date
        let qa_product_date = response.data.data.qa_product_date
        if (qa_product_date != null) {
          this.dataMain.qa_product_date = qa_product_date.split(' ')[0]
        }
        if (qa_production_date != null) {
          this.dataMain.qa_production_date = qa_production_date.split(' ')[0]
        }
        if (package_date != null) {
          this.dataMain.package_date = package_date.split(' ')[0]
        }
        if (save_date != null) {
          this.dataMain.save_date = save_date.split(' ')[0]
        }
        if (order_production_date != null) {
          this.dataMain.order_production_date = order_production_date.split(' ')[0]
        }
        if (prepare_mat_date != null) {
          this.dataMain.prepare_mat_date = prepare_mat_date.split(' ')[0]
        }
        if (data_production_date == null) {
          this.dataMain.production_date = '';
        } else {
          this.dataMain.production_date = data_production_date.split(' ')[0]
        }
        if (data == null) {
          this.dataMain.production_time = '';
        } else {
          this.dataMain.production_time = data.split(':')[0] + ':' + data.split(':')[1]
        }
         for (let i = 0; i < this.dataMain.production_size_list.length; i++) {

            this.list_size.push({
              id: this.dataMain.production_size_list[i].id,
              production_id: this.$route.params.id,
              product_size_id: this.dataMain.production_size_list[i].product_size_id,
              pro_amount:  this.dataMain.production_size_list[i].pro_amount,
              pro_size_name: this.dataMain.production_size_list[i].pro_size_name,
              qty: this.dataMain.production_size_list[i].qty,
              qty_gram: this.dataMain.production_size_list[i].qty_gram,
              is_delete: 0,
            })

          }
          for (let j = 0; j < this.list_size.length; j++) {
            this.total += parseFloat(this.list_size[j].qty_gram);
          }
          this.dataMain.batch_count = this.total / this.dataMain.pro_formula
          this.dataMain.batch_qty = this.dataMain.pro_formula
          this.dataMain.desired_qty = this.total
         
              await production_one.getProductionSize(response.data.data.product_id).then((res) => {
                  this.production_size_list = res.data.data
                })
          
        // this.dataProductSize = response.data.data.production_size_list
        // this.dataUserList = response.data.data.user_list
         this.loading_page = false;
      }).catch((error) => {
             if(error.response.status === 502){
                window.location.reload()
              }
          })
    },

    addSize(id) {
      let check = this.production_size_list.find(o => o.product_size_id === id);
      let check_list_size = this.list_size.find(o => o.product_size_id === id && o.is_delete == 0);
      if (check_list_size == null) {
        this.list_size.push({
          id: null,
          production_id: this.$route.params.id,
          product_size_id: check.product_size_id,
          pro_amount: check.pro_amount,
          pro_size_name: check.pro_size_name,
          qty: parseFloat(0),
          qty_gram: parseFloat(0),
          is_delete: parseFloat(0),
        })
      }
      this.$refs.production_size_list.reset()
    },
    calculate_input(index = null) {
      this.list_size[index].qty_gram = 0
      // this.list_size[index].qty_gram = ( (parseFloat(this.list_size[index].qty) * parseFloat(this.list_size[index].pro_amount)) + (3 / 100));

      this.list_size[index].qty_gram = ( (parseFloat(this.list_size[index].qty == null || this.list_size[index].qty == '' ? 0 : this.list_size[index].qty) * parseFloat(this.list_size[index].pro_amount)) + ((3 / 100) * ((parseFloat(this.list_size[index].qty == null || this.list_size[index].qty == '' ? 0 : this.list_size[index].qty) * parseFloat(this.list_size[index].pro_amount)))));
      this.total = 0
      for (let j = 0; j < this.list_size.length; j++) {
        this.total += parseFloat(this.list_size[j].qty_gram == null || this.list_size[j].qty_gram == '' ? 0 : this.list_size[j].qty_gram);
      }
      this.dataMain.batch_count = parseFloat(this.total) / parseFloat(this.dataMain.pro_formula)
      this.dataMain.batch_qty = this.dataMain.pro_formula
      this.dataMain.desired_qty = this.total
    },
    timeSet() {
      this.sTime = [];
      for (let i = 0; i < 24 * 4; i++) {
        const time = new Date();
        time.setHours(Math.floor(i / 4));
        time.setMinutes((i % 4) * 15);

        const formattedTime = time.toLocaleTimeString('th', {
          hour: '2-digit',
          minute: '2-digit'
        });
        this.sTime.push({
          formattedTime: formattedTime
        })
      }
    },
   async getUserList() {
      await materials.getUserList().then((response) => {
        this.loading_page = false
        this.userList = response.data.data
      })
    },
   async addStepOne() {
      if (this.$refs.form_add_step_one.validate()) {
        this.loading_page = true;
        let listData = [];
        if (this.list_size.length > 0) {
          for (let j = 0; j < this.list_size.length; j++) {
            if (this.list_size[j].id != null && this.list_size[j].is_delete == 1) {
              listData.push({
                id: this.list_size[j].id,
                production_id: this.$route.params.id,
                product_size_id: this.list_size[j].product_size_id,
                qty: this.list_size[j].qty,
                qty_gram: this.list_size[j].qty_gram,
                is_delete: 1,
              })
            } else if (this.list_size[j].id == null && this.list_size[j].is_delete == 0) {
              listData.push({
                production_id: this.$route.params.id,
                product_size_id: this.list_size[j].product_size_id,
                qty: this.list_size[j].qty,
                qty_gram: this.list_size[j].qty_gram,
              })
            } else if (this.list_size[j].id != null && this.list_size[j].is_delete == 0) {
              listData.push({
                id: this.list_size[j].id,
                production_id: this.$route.params.id,
                product_size_id: this.list_size[j].product_size_id,
                qty: this.list_size[j].qty,
                qty_gram: this.list_size[j].qty_gram,
              })
            }

          }
          const data = {
            production_date: this.dataMain.production_date,
            production_time: this.dataMain.production_time,
            user_approve: parseFloat(this.dataMain.user_approve),
            user_order_production: parseFloat(this.dataMain.user_order_production),
            order_production_date: this.dataMain.order_production_date,
            order_production_detail: this.dataMain.order_production_detail,
            user_prepare_mat: parseFloat(this.dataMain.user_prepare_mat),
            prepare_mat_date: this.dataMain.prepare_mat_date,
            prepare_mat_detail: this.dataMain.prepare_mat_detail,
            user_save: parseFloat(this.dataMain.user_save),
            save_date: this.dataMain.save_date,
            save_detail: this.dataMain.save_detail,
            user_package: parseFloat(this.dataMain.user_package),
            package_date: this.dataMain.package_date,
            package_detail: this.dataMain.package_detail,
            user_qa_production: parseFloat(this.dataMain.user_qa_production),
            qa_production_date: this.dataMain.qa_production_date,
            qa_production_detail: this.dataMain.qa_production_detail,
            user_qa_product: parseFloat(this.dataMain.user_qa_product),
            qa_product_date: this.dataMain.qa_product_date,
            qa_product_detail: this.dataMain.qa_product_detail,
            batch_count: this.dataMain.batch_count,
            batch_qty: this.dataMain.pro_formula,
            desired_qty: this.total,
            list_size: listData,
          }
        await  production_one.saveStepOne(data, this.$route.params.id).then((response) => {
            if (response.data.successful === true) {
              let timerInterval;
              Swal.fire({
                title: "ทำรายการเสร็จสิ้น",
                icon: "success",
                html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const b = Swal.getHtmlContainer().querySelector("b");
                  this.$router.push("/pro/processing_product_good_steps/" + this.$route.params.id);
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  this.$router.push("/pro/processing_product_good_steps/" + this.$route.params.id);
                }
              });
            }
          })
        } else {
          let timerInterval;
          Swal.fire({
            title: "แจ้งเตือน",
            icon: "warning",
            html: "กรุณาเลือกขนาดสินค้าอย่างน้อย 1 อย่าง",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }

      }
    }
  },
  mounted() {
     this.timeSet();
     this.getUserList();
    this.getStepOneList();
  },
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 10% 36% 29% 15%;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 23% 23% 29% 15%;
  align-items: baseline;
}

.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}

.text-green {
  color: #88B358;
}
</style>