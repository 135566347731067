<template>
  <div>
    <ProductionProductGoodResultEdit/>
  </div>
</template>

<script>
import ProductionProductGoodResultEdit from "@/components/production_process/processing_product_good/ProductionProductGoodResultEdit";
export default {
  name: "ProductionProductGoodResultEdit_Page",
  components: {ProductionProductGoodResultEdit}
}
</script>

<style scoped>

</style>