<template>
  <div>
    <ProcessingEdit/>
  </div>
</template>

<script>
import ProcessingEdit from "@/components/production_process/processing_raw_materials/ProcessingEdit";
export default {
  name: "Processing_edit",
  components: {ProcessingEdit}
}
</script>

<style scoped>

</style>