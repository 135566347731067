<template>
    <div>
        <Warehouse_page_packaged />
    </div>
</template>
  
<script>
import Warehouse_page_packaged from '@/components/stock/warehouse_stock/WarehousePackaged'
export default {
    name: 'Warehousepackaged',
    components: {
        Warehouse_page_packaged,
    }
}
</script>