<template>
    <div>
      <DetailOrderListPage/>
    </div>
  </template>

<script>
import DetailOrderListPage from '@/components/stock/order/DetailOrderListComponent'
export default {
    name: 'detailorderlist',
    components:{
        DetailOrderListPage,
    }
}
</script>