<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">แก้ไขวัตถุดิบ</h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title class="d-flex justify-space-between">
                        <b>การแก้ไขข้อมูลวัตถุดิบ</b>
                        <div class="ml-3 text-name-title">
                            ผู้แก้ไขล่าสุด :
                            <span class="text-fullname-title">{{
                                material_detail.name
                            }}</span>
                            <span class="text-date-title"> วันที่ : </span>
                            <span class="text-datetime-title">{{
                                material_detail.updated_at
                            }}</span>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-btn @click="$refs.step_3_list.validate() ? material_change(1) : ''" class="ma-2" :class="material_step == 1 ? 'tab-button active' : 'tab-button-disable'
                            " type="button" :color="material_step == 1 ? '#FFBC15' : '#909090'"
                            :elevation="material_step == 1 ? '2' : '0'" large outlined>ข้อมูลวัตถุดิบ</v-btn>
                        <v-btn v-if="material_detail.mat_status == 2" @click="$refs.step_3_list.validate() ? material_change(2) : ''" class="ma-2" :class="material_step == 2 ? 'tab-button active' : 'tab-button-disable'
                            " type="button" large outlined :elevation="material_step == 2 ? '2' : '0'"
                            :color="material_step == 2 ? '#FFBC15' : '#909090'">ข้อมูลการแปรรูป</v-btn>
                        <v-btn @click="$refs.step_3_list.validate() ? material_change(3) : ''" class="ma-2" :class="material_step == 3 ? 'tab-button active' : 'tab-button-disable'
                            " type="button" large outlined :elevation="material_step == 3 ? '2' : '0'"
                            :color="material_step == 3 ? '#FFBC15' : '#909090'">เกณฑ์การตรวจสอบ</v-btn>
                    </v-card-text>
                    <v-card-text v-if="material_step == 1">
                       <v-form ref="step_3_list" @submit.prevent="submitBtn()">
                         <v-row>
                            <v-col cols="12">
                                <v-card elevation="4" style="border-radius: 20px">
                                    <v-card-title class="d-flex justify-space-between" style="align-items: center">
                                        <div>
                                            <v-avatar color="#34230F" size="32">
                                                <v-icon dark> mdi-package-variant-closed </v-icon>
                                            </v-avatar>
                                            <span class="ml-3 text-font-title">ข้อมูลวัตถุดิบ</span>
                                        </div>
                                        <div>
                                            {{ getMaterialStatusText }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-divider class="mb-3" color="#686868"></v-divider>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <div class="mt-2" style="color: #000; font-size: 18px">
                                                    รูปภาพวัตถุดิบ
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="image_preview text-center">
                                                            <v-avatar class="mt-2" size="210">
                                                                <img v-if="imagePreviewURL" :src="imagePreviewURL" />
                                                                <div class="upload_image">
                                                                    <!-- <v-btn v-if="!material_picture" class="white--text" color="#FD8F16" depressed fab :loading="isSelectingImage" @click="handleFileImport"><v-icon>mdi-pencil</v-icon></v-btn> -->
                                                                    <v-btn v-if="material_picture" class="white--text"
                                                                        color="error" depressed fab
                                                                        @click="onFileRemove()"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                                                    <v-file-input class="d-none" id="uploader"
                                                                        accept="image/*" v-model="material_picture"
                                                                        @change="onFileChange"></v-file-input>
                                                                </div>
                                                            </v-avatar>
                                                            <!-- {{ material_picture }} -->
                                                            <!-- {{ material_picture.name == null ? '1':'2' }} -->
                                                            <v-file-input class="" id="uploader" accept="image/*"
                                                                v-model="material_picture" @change="onFileChange"  
                                                                placeholder="เลือกรูปภาพวัตถุดิบ"  :clearable="false">
                                                            </v-file-input>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                        <v-form-group>
                                                            <v-text-field type="text" class="input-radius"
                                                                label="ชื่อวัตถุดิบ" placeholder="ชื่อวัตถุดิบ"
                                                                v-model="material_detail.mat_name" color="#ffbc15"
                                                                :rules="[(v) => !!v || 'กรุณากรอกชื่อวัตถุดิบ']"
                                                                hide-details="auto" dense outlined rounded
                                                                required></v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                        <v-form-group>
                                                            <v-autocomplete :items="material_type" item-text="mat_type_name"
                                                                item-value="id" v-model="material_detail.materials_type_id"
                                                                label="ประเภทวัตถุดิบ" color="#ffbc15" :rules="[
                                                                    (v) => !!v || 'กรุณาเลือกประเภทวัตถุดิบ',
                                                                ]" hide-details="auto" dense outlined rounded
                                                                required></v-autocomplete>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                                                        v-if="material_detail.mat_status != 2">
                                                        <v-form-group>
                                                            <v-autocomplete :items="supplier" item-text="sup_name"
                                                                item-value="id" v-model="material_detail.supplier_id"
                                                                label="ซัพพลายเออร์" color="#ffbc15" :rules="[
                                                                    (v) => !!v || 'กรุณาเลือกซัพพลายเออร์',
                                                                ]" hide-details="auto" dense outlined rounded
                                                                required></v-autocomplete>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                        <v-form-group>
                                                            <v-text-field type="text" class="input-radius" label="บาร์โค้ด"
                                                                v-model="material_detail.barcode" placeholder="บาร์โค้ด"
                                                                color="#ffbc15" hide-details="auto" dense outlined rounded
                                                                required></v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="d-sm-none"></v-col>
                                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                        <v-form-group>
                                                            <v-text-field type="number" class="input-radius"
                                                                label="จำนวนขั้นต่ำในคลัง" v-model="material_detail.min"
                                                                placeholder="จำนวนขั้นต่ำในคลัง" color="#ffbc15"
                                                                hide-details="auto" @keypress="
                                                                    isOnlyNumber($event, material_detail.min)
                                                                    " dense outlined rounded required></v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                        <v-form-group>
                                                            <v-text-field type="text" class="input-radius"
                                                                label="จำนวนสูงสุดในคลัง" v-model="material_detail.max"
                                                                placeholder="จำนวนสูงสุดในคลัง" color="#ffbc15"
                                                                hide-details="auto" @keypress="
                                                                    isOnlyNumber($event, material_detail.max)
                                                                    " dense outlined rounded required></v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card elevation="4" style="border-radius: 20px">
                                    <v-card-title>
                                        <div class="d-flex" style="align-items: center">
                                            <v-avatar color="#34230F" size="32">
                                                <v-icon dark> mdi-currency-usd </v-icon>
                                            </v-avatar>
                                            <span class="ml-3 text-font-title">ข้อมูลหน่วยนับและราคาซื้อ</span>
                                        </div>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-divider class="mb-3" color="#686868"></v-divider>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <v-row>
                                                    <v-col cols="12" v-if="material_detail.mat_status != 2">
                                                        <div class="mt-2" style="color: #000; font-size: 18px">
                                                            หน่วยนับหลักและราคาซื้อ
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4"
                                                        v-if="material_detail.mat_status != 2">
                                                        <div class="d-flex">
                                                            <v-autocomplete style="border-radius: 20px 0 0 20px"
                                                                :items="unit_list" color="#FFBC15" label="หน่วยนับ"
                                                                :rules="[(v) => !!v || 'กรุณาเลือกหน่วยนับ']"
                                                                hide-details="auto" item-text="unit_name" item-value="id"
                                                                v-model="material_detail.unit_main" dense outlined required>
                                                            </v-autocomplete>

                                                            <v-text-field class="input-radius" color="#FFBC15"
                                                                label="ราคาซื้อ" style="border-radius: 0 20px 20px 0"
                                                                v-model="material_detail.retail_price" suffix="บาท"
                                                                :rules="[(v) => !!v || 'กรุณากรอกราคาซื้อ']"
                                                                @keypress="isOnlyDecimal($event)" hide-details="auto" dense
                                                                outlined required>
                                                            </v-text-field>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4"
                                                        v-if="material_detail.mat_status == 1">
                                                        <v-text-field class="input-radius" color="#FFBC15"
                                                            label="1 หน่วยนับ เท่ากับ กี่กรัม" suffix="g"
                                                            :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกหน่วยนับ']"
                                                            v-model="material_detail.unit_main_gram" @keypress="
                                                                isOnlyDecimal(
                                                                    $event,
                                                                    material_detail.unit_main_gram
                                                                )
                                                                " hide-details="auto" rounded dense outlined required>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" v-if="material_detail.mat_status != 2">
                                                        <div class="mt-2" style="color: #000; font-size: 18px">
                                                            หน่วยนับอื่น ๆ
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4"
                                                        v-if="material_detail.mat_status != 2">
                                                        <div class="d-flex">
                                                            <v-autocomplete style="border-radius: 20px 0 0 20px"
                                                                :items="unit_list" color="#FFBC15" label="หน่วยนับอื่น"
                                                                hide-details="auto" item-text="unit_name" item-value="id"
                                                                v-model="material_detail.unit_other" dense outlined
                                                                required>
                                                            </v-autocomplete>

                                                            <v-text-field class="input-radius" color="#FFBC15" v-if="material_detail.unit_other == null || material_detail.unit_other == 0"
                                                                label="ราคาซื้อ" style="border-radius: 0 20px 20px 0"
                                                                suffix="บาท" v-model="material_detail.other_price"
                                                                @keypress="
                                                                    isOnlyDecimal(
                                                                        $event,
                                                                        material_detail.other_price
                                                                    )
                                                                    " hide-details="auto" dense outlined >
                                                            </v-text-field>
                                                            <v-text-field class="input-radius" color="#FFBC15" v-else
                                                                label="ราคาซื้อ" style="border-radius: 0 20px 20px 0" :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกน้ำหนัก']"
                                                                suffix="บาท" v-model="material_detail.other_price"
                                                                @keypress="
                                                                    isOnlyDecimal(
                                                                        $event,
                                                                        material_detail.other_price
                                                                    )
                                                                    " hide-details="auto" dense outlined required>
                                                            </v-text-field>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4"
                                                        v-if="material_detail.mat_status != 2">
                                                        <v-text-field class="input-radius" color="#FFBC15" v-if="material_detail.unit_other == null || material_detail.unit_other == 0"
                                                            label="หน่วยนับอื่น เท่ากับ กี่หน่วยนับหลัก"
                                                            :suffix="unitSuffixName"
                                                            v-model="material_detail.unit_other_qty"
                                                            @keypress="isOnlyDecimal($event, unit_qty)" hide-details="auto"
                                                            rounded dense outlined >
                                                        </v-text-field>
                                                        <v-text-field class="input-radius" color="#FFBC15" v-else
                                                            label="หน่วยนับอื่น เท่ากับ กี่หน่วยนับหลัก"
                                                            :suffix="unitSuffixName"
                                                            :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกน้ำหนัก']"
                                                            v-model="material_detail.unit_other_qty"
                                                            @keypress="isOnlyDecimal($event, unit_qty)" hide-details="auto"
                                                            rounded dense outlined required>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4"
                                                        v-if="material_detail.mat_status == 2">
                                                        <div class="d-flex">
                                                            <v-autocomplete style="border-radius: 20px 0 0 20px"
                                                                :items="unit_list" color="#FFBC15" label="หน่วยนับ"
                                                                :rules="[(v) => !!v || 'กรุณาเลือกหน่วยนับ']"
                                                                hide-details="auto" item-text="unit_name" item-value="id"
                                                                v-model="material_detail.unit_main" dense outlined required>
                                                            </v-autocomplete>

                                                            <v-text-field class="input-radius" color="#FFBC15"
                                                                label="1 หน่วยนับ เท่ากับ กี่กรัม"
                                                                style="border-radius: 0 20px 20px 0" suffix="g"
                                                                :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกน้ำหนัก']"
                                                                v-model="material_detail.unit_main_gram"
                                                                @keypress="isOnlyDecimal($event, price)" hide-details="auto"
                                                                dense outlined required>
                                                            </v-text-field>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358"
                                elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                       </v-form>
                    </v-card-text>
                    <v-card-text v-if="material_step == 2">
                        <v-form ref="step_3_list" @submit.prevent="submitBtn()">
                            <v-row>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-card elevation="4" style="border-radius: 20px">
                                        <v-card-title class="d-flex align-items-center">
                                            <v-avatar color="#34230F" size="32">
                                                <v-icon dark> mdi-cog </v-icon>
                                            </v-avatar>
                                            <span class="ml-3 text-font-title">ข้อมูลการแปรรูป</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-divider class="mb-3" color="#686868"></v-divider>
                                            <v-row class="px-10">
                                                <v-col cols="12">
                                                    <div class="mt-2" style="color: #000; font-size: 18px">
                                                        ปริมาณที่ได้จากสูตรการผลิต
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="6">
                                                    <v-form-group>
                                                        <v-text-field type="text" class="input-radius"
                                                            label="ปริมาณที่ได้จากสูตรการผลิต (กรัม)"
                                                            v-model="material_detail.formula_amount" color="#ffbc15"
                                                            placeholder="ปริมาณที่ได้จากสูตรการผลิต (กรัม)"
                                                            :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอก ปริมาณที่ได้จากสูตรการผลิต']"
                                                            hide-details="auto" dense outlined rounded required>
                                                        </v-text-field>
                                                    </v-form-group>
                                                </v-col>

                                                <v-col cols="12" md="6" lg="6"></v-col>

                                                <!-- loop ขนาดสินค้า -->
                                                <v-col cols="12" v-for="(product_part_loop, index) in material_list"
                                                    :key="index">
                                                    <v-card class="radius-20">
                                                        <v-card-title class="">
                                                            <v-row>
                                                                <v-col cols="6" lg="8">
                                                                    <v-avatar color="#34230F" size="32">
                                                                        <v-icon dark> mdi-cog-sync </v-icon>
                                                                    </v-avatar>
                                                                    <span class="ml-3 text-font-title">การแปรรูปส่วนที่ {{ index
                                                                        + 1 }}</span>
                                                                </v-col>

                                                                <v-col class="text-right" cols="6" lg="4">
                                                                    <div class="error--text" style="cursor: pointer" @click="
                                                                        deletePPL(product_part_loop.material_group)
                                                                        " v-if="index >= 1">
                                                                        <v-icon dark color="error" size="28">
                                                                            mdi-delete-forever
                                                                        </v-icon>
                                                                        <span class="ml-3 f-14">ลบรายการตรวจสอบการผลิต</span>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                            <v-card-text>
                                                            <v-divider class="mb-3" color="#686868"></v-divider>
                                                            <v-row class="pl-10">
                                                                <v-col cols="12" md="12" lg="12">
                                                                    <v-form-group>
                                                                        <v-autocomplete :items="mat_data" class="mt-4"
                                                                            item-text="mat_name" item-value="id" v-model="product_part_loop.materials_selected
                                                                                " label="เลือกวัตถุดิบในการแปรรูป" :rules="[v => product_part_loop.materials_selected.length > 0 || 'กรุณาเลือกข้อมูล']"
                                                                            hide-details="auto" dense outlined rounded chips required
                                                                            deletable-chips multiple></v-autocomplete>
                                                                    </v-form-group>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row class="px-15">
                                                                <v-col cols="6">วัตถุดิบ</v-col>
                                                                <v-col cols="6">จำนวนวัตถุดิบในสูตร</v-col>
                                                            </v-row>
                                                            <v-row class="px-15" v-for="(
                                                                    listID, index
                                                                ) in product_part_loop.materials_selected" :key="index">
                                                                <v-col cols="6">{{ slectedName(listID) }}</v-col>
                                                                <v-col cols="6">
                                                                    <v-form-group class="flex">
                                                                        <v-text-field type="text" class="input-radius mr-2"
                                                                            label="จำนวนวัตถุดิบในสูตร (กรัม)"
                                                                            placeholder="จำนวนวัตถุดิบในสูตร (กรัม)"
                                                                            color="#686868" v-model="product_part_loop
                                                                                .materials_selected_amount[index]
                                                                                " :rules="[
                                                                                    (v) => !!v || 'กรุณากรอก จำนวนวัตถุดิบ',
                                                                                ]" hide-details="auto" dense outlined rounded required>
                                                                        </v-text-field>
                                                                    </v-form-group>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>

                                                <!-- loop ขนาดสินค้า -->
                                            </v-row>
                                        </v-card-text>

                                        <div class="mt-3 div-submit-data">
                                            <v-btn outlined type="button" class="button-submit-data" @click="plusPPL()"
                                                style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                                                <v-icon left>mdi-plus</v-icon> เพิ่มส่วนการแปรรูป
                                            </v-btn>
                                        </div>
                                        <br />
                                    </v-card>
                                </v-col>
                            </v-row>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358"
                                    elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-card-text v-if="material_step == 3">
                       <v-form ref="step_3_list" @submit.prevent="submitBtn()">
                         <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="4" style="border-radius: 20px">
                                    <v-card-title class="d-flex align-items-center">
                                        <v-avatar color="#34230F" size="32">
                                            <v-icon dark> mdi-shield-check </v-icon>
                                        </v-avatar>
                                        <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-divider class="mb-3" color="#686868"></v-divider>
                                        <v-row>
                                            <v-col cols="12">
                                               <v-form-group>
                                                 <v-autocomplete v-model="qc_add" :items="qc_list" hide-details="auto"
                                                    @input="qualityList(qc_add)" :item-text="concatenatedText" item-value="id"
                                                    rounded outlined dense chips small-chips label="เกณฑ์การตรวจสอบคุณภาพ" :rules="[v => v.length > 0 || 'กรุณาเลือก']"
                                                    multiple>
                                                </v-autocomplete>
                                               </v-form-group>
                                            </v-col>
                                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                                <div style="width: 100%">
                                                    <div class="table-header-qc-box-data display-pc" style="color: #b1adad">
                                                        <div class="table-header-qc-box-data-text">
                                                            ชื่อเกณฑ์ (English)
                                                        </div>
                                                        <div class="table-header-qc-box-data-text">
                                                            ชื่อเกณฑ์ (ภาษาไทย)
                                                        </div>
                                                        <div class="table-header-qc-box-data-text">
                                                            Unit
                                                        </div>
                                                        <div class="table-header-qc-box-data-text">Aim</div>
                                                        <div class="table-header-qc-box-data-text">
                                                            Green
                                                        </div>
                                                        <div class="table-header-qc-box-data-text">
                                                            Yellow
                                                        </div>
                                                        <div class="table-header-qc-box-data-text">Red</div>
                                                        <div class="table-header-qc-box-data-text">
                                                            value
                                                        </div>
                                                        <div class="table-header-qc-box-data-text">
                                                            ค่าอัตโนมัติ
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                                <v-expansion-panels>
                                                    <v-expansion-panel v-for="(list, index) in qc_list_detail" :key="index"
                                                        :class="{ border_left_yellow: list.isActive }"
                                                        class="radius-10 mt-3" @click="toggleActive(index)">
                                                        <v-expansion-panel-header class="radius-10 border-yellow">
                                                            {{
                                                                list.pq_title.pq_name_eng == null
                                                                ? "-"
                                                                : list.pq_title.pq_name_eng
                                                            }}
                                                            {{
                                                                list.pq_title.pq_name_thai == null
                                                                ? "-"
                                                                : list.pq_title.pq_name_thai
                                                            }}
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content class="">
                                                            <div class="mt-4" v-for="(subList, index) in list.pq_list"
                                                                :key="index">
                                                                <div class="table-header-qc-box-data display-pc mt-3"
                                                                    style="color: #b1adad">
                                                                    <div class="table-header-qc-box-data-text">
                                                                        {{ subList.pq_list_eng }}
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text">
                                                                        {{ subList.pq_list_thai }}
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text">
                                                                        {{ subList.unit }}
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text">
                                                                        {{ subList.pq_list_aim }}
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text">
                                                                        {{ subList.pq_list_green }}
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text">
                                                                        {{ subList.pq_list_yellow }}
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text">
                                                                        {{ subList.pq_list_red }}
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text text-center" >
                                                                        <v-icon v-if="subList.pq_list_type == 0 && subList.pq_value_status == 1 && subList.pq_value_boolean == 1" color="#88B358">mdi-check-circle</v-icon>
                                                                        <v-icon v-else-if="subList.pq_list_type == 0 && subList.pq_value_status == 1 && subList.pq_value_boolean == 0" color="error">mdi-close-circle</v-icon>
                                                                        <div v-else-if="subList.pq_list_type == 1">{{ subList.pq_value }}</div>
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text" v-if="subList.pq_value_status === 1">
                                                                        <v-icon
                                                                            color="#FFBC15">mdi-check-circle</v-icon>
                                                                    </div>
                                                                    <div class="table-header-qc-box-data-text" v-else>
                                                                        <v-icon
                                                                            color="#F28080">mdi-close-circle</v-icon>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                                <div class="mt-5 ml-1" v-if="material_detail.mat_status === 2">
                                    <v-card elevation="4" style="border-radius: 20px">
                                        <v-card-title class="d-flex align-items-center">
                                            <v-avatar color="#34230F" size="32">
                                                <v-icon dark> mdi-shield-check </v-icon>
                                            </v-avatar>
                                            <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบการผลิต</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-divider class="mb-3" color="#686868"></v-divider>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-autocomplete v-model="fq_add" :items="fq_list" hide-details="auto"
                                                        @input="facQualityList(fq_add)" item-text="fq_name" item-value="id"
                                                        rounded outlined dense chips small-chips
                                                        label="เกณฑ์การตรวจสอบการผลิต" multiple>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                                    <div style="width: 100%">
                                                        <div class="table-header-qc-box-data display-pc"
                                                            style="color: #b1adad">
                                                            <div class="table-header-qc-box-data-text">
                                                                ชื่อเกณฑ์
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                ระยะค่าในเกณฑ์
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                Value
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                ค่าอัตโนมัติ
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                                    <v-expansion-panels>
                                                        <v-expansion-panel v-for="(list, index) in fq_list_detail"
                                                            :key="index" :class="{ border_left_yellow: list.isActive }"
                                                            class="radius-10 mt-3" @click="toggleActive(index)">
                                                            <v-expansion-panel-header class="radius-10 border-yellow">
                                                                {{
                                                                    list.fq_title.fq_name == null
                                                                    ? "-"
                                                                    : list.fq_title.fq_name
                                                                }}
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content class="">
                                                                <div class="mt-4" v-for="(subList, index) in list.fq_list"
                                                                    :key="index">
                                                                    <div class="table-header-qc-box-data display-pc mt-3"
                                                                        style="color: #b1adad">
                                                                        <div class="table-header-qc-box-data-text">
                                                                            {{ subList.fq_list_name }}
                                                                        </div>
                                                                        <div class="table-header-qc-box-data-text">
                                                                            {{ subList.fq_list_guide }}
                                                                        </div>
                                                                        <div class="table-header-qc-box-data-text">
                                                                            {{ subList.fq_value }}
                                                                        </div>
                                                                        <div
                                                                            class="table-header-qc-box-data-text text-center">
                                                                            <v-icon color="#FFBC15"
                                                                                v-if="subList.fq_value_boolean == 1">mdi-check-circle</v-icon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358"
                                elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                       </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { isTelephone } from "@/helper/handler";
import before_image from "@/assets/rice (1).png";
import { onlyForCurrency } from "@/helper/handler";
import material from "@/api/warehouse/materials.js";
import unit from "@/api/warehouse/unit.js";
import supplier from "@/api/warehouse/supplier.js";
import productQuality from "@/api/warehouse/qualityinspectioncriteria.js";
import materialType from "@/api/warehouse/materials_types.js";
import facQuality from "@/api/warehouse/inspectioncriteriaproduction.js";
export default {
    data() {
        return {
            loading_page: false,
            stepImport: 1,
            imagePreviewURL: before_image,
            material_picture: null,
            unit: null,
            max_limit: null,
            min_limit: null,
            material_step: 1,
            sub_menu: [
                {
                    text: "คลัง",
                    disabled: false,
                },
                {
                    text: "ตั้งค่าข้อมูลเกี่ยวกับคลัง",
                    disabled: false,
                    href: "#",
                },
                {
                    text: "ตั้งค่าข้อมูลวัตถุดิบ",
                    disabled: false,
                    href: "/stock/setting-stock/setting-raw-material",
                },
                {
                    text: "ตั้งค่าวัตถุดิบ",
                    disabled: false,
                    href: "/stock/setting-stock/setting-raw-material",
                },
                {
                    text: "แก้ไขวัตถุดิบ",
                    disabled: false,
                    href: "#",
                },
            ],
            unit_list: [
                { id: 1, name: "แพ็ค 5" },
                { id: 2, name: "แพ็ค 10" },
                { id: 3, name: "แพ็ค 15" },
            ],
            type_data: [
                { id: 1, name: "น่ำ" },
                { id: 2, name: "ของแข็ง" },
                { id: 3, name: "ของเก็บไม่นาน" },
            ],
            tab: null,
            material_type: ["ทดสอบ1", "ทดสอบ2", "ทดสอบ3", "ทดสอบ4"],
            supplier: ["supplier 1", "supplier 2", "supplier 3"],
            qc_list: [
                { id: 1, name: "เกณฑ์การตรวจสอบคุณภาพ 1" },
                { id: 2, name: "เกณฑ์การตรวจสอบคุณภาพ 2" },
                { id: 3, name: "เกณฑ์การตรวจสอบคุณภาพ 3" },
            ],
            fq_list: [
                { id: 1, name: "เกณฑ์การตรวจสอบการผลิต 1" },
                { id: 2, name: "เกณฑ์การตรวจสอบการผลิต 2" },
                { id: 3, name: "เกณฑ์การตรวจสอบการผลิต 3" },
            ],
            qc_list_detail: [],
            fq_list_detail: [],
            qcIDlist: [],
            fqIDlist: [],
            menu: false,
            modal: false,
            menu2: false,
            toggle_exclusive: 2,
            page: 1,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,
            material_list: [
                {
                    material_group: 1,
                    materials_selected: [],
                    materials_selected_amount: [],
                },
            ],
            material_detail: null,
            qc_add: [],
            fq_add: [],
            mat_data: [],
            formulaDataID: [],
            unitSuffixName: '',
        };
    },

    methods: {
        concatenatedText(item) {
            // Concatenate the two properties you want here for each item
            return item.pq_name_eng + '  ' + item.pq_name_thai;
        },
        link(link) {
            this.$router.push(link);
        },
        isNumber: function (evt, number) {
            return isTelephone(evt, number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number);
        },
        isOnlyDecimal: function (evt, number) {
            return onlyForCurrency(evt, number);
        },
        material_change(type) {
            this.material_step = type;
        },
        async plusPPL() {
            this.material_list.push({
                material_group: this.material_list.length + 1,
                materials_selected: [],
                materials_selected_amount: [],
            });
        },
        async deletePPL(material_group) {
            this.material_list = this.material_list.filter((detail) => {
                return detail.material_group != material_group;
            });
        },
        async setproductsize_delete() {
            this.confirm_delete_dialog = true;
            this.loading_detail_page = false;
        },
        async materialDetail() {
            await material.detail(this.$route.params.id).then((response) => {
                if (response.data.successful === true) {
                    this.material_detail = response.data.mat_detail;
                    this.material_detail.max = this.material_detail.max.split('.')[0]
                    this.material_detail.min = this.material_detail.min.split('.')[0]
                    // this.material_change(this.material_detail.mat_status);
                    response.data.mat_pd_quality.forEach((e) => {
                        this.qc_add.push(e.product_quality_id);
                        this,
                            this.qcIDlist.push({
                                id: e.id,
                                materials_id: e.materials_id,
                                product_quality_id: e.product_quality_id,
                            });
                    });
                    response.data.mat_fac_quality.forEach((e) => {
                        this.fq_add.push(e.factory_quality_id);
                        this.fqIDlist.push({
                            id: e.id,
                            materials_id: e.materials_id,
                            factory_quality_id: e.factory_quality_id,
                        });
                    });
                    //รูปมาลงตรงนี้นะครับซึ่งหลังบ้านมีส่งมาแล้วในเส้นของ detail แต่หน้าบ้านมันไม่ขึ้นให้ครับ
                    // this.material_picture = response.data.mat_detail.mat_pic;
                    if (response.data.mat_detail.mat_pic) {
                        this.material_picture = {
                            name: response.data.mat_detail.mat_pic
                        }
                    }
                  
                    this.imagePreviewURL = response.data.mat_detail.mat_pic ? response.data.mat_detail.mat_pic : before_image
                    ///////////////////////////////////////////////////////
                    response.data.mat_formula.forEach((e) => {
                        this.formulaDataID.push({ id: e.id, mat_for_id: e.mat_for_id });
                    });
                    this.arrayConvertFormat(response.data.mat_formula);
                }
            });
            await unit.list().then((response) => {
                this.unit_list = response.data.data;
            });
            await supplier.list().then((response) => {
                this.supplier = response.data.data;
            });
            await productQuality.list_type(this.material_detail.mat_status == 3 ? 3 : 1).then((response) => {
                this.qc_list = response.data.data;
            });
            await materialType.list_type(this.material_detail.mat_status).then((response) => {
                this.material_type = response.data.data;
            });
            await material.list_all(this.$route.params.id).then((response) => {
                this.mat_data = response.data.data;
            });
            await facQuality.list_type(1).then((response) => {
                this.fq_list = response.data.data;
            });
            this.updateSuffixValue(this.material_detail.unit_main)
        },
        async qualityList(idArr) {
            this.qc_list_detail = [];
            await idArr.forEach(async (e) => {
                await productQuality
                    .qualityInspectionCriteriaDetail(e)
                    .then((response) => {
                        return this.qc_list_detail.push(response.data.detail);
                    });
            });
        },
        async arrayConvertFormat(formulaArr) {
            this.material_list = formulaArr.reduce((result, item) => {
                const { mat_group, mat_for_id, mat_for_qty } = item;

                // Find the corresponding material_group object
                let materialGroup = result.find(
                    (group) => group.material_group === mat_group
                );

                // Create a new one if the material_group doesn't exist.
                if (!materialGroup) {
                    materialGroup = {
                        material_group: mat_group,
                        materials_selected: [],
                        materials_selected_amount: [],
                    };
                    result.push(materialGroup);
                }

                // Add the id to materials_selected and mat_for_qty to materials_selected_amount
                materialGroup.materials_selected.push(mat_for_id);
                materialGroup.materials_selected_amount.push(mat_for_qty);

                return result;
            }, []);
        },
        async facQualityList(fqArr) {
            this.fq_list_detail = [];
            await fqArr.forEach(async (e) => {
                await facQuality
                    .detailInspectionCriteriaProduction(e)
                    .then((response) => {
                        return this.fq_list_detail.push(response.data.detail);
                    });
            });
        },
        async onFileChange(payload) {
            const file = payload;
            console.log(payload, "pic");
            let extall = "png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split(".").pop().toLowerCase();
            if (parseFloat(extall.indexOf(ext)) < 0) {
                this.imagePreviewURL = before_image;
                this.material_picture = null;
                this.alert_message_error =
                    "อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG) เท่านั้น";
                this.alert_error = true;
                setTimeout(() => (this.alert_error = false), 5000);
            } else {
                if (file) {
                    this.imagePreviewURL = URL.createObjectURL(file);
                    console.log(this.imagePreviewURL);
                    URL.revokeObjectURL(file);
                } else {
                    this.imagePreviewURL = before_image;
                }
            }
        },
        async onFileRemove() {
            this.material_picture = null;
            this.imagePreviewURL = before_image;
        },
        async submitBtn() {
            this.loading_page = true;
            let bodyFormData = new FormData();
            var mappedArrayQC;
            var remainingObjectsQC;
            var filteredUndefinedQCArray;
            var qc_result;
            var mappedArrayFQ;
            var remainingObjectsFQ;
            var filteredUndefinedFQArray;
            var fq_result;
            switch (this.material_detail.mat_status) {
                case 1:
                    if(this.$refs.step_3_list.validate()){
                    //วัตถุดิบปกติ
                    bodyFormData.append("mat_name", this.material_detail.mat_name);
                    bodyFormData.append("mat_status", this.material_detail.mat_status);
                    bodyFormData.append("barcode", this.material_detail.barcode);
                    bodyFormData.append(
                        "materials_type_id",
                        this.material_detail.materials_type_id
                    );
                    bodyFormData.append("supplier_id", this.material_detail.supplier_id);
                    bodyFormData.append("max", this.material_detail.max ? this.material_detail.max : 0);
                    bodyFormData.append("min", this.material_detail.min ? this.material_detail.min : 0);
                    bodyFormData.append("unit_main", this.material_detail.unit_main);
                    bodyFormData.append(
                        "unit_main_gram",
                        this.material_detail.unit_main_gram ? this.material_detail.unit_main_gram : 0
                    );
                    bodyFormData.append(
                        "retail_price",
                        this.material_detail.retail_price
                    );
                    bodyFormData.append("unit_other", this.material_detail.unit_other ? this.material_detail.unit_other : 0);
                    bodyFormData.append(
                        "unit_other_qty",
                        this.material_detail.unit_other_qty ? this.material_detail.unit_other_qty : 0
                    );
                    bodyFormData.append("other_price", this.material_detail.other_price ? this.material_detail.other_price : 0);
                    bodyFormData.append("mat_pic", this.material_picture);
                    //===========================================================================================
                    //quality condition
                    mappedArrayQC = this.qcIDlist.map((obj) => {
                        const foundIndex = this.qc_add.findIndex(
                            (qcID) => qcID === obj.product_quality_id
                        );
                        if (foundIndex == -1) {
                            return {
                                ...obj,
                                is_delete: 1,
                            };
                        }
                        return obj;
                    });

                    remainingObjectsQC = this.qc_add.map((obj) => {
                        const foundIndex = this.qcIDlist.findIndex(
                            (qcID) => qcID.product_quality_id === obj
                        );
                        if (foundIndex == -1) {
                            return {
                                materials_id: this.material_detail.id,
                                product_quality_id: obj,
                            };
                        }
                    });
                    filteredUndefinedQCArray = remainingObjectsQC.filter(
                        (value) => value !== undefined
                    );
                    qc_result = [...filteredUndefinedQCArray, ...mappedArrayQC];
                    qc_result.forEach((e) => {
                        bodyFormData.append("product_quality", JSON.stringify(e));
                    });
                    await material
                .edit(this.material_detail.id, bodyFormData)
                .then((response) => {
                    console.log(response.data, 'response');
                    this.loading_page = true
                    if (response.data.successful === true) {
                        this.loading_page = false
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.alert_message_success = 'แก้ไขวัตถุดิบเสร็จสิ้น'
                        this.$router.push("/stock/setting-stock/setting-raw-material");
                    } else {
                        this.loading_page = false
                        this.alert_error = true
                        setTimeout(() => this.alert_error = false, 5000)
                        this.alert_message_error = 'มีชื่อวัตถุดิบนี้ในระบบแล้ว'
                    }
                });
                    }
                    this.loading_page= false
                    break;
                case 2:
                    if(this.$refs.step_3_list.validate()){
                          //วัตถุดิบแปรรูป
                    bodyFormData.append("mat_name", this.material_detail.mat_name);
                    bodyFormData.append("mat_status", this.material_detail.mat_status);
                    bodyFormData.append("barcode", this.material_detail.barcode);
                    bodyFormData.append(
                        "materials_type_id",
                        this.material_detail.materials_type_id
                    );
                    bodyFormData.append("max", this.material_detail.max ? this.material_detail.max : 0);
                    bodyFormData.append("min", this.material_detail.min ? this.material_detail.min : 0);
                    bodyFormData.append("unit_main", this.material_detail.unit_main);
                    bodyFormData.append(
                        "unit_main_gram",
                        this.material_detail.unit_main_gram ? this.material_detail.unit_main_gram : 0
                    );
                    bodyFormData.append(
                        "formula_amount",
                        this.material_detail.formula_amount ? this.material_detail.formula_amount : 0
                    );
                    bodyFormData.append("mat_pic", this.material_picture);
                    //===========================================================================================
                    //วัตถุดิบแปรรูป
                    var formula = [];
                    this.material_list.flatMap((list) =>
                        list.materials_selected.map((e, index) =>
                            formula.push({
                                materials_id: this.material_detail.id,
                                mat_for_id: e,
                                mat_for_qty: list.materials_selected_amount[index],
                                mat_group: list.material_group,
                            })
                        )
                    );
                    var transFormData = formula.map((item) => {
                        const matchingIDs = this.formulaDataID
                            .filter((data) => data.mat_for_id === item.mat_for_id)
                            .map((data) => data.id);
                        return {
                            ...item,
                            id: matchingIDs.length === 1 ? matchingIDs[0] : null,
                        };
                    });
                    var filterDeletedProduct = this.formulaDataID.map((obj) => {
                        const foundIndex = transFormData.findIndex(
                            (item) => item.mat_for_id === obj.mat_for_id
                        );
                        if (foundIndex == -1) {
                            return {
                                ...obj,
                                is_delete: 1,
                            };
                        }
                    });
                    var filteredUndefinedArr = filterDeletedProduct.filter(
                        (value) => value !== undefined
                    );
                    var result_formula = [...transFormData, ...filteredUndefinedArr];
                    result_formula.forEach((e) => {
                        bodyFormData.append("mat_formula", JSON.stringify(e));
                    });
                    //===========================================================================================
                    //quality condition
                    mappedArrayQC = this.qcIDlist.map((obj) => {
                        const foundIndex = this.qc_add.findIndex(
                            (qcID) => qcID === obj.product_quality_id
                        );
                        if (foundIndex == -1) {
                            return {
                                ...obj,
                                is_delete: 1,
                            };
                        }
                        return obj;
                    });

                    remainingObjectsQC = this.qc_add.map((obj) => {
                        const foundIndex = this.qcIDlist.findIndex(
                            (qcID) => qcID.product_quality_id === obj
                        );
                        if (foundIndex == -1) {
                            return {
                                materials_id: this.material_detail.id,
                                product_quality_id: obj,
                            };
                        }
                    });
                    filteredUndefinedQCArray = remainingObjectsQC.filter(
                        (value) => value !== undefined
                    );
                    qc_result = [...filteredUndefinedQCArray, ...mappedArrayQC];
                    qc_result.forEach((e) => {
                        bodyFormData.append("product_quality", JSON.stringify(e));
                    });
                    //===========================================================================================
                    //process quality
                    mappedArrayFQ = this.fqIDlist.map((obj) => {
                        const foundIndex = this.fq_add.findIndex(
                            (fqID) => fqID === obj.factory_quality_id
                        );
                        if (foundIndex == -1) {
                            return {
                                ...obj,
                                is_delete: 1,
                            };
                        }
                        return obj;
                    });

                    remainingObjectsFQ = this.fq_add.map((obj) => {
                        const foundIndex = this.fqIDlist.findIndex(
                            (fqID) => fqID.factory_quality_id === obj
                        );
                        if (foundIndex == -1) {
                            return {
                                materials_id: this.material_detail.id,
                                factory_quality_id: obj,
                            };
                        }
                    });
                    filteredUndefinedFQArray = remainingObjectsFQ.filter(
                        (value) => value !== undefined
                    );
                    fq_result = [...mappedArrayFQ, ...filteredUndefinedFQArray];
                    fq_result.forEach((e) => {
                        bodyFormData.append("factory_quality", JSON.stringify(e));
                    });
                    await material
                .edit(this.material_detail.id, bodyFormData)
                .then((response) => {
                    console.log(response.data, 'response');
                    this.loading_page = true
                    if (response.data.successful === true) {
                        this.loading_page = false
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.alert_message_success = 'แก้ไขวัตถุดิบเสร็จสิ้น'
                        this.$router.push("/stock/setting-stock/setting-raw-material");
                    } else {
                        this.loading_page = false
                        this.alert_error = true
                        setTimeout(() => this.alert_error = false, 5000)
                        this.alert_message_error = 'มีชื่อวัตถุดิบนี้ในระบบแล้ว'
                    }
                });
                    }
                    this.loading_page = false;
                    break;
                case 3:
                   if(this.$refs.step_3_list.validate()){
                     //บรรจุภัณฑ์
                    bodyFormData.append("mat_name", this.material_detail.mat_name);
                    bodyFormData.append("mat_pic", this.material_picture);
                    bodyFormData.append("mat_status", this.material_detail.mat_status);
                    bodyFormData.append("barcode", this.material_detail.barcode);
                    bodyFormData.append(
                        "materials_type_id",
                        this.material_detail.materials_type_id
                    );
                    bodyFormData.append("max", this.material_detail.max ? this.material_detail.max : 0);
                    bodyFormData.append("min", this.material_detail.min ? this.material_detail.min : 0);
                    bodyFormData.append("other_price", this.material_detail.other_price ? this.material_detail.other_price : 0);
                    bodyFormData.append(
                        "retail_price",
                        this.material_detail.retail_price ?  this.material_detail.retail_price  : 0
                    );
                    bodyFormData.append("supplier_id", this.material_detail.supplier_id);
                    bodyFormData.append("unit_main", this.material_detail.unit_main ? this.material_detail.unit_main : 0);
                    bodyFormData.append("unit_other", this.material_detail.unit_other ? this.material_detail.unit_other : 0);
                    bodyFormData.append(
                        "unit_other_qty",
                        this.material_detail.unit_other_qty ? this.material_detail.unit_other_qty : 0
                    );
                    bodyFormData.append(
                        "formula_amount",
                        this.material_detail.formula_amount ? this.material_detail.formula_amount : 0
                    );
                    //===========================================================================================
                    //quality
                    mappedArrayQC = this.qcIDlist.map((obj) => {
                        const foundIndex = this.qc_add.findIndex(
                            (qcID) => qcID === obj.product_quality_id
                        );
                        if (foundIndex == -1) {
                            return {
                                ...obj,
                                is_delete: 1,
                            };
                        }
                        return obj;
                    });

                    remainingObjectsQC = this.qc_add.map((obj) => {
                        const foundIndex = this.qcIDlist.findIndex(
                            (qcID) => qcID.product_quality_id === obj
                        );
                        if (foundIndex == -1) {
                            return {
                                materials_id: this.material_detail.id,
                                product_quality_id: obj,
                            };
                        }
                    });
                    filteredUndefinedQCArray = remainingObjectsQC.filter(
                        (value) => value !== undefined
                    );
                    qc_result = [...filteredUndefinedQCArray, ...mappedArrayQC];
                    qc_result.forEach((e) => {
                        bodyFormData.append("product_quality", JSON.stringify(e));
                    });
                    await material
                .edit(this.material_detail.id, bodyFormData)
                .then((response) => {
                    console.log(response.data, 'response');
                    this.loading_page = true
                    if (response.data.successful === true) {
                        this.loading_page = false
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.alert_message_success = 'แก้ไขวัตถุดิบเสร็จสิ้น'
                        this.$router.push("/stock/setting-stock/setting-raw-material");
                    } else {
                        this.loading_page = false
                        this.alert_error = true
                        setTimeout(() => this.alert_error = false, 5000)
                        this.alert_message_error = 'มีชื่อวัตถุดิบนี้ในระบบแล้ว'
                    }
                });
                   }
                    break;
            }
        },
        updateSuffixValue(id) {
            this.unitSuffixName = this.unit_list.find((e) => e.id == id).unit_name
        }
    },
    mounted() {
        this.materialDetail().then(() => {
            this.qualityList(this.qc_add);
            this.facQualityList(this.fq_add);
        });
        // this.materialDetail()
    },
    computed: {
        getMaterialStatusText() {
            switch (this.material_detail.mat_status) {
                case 1:
                    return "วัตถุดิบปกติ";
                case 2:
                    return "วัตถุดิบแปรรูป";
                case 3:
                    return "บรรจุภัณฑ์";
                default:
                    return "Unknown Material";
            }
        },
        slectedName() {
            return (id) => {
                let data = this.mat_data.find((e) => e.id == id)
                if(data != null){
                return data.mat_name;
                }else{
                    return '-'
                }
            };
        },
    },
    watch: {
        'material_detail.unit_main': 'updateSuffixValue',
        'material_detail.other_price': function (newPrice) {
            if (newPrice === '0.00') {
                this.material_detail.other_price = '';
            }
        }
    }
};
</script>
<style scoped>
.input-group {
    display: flex;
    gap: 10px;
}

.form-select {
    width: 150px;
}

.image_preview>.v-avatar {
    overflow: unset;
}

.upload_image {
    position: absolute;
    top: 0;
    right: 0;
}

.form-control {
    flex: 1;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.padding-text-title {
    padding: 8px !important;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 20px;
    color: #34230f;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88b358 !important;
    color: #ffffff;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #f28080 !important;
    color: #ffffff;
}

.table-header-import-data {
    display: grid;
    grid-template-columns: 2% 20% 20% 20% 20% 18%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-items {
    display: grid;
    grid-template-columns: 5% 20% 19% 20% 26% 10%;
    align-items: center;
}

.table-header-import-step3-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-step3-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-step3-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
}

.table-header-qc-box-data {
    display: grid;
    grid-template-columns: 17% 20% 7% 8% 12% 10% 9% 9% 10%;
    padding: 10px;
}

.table-header-qc-box-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-qc-box-items {
    display: grid;
    grid-template-columns: 17% 20% 7% 8% 12% 10% 10% 10% 6%;
    align-items: center;
    padding: 15px;
}

.table-header-setstep-4-product-box-data {
    display: grid;
    grid-template-columns: 15% 15% 10% 10% 10% 10% 10% 10% 10%;
    padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.table-header-setstep-4-product-quality-box-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-quality-box-data-text {
    color: #888888;
    font-size: 14px;
    margin-left: 20px;
    font-weight: bold;
}

.border-yellow {
    border-bottom: 1px solid #ffbc15;
}

.border_left_yellow {
    border-left: 4px solid #ffbc15;
    border-top: 1px solid #ffbc15;
    border-bottom: 1px solid #ffbc15;
    border-right: 1px solid #ffbc15;
    border-radius: 10px;
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 0 10px 15px !important;
}
</style>