import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    dataList: (page, size, search) => {
        return HTTP.get(`/producttype/list?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    dataList_all: () => {
        return HTTP.get(`/producttype/list/all`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    add: (data) => {
        return HTTP.post('/producttype/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    detail: (id) => {
        return HTTP.get('/producttype/detail/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    edit: (id, data) => {
        return HTTP.post('/producttype/edit/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    delete: (id) => {
        return HTTP.post('/producttype/delete/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    list: () => {
        return HTTP.get('/producttype/list/all', {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
}