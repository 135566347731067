<template>
  <div>
    <ProductionProcessRecheckEdit/>
  </div>
</template>

<script>
import ProductionProcessRecheckEdit
  from "@/components/production_process/processing_raw_materials/ProductionProcessRecheckEdit";
export default {
  name: "Production_process_recheck_edit",
  components: {ProductionProcessRecheckEdit}
}
</script>

<style scoped>

</style>