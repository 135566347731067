<template>
    <div>
        <div class="d-flex">
            <div class="topic_processing_header border_right">สร้างรอบการบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2">การบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
            <div class="topic_processing_content my-2">สร้างรอบการบรรจุภัณฑ์</div>
        </div>
        <div class="mt-3">
            <v-card elevation="2" class="card-radius">
                <v-card-title>
                    <b>ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ</b>
                </v-card-title>
                <v-stepper class="ml-10 mr-10" v-model="e1" alt-labels color="#ffbc15" elevation="0">

                    <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1" color="#ffbc15" style="flex-basis: 200px;">
                            <span style="font-size: 14px">ข้อมูลความพร้อมการบรรจุภัณฑ์</span>
                        </v-stepper-step>
                        <v-stepper-step :complete="e1 > 2" step="2" color="#ffbc15">
                            <span style="font-size: 14px">ยืนยันการบรรจุภัณฑ์</span>
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-progress-linear color="#ffbc15" background-color="#d9d9d9" v-model="progress_value" height="10"
                        rounded />

                    <v-stepper-items class="ml-n5 mr-n5">

                        <v-stepper-content step="1">
                            <v-card-text>

                                <v-card elevation="4" class="py-2" style="border-radius:20px">

                                    <v-card-title>
                                        <v-avatar color="#34230F" size="36">
                                            <v-icon dark>
                                                mdi-note-text-outline
                                            </v-icon>
                                        </v-avatar>
                                        <span class="ml-3 title-card-text">ข้อมูลความพร้อมการเตรียมวัตถุดิบ</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                                        <div class="table-header-processing ml-15" style="color: #b1adad;">
                                            <div class="table-sub-header-processing">
                                                วัตถุดิบ
                                            </div>
                                            <div class="table-sub-header-processing">
                                                จำนวนวัตถุดิบที่ต้องใช้ (ชิ้น)
                                            </div>
                                            <div class="table-sub-header-processing">
                                                เปรียบเทียบกับวัตถุดิบในคลัง
                                            </div>
                                            <div class="table-sub-header-processing text-center ml-n5">
                                                ผลลัพธ์
                                            </div>
                                        </div>
                                        <v-divider class="ml-15 my-3" dark color="#a3a3a3"></v-divider>
                                        <v-card elevation="0" class="grid-processing-items ma-4 ml-15 black--text"
                                            v-for="(detail, index) in dataList" :key="index">
                                            <div class="ml-3">{{ detail.mat_name }}</div>
                                            <div class="ml-5">{{ formatPrice(detail.qty_need_to_use) }}</div>
                                            <div v-if="detail.status_enough != 1" class="ml-5" style="color: #f28080">ขาด:
                                                <span>{{
                                                    formatPrice(parseFloat(detail.qty_need_to_use) -
                                                        parseFloat(detail.mat_instock)) }}</span>
                                            </div>
                                            <div v-else class="ml-5">พอต่อการบรรจุภัณฑ์</div>
                                            <div v-if="detail.status_enough != 1" class="text-center"
                                                style="color: #f28080">ไม่พอ</div>
                                            <div v-else class="text-center">พอ</div>
                                        </v-card>
                                        <v-divider class="ml-15 my-1" dark color="#e9e7e7"></v-divider>
                                        <div class="grid-processing-items ma-4 ml-15 black--text">
                                            <div class="ml-3"></div>
                                            <div class="ml-5"></div>
                                            <div></div>
                                            <div v-if="check_good == true" class="text-right" style="font-size: 22px"><b
                                                    style="color: #f28080">ไม่พร้อมบรรจุภัณฑ์</b></div>
                                            <div v-if="check_good == false" class="text-right" style="font-size: 22px"><b
                                                    style="color: #88B358">พร้อมบรรจุภัณฑ์</b></div>
                                        </div>
                                    </v-card-text>

                                    <div class="text-right ma-2 mt-n3">
                                        <!-- <v-btn class="mt-2 mr-2" large rounded outlined color="#6C7FD8"
                                            @click="add_dialog = true">
                                            <v-icon left>mdi-store-cog</v-icon>แปรรูปวัตถุดิบ
                                        </v-btn> -->
                                        <v-btn v-if="check_good == true" class="mt-2" large rounded outlined color="#88B358"
                                            @click="add_dialog = true">
                                            <v-icon left>mdi-text-box-check-outline</v-icon>สั่งซื้อวัตถุดิบ
                                        </v-btn>
                                    </div>

                                </v-card>
                            </v-card-text>

                            <div class="my-3 text-center">
                                <v-btn v-if="check_good == false" color="#88B358" elevation="4" rounded large outlined
                                    @click="saveStepOne()">
                                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                                </v-btn>
                                <v-btn v-else color="#88B358" elevation="4" rounded large outlined disabled>
                                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                                </v-btn>
                            </div>

                        </v-stepper-content>

                        <v-stepper-content step="2">

                            <section>
                                <v-card-text>
                                    <v-card elevation="4" class="py-2 px-3" style="border-radius:20px;">
                                        <v-card-title>
                                            <v-avatar color="#34230F" size="36">
                                                <img class="icon-image-logo" src="@/assets/cereal (1).png">
                                            </v-avatar>
                                            <span class="ml-3 title-card-text">ยืนยันการใช้วัตถุดิบเพื่อบรรจุภัณฑ์</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>

                                            <v-row class="">
                                                <v-col v-for="(loop_header, index_header) in mock_header"
                                                    :key="index_header" class="card-table-radius-sub" cols="12" lg="6"
                                                    xl="6">
                                                    <v-card elevation="2" class="border-card">
                                                        <v-row class="ma-1 go-to-card">

                                                            <v-col cols="6 flex-center-header">
                                                                <div class="tag-title-step-card">
                                                                    <b class="black--text">{{ loop_header.pro_size_name
                                                                    }}</b>
                                                                </div>
                                                                <span> {{ loop_header.pro_name }} </span>
                                                            </v-col>
                                                            <v-col cols="6 text-right flex-center-header-two">
                                                                <span>จำนวน {{ loop_header.qty }} {{
                                                                    loop_header.unit_name }}</span>
                                                            </v-col>

                                                            <v-col cols="12 py-1 col-harder">
                                                                <v-divider class="border-main pt-1" dark
                                                                    color=""></v-divider>
                                                            </v-col>

                                                            <v-col cols="4 pb2">
                                                                <span>วัตถุดิบ </span>
                                                            </v-col>
                                                            <v-col cols="4 pb-2">
                                                                <span>เลข Lot </span>
                                                            </v-col>
                                                            <v-col cols="4 px-0 pb-2 ">
                                                                <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                                                            </v-col>

                                                        </v-row>

                                                        <v-row class="ma-1 "
                                                            v-for="(loop_sub, index) in loop_header.material_list"
                                                            :key="index">
                                                            <v-col cols="4 pt-0 ">
                                                                <span> {{ loop_sub.mat_name }}</span>
                                                            </v-col>

                                                            <v-col cols="8  pt-0">
                                                                <v-row v-for="detail in loop_sub.stock_list" :key="detail">
                                                                    <v-col cols="6">
                                                                        <div>{{ detail.material_lot }}</div>
                                                                    </v-col>
                                                                    <v-col cols="6">
                                                                        <div>{{ detail.quantity_cut }}</div>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>

                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                    </v-card>
                                </v-card-text>

                            </section>

                            <div class="mt-3 div-submit-data" v-if="status_packing < 2">
                                <v-btn type="button" class="button-submit-data" color="#88B358" rounded large
                                    @click="saveStep2()">
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>

                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </div>


        <v-dialog v-model="add_dialog" transition="dialog-top-transition" max-width="643" style="border-radius:20px">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="add_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <div class="d-flex flex-column align-center">
                        <img class="icon-image mb-3" src="@/assets/notice.png">
                    </div>
                    <div class="text-center">
                        <h1 class="black--text">วัตถุดิบไม่เพียงพอ</h1>
                        <div class="my-2">วัตถุดิบไม่เพียงพอต่อการบรรจุภัณฑ์กรุณานำเข้าวัตถุดิบ</div>
                    </div>
                    <div class="mt-3" style="padding:10px;border:1px solid #34230F;border-radius:20px;">
                        <b style="font-size:15px; color:black">รายการวัตถุดิบที่ต้องซื้อหรือนำเข้า</b>
                        <div class="mt-3 table-header-processing-dialog" style="color: #b1adad;">
                            <div class="table-sub-header-processing-dialog">
                                ลำดับ
                            </div>
                            <div class="table-sub-header-processing-dialog">
                                วัตถุดิบ
                            </div>
                            <div class="table-sub-header-processing-dialog">
                                จำนวน (ชิ้น)
                            </div>
                        </div>
                        <!-- {{ data_list }} -->
                        <div v-for="(item, index) in data_list" :key="item" class="mt-3 table-header-processing-dialog"
                            style="color: #b1adad;">
                            <div class="table-sub-header-processing-dialog text-left ms-5">
                                {{ index + 1 }}
                            </div>
                            <div class="table-sub-header-processing-dialog text-left">
                                {{ item.mat_name }}
                            </div>
                            <div class="table-sub-header-processing-dialog text-left">
                                {{ formatPrice(parseFloat(item.qty_need_to_use) - parseFloat(item.mat_instock)) }}
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        <v-btn @click="$router.push('/stock/material-import')" class="white--text" color="#88B358"
                            elevation="4" rounded large>
                            <v-icon left>mdi-text-box-check-outline</v-icon> สั่งซื้อวัตถุดิบ
                        </v-btn>
                        <div class="ma-4" style="cursor: pointer">ยกเลิก</div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
  
<script>
import process_package from '@/api/process_package/package.js'
import { formatPrice } from "@/helper/handler";
import Swal from "sweetalert2";
export default {
    name: "ProcessingRawMaterials",
    data() {
        return {
            e1: 1,
            progress_value: 0,
            add_dialog: false,
            checked: true,
            check_good: true,
            check_good1: true,
            dataList: [],
            data_list: [],
            batchList: [
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
                {
                    percent: '100%',
                    gams: 5000,
                    gamsFilter: '5,000.00',
                    totalGams: '8,736.00'
                },
            ],
            mock_header: [],
            status_packing: null,
        }
    },
    methods: {
        formatPrice(number) {
            return formatPrice(number);
        },
        linkToSteps() {
            this.$router.push('/prm/processing_raw_materials')
        },
        progress(val, step) {
            this.e1 = step
            this.progress_value = val
        },
        saveProduction() {
            this.$router.push('/prm/production_result_create')
        },
        // Step 1 ข้อมูลความพร้อมการแปรรูป
        async getStepTwoListOne() {
            let id = this.$route.params.id;
            await process_package.getStepTwoListOne(id).then((response) => {
                if (response.data.successful === true) {
                    this.loading_page = false
                    this.dataList = response.data.data
                    this.status_packing = response.data.status_packing

                    let check = this.dataList.filter(o => o.status_enough === 0)
                    console.log(check.length);
                    this.data_list = check;
                    if (check.length != 0) {
                        this.check_good = true;
                    } else {
                        this.check_good = false;
                    }
                }
            }).catch((error) => {
                if (error.response.status === 502) {
                    window.location.reload()
                }
            })
        },
        async saveStepOne() {
            let id = this.$route.params.id;
            await process_package.saveSteptwoPart1(id).then((response) => {
                if (response.data.successful === true) {
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 502) {
                    window.location.reload()
                }
            })
            this.getStepTwo()
        },
        async getStepTwo() {
            let id = this.$route.params.id;
            await process_package.getStepTwoPart2(id).then((response) => {
                if (response.data.successful === true) {
                    this.loading_page = false
                    this.mock_header = response.data.data
                }
            }).catch((error) => {
                if (error.response.status === 502) {
                    window.location.reload()
                }
            })
            this.progress(100, 2)
        },
        async saveStep2() {
            if (this.status_packing < 2) {
                this.loading_page = true;
                let id = this.$route.params.id;
                await process_package.saveStepTwoPart2(id).then((response) => {
                    if (response.data.successful === true) {
                        this.loading_page = false
                        let timerInterval;
                        Swal.fire({
                            title: "ทำรายการเสร็จสิ้น",
                            icon: "success",
                            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading();
                                const b = Swal.getHtmlContainer().querySelector("b");
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft();
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                this.$router.push("/pac/processing-packaging-step/" + this.$route.params.id);
                            }
                        });
                    }
                }).catch((error) => {
                    if (error.response.status === 502) {
                        window.location.reload()
                    }
                })
            } else {
                let timerInterval;
                Swal.fire({
                    title: "ทำรายการเสร็จสิ้น",
                    icon: "success",
                    html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector("b");
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        this.$router.push("/pac/processing-packaging-step/" + this.$route.params.id);
                    }
                });
            }

        },
    },
    mounted() {
        this.getStepTwoListOne()
        if (this.$route.params.status) {
            this.getStepTwo()
        }
    }
}
</script>
  
<style scoped>
.border_right {
    border-right: 2px solid #000000;
    padding-right: 10px;
}

.topic_processing_header {
    font-size: 24px;
    color: #000000;
}

.topic_processing_content {
    color: #7E7E7E;
    padding-left: 10px;
}

.table-header-processing {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}

.table-header-processing-dialog {
    display: grid;
    grid-template-columns: 25% 58% 17%;
}

.table-sub-header-processing-dialog {
    color: #686868;
    font-size: 14px;
    margin-left: 5px;
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: baseline;
}

.icon-image {
    width: 120px;
    height: 120px;
    text-align: center;
}

.icon-image-logo {
    width: 24px;
    height: 24px;
    vertical-align: center;
}

.tag-title-step {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.divider-table {
    border: 3px solid #ffbc15;
    border-radius: 3px;
}

.w-border-header-first {
    background: #ffbc15;
    border-top: 15px solid #ffbc15;
    border-radius: 36px;
    width: 110%;
}

.w-border-header-first-steptree {
    background: #ffbc15;
    border-top: 15px solid #ffbc15;
    border-radius: 36px;
    width: 130%;
}

.inline-box-specific {
    background: #fff8e8;
    border-top: 1px solid #ffbc15;
    border-bottom: 1px solid #ffbc15;
}

.w-border-header-sub {
    background: #34230F;
    border: 7px solid #34230F;
    border-radius: 36px;
    width: 60%;
}

.card-main-table {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding: 15px;
    width: 110%;
    height: 99%;
}

.card-main-table-steptree {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding: 15px;
    width: 130%;
    height: 99%;
}

.card-sub-table {
    border: 1px solid #34230F;
    border-radius: 15px;
    padding: 15px;
    max-width: 60%;
    width: 60%;
    transition: 0.5s;
}

.card-sub-table:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu {
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu section {
    display: inline-block;
    margin-right: -5%;
}

.scroll-menu section:first-child {
    display: inline-block;
    position: sticky;
    left: 0;
    margin-right: 5%;
    background: #FFFFFF;
    z-index: 1;
}

.w-border-header-sub-last {
    background: #34230F;
    border: 7px solid #34230F;
    border-radius: 36px;
    width: auto;
}

.card-sub-table-last {
    border: 1px solid #34230F;
    border-radius: 15px;
    padding: 15px;
    max-width: 100%;
    width: 100%;
    height: 99%;
    transition: 0.5s;
}

.card-sub-table-last:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-last {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu-last .last {
    display: inline-block;
    margin-right: 2%;
}

.scroll-menu-last section:first-child {
    display: inline-block;
    position: sticky;
    left: 0;
    margin-right: 7.5%;
    background: #FFFFFF;
    z-index: 1;
}

.summarize {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
}

.v-stepper__step {
    padding: 7px;
}

.header-png {
    position: absolute;
    top: -15px;
    left: 20px;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.flex-center-header>.tag-title-step-card {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.flex-center-header>.tag-title-step-card {
    border: 1px solid #34230F;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(even)>.border-card {
    border-radius: 20px;
    border: 1px solid #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card {
    border-radius: 20px;
    border: 1px solid #34230F;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #34230F;
}
</style>