<template>
  <div>
    <ProductionProductGoodProcessRecheck/>
  </div>
</template>

<script>
import ProductionProductGoodProcessRecheck from "@/components/production_process/processing_product_good/ProductionProductGoodProcessRecheck";
export default {
  name: "ProductionProductGoodProcessRecheck_Page",
  components: {ProductionProductGoodProcessRecheck}
}
</script>

<style scoped>

</style>