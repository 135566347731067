import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {

    dataList: (page, size, search) => {
        return HTTP.get(`/productsize/list?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    dataListAll: () => {
        return HTTP.get(`/productsize/list/all`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    add: (data) => {
        console.log(data);
        return HTTP.post('/productsize/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    detail: (id) => {
        return HTTP.get('/productsize/detail/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    edit: (id, data) => {
        return HTTP.post('/productsize/edit/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    delete: (id) => {
        return HTTP.post('/productsize/delete/' + id,null,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },

}