<template>
  <div>
    <ProcessingRawMaterials/>
  </div>
</template>

<script>
import ProcessingRawMaterials from "@/components/production_process/processing_raw_materials/ProcessingRawMaterials";
export default {
  name: "Processing_raw_materials",
  components: {
    ProcessingRawMaterials
  }
}
</script>

<style scoped>

</style>