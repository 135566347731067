<template>
  <div>
    <ProcessingMaterials/>
  </div>
</template>

<script>
import ProcessingMaterials from "@/components/production_process/processing_raw_materials/ProcessingMaterials";
export default {
  name: "Processing_materials",
  components: {
    ProcessingMaterials
  }
}
</script>

<style scoped>

</style>