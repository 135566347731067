<template>
    <div>
        <WarehouseDetail_page />
    </div>
</template>
  
<script>
import WarehouseDetail_page from '@/components/stock/warehouse_stock/WarehouseDetail'
export default {
    name: 'WarehouseDetail',
    components: {
        WarehouseDetail_page,
    }
}
</script>