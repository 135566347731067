<template>
    <div>
        <AccountingSavePage />
    </div>
</template>
  
<script>
import AccountingSavePage from '@/components/accounting/AccountingSaveListComponent'
export default {
    name: 'accountingsave',
    components: {
        AccountingSavePage,
    }
}
</script>