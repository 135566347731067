<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">สร้างคำสั่งซื้อใหม่ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-form ref="input" @submit.prevent="saveData">
                    <v-card-title>
                        <b>สร้างคำสั่งซื้อใหม่</b>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;margin-bottom:25px">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-file-document
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลคำสั่งซื้อ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                        <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                    <v-form-group>
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="order_date" label="วันที่สั่งซื้อ" color="#FFBC15"
                                                    prepend-inner-icon="mdi-calendar" hide-details="auto"
                                                    :rules="[v => !!v || 'กรุณาเลือกวันที่สั่งซื้อ']" v-bind="attrs" dense readonly
                                                    outlined rounded required v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="order_date" @input="menu1 = false"
                                                no-title scrollable></v-date-picker>
                                        </v-menu>
                                    </v-form-group>
                                    </v-col>
                                     <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                   <v-form-group>
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="order_deadline" label="กำหนดส่งสินค้า"
                                                    color="#FFBC15" prepend-inner-icon="mdi-calendar" hide-details="auto" :rules="[(v) => !!v || 'กรุณากรอก']"
                                                    v-bind="attrs" dense outlined rounded required readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="order_deadline" @input="menu2 = false"
                                                no-title scrollable></v-date-picker>
                                        </v-menu>
                                   </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-autocomplete :items="customerList" item-text="customer_name" item-value="id"
                                            v-model="customerID" @change="getCustomer(customerID)" :rules="[(v) => !!v || 'กรุณากรอก']"
                                            class="input-radius-search" color="#FFBC15" label="ชื่อลูกค้า"
                                            hide-details="auto" outlined dense></v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="3">
                                        <div class="ml-3 text-name-title">เบอร์โทร :
                                            <span class="text-fullname-title" v-if="customerDetail.customer_tel != null">{{
                                                customerDetail.customer_tel }}</span>
                                            <span class="text-fullname-title" v-else>-</span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="9" lg="9" xl="9" cols="9">
                                        <div class="ml-3 text-name-title">ที่อยู่ :
                                            <span class="text-fullname-title"
                                                v-if="customerDetail.customer_address != null">{{
                                                    customerDetail.customer_address }}</span>
                                            <span class="text-fullname-title" v-else>-</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex justify-space-between align-items-center">
                                <div>
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-package-variant-closed
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">ข้อมูลสินค้า</span>
                                </div>
                                <h3>ราคารวม <span style="color:#88B358;">{{ formatPrice(total) }}</span> บาท</h3>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="7" lg="7" xl="7" cols="12">
                                        <v-autocomplete :items="productList" item-text="pro_name" item-value="id"
                                            class="input-radius-search mt-3" v-model="product" ref="selectProduct"
                                            @change="getUnitProduct(product)" color="#FFBC15" label="ชื่อสินค้า"
                                            :rules="[v => (List.length > 0) || 'กรุณาเลือกชื่อสินค้า']" hide-details="auto" outlined
                                            dense></v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                        <v-select :items="size_list" item-text="pro_size_name" item-value="product_size_id"
                                            class="input-radius-search mt-3" color="#FFBC15" label="ขนาดสินค้า" ref="selectUnit"
                                            v-model="product_size" :rules="[v => (List.length > 0) || 'กรุณาเลือกขนาดสินค้า']"
                                            hide-details="auto" outlined dense></v-select>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12"
                                        style="display:flex;align-items:center;">
                                        <v-btn color="#88B358" elevation="4" rounded large outlined block
                                            @click="addProduct()">
                                            <v-icon left>mdi-arrow-right</v-icon> เพิ่ม
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <h3 style="color:#000;">รายการสินค้า</h3>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">

                                        <v-card elevation="0" style="border-radius:20px;border:1px solid #FFBC15;"
                                            class="mt-3" v-for="(List, index) in List" :key="index">
                                            <!-- {{ List }} -->
                                            <v-card-title class="d-flex justify-space-between align-items-center">
                                                <div class="d-flex" style="font-size:16px;">
                                                    <div class="ml-3" style="margin-right:60px;">{{ List.product_code }}
                                                    </div>
                                                    <div class="mr-3">{{ List.pro_name }}</div>
                                                    <div class="mr-3">( {{ List.pro_size_name }} )</div>
                                                </div>
                                                <v-icon @click="delete_product(index)" left style="color:#F28080" size="32">mdi-delete-forever</v-icon>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-divider class="mb-3" color="#FFBC15"></v-divider>
                                                <v-row>
                                                    <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                        <v-form-group>
                                                            <v-text-field class="input-radius-search" color="#FFBC15"
                                                            label="จำนวน" placeholder="จำนวน" v-model="List.qty"
                                                            @keyup="calculate_input(index), formatInput(index)"
                                                            :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกจำนวน']"
                                                            @keypress="onlyForCurrency($event, List.qty)" hide-details="auto"
                                                            dense outlined required></v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12"
                                                        style="display:flex;align-items:center;">
                                                        <v-form-group>
                                                            <v-text-field class="input-radius-search" color="#FFBC15"
                                                            label="ราคาต่อหน่วย" suffix="บาท"
                                                             @keyup="calculate_input(index), formatInput(index)"
                                                            :rules="[(v) => !!v || 'กรุณากรอกราคาต่อหน่วย']"
                                                            v-model="List.pro_price"
                                                            @keypress="onlyForCurrency($event, List.pro_price)"
                                                            hide-details="auto" dense outlined required>
                                                        </v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12"
                                                        class="d-flex justify-end" style="align-items:center">
                                                        <div>
                                                            <h3 style="color:#000000DE;font-size:23px;">ราคารวม {{
                                                                formatPrice(List.total_price) }} บาท
                                                            </h3>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center">
                                <div>
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-comment-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">หมายเหตุคำสั่งซื้อ</span>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-textarea class="input-radius" label="หมายเหตุคำสั่งซื้อ" color="#FFBC15"
                                            v-model="order_note" outlined rounded rows="3" row-height="25"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <div class="mt-3 text-center">
                        <v-btn class="white--text" color="#88B358" elevation="4" type="submit" rounded large >
                            <v-icon left>mdi-content-save</v-icon> บันทึก
                        </v-btn>
                    </div>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { formatPrice, onlyForCurrency } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import customers from '@/api/sale/customers.js'
import order from '@/api/sale/order.js'
import Swal from "sweetalert2";
export default {
    data() {
        return {
            loading_page: false,
            sub_menu: [
                {
                    text: 'การขาย',
                    disabled: false,
                },
                {
                    text: 'จัดการคำสั่งซื้อ',
                    disabled: false,
                    href: '/sale/order-list',
                },
                {
                    text: 'สร้างคำสั่งซื้อใหม่',
                    disabled: false,
                    href: '#',
                },
            ],
            minDate: null,
            menu1: false,
            menu2: false,
            customerList: [],
            productList: [],
            size_list: [],
            customerDetail: {
                customer_name: null,
                customer_tel: null,
            },
            order_date:new Date().toISOString().substr(0, 10), // Set the initial value to the current date (yyyy-mm-dd format)
            List: [],
            dataSave: [],
            order_note: '',
            total: 0,
            page: 1,
            search: '',
            product_size:null
        }
    },

    methods: {
        formatInput(index) {
            this.loading_page = true;
            if(this.List[index].qty.length > 3){
                this.List[index].qty = this.List[index].qty.replace(/,/g, '');
                this.List[index].qty = this.List[index].qty.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
            if(this.List[index].pro_price.length > 3){
                this.List[index].pro_price = this.List[index].pro_price.replace(/,/g, '');
                this.List[index].pro_price = this.List[index].pro_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }  
        this.loading_page = false;
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        onlyForCurrency: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        async customer() {
            customers.customerListAll().then((response) => {
                if (response.data.successful === true) {
                    this.customerList = response.data.CustomerList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        getCustomer(id) {
            customers.customerDetail(id).then((response) => {
                if (response.data.successful === true) {
                    this.customerDetail = response.data.detail
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        getProductList() {
            order.orderProductList().then((response) => {
                if (response.data.successful === true) {
                    this.productList = response.data.product
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        getUnitProduct(proID) {
            let check = this.productList.find(o => o.id === proID);
            this.size_list = check.size_list
        },
        addProduct() {
            console.log(this.product);
            console.log(this.product_size);

            let getProduct = this.productList.find(o => o.id === this.product)
            let getUnit = getProduct.size_list.find(a => a.product_size_id === this.product_size)
            let check =  this.List.find(o => o.product_id === getProduct.id && o.product_size_id === this.product_size)
            // console.log(getUnit);
            // console.log(getProduct);
            // console.log(check);
            if(check == null ){
                this.List.push(
                    {
                        product_id: getProduct.id,
                        product_code: getProduct.pro_code,
                        pro_name: getProduct.pro_name,
                        pro_price: formatPrice(getUnit.price),
                        pro_size_name: getUnit.pro_size_name,
                        unit_id: getUnit.unit_id,
                        product_size_id: getUnit.product_size_id,
                        total_price: 0,
                        qty: null,
                    }
                )
            }

            this.size_list = []
            this.$refs.selectProduct.reset();
            this.$refs.selectUnit.reset();
        },
        calculate_input(index = null) {
            this.List[index].total_price = parseFloat(this.List[index].qty == '' ? 0 : this.List[index].qty.replace(/,/g, '')) * parseFloat(this.List[index].pro_price == '' ? 0 : this.List[index].pro_price.replace(/,/g, ''));
            this.total = 0;
            for (let j = 0; j < this.List.length; j++) {
                this.total += parseFloat(this.List[j].total_price);
            }
        },
        delete_product(index){
            this.loading_page = true
            this.List.splice(index, 1)
            if(this.List.length > 0){
                for (let j = 0; j < this.List.length; j++) {
                   this.total += parseFloat(this.List[j].total_price);
               }
            }else{
                this.total = 0
            }
             this.loading_page = false
        },
        saveData() {
           if(this.$refs.input.validate()){
                 let pq_list = []
            for (let j = 0; j < this.List.length; j++) {
                pq_list.push({
                    product_code: this.List[j].product_code,
                    product_id: this.List[j].product_id,
                    product_size_id: this.List[j].product_size_id,
                    qty: this.List[j].qty.replace(/,/g, ''),
                    unit_id: this.List[j].unit_id,
                    unit_price: parseFloat(this.List[j].pro_price.replace(/,/g, '')) + '',
                    total_price: this.List[j].total_price,
                })
            }
            const data = {
                order_date: this.order_date,
                order_deadline: this.order_deadline,
                order_note: this.order_note,
                order_total: this.total,
                customer_id: this.customerID,
                customer_name: this.customerDetail.customer_name,
                customer_address: this.customerDetail.customer_address,
                order_detail: pq_list,
            }
            order.orderProductAdd(data).then((response) => {
                if (response.data.successful === true) {
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            this.$router.push("/sale/order-list");
                        }
                    });
                }
            })
           }else{
                 Swal.fire({
                        title: "คำเตือน",
                        icon: "warning",
                        html: "กรุณากรอกข้อมูลให้เรียบร้อย",
                        confirmButtonText: 'ตกลง',
                    })
           }
        }
    },
    components: {
        navigationmenu
    },
    mounted() {
        this.customer()
        this.getProductList()
        // Get the current date
        const currentDate = new Date();

        // Set the minimum date to the current date
        this.minDate = currentDate.toISOString().substr(0, 10);
    }
}
</script>
<style scoped>
.v-application a{
    color: #1976d2 !important;
}
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-transport-box-data {
    display: grid;
    grid-template-columns: 2% 25% 25% 25% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-transport-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-transport-box-items {
    display: grid;
    grid-template-columns: 6% 25% 24% 33% 12%;
    align-items: center;
}

.input-for-center {
    margin-top: 30px;
}
</style>