<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular
      indeterminate
      size="70"
      :width="8"
      color="#FFBC15"
    ></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">รายละเอียดใบสั่งซื้อ</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card class="card-radius" elevation="4">
          <v-card-title>
            <p class="team-text-title-card" v-if="header_detail.cancel_at != null">
              รายละเอียดใบสั่งซื้อเลขที่ {{ header_detail.po_code }} <span class="text-16"> | ยกเลิก : {{ header_detail.cancel_note }}</span> 
            </p>
            <p class="team-text-title-card" v-else>
              รายละเอียดใบสั่งซื้อเลขที่ {{ header_detail.po_code }} 
            </p>
          </v-card-title>
          <v-card-text>
            <v-card
              elevation="4"
              style="border-radius: 20px; position: relative"
            >
              <v-avatar
                color="#34230F"
                size="32"
                style="position: absolute; top: -15px; left: 20px"
              >
                <v-icon dark> mdi-food-variant </v-icon>
              </v-avatar>
              <v-card-title class="d-flex align-items-center">
                <span class="ml-3 text-font-title">รายการสินค้า</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <v-row>
                  <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <div
                      class="table-header-import-data display-pc mt-3"
                      style="color: #b1adad; padding-left: 15px"
                    >
                      <div class="table-header-import-data-text">รายการ</div>
                      <div class="table-header-import-data-text text-center">
                        จำนวน
                      </div>
                      <div class="table-header-import-data-text text-center">
                        หน่วยนับ
                      </div>
                      <div class="table-header-import-data-text text-center">
                        ราคาต่อหน่วย
                      </div>
                      <div class="table-header-import-data-text text-center">
                        ราคารวม
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <div v-for="(type_name, index) in data" :key="index">
                <v-card-title
                  class="padding-text-title align-items-center"
                  style="
                    background: rgba(255, 188, 21, 0.1);
                    border: 1px solid #ffbc15;
                    border-radius: 20px;
                    margin: 0 15px 15px 15px;
                  "
                >
                  <span class="ml-3 text-font-title" style="font-size: 14px">{{
                    index
                  }}</span>
                </v-card-title>
                <v-card-text
                  v-for="(detailTypeName, index_type_name) in type_name"
                  :key="index_type_name"
                >
                  <div
                    class="mb-2"
                    style="font-weight: 600; color: #000000; padding-left: 15px"
                  >
                    {{ detailTypeName.typeName }}
                  </div>
                  <div
                    class="grid-import-items mb-2"
                    style="padding-left: 15px"
                    v-for="(detail, index_list) in detailTypeName.list"
                    :key="index_list"
                  >
                    <div class="text-left pl-3">{{ detail.mat_name }}</div>
                    <div class="text-center">{{ formatPrice(detail.qty) }}</div>
                    <div class="text-center">{{ detail.unit_name }}</div>
                    <div class="text-center">{{ formatPrice(detail.price) }}</div>
                    <div class="text-center">{{ formatPrice(detail.total_price) }}</div>
                  </div>
                </v-card-text>
              </div>
              <v-card-text>
                <div class="d-flex justify-end">
                  <div
                    class="d-flex justify-space-between"
                    style="
                      padding: 20px;
                      background: rgba(136, 179, 88, 0.15);
                      border: 1px solid #88b358;
                      border-radius: 15px;
                    "
                  >
                    <h2 style="color: #000; margin-right: 100px">รวมเงิน</h2>
                    <h2 style="color: #88b358">
                      {{ formatPrice(header_detail.total_price) }}
                    </h2>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { isTelephone, formatPrice } from "@/helper/handler";
import order from "@/api/order/order.js";
export default {
  data() {
    return {
      loading_page: false,
      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "ใบสั่งซื้อ",
          disabled: false,
          href: '/stock/order-list',
        },
        {
          text: "รายละเอียดใบสั่งซื้อ",
          disabled: false,
          href: "#",
        },
      ],
      header_detail: null,
      data: [],
    };
  },

  methods: {
    link(link) {
      this.$router.push(link);
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    async materialDetailEdit() {
      this.loading_page = true;
      this.header_detail = null;
      this.data = [];
      const response = await order.materialDetailEdit(this.$route.params.id);
      if (response.data.successful == true) {
        this.header_detail = response.data.results.detail;
        this.data = response.data.results.list_item;
        this.loading_page = false;
      }
    },
  },
  computed: {
    total_price() {
      const data = Object.entries(this.data);
      let price = 0;
      data.forEach((all) => {
        all[1].forEach((dataList) => {
          dataList.list.forEach((detail) => {
            price += parseFloat(detail.total_price);
          });
        });
      });
      return price;
    },
  },
  mounted() {
    this.materialDetailEdit();
  },
};
</script>
<style scoped>
.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 22px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-import-data {
  display: grid;
  grid-template-columns: 15% 19% 30% 26% 10%;
  color: #686868 !important;
}

.table-header-import-data-text {
  color: #686868;
  font-size: 14px;
}

.grid-import-items {
  display: grid;
  grid-template-columns: 15% 19% 30% 26% 10%;
  align-items: center;
}
</style>