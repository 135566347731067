<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายละเอียดคำสั่งซื้อ {{ data.order_code }} </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>รายละเอียดคำสั่งซื้อ {{ data.order_code }}</b>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-5">
                            <v-card-title class="d-flex align-items-center pb-1 ">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-file
                                    </v-icon>
                                </v-avatar>
                                <v-row>
                                    <v-col cols="6">
                                        <span class="ml-3 mt-3 text-font-title">ข้อมูลคำสั่งซื้อ</span>
                                    </v-col>
                                    <v-col cols="6 text-right">
                                        <span class="ml-3 mt-3 text-14">ผู้สร้างคำสั่งซื้อ : <b> {{ data.user_edit }}
                                            </b></span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="px-6 pb-8">
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="5" lg="5" xl="5" cols="12" class="pb-0">
                                        <div class="ml-3 text-name-title">วันที่สั่งซื้อ :
                                            <b v-if="data.order_date != null">
                                                {{
                                                    `${data.order_date.split('/')[0]}-${data.order_date.split('/')[1]}-${data.order_date.split('/')[2]}`
                                                }}</b>
                                            <b v-else>-</b>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="7" lg="7" xl="7" cols="12" class="pb-0">
                                        <div class="ml-3 text-name-title">กำหนดส่งสินค้า :
                                            <b v-if="data.order_deadline != null">{{
                                                `${data.order_deadline.split('/')[0]}-${data.order_deadline.split('/')[1]}-${data.order_deadline.split('/')[2]}`
                                            }}
                                            </b>
                                            <b v-else>-</b>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="5" lg="5" xl="5" cols="12" class="pb-0">
                                        <div class="ml-3 text-name-title">ชื่อลูกค้า : <b>{{ data.customer_name }}</b></div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="7" lg="7" xl="7" cols="12" class="pb-0">
                                        <div class="ml-3 text-name-title">เบอร์โทร : <b>{{ data.customer_tel }}</b> </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pb-0">
                                        <div class="ml-3 text-name-title">ที่อยู่ : <b>{{ data.customer_address }}</b></div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card elevation="4" style="border-radius:20px;" class="mt-10">
                            <v-card-title class="d-flex align-items-center pb-1 ">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-package-variant-closed
                                    </v-icon>
                                </v-avatar>
                                <v-row>
                                    <v-col cols="6">
                                        <span class="ml-3 mt-3 text-font-title">ข้อมูลสินค้า</span>
                                    </v-col>
                                    <v-col cols="6 text-right">
                                        <span class="ml-3 mt-3 text-16">ราคารวม <span class="text-green text-18"> {{
                                            data.order_total == null ? 0 : formatPrice(data.order_total) }}
                                            </span> บาท</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="px-6 pb-5">

                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <template>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                รหัสผลิตภัณฑ์
                                                            </th>
                                                            <th class="text-left">
                                                                ชื่อสินค้า
                                                            </th>
                                                            <th class="text-left">
                                                                จำนวน
                                                            </th>
                                                            <th class="text-left">
                                                                หน่วยนับ
                                                            </th>
                                                            <th class="text-left">
                                                                ราคาต่อหน่วย
                                                            </th>
                                                            <th class="text-left">
                                                                ราคารวม
                                                            </th>
                                                            <th class="text-left">
                                                                รายละเอียด
                                                            </th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in order_detail" :key="index">
                                                            <td>{{ item.product_code }}</td>
                                                            <td>{{ item.product_name }}</td>
                                                            <td>{{ formatPrice(item.qty) }}</td>
                                                            <td>{{ item.unit_name }}</td>
                                                            <td>{{ formatPrice(item.unit_price) }}</td>
                                                            <td>{{ formatPrice(item.total_price) }}</td>
                                                            <td width="200">
                                                                <div v-for="(stock_item, index_stock) in item.stock_list"
                                                                    :key="index_stock">
                                                                    {{ stock_item.product_lot }} {{ stock_item.qty_cut }} {{
                                                                        item.unit_name }}
                                                                </div>

                                                            </td>
                                                            <td width="120">
                                                                <div v-if="data.order_status < 4" @click="edit_show_lot(item.id)" outlined
                                                                    style="color:#FEA06C;">
                                                                    <v-icon color="#FEA06C">mdi-pencil</v-icon> แก้ไขล็อต
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>
                                    </v-col>
                                    <v-col cols="12 text-center" v-if="status_edit_lot == true">
                                        <v-btn @click="edit_lot" class="mt-2 mr-2  radius-15 text-16 text-white" width="150"
                                            elevation="2" large color="#88B358">
                                            <v-icon>mdi-content-save</v-icon> บันทึก
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12 pl-5">
                                        <div>
                                            หมายเหตุคำสั่งซื้อ :
                                        </div>
                                        <div>
                                            <b> {{ data.order_note }} </b>
                                        </div>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                        </v-card>

                        <v-card elevation="4" style="border-radius:20px;" class="mt-10">
                            <v-card-title class="d-flex align-items-center pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-archive-star
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">ข้อมูลสถานะสินค้าปัจจุบันในคลัง</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                                <div class="ml-2 mt-4">

                                    <div class="scroll-menu mt-5 mb-5">
                                        <section v-for="(detail, indexs) in order_detail" :key="indexs">
                                            <div class="card-sub-table mb-3">
                                                <v-row class="py-2">
                                                    <v-col cols="3 text-center">
                                                        <v-img class="my-1" height="95"
                                                            :lazy-src="detail.pro_pic == null ? img_mock : detail.pro_pic"
                                                            :src="detail.pro_pic == null ? img_mock : detail.pro_pic"></v-img>
                                                    </v-col>
                                                    <v-col cols="9">
                                                        <div class="black--text font-weight-bold">{{ detail.product_name }}
                                                        </div>
                                                        <div class="black--text ">รหัสผลิตภัณฑ์ : {{ detail.product_code }}
                                                        </div>
                                                        <div class="black--text ">จำนวนออเดอร์ : {{
                                                            formatPrice(detail.qty) }} {{ detail.unit_name }}
                                                        </div>
                                                    </v-col>
                                                </v-row>

                                                <v-divider class="ml-2 mr-2 mt-1" dark color="#d9d9d9"></v-divider>

                                                <v-row class="pt-3">
                                                    <v-col cols="12 pb-0">
                                                        <div style="color:#686868">ผลลัพธ์ที่ต้องดำเนินการเพิ่มเติม :</div>
                                                        <div class="black--text mt-2 mb-2"
                                                            v-if="detail.qty_need_to_production_more > 0"> ผลิตเพิ่ม {{
                                                                detail.qty_need_to_production_more }} {{
        detail.unit_name }}</div>
                                                    </v-col>
                                                    <v-col cols="12 pt-1" v-if="detail.qty_need_to_production_more == 0">
                                                        <div style="color:#686868">ผลลัพธ์ออเดอร์ : <span
                                                                class="text-green">พอสำหรับออเดอร์</span></div>
                                                    </v-col>
                                                    <v-col cols="12 pt-1" v-else>
                                                        <div style="color:#686868">ผลลัพธ์ออเดอร์ : <span
                                                                class="text-red">ไม่พอสำหรับออเดอร์</span></div>
                                                    </v-col>
                                                </v-row>

                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <v-row>
                                    <v-col cols="12 text-right" v-if="check_production.length > 0">
                                        <v-btn class="mt-2 mr-2  radius-15 text-16" elevation="2" large outlined
                                            color="#88B358" @click="openLink('/pro/processing_product_good')">
                                            <v-icon>mdi-cog</v-icon> สั่งผลิต
                                        </v-btn>
                                        <!-- <v-btn  class="mt-2 mr-2  radius-15 text-16" elevation="2" large outlined color="#FFBC15" >
                                           <v-icon>mdi-package-variant</v-icon> สั่งบรรจุภัณฑ์
                                        </v-btn> -->
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialo_lot" persistent max-width="700px">
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col>
                                <span cols="6">แก้ไขล็อต</span>
                            </v-col>
                            <v-col cols="6 text-right">
                                <v-icon @click="dialo_lot = false">
                                    mdi-window-close
                                </v-icon>
                            </v-col>
                        </v-row>

                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_detail_edit_log" @submit.prevent="edit_lot_save()">
                                <v-row>

                                    <v-col cols="8">
                                        {{ product_lot.product_name }} {{ product_lot.qty }} {{ product_lot.unit_name }}
                                    </v-col>
                                     <v-col cols="4" class="text-right" v-if="data.order_status < 4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="refresh_data(product_lot.id)" elevation="2" color="error" v-bind="attrs" v-on="on" fab small>
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>จัดล็อตสินค้าใหม่</span>
                                </v-tooltip>
                                
                            </v-col>
                                    <v-col cols="12">
                                        <v-card elevation="4" style="border-radius:20px;" class="">
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                วันที่นำเข้า/ผลิต
                                                            </th>
                                                            <th class="text-left">
                                                                LOT
                                                            </th>
                                                            <th class="text-left">
                                                                คงเหลือ
                                                            </th>
                                                            <th class="text-left">
                                                                จำนวนที่ต้องการเลือก
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in product_lot.lot" :key="index">
                                                            <td>{{
                                                                `${item.import_date.split('/')[0]}-${item.import_date.split('/')[1]}-${item.import_date.split('/')[2]}`
                                                            }}</td>
                                                            <td>{{ item.product_lot }}</td>
                                                            <td>{{ formatPrice(parseFloat(item.pro_amount)) }}</td>
                                                            <td width="200" style="height: 65px;">
                                                                <v-form-group>
                                                                    <v-text-field type="number" class="input-radius"
                                                                        label="จำนวน" placeholder="จำนวน" color="#ffbc15"
                                                                        v-model="item.qty_cut" @input="check_lot(product_lot.qty, item.qty_cut, item.id)"
                                                                        :rules="[(v) => (!!v+'' && parseFloat(v) <= parseFloat(item.pro_amount)) || 'กรุณากรอกจำนวน']"
                                                                        hide-details="auto" dense outlined rounded required>
                                                                    </v-text-field>
                                                                </v-form-group>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12 text-center" v-if="product_lot.lot.length > 0">
                                        <v-btn type="submit" class="mt-2 mr-2  radius-15 text-16 text-white" width="150"
                                            elevation="2" large color="#88B358">
                                            <v-icon>mdi-content-save</v-icon> บันทึก
                                        </v-btn>
                                    </v-col>

                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import { dateFormatNoSeconds } from "@/helper/dateformats";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import img_mock from "@/assets/groceries.png";
import order from '@/api/sale/order.js'
import Swal from "sweetalert2";
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            img_mock: img_mock,
            status_edit_lot: false,
            dialo_lot: false,
            sub_menu: [
                {
                    text: 'การขาย',
                    disabled: false,
                },
                {
                    text: 'จัดการคำสั่งซื้อ',
                    disabled: false,
                    href: '/sale/order-list',
                },
                {
                    text: 'รายละเอียดคำสั่งซื้อ',
                    disabled: false,
                    href: '#',
                },
            ],
            items: [
                {
                    id: 1,
                    name: 'กล่องไปรษณีย์',
                },
                {
                    id: 2,
                    name: 'กล่องของบริษัท',
                },
                {
                    id: 3,
                    name: 'กล่องลูกค้า',
                }
            ],
            page: 1,
            data: [],
            product_list: [],
            order_detail: [],
            stock_list: [],
            check_production: [],
            ProcessProductList: [],
            mock_lot: [],
            product_lot: {
                product_name: '',
                qty: null,
                unit_name: '',
                lot: [],
            },
        }
    },

    methods: {
       async check_lot(max, min, id){
            // this.loading_page = true
            let sum = 0
            let data = await this.product_lot.lot.find(o => o.id === id)
            if(parseFloat(min) < 0){
                data.qty_cut = 0
            }
           await this.product_lot.lot.forEach(item => {
                sum += parseFloat(item.qty_cut)
            })
            if(parseFloat(max) < parseFloat(sum)){
            data.qty_cut = 0
                 Swal.fire({
                        title: "คำเตือน",
                        icon: "warning",
                        html: "จำนวนสินค้าต้องไม่เกิน "+max,
                        confirmButtonText: 'ตกลง'
                    })
            }
            //  this.loading_page = false
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        dateFormatNoSeconds(dateTime) {
            const date = dateFormatNoSeconds(dateTime).split(" ");
            return date[0];
        },
        link(link) {
            this.$router.push(link)
        },
        openLink(links) {
        // Open the link in a new tab
        window.open(links, '_blank');
        },

        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        async orderDetail() {
            let id = parseFloat(this.$route.params.id);
            let type = 0;
           await order.orderDetail(id, type).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.data
                    this.order_detail = response.data.data.order_detail
                    this.stock_list = response.data.data.order_detail.stock_list
                    const filteredArray = this.order_detail.filter(obj => obj.qty_need_to_production_more > 0)
                    this.check_production = filteredArray
                }
                if (response.data.successful === false) {
                    setTimeout(() => this.alert_error = false, 5000) //need adjust
                }
            })
        },
       async edit_show_lot(id) {
            await order.orderProductLot(id).then((response) => {
                if (response.data.successful === true) {
                    this.product_lot = response.data.data
                    this.dialo_lot = true
                }
                if (response.data.successful === false) {
                    setTimeout(() => this.alert_error = false, 5000) //need adjust
                }
            })
        },
        edit_lot_save() {
            if(this.$refs.form_detail_edit_log.validate()){
                let lot_list = []
                var sum = 0;
                    for (let j = 0; j < this.product_lot.lot.length; j++) {
                        sum += parseFloat(this.product_lot.lot[j].qty_cut)
                    }
                    if(parseFloat(this.product_lot.qty) < parseFloat(sum)){
                        Swal.fire({
                        title: "คำเตือน",
                        icon: "warning",
                        html: "กรุณากรอกข้อมูลให้เรียบร้อย",
                        confirmButtonText: 'ตกลง',
                    })
                    }else{
                        for (let j = 0; j < this.product_lot.lot.length; j++) {
                    lot_list.push({
                    id: this.product_lot.lot[j].id,
                    qty_cut: this.product_lot.lot[j].qty_cut,
                })
            }
            const data = {
                lot: lot_list,
            }
            order.orderDetailLotEdit(data).then((response) => {
                if (response.data.successful === true) {
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            window.location.reload();
                        }
                    });
                }
            })
                    }
            }
        },
        async refresh_data(id) {
           await order.orderRefresh(id).then(async (response) => {
                if (response.data.successful === true) {
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้จัดล็อตสินค้าใหม่เรียบร้อยแล้ว",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose:async () => {
                           await this.orderDetail();
                           await this.edit_show_lot(id);
                            clearInterval(timerInterval);
                        },
                    }).then(async (result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                           await this.orderDetail();
                           await this.edit_show_lot(id);
                        }
                    });
                }
            })
        },
    },
    mounted() {
        this.orderDetail();
    },
    watch: {
        page() {
            this.orderList();
        }
    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.text-14 {
    font-size: 14px;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.input-bottom-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.text-aim {
    color: #FFB98A;
}

.text-green {
    color: #88B358;
}

.text-yellow {
    color: #DBB84D;
}

.text-red {
    color: #F28080
}

.text-blud {
    color: #6C7FD8
}

.text-white {
    color: #fff
}


.topic_processing_header {
    font-size: 24px;
    color: #000000;
}

.topic_processing_content {
    color: #7E7E7E;
    padding-left: 10px;
}

.table-header-processing {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: baseline;
}

.icon-image {
    width: 120px;
    height: 120px;
    text-align: center;
}

.icon-image-logo {
    width: 24px;
    height: 24px;
    vertical-align: center;
}

.tag-title-step {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.divider-table {
    border: 3px solid #ffbc15;
    border-radius: 3px;
}

.w-border-header-first {
    background: #ffbc15;
    border-top: 15px solid #ffbc15;
    border-radius: 36px;
    width: auto;
}

.inline-box-specific {
    background: #fff8e8;
    border-top: 1px solid #ffbc15;
    border-bottom: 1px solid #ffbc15;
}

.w-border-header-sub {
    background: #34230F;
    border: 7px solid #34230F;
    border-radius: 36px;
    width: 60%;
}

.card-main-table {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding: 15px;
    width: auto;
    height: 99%;
}

.card-sub-table {
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    padding: 15px;
    max-width: 400x;
    width: 400px;
    transition: 0.5s;
    white-space: normal;
}

.card-sub-table:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    white-space: normal;
}

.scroll-menu {
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu section {
    display: inline-block;
    margin-right: 3%;
}

/* .scroll-menu section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  margin-right: 3%;
  background: #FFFFFF;
  z-index: 1;
} */
.w-border-header-sub-last {
    background: #34230F;
    border: 7px solid #34230F;
    border-radius: 36px;
    width: auto;
}

.card-sub-table-last {
    border: 1px solid #34230F;
    border-radius: 15px;
    padding: 15px;
    max-width: 130%;
    width: 130%;
    height: 99%;
    transition: 0.5s;
}

.card-sub-table-last:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-last {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu-last .last {
    display: inline-block;
    margin-right: 10%;
}

.scroll-menu-last section:first-child {
    display: inline-block;
    position: sticky;
    left: 0;
    margin-right: 3%;
    background: #FFFFFF;
    z-index: 1;
}

.summarize {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
}

.b-radius-20 {
    border-radius: 20px;
}

.table-header-lotoutstock-data {
    display: grid;
    grid-template-columns: 3% 23% 23% 8% 8% 8% 8% 8% 10%;
    padding: 10px;
}

.table-header-lotoutstock-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-lotoutstock-items {
    display: grid;
    grid-template-columns: 3% 23% 23% 8% 8% 8% 8% 8% 10%;
    align-items: center;
    padding: 15px;
}

.flex-space {
    display: flex;
    justify-content: space-between;
}
</style>