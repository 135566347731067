<template>
  <div>
    <ProductionProductGoodConfirmEdit/>
  </div>
</template>

<script>
import ProductionProductGoodConfirmEdit from "@/components/production_process/processing_product_good/ProductionProductGoodConfirmEdit";
export default {
  name: "ProductionProductGoodConfirmEdit_Page",
  components: {ProductionProductGoodConfirmEdit}
}
</script>

<style scoped>

</style>