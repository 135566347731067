<template>
    <div>
        <QualityInspectionCriteriaEditPage />
    </div>
</template>
  
<script>
import QualityInspectionCriteriaEditPage from '@/components/stock/setting_stock/QualityInspectionCriteriaEditComponent'
export default {
    name: 'QualityInspectionCriteriaEdit',
    components: {
        QualityInspectionCriteriaEditPage,
    }
}
</script>