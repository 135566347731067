<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div v-else>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ตั้งค่าข้อมูลบริษัท </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>แก้ไขข้อมูลบริษัท</b>
                    </v-card-title>
                    <v-form ref="form_add" @submit.prevent="submitBtn">
                        <v-card-text>
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="36">
                                        <v-icon dark>
                                            mdi-domain
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 title-card-text">ข้อมูลบริษ้ท</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <div class="mt-2" style="color:#000;font-size:18px;">
                                                รูปภาพโลโก้ของบริษัท</div>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="image_preview text-center">
                                                        <v-avatar class="mt-2" size="210" tile>
                                                            <img v-if="imagePreviewURL" :src="imagePreviewURL">
                                                            <div class="upload_image">
                                                                <!-- <v-btn v-if="!profile_picture" class="white--text" color="#FD8F16" depressed fab :loading="isSelectingImage" @click="handleFileImport"><v-icon>mdi-pencil</v-icon></v-btn> -->
                                                                <v-btn v-if="profile_picture" class="white--text"
                                                                    color="error" depressed fab
                                                                    @click="onFileRemove"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                                                <v-file-input class="d-none" id="uploader" accept="image/*"
                                                                    v-model="profile_picture"
                                                                    @change="onFileChange"></v-file-input>
                                                            </div>
                                                        </v-avatar>
                                                        <v-file-input class="" id="uploader" accept="image/*"
                                                            color="#FFBC15" placeholder="เลือกรูปภาพโลโก้ของบริษัท"
                                                            v-model="profile_picture" @change="onFileChange">
                                                        </v-file-input>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                            <v-row>
                                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                    <v-form-group>
                                                        <v-text-field type="text" class="input-radius" label="ชื่อบริษัท"
                                                            placeholder="ชื่อบริษัท" color="#FFBC15" v-model="company.name"
                                                            :rules="[v => !!v || 'กรุณากรอกชื่อบริษัท']" hide-details="auto"
                                                            dense outlined rounded required></v-text-field>
                                                    </v-form-group>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                    <v-form-group>
                                                        <v-text-field class="input-radius" color="#FFBC15"
                                                            label="เลขที่ประจำตัวผู้เสียภาษี"
                                                            placeholder="เลขที่ประจำตัวผู้เสียภาษี" v-model="company.code"
                                                            @keypress="isOnlyNumber($event)" hide-details="auto" dense
                                                            outlined rounded required></v-text-field>
                                                    </v-form-group>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                    <v-form-group>
                                                        <v-text-field type="email" class="input-radius" label="อีเมล"
                                                            placeholder="อีเมล" color="#FFBC15" v-model="company.email"
                                                            :rules="[v => !!v || 'กรุณากรอก E-mail', rules.email]"
                                                            hide-details="auto" dense outlined rounded
                                                            required></v-text-field>
                                                    </v-form-group>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                    <v-form-group>
                                                        <v-text-field class="input-radius" color="#FFBC15" label="เบอร์โทร"
                                                            placeholder="เบอร์โทร" v-model="company.tel"
                                                            :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']" minlength="10"
                                                            maxlength="10" @keypress="isOnlyNumber($event)"
                                                            hide-details="auto" dense outlined rounded
                                                            required></v-text-field>
                                                    </v-form-group>
                                                </v-col>
                                                <v-col cols="12">
                                                    <div class="mt-2" style="color:#000;font-size:18px;">ที่อยู่บริษัท</div>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                    <v-form-group>
                                                        <v-text-field class="input-radius" color="#FFBC15" label="ที่อยู่"
                                                            v-model="company.address" placeholde dr="ที่อยู่"
                                                            :rules="[v => !!v || 'กรุณากรอกที่อยู่']" hide-details="auto"
                                                            dense outlined rounded required></v-text-field>
                                                    </v-form-group>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                                                    <v-autocomplete @change=select_province(province) :items="province_list"
                                                        item-text="name_th" item-value="id" v-model="province"
                                                        color="#FFBC15" label="จังหวัด"
                                                        :rules="[v => !!v || 'กรุณาเลือกจังหวัด']" hide-details="auto" dense
                                                        outlined rounded required></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                                                    <v-autocomplete @change=select_amphure(amphures) :items="amphures_list"
                                                        color="#FFBC15" item-text="name_th" item-value="id"
                                                        v-model="amphures" label="อำเภอ"
                                                        :rules="[v => !!v || 'กรุณาเลือกอำเภอ']" hide-details="auto" dense
                                                        outlined rounded required></v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                                                    <v-autocomplete @change=select_district(districts)
                                                        :items="districts_list" item-text="name_th" item-value="id"
                                                        v-model="districts" label="ตำบล"
                                                        :rules="[v => !!v || 'กรุณาเลือกตำบล']" hide-details="auto" dense
                                                        outlined rounded required></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                                                    <v-form-group>
                                                        <v-text-field class="input-radius" color="#FFBC15"
                                                            label="รหัสไปรษณีย์" placeholder="รหัสไปรษณีย์"
                                                            hide-details="auto" v-model="zipcode" dense outlined rounded
                                                            required readonly></v-text-field>
                                                    </v-form-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row style="padding: 15px 70px 0 70px !important;">
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <v-form-group>
                                                <v-text-field type="text" class="input-radius" label="ชื่อกล่องขนส่ง"
                                                    color="#686868" :rules="[v => !!v || 'กรุณากรอกชื่อกล่องขนส่ง']"
                                                     outlined rounded required></v-text-field>
                                            </v-form-group>
                                            <v-form-group>
                                                <v-autocomplete color="#686868" :items="items" item-text="name"
                                                    item-value="id" :rules="[v => !!v || 'กรุณาเลือกประเภทกล่อง']"
                                                    label="ประเภทกล่อง" outlined rounded required></v-autocomplete>
                                            </v-form-group>
                                        </v-col>
                                    </v-row> -->
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import before_image from '@/assets/Ellipse 9.png'
import company from '@/api/company/company.js'
import { onlyForNumber } from "@/helper/handler";
import Swal from 'sweetalert2'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            profile_picture: null,
            imagePreviewURL: before_image,
            province_list: [],
            province: null,
            amphures_list: [],
            amphures: null,
            districts_list: [],
            districts: null,
            zipcode: null,
            company: {
                id: null,
                name: null,
                code: null,
                tel: null,
                email: null,
                address: null,
            },
            rules: {
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'E-mail ที่กรอกไม่ถูกต้อง'
                },
            },
            sub_menu: [
                {
                    text: 'ตั้งค่าข้อมูลบริษัท',
                    disabled: false,
                    href: '#',
                },
            ],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        async handleFileImport() {
            this.isSelectingImage = true
            window.addEventListener('focus', () => {
                this.isSelectingImage = false
            }, { once: true })
            document.getElementById('uploader').click()
        },
        async onFileChange(payload) {
            const file = payload
            let extall = "png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;

            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {

                this.imagePreviewURL = before_image
                this.profile_picture = null
                this.alert_message_error = 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG) เท่านั้น'
                this.alert_error = true
                setTimeout(() => this.alert_error = false, 5000)
            } else {
                if (file) {

                    this.imagePreviewURL = URL.createObjectURL(file)
                    console.log(this.imagePreviewURL);
                    URL.revokeObjectURL(file)
                } else {
                    this.imagePreviewURL = before_image
                }
            }
        },
        async onFileRemove() {
            this.profile_picture = null
            this.imagePreviewURL = before_image
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
        async provinceList() {
            company.provinceList().then((response) => {
                if (response.data.successful === true) {
                    this.province_list = response.data.list
                }
                if (response.data.successful === false) {
                    setTimeout(() => this.alert_error = false, 5000) //need adjust
                }
            })
        },
        async select_province(id) {
            await company.amphuresList(id).then((response) => {
                if (response.data.successful === true) {
                    this.amphures_list = response.data.list
                }
            })
        },
        async select_amphure(id) {
            await company.districtList(id).then((response) => {
                if (response.data.successful === true) {
                    this.districts_list = response.data.list
                }
            })
        },
        async select_amphure_onInit(id, resID) {
            await company.districtList(id).then((response) => {
                if (response.data.successful === true) {
                    this.districts_list = response.data.list
                    const result = this.districts_list.find(({ id }) => id === resID);
                    this.zipcode = result.zip_code
                }
            })
        },
        async select_district(district) {

            const result = this.districts_list.find(({ id }) => id === district);

            this.zipcode = result.zip_code

        },
        async onInit() {
            this.loading_page = true;
            await company.dataList().then((response) => {
                if (response.data.successful === true) {

                    //Form
                    this.company.id = response.data.companyList.id
                    this.company.name = response.data.companyList.company_name
                    this.company.code = response.data.companyList.company_code
                    this.company.email = response.data.companyList.company_email
                    this.company.tel = response.data.companyList.company_tel
                    this.company.address = response.data.companyList.company_address

                    this.profile_picture = {
                        name: response.data.companyList.company_logo
                    }
                    this.imagePreviewURL = response.data.companyList.company_logo


                    //Dropdown
                    this.province = response.data.companyList.company_province
                    this.select_province(response.data.companyList.company_province)

                    this.amphures = response.data.companyList.company_district
                    this.districts = response.data.companyList.company_sub_district
                    this.select_amphure_onInit(this.amphures, this.districts)
                    this.loading_page = false;
                }


            })
        },
        async submitBtn() {
            let bodyFormData = new FormData()
            if (this.$refs.form_add.validate()) {
                bodyFormData.append("company_logo", this.profile_picture)
                bodyFormData.append("company_code", this.company.code)
                bodyFormData.append("company_name", this.company.name)
                bodyFormData.append("company_tel", this.company.tel)
                bodyFormData.append("company_email", this.company.email)
                bodyFormData.append("company_address", this.company.address)
                bodyFormData.append("company_sub_district", this.districts)
                bodyFormData.append("company_district", this.amphures)
                bodyFormData.append("company_province", this.province)
           
                await company.editData(bodyFormData, this.company.id).then((response) => {
                    if (response.data.successful === true) {
                        let timerInterval
                        Swal.fire({
                            title: 'ทำรายการเสร็จสิ้น',
                            icon: 'success',
                            html: 'ระบบได้บันทึกข้อมูลเรียบร้อยแล้ว.',
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft()
                                }, 100)

                            },
                            willClose: () => {
                                setTimeout(function () {
                                    window.location.href = 'company-setting';
                                }, 100);
                                clearInterval(timerInterval)
                            }
                        })
                    }
                })
            }
        }
    },
    components: {
    },
    mounted() {
        this.onInit()
        this.provinceList()
    }
}
</script>
<style scoped>
.image_preview>.v-avatar {
    overflow: unset;
}

.upload_image {
    position: absolute;
    top: 45px;
    right: 0;
    left: 0px;
}

.image_title {
    width: 50px;
}

.image_title>img {
    max-width: 100%;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-transport-box-data {
    display: grid;
    grid-template-columns: 2% 25% 25% 25% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-transport-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-transport-box-items {
    display: grid;
    grid-template-columns: 6% 25% 24% 33% 12%;
    align-items: center;
}
</style>