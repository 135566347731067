<template>
 <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
    <div v-else>
        <div class="d-flex">
            <div class="topic_processing_header border_right">สร้างรอบการบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2">การบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
            <div class="topic_processing_content my-2">สร้างรอบการบรรจุภัณฑ์</div>
        </div>
        <div class="mt-3">
            <v-card elevation="2" class="card-radius">
              <v-form ref="form_add_or_edit" @submit.prevent="saveData()">
                  <v-card-title>
                    <b>ขั้นตอนที่ 4 : สินค้า FG คงเหลือและบรรจุภัณฑ์ที่เสียหาย</b>
                </v-card-title>
                <v-card-text>
                    <v-card elevation="4" class="py-2" style="border-radius:20px">
                        <v-card-title>
                            <v-avatar color="#34230F" size="36">
                                <v-icon color="#ffff">mdi-notebook-edit-outline</v-icon>
                            </v-avatar>
                            <span class="ml-3 title-card-text">การบันทึกผลการผลิต</span>
                        </v-card-title>

                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="black--text font-weight-bold ma-2">จำนวนสินค้า FG ที่คงเหลือจากการบรรจุภัณฑ์</div>
                            <v-row class="ma-4">
                                <v-col lg="3" xl="3">
                                    <v-text-field class="input-radius" color="#FFBC15"
                                        label="จำนวนสินค้า FG ที่คงเหลือจากการบรรจุภัณฑ์" suffix="กรัม" :rules="[v => !!v || 'กรุณาป้อนข้อมูล']" @keypress="isOnlyNumber($event, product_qty_remain)" dense outlined v-model="product_qty_remain" type="number" min="0"
                                        rounded required></v-text-field>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-text>
                    <v-card elevation="4" class="py-2" style="border-radius:20px">
                        <v-card-title>
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>mdi-timer-alert</v-icon>
                            </v-avatar>
                            <span class="ml-3 title-card-text">วัตถุดิบที่เสียหายหรือทิ้ง</span>
                        </v-card-title>
                        <v-card-text>
                  <div class="ml-15 mt-7">
                    <div class="tag-title-step">
                      <b class="black--text">วัตถุดิบที่เสียหายหรือทิ้งไปจากการผลิตในรอบนี้</b>
                    </div>
                    <div class="divider-table my-2"></div>
                    <div class="mt-4">
                      <v-row>
                        <v-col xl="6" lg="6">
                          <div class="mb-2">
                            <v-select class="input-radius" :items="step_2" item-text="mat_name" item-value="material_id"
                              @change="getDetail" color="#686868" label="วัตถุดิบที่เสียหายหรือทิ้ง" ref="lot_list"
                              placeholder="วัตถุดิบที่เสียหายหรือทิ้ง" hide-details="auto" outlined dense rounded
                              required></v-select>
                          </div>
                        </v-col>
                      </v-row>
                      <div class="table-header-processing mt-4" style="color: #b1adad">
                        <div class="table-sub-header-processing">
                          ชื่อวัตถุดิบ
                        </div>
                        <div class="table-sub-header-processing">
                          เลข lot
                        </div>
                        <div class="table-sub-header-processing">
                          จำนวน
                        </div>
                        <div class="table-sub-header-processing text-center ml-n1">
                          ลบ
                        </div>
                      </div>
                      <v-card elevation="0" class="grid-processing-items ma-4" v-for="(detail, index) in dataList"
                        :key="index" :class="detail.is_delete != 0 ? 'd-none' : ''">
                        <div v-if="detail.is_delete == 0">{{ detail.mat_name }}</div>
                        <div class="pa-2 pt-0 mt-n5" v-if="detail.is_delete == 0">
                          <v-select class="input-radius" :items="detail.lot_list" item-text="material_lot"
                            item-value="material_lot_id" v-if="detail.id != null" v-model="detail.material_lot_id"
                            color="#686868" label="เลข lot" placeholder="เลข lot" hide-details="auto" outlined dense rounded
                            required disabled></v-select>
                          <v-select class="input-radius" :items="detail.lot_list" item-text="material_lot"
                            item-value="material_lot_id" v-else v-model="detail.material_lot_id" color="#686868"
                            label="เลข lot" placeholder="เลข lot" hide-details="auto" outlined dense rounded
                            required></v-select>
                        </div>
                        <div class="pa-2 pt-0 mt-n5" v-if="detail.is_delete == 0">
                          <v-text-field class="input-radius" color="#FFBC15" label="จำนวน" v-model="detail.qty" dense
                            outlined rounded required></v-text-field>
                        </div>
                        <div style="text-align: center" v-if="detail.is_delete == 0">
                          <v-btn class="ml-6 radius-15" color="#f2abab" outlined @click="remove(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </v-card-text>
                    </v-card>
                </v-card-text>
                <div class="mt-3 text-center">
                    <v-btn class="white--text" color="#88B358" elevation="4" rounded large type="submit">
                        <v-icon left>mdi-content-save</v-icon> บันทึก
                    </v-btn>
                </div>
              </v-form>
            </v-card>
        </div>
    </div>
</template>

<script>
import { formatPrice, onlyForCurrency } from "@/helper/handler";
import process_package from '@/api/process_package/package.js'
import Swal from "sweetalert2";
export default {
    name: "ProductionResultCreate",
    data() {
        return {
          loading_page: true,
            menu2: false,
            menu3: false,
          //ส่วนที่ 2
      step_2: [],
      dataList: [],
      material_drop_lsit: [],
      product_qty_remain: null,
        }
    },
    methods: {
      isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        formatPrice(number) {
            return formatPrice(number)
        },
        linkToSteps() {
            this.$router.push('/prm/production_process_steps')
        },
        getStepOne() {
      this.loading_page = true;
      let id = this.$route.params.id;
      process_package.getStepFour1(id).then((response) => {
        if(response.status == 502){
        window.location.reload()
      }
        if (response.data.successful === true) {
             this.material_drop_lsit = response.data.material_drop_lsit;
             this.product_qty_remain = response.data.product_qty_remain;
              this.getDetail();
        }
        })
        },
        getStepTwo() {
      this.loading_page = true;
      let id = parseFloat(this.$route.params.id);
      process_package.getStepFour2(id).then((response) => {
        if(response.status == 502){
        window.location.reload()
      }
        if (response.data.successful === true) {
          this.step_2 = response.data.data;
          this.loading_page = false;
        }
      }).catch((error) => {
             if(error.response.status === 502){
                window.location.reload()
              }
          })
    },
         getDetail(material_id = null) {
      if (material_id == null) {
        if (this.material_drop_lsit.length != 0) {
          for (let i = 0; i < this.material_drop_lsit.length; i++) {
            this.dataList.push({
              id: this.material_drop_lsit[i].id,
              material_id: this.material_drop_lsit[i].material_id,
              mat_name: this.material_drop_lsit[i].mat_name,
              lot_list: this.material_drop_lsit[i].lot,
              material_lot_id: this.material_drop_lsit[i].material_lot_id,
              qty: this.material_drop_lsit[i].drop_qty,
              is_delete: 0
            })
          }
        }
      } else {
        let id = this.$route.params.id;
        process_package.getDetail(id, material_id).then((response) => {
          if (response.data.successful === true) {
            let data = response.data.data;
            let check = this.step_2.find(o => o.material_id === material_id);
            if (check != null) {
              this.dataList.push({
                id: null,
                material_id: check.material_id,
                mat_name: check.mat_name,
                lot_list: data,
                material_lot_id: check.material_lot_id,
                qty: 0,
                is_delete: 0
              })
            }

          }
        })
      }
      this.$refs.lot_list.reset();
    },
         getDetailClick(material_id = null, index) {
      this.loading_page = true;
      let id = this.$route.params.id;
      process_package.getDetail(id, material_id).then((response) => {
        if (response.data.successful === true) {
          let data = response.data.data;
          let check = this.dataList.find(o => o.material_id === material_id);
          if (check != null) {
            this.dataList[index] = {
              id: check.id,
              material_id: check.material_id,
              mat_name: check.mat_name,
              lot_list: data,
              material_lot_id: check.material_lot_id,
              qty: check.qty
            }
          }
        }
      })
      this.loading_page = false;
    },
    remove(index) {
      this.loading_page = true;
      this.dataList[index] = {
        id: this.dataList[index].id,
        material_id: this.dataList[index].material_id,
        mat_name: this.dataList[index].mat_name,
        lot_list: this.dataList[index].lot_list,
        material_lot_id: this.dataList[index].material_lot_id,
        qty: this.dataList[index].qty,
        is_delete: 1,
      };
      this.loading_page = false;
    },
     saveData() {
      if (this.$refs.form_add_or_edit.validate()) {
        this.loading_page = true;
        if (this.dataList.length != 0) {
          for (let i = 0; i < this.dataList.length; i++) {
            if (this.material_drop_lsit.length == 0) {
              if(this.dataList[i].is_delete == 0){
                this.material_drop_lsit.push({
                    packing_id: parseFloat(this.$route.params.id),
                    material_id: this.dataList[i].material_id,
                    material_lot_id: this.dataList[i].material_lot_id,
                    drop_qty: this.dataList[i].qty,
                  });
              }
            } else {
              let del_array = [];
              for (let i = 0; i < this.dataList.length; i++) {
                let check = this.material_drop_lsit.find(o => o.id === this.dataList[i].id);
                if (check != null && this.dataList[i].id != null && this.dataList[i].is_delete == 1) {
                  del_array.push({
                    id: this.dataList[i].id,
                    packing_id: parseFloat(this.$route.params.id),
                    material_id: this.dataList[i].material_id,
                    material_lot_id: this.dataList[i].material_lot_id,
                    drop_qty: this.dataList[i].qty,
                    is_delete: 1
                  });
                } else if (check == null && this.dataList[i].id == null && this.dataList[i].is_delete == 0) {
                  del_array.push({
                    packing_id: parseFloat(this.$route.params.id),
                    material_id: this.dataList[i].material_id,
                    material_lot_id: this.dataList[i].material_lot_id,
                    drop_qty: this.dataList[i].qty,
                  });
                } else if (check != null && this.dataList[i].id != null && this.dataList[i].is_delete == 0) {
                  del_array.push({
                    id: this.dataList[i].id,
                    packing_id: parseFloat(this.$route.params.id),
                    material_id: this.dataList[i].material_id,
                    material_lot_id: this.dataList[i].material_lot_id,
                    drop_qty: this.dataList[i].qty,
                  });
                }

              }
              this.material_drop_lsit.splice(0);
              this.material_drop_lsit = del_array;
            }
          }
        }
        let data = {
          packing_id: parseFloat(this.$route.params.id),
          product_qty_remain: this.product_qty_remain,
          material_drop_lsit: this.material_drop_lsit
        }
        this.test = data;
        process_package.saveStepFour(data).then((response) => {
          if (response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                window.location.href = "/pac/processing-packaging-step/" + this.$route.params.id;
                // window.location.reload();
              }
            });
          }
        })
      }
    }
    },
    mounted(){
        this.getStepOne()
        this.getStepTwo()
    }
}
</script>

<style scoped>
.border_right {
    border-right: 2px solid #000000;
    padding-right: 10px;
}

.topic_processing_header {
    font-size: 24px;
    color: #000000;
}

.topic_processing_content {
    color: #7E7E7E;
    padding-left: 10px;
}

.icon-image-logo {
    width: 24px;
    height: 24px;
    vertical-align: center;
}

.tag-title-step {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.tag-title-step-header {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
    background: #ffbd1519;
}

.divider-table {
    border: 3px solid #ffbc15;
    border-radius: 3px;
}

.inline-box-specific {
    background: #fff8e8;
}

.inline-box-specific-sub {
    background: #d6d3cf;
}

/* Scroll Table */
.scroll-menu-last {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu-last .last {
    display: inline-block;
    vertical-align: top;
    margin-right: 1.75%;
}

.scroll-menu-last section:first-child {
    display: inline-block;
    position: sticky;
    left: 0;
    bottom: auto;
    margin-right: 1.5%;
    padding-right: 25px;
    background: #FFFFFF;
    z-index: 1;
}

.card-main-table {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding-bottom: 15px;
    width: 110%;
    height: 99%;
}

.w-border-header-first {
    background: #ffbc15;
    border-top: 15px solid #ffbc15;
    border-radius: 36px;
    width: 110%;
}

.w-border-header-sub-last {
    background: #34230F;
    border: 7px solid #34230F;
    border-radius: 36px;
    width: auto;
}

.card-sub-table-last {
    border: 1px solid #34230F;
    border-radius: 15px;
    max-width: 100%;
    width: 200px;
    height: 99%;
    transition: 0.5s;
}

.card-sub-table-last:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.footer-total {
    position: absolute;
    bottom: 1%;
    border-top: 2px solid #ffbc15;
    background-color: #fff2d0;
    padding: 17px 22px 0 22px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.footer-gams {
    background-color: #000000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.summarize {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
    inline-size: fit-content;
}

.summarize-result {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    inline-size: fit-content;
}

.table-header-processing {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: baseline;
}

.custom-label-color>>>.v-label--active {
    background-color: #FFFFFF;
    padding-left: 7px;
    padding-right: 7px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
</style>