<template>
  <div>
    <ProcessingProductGood/>
  </div>
</template>

<script>
import ProcessingProductGood from "@/components/production_process/processing_product_good/ProcessingProductGood";
export default {
  name: "ProcessingProductGood_Page",
  components: {
    ProcessingProductGood
  }
}
</script>

<style scoped>

</style>