import { render, staticRenderFns } from "./Production_confirm_details.vue?vue&type=template&id=113eaa52&scoped=true&"
import script from "./Production_confirm_details.vue?vue&type=script&lang=js&"
export * from "./Production_confirm_details.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113eaa52",
  null
  
)

export default component.exports