<template>
    <div>
        <Warehouse_page />
    </div>
</template>
  
<script>
import Warehouse_page from '@/components/stock/warehouse_stock/Warehouse'
export default {
    name: 'Warehouse',
    components: {
        Warehouse_page,
    }
}
</script>