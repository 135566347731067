import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    
    getStepOne(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/production/get/detail/step/three/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepTwo(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/production/get/maerial/to/drop/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getDetail(id , material_id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/production/get/lot/to/drop/${id}?material_id=${material_id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      addStep3(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/production/save/result/production`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },

    
   
}
     
     