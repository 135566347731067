<template>
    <div>
        <InspectionCriteriaProductionDetailPage />
    </div>
</template>
  
<script>
import InspectionCriteriaProductionDetailPage from '@/components/stock/setting_stock/InspectionCriteriaProductionDetailComponent'
export default {
    name: 'InspectionCriteriaProductionDetail',
    components: {
        InspectionCriteriaProductionDetailPage,
    }
}
</script>