import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
  getReport(id, fq_type) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/ReportQuality/pdreportqualityreport/get/product/or/material/select/box/${id}?pq_type=${fq_type}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getDetail(id, item_id, start_date, end_date, type_mat_or_pro) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/ReportQuality/listpq/detail/${id}?item_id=${item_id}&start_date=${start_date}&end_date=${end_date}&type_mat_or_pro=${type_mat_or_pro}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
}

