<template>
    <div>
        <WarehouseLotInStockRawMaterial_page />
    </div>
</template>
  
<script>
import WarehouseLotInStockRawMaterial_page from '@/components/stock/warehouse_stock/WarehouseLotInStockRawMaterial'
export default {
    name: 'WarehouseLotInStockRawMaterial',
    components: {
        WarehouseLotInStockRawMaterial_page,
    }
}
</script>