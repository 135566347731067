const mutations = {
  logInSuccess(state, data) {
    state.access_token = data.access_token;
  },
  logOutSuccess(state) {
    state.access_token = null;
  },
  addStockOrder(state, data) {
    state.stockOrderList = data;
  },
  clearStockOrder(state) {
    state.stockOrderList = {
      po_list: [],
      total_price: 0
    };
  },
  setProfileDetail(state, profileDetail) {
    state.profileDetail = profileDetail;
  },
};
export default mutations;
