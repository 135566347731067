import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    add: (data) => {
        return HTTP.post('/materialtype/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    dataList: (page, size) => {
        return HTTP.get(`/materialtype/list?page=${page}&size=${size}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    detail: (id) => {
        return HTTP.get('/materialtype/detail/'+id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    edit: (id, data) => {
        return HTTP.post('/materialtype/edit/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    delete: (id) => {
        return HTTP.post('/materialtype/delete/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    list: () => {
        return HTTP.get('/materialtype/list/all',{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    list_type: (material_type) => {
        return HTTP.get('materialtype/list/by/mat/status?mat_status='+material_type,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },

}