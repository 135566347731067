<template>
  <div>
    <ProcessingProductGoodCreate/>
  </div>
</template>

<script>
import ProcessingProductGoodCreate from "@/components/production_process/processing_product_good/ProcessingProductGoodCreate";
export default {
  name: "ProcessingProductGoodCreate_Page",
  components: {ProcessingProductGoodCreate}
}
</script>

<style scoped>

</style>