<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายงานการตรวจสอบคุณภาพวัตถุดิบ</h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12">
                                <b>รายงานการตรวจสอบคุณภาพวัตถุดิบ</b>
                            </v-col>

                            <v-col cols="12 py-0">
                                <div class="bold text-18"> <v-icon>mdi-package-variant-closed</v-icon> สินค้า :
                                    {{ product_data.pro_name }}</div>
                            </v-col>

                            <v-col cols="12 pb-0">
                                <v-btn
                                    @click="link('/stock/warehouse-stock/warehouse-unpackaged-history-raw-material-audit-inspection/' + $route.params.id)"
                                    class=" tab-button active " type="button" color="#FFBC15" elevation="2" large
                                    outlined>รายงานการตรวจสอบคุณภาพ</v-btn>
                                <v-btn
                                    @click="link('/stock/warehouse-stock/warehouse-unpackaged-history-raw-material-audit-procress/' + $route.params.id)"
                                    class="ma-2 tab-button-disable" type="button" large outlined
                                    color="#909090">รายงานการตรวจสอบคุณภาพการแปรรูป</v-btn>
                            </v-col>

                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-1" v-model="search"
                                    prepend-inner-icon="mdi-magnify" placeholder="ค้นหาที่นี่..." hide-details="auto"
                                    single-line outlined dense></v-text-field>
                            </v-col>

                            <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                                <v-select :items="data_month" item-text="value" item-value="id" v-model="month"
                                     class="input-radius-search mt-1"
                                    prepend-inner-icon="mdi-calendar" label="เดือน" hide-details="auto" outlined
                                    dense></v-select>
                            </v-col>
                            <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                                <v-select :items="data_date" item-text="value" item-value="id" v-model="year"
                                     class="input-radius-search mt-1"
                                    prepend-inner-icon="mdi-calendar" label="ปี" hide-details="auto" outlined
                                    dense></v-select>
                            </v-col>

                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" class="card-radius pt-0">

                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th rowspan="2">วันที่ตรวจสอบ</th>
                                            <th rowspan="2">Lot</th>
                                            <th :colspan="quality_listLength[index]" class="text-center white-s"
                                                style="border-bottom: 1px solid #cdcdcd;height: 40px ;"
                                                v-for="(item, index)  in product_data.quality" :key="index">
                                                {{ item.pq_name_thai }}
                                            </th>

                                        </tr>

                                        <tr>
                                            <th class="text-center white-s" style="height: 40px;"
                                                v-for="(item_sub, index_sub)  in product_header" :key="index_sub">
                                                {{ item_sub.pq_list_thai }}
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="#000000" dark v-bind="attrs" v-on="on">
                                                            mdi-information-variant-circle
                                                        </v-icon>
                                                    </template>
                                                    <v-row style="width: 200px;">
                                                        <v-col cols="12 py-1 pt-3">Aim : {{ item_sub.pq_list_aim != null ?
                                                            item_sub.pq_list_aim : '-' }} </v-col>
                                                        <v-col cols="12 py-1 ">Green : {{ item_sub.pq_list_green != null ?
                                                            item_sub.pq_list_green : '-' }} </v-col>
                                                        <v-col cols="12 py-1">Yellow : {{ item_sub.pq_list_yellow != null ?
                                                            item_sub.pq_list_yellow : '-' }} </v-col>
                                                        <v-col cols="12 py-1 pb-4">Red : {{ item_sub.pq_list_red != null ?
                                                            item_sub.pq_list_red : '-' }} </v-col>
                                                    </v-row>
                                                </v-tooltip>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(dataDetail, index) in product_data.import_list" :key="index">
                                            <td style="border-bottom: 0px" class="white-s">
                                                <div>{{ dataDetail.check_date }}</div>
                                            </td>
                                            <td style="border-bottom: 0px" class="white-s">
                                                <div>
                                                    {{ dataDetail.product_lot }}
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon color="#000000" dark v-bind="attrs" v-on="on">
                                                                mdi-information-variant-circle
                                                            </v-icon>
                                                        </template>
                                                        <v-row style="width: 400px;">
                                                            <v-col cols="12 py-1 pt-5">เลขที่ใบแปรรูป :
                                                                {{ dataDetail.production_code }}</v-col>
                                                            <v-col cols="6 py-1">วันที่นำเข้า :
                                                                {{ dataDetail.import_date }}</v-col>
                                                            <v-col cols="6 py-1">วันที่ตรวจสอบ :
                                                                {{ dataDetail.check_date }}</v-col>
                                                            <v-col cols="12 py-1 pb-5">ผู้ดำเนินการ :
                                                                {{ dataDetail.user_name }}</v-col>
                                                        </v-row>
                                                    </v-tooltip>
                                                </div>
                                            </td>

                                            <td v-for="(dataDetail_sub, index_sub) in dataDetail.quality_check_list"
                                                :key="index_sub" class="text-center " style="border-bottom: 0px">
                                                <div class=""
                                                    v-if="dataDetail_sub.pq_list_type == 0 && dataDetail_sub.pq_value_boolean == 1">
                                                    <v-icon color="#88B358">mdi-check-circle</v-icon>
                                                </div>
                                                <div class=""
                                                    v-if="dataDetail_sub.pq_list_type == 0 && dataDetail_sub.pq_value_boolean == 0">
                                                    <v-icon color="#F28080">mdi-close-circle</v-icon>
                                                </div>
                                                <div class="" v-if="dataDetail_sub.pq_list_type == 1">
                                                    {{ dataDetail_sub.value != null ? dataDetail_sub.value : '-' }}
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <div class="text-center mt-5">
                                <v-pagination v-model="model" :length="1" :total-visible="7" color="#FFBC15"></v-pagination>
                            </div>

                        </v-card>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency} from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import product from '@/api/warehouse/product.js';
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังวัตถุดิบ',
                    disabled: false,
                    href: '/stock/warehouse-stock/warehouse-unpackaged',
                },
                {
                    text: 'นมข้นจืด (ดึงข้อมูล)',
                    disabled: false,
                },
                {
                    text: 'รายงานการตรวจสอบ',
                    disabled: false,
                    href: '#',
                },
            ],
            headers: [
                {
                    text: 'วันที่รับเข้า',
                    align: 'start',
                    sortable: false,
                    value: '',
                    width: '150px',
                },
                {
                    text: 'LOT',
                    align: 'start',
                    sortable: false,
                    value: '',
                    width: '200px',
                    class: 'fixed_header'
                },
            ],

            data_date: [],
            data_month: [
                {
                    id: 0,
                    value: 'ทั้งหมด',
                    month_id: '01',
                },
                {
                    id: 1,
                    value: 'มกราคม',
                    month_id: '01',
                },
                {
                    id: 2,
                    value: 'กุมภาพันธ์',
                    month_id: '02',
                },
                {
                    id: 3,
                    value: 'มีนาคม',
                    month_id: '03',
                },
                {
                    id: 4,
                    value: 'เมษายน',
                    month_id: '04',
                },
                {
                    id: 5,
                    value: 'พฤษภาคม',
                    month_id: '05',
                },
                {
                    id: 6,
                    value: 'มิถุนายน',
                    month_id: '06',
                },
                {
                    id: 7,
                    value: 'กรกฏาคม',
                    month_id: '07',
                },
                {
                    id: 8,
                    value: 'สิงหาคม',
                    month_id: '08',
                },
                {
                    id: 9,
                    value: 'กันยายน',
                    month_id: '09',
                },
                {
                    id: 10,
                    value: 'ตุลาคม',
                    month_id: '10',
                },
                {
                    id: 11,
                    value: 'พฤศจิกายน',
                    month_id: '11',
                },
                {
                    id: 12,
                    value: 'ธันวาคม',
                    month_id: '12',
                },
            ],
            type: 0,
            year: parseFloat(new Date().getUTCFullYear()),
            month: 0,
            product_data: [],
            product_header: [],
            quality_listLength: [],
            search: '',
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        getReportInspection() {
            let id = parseFloat(this.$route.params.id);
            product.getReportInspection(id, this.year, this.month, this.search, this.type).then((response) => {
                if (response.data.successful === true) {
                    this.product_data = response.data.product;
                    this.sub_menu[2].text = this.product_data.pro_name
                     let data = response.data.year_list;
                   data.forEach(item => {
                        this.data_date.push({
                            id: item.year,
                            value: item.year
                        })
                    })
                    console.log(this.product_data);
                    this.product_header.splice(0);
                    for (let index = 0; index < this.product_data.quality.length; index++) {
                        this.quality_listLength.push(this.product_data.quality[index].quality_list.length);
                        for (let i = 0; i < this.product_data.quality[index].quality_list.length; i++) {
                            this.product_header.push(this.product_data.quality[index].quality_list[i]);
                        }
                    }
                }
            })
        }
    },
    mounted() {
        this.getReportInspection();
    },
    watch:{
        search(){
             this.getReportInspection();
        },
        month(){
             this.getReportInspection();
        },
        year(){
             this.getReportInspection();
        },
    },
    components: {
        navigationmenu
    },
    created() {

    },
    computed: {
        roundedNumber() {
        return Math.ceil(this.product_header.length / this.product_data.quality.length);
        },
    },
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.tooltip-style {
    width: 400px;
    border-radius: 10px;
    background-color: #34230F;
    margin-left: -15px;
    margin-right: -15px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.left-fixed-columns .v-data-table__wrapper {
    overflow-x: auto;
    position: relative;
}

.left-fixed-columns .v-data-table__wrapper table {
    position: relative;
}

.b-0 {
    border-bottom: 0px !important;
}

.white-s {
    white-space: nowrap;
}</style>