<template>
  <div>
    <ProductionResultEdit/>
  </div>
</template>

<script>
import ProductionResultEdit from "@/components/production_process/processing_raw_materials/ProductionResultEdit";
export default {
  name: "Production_result_edit",
  components: {ProductionResultEdit}
}
</script>

<style scoped>

</style>