<template>
    <div>
        <WarehouseDetailHistoryRawMaterial_page />
    </div>
</template>
  
<script>
import WarehouseDetailHistoryRawMaterial_page from '@/components/stock/warehouse_stock/WarehouseDetailHistoryRawMaterial'
export default {
    name: 'WarehouseDetailHistoryRawMaterial',
    components: {
        WarehouseDetailHistoryRawMaterial_page,
    }
}
</script>