<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OF-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <div class="d-flex justify-space-between ma-5">
          <h3>
            <b>ขั้นตอนที่ 5 : การตรวจสอบกระบวนการแปรรูป</b>
          </h3>
          <div class="d-flex">
            <div class="d-flex mr-3">
              <div class="mr-2" style="color: #686868">ผู้แก้ไขล่าสุด :</div>
              <div class="black--text">ชลิตวรรณ นาคทอง</div>
            </div>
            <div class="d-flex">
              <div class="mr-2" style="color: #686868">วันที่ :</div>
              <div class="black--text">02/01/2023 09:40</div>
            </div>
          </div>
        </div>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/quality.png">
              </v-avatar>
              <span class="ml-3 title-card-text">การตรวจสอบคุณภาพการแปรรูป</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing mt-6" style="color: #b1adad">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (English)</div>
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (ภาษาไทย)</div>
                  <div class="table-sub-header-processing ml-1">Unit</div>
                  <div class="table-sub-header-processing ml-1" style="color:#ffb98a">Aim</div>
                  <div class="table-sub-header-processing ml-n1" style="color:#13b65d">Green</div>
                  <div class="table-sub-header-processing ml-n2" style="color:#ddbb55">Yellow</div>
                  <div class="table-sub-header-processing ml-n3" style="color:#c00000">Red</div>
                  <div class="table-sub-header-processing ml-n3">Value</div>
                </div>
                <v-card elevation="4" class="my-10" style="border-radius:20px">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Steaming</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">การนึ่ง</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items mt-0 mb-n4 pl-3 pr-3" v-for="(detail, index) in dataList" :key="index">
                    <div class="black--text font-weight-bold">{{ detail.eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.th }}</div>
                    <div>{{ detail.unit }}</div>
                    <div>{{ detail.aim }}</div>
                    <div>{{ detail.green }}</div>
                    <div>{{ detail.yellow }}</div>
                    <div>{{ detail.red }}</div>
                    <div>
                      <v-text-field class="input-radius" color="#FFBC15" label="value" v-model="detail.value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
                <v-card elevation="4" class="my-10" style="border-radius:20px">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Sensory</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">ชิมข้าวนึ่ง</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items mt-n3 mb-n3 pl-3 pr-3" v-for="(detail, index) in secondList" :key="index">
                    <div class="black--text font-weight-bold">{{ detail.eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.th }}</div>
                    <div>{{ detail.unit }}</div>
                    <div>{{ detail.aim }}</div>
                    <div>{{ detail.green }}</div>
                    <div>{{ detail.yellow }}</div>
                    <div>{{ detail.red }}</div>
                     <div>
                      <!-- <v-checkbox color="#FFBC15" v-model="detail.value"></v-checkbox> -->
                      <v-icon class="mt-2 mb-2" @click="detail.value = '1'" v-if="detail.value == '0'" color="#F28080">mdi-close-circle</v-icon>
                      <v-icon class="mt-2 mb-2" @click="detail.value = '0'" v-if="detail.value == '1'" color="#13b65d">mdi-check-circle</v-icon>
                    </div>
                  </div>
                </v-card>
                <v-card elevation="4" class="my-10" style="border-radius:20px">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Drying</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">การตากแห้ง (โดม)</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items mt-0 mb-n4 pl-3 pr-3" v-for="(detail, index) in dataList" :key="index">
                    <div class="black--text font-weight-bold">{{ detail.eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.th }}</div>
                    <div>{{ detail.unit }}</div>
                    <div>{{ detail.aim }}</div>
                    <div>{{ detail.green }}</div>
                    <div>{{ detail.yellow }}</div>
                    <div>{{ detail.red }}</div>
                    <div>
                      <v-text-field class="input-radius" color="#FFBC15" label="value" v-model="detail.value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn class="white--text" color="#88B358" elevation="4" rounded large>
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductionProcessStepsEdit",
  data() {
    return {
      dataList: [
        {
          eng: 'Steaming temp',
          th: 'อุณหภูมิในการนึ่ง',
          unit: 'C',
          aim: '100',
          green: '90-110',
          yellow: '<90>110',
          red: '-',
          value: 324,
        },
        {
          eng: 'Steaming time',
          th: 'เวลาในการนึ่ง',
          unit: 'min',
          aim: '50',
          green: '45-55',
          yellow: '<45>55',
          red: '-',
          value: 50,
        },
      ],
      secondList: [
        {
          eng: 'Appearance',
          th: 'ข้าวเป็นเม็ดสวยไม่แฉะ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
        {
          eng: 'Texture',
          th: 'ไม่เละไม่เป็นไต',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: false,
        },
        {
          eng: 'Flavor',
          th: 'ไม่มีกลิ่นเหม็นอับ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
      ],
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/production_process_recheck')
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
  align-items: baseline;
}
</style>