<template>
    <div>
        <AccountingPage />
    </div>
</template>
  
<script>
import AccountingPage from '@/components/accounting/AccountingListComponent'
export default {
    name: 'accounting',
    components: {
        AccountingPage,
    }
}
</script>