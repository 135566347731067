<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายงานการตรวจสอบคุณภาพ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายงานการตรวจสอบคุณภาพ</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="">
                                    <v-btn class="mt-2 mx-5 radius-15 text-grey text-16" elevation="" large color="#fff" @click="link('/stock/quality-inspection-report-list');">
                                        รายงานการตรวจสอบกระบวนการผลิต
                                    </v-btn>
                                    <v-btn class="mt-2 ml-0 mx-5 radius-15 text-16" elevation="2" large outlined
                                        color="#FFBC15">
                                        รายงานการตรวจสอบคุณภาพสินค้า
                                    </v-btn>

                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search" prepend-inner-icon="mdi-magnify"
                                    v-model="search" placeholder="ค้นหาที่นี่..." hide-details="auto" single-line outlined
                                    dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-supplier-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-supplier-box-data-text"></div>
                                    <div class="table-header-supplier-box-data-text">
                                        ชื่อเกณฑ์การตรวจสอบคุณภาพ (English)
                                    </div>
                                    <!-- <div class="table-header-supplier-box-data-text">
                                        ชื่อเกณฑ์การตรวจสอบคุณภาพ (ภาษาไทย)
                                    </div> -->
                                    <div class="table-header-supplier-box-data-text">
                                        ประเภทการตรวจสอบ
                                    </div>
                                    <div class="table-header-supplier-box-data-text">
                                        ดูรายงาน
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="item in data" :key="item">
                                    <div class="grid-supplier-box-items">
                                        <div class="w-border"></div>
                                        <div>{{item.pq_name_eng}}</div>
                                        <!-- <div>-</div> -->
                                        <div class="pl-7">{{item.pq_type_text}}</div>
                                        <div>
                                            <v-btn @click="$router.push(`/stock/quality-inspection-production-report-detail?id=${item.id}&fq_type=${item.pq_type}`)" class="mt-2" large rounded outlined color="#88B358">
                                                <v-icon left>mdi-clipboard-list-outline</v-icon>
                                                ดูรายงาน
                                            </v-btn>
                                        </div>

                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForNumber } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import report from '@/api/reportQuality/factory_quality.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'รายงานการตรวจสอบคุณภาพ',
                    disabled: false,
                    href: '#',
                },
            ],
            page: 1,
            pageSize: 1,
            search: '',

            data: [],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
        async getList(){
            await report.pdreportqualityreport(this.page, this.search).then(response => {
                if(response.data.successful === true){
                    this.data = response.data.list
                    this.page = parseFloat(response.data.currentPage)
                    this.pageSize = parseFloat(response.data.pages)
                }
            })
        },
    },
    mounted() {
        this.getList()
    },
    components: {
        navigationmenu
    },
    created() {
    },
    watch:{
        search(){
             this.getList()
        },
        page(){
             this.getList()
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-supplier-box-data {
    display: grid;
    grid-template-columns: 1% 32% 28% 25% ;
    padding: 5px 0px 5px 30px;
}

.table-header-supplier-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-supplier-box-items {
    display: grid;
    grid-template-columns: 5% 30% 28% 25%;
    align-items: center;
}
</style>