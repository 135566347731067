<template>
    <div>
        <WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction_page />
    </div>
</template>
  
<script>
import WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction_page from '@/components/stock/warehouse_stock/WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction'
export default {
    name: 'WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction',
    components: {
        WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction_page,
    }
}
</script>