import { HTTP } from "@/axios.js";
import store from "@/store/index.js";
export default {
  importpoListAll(page = "", size = "", search = "") {
    return new Promise((resolve, reject) => {
      HTTP.get(`/importpo/list?page=${page}&size=${size}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  importpoList() {
    return new Promise((resolve, reject) => {
      HTTP.get(`/importpo/polist`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  detailImportpo(id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/importpo/detail/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  detailImportpoList(id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/importpo/details/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getImportDetail(id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/importpo/details/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
};
