<template>
    <div>
        <SettingRawMaterialComponentPage />
    </div>
</template>
  
<script>
import SettingRawMaterialComponentPage from '@/components/stock/setting_stock/SettingRawMaterialComponent'
export default {
    name: 'SettingRawMaterialComponent',
    components: {
        SettingRawMaterialComponentPage,
    }
}
</script>