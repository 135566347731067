<template>
    <v-app class="background-page-login">
        <v-container>
            <div class="loading_all_page" v-if="loading_page === true">
                <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
                <h3>กำลังโหลดข้อมูล...</h3>
            </div>
            <v-slide-x-reverse-transition>
                <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
            </v-slide-x-reverse-transition>
            <!-- <div class="logo-left">
                <img src="@/assets/Ellipse 2.png" alt="Rice Unicorn" style="width: 250px;">
            </div> -->
            <v-row class="text-padding-page-login">
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <p class="text-welcome">WELCOME TO</p>
                    <p class="text-welcome-system">{{ this.company.name }} <span class="text-welcome-system2">!</span></p>
                </v-col>
                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12"></v-col>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <v-card class="card-background-login">
                        <v-card-text>
                            <div class="text-per-login text-center">
                                โปรดลงชื่อเข้าใช้บัญชีของคุณ
                            </div>
                            <div class="form-login">
                                <v-form ref="form" @submit.prevent="login()">
                                    <v-form-group>
                                        <v-text-field type="tel" class="input-radius" v-model="telephone" @keypress="isTelephone($event)" color="#FFBC15"
                                            label="เบอร์เข้าสู่ระบบ" minlength="10" maxlength="10"
                                            :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']" 
                                            placeholder="เบอร์เข้าสู่ระบบ" outlined rounded required></v-text-field>
                                    </v-form-group>
                                    <v-form-group>
                                        <v-text-field :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']" class="input-radius"
                                            color="#FFBC15" label="รหัสผ่าน" v-model="password" outlined rounded
                                            placeholder="รหัสผ่าน" hide-details="auto" required
                                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show_password ? 'text' : 'password'" name="input-10-1"
                                            @click:append="show_password = !show_password"></v-text-field>
                                    </v-form-group>
                                    <v-checkbox class="remember_me" label="จดจำฉันไว้ในระบบ" color="#FFBC15"></v-checkbox>
                                    <v-btn type="submit" class="button-submit-login" block rounded color="#FFBC15"
                                        elevation="4" large>
                                        <v-icon left>mdi-login</v-icon> เข้าสู่ระบบ</v-btn>
                                </v-form>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12"></v-col>
            </v-row>
            <!-- <div class="logo-right">
                <img src="@/assets/Rectangle 7.png" alt="Rice Unicorn" style="width: 250px;">
            </div> -->
        </v-container>
    </v-app>
</template>
<script>
import login from '@/api/user_profile/login.js'
import { isTelephone } from '@/helper/handler.js'
import company from '@/api/company/company.js'

export default {
    data() {
        return {
            loading_page: true,
            alert_error: false,
            alert_message_error: null,
            telephone: null,
            password: null,
            show_password: false,
            company: {
                id: null,
                name: null,
                code: null,
                tel: null,
                email: null,
                address: null,
            },
        }
    },
    methods: {
        async isTelephone(evt){
            return isTelephone(evt)
        },
        async checkAccessToken(){
            this.loading_page = true
            if (this.$store.state.access_token) {
                this.$router.push('/dashboard')
            }
            this.loading_page = false
        },
        async login(){
            if (this.$refs.form.validate()){
                this.loading_page = true
                const data = {
                    tel: this.telephone,
                    password: this.password,
                }
                login.authLogin(data).then((response) => {
                    if (response.data.successful === true) {
                        const data_success = {
                            access_token:response.data.access_token
                        }
                        this.$store.commit('logInSuccess',data_success)
                        this.$router.push('/dashboard')
                    }
                    if(response.data.successful === false){
                        this.loading_page = false
                        this.alert_message_error = 'เบอร์โทรและรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง'
                        this.alert_error = true
                        setTimeout(() => this.alert_error = false, 5000)
                    }
                })
            }
        },
        async onInit() {
            this.loading_page = true;
            await company.dataListNotAuth().then((response) => {
                if (response.data.successful === true) {

                    //Form
                    this.company.id = response.data.companyList.id
                    this.company.name = response.data.companyList.company_name
                    this.company.code = response.data.companyList.company_code
                    this.company.email = response.data.companyList.company_email
                    this.company.tel = response.data.companyList.company_tel
                    this.company.address = response.data.companyList.company_address

                    this.profile_picture = {
                        name: response.data.companyList.company_logo
                    }

                    this.imagePreviewURL = response.data.companyList.company_logo

                    this.loading_page = false;
                }
            })
        },
    },
    created() {
        this.onInit()
        this.checkAccessToken()
    },
}
</script>
<style scoped>
.background-page-login {
    background-image: url('~@/assets/background_login.png') !important;
    background-size: cover !important;
}

.text-padding-page-login {
    padding: 100px;
}

.text-welcome {
    font-weight: 700;
    font-size: 50px;
    text-align: center;
    color: #34230F;
}

.text-welcome-system {
    font-weight: 700;
    font-size: 64px;
    color: #FFBC15;
    text-align: center;
}

.text-welcome-system2 {
    font-weight: 700;
    font-size: 64px;
    color: #34230F;
}

.logo-left {
    top: 30%;
    position: absolute;
    left: 15%;
}

.logo-right {
    top: 30%;
    position: absolute;
    right: 15%;
}

.card-background-login {
    background: rgba(255, 255, 255, 0.75) !important;
    border: 3px solid #FFBC15 !important;
    border-radius: 40px !important;
    padding: 48px !important;
}

.text-per-login {
    color: #686868;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}

.form-login {
    margin-top: 30px;
}

.button-submit-login {
    color: #FFF !important;
    font-weight: 600;
    font-size: 16px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {

    .logo-left,
    .logo-right {
        display: none;
    }

    .text-padding-page-login {
        padding: 0;
        margin-top: 70px;
    }

    .text-welcome {
        font-size: 28px;
    }

    .text-welcome-system,
    .text-welcome-system2 {
        font-size: 36px;
    }

    .card-background-login {
        padding: 15px !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    .logo-left,
    .logo-right {
        display: none;
    }

    .text-padding-page-login {
        padding: 0;
        margin-top: 70px;
    }

    .text-welcome {
        font-size: 48px;
    }

    .text-welcome-system,
    .text-welcome-system2 {
        font-size: 60px;
    }

    .card-background-login {
        padding: 15px !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    .logo-left,
    .logo-right {
        display: none;
    }

    .text-padding-page-login {
        padding: 0;
        margin-top: 70px;
    }

    .text-welcome {
        font-size: 48px;
    }

    .text-welcome-system,
    .text-welcome-system2 {
        font-size: 60px;
    }

    .card-background-login {
        padding: 15px !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {

    .logo-left,
    .logo-right {
        display: none;
    }

    .text-padding-page-login {
        padding: 0;
        margin-top: 70px;
    }

    .text-welcome {
        font-size: 48px;
    }

    .text-welcome-system,
    .text-welcome-system2 {
        font-size: 60px;
    }

    .card-background-login {
        padding: 15px !important;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
    .logo-left {
        top: 30%;
        position: absolute;
        left: 5%;
    }

    .logo-right {
        top: 30%;
        position: absolute;
        right: 5%;
    }

    .text-padding-page-login {
        padding: 0;
        margin-top: 70px;
    }

    .text-welcome {
        font-size: 32px;
    }

    .text-welcome-system,
    .text-welcome-system2 {
        font-size: 60px;
    }

    .card-background-login {
        padding: 15px !important;
    }
}

@media screen and (min-width: 1441px) {
    .text-welcome {
        font-size: 32px;
    }

    .text-welcome-system,
    .text-welcome-system2 {
        font-size: 38px;
    }

    .text-padding-page-login {
        padding: 50px;
    }

    .logo-left,
    .logo-right {
        max-width: 250px;
    }
}
</style>