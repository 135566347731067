<template>
  <div>
    <ProductionProductGoodProcessSteps/>
  </div>
</template>

<script>
import ProductionProductGoodProcessSteps from "@/components/production_process/processing_product_good/ProductionProductGoodProcessSteps";
export default {
  name: "ProductionProductGoodProcessSteps_Page",
  components: {ProductionProductGoodProcessSteps}
}
</script>

<style scoped>

</style>