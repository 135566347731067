<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">บันทึกบัญชี </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการบันทึกบัญชี</b>
                    </v-card-title>
                    <v-card-text>
                        <div class="input-header-search">
                            <div class="ma-2">
                                <v-text-field color="#D9D9D9" class="input-radius-search" prepend-inner-icon="mdi-magnify"
                                    v-model="search" placeholder="ค้นหาที่นี่..." hide-details="auto" outlined>
                                </v-text-field>
                            </div>
                            <div class="ma-2">
                                <v-autocomplete class="input-radius-search" :items="month_list" color="#FFBC15"
                                    prepend-inner-icon="mdi-calendar-range" label="เดือน" hide-details="auto"
                                    item-text="name" item-value="id" outlined>
                                </v-autocomplete>
                            </div>
                            <div class="ma-2">
                                <v-autocomplete class="input-radius-search" :items="year_list" color="#FFBC15"
                                    prepend-inner-icon="mdi-calendar-range" label="ระยะเวลา" hide-details="auto" value="2"
                                    item-text="name" item-value="id" outlined>
                                </v-autocomplete>
                            </div>
                            <div class="ma-2 ml-auto">
                                <div class="d-flex justify-end">
                                    <v-btn class="mt-2" large rounded outlined color="#88B358" @click="add_dialog = true">
                                        <v-icon left>mdi-plus</v-icon>
                                        บันทึกบัญชี
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-accounting-setting-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-accounting-setting-box-data-text"></div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        วันที่/เวลา
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        ชื่อรายการ
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        ประเภทบัญชี
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        รายรับ
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        รายจ่าย
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text">
                                        คงเหลือ
                                    </div>
                                    <div class="table-header-accounting-setting-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius">
                                    <div class="grid-accounting-setting-box-items">
                                        <div class="w-border"></div>
                                        <div>30/11/2022 10:10</div>
                                        <div>เงินทุนจากหุ้นส่วน</div>
                                        <div>เงินทุนจากหุ้นส่วน</div>
                                        <div style="color:##88B358;">15,000.00</div>
                                        <div>-</div>
                                        <div>338450.00</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link @click="detail_dialog = true">
                                                        <v-list-item-title style="color:#6C7FD8">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียด
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link @click="edit_dialog = true">
                                                        <v-list-item-title style="color:#FEA06C">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link @click="delete_dialog = true">
                                                        <v-list-item-title style="color:#F28080">
                                                            <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                            ลบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <v-card elevation="0" class="card-table-radius">
                                    <div class="grid-accounting-setting-box-items">
                                        <div class="w-border"></div>
                                        <div>30/11/2022 09:30</div>
                                        <div>ซีเรียลรสนม</div>
                                        <div>รายได้จากการขาย</div>
                                        <div style="color:##88B358;">65,450.00</div>
                                        <div>-</div>
                                        <div>338450.00</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link @click="detail_dialog = true">
                                                        <v-list-item-title style="color:#6C7FD8">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียด
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link @click="edit_dialog = true">
                                                        <v-list-item-title style="color:#FEA06C">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link @click="delete_dialog = true">
                                                        <v-list-item-title style="color:#F28080">
                                                            <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                            ลบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- เพิ่มบัญชี -->
        <v-dialog v-model="add_dialog" transition="dialog-top-transition" max-width="800" style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b class="ml-5">บันทึกบัญชี</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="add_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-card elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center">
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-calculator
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 text-font-title">ข้อมูลรายการบัญชี</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-text-field type="text" class="input-radius" label="ชื่อรายการ" value=""
                                            placeholder="ชื่อรายการ" color="#FFBC15"
                                            :rules="[v => !!v || 'กรุณากรอกชื่อรายการ']" hide-details="auto"
                                             outlined rounded required></v-text-field>
                                    </v-form-group>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-autocomplete class="input-radius" :items="type_list" item-text="name"
                                            item-value="id" color="#FFBC15" label="ประเภทบัญชี"
                                            :rules="[v => !!v || 'กรุณากรอกประเภทบัญชี']" hide-details="auto" outlined
                                            rounded required></v-autocomplete>
                                    </v-form-group>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-text-field class="input-radius" color="#FFBC15" label="จำนวนเงิน"
                                            placeholder="จำนวนเงิน" v-model="price" minlength="1" maxlength="3"
                                            :rules="[v => !!v || 'กรุณากรอกจำนวนเงิน']"
                                            @keypress="isOnlyNumber($event, price)" hide-details="auto" suffix="บาท"
                                            outlined rounded required></v-text-field>
                                    </v-form-group>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-textarea class="input-radius" label="รายละเอียด" color="#FFBC15" outlined rounded
                                            rows="3" row-height="25"></v-textarea>
                                    </v-form-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="mt-3 div-submit-data">
                        <v-btn type="button" class="button-submit-data" color="#88B358" @click="link('/dashboard')"
                            elevation="4" rounded large>
                            <v-icon left>mdi-content-save</v-icon> บันทึก
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการเพิ่มบัญชี -->
        <!-- แก้ไขบัญชี -->
        <v-dialog v-model="edit_dialog" transition="dialog-top-transition" max-width="800" style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b class="ml-5">แก้ไขรายการบัญชี</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="edit_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด :
                        <span class="text-fullname-title">ชลิตวรรณ นาคทอง</span> <span class="text-date-title">วันที่ :
                        </span>
                        <span class="text-datetime-title">21/03/2022 09:40</span>
                    </div>
                    <v-card elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center">
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-calculator
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 text-font-title">ข้อมูลรายการบัญชี</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-text-field type="text" class="input-radius" label="ชื่อรายการ" value=""
                                            placeholder="ชื่อรายการ" color="#FFBC15"
                                            :rules="[v => !!v || 'กรุณากรอกชื่อรายการ']" hide-details="auto"
                                             outlined rounded required></v-text-field>
                                    </v-form-group>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-autocomplete class="input-radius" :items="type_list" item-text="name"
                                            item-value="id" color="#FFBC15" label="ประเภทบัญชี"
                                            :rules="[v => !!v || 'กรุณากรอกประเภทบัญชี']" hide-details="auto" outlined
                                            rounded required></v-autocomplete>
                                    </v-form-group>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-text-field class="input-radius" color="#FFBC15" label="จำนวนเงิน"
                                            placeholder="จำนวนเงิน" v-model="price" minlength="1" maxlength="3"
                                            :rules="[v => !!v || 'กรุณากรอกจำนวนเงิน']"
                                            @keypress="isOnlyNumber($event, price)" hide-details="auto" suffix="บาท"
                                            outlined rounded required></v-text-field>
                                    </v-form-group>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-textarea class="input-radius" label="รายละเอียด" color="#FFBC15"
                                            placeholder="รายละเอียด" outlined rounded rows="3" row-height="25"></v-textarea>
                                    </v-form-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-2" elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center">
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 text-font-title">การแก้ไข</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-textarea class="input-radius" label="หมายเหตุการแก้ไข" color="#FFBC15"
                                            placeholder="กรอกข้อมูลผิด" outlined rounded rows="3"
                                            row-height="25"></v-textarea>
                                    </v-form-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="mt-3 div-submit-data">
                        <v-btn type="button" class="button-submit-data" color="#88B358" @click="link('/dashboard')"
                            elevation="4" rounded large>
                            <v-icon left>mdi-content-save</v-icon> บันทึก
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการแก้ไขบัญชี -->
        <!-- ดูรายละเอียดบัญชี -->
        <v-dialog v-model="detail_dialog" transition="dialog-top-transition" max-width="1051" style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between mb-5">
                    <v-card-title>
                        <b>รายละเอียดทีมงาน</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="detail_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-card elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center ">
                            <v-avatar class="top-to-card" color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-file-document
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 mt-3 text-font-title">รายละเอียดบัญชี</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                    <div class="text-total-price">
                                        <h3>65,450.00 บาท</h3>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="12" md="9" lg="9" xl="9" cols="12">
                                    <v-row>
                                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                            <div class="ml-3 text-name-title">ชื่อรายการ :
                                                <span class="text-fullname-title">ซีเรียลรสนม
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                            <div class="ml-3 text-name-title">ประเภทบัญชี :
                                                <span class="text-fullname-title">รายได้จากการขาย</span>
                                            </div>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                            <div class="ml-3 text-name-title">วันที่สร้างรายการบัญชี :
                                                <span class="text-fullname-title">30/11/2022 09:30
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                            <div class="ml-3 text-name-title">ผู้บันทึกบัญชี :
                                                <span class="text-fullname-title">ชลิตวรรณ นาคทอง</span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <div class="ml-3 text-name-title">รายละเอียด :
                                        <span class="text-fullname-title"> คำสั่งซื้อบริษัท อาฟเตอร์ ยู จำกัด
                                        </span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-8" elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center">
                            <v-avatar class="top-to-card" color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 mt-3 text-font-title">การแก้ไขข้อมูล</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-simple-table>
                                        <template v-slot:default>
                                          <thead>
                                            <tr>
                                              <th class="text-left" style="font-size:14px;">
                                                วันที่/เวลา
                                              </th>
                                              <th class="text-left" style="font-size:14px;">
                                                รายละเอียดการแก้ไข
                                              </th>
                                              <th class="text-left" style="font-size:14px;">
                                                ผู้ดำเนินการ
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>30/11/2022 09:30</td>
                                              <td>กรอกข้อมูลผิด</td>
                                              <td>ชลิตวรรณ นาคทอง</td>
                                            </tr>
                                          </tbody>
                                        </template>
                                      </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดดูรายละเอียดบัญชี -->

        <!-- ยกเลิกรายการบัญชี -->
        <v-dialog v-model="delete_dialog" transition="dialog-top-transition" max-width="800" style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b class="ml-5">ยกเลิกรายการบัญชี</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-card elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center">
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-package-variant-closed
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 text-font-title">เหตุผลการยกเลิกบัญชี</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-form-group>
                                        <v-textarea class="input-radius" label="เหตุผลการยกเลิกบัญชี" color="#FFBC15"  :rules="[v => !!v || 'กรุณากรอกเหตุผลการยกเลิกบัญชี']" outlined rounded
                                            rows="6" row-height="25"></v-textarea>
                                    </v-form-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="mt-3 div-submit-data">
                        <v-btn type="button" class="button-submit-data" color="#88B358" @click="link('/dashboard')"
                            elevation="4" rounded large>
                            <v-icon left>mdi-content-save</v-icon> บันทึก
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดยกเลิกรายการบัญชี -->
    </div>
</template>
<script>
import { onlyForNumber } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenuAccounting.vue';
export default {
    data() {
        return {
            loading_page: false,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,
            delete_dialog: false,
            sub_menu: [
                {
                    text: 'บัญชี',
                    disabled: false,
                },
                {
                    text: 'บันทึกบัญชี',
                    disabled: false,
                    href: '#',
                },
            ],

            month_list: [
                { id: 1, name: 'เดือน มกราคม' },
                { id: 2, name: 'เดือน กุมภาพันธ์' },
                { id: 3, name: 'เดือน มีนาคม' },
                { id: 4, name: 'เดือน เมษายน' },
                { id: 5, name: 'เดือน พฤษภาคม' },
                { id: 6, name: 'เดือน มิถุนายน' },
                { id: 7, name: 'เดือน กรกฎาคม' },
                { id: 8, name: 'เดือน สิงหาคม' },
                { id: 9, name: 'เดือน กันยายน' },
                { id: 10, name: 'เดือน ตุลาคม' },
                { id: 11, name: 'เดือน พฤศจิกายน' },
                { id: 12, name: 'เดือน ธันวาคม' },
            ],
            year_list: [
                { id: 1, name: '3 เดือน' },
                { id: 2, name: '6 เดือน' },
                { id: 3, name: '9 เดือน' },
                { id: 4, name: '12 เดือน' },
            ],
            type_list: [
                { id: 1, name: 'รายรับ' },
                { id: 2, name: 'รายจ่าย' },
                { id: 3, name: 'เงินทุนหมุนเวียน' },
                { id: 4, name: 'คืนงินหมุนเวียน' },
            ],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}


.table-header-accounting-setting-box-data {
    display: grid;
    grid-template-columns: 0% 12% 22% 15% 12% 12% 12% 15%;
    padding: 5px 0px 5px 30px;
}

.table-header-accounting-setting-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-accounting-setting-box-items {
    display: grid;
    grid-template-columns: 3% 12% 21% 15% 12% 12% 17% 15%;
    align-items: center;
}

.text-total-price>>>h3 {
    padding: 20px;
    border: 1px solid #88B358;
    color: #88B358;
    font-size: 20px;
    font-weight: 600;
    text-align:center;
    border-radius: 10px;
}
</style>