<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/prm/processing_materials')">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-form ref="form_add_or_edit" @submit.prevent="saveData()">
        <v-card elevation="2" class="card-radius">
          <v-card-title>
            <b>ขั้นตอนที่ 3 : บันทึกผลการแปรรูป</b>
          </v-card-title>
          <v-card-text>
            <v-card elevation="4" class="py-2" style="border-radius:20px">
              <v-card-title>
                <v-avatar color="#34230F" size="36">
                  <img class="icon-image-logo" src="@/assets/contract (1).png">
                </v-avatar>
                <span class="ml-3 title-card-text">การบันทึกผลการแปรรูป</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                <v-card-text>
                  <div class="ml-15 mt-4">
                    <div class="tag-title-step">
                      <b class="black--text">การบันทึกเวลาในการแปรรูป</b>
                    </div>
                    <div class="divider-table my-2" />
                    <div class="scroll-menu-last mt-4">

                      <section class="mb-3">
                        <div class="w-border-header-first mb-n3" />
                        <div class="card-main-table" style="padding: 15px">
                          <v-col xl="12" lg="12">
                            <div class="black--text font-weight-bold text-center ma-2">เวลาที่ใช้ในการแปรรูป</div>
                          </v-col>

                          <v-divider />

                          <v-col xl="12" lg="12">
                            <div class="black--text font-weight-bold text-center ma-2">เวลาเริ่มต้นการแปรรูป</div>
                          </v-col>
                          <v-col xl="12" lg="12">
                            <div class="black--text font-weight-bold text-center ma-2">เวลาสิ้นสุดการแปรรูป</div>
                          </v-col>
                        </div>
                      </section>

                      <section class="mb-3 last" v-for="(detail, index) in step_1_list" :key="index">
                        <div class="w-border-header-sub-last mb-n3" />
                        <div class="card-sub-table-last" style="padding: 15px">
                          <div class="py-2 pt-5 pb-4">
                            <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                          </div>
                          <v-divider />
                          <div class="pt-3">
                            <div>
                              <v-text-field v-model="detail.start_date" label="เวลาเริ่ม" color="#FFBC15"
                                @keypress="isOnlyNumber($event, detail.end_date)" @input="formatInput(index)"
                                :rules="[v => !!v || 'กรุณากรอกเวลาเริ่ม']" append-icon="mdi-clock-time-four-outline"
                                outlined dense rounded required  maxlength="5" />
                            </div>
                            <div>
                              <v-text-field v-model="detail.end_date" label="เวลาสิ้นสุด" color="#FFBC15"
                                @keypress="isOnlyNumber($event, detail.end_date)" @input="formatInput(index)"
                                :rules="[v => !!v || 'กรุณากรอกเวลาสิ้นสุด']" append-icon="mdi-clock-time-four-outline"
                                outlined dense rounded required maxlength="5" />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text>
                  <div class="ml-15 mt-7">
                    <div class="tag-title-step">
                      <b class="black--text">การบันทึกผลการแปรรูป</b>
                    </div>
                    <div class="divider-table my-2"></div>
                    <div class="scroll-menu-last mt-4">
                      <section>
                        <div class="w-border-header-first mb-n3" />
                        <div class="card-main-table">
                          <div style="padding: 15px">
                            <div class="py-3 d-flex justify-space-between">
                              <div class="black--text font-weight-bold">วัตถุดิบ</div>
                              <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                            </div>
                          </div>
                          <div class="pt-0" style="padding: 15px">
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12" class="inline-box-specific">
                                <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2" v-for="(item, index) in step_1" :key="item">
                              <v-col cols="12">
                                <v-row>
                                  <v-col cols="auto" style="margin-top: 8px; font-size:15px;">
                                    <div class="px-3 text-center"
                                      style="border: 1px solid #FFBC15; border-radius: 15px; padding:5px;">
                                      <b class="black--text ml-1">ส่วนที่ {{ index + 1 }}</b>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row v-for="detail in item.material_list" :key="detail" >
                                  <v-col xl="6" lg="6" style="margin-top: 2px">
                                    <div class="black--text font-weight-bold">{{ detail.mat_name }}</div>
                                    <div class="font-weight-bold"></div>
                                  </v-col>
                                  <v-col xl="6" lg="6" style="margin-top: 2px">
                                    <div class="black--text font-weight-bold">{{ formatPrice(detail.receipt_qty) }} กรัม
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-divider class="mt-10 mb-2" dark color="#a3a3a3" />
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-total" style="width: 100%;max-width: 100%;">
                            <div class="d-flex justify-center">
                              <div class="pa-3">
                                <div class="black--text font-weight-bold">รวม (FG)</div>
                              </div>
                              <div class="pa-3">
                                <div class="black--text font-weight-bold">{{ formatPrice(fg) }} กรัม</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>


                      <section class="last" v-for="(detail, index) in step_1_list" :key="index">
                        <div class="w-border-header-sub-last mb-n3" />
                        <div class="card-sub-table-last">
                          <div style="padding: 15px">
                            <div class="py-3">
                              <div class="black--text font-weight-bold">Batch  {{ index + 1 }}</div>
                            </div>
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12" class="inline-box-specific-sub">
                            <div class="black--text font-weight-bold text-center">{{ detail.percent }} %</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2 mt-0" style="height: 100%;max-height: 150%;">
                              <v-col cols="12 pt-0">
                                <v-row class="pt-2 mt-0" v-for="item in detail.batch_list" :key="item" >
                                <v-row v-for="sub_item in item.stock_list" :key="sub_item" class="mt-0">
                                      <v-col xl="12" lg="12">
                                        <div class="px-3">
                                          <label for="">จำนวนกรัม</label>
                                          <p>{{ formatPrice(sub_item.quantity_cut) }}</p>
                                          <!-- <v-text-field class="input-radius" color="#FFBC15" label="จำนวนกรัม"
                                            placeholder="จำนวนกรัม" hide-details="auto" v-model="sub_item.quantity_cut"
                                            :rules="[v => !!v || 'กรุณากรอกจำนวนกรัม']" dense  required
                                            disabled></v-text-field> -->
                                        </div>
                                        <div class="px-3">
                                          <label for="">เลข LOT</label>
                                          <p>{{ sub_item.material_lot }}</p>
                                          <!-- <v-select class="input-radius" color="#686868" label="เลข lot" placeholder="เลข lot" hide-details="auto" outlined dense rounded required></v-select> -->
                                          <!-- <v-text-field class="input-radius" color="#FFBC15" label="เลข LOT"
                                            placeholder="เลข LOT" hide-details="auto" v-model="sub_item.material_lot"
                                            :rules="[v => !!v || 'กรุณากรอกเลข LOT']" dense required
                                            disabled></v-text-field> -->
                                        </div>
                                        <!-- <div class="mb-2 text-center" style="color: #88b358">+ เพิ่ม Lot วัตถุดิบ</div> -->
                                        <v-divider class="mt-5 mb-2" dark color="#a3a3a3" />
                                      </v-col>
                                    </v-row>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-gams">
                            <div style="padding: 15px;">
                              <v-text-field v-if="status_process <=2" class="input-radius white custom-label-color" color="#FFBC15"
                                label="จำนวนกรัม" placeholder="จำนวนกรัม" hide-details="auto" v-model="detail.qty_after_process" @input="total_price()"
                                :rules="[v => !!v || 'กรุณากรอกจำนวนกรัม']" outlined dense rounded
                                required></v-text-field>
                              <v-text-field v-else class="input-radius white custom-label-color" color="#FFBC15"
                                label="จำนวนกรัม" placeholder="จำนวนกรัม" hide-details="auto" v-model="detail.qty_after_process"
                                :rules="[v => !!v || 'กรุณากรอกจำนวนกรัม']" outlined dense rounded
                                required disabled></v-text-field>
                            </div>
                          </div>
                        </div>
                      </section>



                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div></div>
                    <div class="summarize my-5 pa-5">
                      <h2 class="font-weight-bold text-center" style="color: #88b358">ผลลัพธ์ที่จะได้รับจากการแปรรูป
                        {{ formatPrice(step3_total) }} กรัม หรือ {{ formatPrice((step3_total / 1000)) }} กิโลกรัม
                      </h2>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text>
                  <div class="ml-15 mt-7">
                    <div class="tag-title-step">
                      <b class="black--text">วัตถุดิบที่เสียหายหรือทิ้งไปจากการแปรรูปในรอบนี้</b>
                    </div>
                    <div class="divider-table my-2"></div>
                    <div class="mt-4">
                      <v-row>
                        <v-col xl="6" lg="6">
                          <div class="mb-2">
                            <v-select class="input-radius" :items="step_2" item-text="mat_name" item-value="material_id"
                              @change="getDetail" color="#FFBC15" label="วัตถุดิบที่เสียหายหรือทิ้ง" ref="step_2"
                              placeholder="วัตถุดิบที่เสียหายหรือทิ้ง" hide-details="auto" outlined dense
                              rounded></v-select>
                          </div>
                        </v-col>
                      </v-row>
                      <div class="table-header-processing mt-4" style="color: #b1adad">
                        <div class="table-sub-header-processing">
                          ชื่อวัตถุดิบ
                        </div>
                        <div class="table-sub-header-processing">
                          เลข lot
                        </div>
                        <div class="table-sub-header-processing">
                          จำนวนกรัม
                        </div>
                        <div class="table-sub-header-processing text-center ml-n1">
                          ลบ
                        </div>
                      </div>
                      <v-card elevation="0" class="grid-processing-items ma-4" v-for="(detail, index) in dataList"
                        :key="index" :class="detail.is_delete != 0 ? 'd-none' : ''">
                        <div v-if="detail.is_delete == 0">{{ detail.mat_name }}</div>
                        <div class="pa-2 pt-0 mt-n5" v-if="detail.is_delete == 0">
                          <v-select class="input-radius" :items="detail.lot_list" item-text="material_lot"
                            item-value="material_lot_id" v-if="detail.id != null" v-model="detail.material_lot_id"
                            color="#FFBC15" label="เลข lot" placeholder="เลข lot" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกเลข Lot']" outlined dense rounded required
                            disabled></v-select>
                          <v-select class="input-radius" :items="detail.lot_list" item-text="material_lot"
                            item-value="material_lot_id" v-else v-model="detail.material_lot_id" color="#FFBC15"
                            label="เลข lot" placeholder="เลข lot" hide-details="auto" outlined dense rounded
                            :rules="[v => !!v || 'กรุณากรอกจำนวนกรัม']" required></v-select>
                        </div>
                        <div class="pa-2 pt-0 mt-n5" v-if="detail.is_delete == 0">
                          <v-text-field class="input-radius" color="#FFBC15" label="จำนวนกรัม" v-model="detail.qty" dense
                            :rules="[v => !!v || 'กรุณากรอกจำนวนกรัม']" outlined rounded required></v-text-field>
                        </div>
                        <div style="text-align: center" v-if="detail.is_delete == 0">
                          <v-btn class="ml-6 radius-15" color="#f2abab" outlined @click="remove(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-card elevation="4" class="py-2" style="border-radius:20px">
              <v-card-title>
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>mdi-timer-alert</v-icon>
                </v-avatar>
                <span class="ml-3 title-card-text">วันหมดอายุของสินค้า FG</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                <v-row class="ml-15">
                  <v-col xl="12" lg="12">
                    <v-row>
                      <v-col xl="3" lg="3">
                        <div class="black--text font-weight-bold ma-2">วันหมดอายุของการแปรรูปสินค้า FG </div>
                        <v-menu v-model="menu1" :close-on-content-click="false" class="input-radius-search "
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-if="exp_date == null" v-model="exp_date" label="วัน/เดือน/ปี" color="#FFBC15"
                              prepend-inner-icon="mdi-calendar" hide-details="auto" 
                              :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded readonly
                              required v-on="on"></v-text-field>
                            <v-text-field v-else v-model="formattedDate" label="วัน/เดือน/ปี" color="#FFBC15"
                              prepend-inner-icon="mdi-calendar" hide-details="auto"
                              :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded readonly
                              required v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="exp_date" @input="menu1 = false" :min="currentDate" no-title
                            scrollable></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <div class="mt-3 text-center">
            <v-btn type="submit" class="white--text" color="#88B358" elevation="4" rounded large>
              <v-icon left>mdi-content-save</v-icon> บันทึก
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import { formatPrice, onlyForNumber } from "@/helper/handler";
import process from '@/api/process/meterial_process.js'
import Swal from "sweetalert2";
export default {
  name: "ProductionResultCreate",
  data() {
    return {
      loading_page: true,
      menu1: false,
      menu2: false,
      menu3: false,
      currentDate: new Date().toISOString().substr(0, 10), // Get the current date in YYYY-MM-DD format
      timeList: [
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
      ],
      minDate: new Date().toISOString().substr(0, 10),
      
      //ส่วนที่ 1
      step_1: [],
      step_1_list: [],
      fg: 0,

      //ส่วนที่ 2
      step_2: [],
      dataList: [],
      material_drop_lsit: [],

      //ส่วนที่ 3
      exp_date: null,
      status_process: null,
      total_result: 0,

      test: {},

      step3_total: 0,
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/production_process_steps')
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    isOnlyNumber(number) {
      return onlyForNumber(number);
    },
    formatInput(index = null) {
      if (this.step_1_list[index].start_date.length > 2) {
        this.loading_page = true;
        this.step_1_list[index].start_date = this.step_1_list[index].start_date.replace(/:/g, '');
        this.step_1_list[index].start_date = this.step_1_list[index].start_date.replace(/\B(?=(\d{2})+(?!\d))/g, ':');
        this.loading_page = false;
      }
      if (this.step_1_list[index].end_date.length > 2) {
        this.loading_page = true;
        this.step_1_list[index].end_date = this.step_1_list[index].end_date.replace(/:/g, '');
        this.step_1_list[index].end_date = this.step_1_list[index].end_date.replace(/\B(?=(\d{2})+(?!\d))/g, ':');
        this.loading_page = false;
      }
    },
    getStepOne() {
      this.loading_page = true;
      let id = this.$route.params.id;
      process.getStepOne(id).then((response) => {
        if (response.status == 502) {
          window.location.reload()
        }
        if (response.data.successful === true) {
          this.step_1 = response.data.mat_group_list;
          this.step_1_list = response.data.batch;
          this.material_drop_lsit = response.data.material_drop_lsit;
          this.fg = response.data.batch_qty;
          this.exp_date = response.data.exp_date;
          this.status_process = response.data.status_process;
          let sum = 0;
          let result_sum = 0;
          for (let i = 0; i < this.step_1_list.length; i++) {
            sum = 0;
            for (let j = 0; j < this.step_1_list[i].batch_list.length; j++) {
              for (let x = 0; x < this.step_1_list[i].batch_list[j].stock_list.length; x++) {
                sum += parseFloat(this.step_1_list[i].batch_list[j].stock_list[x].quantity_cut);
              }
              this.step_1_list[i] = {
                id: this.step_1_list[i].id,
                batch_round: this.step_1_list[i].batch_round,
                percent: this.step_1_list[i].percent,
                qty_after_process: this.step_1_list[i].qty_after_process,
                start_date: this.step_1_list[i].start_date,
                end_date: this.step_1_list[i].end_date,
                batch_list: this.step_1_list[i].batch_list,
                total_price: sum,
              }

            }
            result_sum += parseFloat(this.step_1_list[i].qty_after_process)
          }
          this.step3_total = result_sum;
          this.getDetail();
          this.loading_page = false;
        }
      })
    },
    getStepTwo() {
      this.loading_page = true;
      let id = this.$route.params.id;
      process.getStepTwo(id).then((response) => {
        if (response.status == 502) {
          window.location.reload()
        }
        if (response.data.successful === true) {
          this.step_2 = response.data.data;
          this.loading_page = false;
        }
      })
    },
    getDetail(material_id = null) {
      if (material_id == null) {
        if (this.material_drop_lsit.length != 0) {
          for (let i = 0; i < this.material_drop_lsit.length; i++) {
            this.dataList.push({
              id: this.material_drop_lsit[i].id,
              material_id: this.material_drop_lsit[i].material_id,
              mat_name: this.material_drop_lsit[i].mat_name,
              lot_list: this.material_drop_lsit[i].lot,
              material_lot_id: this.material_drop_lsit[i].material_lot_id,
              qty: this.material_drop_lsit[i].drop_qty,
              is_delete: 0
            })
          }
        }
      } else {
        let id = this.$route.params.id;
        process.getDetail(id, material_id).then((response) => {
          if (response.data.successful === true) {
            let data = response.data.data;
            let check = this.step_2.find(o => o.material_id === material_id);
            if (check != null) {
              this.dataList.push({
                id: null,
                material_id: check.material_id,
                mat_name: check.mat_name,
                lot_list: data,
                material_lot_id: check.material_lot_id,
                qty: 0,
                is_delete: 0
              })
            }

          }
          this.$refs.step_2.reset()
        })
      }
    },

    // getDetailClick(material_id = null, index){
    //   this.loading_page =true;
    //    let  id = this.$route.params.id;
    //      process.getDetail(id, material_id).then((response) => {
    //             if(response.data.successful === true){
    //               let data = response.data.data;
    //               let check = this.dataList.find(o => o.material_id === material_id);
    //               if(check != null){
    //                      this.dataList[index] ={
    //                     id: check.id,
    //                     material_id: check.material_id,
    //                     mat_name: check.mat_name,
    //                     lot_list:  data,
    //                     material_lot_id: check.material_lot_id,
    //                     qty: check.qty
    //                    }
    //               }
    //         }
    //         })
    //          this.loading_page =false;
    // },
    remove(index) {
      this.loading_page = true;
      this.dataList[index] = {
        id: this.dataList[index].id,
        material_id: this.dataList[index].material_id,
        mat_name: this.dataList[index].mat_name,
        lot_list: this.dataList[index].lot_list,
        material_lot_id: this.dataList[index].material_lot_id,
        qty: this.dataList[index].qty,
        is_delete: 1,
      };
      this.loading_page = false;
    },
    saveData() {
      if (this.$refs.form_add_or_edit.validate()) {
        this.loading_page = true;
        let batch_array = [];
        for (let i = 0; i < this.step_1_list.length; i++) {
          batch_array.push({
            id: this.step_1_list[i].id,
            batch_round: this.step_1_list[i].batch_round,
            percent: this.step_1_list[i].percent,
            qty_after_process: this.step_1_list[i].qty_after_process,
            start_date: this.step_1_list[i].start_date,
            end_date: this.step_1_list[i].end_date
          });
        }
        if (this.dataList.length != 0) {
          for (let i = 0; i < this.dataList.length; i++) {
            if (this.material_drop_lsit.length == 0) {
              if(this.dataList[i].is_delete == 0){
                this.material_drop_lsit.push({
                process_id: parseFloat(this.$route.params.id),
                material_id: this.dataList[i].material_id,
                material_lot_id: this.dataList[i].material_lot_id,
                drop_qty: this.dataList[i].qty
              });
              }
            } else {
              let del_array = [];
              for (let i = 0; i < this.dataList.length; i++) {
                let check = this.material_drop_lsit.find(o => o.id === this.dataList[i].id);
                if (check != null && this.dataList[i].id != null && this.dataList[i].is_delete == 1) {
                  del_array.push({
                    id: this.dataList[i].id,
                    process_id: parseFloat(this.$route.params.id),
                    material_id: this.dataList[i].material_id,
                    material_lot_id: this.dataList[i].material_lot_id,
                    drop_qty: this.dataList[i].qty,
                    is_delete: 1
                  });
                } else if (check == null && this.dataList[i].id == null && this.dataList[i].is_delete == 0) {
                  del_array.push({
                    process_id: parseFloat(this.$route.params.id),
                    material_id: this.dataList[i].material_id,
                    material_lot_id: this.dataList[i].material_lot_id,
                    drop_qty: this.dataList[i].qty,
                  });
                } else if (check != null && this.dataList[i].id != null && this.dataList[i].is_delete == 0) {
                  del_array.push({
                    id: this.dataList[i].id,
                    process_id: parseFloat(this.$route.params.id),
                    material_id: this.dataList[i].material_id,
                    material_lot_id: this.dataList[i].material_lot_id,
                    drop_qty: this.dataList[i].qty,
                  });
                }

              }
              this.material_drop_lsit.splice(0);
              this.material_drop_lsit = del_array;
            }
          }
        }
        let data = {
          process_id: parseFloat(this.$route.params.id),
          exp_date: this.exp_date,
          batch: batch_array,
          material_drop_lsit: this.material_drop_lsit
        }
        this.test = data;
        process.addStep3(data).then((response) => {
          if (response.status == 200 && response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.$router.push("/prm/processing_steps/" + this.$route.params.id);
                // window.location.reload();
              }
            });
          } else if (response.status == 200 && response.data.successful === false) {
            let timerInterval;
            Swal.fire({
              title: "คำเตือน",
              icon: "warnning",
              html: "ป้อนวัตถุดิบที่เสียหายหรือทิ้งไปจากการแปรรูปเกินจำนวนวัตถุดิบที่ต้องการแปรรูป",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
          }
        })
      }

    },
    total_price(){
      this.step3_total = 0
      this.step_1_list.forEach(item => {
          this.step3_total += parseFloat(item.qty_after_process == null || item.qty_after_process == '' ? 0 : item.qty_after_process)
      })
    }
  },
  mounted() {
    this.getStepOne();
    this.getStepTwo();
  },
  computed: {
    formattedDate() {
      const [datePart] = this.exp_date.split(" ");
      const [year, month, day] = datePart.split("-");
      return `${year}-${month}-${day}`;
    },
  },
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}

.tag-title-step {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}

.inline-box-specific {
  background: #fff8e8;
}

.inline-box-specific-sub {
  background: #d6d3cf;
}

/* Scroll Table */
.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}

.scroll-menu-last .last {
  display: inline-block;
  vertical-align: top;
  margin-right: 1.75%;
}

.scroll-menu-last section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  bottom: auto;
  margin-right: 1.5%;
  padding-right: 25px;
  background: #FFFFFF;
  z-index: 1;
}

.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding-bottom: 15px;
  width: 110%;
  height: 99%;
}

.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: 110%;
}

.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}

.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  max-width: 100%;
  width: 200px;
  height: 100%;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.footer-total {
  position: absolute;
  bottom: 1%;
  border-top: 2px solid #ffbc15;
  background-color: #fff2d0;
  padding: 17px 22px 0 22px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
}

.footer-gams {
  background-color: #000000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
  inline-size: fit-content;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: baseline;
}

.custom-label-color>>>.v-label--active {
  background-color: #FFFFFF;
  padding-left: 7px;
  padding-right: 7px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}</style>