<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">จัดการข้อมูลผู้ดูแลระบบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card class="card-radius" elevation="4">
                    <v-card-title>
                        <p class="team-text-title-card">รายการผู้ดูแลระบบ</p>
                    </v-card-title>
                    <v-card-text>
                        <v-btn class="tab-button-disable ma-2" type="button" large outlined color="#909090"
                            @click="link('/teams/teams')">ทีมงาน</v-btn>
                        <v-btn class="tab-button active ma-2" type="button" elevation="2" color="#FFBC15" large
                            outlined>ผู้ดูแลระบบ</v-btn>
                        <v-row>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" 
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                <div class="d-flex justify-end">
                                    <v-btn class="mt-2" large rounded outlined color="#88B358" @click="teams_add()" v-if="$store.state.profileDetail.per_user == 1 || $store.state.profileDetail.permission == 1">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มผู้ดูแลระบบ
                                    </v-btn>
                                    <v-btn class="mt-2" v-else large rounded  color="#dcdcdc">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มผู้ดูแลระบบ
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-data-text"></div>
                                    <div class="table-header-data-text">
                                        ชื่อผู้ดูแลระบบ
                                    </div>
                                    <div class="table-header-data-text">
                                        เบอร์โทร
                                    </div>
                                    <div class="table-header-data-text">
                                        สถานะ
                                    </div>
                                    <div class="table-header-data-text">
                                        รีเซ็ตรหัสผ่าน
                                    </div>
                                    <div class="table-header-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in data"
                                    :key="index">
                                    <div class="grid-items">
                                        <div class="w-border"></div>
                                        <div>{{ detail.name }}</div>
                                        <div>{{ detail.tel }}</div>
                                        <div>
                                            <v-switch class="d-inline-block" color="#4CAF50" v-model="detail.status" v-if="$store.state.profileDetail.per_user_status == 1 || $store.state.profileDetail.permission == 1"
                                                @click="changeStatus(detail.id)" inset></v-switch>
                                            <v-switch class="d-inline-block" color="#4CAF50" v-model="detail.status" disabled="" v-else inset></v-switch>
                                        </div>
                                        <div>
                                            <v-btn v-if="$store.state.profileDetail.per_user_reset_password == 1 || $store.state.profileDetail.permission == 1" class="mr-2 radius-15" color="#A68156" @click="reset_password(detail.id)"
                                                large outlined>
                                                <v-icon left>mdi-lock-reset</v-icon>
                                                <div>รีเซ็ตรหัสผ่าน</div>
                                            </v-btn>
                                            <v-btn v-else class="mr-2 radius-15" color="#dcdcdc" 
                                                large >
                                                <v-icon left>mdi-lock-reset</v-icon>
                                                <div>รีเซ็ตรหัสผ่าน</div>
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#6C7FD8"
                                                            @click="teams_detail(detail.id)">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียด
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#FEA06C"
                                                            @click="teams_edit(detail.id)">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#F28080"
                                                            @click="teams_delete(detail.id)">
                                                            <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                            ลบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- เพิ่มแอดมิน -->
        <v-dialog v-model="add_dialog" transition="dialog-top-transition" max-width="800" style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>เพิ่มข้อมูลผู้ดูแลระบบ</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="add_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-form ref="form_add" @submit.prevent="teamsAddSuccess()">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-account
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลส่วนตัว</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อ - นามสกุล"
                                                v-model="user_detail.name" color="#FFBC15"
                                                :rules="[v => !!v || 'กรุณากรอกชื่อ - นามสกุล']" hide-details="auto"
                                                 dense outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius"
                                                label="เบอร์โทรศัพท์สำหรับเข้าสู่ระบบ" v-model="user_detail.tel" value=""
                                                color="#FFBC15" minlength="10" maxlength="10"
                                                @keypress="isNumber($event, user_detail.tel)"
                                                :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']" hide-details="auto"
                                                 dense outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการเพิ่มแอดมิน -->
        <!-- แก้ไขแอดมิน -->
        <v-dialog v-model="edit_dialog" transition="dialog-top-transition" max-width="800" style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b class="ml-5">แก้ไขข้อมูลผู้ดูแลระบบ</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="edit_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด :
                        <span class="text-fullname-title">{{ user_detail.user_edit }}</span> <span
                            class="text-date-title">วันที่ :
                        </span>
                        <span class="text-datetime-title">{{ user_detail.updated_at }}</span>
                    </div>
                    <v-form ref="form_edit" @submit.prevent="teamsEditSuccess()">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-account
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลส่วนตัว</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อ - นามสกุล"
                                                v-model="user_detail.name" color="#FFBC15"
                                                :rules="[v => !!v || 'กรุณากรอกชื่อ - นามสกุล']" hide-details="auto"
                                                 outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius"
                                                label="เบอร์โทรศัพท์สำหรับเข้าสู่ระบบ" v-model="user_detail.tel"
                                                color="#FFBC15" minlength="10" maxlength="10"
                                                @keypress="isNumber($event, user_detail.tel)"
                                                :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']" hide-details="auto"
                                                 outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดแก้ไขแอดมิน -->
        <!-- ดูรายละเอียดแอดมิน -->
        <v-dialog v-model="detail_dialog" transition="dialog-top-transition" max-width="800" style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-space-between mb-5">
                    <v-card-title>
                        <b>รายละเอียดผู้ดูแลระบบ</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="detail_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-card elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center ">
                            <v-avatar class="top-to-card" color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-account
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 mt-3 text-font-title">ข้อมูลส่วนตัว</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                    <div class="ml-3 text-name-title">ชื่อ - สกุล :
                                        <span class="text-fullname-title">
                                            {{ user_detail.name }}
                                        </span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                    <div class="ml-3 text-name-title">เบอร์โทรสำหรับเข้าสู่ระบบ :
                                        <span class="text-fullname-title">{{ user_detail.tel }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-8" elevation="4" style="border-radius:20px;">
                        <v-card-title class="d-flex align-items-center">
                            <v-avatar class="top-to-card" color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-key
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 mt-3 text-font-title">สิทธิ์การเข้าถึง</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" color="#686868"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">เข้าถึงรายงาน</span>
                                    </div>
                                </v-col>

                                <!-- ทีมงาน -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">เข้าเมนูจัดการพนักงาน</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">เปลี่ยนสถานะ</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">รีเซ็ตรหัสผ่าน</span>
                                    </div>
                                </v-col>

                                <!-- คลัง -->
                                <!-- <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12"
                                    v-if="permissionCheckbox.per_inventory == true">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">คลังสินค้า</span>
                                    </div>
                                </v-col> -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">คลัง</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">รายการวัตถุดิบ</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">รายงานการตรวจสอบ</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ดูรายงานคลัง</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">นำวัตถุดิบเข้าคลัง</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">นำสินค้าเข้าคลัง</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">รายงานการตรวจสอบคุณภาพ</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">สร้างใบสั่งซื้อวัตถุดิบ</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่าข้อมูลคลัง</span>
                                    </div>
                                </v-col>

                                <!-- ตั้งค่าข้อมูลสินค้า -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่าข้อมูลตั้งค่าประเภทสินค้า</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่าข้อมูสินค้า</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่าขนาดสินค้า</span>
                                    </div>
                                </v-col>

                                <!-- ตั้งค่าข้อมูลวัตถุดิบ -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่าซัพพลายเออร์</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่าข้อมูวัตถุดิบ</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่ากลุ่มวัตถุดิบ</span>
                                    </div>
                                </v-col>

                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                                    </div>
                                </v-col>

                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">เกณฑ์การตรวจสอบการผลิต</span>
                                    </div>
                                </v-col>

                                <!-- ตั้งค่าข้อมูลบอื่นๆ -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">หน่วยนับ</span>
                                    </div>
                                </v-col>
                                <!-- <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12"
                                    v-if="permissionCheckbox.per_box == true">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">กล่องขนส่ง</span>
                                    </div>
                                </v-col> -->

                                <!-- การผลิตและการแปรรูป และบรรจุภัณน์ -->
                                <!-- การแปรรูป -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">จัดการสั่งแปรรูป</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">จัดการยกเลิกแปรรูป</span>
                                    </div>
                                </v-col>

                                <!-- การผลิต -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">จัดการสั่งผลิต</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">จัดการยกเลิกผลิต</span>
                                    </div>
                                </v-col>

                                 <!-- การบรรจุภัฒน์ -->
                                 <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">จัดการบรรจุภัณฑ์</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">จัดการยกเลิกบรรจุภัณฑ์</span>
                                    </div>
                                </v-col>

                                <!-- การขาย -->
                                <!-- <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12"
                                    v-if="permissionCheckbox.per_customer == true">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">จัดการข้อมูลลูกค้า</span>
                                    </div>
                                </v-col> -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">เพิ่มข้อมูลลูกค้าใหม่</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">แก้ไขข้อมูลลูกค้า</span>
                                    </div>
                                </v-col>

                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">สร้างคำสั่งซื้อใหม่</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">แก้ไขคำสั่งซื้อ</span>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ยกเลิกคำสั่งซื้อ</span>
                                    </div>
                                </v-col>

                                <!-- ตั้งค่าข้อมูลบริษัท -->
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <div class="d-flex align-items-center">
                                        <v-icon left color="#FFBC15">mdi-check</v-icon>
                                        <span class="text-name-title">ตั้งค่าข้อมูลบริษัท</span>
                                    </div>
                                </v-col>

                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดดูรายละเอียดแอดมิน -->
        <!-- รีเซ็ตรหัสผ่าน -->
        <v-dialog v-model="resetpassword_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="resetpassword_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/resetpassword.png">
                                <h2 style="color:#000000">รีเซ็ตรหัสผ่าน</h2>
                                <h3 class="mt-2">คุณต้องการรีเซ็ตรหัสผ่านใช่หรือไม่</h3>
                                <v-btn type="button" class="btn-reset-pasword" @click="resetPasswordSuccess()" elevation="4"
                                    rounded x-large>
                                    <v-icon style="font-size:28px;" left>mdi-lock-reset</v-icon>
                                    ยืนยันรีเซ็ตรหัสผ่าน
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:16px;" text @click="resetpassword_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดรีเซ็ตรหัสผ่าน -->
        <!-- ลบข้อมูล -->
        <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn type="button" class="btn-delete-data" @click="teamsDeleteSuccess()" elevation="4"
                                    rounded x-large>
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:16px;" text @click="confirm_delete_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->
    </div>
</template>
<script>
import { isTelephone } from "@/helper/handler";
import { dateFormatNoSeconds } from "@/helper/dateformats";
import users from '@/api/users/users.js'
import Swal from 'sweetalert2'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: true,
            loading_detail_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'ผู้ดูแลระบบ',
                    disabled: false,
                }
            ],
            page: 1,
            pageSize: 1,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,
            resetpassword_dialog: false,
            confirm_delete_dialog: false,
            user_detail: {
                id: null,
                name: '',
                tel: '',
                user_edit: null,
                updated_at: null,
            },
            data: [],
            search: '',
        }
    },

    methods: {
        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
        link(link) {
            this.$router.push(link)
        },
        async userList() {
            this.data = []
            users.userList(1, this.page, '', this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.userList
                    this.pageSize = response.data.pages
                     this.page = parseInt(response.data.currentPage)
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async teams_add() {
            this.loading_detail_page = false
            this.add_dialog = true
            this.user_detail = {
                id: null,
                name: '',
                tel: '',
                user_edit: null,
                updated_at: null,
            }
        },
        async teamsAddSuccess() {
            if (this.$refs.form_add.validate()) {
                this.loading_detail_page = true
                const data = {
                    name: this.user_detail.name,
                    tel: this.user_detail.tel,
                    permission: 1
                }
                users.addUser(data).then((response) => {
                    if (response.data.successful === true) {
                        this.user_detail = {
                            id: null,
                            name: '',
                            tel: '',
                            user_edit: null,
                            updated_at: null,
                        }
                        this.add_dialog = false
                        this.loading_detail_page = false
                        this.alert_message_success = 'เพิ่มผู้ดูแลระบบเรียบร้อยแล้ว'
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.userList()
                    }
                    if (response.data.successful === false) {
                        if (response.data.message == 'This phone number has already been taken') {
                             Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'warning',
                            html: 'เบอร์โทรนี้มีผู้ใช้งานแล้ว',
                            confirmButtonText: 'ตกลง'
                        })
                        }
                        if (response.data.message == 'This name has already been taken') {
                             Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'warning',
                            html: 'ชื่อ - นามสกุลนี้มีผู้ใช้งานแล้ว',
                            confirmButtonText: 'ตกลง'
                        })
                        }
                    }
                    this.loading_detail_page = false
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
        async teams_edit(id) {
            this.loading_detail_page = true
            this.edit_dialog = true
            this.user_detail = {
                id: null,
                name: '',
                tel: '',
                user_edit: null,
                updated_at: null,
            }
            users.userDetail(id).then((response) => {
                if (response.data.successful === true) {
                    this.user_detail.id = response.data.userDetail.id
                    this.user_detail.name = response.data.userDetail.name
                    this.user_detail.tel = response.data.userDetail.tel
                    this.user_detail.user_edit = response.data.userDetail.user_edit ? response.data.userDetail.user_edit : '-'
                    this.user_detail.updated_at = response.data.userDetail.user_edit ? dateFormatNoSeconds(response.data.userDetail.updated_at) : '-'
                    this.loading_detail_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async teamsEditSuccess() {
            if (this.$refs.form_edit.validate()) {
                this.loading_detail_page = true
                const data = {
                    name: this.user_detail.name,
                    tel: this.user_detail.tel,
                }
                users.editUser(this.user_detail.id, data).then((response) => {
                    if (response.data.successful === true) {
                        this.user_detail = {
                            id: null,
                            name: '',
                            tel: '',
                            user_edit: null,
                            updated_at: null,
                        }
                        this.edit_dialog = false
                        this.loading_detail_page = false
                        this.alert_message_success = 'แก้ไขผู้ดูแลระบบเรียบร้อยแล้ว'
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.userList()
                    }
                    if (response.data.successful === false) {
                        if (response.data.message == 'This phone number has already been taken') {
                            this.alert_message_error = 'เบอร์โทรนี้มีผู้ใช้งานแล้ว'
                            this.alert_error = true
                            this.loading_detail_page = false
                            setTimeout(() => this.alert_error = false, 5000)
                        }
                        if (response.data.message == 'This name has already been taken') {
                            this.alert_message_error = 'ชื่อ - นามสกุลนี้มีผู้ใช้งานแล้ว'
                            this.alert_error = true
                            this.loading_detail_page = false
                            setTimeout(() => this.alert_error = false, 5000)
                        }
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
        async teams_detail(id) {
            this.loading_detail_page = true
            this.detail_dialog = true
            this.user_detail = {
                id: null,
                name: '',
                tel: '',
                user_edit: null,
                updated_at: null,
            }
            users.userDetail(id).then((response) => {
                if (response.data.successful === true) {
                    this.user_detail.id = response.data.userDetail.id
                    this.user_detail.name = response.data.userDetail.name
                    this.user_detail.tel = response.data.userDetail.tel
                    this.loading_detail_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async teams_delete(id) {
            this.loading_detail_page = false
            this.confirm_delete_dialog = true
            this.user_detail = {
                id: null,
                name: '',
                tel: '',
                user_edit: null,
                updated_at: null,
            }
            this.user_detail.id = id
        },
        async teamsDeleteSuccess() {
            this.loading_detail_page = true
            users.deleteUser(this.user_detail.id).then((response) => {
                if (response.data.successful === true) {
                    this.user_detail = {
                        id: null,
                        name: '',
                        tel: '',
                        user_edit: null,
                        updated_at: null,
                    }
                    this.confirm_delete_dialog = false
                    this.loading_detail_page = false
                    this.alert_message_success = 'ลบผู้ดูแลระบบเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                    this.userList()
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async changeStatus(id) {
            users.changeStatus(id).then((response) => {
                if (response.data.successful === true) {
                    this.alert_message_success = 'เปลี่ยนสถานะเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async reset_password(id) {
            this.resetpassword_dialog = true
            this.user_detail = {
                id: null,
                name: '',
                tel: '',
                user_edit: null,
                updated_at: null,
            }
            this.user_detail.id = id
        },
        async resetPasswordSuccess() {
            this.loading_detail_page = true
            users.resetPasswordSuccess(this.user_detail.id).then((response) => {
                if (response.data.successful === true) {
                    this.resetpassword_dialog = false
                    this.user_detail = {
                        id: null,
                        name: '',
                        tel: '',
                        user_edit: null,
                        updated_at: null,
                    }
                    this.resetpassword_dialog = false
                    this.loading_detail_page = false
                    this.alert_message_success = 'รีเซ็ตรหัสผ่านเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
    },
    mounted() {
        this.userList()
    },
    watch: {
        page() {
            this.userList()
        },
        search(){
            this.page = 1 
            this.userList()
        },
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}
</style>