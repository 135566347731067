<template>
    <div>
        <Warehouse_page_unpackaged_detail_history_raw_material_audit_procress />
    </div>
</template>
  
<script>
import Warehouse_page_unpackaged_detail_history_raw_material_audit_procress from '@/components/stock/warehouse_stock/WarehouseUnpackagedDetailHistoryRawMaterialAuditProcress'
export default {
    name: 'WarehouseUnpackagedDetailHistoryRawMaterialAuditProcress',
    components: {
        Warehouse_page_unpackaged_detail_history_raw_material_audit_procress,
    }
}
</script>