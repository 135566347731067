<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">คลังสินค้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>คลังสินค้า</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="">
                                    <v-btn class="mt-2 mx-5 radius-15 text-grey text-16" elevation="" large color="#fff"
                                        @click="link('/stock/warehouse-stock/warehouse')">
                                        คลังวัตถุดิบ
                                    </v-btn>
                                    <v-btn class="mt-2 mx-5 radius-15 text-grey text-16" elevation="" large color="#fff"
                                        @click="link('/stock/warehouse-stock/warehouse-unpackaged')">
                                        คลังสินค้าที่ยังไม่ได้บรรจุภัณฑ์
                                    </v-btn>
                                    <v-btn class="mt-2 ml-0 mx-5 radius-15 text-16" elevation="2" large outlined
                                        color="#FFBC15">
                                        คลังสินค้าที่บรรจุภัณฑ์แล้วคงเหลือ
                                    </v-btn>

                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search" prepend-inner-icon="mdi-magnify"
                                     v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-warehouse-box-data display-pc"
                                    style="color: #b1adad;">
                                    <div class="table-header-warehouse-box-data-text"></div>
                                    <!-- <div class="table-header-warehouse-box-data-text">
                                        ชื่อสินค้า
                                    </div> -->
                                    <div class="table-header-warehouse-box-data-text ml-0">
                                        <!-- v-for="(detail, index_size_list) in size_list" :key="index_size_list" -->
                                        <!-- {{ detail.pro_size_name }} -->
                                        ชื่อสินค้า
                                    </div>
                                    <div class="table-header-warehouse-box-data-text">
                                        จำนวนคงเหลือ
                                    </div>
                                    <div class="table-header-warehouse-box-data-text">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in ware_house_data"
                                    :key="index">
                                    <div class="grid-warehouse-box-items grid-warehouse-box-loop " >
                                        <div class="w-border"></div>
                                        <!-- <div></div> -->
                                        
                                        <div>
                                            <v-row style="padding: 25px 0 25px 0;">
                                                <v-col cols="3" class="px-3 d-flex align-center">
                                                    {{ detail.pro_name }}
                                                </v-col>
                                                <v-col cols="9" style="display: contents;">
                                                    <v-div class="text-center ml-5" v-for="(detailSize, index_size_list) in detail.size_list" :key="index_size_list"   >
                                                        {{ detailSize.pro_size_name }} <br> 
                                                        <v-divider class="my-2" color="#d9d9d9"></v-divider>
                                                        {{ formatPrice(detailSize.instock) }} {{ detailSize.unit_name }}
                                                    </v-div>
                                                </v-col>
                                            </v-row>
                                            <!-- <v-row class="scrollable-content">
                                                
                                            </v-row> -->
                                        </div>

                                        <div class="text-center">
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#6C7FD8"
                                                            @click="link('/stock/warehouse-stock/warehouse-packaged-detail/' + detail.id)">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียดสินค้า
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#34230F"
                                                            @click="link('/stock/warehouse-stock/warehouse-packaged-history-raw-material/' + detail.id)">
                                                            <v-icon left style="color:#34230F">mdi-clock</v-icon>
                                                            ประวัติสินค้า
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link v-if="$store.state.profileDetail.per_inventory_report == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item-title style="color:#88B358"
                                                            @click="link('/stock/warehouse-stock/warehouse-packaged-raw-material-audit-inspection/' + detail.id)">
                                                            <v-icon left style="color:#88B358">mdi-calendar-month</v-icon>
                                                            รายงานการตรวจสอบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                        
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="all_page" :total-visible="all_page"
                                    @input="getProductList()" color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import product from '@/api/warehouse/product.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
            ],

            ware_house_data: [],
            size_list: [],
            page: 1,
            all_page: 1,
            search: '',
            type: 1,
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        getProductList(page_cilck) {
          
            product.getProductList(page_cilck ? page_cilck : this.page, '', this.search, this.type).then((response) => {
                if (response.data.successful === true) {
                    this.ware_house_data = response.data.data;
                    this.all_page = response.data.pages;
                    this.page = parseFloat(page_cilck ? page_cilck :response.data.currentPage);
                }
            })

        },
        getProductSizeList() {
            product.getProductSizeList().then((response) => {
                if (response.data.successful === true) {
                    this.size_list = response.data.data;
                }
            })
        }
    },
    mounted() {
        this.getProductList();
        this.getProductSizeList();
    },
    components: {
        navigationmenu
    },
    created() {
    },
    watch:{
        search(){
            this.getProductList(1)
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-warehouse-box-data-1 {
    display: grid;
    grid-template-columns: 2% 35% 38% 25%;
    padding: 5px 0px 5px 30px;
}

.grid-warehouse-box-items-1 {
    display: grid;
    grid-template-columns: 5% 36% 46% 13%;
    align-items: center;
}

.table-header-warehouse-box-data-2 {
    display: grid;
    grid-template-columns: 2% 34% 21% 21% 22%;
    padding: 5px 0px 5px 30px;
}

.grid-warehouse-box-items-2 {
    display: grid;
    grid-template-columns: 5% 34% 21% 29% 11%;
    align-items: center;
}

.table-header-warehouse-box-data-3 {
    display: grid;
    grid-template-columns: 2% 28% 18% 18% 18% 16%;
    padding: 5px 0px 5px 30px;
}

.grid-warehouse-box-items-3 {
    display: grid;
    grid-template-columns: 5% 29% 18% 17% 23% 8%;
    align-items: center;
}

.table-header-warehouse-box-data-4 {
    display: grid;
    grid-template-columns: 2% 25% 14% 14% 14% 14% 17%;
    padding: 5px 0px 5px 30px;
}

.grid-warehouse-box-items-4 {
    display: grid;
    grid-template-columns: 5% 26% 14% 14% 14% 18% 9%;
    align-items: center;
}


.table-header-warehouse-box-data-5 {
    display: grid;
    grid-template-columns: 2% 22% 13% 13% 13% 13% 13% 11%;
    padding: 5px 0px 5px 30px;
}

.grid-warehouse-box-items-5 {
    display: grid;
    grid-template-columns: 5% 23% 13% 13% 13% 13% 14% 6%;
    align-items: center;
}

.table-header-warehouse-box-data {
    display: grid;
    grid-template-columns:  1% 22% 70% 7%;
    padding: 5px 0px 5px 30px;
}

.grid-warehouse-box-items {
    display: grid;
    grid-template-columns:  3% 88% 9%;
    align-items: center;
}

.table-header-warehouse-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.scrollable-content {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}
.card-table-radius:nth-child(odd)>.grid-warehouse-box-items>.w-border, .card-table-radius:nth-child(odd)>.grid-warehouse-box-loop>.w-border
{
    height: 92px;
    background: #34230F;
    border: 1px solid #34230F;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 36px;
    width: 20px;
}
.card-table-radius:nth-child(even)>.grid-warehouse-box-items>.w-border, .card-table-radius:nth-child(even)>.grid-warehouse-box-loop>.w-border
{
    height: 92px;
    background: #FFBC15;
    border: 1px solid #FFBC15;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 36px;
    width: 20px;
}
</style>