<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ประวัติวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>สต๊อกสินค้า</b>
                            </v-col>
                            <v-col cols="6 text-right">
                                <b>Krispy Rice MILK Flavor 63 ถุง</b>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-btn @click="link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterial')" class="ma-2 tab-button active "
                                    type="button" elevation="2"
                                    color="#FFBC15"  large
                                    outlined>240 กรัม(g)</v-btn>
                                <v-btn @click="link('')" class="ma-2 tab-button-disable"
                                    type="button"  large outlined
                                    color="#909090">500 กรัม(g)</v-btn>
                                    <v-btn @click="link('')" class="ma-2 tab-button-disable"
                                    type="button"  large outlined
                                    color="#909090">1,000 กรัม(g)</v-btn>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3"
                                    prepend-inner-icon="mdi-magnify" placeholder="ค้นหาที่นี่..." hide-details="auto"
                                    single-line outlined dense></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12">
                                <v-select :items="data_date" item-text="value" item-value="id"
                                    class="input-radius-search mt-3" prepend-inner-icon="mdi-calendar" label="ปี"
                                    hide-details="auto" outlined dense></v-select>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="left-fixed-columns" :headers="headers" :items="data_list"
                            :footer-props="footer_Props" mobile-breakpoint="0" st>
                            <template v-slot:item="dataDetail">
                                <tr style="white-space: nowrap;">
                                    <td>
                                        <div>{{ dataDetail.item.date }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.lot }}</div>
                                    </td>
                                    <td>
                                        <div style="color:#88B358">{{ dataDetail.item.manufacture }}</div>
                                    </td>
                                    <td>
                                        <div style="color:#F28080">{{ dataDetail.item.disbursement }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.operator }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.remaining }}</div>
                                    </td>
                                    <td>
                                        <div>{{ dataDetail.item.note }}</div>
                                    </td>

                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency} from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังวัตถุดิบ',
                    disabled: false,
                },
                {
                    text: 'นมข้นจืด',
                    disabled: false,
                },
                {
                    text: 'ประวัติวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            headers: [
                {
                    text: 'วันที่',
                    align: 'start',
                    sortable: false,
                    value: '',
                    width: '150px',
                },
                {
                    text: 'LOT',
                    align: 'start',
                    sortable: false,
                    value: '',
                    width: '200px',
                    class:'fixed_header'
                },
                {
                    text: 'ผลิต จำนวน(ถุง)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: '',
                },
                {
                    text: 'เบิกจ่าย จำนวน(ถุง)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: '',
                },
                {
                    text: 'ผู้ดำเนินการ',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: '',
                },
                {
                    text: 'คงเหลือ จำนวน(ถุง)',
                    align: 'start',
                    sortable: false,
                    width: '150px',
                    value: '',
                },
                {
                    text: 'หมายเหตุ',
                    align: 'start',
                    sortable: false,
                    width: '200px',
                    value: '',
                },

            ],
            data_list: [
                {
                    date: '05/09/2022',
                    lot: '3-202209051351',
                    manufacture: '100',
                    disbursement: '',
                    operator: 'พงศ์กมล',
                    remaining: '15',
                    note: 'PK-202209002',
                },
                {
                    date: '05/09/2022',
                    lot: '3-202209051351',
                    manufacture: '',
                    disbursement: '37',
                    operator: 'พงศ์กมล',
                    remaining: '90',
                    note: 'PO-202209001PO',
                },
            ],
            data_date: [
                {
                    id: 1,
                    value: 'ปี 2023',
                },
                {
                    id: 2,
                    value: 'ปี 2022',
                },
                {
                    id: 3,
                    value: 'ปี 2021',
                },
            ],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
    },
    mounted() {

    },
    components: {
        navigationmenu
    },
    created() {

    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.left-fixed-columns .v-data-table__wrapper {
    overflow-x: auto;
    position: relative;
}

.left-fixed-columns .v-data-table__wrapper table {
    position: relative;
}

</style>