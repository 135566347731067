<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายละเอียดวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>


        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายละเอียดวัตถุดิบ</b>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex justify-space-between align-items-center pb-1">
                                <div>
                                    <v-avatar class="top-to-card" color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-package-variant-closed
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">ข้อมูลวัตถุดิบ</span>
                                </div>
                                <div>
                                    <div class="mt-2 ml-5" style="color:#000;font-size:18px;">
                                        {{ detail.mat_status == 1 ? 'วัตถุดิบปกติ' : detail.mat_status == 2 ?
                                            'วัตถุดิบแปรรูป' : 'บรรจุภัณฑ์' }}
                                    </div>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="mt-2 ml-5" style="color:#000;font-size:18px;">
                                            รูปภาพวัตถุดิบ
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="image_preview text-center">
                                                    <img v-if="detail.mat_pic" :src="detail.mat_pic"
                                                        style="width:218px;max-width:218px;">
                                                    <img v-else :src="imagePreviewURL" style="width:218px;max-width:218px;">
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">ชื่อวัตถุดิบ :
                                                    <span class="text-fullname-title">{{ detail.mat_name }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">บาร์โค้ด :
                                                    <span class="text-fullname-title">{{ detail.barcode }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">กลุ่มวัตถุดิบ :
                                                    <span class="text-fullname-title">{{ detail.mat_type_name }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">ซัพพลายเออร์ :
                                                    <span class="text-fullname-title">{{ detail.mat_status === 1 || detail.mat_status === 3 ? detail.sup_name : '-' }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">จำนวนขั้นต่ำในคลัง :
                                                    <span class="text-fullname-title">{{ formatPrice(parseFloatToInt(detail.min)) }} {{
                                                        detail.unit_main_name }}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">จำนวนสูงสุดในคลัง :
                                                    <span class="text-fullname-title">{{ formatPrice(parseFloatToInt(detail.max)) }} {{
                                                        detail.unit_main_name }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-currency-usd
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-5 bold text-font-title">ข้อมูลหน่วยนับและราคาซื้อ</span>
                            </v-card-title>
                            <v-card-text class="px-10 pb-10">
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                                            <v-card-title>
                                                <v-btn rounded color="#FFBC15" outlined dark>
                                                    {{ detail.unit_main_name }}
                                                </v-btn>
                                                <span class="ml-3 f-14">หน่วยนับหลัก</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <div
                                                    style="border:1px solid #FFBC15;background:#FFBC15;border-radius: 5px;height:5px;width:100%">
                                                </div>
                                                <div class="mt-3"
                                                    style="padding:10px;border: 1px solid #FFBC15;border-radius: 20px;">
                                                    <v-row class="mt-1">
                                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6"
                                                            v-if="detail.mat_status === 1 || detail.mat_status === 3">
                                                            <div>
                                                                <p class="f-16" style="color:#686868;">ราคาซื้อ</p>
                                                                <p class="f-16" style="color:#000;">{{
                                                                    formatPrice(detail.retail_price)
                                                                }} บาท</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6"
                                                            v-if="detail.mat_status === 1 || detail.mat_status === 2">
                                                            <div>
                                                                <p class="f-16" style="color:#686868;">1 {{
                                                                    detail.unit_main_name }}
                                                                    เท่ากับ กี่กรัม</p>
                                                                <p class="f-16" style="color:#000;">{{
                                                                    formatPrice(detail.unit_main_gram)
                                                                }} กรัม</p>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12"
                                        v-if="detail.mat_status === 1 || detail.mat_status === 3">
                                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                                            <v-card-title>
                                                <v-btn rounded color="#34230F" outlined dark>
                                                    {{ detail.unit_other_name }}
                                                </v-btn>
                                                <span class="ml-3 f-14">หน่วยนับอื่น ๆ</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <div
                                                    style="border:1px solid #34230F;background:#34230F;border-radius: 5px;height:5px;width:100%">
                                                </div>
                                                <div class="mt-3"
                                                    style="padding:10px;border: 1px solid #34230F;border-radius: 20px;">
                                                    <v-row class="mt-1">
                                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                                            <div>
                                                                <p class="f-16" style="color:#686868;">ราคาซื้อ</p>
                                                                <p class="f-16" style="color:#000;">{{
                                                                    formatPrice(detail.other_price) }}
                                                                    บาท</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                                            <div>
                                                                <p class="f-16" style="color:#686868;">1 {{
                                                                    detail.unit_other_name }} เท่ากับ
                                                                    กี่{{ detail.unit_main_name }}</p>
                                                                <p class="f-16" style="color:#000;">{{ detail.unit_other_qty
                                                                }} {{ detail.unit_main_name }}</p>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text v-if="detail.mat_status === 2">

                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-2">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-cog
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-5 text-font-title bold">ข้อมูลการแปรรูป</span>
                            </v-card-title>
                            <v-card-text class="px-10 pb-10">
                                <v-divider class="mb-3" color="#686868"></v-divider>

                                <v-row>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div>ปริมาณที่ได้จากสูตรการผลิต : {{ formatPrice(detail.formula_amount) }} กรัม</div>
                                        <v-card elevation="4" style="border-radius:20px;" class="mt-3"
                                            v-for="(group, index) in formula" :key="index">
                                            <v-card-title>
                                                <v-btn rounded color="#FFBC15" outlined dark>
                                                    ส่วนที่ {{ group.group }}
                                                </v-btn>
                                                <span class="ml-3 f-14">ส่วนการแปรรูป</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <div
                                                    style="border:1px solid #FFBC15;background:#FFBC15;border-radius: 5px;height:5px;width:100%">
                                                </div>
                                                <div class="mt-3"
                                                    style="padding:10px;border: 1px solid #FFBC15;border-radius: 20px;">
                                                    <v-row class="mt-1">
                                                        <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                                                            <div>
                                                                <div class="f-14" style="color:#686868;">วัตถุดิบ</div>
                                                                <div class="f-14 mb-1" style="color:#000;"
                                                                    v-for="(matName, index) in group.list" :key="index">
                                                                    {{ matName.mat_for_name }}</div>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
                                                            <div>
                                                                <div class="f-14" style="color:#686868;">
                                                                    จำนวนวัตถุดิบในสูตร</div>
                                                                <div class="f-14 mb-1" style="color:#000;"
                                                                    v-for="(matQty, index) in group.list" :key="index">{{
                                                                        formatPrice(matQty.mat_for_qty) }} กรัม</div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <!-- quality_criteria_list -->
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-5 bold text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                            </v-card-title>
                            <v-card-text class="px-8">
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pb-0">
                                        <div class="table-header-quality-box-data display-pc" style="color: #b1adad;">
                                            <div class="table-header-qc-box-data-text">
                                                ชื่อเกณฑ์ (English)
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                ชื่อเกณฑ์ (ภาษาไทย)
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                Unit
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                Aim
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                Green
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                Yellow
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                Red
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                Value
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                ค่าอัตโนมัติ
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="">
                                        <v-expansion-panels>
                                            <v-expansion-panel
                                                v-for="(quality_criteria_data, index) in quality_criteria_list" :key="index"
                                                :class="{ border_left_yellow: quality_criteria_data.isActive }"
                                                class="radius-10 mt-3" @click="toggleActive(index)">
                                                <v-expansion-panel-header class="radius-10 border-yellow">
                                                    <div class="">
                                                        <span class="mr-10">{{ quality_criteria_data.pq_name_eng }}</span>
                                                        <span class="ml-10"> {{ quality_criteria_data.pq_name_thai }}</span>
                                                    </div>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="">
                                                    <div class="mt-0"
                                                        v-for="(quality_criteria_sub_data, index_sub) in quality_criteria_data.list"
                                                        :key="index_sub">
                                                        <div class="table-header-quality-box-data display-pc mt-0"
                                                            style="color: #b1adad;">
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ quality_criteria_sub_data.pq_list_eng }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ quality_criteria_sub_data.pq_list_thai }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ quality_criteria_sub_data.unit }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ quality_criteria_sub_data.pq_list_aim }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ quality_criteria_sub_data.pq_list_green }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ quality_criteria_sub_data.pq_list_yellow }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ quality_criteria_sub_data.pq_list_red }}
                                                            </div>
                                                            <!-- <div class="table-header-qc-box-data-text"
                                                                v-if="quality_criteria_sub_data.pq_list_type == 0">
                                                                <v-icon color="#88b358">mdi-check-circle</v-icon>
                                                            </div> -->
                                                            <div class="table-header-qc-box-data-text">
                                                                <v-icon v-if="quality_criteria_sub_data.pq_list_type == 0 && quality_criteria_sub_data.pq_value_status == 1 && quality_criteria_sub_data.pq_value_boolean == 1" color="#88B358">mdi-check-circle</v-icon>
                                                                <v-icon v-else-if="quality_criteria_sub_data.pq_list_type == 0 && quality_criteria_sub_data.pq_value_status == 1 && quality_criteria_sub_data.pq_value_boolean == 0" color="error">mdi-close-circle</v-icon>
                                                                <div v-else-if="quality_criteria_sub_data.pq_list_type == 1">{{ quality_criteria_sub_data.pq_value }}</div>
                                                            </div>

                                                            <div class="table-header-qc-box-data-text" v-if="quality_criteria_sub_data.pq_value_status === 1">
                                                                <v-icon
                                                                    color="#FFBC15">mdi-check-circle</v-icon>
                                                            </div>
                                                            <div class="table-header-qc-box-data-text" v-else>
                                                                <v-icon
                                                                    color="#F28080">mdi-close-circle</v-icon>
                                                            </div>
                                                            <!-- <div class="table-header-qc-box-data-text">
                                                                <v-icon color="#88b358">mdi-check-circle</v-icon>
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                <v-icon color="#FFBC15">mdi-check-circle</v-icon>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <!-- production_criteria_list -->
                    <v-card-text v-if="detail.mat_status === 2">
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-check-decagram
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-5 bold text-font-title">เกณฑ์การตรวจสอบการผลิต</span>
                            </v-card-title>
                            <v-card-text class="px-8">
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pb-0">
                                        <div class="table-header-production-box-data display-pc" style="color: #b1adad;">
                                            <div class="table-header-qc-box-data-text">
                                                ชื่อเกณฑ์
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                ระยะค่าในเกณฑ์
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                Value
                                            </div>
                                            <div class="table-header-qc-box-data-text">
                                                ค่าอัตโนมัติ
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="">
                                        <v-expansion-panels>
                                            <v-expansion-panel
                                                v-for="(production_criteria_data, index) in production_criteria_list"
                                                :key="index"
                                                :class="{ border_left_yellow: production_criteria_data.isActive }"
                                                class="radius-10 mt-3" @click="toggleActive_production(index)">
                                                <v-expansion-panel-header class="radius-10 border-yellow">

                                                    {{ production_criteria_data.fq_name }}

                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="">
                                                    <div class=""
                                                        v-for="(production_criteria_sub_data, index_sub) in production_criteria_data.list"
                                                        :key="index_sub">
                                                        <div class="table-header-production-box-data display-pc "
                                                            style="color: #b1adad;">
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ production_criteria_sub_data.fq_list_name }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ production_criteria_sub_data.fq_list_guide }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                {{ production_criteria_sub_data.fq_value }}
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                <v-icon
                                                                    v-if="production_criteria_sub_data.fq_value_status === 1"
                                                                    color="#FFBC15">mdi-check-circle</v-icon>
                                                            </div>
                                                            <!-- <div class="table-header-qc-box-data-text">
                                                                <v-icon color="#88b358">mdi-check-circle</v-icon>
                                                            </div>
                                                            <div class="table-header-qc-box-data-text">
                                                                <v-icon color="#FFBC15">mdi-check-circle</v-icon>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { isTelephone, formatPrice, onlyForCurrency } from "@/helper/handler";
import imagePreviewURL from "@/assets/rice (1).png";
import material from '@/api/warehouse/materials.js'
export default {
    data() {
        return {
            loading_page: false,
            imagePreviewURL: imagePreviewURL,
            stepImport: 1,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลวัตถุดิบ',
                    disabled: false,
                    href: "/stock/setting-stock/setting-raw-material",
                },
                {
                    text: 'ตั้งค่าวัตถุดิบ',
                    disabled: false,
                    href: "/stock/setting-stock/setting-raw-material",
                },
                {
                    text: 'รายละเอียดวัตถุดิบ',
                    disabled: false,
                },
            ],
            unit: [
                { id: 1, name: 'แพ็ค 5' },
                { id: 2, name: 'แพ็ค 10' },
                { id: 3, name: 'แพ็ค 15' },
            ],
            tab: null,
            items: [
                'PO20220321-001', 'PO20220321-002', 'PO20220321-003', 'PO20220321-004'
            ],
            detail: [],
            quality_criteria_list: [
                {
                    quality_head_en: 'Steaming',
                    quality_head_th: 'การนึ่ง',
                    isActive: false,
                    quality_criteria_detail: [
                        {
                            quality_sub_en: 'Steaming temp',
                            quality_sub_th: 'อุณหภูมิการนึ่ง',
                            quality_sub_unit: 'C',
                            quality_sub_aim: '100',
                            quality_sub_green: '90-110',
                            quality_sub_yellow: '<90 >110',
                            quality_sub_red: '-',
                            quality_sub_value: '',
                            quality_sub_auto: '',
                        }
                    ]
                },
                {
                    quality_head_en: 'Sensory',
                    quality_head_th: 'ชิมข้าวนึ่ง',
                    quality_criteria_detail: [
                        {
                            quality_sub_en: 'Sensory',
                            quality_sub_th: 'ชิมข้าวนึ่ง',
                            quality_sub_unit: 'C',
                            quality_sub_aim: '100',
                            quality_sub_green: '90-110',
                            quality_sub_yellow: '<90 >110',
                            quality_sub_red: '-',
                            quality_sub_value: '',
                            quality_sub_auto: '',
                        }
                    ]
                }
            ],
            production_criteria_list: [
                {
                    production_head_name: 'Fryer',
                    isActive: false,
                    production_criteria_detail: [
                        {
                            production_sub_name: 'Time',
                            production_sub_range: '200-220 C',
                            production_sub_unit: '',
                            production_sub_auto: '',
                        },
                        {
                            production_sub_name: 'Time',
                            production_sub_range: '5.0-7.0 Sec',
                            production_sub_unit: '',
                            production_sub_auto: '',
                        },
                        {
                            production_sub_name: 'Polar (<25)',
                            production_sub_range: '-',
                            production_sub_unit: 'ผ่าน',
                            production_sub_auto: 1,
                        },
                        {
                            production_sub_name: 'Appearance',
                            production_sub_range: 'ไม่ไหม้ สีสม่ำเสมอ',
                            production_sub_unit: 'ผ่าน',
                            production_sub_auto: 1,
                        }
                    ]
                },

            ],
            formula: [],

            menu: false,
            modal: false,
            menu2: false,
            toggle_exclusive: 2,
            page: 1,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,
        }
    },

    methods: {
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        link(link) {
            this.$router.push(link)
        },
        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
        async materialList() {
            material.detail(this.$route.params.id).then((response) => {
                this.detail = response.data.mat_detail
                this.quality_criteria_list = response.data.mat_pd_quality
                this.production_criteria_list = response.data.mat_fac_quality
                this.arrayConvertFormat(response.data.mat_formula)
            })
        },
        toggleActive(index) {
            let clickedButton = this.quality_criteria_list[index];
            if (!clickedButton.isActive) {
                this.quality_criteria_list.forEach((loops) => {
                    loops.isActive = false;
                });
            }
            clickedButton.isActive = !clickedButton.isActive;
            if (this.quality_criteria_list.every((loops) => !loops.isActive)) {
                this.quality_criteria_list.forEach((loops) => {
                    loops.isActive = false;
                });
            }
        },
        toggleActive_production(index) {
            let clickedButton = this.production_criteria_list[index];
            if (!clickedButton.isActive) {
                this.production_criteria_list.forEach((loops) => {
                    loops.isActive = false;
                });
            }
            clickedButton.isActive = !clickedButton.isActive;
            if (this.production_criteria_list.every((loops) => !loops.isActive)) {
                this.production_criteria_list.forEach((loops) => {
                    loops.isActive = false;
                });
            }
        },
        parseFloatToInt(value) {
            return parseFloat(value);
        },
        async arrayConvertFormat(formulaArr) {
            this.formula = formulaArr.reduce((result, item) => {
                const groupIndex = result.findIndex(group => group.group === item.mat_group);

                if (groupIndex !== -1) {
                    result[groupIndex].list.push({
                        mat_for_name: item.mat_for_name,
                        mat_for_qty: item.mat_for_qty
                    });
                } else {
                    result.push({
                        group: item.mat_group,
                        list: [{
                            mat_for_name: item.mat_for_name,
                            mat_for_qty: item.mat_for_qty
                        }]
                    });
                }
                return result;
            }, []);
        }
    },
    mounted() {
        this.materialList()
    }
}
</script>
<style scoped>
.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.input-group {
    display: flex;
    gap: 10px;
}

.form-select {
    width: 150px;
}

.image_preview>.v-avatar {
    overflow: unset;
}

.upload_image {
    position: absolute;
    top: 0;
    right: 0;
}

.form-control {
    flex: 1;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.padding-text-title {
    padding: 8px !important;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 20px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-qc-box-data {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding: 10px;
}

.table-header-qc-box-data-text {
    color: #686868;
    font-size: 14px;
}

.table-header-quality-box-data {
    display: grid;
    grid-template-columns: 15% 15% 10% 10% 10% 10% 10% 10% 10%;
    padding: 10px;
}

.table-header-production-box-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 10px;
}

.grid-qc-box-items {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    padding: 15px;
}


.border-yellow {
    border-bottom: 1px solid #FFBC15;
}

.border_left_yellow {
    border-left: 4px solid #FFBC15;
    border-top: 1px solid #FFBC15;
    border-bottom: 1px solid #FFBC15;
    border-right: 1px solid #FFBC15;
    border-radius: 10px;
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 0 10px 15px !important;
}
</style>