<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายงานการตรวจสอบคุณภาพวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>รายงานการตรวจสอบคุณภาพวัตถุดิบ</b>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-btn @click="link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterial/'+$route.params.id)"
                            class="ma-2 tab-button active" type="button" color="#FFBC15" elevation="2" large
                            outlined>รายงานการตรวจสอบคุณภาพ</v-btn>
                        <v-btn @click="link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterialProcess/'+$route.params.id)"
                            class="ma-2 tab-button-disable" type="button" large outlined
                            color="#909090">รายงานการตรวจสอบคุณภาพการแปรรูป</v-btn>
                        <!-- <v-btn @click="link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction')"
                            class="ma-2 tab-button-disable" type="button" large outlined
                            color="#909090">รายงานการตรวจสอบคุณภาพการผลิต</v-btn> -->
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center ">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-file
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายละเอียด {{meterial_data.mat_name}} LOT {{meterial_data.material_lot}}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">เลขที่ใบสั่งแปรรูป :
                                            <span color="6C7FD8" class="text-fullname-title text-blud"><a
                                                target="_blank" :href="`/prm/production_confirm/${meterial_data.process_id}/0`">{{meterial_data.process_code}}</a></span>
                                        </div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">ผู้ดำเนินการ :
                                            <span class="text-fullname-title">{{meterial_data.user_name}}</span>
                                        </div>
                                    </v-col>


                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card class="mt-8" elevation="4" style="border-radius:20px;overflow-wrap: unset !important;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายการตรวจสอบคุณภาพ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pb-0">
                                        <div style="width: 100%;">
                                            <div class="table-header-lotoutstock-data display-pc pb-0 "
                                                style="color: #b1adad;">
                                                <div class="table-header-lotoutstock-data-text pb-0"></div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    ชื่อเกณฑ์ (English)
                                                </div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    ชื่อเกณฑ์ (ภาษาไทย)
                                                </div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    Unit
                                                </div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    <span class="text-aim"> Aim </span>
                                                </div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    <span class="text-green"> Green </span>
                                                </div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    <span class="text-yellow"> Yellow </span>
                                                </div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    <span class="text-red"> Red </span>
                                                </div>
                                                <div class="table-header-lotoutstock-data-text pb-0">
                                                    Value
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                                        v-for="(quality_loop, index) in quality_list" :key="index">
                                        <v-card elevation="2" class=" display-pc b-radius-20 py-4 mx-5 mb-3">
                                            <div class="table-header-lotoutstock-data pb-2">
                                                <div class="table-header-lotoutstock-data-text"></div>
                                                <div class="table-header-lotoutstock-data-text bold">{{
                                                    quality_loop.pq_name_eng }}</div>
                                                <div class="table-header-lotoutstock-data-text bold">{{
                                                    quality_loop.pq_name_thai }}</div>
                                            </div>

                                            <div class="mx-5 ">
                                                <v-divider class="mb-2" color="#686868"></v-divider>
                                            </div>

                                            <div class="table-header-lotoutstock-data pb-0"
                                                v-for="(quality_loop_list, index_sub) in quality_loop.quality_list"
                                                :key="index_sub">
                                                <div class="table-header-lotoutstock-data-text"></div>
                                                <div class="table-header-lotoutstock-data-text">{{
                                                    quality_loop_list.pq_list_eng }}</div>
                                                <div class="table-header-lotoutstock-data-text">{{
                                                    quality_loop_list.pq_list_thai }}</div>
                                                <div class="table-header-lotoutstock-data-text">{{
                                                    quality_loop_list.unit }}</div>
                                                <div class="table-header-lotoutstock-data-text">{{ quality_loop_list.pq_list_aim
                                                }}</div>
                                                <div class="table-header-lotoutstock-data-text">{{
                                                    quality_loop_list.pq_list_green }}</div>
                                                <div class="table-header-lotoutstock-data-text">{{
                                                    quality_loop_list.pq_list_yellow }}</div>
                                                <div class="table-header-lotoutstock-data-text">{{ quality_loop_list.pq_list_red
                                                }}</div>
                                                <div class="table-header-lotoutstock-data-text text-center">
                                                    <v-icon v-if="quality_loop_list.pq_list_type == 0 && quality_loop_list.pq_value_status == 1 && quality_loop_list.pq_value_boolean == 1" color="#88B358">mdi-check-circle</v-icon>
                                                    <v-icon v-else-if="quality_loop_list.pq_list_type == 0 && quality_loop_list.pq_value_status == 0 && quality_loop_list.pq_value_boolean == null" color="#88B358">mdi-check-circle</v-icon>
                                                    <v-icon v-else-if="quality_loop_list.pq_list_type == 0 && quality_loop_list.pq_value_status == 0 && quality_loop_list.pq_value_boolean == 1" color="#88B358">mdi-check-circle</v-icon>
                                                    <v-icon v-else-if="quality_loop_list.pq_list_type == 0 && quality_loop_list.pq_value_status == 0 && quality_loop_list.pq_value_boolean == 0" color="error">mdi-close-circle</v-icon>
                                                    <v-icon v-else-if="quality_loop_list.pq_list_type == 0 && quality_loop_list.pq_value_status == 1 && quality_loop_list.pq_value_boolean == 0" color="error">mdi-close-circle</v-icon>
                                                    <div v-else-if="quality_loop_list.pq_list_type == 1">{{ quality_loop_list.value }}</div>
                                                </div>
                                                           
                                               
                                                <!-- <div class="table-header-lotoutstock-data-text">
                                                    <v-icon color="#88B358">mdi-check-circle</v-icon>
                                                </div> -->
                                            </div>

                                        </v-card>
                                    </v-col>


                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>

    </div>
</template>
<script>
import { onlyForCurrency} from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import meterial from '@/api/warehouse/materials.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',

            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังวัตถุดิบ',
                    disabled: false,
                },
                {
                    text: '(นมข้นจืด)ดึงข้อมูล',
                    disabled: false,
                },
                {
                    text: 'รายละเอียดวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
                {
                    text: '(LOT 1-202209051351)ดึงข้อมูล',
                    disabled: false,
                    href: '#',
                },
                {
                    text: 'รายงานการตรวจสอบคุณภาพวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            items: [
                {
                    id: 1,
                    name: 'กล่องไปรษณีย์',
                },
                {
                    id: 2,
                    name: 'กล่องของบริษัท',
                },
                {
                    id: 3,
                    name: 'กล่องลูกค้า',
                }
            ],
            page: 1,
            data: [],
            quality_list: [],
            meterial_data: {}
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
         meterialLotOut() {
             this.loading_page = true;
            let id = parseFloat(this.$route.params.id);
            meterial.meterialLotOut(id, '0').then((response) => {
                if(response.data.successful === true){
                    this.meterial_data = response.data.data;
                    this.quality_list = response.data.quality;
                    this.sub_menu[2].text = this.meterial_data.mat_name
                    this.sub_menu[4].text = 'LOT - ' + this.meterial_data.material_lot
                    this.loading_page = false;
                }
            })
          
        },
    },
    mounted() {
        this.meterialLotOut();
    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.text-aim {
    color: #FFB98A;
}

.text-green {
    color: #00B050;
}

.text-yellow {
    color: #DBB84D;
}

.text-red {
    color: #C00000
}

.text-blud {
    color: #6C7FD8
}

.b-radius-20 {
    border-radius: 20px;
}

.table-header-lotoutstock-data {
    display: grid;
    grid-template-columns: 3% 23% 23% 8% 8% 8% 8% 8% 10%;
    padding: 10px;
}

.table-header-lotoutstock-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-lotoutstock-items {
    display: grid;
    grid-template-columns: 3% 23% 23% 8% 8% 8% 8% 8% 10%;
    align-items: center;
    padding: 15px;
}
</style>