import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    orderList: (page, size, search) => {


        return new Promise((resolve, reject) => {
            HTTP.get(`/Order/listOrder?page=${page}&size=${size}&search=${search}`, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderDetail: (id, type) => {
        return new Promise((resolve, reject) => {
            HTTP.get(`/Order/get/detail/${id}?type=${type}`, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderProductList: () => {
        return new Promise((resolve, reject) => {
            HTTP.get('/product/get/create/order', {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderProductAdd: (data) => {
        return new Promise((resolve, reject) => {
            HTTP.post('/Order/orderAdd', data, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderProductLot: (id) => {
        return new Promise((resolve, reject) => {
            HTTP.get('/Order/get/lot/' + id, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderCancel: (data) => {
        return new Promise((resolve, reject) => {
            HTTP.post('/Order/cancel', data, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderDetailLotEdit: (data) => {
        return new Promise((resolve, reject) => {
            HTTP.post('/order/update/lot', data, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderGetEdit: (id) => {
        return new Promise((resolve, reject) => {
            HTTP.get('/Order/get/edit/' + id, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderProductEdit: (data) => {
        return new Promise((resolve, reject) => {
            HTTP.post('/Order/update', data, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    orderUpdateBox: (data) => {
        return new Promise((resolve, reject) => {
            HTTP.post('/order/update/box', data, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });

    },
    orderUpdateShipping: (id) => {
        return new Promise((resolve, reject) => {
            HTTP.get('/Order/confirm/order/success/' + id, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });

    },
    orderRefresh(id) {
        return new Promise((resolve, reject) => {
          HTTP.get("/Order/re/create/stock/cut/" + id, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
    boxRefresh(id) {
        return new Promise((resolve, reject) => {
          HTTP.get("/Order/re/create/box/cut/" + id, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
}