<template>
    <div>
        <OrderEditPage />
    </div>
</template>
  
<script>
import OrderEditPage from '@/components/sale/OrderEdit'
export default {
    name: 'Orderedit',
    components: {
        OrderEditPage,
    }
}
</script>