import { HTTP } from "@/axios.js";
import store from "@/store/index.js";
export default {
    getProductSelectbox() {
    return new Promise((resolve, reject) => {
      HTTP.get(`/product/list/for/import`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
    add(data) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/importpo/import/product/create`, data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
};
