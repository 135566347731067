
<template>
    <div>
        <SetProductDetailPage />
    </div>
</template>
  
<script>
import SetProductDetailPage from '@/components/stock/setting_stock/SetProductDetail'
export default {
    name: 'SetProductDetail',
    components: {
        SetProductDetailPage,
    }
}
</script>