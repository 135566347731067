<template>
  <div id="app">
    <router-view></router-view>
  </div>
  <!-- overflow: inherit; -->
</template>
  
<script>
export default {
  name: 'App',

};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap");

html,
body,
#app {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  background: #f7f7f9;
}

.v-main__wrap {
  margin-top: 150px;
}

.background-page-login {
  background-image: url('~@/assets/background_login.png');
  background-size: cover;
}

.flex {
  display: flex;
}

.loading_all_page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  background: rgba(0, 0, 0, 50%);
  color: #FFF;
}

.bold {
  font-weight: bold !important;
}

.border-yellow-all {
  border: 1px solid #FFBC15 !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-24 {
  font-size: 24px !important;
}

.btn-delete-data {
  font-size: 14px !important;
  margin-top: 20px;
  background-color: #F28080 !important;
  color: #FFFFFF !important;
}

.alert_all_page {
  margin: 0;
  position: fixed !important;
  z-index: 9999;
  top: 15px;
  right: 5px;
}

.v-dialog {
  border-radius: 20px !important;
}

.text-error {
  color: #f44336;
}

.input-radius {
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
}

.input-radius-search {
  font-size: 18px;
  font-weight: 500;
  border-radius: 15px !important;
}

.card-radius {
  border-radius: 20px !important;
  padding: 15px !important;
}

.card-radius-bg-cycle {
  background: #F2F5FC !important;
  border-radius: 20px !important;
  padding: 15px !important;
}

.title-card-text {
  font-size: 18px !important;
}

.title_page {
  display: flex;
  align-items: center;
}

.title_page .breadcrumb_title_page {
  font-size: 14px;
  font-weight: 600;
  border-right: 2px #000000 solid;
  padding-right: 24px;
}

.title_page h4.breadcrumb_title_page {
  font-size: 24px !important;
  font-weight: 500;
}

.v-breadcrumbs__item {
  color: #444444 !important;
  font-weight: 500;
  font-size: 14px;
}

.icon-image {
  width: 60px;
  height: 60px;
  text-align: center;
}

.div-submit-data {
  display: flex;
  justify-content: center !important;

}

.button-submit-data {
  color: #FFF !important;
  font-weight: 600;
  font-size: 14px !important;
}

.button-submit-data span.v-btn__content {
  padding: 20px !important;
}

.button-cancel-data {
  color: #FFF;
  font-weight: 600;
  font-size: 14px !important;
}

.button-cancel-data span.v-btn__content {
  padding: 20px !important;
}

.title-table-data-list {
  font-style: normal;
  font-weight: 600;
  font-size: 15px !important;
  line-height: 21px;
  color: #585B6B !important;
  border-bottom: unset !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-15 {
  border-radius: 15px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.display-pc {
  display: grid !important;
}

.text-name-title {
  color: #686868;
}

.text-fullname-title {
  color: #000000;
}

.f-black {
  color: #000000;
}

.text-name-title,
.text-fullname-title {
  font-size: 16px;
}

.table-header-data {
  display: grid;
  grid-template-columns: 1% 20% 19% 21% 18% 21%;
  padding: 5px 0px 5px 30px;

}

.table-header-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-items {
  display: grid;
  grid-template-columns: 4% 20% 19% 20% 26% 11%;
  align-items: center;
}

.card-table-radius {
  border-top: 1px solid #D9D9D9;
  border-radius: 15px 20px !important;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  margin-bottom: 10px;
}

.left-fixed-columns .v-data-table__wrapper table thead>tr>th:first-child {
  position: sticky;
  left: 0;
  background-color: #FFF;
  z-index: 1;
}

.left-fixed-columns .v-data-table__wrapper table thead>tr>th:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: #FFF;
  z-index: 1;
}


.left-fixed-columns .v-data-table__wrapper table td:first-child {
  position: sticky;
  left: 0;
  background-color: #FFF;
  z-index: 1;
}

.left-fixed-columns .v-data-table__wrapper table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: #FFF;
  z-index: 1;
}


.card-table-radius:nth-child(even)>.grid-items>.w-border,
.card-table-radius:nth-child(even)>.grid-unit-items>.w-border,
.card-table-radius:nth-child(even)>.grid-transport-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-quality-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-production-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-supplier-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-warehousedetail-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-warehousedetail-box-loop>.w-border,
.card-table-radius:nth-child(even)>.grid-warehouse-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-warehouse-box-loop>.w-border,
.card-table-radius:nth-child(even)>.grid-material-items>.w-border,
.card-table-radius:nth-child(even)>.grid-raw-material-type-items>.w-border,
.card-table-radius:nth-child(even)>.grid-product-size-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-customer-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-WarehouseRawmaterial-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-accounting-setting-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-order-list-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-processing-items>.w-border {
  height: 67px;
  background: #FFBC15;
  border: 1px solid #FFBC15;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.card-table-radius:nth-child(even)>.grid-setraw-box-items>.w-border,
.card-table-radius:nth-child(even)>.grid-setproduct-box-items>.w-border {
  height: 81px;
  background: #FFBC15;
  border: 1px solid #FFBC15;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.card-table-radius:nth-child(odd)>.grid-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-unit-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-transport-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-quality-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-supplier-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-warehouse-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-warehouse-box-loop>.w-border,
.card-table-radius:nth-child(odd)>.grid-WarehouseRawmaterial-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-warehousedetail-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-warehousedetail-box-loop>.w-border,
.card-table-radius:nth-child(odd)>.grid-production-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-material-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-raw-material-type-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-product-size-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-customer-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-setraw-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-accounting-setting-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-order-list-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-processing-items>.w-border {
  height: 67px;
  background: #34230F;
  border: 1px solid #34230F;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.card-table-radius:nth-child(odd)>.grid-setraw-box-items>.w-border,
.card-table-radius:nth-child(odd)>.grid-setproduct-box-items>.w-border {
  height: 81px;
  background: #34230F;
  border: 1px solid #34230F;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.text-font-title {
  font-size: 18px;
}

.main-menu {
  z-index: 10;
  position: absolute;
  display: table-cell;
  height: 100%;
  overflow: hidden;
  margin: 165px 0 0 0px;
  z-index: 1;
}

.main-menu-content {
  background-color: transparent !important;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: transparent !important;
}

.main-menu.menu-light {
  color: #626262;
}

.main-menu.menu-light .main-menu-header {
  padding: 20px;
}

.main-menu.menu-light .main-menu-header .menu-search {
  background: #e6e6e6;
  padding: 0.5rem 1rem;
  color: #494949;
}

.main-menu.menu-light .main-menu-header .menu-search:focus {
  border-color: #d9d9d9;
}

.main-menu.menu-light .navigation .navigation-header {
  color: #999999;
  margin: calc(2.2rem - 0.2rem) 0 0.8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
  letter-spacing: .01rem;
}

.main-menu.menu-light .navigation .navigation-header span {
  font-weight: 500;
}

.main-menu.menu-light .navigation li.has-sub ul.menu-content {
  padding: 7px;
  margin: -7px -7px 0 -7px;
}

.main-menu.menu-light .navigation li a {
  color: #7D7D7D;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
}

.main-menu.menu-light .navigation li a span.menu-sub-title {
  color: #7D7D7D;
}

.main-menu.menu-light .navigation li.hover>a {
  padding-left: 25px;
  transition: padding 0.35s ease 0s !important;
}

.main-menu.menu-light .navigation>li {
  padding: 0 15px;
}

.main-menu.menu-light .navigation>li>a .label {
  margin-top: 4px;
  margin-right: 5px;
}

.main-menu.menu-light .navigation>li>a i {
  position: relative;
  top: 1px;
}

.main-menu.menu-light .navigation>li.open>a,
.main-menu.menu-light .navigation>li.sidebar-group-active>a {
  color: #494949;
  background: whitesmoke;
  transition: transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s;
  border-radius: 6px;
  margin-bottom: 7px;
}

.main-menu.menu-light .navigation>li:not(.open)>ul {
  display: none;
}

.main-menu.menu-light .navigation>li.active>a {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  color: #fff;
  font-weight: 400;
  border-radius: 4px;
}

.main-menu.menu-light .navigation>li .active {
  background: #f0f0f0;
}

.main-menu.menu-light .navigation>li .active>a {
  color: #7367f0;
  margin-bottom: 0;
}

.main-menu.menu-light .navigation>li .active .hover>a {
  background: whitesmoke;
}

.main-menu.menu-light .navigation>li ul {
  padding: 0;
  margin: 0;
}

.main-menu.menu-light .navigation>li ul li {
  color: #626262;
  background: transparent;
}

.main-menu.menu-light .navigation>li ul li>a {
  padding: 10px 15px 10px 20px;
}

.main-menu.menu-light .navigation>li ul .has-sub:not(.open)>ul {
  display: none;
}

.main-menu.menu-light .navigation>li ul .open>a,
.main-menu.menu-light .navigation>li ul .sidebar-group-active>a {
  color: #626262;
}

.main-menu.menu-light .navigation>li ul .open>ul,
.main-menu.menu-light .navigation>li ul .sidebar-group-active>ul {
  display: block;
}

.main-menu.menu-light .navigation>li ul .open>ul .open>ul,
.main-menu.menu-light .navigation>li ul .sidebar-group-active>ul .open>ul {
  display: block;
}

.main-menu.menu-light .navigation>li ul .hover>a,
.main-menu.menu-light .navigation>li ul:hover>a {
  color: #6c6c6c;
}

.main-menu.menu-light .navigation>li ul .active {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  border-radius: 4px;
}

.main-menu.menu-light .navigation>li ul .active>a {
  color: #fff;
}

.main-menu.menu-light .navigation>li>ul {
  background: #fff;
}

.main-menu.menu-light ul.menu-popout {
  background: #fff;
}

.main-menu.menu-light ul.menu-popout li a {
  color: #626262;
}

.main-menu.menu-light ul.menu-popout li a span.menu-sub-title {
  color: #afafaf;
}

.main-menu.menu-light ul.menu-popout li.hover>a,
.main-menu.menu-light ul.menu-popout li:hover>a,
.main-menu.menu-light ul.menu-popout li.open>a {
  background: #f0f0f0;
}

.main-menu.menu-light ul.menu-popout .has-sub:not(.open)>ul {
  display: none;
}

.main-menu.menu-light ul.menu-popout .open>a {
  color: #626262;
}

.main-menu.menu-light ul.menu-popout .open>ul {
  display: block;
}

.main-menu.menu-light ul.menu-popout .open>ul .open {
  background: #e8e8e8;
}

.main-menu.menu-light ul.menu-popout .open>ul .open>ul {
  display: block;
  background: #e8e8e8;
}

.main-menu.menu-light ul.menu-popout .hover>a,
.main-menu.menu-light ul.menu-popout:hover>a {
  color: #6c6c6c;
  background-color: whitesmoke;
}

.main-menu.menu-light ul.menu-popout .active {
  background: rgba(0, 0, 0, 0.06);
}

.main-menu.menu-light ul.menu-popout .active>a {
  color: #7c7c7c;
  background-color: whitesmoke;
}

.main-menu.menu-light ul.menu-popout .active .hover>a,
.main-menu.menu-light ul.menu-popout .active :hover>a {
  background-color: transparent;
}

.main-menu.menu-dark {
  color: #dcdcdc;
  background: #10163a;
}

.main-menu.menu-dark .main-menu-header {
  padding: 20px;
}

.main-menu.menu-dark .main-menu-header .menu-search {
  background: #050712;
  padding: 0.5rem 1rem;
  color: #c3c3c3;
}

.main-menu.menu-dark .main-menu-header .menu-search:focus {
  border-color: black;
}

.main-menu.menu-dark .navigation {
  background: #10163a;
}

.main-menu.menu-dark .navigation .navigation-header {
  color: white;
  margin: calc(2.2rem - 0.2rem) 0 0.8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
  letter-spacing: .01rem;
}

.main-menu.menu-dark .navigation .navigation-header span {
  font-weight: 500;
}

.main-menu.menu-dark .navigation li.has-sub ul.menu-content {
  padding: 7px;
  margin: -7px -7px 0 -7px;
}

.main-menu.menu-dark .navigation li a {
  color: #d0d0d0;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
}

.main-menu.menu-dark .navigation li a span.menu-sub-title {
  color: white;
}

.main-menu.menu-dark .navigation li.hover>a {
  padding-left: 25px;
  transition: padding 0.35s ease 0s !important;
}

.main-menu.menu-dark .navigation>li {
  padding: 0 15px;
}

.main-menu.menu-dark .navigation>li>a .label {
  margin-top: 4px;
  margin-right: 5px;
}

.main-menu.menu-dark .navigation>li>a i {
  position: relative;
  top: 1px;
}

.main-menu.menu-dark .navigation>li.open>a,
.main-menu.menu-dark .navigation>li.sidebar-group-active>a {
  color: #c3c3c3;
  background: #0c102a;
  transition: transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s;
  border-radius: 6px;
  margin-bottom: 7px;
}

.main-menu.menu-dark .navigation>li:not(.open)>ul {
  display: none;
}

.main-menu.menu-dark .navigation>li.active>a {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  color: #fff;
  font-weight: 400;
  border-radius: 4px;
}

.main-menu.menu-dark .navigation>li .active {
  background: #090d22;
}

.main-menu.menu-dark .navigation>li .active>a {
  color: #7367f0;
  margin-bottom: 0;
}

.main-menu.menu-dark .navigation>li .active .hover>a {
  background: #0c102a;
}

.main-menu.menu-dark .navigation>li ul {
  padding: 0;
  margin: 0;
}

.main-menu.menu-dark .navigation>li ul li {
  color: #dcdcdc;
  background: transparent;
}

.main-menu.menu-dark .navigation>li ul li>a {
  padding: 10px 15px 10px 20px;
}

.main-menu.menu-dark .navigation>li ul .has-sub:not(.open)>ul {
  display: none;
}

.main-menu.menu-dark .navigation>li ul .open>a,
.main-menu.menu-dark .navigation>li ul .sidebar-group-active>a {
  color: #dcdcdc;
}

.main-menu.menu-dark .navigation>li ul .open>ul,
.main-menu.menu-dark .navigation>li ul .sidebar-group-active>ul {
  display: block;
}

.main-menu.menu-dark .navigation>li ul .open>ul .open>ul,
.main-menu.menu-dark .navigation>li ul .sidebar-group-active>ul .open>ul {
  display: block;
}

.main-menu.menu-dark .navigation>li ul .hover>a,
.main-menu.menu-dark .navigation>li ul:hover>a {
  color: #e6e6e6;
}

.main-menu.menu-dark .navigation>li ul .active {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  border-radius: 4px;
}

.main-menu.menu-dark .navigation>li ul .active>a {
  color: #fff;
}

.main-menu.menu-dark .navigation>li>ul {
  background: #10163a;
}

.main-menu.menu-dark ul.menu-popout {
  background: #10163a;
}

.main-menu.menu-dark ul.menu-popout li a {
  color: #dcdcdc;
}

.main-menu.menu-dark ul.menu-popout li a span.menu-sub-title {
  color: white;
}

.main-menu.menu-dark ul.menu-popout li.hover>a,
.main-menu.menu-dark ul.menu-popout li:hover>a,
.main-menu.menu-dark ul.menu-popout li.open>a {
  background: #090d22;
}

.main-menu.menu-dark ul.menu-popout .has-sub:not(.open)>ul {
  display: none;
}

.main-menu.menu-dark ul.menu-popout .open>a {
  color: #dcdcdc;
}

.main-menu.menu-dark ul.menu-popout .open>ul {
  display: block;
}

.main-menu.menu-dark ul.menu-popout .open>ul .open {
  background: #060816;
}

.main-menu.menu-dark ul.menu-popout .open>ul .open>ul {
  display: block;
  background: #060816;
}

.main-menu.menu-dark ul.menu-popout .hover>a,
.main-menu.menu-dark ul.menu-popout:hover>a {
  color: #e6e6e6;
  background-color: #0c102a;
}

.main-menu.menu-dark ul.menu-popout .active {
  background: rgba(0, 0, 0, 0.06);
}

.main-menu.menu-dark ul.menu-popout .active>a {
  color: #f6f6f6;
  background-color: #0c102a;
}

.main-menu.menu-dark ul.menu-popout .active .hover>a,
.main-menu.menu-dark ul.menu-popout .active :hover>a {
  background-color: transparent;
}

.main-menu.menu-fixed {
  position: fixed;
  /* fallback if needed */
}

.main-menu.menu-fixed .main-menu-footer {
  position: fixed;
}

.main-menu.menu-static {
  height: 100%;
  padding-bottom: calc(100% - 20rem);
}

.main-menu.menu-static .main-menu-content {
  height: unset !important;
}


.main-menu.menu-border {
  border-right: 1px solid #E4E7ED;
}

.main-menu .shadow-bottom {
  display: none;
  position: absolute;
  z-index: 2;
  height: 60px;
  width: 100%;
  pointer-events: none;
  margin-top: -1.3rem;
  filter: blur(5px);
  background: linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}

.main-menu.menu-native-scroll .main-menu-content {
  overflow-y: scroll;
}

.main-menu .navbar-header {
  height: 100%;
  width: 260px;
  height: 5rem;
  position: relative;
  padding: 0.35rem 1rem 0.3rem 1.64rem;
  transition: 300ms ease all;
}

.main-menu .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  margin-top: 1.35rem;
}

.main-menu .navbar-header .navbar-brand .brand-logo {

  background-position: -65px -54px;
  height: 24px;
  width: 35px;
}

.main-menu .navbar-header .navbar-brand .brand-text {
  color: #7367f0;
  padding-left: 1rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  font-size: 1.57rem;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.main-menu .navbar-header .modern-nav-toggle {
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.main-menu .main-menu-content {
  height: calc(100% - 6rem) !important;
  position: relative;
}

.main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-menu ul.navigation-main {
  overflow-x: hidden;
}

.main-menu ul.navigation-main>li:first-child {
  margin-top: .5rem;
}

.main-menu a {
  outline: none;
}

.main-menu a:hover,
.main-menu a:focus {
  text-decoration: none;
}

.top-menu-vertical {
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.not-bg {
  background: transparent !important;
}

.main-menu.main-menu-pc {
  display: flex;
}

.menu-left-ipad {
  display: flex !important;
}

.menu-left-pc {
  display: flex !important;
}

.grid_half {
  display: grid;
  grid-template-columns: 20% 20% !important;
}

.grid_accounting {
  display: grid;
  grid-template-columns: 20% 15% 15% !important;
}

.input-header-search {
  display: flex !important;
}

.font-18{
  font-size: 18px;
}

.font-16{
  font-size: 16px;
}

.font-14{
  font-size: 14px;
}

@media only screen and (min-width: 960px) {
  .responsive-content {
    margin-left: 220px;
  }

  .input-header-search {
    display: flex !important;
  }

  .menu-left-pc {
    display: flex !important;
  }

  .menu-left-ipad {
    display: none !important;
  }

  .main-menu.main-menu-pc {
    display: flex !important;
  }


  .nav-header-desktop {
    display: block !important;
  }

  .nav-header-ipad {
    display: none !important;
  }

  .grid_half {
    display: grid;
    grid-template-columns: 20% 20% !important;
  }

  .grid_accounting {
    display: grid;
    grid-template-columns: 20% 15% 15% !important;
  }

}

@media only screen and (max-width: 959.5px) {
  .grid_half {
    display: block;
  }

  .grid_accounting {
    display: block;
  }

  .input-header-search {
    display: block !important;
  }

  .responsive-content {
    margin-left: 50px;
  }

  .menu-left-pc {
    display: none !important;
  }

  .menu-left-ipad {
    display: flex !important;
  }

  .menu-left-ipad {
    z-index: 9;
    position: absolute;
    display: table-cell;
    height: 100%;
    overflow: hidden;
    margin: 0px 0px 0px 0px;
  }

  .v-main__wrap {
    margin-top: 57px;
  }

  .v-breadcrumbs {
    display: none !important;
  }

  .main-menu.main-menu-pc {
    display: none;
  }

  .main-menu {
    margin: 70px 0 0 30px;
  }

  .title_page .breadcrumb_title_page {
    border-right: 0px;
  }

  .nav-header-desktop {
    display: none !important;
  }

  .nav-header-ipad {
    display: flex !important;
  }
}

.text-grey {
  color: #686868 !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-20 {
  font-size: 20px !important;
}

.link-pointer {
  cursor: pointer;
}
</style>