<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular
      indeterminate
      size="70"
      :width="8"
      color="#FFBC15"
    ></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">นำเข้าวัตถุดิบ</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card class="card-radius" elevation="4">
          <v-card-title>
            <p class="team-text-title-card">รายการนำเข้าวัตถุดิบ</p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <v-text-field
                  color="#D9D9D9"
                  class="input-radius-search mt-3"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="ค้นหาที่นี่..."
                  hide-details="auto"
                  single-line
                  outlined
                  dense
                  ref="searchField"
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                <div class="d-flex justify-end">
                  <v-menu rounded="b-xl" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="$store.state.profileDetail.per_stock_in == 1 || $store.state.profileDetail.permission == 1"
                        class="mt-2"
                        large
                        rounded
                        outlined
                        color="#88B358"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left>mdi-plus</v-icon>
                        นำวัตถุดิบเข้าคลัง
                      </v-btn>
                      <v-btn v-else
                        class="mt-2"
                        large
                        rounded
                        color="#dcdcdc"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left>mdi-plus</v-icon>
                        นำวัตถุดิบเข้าคลัง
                      </v-btn>
                    </template>
                    <v-list v-if="$store.state.profileDetail.per_stock_in == 1 || $store.state.profileDetail.permission == 1">
                      <v-list-item
                        @click="
                          link('/stock/material-import-purchase-order-add')
                        "
                      >
                        <v-list-item-title class="f-14"
                          >นำเข้าจากใบสั่งซื้อ</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        @click="
                          link('/stock/material-create-purchase-order-add')
                        "
                      >
                        <v-list-item-title class="f-14"
                          >เพิ่มวัตถุดิบเข้าคลัง</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div
                  class="table-header-material-data display-pc"
                  style="color: #b1adad"
                >
                  <div class="table-header-material-data-text"></div>
                  <div class="table-header-material-data-text">
                    รหัสการนำเข้า
                  </div>
                  <div class="table-header-material-data-text">
                    วันที่นำเข้าวัตถุดิบ
                  </div>
                  <div class="table-header-material-data-text">ยอดสั่งซื้อ</div>
                  <div class="table-header-material-data-text">การนำเข้า</div>
                  <div class="table-header-material-data-text">
                    ผู้ดำเนินการ
                  </div>
                  <div class="table-header-material-data-text text-center">
                    จัดการ
                  </div>
                </div>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card
                  elevation="0"
                  class="card-table-radius"
                  v-for="(detail, index) in data"
                  :key="index"
                >
                  <div class="grid-material-items">
                    <div class="w-border"></div>
                    <div>{{ detail.im_code }}</div>
                    <div>{{ `${dateFormatNoSeconds(detail.created_at).split('/')[0]}-${dateFormatNoSeconds(detail.created_at).split('/')[1]}-${dateFormatNoSeconds(detail.created_at).split('/')[2]}` }}</div>
                    <div>{{ formatPrice(detail.total_price) }} บาท</div>
                    <div>{{ detail.po_code ? detail.po_code : "-" }}</div>
                    <div>{{ detail.name }}</div>
                    <div>
                      <v-menu rounded="b-xl" offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            elevation="0"
                            color="normal"
                            v-bind="attrs"
                            v-on="on"
                            fab
                            small
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            @click="
                            detail.po_code == null ?
                              link(
                                '/stock/material-import-purchase-order-detail/' +
                                  detail.id + '/1'
                              )
                              :
                              link(
                                '/stock/material-import-purchase-order-detail/' +
                                  detail.id + '/0'
                              )
                            "
                          >
                            <v-list-item-title
                              class="f-14"
                              style="color: #6c7fd8"
                            >
                              <v-icon left style="color: #6c7fd8"
                                >mdi-information-variant</v-icon
                              >
                              รายละเอียด
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="$store.state.profileDetail.per_stock_in == 1 || $store.state.profileDetail.permission == 1"
                            link
                            @click="detail.po_code == null ?  link('/stock/material-purchase-order-edit/'+detail.id) : link('/stock/material-import-purchase-order-edit/'+detail.id)"
                          >
                            <v-list-item-title
                              class="f-14"
                              style="color: #fea06c"
                            >
                              <v-icon left style="color: #fea06c"
                                >mdi-pencil</v-icon
                              >
                              แก้ไขข้อมูล
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </v-card>
                <div class="text-center mt-5">
                  <v-pagination
                    v-model="page"
                    :length="pages"
                    :total-visible="7"
                    color="#FFBC15"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { dateFormatNoSeconds } from "@/helper/dateformats";
import { isTelephone, formatPrice } from "@/helper/handler";
import importorder from "@/api/importrawmaterials/importorder.js";
export default {
  data() {
    return {
      loading_page: true,
      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "นำเข้าวัตถุดิบ",
          disabled: false,
          href: "#",
        },
      ],
      search: "",
      page: 1,
      size: 8,
      pages: 1,
      add_dialog: false,
      edit_dialog: false,
      detail_dialog: false,
      data: []
    };
  },

  methods: {
    dateFormatNoSeconds(dateTime) {
      const date = dateFormatNoSeconds(dateTime).split(" ");
      return date[0];
    },
    link(link) {
      this.$router.push(link);
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    async importpoListAll() {
      this.loading_page = true;
      this.data = [];
      await importorder.importpoListAll(
        this.page,
        this.size,
        this.search
      ).then(response => {
          if (response.data.successful == true) {
        this.data = response.data.List;
        this.pages = response.data.pages;
         this.page = parseFloat(response.data.currentPage)
        this.loading_page = false;
      }
      });
      
    },
    async searchData() {
      this.data = [];
      this.page = 1;
      await importorder.importpoListAll(
        this.page,
        this.size,
        this.search
      ).then(async response => {
          if (response.data.successful == true) {
        this.data = await response.data.List;
        this.pages = await response.data.pages;
         this.page = await parseFloat(response.data.currentPage)
      }
      });
    },
  },
  mounted() {
    this.importpoListAll();
  },
  watch: {
    page() {
      this.importpoListAll();
    },
    search() {
      this.searchData();
    },
  },
};
</script>
<style scoped>
.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 22px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-material-data {
  display: grid;
  grid-template-columns: 1% 18% 15% 15% 18% 15% 18%;
  padding: 5px 0px 5px 30px;
}

.table-header-material-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-material-items {
  display: grid;
  grid-template-columns: 4% 18% 15% 14% 18% 22% 9%;
  align-items: center;
}
</style>