<template>
    <div>
        <SettingRawMaterialDetailPage />
    </div>
</template>
  
<script>
import SettingRawMaterialDetailPage from '@/components/stock/setting_stock/SettingRawMaterialDetailComponent'
export default {
    name: 'SettingRawMaterialDetail',
    components: {
        SettingRawMaterialDetailPage,
    }
}
</script>