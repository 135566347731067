<template>
  <div>
    <ProductionProductGoodPackage/>
  </div>
</template>

<script>
import ProductionProductGoodPackage from "@/components/production_process/processing_product_good/ProductionProductGoodPackage";
export default {
  name: "ProductionProductGoodPackage_Page",
  components: {ProductionProductGoodPackage}
}
</script>

<style scoped>

</style>