import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    customerList: (page, size, search) => {
        return HTTP.get(`/customer/customerList?page=${page}&size=${size}&search=${search}` , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    customerListAll: () => {
        return HTTP.get(`/customer/List` , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    addCustomer: (data) => {
        return HTTP.post('/customer/addcustomer' , data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    customerDetail: (id) => {
        return HTTP.get('/customer/detailCustomer/'+id , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    EditCustomer: (data, id) => {
        return new Promise((resolve, reject) => {
            HTTP.post('/customer/editCustomer/' + id, data, {
                headers: {
                    Authorization: `Bearer ${store.state.access_token}`,
                },
            })
                .then((response) => {
                    const result = response;
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
}