<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OF-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <div class="d-flex justify-space-between ma-5">
          <h3>
            <b>ขั้นตอนที่ 3 : บันทึกผลการแปรรูป</b>
          </h3>
          <div class="d-flex">
            <div class="d-flex mr-3">
              <div class="mr-2" style="color: #686868">ผู้แก้ไขล่าสุด :</div>
              <div class="black--text">ชลิตวรรณ นาคทอง</div>
            </div>
            <div class="d-flex">
              <div class="mr-2" style="color: #686868">วันที่ :</div>
              <div class="black--text">02/01/2023 09:40</div>
            </div>
          </div>
        </div>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/contract (1).png">
              </v-avatar>
              <span class="ml-3 title-card-text">การบันทึกผลการแปรรูป</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <v-card-text>
                <div class="ml-15 mt-4">
                  <div class="tag-title-step">
                    <b class="black--text">การบันทึกเวลาในการแปรรูป</b>
                  </div>
                  <div class="divider-table my-2"/>
                  <div class="scroll-menu-last mt-4">
                    <section class="mb-3">
                      <div class="w-border-header-first mb-n3"/>
                      <div class="card-main-table" style="padding: 15px">
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2">เวลาที่ใช้ในการแปรรูป</div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2">เวลาเริ่มต้นการแปรรูป</div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2">เวลาสิ้นสุดการแปรรูป</div>
                        </v-col>
                      </div>
                    </section>
                    <section class="mb-3 last" v-for="(detail, index) in timeList" :key="index">
                      <div class="w-border-header-sub-last mb-n3"/>
                      <div class="card-sub-table-last" style="padding: 15px">
                        <div class="py-2">
                          <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                        </div>
                        <div class="pt-3">
                          <div>
                            <v-text-field v-model="detail.sTime" label="เวลาเริ่ม" append-icon="mdi-clock-time-four-outline" outlined dense rounded required/>
                          </div>
                          <div>
                            <v-text-field v-model="detail.eTime" label="เวลาสิ้นสุด" append-icon="mdi-clock-time-four-outline" outlined dense rounded required/>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </v-card-text>
              <v-card-text>
                <div class="ml-15 mt-7">
                  <div class="tag-title-step">
                    <b class="black--text">การบันทึกผลการแปรรูป</b>
                  </div>
                  <div class="divider-table my-2"></div>
                  <div class="ml-5">
                    <b class="black--text">ส่วนที่ 1</b>
                  </div>
                  <div class="scroll-menu-last mt-4">
                    <section>
                      <div class="w-border-header-first mb-n3"/>
                      <div class="card-main-table">
                        <div style="padding: 15px">
                          <div class="py-3 d-flex justify-space-between">
                            <div class="black--text font-weight-bold">วัตถุดิบ</div>
                            <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                          </div>
                        </div>
                        <div class="pt-0" style="padding: 15px">
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12" class="inline-box-specific">
                              <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                            </v-col>
                          </v-row>
                          <v-row class="pt-2">
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">ข้าวสารขาว</div>
                              <div class="font-weight-bold"></div>
                            </v-col>
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                            </v-col>
                          </v-row>
                          <v-divider class="mt-10 mb-2" dark color="#a3a3a3"/>
                          <v-row class="pt-2">
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">น้ำกรอง</div>
                              <div class="font-weight-bold"></div>
                            </v-col>
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="footer-total">
                          <div class="d-flex justify-center">
                            <div class="pa-3">
                              <div class="black--text font-weight-bold">รวม (FG)</div>
                            </div>
                            <div class="pa-3">
                              <div class="black--text font-weight-bold">8,736.00 กรัม</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section class="last" v-for="(detail, index) in timeList" :key="index">
                      <div class="w-border-header-sub-last mb-n3"/>
                      <div class="card-sub-table-last">
                        <div style="padding: 15px">
                          <div class="py-3">
                            <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                          </div>
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12" class="inline-box-specific-sub">
                              <div class="black--text font-weight-bold text-center">103%</div>
                            </v-col>
                          </v-row>
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12">
                              <div class="mb-2">
                                <v-text-field class="input-radius" color="#686868" label="จำนวนกรัม" placeholder="จำนวนกรัม" hide-details="auto" value="5103" outlined dense rounded required></v-text-field>
                              </div>
                              <div class="mb-2">
                                <v-select class="input-radius" color="#686868" label="เลข lot" placeholder="เลข lot" hide-details="auto" outlined dense rounded required></v-select>
                              </div>
                              <div class="mb-2 text-center" style="color: #88b358">+ เพิ่ม Lot วัตถุดิบ</div>
                            </v-col>
                          </v-row>
                          <v-divider class="mt-10 mb-2" dark color="#a3a3a3"/>
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12">
                              <div class="mb-2">
                                <v-text-field class="input-radius" color="#686868" label="จำนวนกรัม" placeholder="จำนวนกรัม" hide-details="auto" value="5103" outlined dense rounded required></v-text-field>
                              </div>
                              <div class="mb-2">
                                <v-select class="input-radius" color="#686868" label="เลข lot" placeholder="เลข lot" hide-details="auto" outlined dense rounded required></v-select>
                              </div>
                              <div class="d-flex">
                                <div style="text-align: center">
                                  <v-btn class="mr-2 radius-15" height="40" color="#f2abab" outlined>
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                                <div class="mb-2">
                                  <v-text-field class="input-radius" color="#686868" label="จำนวนกรัม" placeholder="จำนวนกรัม" hide-details="auto" value="150" outlined dense rounded required></v-text-field>
                                </div>
                              </div>
                              <div class="mb-2">
                                <v-select class="input-radius" color="#686868" label="เลข lot" placeholder="เลข lot" hide-details="auto" outlined dense rounded required></v-select>
                              </div>
                              <div class="mb-2 text-center" style="color: #88b358">+ เพิ่ม Lot วัตถุดิบ</div>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="footer-gams">
                          <div style="padding: 15px">
                            <v-text-field class="input-radius white custom-label-color" color="#686868" label="จำนวนกรัม" placeholder="จำนวนกรัม" hide-details="auto" value="150" outlined dense rounded required></v-text-field>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <div></div>
                  <div class="summarize my-5 pa-5">
                    <h2 class="font-weight-bold text-center" style="color: #88b358">ผลลัพธ์ที่จะได้รับจากการแปรรูป 71,9847.64 กรัม หรือ 71.98 กิโลกรัม</h2>
                  </div>
                </div>
              </v-card-text>
              <v-card-text>
                <div class="ml-15 mt-7">
                  <div class="tag-title-step">
                    <b class="black--text">วัตถุดิบที่เสียหายหรือทิ้งไปจากการแปรรูปในรอบนี้</b>
                  </div>
                  <div class="divider-table my-2"></div>
                  <div class="mt-4">
                    <v-row>
                      <v-col xl="6" lg="6">
                        <div class="mb-2">
                          <v-select class="input-radius" color="#686868" label="วัตถุดิบที่เสียหายหรือทิ้ง" placeholder="วัตถุดิบที่เสียหายหรือทิ้ง" hide-details="auto" outlined dense rounded required></v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <div class="table-header-processing mt-4" style="color: #b1adad">
                      <div class="table-sub-header-processing">
                        ชื่อวัตถุดิบ
                      </div>
                      <div class="table-sub-header-processing">
                        เลข lot
                      </div>
                      <div class="table-sub-header-processing">
                        จำนวนกรัม
                      </div>
                      <div class="table-sub-header-processing text-center ml-n1">
                        ลบ
                      </div>
                    </div>
                    <v-card elevation="0" class="grid-processing-items ma-4" v-for="(detail, index) in dataList" :key="index">
                      <div>{{ detail.product }}</div>
                      <div class="pa-2 pt-0 mt-n3">
                        <v-text-field class="input-radius" color="#FFBC15" label="เลข lots" v-model="detail.lots" dense outlined rounded required></v-text-field>
                      </div>
                      <div class="pa-2 pt-0 mt-n3">
                        <v-text-field class="input-radius" color="#FFBC15" label="จำนวนกรัม" v-model="detail.gams" dense outlined rounded required></v-text-field>
                      </div>
                      <div style="text-align: center">
                        <v-btn class="ml-6 radius-15" color="#f2abab" outlined>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                </div>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <v-icon dark>mdi-timer-alert</v-icon>
              </v-avatar>
              <span class="ml-3 title-card-text">วันหมดอายุของสินค้า FG</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <v-row class="ml-15">
                <v-col xl="12" lg="12">
                  <v-row>
                    <v-col xl="3" lg="3">
                      <div class="black--text font-weight-bold ma-2">วันหมดอายุของการแปรรูปสินค้า FG</div>
                      <v-select item-text="value" item-value="id" class="input-radius-search mt-3" prepend-inner-icon="mdi-calendar" label="วันที่หมดอายุ" hide-details="auto" outlined dense></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn class="white--text" color="#88B358" elevation="4" rounded large>
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductionResultEdit",
  data() {
    return {
      menu2: false,
      menu3: false,
      currentDate: new Date().toISOString().substr(0, 10), // Get the current date in YYYY-MM-DD format
      dataList: [
        {
          product: 'ข้าวแห้ง',
          lots: '1-232323243',
          gams: 7234.00,
        },
        {
          product: 'ข้าวแห้ง',
          lots: '1-232323243',
          gams: 132.00,
        },
        {
          product: 'ข้าวแห้ง',
          lots: '1-232323243',
          gams: 132.00,
        },
      ],
      timeList: [
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
      ],
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/production_process_steps')
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.tag-title-step {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}
.inline-box-specific {
  background: #fff8e8;
}
.inline-box-specific-sub {
  background: #d6d3cf;
}
/* Scroll Table */
.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-last .last {
  display: inline-block;
  vertical-align: top;
  margin-right: 1.75%;
}
.scroll-menu-last section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  bottom: auto;
  margin-right: 1.5%;
  padding-right: 25px;
  background: #FFFFFF;
  z-index: 1;
}
.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding-bottom: 15px;
  width: 110%;
  height: 99%;
}
.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: 110%;
}
.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}
.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  max-width: 100%;
  width: 200px;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.footer-total {
  position: absolute;
  bottom: 1%;
  border-top: 2px solid #ffbc15;
  background-color: #fff2d0;
  padding: 17px 22px 0 22px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.footer-gams {
  background-color: #000000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
  inline-size: fit-content;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: baseline;
}
.custom-label-color >>> .v-label--active {
  background-color: #FFFFFF;
  padding-left: 7px;
  padding-right: 7px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>