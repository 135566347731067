<template>
    <div>
        <v-navigation-drawer v-if="$route.path.substring(1).split('/')[0] == 'stock'" class="menu-left-ipad" permanent expand-on-hover absolute>
            <v-list-item class="px-2">
                <v-list-item-title class="text-center">เมนูคลัง</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense shaped>
                <v-list-item-group>
                    <v-list-group>
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <img src="@/assets/Rectangle 73.png" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>คลังสินค้า</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item link @click="link('/stock/warehouse-stock/warehouse')" v-if="$store.state.profileDetail.per_stock == 1 || $store.state.profileDetail.permission == 1">
                            <v-list-item-content>
                                <v-list-item-title>คลัง</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link @click="link('/stock/warehouse-stock/WarehouseRawmaterial')" v-if="$store.state.profileDetail.per_stock_report == 1 || $store.state.profileDetail.permission == 1">
                            <v-list-item-content>
                                <v-list-item-title>รายการวัตถุดิบ/สินค้าที่นำออกจากคลัง</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item link @click="link('/stock/report-stock')">
                        <v-list-item-icon>
                            <img src="@/assets/Rectangle 160.png" alt="">
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>รายงานคลัง</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="link('/stock/quality-inspection-report-list');">
                        <v-list-item-icon>
                            <img src="@/assets/Rectangle 159.png" alt="">
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>รายงานการตรวจสอบคุณภาพ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link>
                        <v-list-item-icon>
                            <img src="@/assets/Rectangle 75.png" alt="">
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>ใบสั่งซื้อ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="link('/stock/material-import')">
                        <v-list-item-icon>
                            <img src="@/assets/Rectangle 74.png" alt="">
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>นำเข้าวัตถุดิบ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- <v-list-item link @click="link('/stock/product-import-fg')">
                        <v-list-item-icon>
                            <img src="@/assets/Rectangle 74.png" alt="">
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>นำเข้าสินค้าสำเร็จ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                    
                    <v-list-group>
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <img src="@/assets/Rectangle 24.png" alt="">
                            </v-list-item-icon>
                            <v-list-item-title>ตั้งค่าข้อมูลเกี่ยวกับคลัง</v-list-item-title>
                        </template>

                        <v-list-group sub-group>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>ตั้งค่าข้อมูลสินค้า</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item link @click="link('/stock/setting-stock/product-type')">
                                <v-list-item-title>- ตั้งค่าประเภทสินค้า</v-list-item-title>

                            </v-list-item>
                            <v-list-item link @click="link('/stock/setting-stock/product-size')">
                                <v-list-item-title>- ตั้งค่าขนาดสินค้า</v-list-item-title>

                            </v-list-item>
                            <v-list-item link @click="link('/stock/setting-stock/set-product-component')">
                                <v-list-item-title>- ตั้งสินค้า</v-list-item-title>

                            </v-list-item>
                        </v-list-group>

                        <v-list-group sub-group>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>ตั้งค่าข้อมูลวัตถุดิบ</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item link  @click="link('/stock/setting-stock/supplier')">
                                <v-list-item-title>- ซัพพลายเออร์</v-list-item-title>

                            </v-list-item>
                            <v-list-item link  @click="link('/stock/setting-stock/raw-material-type')">
                                <v-list-item-title>- ตั้งค่ากลุ่มวัตถุดิบ</v-list-item-title>

                            </v-list-item>
                            <v-list-item link @click="link('/stock/setting-stock/setting-raw-material')">
                                <v-list-item-title>- ตั้งวัตถุดิบ</v-list-item-title>

                            </v-list-item>
                        </v-list-group>
                        <v-list-group sub-group>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>เกณฑ์การตรวจสอบ</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item link @click="link('/stock/setting-stock/quality-inspection-criteria')">
                                <v-list-item-title>-
                                    เกณฑ์การตรวจสอบคุณภาพ</v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="link('/stock/setting-stock/inspection-criteria-production')">
                                <v-list-item-title>-
                                    เกณฑ์การตรวจสอบการผลิต</v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group sub-group>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>ตั้งค่าข้อมูลอื่น ๆ</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item link @click="link('/stock/setting-stock/unit')">
                                <v-list-item-title>- หน่วยนับ</v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item link @click="link('/stock/setting-stock/transport-box')">
                                <v-list-item-title>-
                                    กล่องขนส่ง</v-list-item-title>
                            </v-list-item> -->
                        </v-list-group>
                    </v-list-group>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            admins: [
                ['Management', 'mdi-account-multiple-outline'],
                ['Settings', 'mdi-cog-outline'],
            ],
            cruds: [
                ['Create', 'mdi-plus-outline'],
                ['Read', 'mdi-file-outline'],
                ['Update', 'mdi-update'],
                ['Delete', 'mdi-delete'],
            ],
        }
    },
    methods: {
        link(link) {
            this.$router.push(link)
        },
    }
}
</script>
