<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายละเอียดเกณฑ์การตรวจสอบคุณภาพ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายละเอียดเกณฑ์การตรวจสอบคุณภาพ</b>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center ">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">หัวข้อรายการตรวจสอบคุณภาพ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 text-name-title">ชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (English) :
                                            <span class="text-fullname-title">{{ pq_title.pq_name_eng }}</span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 text-name-title">ชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (ภาษาไทย) :
                                            <span class="text-fullname-title">{{ pq_title.pq_name_thai }}</span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 text-name-title">ประเภทการตรวจสอบ :
                                            <span v-if="pq_title.pq_type == 1" class="text-fullname-title">
                                                ตรวจสอบวัตถุดิบ
                                            </span>
                                            <span v-if="pq_title.pq_type == 2" class="text-fullname-title">
                                                ตรวจสอบคุณภาพสินค้า (FG)
                                            </span>
                                            <span v-if="pq_title.pq_type == 3" class="text-fullname-title">
                                                ตรวจสอบคุณภาพบรรจุภัณฑ์
                                            </span>
                                            
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card class="mt-8" elevation="4" style="border-radius:20px;overflow-wrap: unset !important;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-format-list-bulleted
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายการตรวจสอบคุณภาพ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <div style="width: 100%;">
                                            <div class="table-header-qc-box-data display-pc " style="color: #b1adad;">
                                                <div class="table-header-qc-box-data-text">
                                                    ชื่อเกณฑ์ (English)
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    ชื่อเกณฑ์ (ภาษาไทย)
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    Unit
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    Aim
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    Green
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    Yellow
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    Red
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    value
                                                </div>
                                                <div class="table-header-qc-box-data-text">
                                                    ค่าอัตโนมัติ
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <!-- <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-card elevation="0" class="card-table-radius">
                                            <div class="grid-qc-box-items">
                                                <div>Homogeneous</div>
                                                <div>ความเข้ากัน (ไม่เป็นก้อน)</div>
                                                <div>-</div>
                                                <div>-</div>
                                                <div>เป็นเนื้อเดียวกัน</div>
                                                <div>เป็นก้อน</div>
                                                <div>-</div>
                                                <div><v-icon color="#88B358">mdi-check-circle</v-icon></div>
                                                <div><v-icon color="#FFBC15">mdi-check-circle</v-icon></div>
                                            </div>
                                        </v-card>
                                    </v-col> -->
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" v-for="(pq_detail,index) in pq_list" :key="index">
                                        <v-card elevation="0" class="card-table-radius">
                                            <div class="grid-qc-box-items">
                                                <div>{{ pq_detail.pq_list_eng }}</div>
                                                <div>{{ pq_detail.pq_list_thai }}</div>
                                                <div>{{ pq_detail.unit ? pq_detail.unit : '-' }}</div>
                                                <div>{{ pq_detail.pq_list_aim ? pq_detail.pq_list_aim : '-' }}</div>
                                                <div>{{ pq_detail.pq_list_green ? pq_detail.pq_list_green : '-' }}</div>
                                                <div>{{ pq_detail.pq_list_yellow ? pq_detail.pq_list_yellow : '-' }}</div>
                                                <div>{{ pq_detail.pq_list_red ? pq_detail.pq_list_red : '-' }}</div>
                                                <div>
                                                    <v-icon v-if="pq_detail.pq_list_type == 0 && pq_detail.pq_value_status == 1 && pq_detail.pq_value_boolean == 1" color="#88B358">mdi-check-circle</v-icon>
                                                    <v-icon v-else-if="pq_detail.pq_list_type == 0 && pq_detail.pq_value_status == 1 && pq_detail.pq_value_boolean == 0" color="error">mdi-close-circle</v-icon>
                                                    <div v-else-if="pq_detail.pq_list_type == 1">{{ pq_detail.pq_value }}</div>
                                                </div>
                                                <div>
                                                    <v-icon v-if="pq_detail.pq_value_status == 1" color="#FFBC15">mdi-check-circle</v-icon>
                                                    <v-icon v-if="pq_detail.pq_value_status == 0" color="error">mdi-close-circle</v-icon>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import qualityinspectioncriteria from '@/api/warehouse/qualityinspectioncriteria.js'
import navigationmenu from '@/components/menu/VerticalMenu.vue';
export default {
    data() {
        return {
            loading_page: false,
            width: null,
            long: null,
            height: null,
            search: null,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าเกณฑ์การตรวจสอบ',
                    disabled: false,
                    href:'/stock/setting-stock/quality-inspection-criteria'
                },
                {
                    text: 'เกณฑ์การตรวจสอบคุณภาพ',
                    disabled: false,
                    href:'/stock/setting-stock/quality-inspection-criteria'
                },
                {
                    text: 'รายละเอียดเกณฑ์การตรวจสอบคุณภาพ',
                    disabled: false,
                    href: '#',
                },
            ],
            pq_title: {
                    pq_name_eng: '',
                    pq_name_thai: '',
                    pq_type: null
            },
            pq_list:[
                {
                    pq_list_eng: '',
                    pq_list_thai: '',
                    unit: '',
                    pq_list_type: 1,
                    pq_value_status: 1,
                    pq_value_boolean: 1,
                    pq_value: '',
                    pq_list_aim: '',
                    pq_list_green: '',
                    pq_list_yellow: '',
                    pq_list_red: '',
                }
            ]
        }
    },

    methods: {
        async qualityInspectionCriteriaDetail(){
            this.loading_page = true
            this.pq_title = {
                pq_name_eng: '',
                pq_name_thai: '',
                pq_type: null
            }
            this.pq_list = []
            qualityinspectioncriteria.qualityInspectionCriteriaDetail(this.$route.params.id).then((response) => {
                if (response.data.successful === true) {
                    this.pq_id = response.data.detail.pq_title.id
                    this.pq_title.pq_name_eng = response.data.detail.pq_title.pq_name_eng
                    this.pq_title.pq_name_thai = response.data.detail.pq_title.pq_name_thai
                    this.pq_title.pq_type = response.data.detail.pq_title.pq_type
                    
                    const num_arr = response.data.detail.pq_list.length
                    for (let index = 0; index < num_arr; index++) {
                        this.pq_list.push({
                            pq_list_eng: response.data.detail.pq_list[index].pq_list_eng,
                            pq_list_thai: response.data.detail.pq_list[index].pq_list_thai,
                            unit: response.data.detail.pq_list[index].unit,
                            pq_list_type: response.data.detail.pq_list[index].pq_list_type,
                            pq_value_status: response.data.detail.pq_list[index].pq_value_status,
                            pq_value_boolean: response.data.detail.pq_list[index].pq_value_boolean,   
                            pq_value: response.data.detail.pq_list[index].pq_value,
                            pq_list_aim: response.data.detail.pq_list[index].pq_list_aim,
                            pq_list_green: response.data.detail.pq_list[index].pq_list_green,
                            pq_list_yellow: response.data.detail.pq_list[index].pq_list_yellow,
                            pq_list_red: response.data.detail.pq_list[index].pq_list_red,
                        })
                    }

                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    components: {
        navigationmenu
    },
    created() {
        this.qualityInspectionCriteriaDetail()
    }
}
</script>
<style scoped>
.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}


.table-header-qc-box-data {
    display: grid;
    grid-template-columns: 17% 20% 7% 8% 12% 10% 9% 9% 10%;
    padding: 10px;
}

.table-header-qc-box-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-qc-box-items {
    display: grid;
    grid-template-columns: 17% 20% 7% 8% 12% 10% 10% 10% 6%;
    align-items: center;
    padding: 15px;
}

.v-card {
    overflow-wrap: unset !important;
}
</style>