<template>
  <div>
    <ProductionProcessSteps/>
  </div>
</template>

<script>
import ProductionProcessSteps from "@/components/production_process/processing_raw_materials/ProductionProcessSteps";
export default {
  name: "Production_process_steps",
  components: {ProductionProcessSteps}
}
</script>

<style scoped>

</style>