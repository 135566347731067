<template>
    <div>
        <SetProductComponentPage />
    </div>
</template>
  
<script>
import SetProductComponentPage from '@/components/stock/setting_stock/SetProductComponent'
export default {
    name: 'SetProductComponent',
    components: {
        SetProductComponentPage,
    }
}
</script>