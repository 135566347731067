<template>
    <div>
        <OrderAddPage />
    </div>
</template>
  
<script>
import OrderAddPage from '@/components/sale/OrderAdd'
export default {
    name: 'Orderadd',
    components: {
        OrderAddPage,
    }
}
</script>