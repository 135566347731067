<template>
    <div>
        <Warehouse_page_packaged_detail_raw_material />
    </div>
</template>
  
<script>
import Warehouse_page_packaged_detail_raw_material from '@/components/stock/warehouse_stock/WarehousePackagedRawMaterial'
export default {
    name: 'WarehousePackagedRawMaterial',
    components: {
        Warehouse_page_packaged_detail_raw_material,
    }
}
</script>