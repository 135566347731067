import Accounting from "@/views/accounting/AccountingList.vue";
import AccountingSetting from "@/views/accounting/AccountingSetting.vue";
import AccountingSaveList from "@/views/accounting/AccountingSaveList.vue";

export default [
  {
    path: "/accounting/accounting-list",
    name: "accounting",
    component: Accounting,
  },
  {
    path: "/accounting/accounting-setting",
    name: "accountingsetting",
    component: AccountingSetting,
  },
  {
    path: "/accounting/accounting-save",
    name: "accountingsave",
    component: AccountingSaveList,
  },
];
