<template>
    <div>
      <ProcessingPackagingEdit/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingEdit from "@/components/production_process/procressing_packaging/ProcressingPackagingEdit";
  export default {
    name: "Processing_packaging_detail",
    components: {
      ProcessingPackagingEdit
    }
  }
  </script>
  
  <style scoped>
  
  </style>