<template>
    <div>
        <WarehouseDetailHistoryRawMaterialAuditInspection_page />
    </div>
</template>
  
<script>
import WarehouseDetailHistoryRawMaterialAuditInspection_page from '@/components/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialAuditInspection'
export default {
    name: 'WarehouseDetailHistoryRawMaterialAuditInspection',
    components: {
        WarehouseDetailHistoryRawMaterialAuditInspection_page,
    }
}
</script>