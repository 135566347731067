<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">แก้ไขเกณฑ์การตรวจสอบคุณภาพ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title class="justify-space-between">
                        <b>แก้ไขเกณฑ์การตรวจสอบคุณภาพ</b>
                        <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด :
                            <span class="text-fullname-title">{{ user_edit_last.editor_name }}</span> <span
                                class="text-date-title">วันที่ :
                            </span>
                            <span class="text-datetime-title">{{ `${(user_edit_last.updated_at.split('/')[0]).split(' ')[0]}-${user_edit_last.updated_at.split('/')[1]}-${user_edit_last.updated_at.split('/')[2]}` }}</span>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;margin-bottom:25px">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">หัวข้อรายการตรวจสอบคุณภาพ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-text-field type="text" class="input-radius"
                                            label="ชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (English)" v-model="pq_title.pq_name_eng"
                                            color="#686868"
                                            :rules="[v => !!v || 'กรุณากรอกชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (English)']"
                                            hide-details="auto" dense outlined rounded required></v-text-field>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-text-field type="text" class="input-radius"
                                            label="ชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (ภาษาไทย)" v-model="pq_title.pq_name_thai"
                                            color="#686868"
                                            :rules="[v => !!v || 'กรุณากรอกชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (ภาษาไทย)']"
                                            hide-details="auto" dense outlined rounded></v-text-field>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-autocomplete color="#686868" :items="items" v-model="pq_title.pq_type"
                                            item-text="name" item-value="id"
                                            :rules="[v => !!v || 'กรุณาเลือกประเภทการตรวจสอบ']" label="ประเภทการตรวจสอบ"
                                            hide-details="auto" dense outlined rounded required></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-form ref="form" @submit.prevent="qualityInspectionCriteriaEditSuccess()">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-format-list-bulleted
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">รายการตรวจสอบคุณภาพ</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-card elevation="2" style="border-radius:20px;margin:20px;"
                                        v-for="(pq_detail, index) in pq_list" :key="index">
                                        <v-card-title class="d-flex align-items-center justify-space-between">
                                            <div>
                                                <v-icon dark color="#34230F" size="32">
                                                    mdi-certificate
                                                </v-icon>
                                                <span class="ml-3 text-font-title">รายการตรวจสอบคุณภาพที่ {{ index +
                                                    1 }}</span>
                                            </div>
                                            <div class="error--text" style="cursor:pointer;"
                                                @click="deletePQ(pq_detail.no_pq_id)" v-if="pq_list.length > 1">
                                                <v-icon dark color="error" size="32">
                                                    mdi-delete-forever
                                                </v-icon>
                                                <span class="ml-3 f-14">ลบรายการตรวจสอบคุณภาพ</span>
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-divider class="mb-5" color="#686868"></v-divider>
                                            <span class="ml-3"
                                                style="font-size:16px;color:#000;">ข้อมูลรายการตรวจสอบคุณภาพ</span>
                                            <v-row class="mb-3">
                                                <v-col xs="12" sm="12" md="12" lg="12" cols="12"></v-col>
                                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">

                                                    <v-text-field type="text" class="input-radius"
                                                        label="ชื่อรายการตรวจสอบคุณภาพ (English)"
                                                        v-model="pq_detail.pq_list_eng" color="#686868"
                                                        :rules="[v => !!v || 'กรุณากรอกชื่อรายการตรวจสอบคุณภาพ (English)']"
                                                        hide-details="auto" dense outlined rounded required></v-text-field>

                                                </v-col>
                                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">

                                                    <v-text-field type="text" class="input-radius"
                                                        label="ชื่อรายการตรวจสอบคุณภาพ (ภาษาไทย)"
                                                        v-model="pq_detail.pq_list_thai" color="#686868"
                                                        :rules="[v => !!v || 'กรุณากรอกชื่อรายการตรวจสอบคุณภาพ (ภาษาไทย)']"
                                                        hide-details="auto" dense outlined rounded required></v-text-field>

                                                </v-col>
                                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">

                                                    <v-text-field type="text" class="input-radius"
                                                        label="หน่วยการวัด (Unit)" v-model="pq_detail.unit" color="#686868"
                                                        :rules="[v => !!v || 'กรุณากรอกหน่วยการวัด (Unit)']"
                                                        hide-details="auto" dense outlined rounded></v-text-field>

                                                </v-col>
                                            </v-row>
                                            <v-row class="ml-1">
                                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                                    <span style="font-size:14px;color:#000;">ประเภทการตรวจสอบข้อมูล</span>
                                                    <v-radio-group v-model="pq_detail.pq_list_type"
                                                        @change="refreshPQListType(index)" row>
                                                        <v-radio label="Checkbox" :value="0" color='#FFBC15'></v-radio>
                                                        <v-radio label="กรอกข้อความ" :value="1" color='#FFBC15'></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <span style="font-size:14px;color:#000;">การใส่ค่าอัตโนมัติ</span>
                                                    <div class="d-flex">
                                                        <v-checkbox label="ใส่ค่าอัตโนมัติ" color="#FFBC15"
                                                            v-model="pq_detail.pq_value_status"
                                                            @change="refreshPQValueStatus(index)"></v-checkbox>
                                                    </div>
                                                </v-col>

                                                <v-col v-if="pq_detail.pq_list_type == 0 && pq_detail.pq_value_status == 1"
                                                    xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-radio-group v-model="pq_detail.pq_value_boolean" row
                                                        style="margin-top: 40px;">
                                                        <v-radio label="ผ่าน" :value="1" color='#FFBC15'></v-radio>
                                                        <v-radio label="ไม่ผ่าน" :value="0" color='#FFBC15'></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col v-if="pq_detail.pq_list_type == 1 && pq_detail.pq_value_status == 1"
                                                    xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-text-field type="text" class="input-radius input-for-center"
                                                        label="ใส่คำนี้อัตโนมัติ" v-model="pq_detail.pq_value"
                                                        color="#686868" :rules="[v => !!v || 'กรุณากรอกใส่คำนี้อัตโนมัติ']"
                                                        hide-details="auto" outlined rounded required></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <span class="ml-3" style="font-size:14px;color:#000;">ระยะค่าในเกณฑ์</span>
                                            <v-row class="mt-1">
                                                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-text-field type="text" class="input-radius"
                                                        label="ค่าในเกณฑ์เริ่มต้น (Aim)" v-model="pq_detail.pq_list_aim"
                                                        color="#F8CBAD"
                                                        :rules="[v => !!v || 'กรุณากรอกค่าในเกณฑ์เริ่มต้น (Aim)']"
                                                        hide-details="auto" dense outlined rounded required></v-text-field>

                                                </v-col>
                                                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-text-field type="text" class="input-radius"
                                                        label="ค่าในเกณฑ์ดี (Green)" v-model="pq_detail.pq_list_green"
                                                        color="#00B050"
                                                        :rules="[v => !!v || 'กรุณากรอกค่าในเกณฑ์ดี (Green)']"
                                                        hide-details="auto" dense outlined rounded required></v-text-field>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-text-field type="text" class="input-radius"
                                                        label="ค่าในเกณฑ์พอใช้ (Yellow)" v-model="pq_detail.pq_list_yellow"
                                                        color="#FFE699"
                                                        :rules="[v => !!v || 'กรุณากรอกค่าในเกณฑ์พอใช้ (Yellow)']"
                                                        hide-details="auto" dense outlined rounded required></v-text-field>
                                                </v-col>
                                                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                                                    <v-text-field type="text" class="input-radius"
                                                        label="ค่าในเกณฑ์อันตราย (Red)" v-model="pq_detail.pq_list_red"
                                                        color="#C00000"
                                                        :rules="[v => !!v || 'กรุณากรอกค่าในเกณฑ์อันตราย (Red)']"
                                                        hide-details="auto" dense outlined rounded required></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <!-- กดเพิ่มมาถึงจะขึ้นตรงนี้ -->

                                    <!-- สิ้นสุดกดเพิ่มมาถึงจะขึ้นตรงนี้ -->
                                    <div class="d-flex justify-center mt-3">
                                        <v-btn type="button" class="mt-2" large rounded outlined color="#88B358"
                                            @click="plusPQ()">
                                            <v-icon left>mdi-plus</v-icon>
                                            รายการตรวจสอบคุณภาพ
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" @click="link('/#')"
                                    elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { dateFormatNoSeconds } from "@/helper/dateformats";
import qualityinspectioncriteria from '@/api/warehouse/qualityinspectioncriteria.js'
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import Swal from 'sweetalert2'
export default {
    data() {
        return {
            loading_page: true,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าเกณฑ์การตรวจสอบ',
                    disabled: false,
                    href:'/stock/setting-stock/quality-inspection-criteria'
                },
                {
                    text: 'เกณฑ์การตรวจสอบคุณภาพ',
                    disabled: false,
                    href:'/stock/setting-stock/quality-inspection-criteria'
                },
                {
                    text: 'แก้ไขเกณฑ์การตรวจสอบคุณภาพ',
                    disabled: false,
                    href: '#',
                },
            ],
            items: [
            {
                    id: 1,
                    name: 'ตรวจสอบวัตถุดิบ',
                },
                {
                    id: 2,
                    name: 'ตรวจสอบคุณภาพสินค้า (FG)',
                },
                {
                    id: 3,
                    name: 'ตรวจสอบคุณภาพบรรจุภัณฑ์',
                }
            ],
            pq_id: null,
            pq_title: {
                pq_name_eng: '',
                pq_name_thai: '',
                pq_type: null
            },
            user_edit_last: {
                editor_name: '',
                updated_at: '',
            },
            no_pq_id: 1,
            pq_list: [],
        }
    },

    methods: {
        async qualityInspectionCriteriaDetail() {
            this.loading_page = true
            this.pq_id = null
            this.pq_title = {
                pq_name_eng: '',
                pq_name_thai: '',
                pq_type: null
            }
            this.user_edit_last = {
                editor_name: '',
                updated_at: '',
            }
            this.no_pq_id = 1
            this.pq_list = []
            qualityinspectioncriteria.qualityInspectionCriteriaDetail(this.$route.params.id).then((response) => {
                if (response.data.successful === true) {
                    this.pq_id = response.data.detail.pq_title.id
                    this.pq_title.pq_name_eng = response.data.detail.pq_title.pq_name_eng
                    this.pq_title.pq_name_thai = response.data.detail.pq_title.pq_name_thai
                    this.pq_title.pq_type = response.data.detail.pq_title.pq_type
                    this.user_edit_last.editor_name = response.data.detail.pq_title.editor_name ? response.data.detail.pq_title.editor_name : '-'
                    this.user_edit_last.updated_at = response.data.detail.pq_title.editor_name ? dateFormatNoSeconds(response.data.detail.pq_title.updated_at) : '-'

                    const num_arr = response.data.detail.pq_list.length
                    for (let index = 0; index < num_arr; index++) {
                        this.pq_list.push({
                            no_pq_id: this.no_pq_id,
                            id: response.data.detail.pq_list[index].id,
                            pq_id: this.pq_id,
                            pq_list_eng: response.data.detail.pq_list[index].pq_list_eng,
                            pq_list_thai: response.data.detail.pq_list[index].pq_list_thai,
                            unit: response.data.detail.pq_list[index].unit,
                            pq_list_type: response.data.detail.pq_list[index].pq_list_type,
                            pq_value_status: response.data.detail.pq_list[index].pq_value_status,
                            pq_value_boolean: response.data.detail.pq_list[index].pq_value_boolean,
                            pq_value: response.data.detail.pq_list[index].pq_value,
                            pq_list_aim: response.data.detail.pq_list[index].pq_list_aim,
                            pq_list_green: response.data.detail.pq_list[index].pq_list_green,
                            pq_list_yellow: response.data.detail.pq_list[index].pq_list_yellow,
                            pq_list_red: response.data.detail.pq_list[index].pq_list_red,
                        })
                        this.no_pq_id += 1
                    }

                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async plusPQ() {
            this.pq_list.push({
                // no_pq_id: this.no_pq_id,
                id: null,
                pq_id: this.pq_id,
                pq_list_eng: '',
                pq_list_thai: '',
                unit: '',
                pq_list_type: 1,
                pq_value_status: 1,
                pq_value_boolean: 1,
                pq_value: '',
                pq_list_aim: '',
                pq_list_green: '',
                pq_list_yellow: '',
                pq_list_red: '',
            })
            this.no_pq_id += 1
        },
        async deletePQ(no_pq_id) {
            this.pq_list = this.pq_list.filter((detail) => { return detail.no_pq_id != no_pq_id })
        },
        async refreshPQListType(key) {
            this.pq_list[key].pq_value_boolean = 1
            this.pq_list[key].pq_value = ''
        },
        async refreshPQValueStatus(key) {
            this.pq_list[key].pq_value_boolean = 1
            this.pq_list[key].pq_value = ''
        },
        async qualityInspectionCriteriaEditSuccess() {
            if (this.$refs.form.validate()) {
                this.loading_page = true
                const data = {
                    pq_title: this.pq_title,
                    pq_list: this.pq_list,
                }
                qualityinspectioncriteria.editQualityInspectionCriteria(this.$route.params.id, data).then((response) => {
                    if (response.data.successful === true) {
                        let timerInterval
                        Swal.fire({
                            title: 'ทำรายการเสร็จสิ้น',
                            icon: 'success',
                            html: 'ระบบได้แก้ไขข้อมูลเรียบร้อยแล้ว.',
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft()
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval)
                            }
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                this.$router.push('/stock/setting-stock/quality-inspection-criteria')
                            }
                        })

                    }else{
                        console.log('ERROR นะ');
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        }
    },
    components: {
        navigationmenu
    },
    created() {
        this.qualityInspectionCriteriaDetail()
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-transport-box-data {
    display: grid;
    grid-template-columns: 2% 25% 25% 25% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-transport-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-transport-box-items {
    display: grid;
    grid-template-columns: 6% 25% 24% 33% 12%;
    align-items: center;
}

.input-for-center {
    margin-top: 30px;
}
</style>