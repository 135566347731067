<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">รายละเอียดการเตรียมวัตถุดิบสำหรับแปรรูป ข้าวแห้ง</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/prm/processing_materials')">การแปรรูป</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OF-23198237</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">รายละเอียดการเตรียมวัตถุดิบสำหรับแปรรูป ข้าวแห้ง</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนที่ 1 : สร้างใบสั่งแปรรูป</b>
        </v-card-title>
        <v-card elevation="4" class="py-2" style="border-radius:20px">
          <div class="header-png">
            <v-avatar color="#34230F" size="36">
              <img class="icon-image-logo" src="@/assets/flour (4).png">
            </v-avatar>
          </div>
          <div class="d-flex justify-space-between mt-5 my-3 ml-5 mr-5">
            <b>รายละเอียดขั้นตอนที่ 2 : ใบเตรียมวัตถุดิบ</b>
            <div class="d-flex">
              <div class="mr-2">วันที่ใบเตรียมวัตถุดิบ :</div>
              <div class="black--text">12/02/66</div>
            </div>
          </div>
          <v-card-text>
            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
            <div class="ml-15 mt-4">
              <div class="tag-title-step">
                <b class="black--text">ส่วนที่ 1</b>
              </div>
              <div class="divider-table my-2"></div>
              <div class="scroll-menu-last mt-4">
                <section>
                  <div class="w-border-header-first mb-n3"/>
                  <div class="card-main-table">
                    <div class="header-card">
                      <div class="py-3 d-flex justify-space-between">
                        <div class="black--text font-weight-bold">วัตถุดิบ</div>
                        <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                      </div>
                    </div>
                    <div class="content-card">
                      <v-row class="pt-2">
                        <v-col xl="12" lg="12" class="inline-box-specific">
                          <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col xl="6" lg="6" style="margin-top: 2px">
                          <div class="black--text font-weight-bold">ข้าวสารขาว</div>
                          <div class="font-weight-bold">Lot ข้าวสารขาว</div>
                        </v-col>
                        <v-col xl="6" lg="6" style="margin-top: 2px">
                          <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col xl="6" lg="6" style="margin-top: 2px">
                          <div class="black--text font-weight-bold">น้ำกรอง</div>
                          <div class="font-weight-bold">Lot น้ำกรอง</div>
                        </v-col>
                        <v-col xl="6" lg="6" style="margin-top: 2px">
                          <div class="font-weight-bold">5,000.00 กรัม</div>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="footer-card my-3">
                      <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                      <v-row class="pt-2" style="align-self: flex-end">
                        <v-col xl="6" lg="6">
                          <div class="black--text font-weight-bold">รวม (FG)</div>
                        </v-col>
                        <v-col xl="6" lg="6">
                          <div class="black--text font-weight-bold">8,736.00 กรัม</div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </section>
                <section class="last" v-for="(detail, index) in batchList" :key="index">
                  <div class="w-border-header-sub-last mb-n3"/>
                  <div class="card-sub-table-last">
                    <div>
                      <div class="py-3">
                        <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                      </div>
                      <div class="black--text font-weight-bold text-center">{{ detail.percent }}</div>
                    </div>
                    <div>
                      <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                      <v-row class="pt-2">
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center">{{ detail.gams }} กรัม</div>
                          <div class="font-weight-bold text-center">1-22230923823</div>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center">{{ detail.gamsFilter }}</div>
                          <div class="font-weight-bold text-center">5-22230923823</div>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center">5,000.00 กรัม</div>
                          <div class="font-weight-bold text-center">5-22230923823</div>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="footer-card-last">
                      <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                      <v-row class="pt-2">
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">{{ detail.totalGams }}</div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </section>
              </div>
              <div class="summarize my-5 pa-5">
                <h1 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป {{ total_result }} กรัม หรือ {{ total_result/1000 }} กิโลกรัม</h1>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessingRawMaterialDetails",
  data() {
    return {
      batchList: [
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
      ],
      total_result:0,
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.header-png {
  position: absolute;
  top: -15px;
  left: 20px;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}
.tag-title-step {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}
.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: auto;
}
.inline-box-specific {
  background: #fff8e8;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
}
.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}
.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding: 15px;
  width: auto;
  height: 99%;
}
.card-main-table .header-card {}
.card-main-table .content-card {}
.card-main-table .footer-card {
  position: absolute;
  bottom: 0;
  width: 85%;
}
.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 100%;
  width: 100%;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-last .last {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 3%;
}
.scroll-menu-last section:first-child {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: sticky;
  left: 0;
  margin-right: 3%;
  background: #FFFFFF;
  z-index: 1;
}
</style>