<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายละเอียดวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>รายละเอียดวัตถุดิบคงเหลือ</b>
                            </v-col>
                            <v-col cols="6 text-right">
                                <b>{{ meterial_header.mat_name }} {{ formatPrice(meterial_header.mat_instock) }}</b>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="">
                                    <v-btn class="mt-2 ml-0 mx-5 radius-15 text-16" elevation="2" large outlined
                                        color="#FFBC15">
                                        LOT คงเหลือ 
                                    </v-btn>
                                    <v-btn class="mt-2 mx-5 radius-15 text-grey text-16" elevation="" large color="#fff"
                                        @click="link('/stock/warehouse-stock/warehousedetail-log-used/' + parseFloat($route.params.id))">
                                        LOT ที่ใช้หมดแล้ว
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search" prepend-inner-icon="mdi-magnify"
                                    @input="meterialDetail()" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-warehousedetail-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-warehousedetail-box-data-text"></div>
                                    <div class="table-header-warehousedetail-box-data-text">
                                        วันที่นำเข้า/แปรรูป
                                    </div>
                                    <div class="table-header-warehousedetail-box-data-text">
                                        LOT
                                    </div>
                                    <div class="table-header-warehousedetail-box-data-text">
                                        จำนวนคงเหลือ {{ meterial_header.mat_status >= 3 ? meterial_header.unit_name  : 'กรัม(g)'  }}
                                    </div>
                                    <div class="table-header-warehousedetail-box-data-text">
                                        LOT EXP
                                    </div>
                                    <div class="table-header-warehousedetail-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in meterial_data"
                                    :key="index">
                                    <div class="grid-warehousedetail-box-items">
                                        <div class="w-border"></div>
                                        <div v-if="detail.import_date == null">-</div>
                                        <div v-else>{{
                                            `${detail.import_date.split('/')[0]}-${detail.import_date.split('/')[1]}-${detail.import_date.split('/')[2]}`
                                        }}</div>
                                        <div>{{ detail.material_lot }}</div>
                                        <div>{{ formatPrice(detail.material_amount) }}</div>
                                        <div v-if="detail.lot_exp == null">-</div>
                                        <div v-else>{{
                                            `${detail.lot_exp.split('/')[0]}-${detail.lot_exp.split('/')[1]}-${detail.lot_exp.split('/')[2]}`
                                        }}</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#6C7FD8"
                                                            @click="meterial_header.mat_status == 2 ? link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterial/' + detail.id) : link('/stock/warehouse-stock/WarehouseLotInStockRawMaterial/' + detail.im_list_id)">
                                                            <v-icon left style="color:#6C7FD8">mdi-table-large</v-icon>
                                                            รายงานการตรวจสอบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item link>
                                                        <v-list-item-title style="color:#FFBC15"
                                                            @click="detail.mat_status == 2 ? link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterial/'+detail.id) : link('/stock/warehouse-stock/WarehouseLotInStockRawMaterial/'+detail.id)">
                                                            <v-icon left style="color:#FFBC15">mdi-package-variant</v-icon>
                                                            บรรจุภัณฑ์
                                                        </v-list-item-title>
                                                    </v-list-item> -->
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#F28080"
                                                            @click="export_product_stock_dialog(detail.id,detail.material_amount_remain)">
                                                            <v-icon left style="color:#F28080">mdi-arrow-right-top</v-icon>
                                                            นำออกจากคลัง
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="all_page" :total-visible="all_page"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- นำสินค้าออกจากคลัง -->
        <v-dialog v-model="export_product_stock" transition="dialog-top-transition" max-width="667"
            style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>นำสินค้าออกจากคลัง</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="export_product_stock = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-form ref="form_add" @submit.prevent="exportmeterial(material_lot_id)">
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-archive
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">{{ meterial_header.mat_name }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="จำนวนที่ต้องการนำออก"
                                                :suffix="meterial_header.mat_status >= 3 ? meterial_header.unit_name  : 'g' " :rules="[v => !!(v+'') || 'กรุณากรอกจำนวนที่ต้องการนำออก']"
                                                v-model="qty" @keyup="isStep1Valid(qty,material_lot_max_qty)" @keypress="isOnlyNumber($event, qty)" @paste="onPaste($event)" hide-details="auto"
                                                rounded dense outlined required>
                                            </v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-textarea class="input-radius" label="เหตุผลการนำออก"
                                                :rules="[v => !!v || 'กรุณากรอกเหตุผลการนำออก']" color="#FFBC15" dense
                                                v-model="note" outlined rounded required rows="3"
                                                row-height="25"></v-textarea>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data" >
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large :disabled="!material_lot_disabled"
                                >
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการนำสินค้าออกจากคลัง -->
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice, onPaste } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import meterial from '@/api/warehouse/materials.js'
import Swal from 'sweetalert2'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            loading_detail_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            export_product_stock: false,
            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังวัตถุดิบ',
                    disabled: false,
                    href: '/stock/warehouse-stock/warehouse',
                },
                {
                    text: '',
                    disabled: false,
                },
                {
                    text: 'รายละเอียดวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            test: {},
            qty: 0,
            note: null,
            page: 1,
            all_page: 1,
            search: '',
            status: 0,
            material_lot_id: null,
            material_lot_max_qty: null,
            material_lot_disabled: true,
            meterial_data: [],
            meterial_header: {},
            ware_house_data: [
                {
                    id: 1,
                    import_date: '26/08/2022',
                    lot: '2-202208261125',
                    in_stock: '796 กรัม',
                    exp: '05/10/2022',
                },
                {
                    id: 2,
                    import_date: '05/10/2022',
                    lot: '1-202209051351',
                    in_stock: '123,000 กรัม',
                    exp: '05/10/2022',
                },
                {
                    id: 3,
                    import_date: '05/10/2022',
                    lot: '1-202209051351',
                    in_stock: '35,000 กรัม',
                    exp: '05/10/2022',
                },
            ],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        onPaste(evt) {
            return onPaste(evt);
        },
        export_product_stock_dialog(id,max_qty) {
            this.material_lot_id = null;
            this.material_lot_id = id;
            this.material_lot_max_qty = max_qty;
            this.export_product_stock = true
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        meterialDetail() {
            let id = parseFloat(this.$route.params.id);
            meterial.meterialDetail(id, this.page, '', this.search, this.status).then((response) => {
                if (response.data.successful === true) {
                    this.meterial_header = response.data.material;
                    this.meterial_data = response.data.data;
                    this.all_page = response.data.pages;
                    this.sub_menu[2].text = this.meterial_header.mat_name
                    this.page = parseFloat(response.data.currentPage);

                }
            })

        },
        exportmeterial(material_lot_id) {
            if(this.$refs.form_add.validate()){
                let data = {
                material_id: parseFloat(this.$route.params.id),
                material_lot_id: material_lot_id,
                count_export: parseFloat(this.qty),
                note: this.note
            }
            this.test = data;
            meterial.exportmeterial(data).then((response) => {

                if ( response.status == 200 && response.data.successful === true) {
                    let timerInterval
                    Swal.fire({
                        title: 'ทำรายการเสร็จสิ้น',
                        icon: 'success',
                        html: 'ระบบได้บันทึกข้อมูลเรียบร้อยแล้ว.',
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            this.meterialDetail()
                            timerInterval = setInterval(() => {
                                this.export_product_stock = false
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                            this.qty = parseFloat(0),
                            this.note = null
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    });
                }else if(response.status === 200 && response.data.successful === false){
                     let timerInterval
                    Swal.fire({
                        title: 'คำเตือน',
                        icon: 'warning',
                        html: 'ไม่สามารถทำรายการได้เนื่องจากจำนวนคลังไม่เพียงพอ',
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            this.meterialDetail()
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    });
                }
            })
            }
        },
        isStep1Valid(qty,lot_qty) {
            this.loading_page = true
            if (parseFloat(qty) > parseFloat(lot_qty)) {
                this.material_lot_disabled = false

            }else{
                this.material_lot_disabled = true
            }
            this.loading_page = false
        },
    },
    mounted() {
        this.meterialDetail();
    },
    components: {
        navigationmenu
    },
    watch: {
        page() {
            this.meterialDetail()
        }
    },
    created() {
        // this.isStep1Valid()
    },
    computed: {
        
    },
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-warehousedetail-box-data {
    display: grid;
    grid-template-columns: 1% 21% 20% 21% 15% 16%;
    padding: 5px 0px 5px 30px;
}

.table-header-warehousedetail-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-warehousedetail-box-items {
    display: grid;
    grid-template-columns: 5% 20% 20% 20% 21% 14%;
    align-items: center;
}
</style>