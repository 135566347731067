<template>
    <div>
      <TeamsPage/>
    </div>
  </template>
  
  <script>
  import TeamsPage from '@/components/teams/TeamsComponent'
  export default {
    name: 'Teams',
    components:{
      TeamsPage,
    }
  }
  </script>