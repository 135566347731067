import Vue from "vue";
import Router from "vue-router";
import Login from "../routers/login";
import Dashboard from "../routers/dashboard";
import Teams from "../routers/teams";
import Stock from "../routers/stock";
import Sale from "../routers/sale";
import company_setting from "../routers/company_setting";
import accounting from "../routers/accounting";
import PageAll from "../views/PageAll.vue";
import production_process from "@/routers/production_process";

// import ManageUsers from '../routers/manage_users'
// import UserProfile from '../routers/user_profile'
// import Hospital from '../routers/hospital'

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...Login,
    {
      path: "/",
      name: "pageAll",
      component: PageAll,
      children: [
        ...Dashboard,
        ...Teams,
        ...Stock,
        ...Sale,
        ...accounting,
        ...company_setting,
        ...production_process,
      ],
    },
  ],
});
