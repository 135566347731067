<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
      }}</v-alert>
      <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
    </v-slide-x-reverse-transition>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">เพิ่มวัตถุดิบ</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>

    <v-stepper v-model="stepImport" style="padding: 30px">
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <p class="team-text-title-card">ขั้นตอนการเพิ่มข้อมูล</p>
          <div class="d-flex justify-space-between">
            <div class="text-center">
              <v-btn color="#FFBC15" fab x-small outlined dark style="font-size: 14px" :complete="stepImport > 1"
                step="1">
                1
              </v-btn>
              <p class="my-2" style="font-size: 16px; font-weight: 600; color: #ffbc15">
                ข้อมูลวัตถุดิบ
              </p>
            </div>
            <div class="text-center" v-if="materials_add_detail.mat_status == 2">
              <v-btn :color="stepImport == 1 ? '#7D7D7D' : '#ffbc15'" fab x-small outlined dark style="font-size: 14px"
                :complete="stepImport > 2" step="2">
                2
              </v-btn>
              <p class="my-2" :style="stepImport == 2 || stepImport == 3
                ? 'font-size:16px;font-weight:600;color:#FFBC15;'
                : 'font-size:16px;font-weight:600;color:#D9D9D9;'
                ">
                ข้อมูลการแปรรูป
              </p>
            </div>
            <div class="text-center">
              <v-btn :color="stepImport != 3 ? '#7D7D7D' : '#ffbc15'" fab x-small outlined dark style="font-size: 14px"
                :complete="stepImport > 3" step="3">
                {{ materials_add_detail.mat_status == 2 ? "3" : "2" }}
              </v-btn>
              <p class="my-2" :style="stepImport != 3
                ? 'font-size:16px;font-weight:600;color:#D9D9D9;'
                : 'font-size:16px;font-weight:600;color:#FFBC15;'
                ">
                เกณฑ์การตรวจสอบ
              </p>
            </div>
          </div>
          <!-- <v-progress-linear v-if="stepImport == 1" style="background-color:#D9D9D9;" :color="stepImport == 1 ? '#D9D9D9' : '#ffbc15'"
                        rounded height="9" :value="stepImport == 1 ? 0 : 80">
                    </v-progress-linear> -->
          <v-progress-linear v-if="stepImport == 1" style="background-color: #d9d9d9" color="#D9D9D9" rounded height="9"
            value="0"></v-progress-linear>
          <v-progress-linear v-if="stepImport == 2" style="background-color: #d9d9d9" color="#FFBC15" rounded height="9"
            value="45"></v-progress-linear>
          <v-progress-linear v-if="stepImport == 3" style="background-color: #d9d9d9" color="#FFBC15" rounded height="9"
            value="90"></v-progress-linear>
        </v-col>
      </v-row>
      <v-stepper-items>
        <v-row>
          <v-stepper-content step="1">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-text>
                  <div class="d-flex justify-space-between" style="align-items: center">
                    <div>
                      <v-avatar color="#34230F" size="32">
                        <v-icon dark> mdi-package-variant-closed </v-icon>
                      </v-avatar>
                      <span class="ml-3 text-font-title">ข้อมูลวัตถุดิบ</span>
                    </div>
                    <div>
                      <v-radio-group v-model="materials_add_detail.mat_status" row>
                        <v-radio color="#ffbc15" label="วัตถุดิบปกติ" :value="1"></v-radio>
                        <v-radio color="#ffbc15" label="วัตถุดิบแปรรูป" :value="2"></v-radio>
                        <v-radio color="#ffbc15" label="บรรจุภัณฑ์" :value="3"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <div class="mt-2" style="color: #000; font-size: 18px">
                        ข้อมูลวัตถุดิบ
                      </div>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center">
                      <v-row>
                        <v-col cols="12">
                          <div class="image_preview text-center">
                            <v-avatar class="mt-2" size="210">
                              <img v-if="imagePreviewURL" :src="imagePreviewURL" />
                              <div class="upload_image">
                                <v-btn v-if="material_picture" class="white--text" color="error" depressed fab
                                  @click="onFileRemove()"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                <v-file-input class="d-none" id="uploader" v-model="material_picture" accept="image/*"
                                  @change="onFileChange"></v-file-input>
                              </div>
                            </v-avatar>
                            <v-file-input class="" id="uploader" accept="image/*" v-model="material_picture"
                              placeholder="เลือกรูปภาพวัตถุดิบ" @change="onFileChange" :clearable="false">
                            </v-file-input>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <v-form-group>
                            <v-text-field type="text" class="input-radius" label="ชื่อวัตถุดิบ"
                              v-model="materials_add_detail.mat_name" placeholder="ชื่อวัตถุดิบ" color="#ffbc15"
                              :rules="[(v) => !!v || 'กรุณากรอกชื่อวัตถุดิบ']" hide-details="auto" dense outlined rounded
                              required></v-text-field>
                          </v-form-group>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-form-group>
                            <v-autocomplete :items="material_type" item-text="mat_type_name" item-value="id"
                              v-model="materials_add_detail.materials_type_id" label="กลุ่มวัตถุดิบ" color="#ffbc15"
                              :rules="[(v) => !!v || 'กรุณาเลือกกลุ่มวัตถุดิบ']" hide-details="auto" dense outlined
                              rounded required></v-autocomplete>
                          </v-form-group>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="materials_add_detail.mat_status != 2">
                          <v-form-group>
                            <v-autocomplete :items="supplier" item-text="sup_name" item-value="id"
                              v-model="materials_add_detail.supplier_id" label="ซัพพลายเออร์" color="#ffbc15"
                              :rules="[(v) => !!v || 'กรุณาเลือกซัพพลายเออร์']" hide-details="auto" dense outlined rounded
                              required></v-autocomplete>
                          </v-form-group>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-form-group>
                            <v-text-field type="text" class="input-radius" label="บาร์โค้ด"
                              v-model="materials_add_detail.barcode" placeholder="บาร์โค้ด" color="#ffbc15"
                              hide-details="auto" dense outlined rounded required></v-text-field>
                          </v-form-group>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="d-sm-none"></v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-form-group>
                            <v-text-field type="number" class="input-radius" label="จำนวนขั้นต่ำในคลัง"
                              v-model="materials_add_detail.min" placeholder="จำนวนขั้นต่ำในคลัง" color="#ffbc15"
                              hide-details="auto" @keypress="
                                isOnlyNumber($event, materials_add_detail.min), materials_add_detail.max = ''
                                " dense outlined rounded required></v-text-field>
                          </v-form-group>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-form-group>
                            <!-- :rules="[v => parseFloat(v) > parseFloat(materials_add_detail.min) || 'กรุณากรอกจำนวนสูงสุดมากกว่าจำนวนต่ำสุด']" -->
                            <v-text-field type="number" class="input-radius" label="จำนวนสูงสุดในคลัง"
                              v-model="materials_add_detail.max" placeholder="จำนวนสูงสุดในคลัง" color="#ffbc15" 
                              hide-details="auto" @keypress="
                                isOnlyNumber($event, materials_add_detail.max)
                                " dense outlined rounded required></v-text-field>
                          </v-form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-form ref="step1">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title>
                  <div class="d-flex" style="align-items: center">
                    <v-avatar color="#34230F" size="32">
                      <v-icon dark> mdi-currency-usd </v-icon>
                    </v-avatar>
                    <span class="ml-3 text-font-title">ข้อมูลหน่วยนับและราคาซื้อ</span>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-row>
                        <v-col cols="12" v-if="materials_add_detail.mat_status != 2">
                          <div class="mt-2" style="color: #000; font-size: 18px">
                            หน่วยนับหลักและราคาซื้อ
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="materials_add_detail.mat_status != 2">
                          <div class="d-flex">
                            <v-autocomplete style="border-radius: 20px 0 0 20px" :items="unit_list" color="#FFBC15"
                              label="หน่วยนับ" :rules="[(v) => !!v || 'กรุณาเลือกหน่วยนับ']" hide-details="auto"
                              item-text="unit_name" item-value="id" v-model="materials_add_detail.unit_main" dense
                              outlined required>
                            </v-autocomplete>

                            <v-text-field class="input-radius" color="#FFBC15" label="ราคาซื้อ"
                              style="border-radius: 0 20px 20px 0" suffix="บาท"
                              v-model="materials_add_detail.retail_price" :rules="[(v) => !!v || 'กรุณากรอกราคาซื้อ']"
                              @keypress="isOnlyDecimal($event, unit_qty)" hide-details="auto" dense outlined required>
                            </v-text-field>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" lg="5" xl="5" v-if="materials_add_detail.mat_status == 1">
                          <v-text-field class="input-radius" color="#FFBC15" label="1 หน่วยนับ เท่ากับ กี่กรัม" suffix="g"
                          :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอก']" v-model="materials_add_detail.unit_main_gram"
                            @keypress="isOnlyDecimal($event, unit_qty)" hide-details="auto" rounded dense outlined
                            required>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="materials_add_detail.mat_status != 2">
                          <div class="mt-2" style="color: #000; font-size: 18px">
                            หน่วยนับอื่น ๆ
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="materials_add_detail.mat_status != 2">
                          <div class="d-flex">
                            <v-autocomplete style="border-radius: 20px 0 0 20px" :items="unit_list" color="#FFBC15"
                              label="หน่วยนับอื่น" hide-details="auto" item-text="unit_name" item-value="id"
                              v-model="materials_add_detail.unit_other" dense outlined>
                            </v-autocomplete>

                            <v-text-field class="input-radius" color="#FFBC15" label="ราคาซื้อ" v-if="materials_add_detail.unit_other == null"
                              style="border-radius: 0 20px 20px 0" suffix="บาท" v-model="materials_add_detail.other_price"
                              @keypress="isOnlyDecimal($event, unit_price)" hide-details="auto" dense outlined>
                            </v-text-field>
                            <v-text-field class="input-radius" color="#FFBC15" label="ราคาซื้อ" v-else :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกราคาซื้อ']"
                              style="border-radius: 0 20px 20px 0" suffix="บาท" v-model="materials_add_detail.other_price"
                              @keypress="isOnlyDecimal($event, unit_price)" hide-details="auto" dense outlined>
                            </v-text-field>
                          </div>
                        </v-col>
                        <!-- :suffix='unitSuffixName' -->
                        <v-col cols="12" sm="12" md="5" lg="5" xl="5" v-if="materials_add_detail.mat_status != 2">
                          <v-text-field class="input-radius" color="#FFBC15" label="หน่วยนับอื่น เท่ากับ กี่หน่วยนับหลัก" v-if="materials_add_detail.unit_other == null"
                            :suffix="unitSuffixName" v-model="materials_add_detail.unit_other_qty"  
                            @keypress="isOnlyDecimal($event, unit_qty)" hide-details="auto" rounded dense outlined>
                          </v-text-field>
                          <v-text-field class="input-radius" color="#FFBC15" label="หน่วยนับอื่น เท่ากับ กี่หน่วยนับหลัก" v-else
                            :suffix="unitSuffixName" v-model="materials_add_detail.unit_other_qty" :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกน้ำหนัก']"
                            @keypress="isOnlyDecimal($event, unit_qty)" hide-details="auto" rounded dense outlined>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="materials_add_detail.mat_status == 2">
                          <div class="d-flex">
                            <v-autocomplete style="border-radius: 20px 0 0 20px" :items="unit_list" color="#FFBC15"
                              label="หน่วยนับ" :rules="[(v) => !!v || 'กรุณาเลือกหน่วยนับ']" hide-details="auto"
                              item-text="unit_name" item-value="id" v-model="materials_add_detail.unit_main" dense
                              outlined required>
                            </v-autocomplete>

                            <v-text-field class="input-radius" color="#FFBC15" label="1 หน่วยนับ เท่ากับ กี่กรัม"
                              style="border-radius: 0 20px 20px 0" suffix="g" :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอกน้ำหนัก']"
                              v-model="materials_add_detail.unit_main_gram" @keypress="isOnlyNumber($event, price)"
                              hide-details="auto" dense outlined required>
                            </v-text-field>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <div class="mt-3 div-submit-data">
                <v-btn v-if="materials_add_detail.mat_status == 2" outlined type="button" class="button-submit-data" :disabled="isNextButtonDisabled_mat2"
                  @click="stepImport = 2"  :style="isNextButtonDisabled_mat2 == true ? 'color: rgb(152, 152, 152) !important' : 'color: rgb(136, 179, 88) !important'" elevation="4" rounded large>
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>

                <v-btn v-if="materials_add_detail.mat_status == 1" outlined type="button" class="button-submit-data"
                  @click="stepImport = 3" :disabled="isNextButtonDisabled"
                  :style="isNextButtonDisabled == true ? 'color: rgb(152, 152, 152) !important' : 'color: rgb(136, 179, 88) !important'"
                  elevation="4" rounded large>
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>

                <v-btn v-if="materials_add_detail.mat_status == 3" outlined type="button" class="button-submit-data"
                  @click="stepImport = 3" :disabled="isNextButtonDisabled_three"
                  :style="isNextButtonDisabled_three == true ? 'color: rgb(152, 152, 152) !important' : 'color: rgb(136, 179, 88) !important'"
                  elevation="4" rounded large>
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
              </div>
              </v-form>
            </v-col>
          </v-stepper-content>

          <v-stepper-content step="2" class="pl-2 pr-2">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-cog </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">ข้อมูลการแปรรูป</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row class="px-10">
                    <v-col cols="12">
                      <div class="mt-2" style="color: #000; font-size: 18px">
                        ปริมาณที่ได้จากสูตรการผลิต
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-form-group>
                        <v-text-field type="text" class="input-radius" label="ปริมาณที่ได้จากสูตรการผลิต (กรัม)"
                          v-model="materials_add_detail.formula_amount" placeholder="ปริมาณที่ได้จากสูตรการผลิต (กรัม)"
                          color="#686868" :rules="[
                            (v) =>
                              !!v || 'กรุณากรอก ปริมาณที่ได้จากสูตรการผลิต',
                          ]" hide-details="auto" dense outlined rounded required>
                        </v-text-field>
                      </v-form-group>
                    </v-col>

                    <v-col cols="12" md="6" lg="6"></v-col>

                    <!-- loop ขนาดสินค้า -->
                    <v-col cols="12" v-for="(product_part_loop, index) in material_list" :key="index">
                      <v-card class="radius-20">
                        <v-card-title class="">
                          <v-row>
                            <v-col cols="6" lg="8">
                              <v-avatar color="#34230F" size="32">
                                <v-icon dark> mdi-cog-sync </v-icon>
                              </v-avatar>
                              <span class="ml-3 text-font-title">การแปรรูปส่วนที่ {{ index + 1 }}</span>
                            </v-col>
                            <v-col class="text-right" cols="6" lg="4">
                              <div class="error--text" style="cursor: pointer" @click="
                                deletePPL(product_part_loop.material_group)
                                " v-if="index >= 1">
                                <v-icon dark color="error" size="28">
                                  mdi-delete-forever
                                </v-icon>
                                <span class="ml-3 f-14">ลบรายการตรวจสอบการผลิต</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <v-divider class="mb-3" color="#686868"></v-divider>
                          <v-row class="pl-10">
                            <v-col cols="12" md="12" lg="12">
                              <v-form-group>
                                <v-autocomplete :items="mat_data" class="mt-4" item-text="mat_name" item-value="id"
                                  v-model="product_part_loop.materials_selected" label="เลือกวัตถุดิบในการแปรรูป"
                                  hide-details="auto" dense outlined rounded chips deletable-chips
                                  multiple></v-autocomplete>
                              </v-form-group>
                            </v-col>
                          </v-row>
                          <v-row class="px-15">
                            <v-col cols="6">วัตถุดิบ</v-col>
                            <v-col cols="6">จำนวนวัตถุดิบในสูตร</v-col>
                          </v-row>
                          <v-row class="px-15" v-for="(
                              listID, index
                            ) in product_part_loop.materials_selected" :key="index">
                            <v-col cols="6">{{ slectedName(listID) }}</v-col>
                            <v-col cols="6">
                              <v-form-group class="flex">
                                <v-text-field type="text" class="input-radius mr-2" label="จำนวนวัตถุดิบในสูตร (กรัม)"
                                  placeholder="จำนวนวัตถุดิบในสูตร (กรัม)" color="#686868" v-model="product_part_loop.materials_selected_amount[
                                    index
                                  ]
                                    " :rules="[
    (v) => !!v || 'กรุณากรอก จำนวนวัตถุดิบ',
  ]" hide-details="auto" dense outlined rounded required>
                                </v-text-field>
                              </v-form-group>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <!-- loop ขนาดสินค้า -->
                  </v-row>
                </v-card-text>

                <div class="mt-3 div-submit-data">
                  <v-btn outlined type="button" class="button-submit-data" @click="plusPPL()"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                    <v-icon left>mdi-plus</v-icon> เพิ่มส่วนการแปรรูป
                  </v-btn>
                </div>
                <br />
              </v-card>
              <div class="d-flex justify-center">
                <div class="mt-5 div-submit-data mr-1">
                  <v-btn v-if="materials_add_detail.mat_status == 2" outlined type="button" class="button-submit-data"
                    @click="stepImport = 1" style="color: #ff5252 !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-left</v-icon> ย้อนกลับ
                  </v-btn>
                </div>
                <div class="mt-5 div-submit-data ml-1">
                  <v-btn outlined type="button" class="button-submit-data" @click="stepImport = 3"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-form ref="step_3_list" @submit.prevent="submitBtn()">
                <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-shield-check </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete v-model="qc_add"  :items="qc_list" hide-details="auto" @input="qualityList(qc_add)"
                       :item-text="concatenatedText" item-value="id" rounded outlined dense chips small-chips :rules="[v => qc_add.length > 0 || 'กรุณาเลือก']"
                        label="เกณฑ์การตรวจสอบคุณภาพ" multiple>
                      </v-autocomplete>
                    </v-col>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                      <div style="width: 100%">
                        <div class="table-header-qc-box-data display-pc" style="color: #b1adad">
                          <div class="table-header-qc-box-data-text">
                            ชื่อเกณฑ์ (English)
                          </div>
                          <div class="table-header-qc-box-data-text">
                            ชื่อเกณฑ์ (ภาษาไทย)
                          </div>
                          <div class="table-header-qc-box-data-text">Unit</div>
                          <div class="table-header-qc-box-data-text">Aim</div>
                          <div class="table-header-qc-box-data-text">Green</div>
                          <div class="table-header-qc-box-data-text">
                            Yellow
                          </div>
                          <div class="table-header-qc-box-data-text">Red</div>
                          <div class="table-header-qc-box-data-text">value</div>
                          <div class="table-header-qc-box-data-text">
                            ค่าอัตโนมัติ
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                      <v-expansion-panels>
                        <v-expansion-panel v-for="(list, index) in qc_list_detail" :key="index"
                          :class="{ border_left_yellow: list.isActive }" class="radius-10 mt-3"
                          @click="toggleActive(index)">
                          <v-expansion-panel-header class="radius-10 border-yellow">
                            {{
                              list.pq_title.pq_name_eng == null
                              ? "-"
                              : list.pq_title.pq_name_eng
                            }}
                            {{
                              list.pq_title.pq_name_thai == null
                              ? "-"
                              : list.pq_title.pq_name_thai
                            }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="">
                            <div class="mt-4" v-for="(subList, index) in list.pq_list" :key="index">
                              <div class="table-header-qc-box-data display-pc mt-3" style="color: #b1adad">
                                <div class="table-header-qc-box-data-text">
                                  {{ subList.pq_list_eng }}
                                </div>
                                <div class="table-header-qc-box-data-text">
                                  {{ subList.pq_list_thai }}
                                </div>
                                <div class="table-header-qc-box-data-text">
                                  {{ subList.unit }}
                                </div>
                                <div class="table-header-qc-box-data-text">
                                  {{ subList.pq_list_aim }}
                                </div>
                                <div class="table-header-qc-box-data-text">
                                  {{ subList.pq_list_green }}
                                </div>
                                <div class="table-header-qc-box-data-text">
                                  {{ subList.pq_list_yellow }}
                                </div>
                                <div class="table-header-qc-box-data-text">
                                  {{ subList.pq_list_red }}
                                </div>
                                <div class="table-header-qc-box-data-text text-center" >
                                  <v-icon v-if="subList.pq_list_type == 0 && subList.pq_value_status == 1 && subList.pq_value_boolean == 1" color="#88B358">mdi-check-circle</v-icon>
                                  <v-icon v-else-if="subList.pq_list_type == 0 && subList.pq_value_status == 1 && subList.pq_value_boolean == 0" color="error">mdi-close-circle</v-icon>
                                  <div v-else-if="subList.pq_list_type == 1">{{ subList.pq_value }}</div>
                                </div>
                                <div class="table-header-qc-box-data-text" v-if="subList.pq_value_status === 1">
                                    <v-icon
                                        color="#FFBC15">mdi-check-circle</v-icon>
                                </div>
                                <div class="table-header-qc-box-data-text" v-else>
                                    <v-icon
                                        color="#F28080">mdi-close-circle</v-icon>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <div class="mt-5 ml-1" v-if="materials_add_detail.mat_status === 2">
                <v-card elevation="4" style="border-radius: 20px">
                  <v-card-title class="d-flex align-items-center">
                    <v-avatar color="#34230F" size="32">
                      <v-icon dark> mdi-shield-check </v-icon>
                    </v-avatar>
                    <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบการผลิต</span>
                  </v-card-title>
                  <v-card-text>
                    <v-divider class="mb-3" color="#686868"></v-divider>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete v-model="fq_add" :items="fq_list" hide-details="auto"
                          @input="facQualityList(fq_add)" item-text="fq_name" item-value="id" rounded outlined dense chips :rules="[v => fq_add.length > 0 || 'กรุณาเลือก']"
                          small-chips label="เกณฑ์การตรวจสอบการผลิต" multiple>
                        </v-autocomplete>
                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <div style="width: 100%">
                          <div class="table-header-qc-box-data display-pc" style="color: #b1adad">
                            <div class="table-header-qc-box-data-text">
                              ชื่อเกณฑ์
                            </div>
                            <div class="table-header-qc-box-data-text">
                              ระยะค่าในเกณฑ์
                            </div>
                            <div class="table-header-qc-box-data-text">
                              Value
                            </div>
                            <div class="table-header-qc-box-data-text">
                              ค่าอัตโนมัติ
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <v-expansion-panels>
                          <v-expansion-panel v-for="(list, index) in fq_list_detail" :key="index"
                            :class="{ border_left_yellow: list.isActive }" class="radius-10 mt-3"
                            @click="toggleActive(index)">
                            <v-expansion-panel-header class="radius-10 border-yellow">
                              {{
                                list.fq_title.fq_name == null
                                ? "-"
                                : list.fq_title.fq_name
                              }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="">
                              <div class="mt-4" v-for="(subList, index) in list.fq_list" :key="index">
                                <div class="table-header-qc-box-data display-pc mt-3" style="color: #b1adad">
                                  <div class="table-header-qc-box-data-text">
                                    {{ subList.fq_list_name }}
                                  </div>
                                  <div class="table-header-qc-box-data-text">
                                    {{ subList.fq_list_guide }}
                                  </div>
                                  <div class="table-header-qc-box-data-text">
                                    {{ subList.fq_value }}
                                  </div>
                                  <div class="table-header-qc-box-data-text text-center">
                                    <v-icon color="#FFBC15" v-if="subList.fq_value_boolean == 1">mdi-check-circle</v-icon>
                                  </div>
                                </div>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>

              <div class="d-flex justify-center">
                <div class="mt-5 div-submit-data mr-1">
                  <v-btn v-if="materials_add_detail.mat_status == 2" outlined type="button" class="button-submit-data"
                    @click="stepImport = 2" style="color: #ff5252 !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-left</v-icon> ย้อนกลับ
                  </v-btn>
                  <v-btn v-else outlined type="button" class="button-submit-data" @click="stepImport = 1, fq_list_detail = [], qc_list_detail = [], fq_add = [], qc_add = []"
                    style="color: #ff5252 !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-left</v-icon> ย้อนกลับ
                  </v-btn>
                </div>
                <div class="mt-5 div-submit-data ml-1">
                  <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded
                    large>
                    <v-icon left>mdi-content-save</v-icon> บันทึก
                  </v-btn>
                </div>
              </div>
              </v-form>
            </v-col>
          </v-stepper-content>
        </v-row>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { isTelephone } from "@/helper/handler";
import before_image from "@/assets/preview_img/image 38.png";
import { onlyForCurrency } from "@/helper/handler";
import unit from "@/api/warehouse/unit.js";
import supplier from "@/api/warehouse/supplier.js";
import productQuality from "@/api/warehouse/qualityinspectioncriteria.js";
import facQuality from "@/api/warehouse/inspectioncriteriaproduction.js";
import materialType from "@/api/warehouse/materials_types.js";
import material from "@/api/warehouse/materials.js";
export default {
  data() {
    return {
      loading_page: false,
      stepImport: 1,
      imagePreviewURL: before_image,
      material_picture: null,
      unit: null,
      qc_add: [],
      fq_add: [],
      max_limit: null,
      min_limit: null,

      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "ตั้งค่าข้อมูลเกี่ยวกับคลัง",
          disabled: false,
          href: "#",
        },
        {
          text: "ตั้งค่าข้อมูลวัตถุดิบ",
          disabled: false,
          href: "/stock/setting-stock/setting-raw-material",
        },
        {
          text: "ตั้งค่าวัตถุดิบ",
          disabled: false,
          href: "/stock/setting-stock/setting-raw-material",
        },
        {
          text: "เพิ่มวัตถุดิบ",
          disabled: false,
          href: "#",
        },
      ],
      unit_list: [],
      mat_data: [],
      tab: null,
      materials_add_detail: {
        mat_status: 1,
      },
      material_type: ["ทดสอบ1", "ทดสอบ2", "ทดสอบ3", "ทดสอบ4"],
      supplier: ["supplier 1", "supplier 2", "supplier 3"],
      qc_list: [
        { id: 1, name: "เกณฑ์การตรวจสอบคุณภาพ 1" },
        { id: 2, name: "เกณฑ์การตรวจสอบคุณภาพ 2" },
        { id: 3, name: "เกณฑ์การตรวจสอบคุณภาพ 3" },
      ],
      fq_list: [
        { id: 1, name: "เกณฑ์การตรวจสอบการผลิต 1" },
        { id: 2, name: "เกณฑ์การตรวจสอบการผลิต 2" },
        { id: 3, name: "เกณฑ์การตรวจสอบการผลิต 3" },
      ],
      menu: false,
      modal: false,
      menu2: false,
      toggle_exclusive: 2,
      add_dialog: false,
      edit_dialog: false,
      detail_dialog: false,
      material_list: [
        {
          material_group: 1,
          materials_selected: [],
          materials_selected_amount: [],
        },
      ],
      qc_list_detail: [],
      fq_list_detail: [],
      unitSuffixName: null,
    };
  },
  methods: {
    concatenatedText(item) {
      // Concatenate the two properties you want here for each item
      return item.pq_name_eng + '  ' + item.pq_name_thai;
    },
    link(link) {
      this.$router.push(link);
    },
    async onFileRemove() {
      this.material_picture = null;
      this.imagePreviewURL = before_image;
    },
    async onFileChange(payload) {
      const file = payload;
      let extall = "png,PNG,jpeg,JPEG,jpg,JPG";
      let file_ext = file.name;
      let ext = file_ext.split(".").pop().toLowerCase();
      if (parseFloat(extall.indexOf(ext)) < 0) {
        this.imagePreviewURL = before_image;
        this.step_one_profile_picture = null;
        this.alert_message_error =
          "อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG) เท่านั้น";
        this.alert_error = true;
        setTimeout(() => (this.alert_error = false), 5000);
      } else {
        if (file) {
          this.imagePreviewURL = URL.createObjectURL(file);
          URL.revokeObjectURL(file);
        } else {
          this.imagePreviewURL = before_image;
        }
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    isOnlyNumber: function (evt, number) {
      return onlyForCurrency(evt, number);
    },
    isOnlyDecimal: function (evt, number) {
      return onlyForCurrency(evt, number);
    },
    toggleActive(index) {
      let clickedButton = this.quality_list[index];
      if (!clickedButton.isActive) {
        this.quality_list.forEach((loops) => {
          loops.isActive = false;
        });
      }
      clickedButton.isActive = !clickedButton.isActive;
      if (this.quality_list.every((loops) => !loops.isActive)) {
        this.quality_list.forEach((loops) => {
          loops.isActive = false;
        });
      }
    },

    toggleActive_production(index) {
      let click_pro = this.production_list[index];
      if (!click_pro.isActive) {
        this.production_list.forEach((loopro) => {
          loopro.isActive = false;
        });
      }
      click_pro.isActive = !click_pro.isActive;
      if (this.production_list.every((loopro) => !loopro.isActive)) {
        this.production_list.forEach((loopro) => {
          loopro.isActive = false;
        });
      }
    },
    async plusPPL() {
      this.material_list.push({
        material_group: this.material_list.length + 1,
        materials_selected: [],
        materials_selected_amount: [],
      });
    },
    async deletePPL(material_group) {
      this.material_list = this.material_list.filter((detail) => {
        return detail.material_group != material_group;
      });
    },
    async setproductsize_delete() {
      this.confirm_delete_dialog = true;
      this.loading_detail_page = false;
    },
    async initDataList() {
      await unit.list().then((response) => {
        this.unit_list = response.data.data;
      });
      await supplier.list().then((response) => {
        this.supplier = response.data.data;
      });
      await productQuality.list_type(this.materials_add_detail.mat_status == 3 ? 3 : 1).then((response) => {
        this.qc_list = response.data.data;
      });
      await materialType.list_type(this.materials_add_detail.mat_status).then((response) => {
        this.material_type = response.data.data;
      });
      await material.list_all(this.$route.params.id).then((response) => {
        this.mat_data = response.data.data;
      });
      await facQuality.list_type(1).then((response) => {
        this.fq_list = response.data.data;
      });
    },
    async qualityList(idArr) {
      this.qc_list_detail = [];
      await idArr.forEach(async (e) => {
        await productQuality
          .qualityInspectionCriteriaDetail(e)
          .then((response) => {
            return this.qc_list_detail.push(response.data.detail);
          });
      });
    },
    async facQualityList(fqArr) {
      this.fq_list_detail = [];
      await fqArr.forEach(async (e) => {
        await facQuality
          .detailInspectionCriteriaProduction(e)
          .then((response) => {
            return this.fq_list_detail.push(response.data.detail);
          });
      });
    },
    async submitBtn() {
      if(this.$refs.step_3_list.validate()){
          let bodyFormData = new FormData();
      //===========================================================================================
      if (this.materials_add_detail.mat_status == 1) {
        bodyFormData.append("mat_name", this.materials_add_detail.mat_name);
        bodyFormData.append("mat_status", this.materials_add_detail.mat_status);
        bodyFormData.append("barcode", this.materials_add_detail.barcode ? this.materials_add_detail.barcode : '-');
        bodyFormData.append(
          "materials_type_id",
          this.materials_add_detail.materials_type_id
        );
        bodyFormData.append(
          "supplier_id",
          this.materials_add_detail.supplier_id
        );
        bodyFormData.append("max", this.materials_add_detail.max ? this.materials_add_detail.max : 0);
        bodyFormData.append("min", this.materials_add_detail.min ? this.materials_add_detail.min : 0);
        bodyFormData.append("unit_main", this.materials_add_detail.unit_main ? this.materials_add_detail.unit_main : 0);
        bodyFormData.append(
          "unit_main_gram",
          this.materials_add_detail.unit_main_gram ? this.materials_add_detail.unit_main_gram : 0
        );
        bodyFormData.append(
          "retail_price",
          this.materials_add_detail.retail_price ? this.materials_add_detail.retail_price : 0
        );
        bodyFormData.append("unit_other", this.materials_add_detail.unit_other ? this.materials_add_detail.unit_other : 0);
        bodyFormData.append(
          "unit_other_qty",
          this.materials_add_detail.unit_other_qty ? this.materials_add_detail.unit_other_qty : 0
        );
        bodyFormData.append(
          "other_price",
          this.materials_add_detail.other_price ? this.materials_add_detail.other_price : 0
        );
        bodyFormData.append("mat_pic", this.material_picture);
        //===========================================================================================
        //qc_add คือ id เกณฑ์การตรวจสอบคุณถาพ
        this.qc_add.forEach((e) => {
          bodyFormData.append("product_quality_id", JSON.stringify(e));
        });
      } else if (this.materials_add_detail.mat_status == 2) {
        bodyFormData.append("mat_name", this.materials_add_detail.mat_name);
        bodyFormData.append("mat_status", this.materials_add_detail.mat_status);
        bodyFormData.append("barcode", this.materials_add_detail.barcode ? this.materials_add_detail.barcode : '-');
        bodyFormData.append(
          "materials_type_id",
          this.materials_add_detail.materials_type_id
        );
        bodyFormData.append("max", this.materials_add_detail.max ? this.materials_add_detail.max : 0);
        bodyFormData.append("min", this.materials_add_detail.min ? this.materials_add_detail.min : 0);
        bodyFormData.append("unit_main", this.materials_add_detail.unit_main ? this.materials_add_detail.unit_main : 0);
        bodyFormData.append(
          "unit_main_gram",
          this.materials_add_detail.unit_main_gram ? this.materials_add_detail.unit_main_gram : 0
        );
        bodyFormData.append(
          "formula_amount",
          this.materials_add_detail.formula_amount ? this.materials_add_detail.formula_amount :0
        );
        bodyFormData.append("mat_pic", this.material_picture);
        //===========================================================================================
        //วัตถุดิบแปรรูป
        const formulaData = this.material_list.flatMap((list) =>
          list.materials_selected.map((e, index) => ({
            mat_for_id: e,
            mat_for_qty: list.materials_selected_amount[index],
            mat_group: list.material_group,
          }))
        );
        console.log(formulaData, 'formula form');
        // formulaData.forEach((data) => {
        bodyFormData.append("formula", JSON.stringify(formulaData));
        // });
        //===========================================================================================
        this.qc_add.forEach((e) => {
          bodyFormData.append("product_quality_id", JSON.stringify(e));
        });
        this.fq_add.forEach((e) => {
          bodyFormData.append("factory_quality_id", JSON.stringify(e));
        });
      } else if (this.materials_add_detail.mat_status == 3) {
        bodyFormData.append("mat_name", this.materials_add_detail.mat_name);
        bodyFormData.append("mat_pic", this.material_picture);
        bodyFormData.append("mat_status", this.materials_add_detail.mat_status);
        bodyFormData.append("barcode", this.materials_add_detail.barcode ? this.materials_add_detail.barcode : '-');
        bodyFormData.append(
          "materials_type_id",
          this.materials_add_detail.materials_type_id
        );
        bodyFormData.append("max", this.materials_add_detail.max ? this.materials_add_detail.max : 0);
        bodyFormData.append("min", this.materials_add_detail.min ? this.materials_add_detail.min : 0);
        bodyFormData.append(
          "other_price",
          this.materials_add_detail.other_price ? this.materials_add_detail.other_price : 0
        );
        bodyFormData.append(
          "retail_price",
          this.materials_add_detail.retail_price ? this.materials_add_detail.retail_price : 0
        );
        bodyFormData.append(
          "supplier_id",
          this.materials_add_detail.supplier_id
        );
        bodyFormData.append("unit_main", this.materials_add_detail.unit_main ? this.materials_add_detail.unit_main : 0);
        bodyFormData.append("unit_other", this.materials_add_detail.unit_other ? this.materials_add_detail.unit_other : 0);
        bodyFormData.append(
          "unit_other_qty",
          this.materials_add_detail.unit_other_qty ? this.materials_add_detail.unit_other_qty : 0
        );
        bodyFormData.append(
          "formula_amount",
          this.materials_add_detail.formula_amount ? this.materials_add_detail.formula_amount : 0
        );
        //===========================================================================================
        this.qc_add.forEach((e) => {
          bodyFormData.append("product_quality_id", e);
        });
      }
      material.add(bodyFormData).then((response) => {
        this.loading_page = true
        if (response.data.successful === true) {
          this.loading_page = false
          this.alert_message_success = 'เพิ่มวัตถุดิบเสร็จสิ้น'
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 5000)
          this.$router.push('/stock/setting-stock/setting-raw-material');
        } else {
          this.loading_page = false
          this.alert_message_error = 'มีชื่อวัตถุดิบนี้ในระบบแล้ว'
          this.alert_error = true
          setTimeout(() => this.alert_error = false, 5000)

        }
      });
      }
    },
    updateSuffixValue(id) {
      this.unitSuffixName = this.unit_list.find((e) => e.id == id).unit_name
    },
  },

  computed: {
    slectedName() {
      return (id) => {
        return this.mat_data.find((e) => e.id == id).mat_name;
      };
    },
     isNextButtonDisabled() {
      // Check if any of the required fields are empty
      if (!this.materials_add_detail.mat_name || !this.materials_add_detail.materials_type_id || !this.materials_add_detail.supplier_id || !this.materials_add_detail.unit_main || !this.materials_add_detail.retail_price || !this.materials_add_detail.unit_main_gram || !(parseFloat(this.materials_add_detail.max) >= parseFloat(this.materials_add_detail.min))) {
        return true; // Disable the button if any of the fields are empty
      } else {
        return false; // Enable the button if all fields are filled
      }
    },
    isNextButtonDisabled_mat2() {
      // Check if any of the required fields are empty
      if (!this.materials_add_detail.mat_name || !this.materials_add_detail.materials_type_id || !this.materials_add_detail.unit_main || !this.materials_add_detail.unit_main_gram) {
        return true; // Disable the button if any of the fields are empty
      } else {
        return false; // Enable the button if all fields are filled
      }
    },
    isNextButtonDisabled_three() {
      // Check if any of the required fields are empty
      if (!this.materials_add_detail.mat_name || !this.materials_add_detail.materials_type_id || !this.materials_add_detail.supplier_id || !this.materials_add_detail.unit_main || !this.materials_add_detail.retail_price) {
        return true; // Disable the button if any of the fields are empty
      } else {
        return false; // Enable the button if all fields are filled
      }
    }
  },

  mounted() {
    this.initDataList();
    this.isNextButtonDisabled()
    this.isNextButtonDisabled_mat2()
    this.isNextButtonDisabled_three()
  },
  created() {
    this.isNextButtonDisabled()
    this.isNextButtonDisabled_mat2()
    this.isNextButtonDisabled_three()
  },
  
  watch: {
    'materials_add_detail.unit_main': 'updateSuffixValue',
    'materials_add_detail.mat_status': 'initDataList'
  }
};
</script>
<style scoped>
.input-group {
  display: flex;
  gap: 10px;
}

.form-select {
  width: 150px;
}

.image_preview>.v-avatar {
  overflow: unset;
}

.upload_image {
  position: absolute;
  top: 0;
  right: 0;
}

.form-control {
  flex: 1;
}

.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.padding-text-title {
  padding: 8px !important;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 20px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-import-data {
  display: grid;
  grid-template-columns: 2% 20% 20% 20% 20% 18%;
  padding: 5px 0px 5px 30px;
  color: #686868 !important;
}

.table-header-import-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-items {
  display: grid;
  grid-template-columns: 5% 20% 19% 20% 26% 10%;
  align-items: center;
}

.table-header-import-step3-data {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 5px 0px 5px 30px;
  color: #686868 !important;
}

.table-header-import-step3-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-step3-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: center;
}

.table-header-qc-box-data {
  display: grid;
  grid-template-columns: 17% 20% 7% 8% 12% 10% 9% 9% 10%;
  padding: 10px;
}

.table-header-qc-box-data-text {
  color: #686868;
  font-size: 14px;
}

.grid-qc-box-items {
  display: grid;
  grid-template-columns: 17% 20% 7% 8% 12% 10% 10% 10% 6%;
  align-items: center;
  padding: 15px;
}

.table-header-setstep-4-product-box-data {
  display: grid;
  grid-template-columns: 15% 15% 10% 10% 10% 10% 10% 10% 10%;
  padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-box-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.table-header-setstep-4-product-quality-box-data {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-quality-box-data-text {
  color: #888888;
  font-size: 14px;
  margin-left: 20px;
  font-weight: bold;
}

.border-yellow {
  border-bottom: 1px solid #ffbc15;
}

.border_left_yellow {
  border-left: 4px solid #ffbc15;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
  border-right: 1px solid #ffbc15;
  border-radius: 10px;
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 10px 15px !important;
}
</style>