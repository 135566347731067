<template>
    <div>
      <ProcessingPackagingStepProcress/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepProcress from "@/components/production_process/procressing_packaging/ProcressingPackagingStepProcress";
  export default {
    name: "Processing_packaging_step_procress",
    components: {
      ProcessingPackagingStepProcress
    }
  }
  </script>
  
  <style scoped>
  
  </style>