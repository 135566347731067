import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    // Lot
    getListLot(id){
      return new Promise((resolve, reject) => {
        HTTP.get(
          `/inventory/materials/lot/list/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          }
        )
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    saveAddLot(data){
      return new Promise((resolve, reject) => {
        HTTP.post(`/production/step/two/add/meterial/lot/use`, data, {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        })
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    deleteLot(id){
      return new Promise((resolve, reject) => {
        HTTP.get(
          `/production/step/two/delete/meterial/lot/use/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          }
        )
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    // End Lot
    
    getStepTwoListOne(id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`production/getDetailStepTwoFist/`+id,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          }
        )
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },

    getStepTwoListTwoPrepare(id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/production/getDetailStepTwoMid/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        })
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },

    saveStep2(id, data) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/production/saveStepTwoMid/${id}`, data, {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        })
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },

    getPrepareDeatail(id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/production/getDetailStepTwoEnd/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        })
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },

    saveStepThree(id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/production/saveSteoTwo/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        })
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    
   
}
     
     