<template>
    <div>
        <Warehouse_page_unpackaged_detail />
    </div>
</template>
  
<script>
import Warehouse_page_unpackaged_detail from '@/components/stock/warehouse_stock/WarehouseUnpackagedDetail'
export default {
    name: 'WarehouseunpackagedDetail',
    components: {
        Warehouse_page_unpackaged_detail,
    }
}
</script>