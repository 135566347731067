<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OF-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <div class="d-flex justify-space-between ma-5">
          <h3>
            <b>ขั้นตอนที่ 6 : ยืนยันการแปรรูปสำเร็จ</b>
          </h3>
          <div class="d-flex">
            <div class="d-flex mr-3">
              <div class="mr-2" style="color: #686868">ผู้แก้ไขล่าสุด :</div>
              <div class="black--text">ชลิตวรรณ นาคทอง</div>
            </div>
            <div class="d-flex">
              <div class="mr-2" style="color: #686868">วันที่ :</div>
              <div class="black--text">02/01/2023 09:40</div>
            </div>
          </div>
        </div>
        <v-card-text>
          <v-card elevation="4" class="py-2 my-5" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/process (2).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 1 : ใบสั่งแปรรูป</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <v-row>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">วันที่ใบสั่งแปรรูป :</div>
                        <div class="black--text font-weight-bold">25/01/2023</div>
                      </div>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">เลขที่ใบสั่งผลิต :</div>
                        <div class="black--text font-weight-bold">PO-23981273</div>
                      </div>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">อนุมัติสั่งผลิต :</div>
                        <div class="black--text font-weight-bold">พงศ์กมล</div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="tag-title-step my-3">
                  <b class="black--text">รายการวัตถุดิบแปรรูป</b>
                </div>
                <div>
                  <div class="table-header-processing mt-4" style="color: #b1adad;">
                    <div class="table-sub-header-processing">วัตถุดิบแปรรูป</div>
                    <div class="table-sub-header-processing ml-n1">จำนวนกรัม</div>
                    <div class="table-sub-header-processing ml-n1">g FG/Batch</div>
                    <div class="table-sub-header-processing ml-n1">No. of Batch</div>
                  </div>
                  <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items ml-3">
                    <div class="black--text font-weight-bold">ข้าวแห้ง</div>
                    <div class="black--text font-weight-bold ml-n3">72,000.00</div>
                    <div class="black--text font-weight-bold ml-n3">8,736.00</div>
                    <div class="black--text font-weight-bold ml-n2">8.24</div>
                  </div>
                </div>
                <div class="tag-title-step my-3">
                  <b class="black--text">ขั้นตอนการสั่งแปรรูปและผู้รับผิดชอบ</b>
                </div>
                <div>
                  <div class="table-header-processing-second mt-4" style="color: #b1adad">
                    <div class="table-sub-header-processing">No.</div>
                    <div class="table-sub-header-processing">ขั้นตอน</div>
                    <div class="table-sub-header-processing ml-n1">ผู้รับผิดชอบ</div>
                    <div class="table-sub-header-processing ml-n1">วัน/เดือน/ปี</div>
                    <div class="table-sub-header-processing ml-n1">รายละเอียด</div>
                  </div>
                  <div class="grid-processing-items-second ml-3" v-for="(detail, index) in firstStepList" :key="index">
                    <div class="black--text font-weight-bold py-1 ml-1">{{ index + 1 }}.</div>
                    <div class="black--text font-weight-bold ml-1">{{ detail.step }}</div>
                    <div class="black--text font-weight-bold ml-n3">{{ detail.author }}</div>
                    <div class="black--text font-weight-bold ml-n2">{{ detail.date }}</div>
                    <div class="black--text font-weight-bold ml-n1">{{ detail.detail }}</div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/flour (4).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 2 : ใบเตรียมวัตถุดิบ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <v-row>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">วันที่ใบเตรียมวัตถุดิบ :</div>
                        <div class="black--text font-weight-bold">25/01/2023</div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">รายละเอียดการแปรรูป</b>
                  </div>
                  <div>
                    <div class="table-header-processing-details mt-4" style="color: #b1adad;">
                      <div class="table-sub-header-processing">No.</div>
                      <div class="table-sub-header-processing ml-n1">RM Code</div>
                      <div class="table-sub-header-processing ml-n1">การ weight % จากสูตร</div>
                      <div class="table-sub-header-processing ml-n1">จำนวน (สูตรต่อ Batch)</div>
                    </div>
                    <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                    <div class="grid-processing-items-details ml-3">
                      <div class="black--text font-weight-bold">1.</div>
                      <div class="black--text font-weight-bold ml-n3">ข้าวสารขาว</div>
                      <div class="black--text font-weight-bold ml-n3">100%</div>
                      <div class="black--text font-weight-bold ml-n2">5,000 กรัม</div>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div></div>
                    <div class="summarize-header my-5 pa-3">
                      <h3 class="black--text font-weight-bold text-center" style="width: 300px">รวม (FG) 8,736.00 กรัม</h3>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="tag-title-step-table">
                      <b class="black--text">ส่วนที่ 1</b>
                    </div>
                    <div class="divider-table my-2"></div>
                    <div class="scroll-menu-last mt-4">
                      <section>
                        <div class="w-border-header-first mb-n3"/>
                        <div class="card-main-table">
                          <div class="header-card">
                            <div class="py-3 d-flex justify-space-between">
                              <div class="black--text font-weight-bold">วัตถุดิบ</div>
                              <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                            </div>
                          </div>
                          <div class="content-card">
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12" class="inline-box-specific">
                                <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">ข้าวสารขาว</div>
                                <div class="font-weight-bold">Lot ข้าวสารขาว</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">น้ำกรอง</div>
                                <div class="font-weight-bold">Lot น้ำกรอง</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="font-weight-bold">5,000.00 กรัม</div>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-card my-3">
                            <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                            <v-row class="pt-2" style="align-self: flex-end">
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">รวม (FG)</div>
                              </v-col>
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">8,736.00 กรัม</div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                      <section class="last" v-for="(detail, index) in batchList" :key="index">
                        <div class="w-border-header-sub-last mb-n3"/>
                        <div class="card-sub-table-last">
                          <div>
                            <div class="py-3">
                              <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                            </div>
                            <div class="black--text font-weight-bold text-center">{{ detail.percent }}</div>
                          </div>
                          <div>
                            <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center">{{ detail.gams }} กรัม</div>
                                <div class="font-weight-bold text-center">1-22230923823</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center">{{ detail.gamsFilter }}</div>
                                <div class="font-weight-bold text-center">5-22230923823</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center">5,000.00 กรัม</div>
                                <div class="font-weight-bold text-center">5-22230923823</div>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-card-last">
                            <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">{{ detail.totalGams }}</div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div class="summarize my-5 pa-5">
                      <h3 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป 71,9847.64 กรัม หรือ 71.98 กิโลกรัม</h3>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/editing(2).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 3 : บันทึกผลการผลิต (วันหมดอายุของสินค้า FG:23/01/2023)</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">การบันทึกเวลาในการแปรรูป</b>
                  </div>
                  <div class="divider-table my-2"/>
                  <div class="scroll-menu-time mt-2">
                    <section class="mb-3">
                      <div class="w-border-header-first mb-n3"/>
                      <div class="card-main-table-time" style="padding: 15px">
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">เวลาที่ใช้ในการแปรรูป</div>
                        </v-col>
                        <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">เวลาเริ่มต้นการแปรรูป</div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">เวลาสิ้นสุดการแปรรูป</div>
                        </v-col>
                      </div>
                    </section>
                    <section class="mb-3 time" v-for="(detail, index) in timeList" :key="index">
                      <div class="w-border-header-sub-last mb-n3"/>
                      <div class="card-sub-table-time" style="padding: 15px">
                        <div class="pt-3 pb-5">
                          <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                        </div>
                        <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">{{ detail.sTime }}</div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">{{ detail.eTime }}</div>
                        </v-col>
                      </div>
                    </section>
                  </div>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">การบันทึกผลการแปรรูป</b>
                  </div>
                  <div class="divider-table my-2"/>
                  <div class="ml-5">
                    <b class="black--text">ส่วนที่ 1</b>
                  </div>
                  <div class="scroll-menu-time mt-2">
                    <section>
                      <div class="w-border-header-first mb-n3"/>
                      <div class="card-main-table-time">
                        <div style="padding: 15px">
                          <div class="py-3 d-flex justify-space-between">
                            <div class="black--text font-weight-bold">วัตถุดิบ</div>
                            <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                          </div>
                        </div>
                        <div class="pt-0" style="padding: 15px">
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12" class="inline-box-specific">
                              <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                            </v-col>
                          </v-row>
                          <v-row class="pt-2">
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">ข้าวสารขาว</div>
                              <div class="font-weight-bold"></div>
                            </v-col>
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                            </v-col>
                          </v-row>
                          <v-divider class="mt-10 mb-2" dark color="#a3a3a3"/>
                          <v-row class="pt-2">
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">น้ำกรอง</div>
                              <div class="font-weight-bold"></div>
                            </v-col>
                            <v-col xl="6" lg="6" style="margin-top: 2px">
                              <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="footer-total">
                          <div class="d-flex justify-center">
                            <div class="pa-3">
                              <div class="black--text font-weight-bold">รวม (FG)</div>
                            </div>
                            <div class="pa-3">
                              <div class="black--text font-weight-bold">8,736.00 กรัม</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section class="time" v-for="(detail, index) in timeList" :key="index">
                      <div class="w-border-header-sub-last mb-n3"/>
                      <div class="card-sub-table-time">
                        <div style="padding: 15px">
                          <div class="py-3">
                            <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                          </div>
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12" class="inline-box-specific-time">
                              <div class="black--text font-weight-bold text-center">103%</div>
                            </v-col>
                          </v-row>
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12">
                              <div class="mb-2">
                                <div class="black--text font-weight-bold text-center ma-2">5103 กรัม</div>
                              </div>
                              <div class="mb-2">
                                <div class="font-weight-bold text-center ma-2">2-123123123 กรัม</div>
                              </div>
                            </v-col>
                          </v-row>
                          <v-divider class="mt-4 mb-2" dark color="#a3a3a3"/>
                          <v-row class="pt-2">
                            <v-col xl="12" lg="12">
                              <div class="mb-2">
                                <div class="black--text font-weight-bold text-center ma-2">5103 กรัม</div>
                              </div>
                              <div class="mb-2">
                                <div class="font-weight-bold text-center ma-2">2-123123123 กรัม</div>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="footer-gams">
                          <div class="white--text font-weight-bold text-center" style="padding: 15px">6,873.32 กรัม</div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="summarize my-5 pa-5">
                  <h3 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป 71,9847.64 กรัม หรือ 71.98 กิโลกรัม</h3>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">วัตถุดิบที่เสียหายหรือทิ้งไปในรอบนี้</b>
                  </div>
                  <div class="divider-table my-2"/>
                  <div>
                    <div class="table-header-processing-details mt-4" style="color: #b1adad">
                      <div class="table-sub-header-processing">No.</div>
                      <div class="table-sub-header-processing ml-n1">ชื่อวัตถุดิบ</div>
                      <div class="table-sub-header-processing ml-n1">เลข Lot</div>
                      <div class="table-sub-header-processing ml-n1">จำนวนกรัม</div>
                    </div>
                    <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                    <div class="grid-processing-items-details ml-3">
                      <div class="black--text font-weight-bold py-1">1.</div>
                      <div class="black--text font-weight-bold ml-n3">ข้าวสารขาว</div>
                      <div class="black--text font-weight-bold ml-n3">1-23918263</div>
                      <div class="black--text font-weight-bold ml-n2">50 กรัม</div>
                    </div>
                    <div class="grid-processing-items-details ml-3">
                      <div class="black--text font-weight-bold">2.</div>
                      <div class="black--text font-weight-bold ml-n3">น้ำกรอง</div>
                      <div class="black--text font-weight-bold ml-n3">2-32119836</div>
                      <div class="black--text font-weight-bold ml-n2">150 กรัม</div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/shield(2).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 4 : การตรวจสอบกระบวนการแปรรูป</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing-steps mt-6" style="color: #b1adad;">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (English)</div>
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (ภาษาไทย)</div>
                  <div class="table-sub-header-processing ml-1">Unit</div>
                  <div class="table-sub-header-processing ml-1" style="color:#ffb98a">Aim</div>
                  <div class="table-sub-header-processing ml-n1" style="color:#13b65d">Green</div>
                  <div class="table-sub-header-processing ml-n1" style="color:#ddbb55">Yellow</div>
                  <div class="table-sub-header-processing ml-n3" style="color:#c00000">Red</div>
                  <div class="table-sub-header-processing ml-n3">Value</div>
                </div>
                <v-card elevation="4" class="my-5" style="border-radius:20px">
                  <div class="table-header-processing-steps py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Steaming</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">การนึ่ง</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items-steps ma-2 pl-3 pr-3" v-for="(detail, index) in dataList" :key="index">
                    <div class="black--text font-weight-bold pa-1">{{ detail.eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.th }}</div>
                    <div>{{ detail.unit }}</div>
                    <div>{{ detail.aim }}</div>
                    <div>{{ detail.green }}</div>
                    <div>{{ detail.yellow }}</div>
                    <div>{{ detail.red }}</div>
                    <div class="ml-1">{{ detail.value }}</div>
                  </div>
                </v-card>
                <v-card elevation="4" class="my-5" style="border-radius:20px">
                  <div class="table-header-processing-steps py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Sensory</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">ชิมข้าวนึ่ง</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items-steps ma-2 mt-0 pl-3 pr-3" v-for="(detail, index) in secondList" :key="index">
                    <div class="black--text font-weight-bold pa-1">{{ detail.eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.th }}</div>
                    <div>{{ detail.unit }}</div>
                    <div>{{ detail.aim }}</div>
                    <div>{{ detail.green }}</div>
                    <div>{{ detail.yellow }}</div>
                    <div>{{ detail.red }}</div>
                    <div>
                      <v-checkbox color="#FFBC15" v-model="detail.value"></v-checkbox>
                    </div>
                  </div>
                </v-card>
                <v-card elevation="4" class="my-5" style="border-radius:20px">
                  <div class="table-header-processing-steps py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Drying</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">การตากแห้ง (โดม)</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items-steps ma-2 pl-3 pr-3" v-for="(detail, index) in dataList" :key="index">
                    <div class="black--text font-weight-bold pa-1">{{ detail.eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.th }}</div>
                    <div>{{ detail.unit }}</div>
                    <div>{{ detail.aim }}</div>
                    <div>{{ detail.green }}</div>
                    <div>{{ detail.yellow }}</div>
                    <div>{{ detail.red }}</div>
                    <div class="ml-1">{{ detail.value }}</div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/quality (1).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 5 : การตรวจสอบวัตถุดิบที่แปรรูปสำเร็จ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing-recheck mt-6" style="color: #b1adad">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์</div>
                  <div class="table-sub-header-processing ml-2">เกณฑ์คุณภาพ</div>
                  <div class="table-sub-header-processing ml-n3">Value</div>
                </div>
                <v-card elevation="4" class="my-5" style="border-radius:20px">
                  <div class="table-header-processing-recheck py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Steamer</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items-recheck ma-2 pl-3 pr-3" v-for="(detail, index) in qualityList" :key="index">
                    <div class="black--text font-weight-bold pa-1">{{ detail.name }}</div>
                    <div>{{ detail.quality }}</div>
                    <div>{{ detail.value }}</div>
                  </div>
                </v-card>
                <v-card elevation="4" class="my-5" style="border-radius:20px">
                  <div class="table-header-processing-recheck py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Solar Dome</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items-recheck ma-2 pl-3 pr-3" v-for="(detail, index) in solarList" :key="index">
                    <div class="black--text font-weight-bold pa-1">{{ detail.name }}</div>
                    <div>{{ detail.detail }}</div>
                    <div>2,423.00</div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="modal = true">
            <v-icon left>mdi-content-save</v-icon> ยืนยันการแปรรูปสำเร็จ
          </v-btn>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="modal" transition="dialog-top-transition" max-width="400" style="border-radius:20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="modal = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="d-flex flex-column align-center">
            <img class="icon-image mb-3" src="@/assets/buying.png">
          </div>
          <div class="text-center">
            <h1 class="black--text">ยืนยันการแปรรูปสำเร็จ</h1>
            <div class="my-2">คุณต้องการยืนยันการแปรรูปสำเร็จหรือไม่</div>
          </div>
          <div class="mt-3 text-center">
            <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="linkTo">
              <v-icon left>mdi-content-save</v-icon> ตกลง
            </v-btn>
            <div class="ma-4" style="cursor: pointer">ยกเลิก</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProductionConfirmEdit",
  data() {
    return {
      modal: false,
      firstStepList: [
        {
          step: 'สั่งแปรรูป',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'สั่งปริ้นใบเตรียมวัตถุดิบ',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'เตรียมวัตถุดิบในการผลิต',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'แปรรูปวัตุดิบ',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'QA Process',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'QA Material',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
      ],
      batchList: [
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
      ],
      timeList: [
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
      ],
      dataList: [
        {
          eng: 'Steaming temp',
          th: 'อุณหภูมิในการนึ่ง',
          unit: 'C',
          aim: '100',
          green: '90-110',
          yellow: '<90>110',
          red: '-',
          value: 324,
        },
        {
          eng: 'Steaming time',
          th: 'เวลาในการนึ่ง',
          unit: 'min',
          aim: '50',
          green: '45-55',
          yellow: '<45>55',
          red: '-',
          value: 50,
        },
      ],
      secondList: [
        {
          eng: 'Appearance',
          th: 'ข้าวเป็นเม็ดสวยไม่แฉะ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
        {
          eng: 'Texture',
          th: 'ไม่เละไม่เป็นไต',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: false,
        },
        {
          eng: 'Flavor',
          th: 'ไม่มีกลิ่นเหม็นอับ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
      ],
      qualityList: [
        {
          name: 'Rice: Water',
          quality: 'Jasmin 32:24/Berry 11:54',
          value: 23,
        },
        {
          name: 'จำนวนถาดที่หุ้ง',
          quality: '-',
          value: 23,
        },
        {
          name: 'Temperature',
          quality: '100C',
          value: 23,
        },
        {
          name: 'Time Start',
          quality: '-',
          value: 23,
        },
        {
          name: 'Time End',
          quality: '-',
          value: 23,
        },
        {
          name: 'Time',
          quality: '50 Min - 60 Min',
          value: 23,
        },
      ],
      solarList: [
        {
          name: 'Temp',
          detail: '45.0 - 70.0 C',
        },
        {
          name: 'DateStart',
          detail: '-',
        },
        {
          name: 'TimeStart',
          detail: '-',
        },
        {
          name: 'DateEnd',
          detail: '-',
        },
        {
          name: 'TimeEnd',
          detail: '-',
        },
        {
          name: 'Time',
          detail: '>6.0 hr',
        },
        {
          name: 'Moisture : Dried Rice',
          detail: '9.0 - 11.0',
        },
      ],
    }
  },
  methods: {
    linkTo() {
      this.$router.push('/prm/processing_materials')
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.header-png {
  position: absolute;
  top: -15px;
  left: 20px;
}
.icon-image {
  width: 120px;
  height: 120px;
  text-align: center;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.tag-title-step {
  background-color: #fff8e8;
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.tag-title-step-table {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.table-header-processing-second {
  display: grid;
  grid-template-columns: 5% 20% 25% 25% 25%;
}
.table-header-processing-details {
  display: grid;
  grid-template-columns: 10% 25% 25% 40%;
}
.table-header-processing-steps {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
}
.table-header-processing-recheck {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: baseline;
}
.grid-processing-items-second {
  display: grid;
  grid-template-columns: 5% 20% 25% 25% 25%;
  align-items: baseline;
}
.grid-processing-items-details {
  display: grid;
  grid-template-columns: 10% 25% 25% 40%;
  align-items: baseline;
}
.grid-processing-items-steps {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
  align-items: baseline;
}
.grid-processing-items-recheck {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  align-items: baseline;
}
.summarize-header {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
  inline-size: fit-content;
}
.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}
.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}
/* Part 1 */
.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: auto;
}
.inline-box-specific {
  background: #fff8e8;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
}
.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}
.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding: 15px;
  width: auto;
  height: 99%;
}
.card-main-table .header-card {}
.card-main-table .content-card {}
.card-main-table .footer-card {
  position: absolute;
  bottom: 0;
  width: 85%;
}
.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 100%;
  width: 100%;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-last .last {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 3%;
}
.scroll-menu-last section:first-child {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: sticky;
  left: 0;
  margin-right: 3%;
  background: #FFFFFF;
  z-index: 1;
}
/* Part 1 */
/* Part 2 */
.card-main-table-time {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding-bottom: 15px;
  width: 100%;
  height: 99%;
}
.card-sub-table-time {
  border: 1px solid #34230F;
  border-radius: 15px;
  max-width: 100%;
  width: 200px;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-time:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu-time {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-time .time {
  display: inline-block;
  vertical-align: top;
  margin-right: 3%;
}
.scroll-menu-time section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  bottom: auto;
  margin-right: 3%;
  background: #FFFFFF;
  z-index: 1;
}
.inline-box-specific-time {
  background: #d6d3cf;
}
.footer-total {
  position: absolute;
  bottom: 1%;
  border-top: 2px solid #ffbc15;
  background-color: #fff2d0;
  padding: 11px;
}
.footer-gams {
  background-color: #000000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
/* Part 2 */
</style>