<template>
  <div>
    <ProductionProductGoodPackageEdit/>
  </div>
</template>

<script>
import ProductionProductGoodPackageEdit from "@/components/production_process/processing_product_good/ProductionProductGoodPackageEdit";
export default {
  name: "ProductionProductGoodPackageEdit_Page",
  components: {ProductionProductGoodPackageEdit}
}
</script>

<style scoped>

</style>