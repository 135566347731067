<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>

        <navigationmenu />

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">เกณฑ์การตรวจสอบคุณภาพ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการเกณฑ์การตรวจสอบคุณภาพ</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3"
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."  @keyup="searchList()"
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                <div class="d-flex justify-end" >
                                    <v-btn class="mt-2" large rounded outlined color="#88B358" v-if="$store.state.profileDetail.per_materials_formula == 1 || $store.state.profileDetail.permission == 1"
                                        @click="link('quality-inspection-criteria-add')">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มเกณฑ์การตรวจสอบคุณภาพ
                                    </v-btn>
                                    <v-btn class="mt-2" large rounded color="#D9D9D9" v-else>
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มเกณฑ์การตรวจสอบคุณภาพ
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-quality-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-quality-box-data-text"></div>
                                    <div class="table-header-quality-box-data-text">
                                        ชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (English)
                                    </div>
                                    <div class="table-header-quality-box-data-text">
                                        ชื่อหัวข้อเกณฑ์การตรวจสอบคุณภาพ (ภาษาไทย)
                                    </div>
                                    <div class="table-header-quality-box-data-text">
                                        ประเภทการตรวจสอบ
                                    </div>
                                    <div class="table-header-quality-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in data"
                                    :key="index">
                                    <div class="grid-quality-box-items">
                                        <div class="w-border"></div>
                                        <div>{{ detail.pq_name_eng }}</div>
                                        <div>{{ detail.pq_name_thai == null ? '-' : detail.pq_name_thai}}</div>
                                        <div>{{ detail.pq_type }}</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title class="f-14" style="color:#6C7FD8"
                                                            @click="link('quality-inspection-criteria-detail/' + detail.id);">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียด
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link v-if="$store.state.profileDetail.per_materials_formula == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item-title class="f-14" style="color:#FEA06C"
                                                            @click="link('quality-inspection-criteria-edit/' + detail.id);">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link v-if="$store.state.profileDetail.per_materials_formula == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item-title class="f-14" style="color:#F28080"
                                                            @click="quality_inspection_criteria_delete(detail.id)">
                                                            <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                            ลบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- ลบข้อมูล -->
        <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large
                                    @click="qualityInspectionCriteriaDeleteSuccess()">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:14px;" text @click="confirm_delete_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->
    </div>
</template>
<script>
import qualityinspectioncriteria from '@/api/warehouse/qualityinspectioncriteria.js'
import navigationmenu from '@/components/menu/VerticalMenu.vue';
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: true,
            loading_detail_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            page: 1,
            pageSize: 1,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าเกณฑ์การตรวจสอบ',
                    disabled: false,
                },
                {
                    text: 'เกณฑ์การตรวจสอบคุณภาพ',
                    disabled: false,
                    href: '#',
                },
            ],
            confirm_delete_dialog: false,
            delete_id: null,
            data: [],
            search: '',
           
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        async quality_inspection_criteria_delete(id) {
            this.delete_id = id
            this.confirm_delete_dialog = true;
            this.loading_detail_page = false
        },
        async qualityInspectionCriteriaDeleteSuccess() {
            this.loading_detail_page = true
            qualityinspectioncriteria.deleteQualityInspectionCriteria(this.delete_id).then((response) => {
                if (response.data.successful === true) {
                    this.delete_id = null
                    this.confirm_delete_dialog = false
                    this.loading_detail_page = false
                    this.alert_message_success = 'ลบเกณฑ์การตรวจสอบคุณภาพเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                    this.qualityInspectionCriteriaList()
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async qualityInspectionCriteriaList() {
            this.loading_page = true
            await qualityinspectioncriteria.qualityInspectionCriteriaList(this.page, '', this.search).then((response) => {
                if (response.data.successful === true) {
                    this.pageSize = response.data.pages
                    this.data = response.data.list
                    this.loading_page = false
                }
            })
        },
        async searchList() {
            this.data = []
            this.page = 1
            await qualityinspectioncriteria.qualityInspectionCriteriaList(this.page, '', this.search).then((response) => {
                this.pageSize = response.data.pages
                this.data = response.data.list
                this.page = parseInt(response.data.currentPage)
            })
        }
    },
    components: {
        navigationmenu
    },
    created() {
        this.qualityInspectionCriteriaList()
    },
    watch: {
        page() {
            // this.searchList(),
            this.qualityInspectionCriteriaList()
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.table-header-quality-box-data {
    display: grid;
    grid-template-columns: 2% 25% 29% 21% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-quality-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-quality-box-items {
    display: grid;
    grid-template-columns: 6% 25% 28% 29% 12%;
    align-items: center;
}
</style>