import { HTTP } from "@/axios.js";
import store from "@/store/index.js";
export default {
    detailImportpoList(id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/importpo/import/product/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
}
};
