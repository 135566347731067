import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    // Lot
    getListLot(id){
      return new Promise((resolve, reject) => {
        HTTP.get(
          `/inventory/materials/lot/list/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          }
        )
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    saveAddLot(data){
      return new Promise((resolve, reject) => {
        HTTP.post(`/process/step/two/add/meterial/lot/use`, data, {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        })
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    deleteLot(id){
      return new Promise((resolve, reject) => {
        HTTP.get(
          `/process/step/two/delete/meterial/lot/use/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          }
        )
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    // End Lot

    getStepProcess(id) {
      return new Promise((resolve, reject) => {
        HTTP.get(
          `/process/get/step/process/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          }
        )
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    getProcessCancel: (data) => {
      return HTTP.post('/process/cancel/process', data, {
        headers: {
          "Authorization": `Bearer ${store.state.access_token}`
        }
      })
        .then(response => response)
    },
      getProcessingList(page = "", size = "", search = "") {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/process/?page=${page}&size=${size}&search=${search}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getDatatoEdit(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/process/get/data/step1/for/edit/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      add(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/process/create`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      edit(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/process/update/step1`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
     async getMeterialFormula(id,token) {
        // alert(store.state.access_token)

        // const response = await HTTP.get(`/process/get/material/formula/${id}`, {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // });

        // return response;

        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/material/formula/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },

      async getPrepare(id,token) {
        // alert(store.state.access_token)
        // const response = await HTTP.get(`/process/get/prepare/material/${id}`, {
        //   headers: {
        //     Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjg3ODYxMDgwLCJleHAiOjE2OTA0NTMwODB9.KywlVTOUhdYWFJUnhn0TB7w01pOPch844A9Gf0jpIVA`,
        //   },
        // });

        // return response;

        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/prepare/material/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      
      saveStepThree(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/update/status/process/${id}?status_process=2`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getPrepareDeatail(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/prepare/material/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      addPrepare(data) {
        return new Promise((resolve, reject) => {
          console.log(store.state.access_token);
          HTTP.post(`/process/create/prepare/material`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },

      //ส่วนที่ 3
      getStepOne(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/data/to/start/process/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepTwo(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/maerial/to/drop/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getDetail(id , material_id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/lot/to/drop/${id}?material_id=${material_id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      addStep3(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/process/save/result/process`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },

      //ส่วนที่ 4
      getDetailStep4(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/process/quality/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStep4(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/process/update/process/quality`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      //ส่วนที่ 5 
      getDetailStep5(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/material/quality/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStep5(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/process/update/material/quality`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },

      //ส้วนที่ 6
      getDetail_step1(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/get/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStep6(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/process/confirm/process/success/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
}
     
     