<template>
  <div>
    <ProductionProductGoodConfirmDetail/>
  </div>
</template>

<script>
import ProductionProductGoodConfirmDetail from "@/components/production_process/processing_product_good/ProductionProductGoodConfirmDetail";
export default {
  name: "ProductionProductGoodConfirmDetail_Page",
  components: {ProductionProductGoodConfirmDetail}
}
</script>

<style scoped>

</style>