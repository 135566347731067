import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    getProduct(search) {
      return new Promise((resolve, reject) => {
        HTTP.get(
          `/packing/product/lot?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          }
        )
          .then((response) => {
            const result = response;
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    saveLot(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(
            `/packing/create`, data,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepOne(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/step/one/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStepOne(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(
            `/packing/update`, data,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepTwoListOne(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/step/two/part/one/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveSteptwoPart1(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/save/step/two/part/one/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepTwoPart2(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/step/two/part/two/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStepTwoPart2(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/save/step/two/part/two/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepThreeListOne(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/step/three/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStepThree(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/save/step/three/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepFour1(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/step/four/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepFour2(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/get/maerial/to/drop/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getDetail(id , material_id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/get/lot/to/drop/${id}?material_id=${material_id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStepFour(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(
            `/packing/save/step/four`, data,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepFiveList(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/step/six/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStepFive(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(
            `/packing/save/step/six`, data,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getStepSixList(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/step/five/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStepSix(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(
            `/packing/save/step/five/`, data,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getDetail_step1(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/get/detail/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStep6(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/confirm/success/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getSteppacking(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing/get/step/packing/${id}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getpackingList(page,search) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/packing?page=${page}&search=${search}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getPackingCancel(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(
            `/packing/cancel`, data,
            {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            }
          )
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
}
     
     