import { HTTP } from "@/axios.js";
import store from "@/store/index.js";
export default {
  InspectionCriteriaProductionList: (page, size, search) => {
    return HTTP.get(
      `/quality/facquality/list?page=${page}&size=${size}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      }
    ).then((response) => response);
  },
  InspectionCriteriaProduction: () => {
    return HTTP.get("/quality/facquality/list", {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  addInspectionCriteriaProduction: (data) => {
    return HTTP.post("/quality/facquality/add", data, {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  editInspectionCriteriaProduction: (id, data) => {
    return HTTP.post("/quality/facquality/edit/" + id, data, {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  detailInspectionCriteriaProduction: (id) => {
    return HTTP.get("/quality/facquality/detail/" + id, {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  deleteInspectionCriteriaProduction: (id) => {
    return HTTP.post("/quality/facquality/delete/" + id, null, {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  list: () => {
    return HTTP.get("/quality/facquality/list/all", {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
  list_type: (type_id) => {
    return HTTP.get("/quality/facquality/list/by/type?type="+type_id, {
      headers: {
        Authorization: `Bearer ${store.state.access_token}`,
      },
    }).then((response) => response);
  },
};
