<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ตั้งค่ากลุ่มวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title class="justify-space-between">
                        <b>เพิ่มกลุ่มวัตถุดิบ</b>
                        <div class="ml-3 text-name-title" v-if="onEdit">ผู้แก้ไขล่าสุด :
                            <span class="text-fullname-title">{{ detail.name }}</span> <span class="text-date-title">วันที่
                                :</span>
                            <span class="text-datetime-title">{{ detail.updated_at }}</span>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form_add_or_edit" @submit.prevent="add_or_edit()">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-view-dashboard
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">กลุ่มวัตถุดิบ</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <v-form-group>
                                                <v-text-field type="text" class="input-radius" label="ชื่อกลุ่มวัตถุดิบ"
                                                    v-model="detail.mat_type_name" color="#686868"
                                                    :rules="[v => !!v || 'กรุณากรอกชื่อกลุ่มวัตถุดิบ']" hide-details="auto"
                                                     dense outlined rounded required>
                                                </v-text-field>
                                            </v-form-group>
                                            <div class="mt-1 d-flex pa-2">
                                                <div class="pr-3" style="font-size:16px;">เลือกสีสำหรับกลุ่มวัตถุดิบ<span
                                                        class="text-error">*</span></div>
                                                <v-input-colorpicker v-model="detail.color_code" />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการกลุ่มวัตถุดิบ</b>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field color="#D9D9D9" class="input-radius-search mt-3" prepend-inner-icon="mdi-magnify"
                            @keyup="searchList()" v-model="search" placeholder="ค้นหาที่นี่..." hide-details="auto"
                            single-line outlined dense>
                        </v-text-field>
                        <div class="table-header-raw-material-type-data display-pc mt-3" style="color: #b1adad;">
                            <div class="table-header-raw-material-type-data-text"></div>
                            <div class="table-header-raw-material-type-data-text">
                                ชื่อกลุ่มวัตถุดิบ
                            </div>
                            <div class="table-header-raw-material-type-data-text text-center">
                                สีกลุ่มวัตถุดิบ
                            </div>
                            <div class="table-header-raw-material-type-data-text text-center">
                                จัดการ
                            </div>
                        </div>

                        <v-card elevation="0" class="card-table-radius" v-for="(list, index) in dataList " :key="index">
                            <div class="grid-raw-material-type-items">
                                <div class="w-border"></div>
                                <div>{{ list.mat_type_name }}</div>
                                <div>
                                    <v-system-bar :color="list.color_code" class="radius-15" height="14"></v-system-bar>
                                </div>
                                <div class="text-center">
                                    <v-menu rounded="b-xl" offset-y>
                                        <template v-slot:activator="{ attrs, on }">
                                            <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link @click="matTypeDetail(list.id)">
                                                <v-list-item-title class="f-14" style="color:#FEA06C">
                                                    <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                    แก้ไขข้อมูล
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item link @click="mat_type_delete(list.id)">
                                                <v-list-item-title  class="f-14" style="color:#F28080">
                                                    <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                    ลบ
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                        </v-card>

                        <div class="text-center mt-5">
                            <v-pagination v-model="page" :length="1" :total-visible="7" color="#FFBC15"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- ลบข้อมูล -->
        <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="confirmDelete()">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:14px;" text @click="confirm_delete_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->
    </div>
</template>
<script>
import { onlyForCurrency} from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import matType from '@/api/warehouse/materials_types.js'
export default {
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            onEdit: false,
            detail: [],
            page: 1,
            search: null,
            matType_id: null,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลวัตถุดิบ',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่ากลุ่มวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            confirm_delete_dialog: false,
            dataList: [],
            items: [
                { title: 'Home', icon: 'mdi-home-city' },
                { title: 'My Account', icon: 'mdi-account' },
                { title: 'Users', icon: 'mdi-account-group-outline' },
            ]
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        mat_type_delete(id) {
            this.confirm_delete_dialog = true;
            this.matType_id = id
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        async init_dataList() {
            matType.dataList().then((response) => {
                this.dataList = response.data.materialTypeList
            })
        },
        async matTypeDetail(id) {
            matType.detail(id).then((response) => {
                this.detail = response.data.detail
                this.onEdit = true
            })
        },
        async add_or_edit() {
            if (this.$refs.form_add_or_edit.validate()) {
                this.loading_page = true
                if (!this.detail.id) {
                    const data = {
                        "mat_type_name": this.detail.mat_type_name,
                        "color_code": this.detail.color_code
                    }
                    matType.add(data).then((response) => {
                        if (response.data.successful === true) {
                            this.detail = []
                            this.loading_page = false
                            this.alert_message_success = 'เพิ่มกลุ่มวัตถุดิบเสร็จสิ้น'
                            this.alert_success = true
                            setTimeout(() => this.alert_success = false, 5000)
                            this.init_dataList()
                        }
                        if (response.data.successful === false) {
                            this.alert_message_error = 'ชื่อกลุ่มวัตถุดิบนี้มีอยู่ในระบบแล้ว'
                            this.alert_error = true
                            setTimeout(() => this.alert_error = false, 5000)
                            this.loading_page = false
                        }
                    })
                }
                if (this.detail.id) {
                    const data = {
                        "mat_type_name": this.detail.mat_type_name,
                        "color_code": this.detail.color_code
                    }
                    matType.edit(this.detail.id, data).then((response) => {
                        if (response.data.successful === true) {
                            this.detail = []
                            this.alert_message_success = 'แก้ไขกลุ่มวัตถุดิบเสร็จสิ้น'
                            this.loading_page = false
                            this.alert_success = true
                            setTimeout(() => this.alert_success = false, 5000)
                            this.init_dataList()
                        }
                        if (response.data.successful === false) {
                            this.alert_message_error = 'ชื่อกลุ่มวัตถุดิบนี้มีอยู่ในระบบแล้ว'
                            this.alert_error = true
                            setTimeout(() => this.alert_error = false, 5000)
                            this.loading_page = false
                        }
                    })
                }
            }
        },
        async searchList() {
            if (this.search !== '') {
                const data = {
                    "search": this.search
                }
                matType.search(data).then((response) => {
                    this.dataList = response.data.List
                })
            } else {
                this.page = 1
                this.init_dataList()
            }
        },
        async confirmDelete() {
            this.loading_page = true
            matType.delete(this.matType_id).then((response) => {
                if (response.data.successful === true) {
                    this.confirm_delete_dialog = false
                    this.matType_id = null
                    this.alert_message_success = 'ลบกลุ่มวัตถุดิบ เรียบร้อยแล้ว'
                    this.loading_page = false
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                    this.init_dataList()
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    components: {
        navigationmenu
    },
    created() {
        this.init_dataList()
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.table-header-raw-material-type-data {
    display: grid;
    grid-template-columns: 1% 31% 35% 33%;
    padding: 5px 0px 5px 30px;
}

.table-header-raw-material-type-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-raw-material-type-items {
    display: grid;
    grid-template-columns: 8% 28% 35% 29%;
    align-items: center;
}
</style>