<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายงานการตรวจสอบคุณภาพสินค้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>รายงานการตรวจสอบคณภาพการแปรรูป</b>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text>
                        <v-btn
                            @click="link('/stock/warehouse-stock/warehouse-packaged-detail-raw-material/' + $route.params.id)"
                            class="ma-2 tab-button-disable " type="button" color="#909090" large
                            outlined>รายงานการตรวจสอบคุณภาพ</v-btn>
                        <v-btn
                            @click="link('/stock/warehouse-stock/warehouse-packaged-detail-raw-material-procress/' + $route.params.id)"
                            class="ma-2 tab-button active" type="button" elevation="2" large outlined
                            color="#FFBC15">รายงานการตรวจสอบคณภาพการแปรรูป</v-btn>
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center ">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-file
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายละเอียด {{ data.pro_name }} LOT
                                    {{ data.product_lot }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">เลขที่ใบสั่งแปรรูป :
                                            <span color="6C7FD8" class="text-fullname-title text-blud"><a
                                                    href="#" @click="link('/stock/product-import-purchase-order-detail/' + data.production_id)">{{
                                                        data.production_code }}</a></span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">ผู้ดำเนินการ :
                                            <span class="text-fullname-title">{{ data.user_name }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card class="mt-8" elevation="4" style="border-radius:20px;overflow-wrap: unset !important;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">การตรวจสอบคุณภาพการแปรรูป</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pb-0">
                                        <div style="width: 100%;">
                                            <div class="table-header-lotoutstock-process-data display-pc pb-0 "
                                                style="color: #b1adad;">
                                                <div class="table-header-lotoutstock-process-data-text pb-0"></div>
                                                <div class="table-header-lotoutstock-process-data-text pb-0">
                                                    รายการตรวจสอบคุณภาพ
                                                </div>
                                                <div class="table-header-lotoutstock-process-data-text pb-0">
                                                    เกณฑ์คุณภาพ
                                                </div>
                                                <div class="table-header-lotoutstock-process-data-text pb-0">
                                                    ผลการตรวจสอบ
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                                        v-for="(quality_loop, index) in quality_list" :key="index">
                                        <v-card elevation="2" class=" display-pc b-radius-20 py-4 mx-5 mb-3">
                                            <div class="table-header-lotoutstock-process-data pb-2">
                                                <div class="table-header-lotoutstock-process-data-text"></div>
                                                <div class="table-header-lotoutstock-process-data-text bold">{{
                                                    quality_loop.fq_name }}</div>
                                            </div>

                                            <div class="mx-5 ">
                                                <v-divider class="mb-2" color="#686868"></v-divider>
                                            </div>

                                            <div class="table-header-lotoutstock-process-data pb-0"
                                                v-for="(quality_loop_list, index_sub) in quality_loop.quality_list"
                                                :key="index_sub">
                                                <div class="table-header-lotoutstock-process-data-text"></div>
                                                <div class="table-header-lotoutstock-process-data-text">{{
                                                    quality_loop_list.fq_list_name }}</div>
                                                <div class="table-header-lotoutstock-process-data-text">{{
                                                    quality_loop_list.fq_list_guide }}</div>
                                                <div class="table-header-lotoutstock-process-data-text">{{
                                                    quality_loop_list.value != null ? quality_loop_list.value : '-' }}</div>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency} from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import product from '@/api/warehouse/product.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',

            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังสินค้าที่บรรจุภัณฑ์แล้ว',
                    disabled: false,
                },
                {
                    text: 'รายละเอียดสินค้า',
                    disabled: false,
                },
                {
                    text: 'รายงานการตรวจสอบคุณภาพสินค้า',
                    disabled: false,
                    href: '#',
                },
            ],
            page: 1,
            type: 1,
            data: [],
            quality_list: [],
        }
    },
    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        getProductLotDetail() {
            this.loading_page = true;
            let id = parseFloat(this.$route.params.id);
            product.getProductLotDetail(id, this.type).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.data;
                    this.quality_list = response.data.quality;
                    this.loading_page = false;
                }
            })
        },
    },
    mounted() {
        this.getProductLotDetail();
    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.text-aim {
    color: #FFB98A;
}

.text-green {
    color: #00B050;
}

.text-yellow {
    color: #DBB84D;
}

.text-red {
    color: #C00000
}

.text-blud {
    color: #6C7FD8
}

.b-radius-20 {
    border-radius: 20px;
}

.table-header-lotoutstock-process-data {
    display: grid;
    grid-template-columns: 3% 30% 30% 30%;
    padding: 10px;
}

.table-header-lotoutstock-process-data-text {
    color: #000;
    font-size: 14px;
}

.grid-lotoutstock-process-items {
    display: grid;
    grid-template-columns: 3% 30% 30% 30%;
    align-items: center;
    padding: 15px;
}
</style>