<template>
    <div>
      <ProductCreatePurchaseOrderAddPage/>
    </div>
  </template>
  
  <script>
  import ProductCreatePurchaseOrderAddPage from '@/components/stock/product_import/ProductCreatePurchaseOrderAddComponent'
  export default {
    name: 'Productcreatepurchaseorderadd',
    components:{
      ProductCreatePurchaseOrderAddPage,
    }
  }
  </script>