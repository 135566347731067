<template>
  <div class="loading_all_page" v-if="loading_page === true">
      <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
      <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการผลิตสินค้า</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/pro/processing_product_good')">การผลิตสินค้า</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการผลิตสินค้า</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนที่ 5 : การตรวจสอบสินค้าที่ผลิตสำเร็จ</b>
        </v-card-title>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/quality (1).png">
              </v-avatar>
              <span class="ml-3 title-card-text">การตรวจสอบคุณภาพการผลิต</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing mt-6" style="color: #b1adad">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์</div>
                  <div class="table-sub-header-processing">เกณฑ์คุณภาพ</div>
                  <div class="table-sub-header-processing">Value</div>
                </div>

                <v-card elevation="4" class="my-5" style="border-radius:20px" v-for="(item, index) in dataList" :key="index">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">{{item.fq_name}}</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items ma-2 pl-3 pr-3 py-2" v-for="(detail, index_sub) in item.quality_list" :key="index_sub">
                    <div class="black--text font-weight-bold">{{ detail.fq_list_name }}</div>
                    <div>{{ detail.fq_list_guide }}</div>
                    <div>
                      <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == null" class="mb-0"><v-icon @click="getcheck(index, index_sub, 1)" color="#88B358">mdi-checkbox-blank-circle-outline</v-icon></p>
                      <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 0 && detail.fq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(index, index_sub, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                      <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 0 && detail.fq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(index, index_sub, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                      <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(index, index_sub, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                      <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(index, index_sub, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                      <v-text-field v-if="detail.fq_list_type == 1" class="input-radius" color="#FFBC15" label="value" v-model="detail.value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
                
                <div class="my-3">
                  <div class="black--text font-weight-bold">หมายเหตุ (Remark)</div>
                  <v-textarea class="input-radius py-2" label="หมายเหตุ (Remark)"  v-model="quality_production_remark" color="#FFBC15" outlined rounded rows="3" row-height="25"></v-textarea>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="saveData()">
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import process from '@/api/product_good/product_process_step_six.js'
import Swal from "sweetalert2";
export default {
  name: "ProductionProductGoodProcessRecheck",
  data() {
    return {
      loading_page: true,
      dataList: [],
      quality_production_remark:null,
      test:[],
      
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/production_confirm')
    },
    link(link) {
      this.$router.push(link)
    },
    getcheck(i, index, num){
      this.loading_page = true;
      this.dataList[i].quality_list[index].fq_value_boolean = num;
      this.loading_page = false;
    },

    getStepSixList(){
      let id = parseFloat(this.$route.params.id);
      this.loading_page = true;
      process.getStepSixList(id).then((response) => {
        if(response.status == 502){
        window.location.reload()
      }
        if(response.data.successful === true){
          this.dataList = response.data.quality
        
          this.quality_production_remark = response.data.quality_production_remark
          for(let i = 0; i < this.dataList.length; i++){
            for(let x = 0; x < this.dataList[i].quality_list.length; x++){
              if(this.dataList[i].quality_list[x].fq_list_type == 0 && this.dataList[i].quality_list[x].fq_value_status == 0){
                this.dataList[i].quality_list[x] = {
                    production_id: this.dataList[i].quality_list[x].production_id,
                    product_id: this.dataList[i].quality_list[x].product_id,
                    fq_id: this.dataList[i].quality_list[x].fq_id,
                    fq_list_id: this.dataList[i].quality_list[x].fq_list_id,
                    fq_list_name: this.dataList[i].quality_list[x].fq_list_name,
                    fq_list_guide: this.dataList[i].quality_list[x].fq_list_guide,
                    fq_list_type: this.dataList[i].quality_list[x].fq_list_type,
                    fq_value_status: this.dataList[i].quality_list[x].fq_value_status,
                    value:this.dataList[i].quality_list[x].value,
                    fq_value_boolean: null
                }
              }
            }
          }
          this.loading_page = false;
        }
      }).catch((error) => {
             if(error.response.status === 502){
                window.location.reload()
              }
          })
    },

    saveData(){
      this.loading_page = true;
      let quality_list_array = [];
      for(let i = 0; i < this.dataList.length; i++){
        for(let x = 0; x < this.dataList[i].quality_list.length; x++){
          quality_list_array.push({
            id: this.dataList[i].quality_list[x].id,
            production_id:this.$route.params.id,
            product_id: this.dataList[i].quality_list[x].product_id,
            fq_id: this.dataList[i].quality_list[x].fq_id,
            fq_list_id: this.dataList[i].quality_list[x].fq_list_id,
            fq_list_type: this.dataList[i].quality_list[x].fq_list_type,
            fq_value_status: this.dataList[i].quality_list[x].fq_value_status,
            value: this.dataList[i].quality_list[x].value,
            fq_value_boolean: this.dataList[i].quality_list[x].fq_value_boolean
          })
        }
      }

      let data = {
        production_id:this.$route.params.id,
        quality_list:quality_list_array,
        quality_production_remark:this.quality_production_remark
      }
      // this.test = data
      
      process.getStepSixAdd(data).then((response) => {
          if(response.data.successful === true){
              let timerInterval;
              Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                // this.$router.push("/pro/processing_product_good_steps/"+this.$route.params.id);
                window.location.href = "/pro/processing_product_good_steps/" + this.$route.params.id;
              // window.location.reload(); 
              }
            });
              this.loading_page = false;
          }
      })
    }

  },
  mounted(){
    this.getStepSixList();
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  align-items: baseline;
}
</style>