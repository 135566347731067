<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ตั้งค่าขนาดสินค้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการขนาดสินค้า</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" @keyup="searchList()"
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                <div class="d-flex justify-end" v-if="$store.state.profileDetail.per_product_size == 1 || $store.state.profileDetail.permission == 1">
                                    <v-btn class="mt-2" large rounded outlined color="#88B358" @click="popAddModal()">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มขนาดสินค้า
                                    </v-btn>
                                </div>
                                <div class="d-flex justify-end" v-else>
                                    <v-btn class="mt-2" large rounded  color="#d0d0d0" >
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มขนาดสินค้า
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-product-size-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-product-size-box-data-text"></div>
                                    <div class="table-header-product-size-box-data-text">
                                        ชื่อขนาดสินค้า
                                    </div>
                                    <div class="table-header-product-size-box-data-text">
                                        ปริมาณการบรรจุ
                                    </div>
                                    <div class="table-header-product-size-box-data-text">
                                        ปริมาตร (กว้าง x ยาว x สูง)
                                    </div>
                                    <div class="table-header-product-size-box-data-text">
                                        หน่วยนับ
                                    </div>
                                    <div class="table-header-product-size-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <!-- list --> <v-card elevation="0" class="card-table-radius"
                                    v-for="(list, index) in dataList" :key="index">
                                    <div class="grid-product-size-box-items">
                                        <div class="w-border"></div>
                                        <div>{{ list.pro_size_name }}</div>
                                        <div>{{ formatPrice(parseFloat(list.pro_amount)) }}</div>
                                        <div>{{ list.pro_wide }} x {{ list.pro_long }} x {{ list.pro_high }} เซนติเมตร</div>
                                        <div>{{ list.unit_name }}</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }" v-if="$store.state.profileDetail.per_product_size == 1 || $store.state.profileDetail.permission == 1">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:activator="{ not_premissin, not_premissin_arr }" v-else>
                                                    <v-btn elevation="0" color="#d0d0d0" v-bind="not_premissin" v-on="not_premissin_arr" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list v-if="$store.state.profileDetail.per_product_size == 1 || $store.state.profileDetail.permission == 1">
                                                    <v-list-item link @click="productSeizeDetail(list.id)">
                                                        <v-list-item-title class="f-14" style="color:#FEA06C">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item link @click="productSizeDelete(list.id)">
                                                        <v-list-item-title class="f-14" style="color:#F28080">
                                                            <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                            ลบ
                                                        </v-list-item-title>
                                                    </v-list-item> -->
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- เพิ่มข้อมูล -->
        <v-dialog v-model="product_size_add" transition="dialog-top-transition" max-width="667" style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>เพิ่มขนาดสินค้า</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="product_size_add = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-form ref="form_add" @submit.prevent="confirmAdd()">
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-package-variant-closed
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ข้อมูลขนาดสินค้า</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อขนาดสินค้า"
                                                v-model="product_size_detail.pro_size_name" color="#FFBC15"
                                                :rules="[v => !!v || 'กรุณากรอกชื่อขนาดสินค้า']" hide-details="auto"
                                                 dense outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="ปริมาณการบรรจุ"
                                                placeholder="ปริมาณการบรรจุ" minlength="1" maxlength="5"
                                                v-model="product_size_detail.pro_amount"
                                                :rules="[v => !!v || 'กรุณากรอกปริมาณการบรรจุ']"
                                                @keypress="isOnlyNumber($event, wide)" hide-details="auto" suffix="g" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-autocomplete color="#FFBC15" :items="items" item-text="unit_name"
                                                item-value="id" v-model="product_size_detail.unit_id"
                                                :rules="[v => !!v || 'กรุณาเลือกหน่วยนับ']" label="เลือกหน่วยนับ"
                                                hide-details="auto" dense outlined rounded required></v-autocomplete>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card class="mt-5" elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-database
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ปริมาตรของขนาดสินค้า</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="4">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="กว้าง"
                                                placeholder="กว้าง" minlength="1" maxlength="5"
                                                v-model="product_size_detail.pro_wide"
                                                :rules="[v => v === '0' || !!v || 'กรุณากรอกความกว้าง', v => /^\d+(\.\d+)?$/.test(v) || 'กรุณากรอกตัวเลขทศนิยม']"
                                                hide-details="auto" suffix="cm" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="4">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="ยาว" placeholder="ยาว"
                                                v-model="product_size_detail.pro_long" minlength="1" maxlength="5"
                                                :rules="[v => v === '0' || !!v || 'กรุณากรอกความยาว', v => /^\d+(\.\d+)?$/.test(v) || 'กรุณากรอกตัวเลขทศนิยม']"
                                                hide-details="auto" suffix="cm" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="4">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="สูง" placeholder="สูง"
                                                v-model="product_size_detail.pro_high" minlength="1" maxlength="5"
                                                :rules="[v => v === '0' || !!v || 'กรุณากรอกความสูง', v => /^\d+(\.\d+)?$/.test(v) || 'กรุณากรอกตัวเลขทศนิยม']"
                                                hide-details="auto" suffix="cm" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการเพิ่ม -->
        <!-- แก้ไขข้อมูล -->
        <v-dialog v-model="product_size_edit" transition="dialog-top-transition" max-width="667"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b class="ml-5">แก้ไขขนาดสินค้า</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="product_size_edit = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-form ref="form_edit" @submit.prevent="confirmEdit()">
                    <v-card-text>
                        <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด
                            <span class="text-fullname-title">{{ product_size_detail.name }}</span> <span
                                class="text-date-title">วันที่ :
                            </span>
                            <span class="text-datetime-title" v-if="product_size_detail.updated_at == null">
                                {{ product_size_detail.updated_at }}
                            </span>
                            <span class="text-datetime-title" v-else>
                                {{`${(product_size_detail.updated_at.split('-')[2]).split(' ')[0]}-${product_size_detail.updated_at.split('-')[1]}-${product_size_detail.updated_at.split('-')[0]} ${(product_size_detail.updated_at.split('-')[2]).split(' ')[1]}`}}
                            </span>
                           </div>
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-archive-settings
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ข้อมูลขนาดสินค้า</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อกล่องขนส่ง"
                                                v-model="product_size_detail.pro_size_name" color="#FFBC15"
                                                :rules="[v => !!v || 'กรุณากรอกชื่อกล่องขนส่ง']" hide-details="auto"
                                                 dense outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="ปริมาณการบรรจุ"
                                                placeholder="ปริมาณการบรรจุ" minlength="1" maxlength="5"
                                                v-model="product_size_detail.pro_amount"
                                                :rules="[v => !!v || 'กรุณากรอกปริมาณการบรรจุ']"
                                                @keypress="isOnlyNumber($event, wide)" hide-details="auto" suffix="g" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <v-form-group>
                                            <v-autocomplete color="#FFBC15" :items="items" item-text="unit_name"
                                                item-value="id" v-model="product_size_detail.unit_id"
                                                :rules="[v => !!v || 'กรุณาเลือกหน่วยนับ']" label="เลือกหน่วยนับ"
                                                hide-details="auto" dense outlined rounded required></v-autocomplete>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card class="mt-5" elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-package-variant-closed
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ปริมาตรของขนาดสินค้า</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="4">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="กว้าง"
                                                placeholder="กว้าง" minlength="1" maxlength="5"
                                                v-model="product_size_detail.pro_wide"
                                                :rules="[v => v === '0' || !!v || 'กรุณากรอกความกว้าง', v => /^\d+(\.\d+)?$/.test(v) || 'กรุณากรอกตัวเลขทศนิยม']"
                                                @keypress="isOnlyNumber($event, wide)" hide-details="auto" suffix="cm" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="4">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="ยาว" placeholder="ยาว"
                                                v-model="product_size_detail.pro_long" minlength="1" maxlength="5"
                                                :rules="[v => v === '0' || !!v || 'กรุณากรอกความยาว', v => /^\d+(\.\d+)?$/.test(v) || 'กรุณากรอกตัวเลขทศนิยม']"
                                                @keypress="isOnlyNumber($event, long)" hide-details="auto" suffix="cm" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="4">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="สูง" placeholder="สูง"
                                                v-model="product_size_detail.pro_high" minlength="1" maxlength="5"
                                                :rules="[v => v === '0' || !!v || 'กรุณากรอกความสูง', v => /^\d+(\.\d+)?$/.test(v) || 'กรุณากรอกตัวเลขทศนิยม']"
                                                @keypress="isOnlyNumber($event, high)" hide-details="auto" suffix="cm" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการแก้ไข -->
        <!-- ลบข้อมูล -->
        <v-dialog v-model="product_size_delete" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="product_size_delete = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="confirmDelete()">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:16px;" text @click="product_size_delete = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice} from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import productSize from '@/api/warehouse/productSize.js'
import unitList from '@/api/warehouse/unit.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            loading_detail_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            productSizeDelete_id: null,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลสินค้า',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าขนาดสินค้า',
                    disabled: false,
                    href: '#',
                },
            ],
            items: [],
            page: 1,
            pageSize: 1,
            product_size_add: false,
            product_size_edit: false,
            product_size_delete: false,
            dataList: [],
            product_size_detail: [],
            search: ''
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        formatPrice: function (number) {
            return formatPrice(number)
        },
        productSizeList() {
            productSize.dataList(this.page, '', this.search).then((response) => {
                this.dataList = response.data.pdSizeList
                this.pageSize = response.data.pages
                this.page = parseFloat(response.data.currentPage)
            })
            unitList.list().then((response) => {
                this.items = response.data.data
            })
        },
        productSeizeDetail(id) {
            this.product_size_edit = []
            productSize.detail(id).then((response) => {
                this.product_size_detail = response.data.detail
                this.product_size_edit = true
            })
        },
        async confirmEdit() {
         
            if (this.$refs.form_edit.validate()) {
                this.loading_page = true
                const data = {
                    "pro_size_name": this.product_size_detail.pro_size_name,
                    "pro_amount": this.product_size_detail.pro_amount,
                    "pro_wide": this.product_size_detail.pro_wide,
                    "pro_long": this.product_size_detail.pro_long,
                    "pro_high": this.product_size_detail.pro_high,
                    "unit_id": this.product_size_detail.unit_id
                }
                await productSize.edit(this.product_size_detail.id, data).then((response) => {
                    if (response.data.successful === true) {
                        this.product_size_detail = []
                        this.product_size_edit = false
                        this.loading_page = false
                        this.alert_message_success = "แก้ไขรายการขนาดสินค้าเสร็จสิ้น"
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.productSizeList()
                    }else if(response.data.successful === false){
                         this.loading_page = false
                        this.alert_message_error = "ชื่อขนาดสินค้ามีอยู่ในระบบแล้ว"
                        this.alert_error = true
                        setTimeout(() => this.alert_error = false, 5000)
                    }
                })
            }
        },
        async confirmAdd() {
            if (this.$refs.form_add.validate()) {
                this.loading_page = true
                const data = {
                    "pro_size_name": this.product_size_detail.pro_size_name,
                    "pro_amount": this.product_size_detail.pro_amount,
                    "pro_wide": this.product_size_detail.pro_wide,
                    "pro_long": this.product_size_detail.pro_long,
                    "pro_high": this.product_size_detail.pro_high,
                    "unit_id": this.product_size_detail.unit_id
                }
                await productSize.add(data).then((response) => {
                    console.log(response.data);
                    if (response.data.successful === true) {
                        this.product_size_detail = []
                        this.product_size_add = false
                        this.loading_page = false
                        this.alert_message_success = "เพิ่มรายการขนาดสินค้าเสร็จสิ้น"
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.productSizeList()
                    }else if(response.data.successful === false){
                        this.loading_page = false
                        this.alert_message_error = "ชื่อขนาดสินค้ามีอยู่ในระบบแล้ว"
                        this.alert_error = true
                        setTimeout(() => this.alert_error = false, 5000)
                    }
                })
            }
        },
        popAddModal() {
            this.product_size_detail = []
            this.product_size_add = true
        },
        productSizeDelete(id) {
            this.product_size_delete = true
            this.productSizeDelete_id = id
        },
        async confirmDelete() {
            console.log(this.productSizeDelete_id);
            await productSize.delete(this.productSizeDelete_id).then((response) => {
                console.log();
                if (response.data.successful === true) {
                    this.alert_message_success = "ลบรายการขนาดสินค้าเสร็จสิ้น"
                    this.alert_success = true
                    this.product_size_delete = false
                    setTimeout(() => this.alert_success = false, 5000)
                    this.productSizeList()
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    console.log(error.response);
                    this.$store.commit('logOutSuccess')
                    // this.$router.push('/login')
                }
            })

            
        },
        async searchList() {
            this.page = 1
            this.dataList = []
            productSize.dataList(this.page, '', this.search).then((response) => {
                this.dataList = response.data.pdSizeList
                this.pageSize = response.data.pages
                this.page = parseFloat(response.data.currentPage)
            })
        }
    },
    components: {
        navigationmenu
    },
    mounted() {
        this.productSizeList()
    },
    watch: {
        page() {
            this.productSizeList()
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-product-size-box-data {
    display: grid;
    grid-template-columns: 0% 22% 23% 26% 15% 14%;
    padding: 5px 0px 5px 30px;
}

.table-header-product-size-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-product-size-box-items {
    display: grid;
    grid-template-columns: 4% 22% 22% 25% 19% 8%;
    align-items: center;
}
</style>