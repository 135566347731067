import ReportStock from "@/views/stock/report_stock/ReportStock.vue";
import QualityInspectionReportList from "@/views/stock/quality_inspection_report/QualityInspectionReportList.vue";
import QualityInspectionReportDetail from "@/views/stock/quality_inspection_report/QualityInspectionReportDetail.vue";
import QualityInspectionProductionReportList from "@/views/stock/quality_inspection_report/QualityInspectionProductionReportList.vue";
import QualityInspectionProductionReportDetail from "@/views/stock/quality_inspection_report/QualityInspectionProductionReportDetail.vue";
import Unit from "@/views/stock/setting_stock/Unit.vue";
// import TransportBox from "@/views/stock/setting_stock/TransportBox.vue";
import Qualityinspectioncriteria from "@/views/stock/setting_stock/QualityInspectionCriteria.vue";
import Qualityinspectioncriteriaadd from "@/views/stock/setting_stock/QualityInspectionCriteriaAdd.vue";
import Qualityinspectioncriteriaedit from "@/views/stock/setting_stock/QualityInspectionCriteriaEdit.vue";
import Qualityinspectioncriteriadetail from "@/views/stock/setting_stock/QualityInspectionCriteriaDetail.vue";

import Inspectioncriteriaproduction from "@/views/stock/setting_stock/InspectionCriteriaProduction.vue";
import Inspectioncriteriaproductionadd from "@/views/stock/setting_stock/InspectionCriteriaProductionAdd.vue";
import Inspectioncriteriaproductionedit from "@/views/stock/setting_stock/InspectionCriteriaProductionEdit.vue";
import Inspectioncriteriaproductiondetail from "@/views/stock/setting_stock/InspectionCriteriaProductionDetail.vue";

import Producttype from "@/views/stock/setting_stock/ProductType.vue";
import Productsize from "@/views/stock/setting_stock/ProductSize.vue";

import SetRawMaterialAdd from "@/views/stock/setting_stock/SetRawMaterialAdd.vue";
import SetRawMaterialEdit from "@/views/stock/setting_stock/SetRawMaterialEdit.vue";
import SetRawMaterialDetail from "@/views/stock/setting_stock/SetRawMaterialDetail.vue";

import SettingRawMaterial from "@/views/stock/setting_stock/SettingRawMaterial.vue";
import SettingRawMaterialAdd from "@/views/stock/setting_stock/SettingRawMaterialAdd";
import SettingRawMaterialEdit from "@/views/stock/setting_stock/SettingRawMaterialEdit";
import SettingRawMaterialDetail from "@/views/stock/setting_stock/SettingRawMaterialDetail";
import SetProductComponent from "@/views/stock/setting_stock/SetProductComponent.vue";
import SetProductAdd from "@/views/stock/setting_stock/SetProductAdd.vue";
import SetProductEdit from "@/views/stock/setting_stock/SetProductEdit.vue";
import SetProductDetail from "@/views/stock/setting_stock/SetProductDetail.vue";

import Supplier from "@/views/stock/setting_stock/Supplier.vue";
import RawMaterialType from "@/views/stock/setting_stock/RawMaterialType.vue";
import Warehouse from "@/views/stock/warehouse_stock/Warehouse.vue";
import WarehouseRawmaterial from "@/views/stock/warehouse_stock/WarehouseRawmaterial.vue";
import WarehouseRawmaterialProductOut from "@/views/stock/warehouse_stock/WarehouseRawmaterialProductOut.vue";
import WarehouseUnpackaged from "@/views/stock/warehouse_stock/WarehouseUnpackaged.vue";
import WarehouseUnpackagedDetail from "@/views/stock/warehouse_stock/WarehouseUnpackagedDetail.vue";
import WarehouseUnpackagedRawMaterial from "@/views/stock/warehouse_stock/WarehouseUnpackagedRawMaterial.vue";
import WarehouseUnpackagedDetailHistoryRawMaterial from "@/views/stock/warehouse_stock/WarehouseUnpackagedDetailHistoryRawMaterial.vue";
import WarehouseUnpackagedDetailHistoryRawMaterialAudit from "@/views/stock/warehouse_stock/WarehouseUnpackagedDetailHistoryRawMaterialAudit.vue";
import WarehouseUnpackagedDetailHistoryRawMaterialAuditInspection from "@/views/stock/warehouse_stock/WarehouseUnpackagedDetailHistoryRawMaterialAuditInspection.vue";
import WarehouseUnpackagedRawMaterialProcress from "@/views/stock/warehouse_stock/WarehouseUnpackagedRawMaterialProcress.vue";
import WarehouseUnpackagedDetailHistoryRawMaterialAuditProcress from "@/views/stock/warehouse_stock/WarehouseUnpackagedDetailHistoryRawMaterialAuditProcress";

import WarehousePackaged from "@/views/stock/warehouse_stock/WarehousePackaged.vue";
import WarehousePackagedDetail from "@/views/stock/warehouse_stock/WarehousePackagedDetail.vue";
import WarehousePackagedDetailLotUsed from "@/views/stock/warehouse_stock/WarehousePackagedDetailLotUsed.vue";
import WarehousePackagedRawMaterial from "@/views/stock/warehouse_stock/WarehousePackagedRawMaterial.vue";
import WarehousePackagedRawMaterialProcress from "@/views/stock/warehouse_stock/WarehousePackagedRawMaterialProcress.vue";
import WarehousePackagedRawMaterialAuditInspection from "@/views/stock/warehouse_stock/WarehousePackagedRawMaterialAuditInspection.vue";
import WarehousePackagedRawMaterialAuditProcress from "@/views/stock/warehouse_stock/WarehousePackagedRawMaterialAuditProcress.vue";
import WarehousePackagedHistoryRawMaterial from "@/views/stock/warehouse_stock/WarehousePackagedHistoryRawMaterial.vue";

import WarehouseDetail from "@/views/stock/warehouse_stock/WarehouseDetail.vue";
import WarehouseLotInStockRawMaterial from "@/views/stock/warehouse_stock/WarehouseLotInStockRawMaterial.vue";
import WarehouseLotSlodOutRawMaterial from "@/views/stock/warehouse_stock/WarehouseLotSlodOutRawMaterial.vue";
import WarehouseLotSlodOutRawMaterialProcess from "@/views/stock/warehouse_stock/WarehouseLotSlodOutRawMaterialProcess.vue";
import WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction from "@/views/stock/warehouse_stock/WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction.vue";
import WarehouseDetailLotUsed from "@/views/stock/warehouse_stock/WarehouseDetailLotUsed.vue";
import WarehouseDetailUnpackagedLotUsed from "@/views/stock/warehouse_stock/WarehouseDetailUnpackagedDetailLotUsed.vue";
import WarehouseDetailHistoryRawMaterial from "@/views/stock/warehouse_stock/WarehouseDetailHistoryRawMaterial.vue";
import WarehouseDetailHistoryRawMaterialStockProduct from "@/views/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialStockProduct.vue";
import WarehouseDetailHistoryRawMaterialAudit from "@/views/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialAudit.vue";
import WarehouseDetailHistoryRawMaterialAuditInspection from "@/views/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialAuditInspection.vue";
import WarehouseDetailHistoryRawMaterialAuditProcess from "@/views/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialAuditProcess.vue";

import OrderList from "@/views/stock/order/OrderList.vue";
import AddOrderList from "@/views/stock/order/AddOrderList.vue";
import SummaryAddOrderList from "@/views/stock/order/SummaryAddOrderList.vue";
import EditOrderList from "@/views/stock/order/EditOrderList.vue";
import DetailOrderList from "@/views/stock/order/DetailOrderList.vue";

import MaterialImport from "@/views/stock/material_import/MaterialImport";
import MaterialImportPurchaseOrderAdd from "@/views/stock/material_import/MaterialImportPurchaseOrderAdd";
import MaterialPurchaseOrderEdit from "@/views/stock/material_import/MaterialPurchaseOrderEdit";
import MaterialImportPurchaseOrderEdit from "@/views/stock/material_import/MaterialImportPurchaseOrderEdit";
import MaterialImportPurchaseOrderDetail from "@/views/stock/material_import/MaterialImportPurchaseOrderDetail";

import MaterialCreatePurchaseOrderAdd from "@/views/stock/material_import/MaterialCreatePurchaseOrderAdd";

//import product
import ProductImport from "@/views/stock/product_import/ProductImport";
import ProductCreatePurchaseOrderAdd from "@/views/stock/product_import/ProductCreatePurchaseOrderAdd";
import ProductCreatePurchaseOrderEdit from "@/views/stock/product_import/ProductCreatePurchaseOrderEdit";
import ProductImportPurchaseOrderDetail from "@/views/stock/product_import/ProductImportPurchaseOrderDetail";

export default [
  // คลัง -> รายงานคลัง
  {
    path: "/stock/report-stock",
    name: "reportstock",
    component: ReportStock,
  },
  // คลัง -> รายงานการตรวจสอบคุณภาพ
  {
    path: "/stock/quality-inspection-report-list",
    name: "qualityinspectionreportlist",
    component: QualityInspectionReportList,
  },
  // คลัง -> รายงานการตรวจสอบคุณภาพ
  {
    path: "/stock/quality-inspection-report-detail",
    name: "qualityinspectionreportdetail",
    component: QualityInspectionReportDetail,
  },
  {
    path: "/stock/quality-inspection-production-report-list",
    name: "qualityinspectionproductionreportlist",
    component: QualityInspectionProductionReportList,
  },
  {
    path: "/stock/quality-inspection-production-report-detail",
    name: "qualityinspectionproductionreportdetail",
    component: QualityInspectionProductionReportDetail,
  },
  {
    // คลัง -> นำเข้าวัตถุดิบ
    path: "/stock/material-import",
    name: "materialimport",
    component: MaterialImport,
  },
  {
    // คลัง -> นำเข้าสินค้า
    path: "/stock/product-import",
    name: "productimport",
    component: ProductImport,
  },
  {
    // คลัง -> นำเข้าวัตถุดิบจากใบสั่งซื้อ
    path: "/stock/material-import-purchase-order-add",
    name: MaterialImportPurchaseOrderAdd,
    component: MaterialImportPurchaseOrderAdd,
  },
  {
    // คลัง -> แก้ไขวัตถุดิบจากใบสั่งซื้อ อ้างอิง
    path: "/stock/material-import-purchase-order-edit/:id",
    name: MaterialImportPurchaseOrderEdit,
    component: MaterialImportPurchaseOrderEdit,
  },
  {
    // คลัง -> แก้ไขวัตถุดิบจากใบสั่งซื้อ ไม่อ้างอิง
    path: "/stock/material-purchase-order-edit/:id",
    name: MaterialPurchaseOrderEdit,
    component: MaterialPurchaseOrderEdit,
  },
  {
    // คลัง -> รายละเอียดวัตถุดิบจากใบสั่งซื้อ
    path: "/stock/material-import-purchase-order-detail/:id/:status",
    name: MaterialImportPurchaseOrderDetail,
    component: MaterialImportPurchaseOrderDetail,
  },
  {
    // คลัง -> สร้างรายการเพิ่มวัตถุดิบเข้าคลัง
    path: "/stock/material-create-purchase-order-add",
    name: MaterialCreatePurchaseOrderAdd,
    component: MaterialCreatePurchaseOrderAdd,
  },
  {
    // คลัง -> สร้างรายการเพิ่มสินค้าเข้าคลัง
    path: "/stock/product-create-purchase-order-add",
    name: ProductCreatePurchaseOrderAdd,
    component: ProductCreatePurchaseOrderAdd,
  },
  {
    // คลัง -> สร้างรายการเพิ่มสินค้าเข้าคลัง
    path: "/stock/product-create-purchase-order-edit/:id",
    name: ProductCreatePurchaseOrderEdit,
    component: ProductCreatePurchaseOrderEdit,
  },
  {
    // คลัง -> รายละเอียดสินค้าจากใบสั่งซื้อ
    path: "/stock/product-import-purchase-order-detail/:id",
    name: ProductImportPurchaseOrderDetail,
    component: ProductImportPurchaseOrderDetail,
  },
  {
    // คลัง -> ใบสั่งซื้อ
    path: "/stock/order-list",
    name: "orderlist",
    component: OrderList,
  },
  {
    // คลัง -> สร้างใบสั่งซื้อ
    path: "/stock/order-list/add",
    name: "addorderlist",
    component: AddOrderList,
  },
  {
    // คลัง -> สร้างใบสั่งซื้อ
    path: "/stock/order-list/add/summary",
    name: "summaryaddorderlist",
    component: SummaryAddOrderList,
  },
  {
    // คลัง -> แก้ไขใบสั่งซื้อ
    path: "/stock/order-list/edit/:id",
    name: "editorderlist",
    component: EditOrderList,
  },
  {
    // คลัง -> เนื้อหาใบสั่งซื้อ
    path: "/stock/order-list/detail/:id",
    name: "detailorderlist",
    component: DetailOrderList,
  },

  {
    // คลังสินค้า -> คลัง
    path: "/stock/warehouse-stock/warehouse",
    name: "warehouse",
    component: Warehouse,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่ยังไม่บรรจุภัณฑ์
    path: "/stock/warehouse-stock/warehouse-unpackaged",
    name: "warehouse_unpackaged",
    component: WarehouseUnpackaged,
  },
  {
    // คลัง -> คลังสินค้า -> ประวัติสินค้า
    path: "/stock/warehouse-stock/warehouse-unpackaged-history-raw-material/:id",
    name: "warehouse_unpackaged_history_raw_material",
    component: WarehouseUnpackagedDetailHistoryRawMaterial,
  },
  {
    // คลัง -> คลังสินค้า -> ประวัติสินค้า
    path: "/stock/warehouse-stock/warehouse-unpackaged-history-raw-material-audit/:id",
    name: "warehouse_unpackaged_history_raw_material_audit",
    component: WarehouseUnpackagedDetailHistoryRawMaterialAudit,
  },
  {
    // คลัง -> คลังสินค้า -> รายงานการตรวจสอบคุณภาพวัตถุดิบ
    path: "/stock/warehouse-stock/warehouse-unpackaged-history-raw-material-audit-inspection/:id",
    name: "warehouse_unpackaged_history_raw_material_audit_inspection",
    component: WarehouseUnpackagedDetailHistoryRawMaterialAuditInspection,
  },
  {
    // คลัง -> คลังสินค้า -> รายงานการตรวจสอบคุณภาพแปรรูป
    path: "/stock/warehouse-stock/warehouse-unpackaged-history-raw-material-audit-procress/:id",
    name: "warehouse_unpackaged_history_raw_material_audit_procress",
    component: WarehouseUnpackagedDetailHistoryRawMaterialAuditProcress,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่ยังไม่บรรจุภัณฑ์ -> รายละเอียดวัตถุดิบ
    path: "/stock/warehouse-stock/warehouse-unpackaged-detail/:id",
    name: "warehouse_unpackaged_detail",
    component: WarehouseUnpackagedDetail,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่ยังไม่บรรจุภัณฑ์ -> รายงานการตรวจสอบคุณภาพ
    path: "/stock/warehouse-stock/warehouse-unpackaged-raw-material-detail/:id",
    name: "warehouse_unpackaged_raw_material_detail",
    component: WarehouseUnpackagedRawMaterial,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่ยังไม่บรรจุภัณฑ์ -> รายงานการตรวจสอบคุณภาพการผลิต
    path: "/stock/warehouse-stock/warehouse-unpackaged-raw-material-procress/:id",
    name: "warehouse_unpackaged_raw_material_procress",
    component: WarehouseUnpackagedRawMaterialProcress,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่บรรจุภัณฑ์
    path: "/stock/warehouse-stock/warehouse-packaged",
    name: "warehouse_packaged",
    component: WarehousePackaged,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่บรรจุภัณฑ์ -> รายละเอียด Lot คงเหลือ
    path: "/stock/warehouse-stock/warehouse-packaged-detail/:id",
    name: "warehouse_packaged_detail",
    component: WarehousePackagedDetail,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่บรรจุภัณฑ์ -> รายละเอียด Lot ที่ใช้หมดแล้ว
    path: "/stock/warehouse-stock/warehouse-packaged-detail-lot-used/:id",
    name: "warehouse_packaged_detail_lot_used",
    component: WarehousePackagedDetailLotUsed,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่บรรจุภัณฑ์ -> รายละเอียด Lot -> รายงานการตรวจสอบคุณภาพ
    path: "/stock/warehouse-stock/warehouse-packaged-detail-raw-material/:id",
    name: "warehouse_packaged_detail_raw_material",
    component: WarehousePackagedRawMaterial,
  },
  {
    // คลัง -> คลังสินค้า -> คลังสินค้าที่บรรจุภัณฑ์ -> รายละเอียด Lot -> รายงานการตรวจสอบการผลิต
    path: "/stock/warehouse-stock/warehouse-packaged-detail-raw-material-procress/:id",
    name: "warehouse_packaged_detail_raw_material_procress",
    component: WarehousePackagedRawMaterialProcress,
  },
  {
    // คลัง -> คลังสินค้า -> รายงานการตรวจสอบคุณภาพ
    path: "/stock/warehouse-stock/warehouse-packaged-raw-material-audit-inspection/:id",
    name: "warehouse_packaged_audit_inspection",
    component: WarehousePackagedRawMaterialAuditInspection,
  },
  {
    // คลัง -> คลังสินค้า -> รายงานการตรวจสอบการแปรรูป
    path: "/stock/warehouse-stock/warehouse-packaged-raw-material-audit-procress/:id",
    name: "warehouse_packaged_audit_procress",
    component: WarehousePackagedRawMaterialAuditProcress,
  },
  {
    // คลัง -> คลังสินค้า -> ประวัติสินค้า
    path: "/stock/warehouse-stock/warehouse-packaged-history-raw-material/:id",
    name: "warehouse_packaged_history_raw_material",
    component: WarehousePackagedHistoryRawMaterial,
  },
  
  {
    // คลังสินค้า -> คลัง -> รายละเอียดวัตถุดิบ
    path: "/stock/warehouse-stock/warehousedetail/:id",
    name: "warehousedetail",
    component: WarehouseDetail,
  },
  {
    // คลังสินค้า -> คลัง -> รายการวัตถุดิบ
    path: "/stock/warehouse-stock/WarehouseRawmaterial",
    name: "WarehouseRawmaterial",
    component: WarehouseRawmaterial,
  },
  {
    // คลังสินค้า -> คลัง -> รายการสินค้า
    path: "/stock/warehouse-stock/WarehouseRawmaterialProductOut",
    name: "WarehouseRawmaterialProductOut",
    component: WarehouseRawmaterialProductOut,
  },
  {
    // คลังสินค้า -> คลัง -> รายละเอียดประวัติวัตถุดิบ
    path: "/stock/warehouse-stock/warehousedetail-history-raw-material/:id",
    name: "warehousedetailhistoryrawmaterial",
    component: WarehouseDetailHistoryRawMaterial,
  },
  {
    // คลังสินค้า -> คลัง -> รายละเอียดประวัติวัตถุดิบ
    path: "/stock/warehouse-stock/warehousedetail-history-raw-material-stock-product",
    name: "warehousedetailhistoryrawmaterialstockproduct",
    component: WarehouseDetailHistoryRawMaterialStockProduct,
  },
  {
    // คลังสินค้า -> คลัง -> รายละเอียดประวัติวัตถุดิบ
    path: "/stock/warehouse-stock/warehousedetail-history-raw-material-audit/:id",
    name: "warehousedetailhistoryrawmaterialaudit",
    component: WarehouseDetailHistoryRawMaterialAudit,
  },
  {
    // คลังสินค้า -> คลัง -> รายงานการตรวจสอบคุณภาพวัตถุดิบ -> รายงานการตรวจสอบคุณภาพ
    path: "/stock/warehouse-stock/warehousedetail-history-raw-material-audit-inspection/:id",
    name: "warehousedetailhistoryrawmaterialauditinspection",
    component: WarehouseDetailHistoryRawMaterialAuditInspection,
  },
  {
    // คลังสินค้า -> คลัง -> รายงานการตรวจสอบคุณภาพวัตถุดิบ -> รายงานการตรวจสอบคุณภาพการแปรรูป
    path: "/stock/warehouse-stock/warehousedetail-history-raw-material-audit-process/:id",
    name: "warehousedetailhistoryrawmaterialauditprocess",
    component: WarehouseDetailHistoryRawMaterialAuditProcess,
  },
  {
    // คลังสินค้า -> คลัง -> รายละเอียดวัตถุดิบ Lot ที่ใช้หมดแล้ว
    path: "/stock/warehouse-stock/warehousedetail-log-used/:id",
    name: "warehousedetaillotused",
    component: WarehouseDetailLotUsed,
  },
  {
    // คลังสินค้า -> คลัง -> รายละเอียดวัตถุดิบ Lot ที่ใช้หมดแล้ว
    path: "/stock/warehouse-stock/warehousedetail-unpackaged-log-used/:id",
    name: "WarehouseDetailUnpackagedLotUsed",
    component: WarehouseDetailUnpackagedLotUsed,
  },
  {
    // คลังสินค้า -> คลัง -> LOT คงเหลือ -> รายงานการตรวจสอบคุณภาพวัตถุดิบ
    path: "/stock/warehouse-stock/WarehouseLotInStockRawMaterial/:id",
    name: "WarehouseLotInStockRawMaterial",
    component: WarehouseLotInStockRawMaterial,
  },
  {
    // คลังสินค้า -> คลัง -> LOT ที่ใช้หมดแล้ว -> รายงานการตรวจสอบคุณภาพวัตถุดิบ -> รายงานการตรวจสอบคุณภาพ
    path: "/stock/warehouse-stock/WarehouseLotSlodOutRawMaterial/:id",
    name: "WarehouseLotSlodOutRawMaterial",
    component: WarehouseLotSlodOutRawMaterial,
  },
  {
    // คลังสินค้า -> คลัง -> LOT ที่ใช้หมดแล้ว -> รายงานการตรวจสอบคุณภาพวัตถุดิบ -> รายงานการตรวจสอบคุณภาพการแปรรูป
    path: "/stock/warehouse-stock/WarehouseLotSlodOutRawMaterialProcess/:id",
    name: "WarehouseLotSlodOutRawMaterialProcess",
    component: WarehouseLotSlodOutRawMaterialProcess,
  },
  {
    // คลังสินค้า -> คลัง -> LOT ที่ใช้หมดแล้ว -> รายงานการตรวจสอบคุณภาพวัตถุดิบ -> รายงานการตรวจสอบคุณภาพการผลิต
    path: "/stock/warehouse-stock/WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction",
    name: "WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction",
    component: WarehouseLotSlodOutRawMaterialInspectionCriteriaProduction,
  },
  {
    // ตั้งค่าข้อมูลอื่นๆ -> หน่วยนับ
    path: "/stock/setting-stock/unit",
    name: "unit",
    component: Unit,
  },
  // {
  //   // ตั้งค่าข้อมูลอื่นๆ -> กล่องขนส่ง
  //   path: "/stock/setting-stock/transport-box",
  //   name: "transportbox",
  //   component: TransportBox,
  // },
  // ตั้งค่าข้อมูลสินค้า -> ตั้งค่าประเภทสินค้า
  {
    path: "/stock/setting-stock/product-type",
    name: "producttype",
    component: Producttype,
  },
  {
    path: "/stock/setting-stock/product-size",
    name: "Productsize",
    component: Productsize,
  },
  {
    // เกณฑ์การตรวจสอบ -> เกณฑ์การตรวจสอบคุณภาพ
    path: "/stock/setting-stock/quality-inspection-criteria",
    name: "qualityinspectioncriteria",
    component: Qualityinspectioncriteria,
  },
  {
    // เกณฑ์การตรวจสอบ -> เพิ่มเกณฑ์การตรวจสอบคุณภาพ
    path: "/stock/setting-stock/quality-inspection-criteria-add",
    name: "qualityinspectioncriteriaadd",
    component: Qualityinspectioncriteriaadd,
  },
  {
    // เกณฑ์การตรวจสอบ -> แก้ไขเกณฑ์การตรวจสอบคุณภาพ
    path: "/stock/setting-stock/quality-inspection-criteria-edit/:id",
    name: "qualityinspectioncriteriaedit",
    component: Qualityinspectioncriteriaedit,
  },
  {
    // เกณฑ์การตรวจสอบ -> รายละเอียดเกณฑ์การตรวจสอบคุณภาพ
    path: "/stock/setting-stock/quality-inspection-criteria-detail/:id",
    name: "qualityinspectioncriteriadetail",
    component: Qualityinspectioncriteriadetail,
  },
  {
    // เกณฑ์การตรวจสอบ -> เกณฑ์การตรวจสอบคุณการผลิต
    path: "/stock/setting-stock/inspection-criteria-production",
    name: "inspectioncriteriaproduction",
    component: Inspectioncriteriaproduction,
  },
  {
    // เกณฑ์การตรวจสอบ -> เพิ่มเกณฑ์การตรวจสอบคุณการผลิต
    path: "/stock/setting-stock/inspection-criteria-production-add",
    name: "inspectioncriteriaproductionadd",
    component: Inspectioncriteriaproductionadd,
  },
  {
    // เกณฑ์การตรวจสอบ -> แก้ไขเกณฑ์การตรวจสอบคุณการผลิต
    path: "/stock/setting-stock/inspection-criteria-production-edit/:id",
    name: "inspectioncriteriaproductionedit",
    component: Inspectioncriteriaproductionedit,
  },
  {
    // เกณฑ์การตรวจสอบ -> ดูรายละเอียดเกณฑ์การตรวจสอบคุณการผลิต
    path: "/stock/setting-stock/inspection-criteria-production-detail/:id",
    name: "inspectioncriteriaproductiondetail",
    component: Inspectioncriteriaproductiondetail,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ซัพพลายเออร์
    path: "/stock/setting-stock/supplier",
    name: "supplier",
    component: Supplier,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งค่ากลุ่มวัตถุดิบ
    path: "/stock/setting-stock/raw-material-type",
    name: "rawmaterialtype",
    component: RawMaterialType,
  },

  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งวัตถุดิบ
    path: "/stock/setting-stock/setting-raw-material",
    name: "settingrawmaterial",
    component: SettingRawMaterial,
  },
  {
    // ตั้งวัตถุดิบ -> รายการวัตถุดิบ -> เพิ่มวัตถุดิบ
    path: "/stock/setting-stock/setting-raw-material-add",
    name: "settingrawmaterialadd",
    component: SettingRawMaterialAdd,
  },
  {
    // ตั้งวัตถุดิบ -> รายการวัตถุดิบ -> แก้ไขวัตถุดิบ
    path: "/stock/setting-stock/setting-raw-material-edit/:id",
    name: "settingrawmaterialedit",
    component: SettingRawMaterialEdit,
  },
  {
    // ตั้งวัตถุดิบ -> รายการวัตถุดิบ -> รายละเอียดวัตถุดิบ
    path: "/stock/setting-stock/setting-raw-material-detail/:id",
    name: "settingrawmaterialdetail",
    component: SettingRawMaterialDetail,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งวัตถุดิบ -> เพิ่มวัตถุดิบ
    path: "/stock/setting-stock/set-raw-material-add",
    name: "setrawmaterialadd",
    component: SetRawMaterialAdd,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งวัตถุดิบ -> แก้ไขวัตถุดิบ
    path: "/stock/setting-stock/set-raw-material-edit",
    name: "setrawmaterialedit",
    component: SetRawMaterialEdit,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งวัตถุดิบ -> รายละเอียดวัตถุดิบ
    path: "/stock/setting-stock/set-raw-material-detail",
    name: "setrawmaterialdetail",
    component: SetRawMaterialDetail,
  },

  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งค่าสินค้า
    path: "/stock/setting-stock/set-product-component",
    name: "setproductcomponent",
    component: SetProductComponent,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งค่าสินค้า -> เพิ่มสินค้า
    path: "/stock/setting-stock/set-product-add",
    name: "setproductadd",
    component: SetProductAdd,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งค่าสินค้า -> แก้ไขสินค้า
    path: "/stock/setting-stock/set-product-edit/:id",
    name: "setproductedit",
    component: SetProductEdit,
  },
  {
    // ตั้งค่าข้อมูลวัตถุดิบ -> ตั้งค่าสินค้า -> รายละเอียดสินค้า
    path: "/stock/setting-stock/set-product-detail/:id",
    name: "setproductdetail",
    component: SetProductDetail,
  },
];
