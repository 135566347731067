<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">แก้ไขรายการนำเข้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>

        <v-stepper v-model="stepImport" style="padding:30px;">
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <v-row>
                        <v-col cols="6"><p class="team-text-title-card">แก้ไขรายการนำเข้ารหัส {{header.im_code}}</p></v-col>
                        <v-col cols="6 text-right">ผู้แก้ไขล่าสุด : {{header.name}} วันที่ : {{`${(header.updated_at.split('-')[2]).split(' ')[0]}-${header.updated_at.split('-')[1]}-${header.updated_at.split('-')[0]} ${(header.updated_at.split('-')[2]).split(' ')[1]}`}}</v-col>
                    </v-row>
                    
                    <div class="d-flex justify-space-between">
                        <div class="">
                            <v-btn class="mt-2 ml-0 mx-5 radius-15 text-16" :class="stepImport == 1 ? '' : 'text-grey'" :elevation="stepImport == 1 ? '2' : '0' " @click="stepImport = 1" large   :color="stepImport == 1 ? '#FFBC15' : '#fff' " :outlined="stepImport == 1 ? true : false">
                                ข้อมูลวัตถุดิบนำเข้าคลัง
                            </v-btn>
                            <v-btn class="mt-2 mx-5 radius-15 text-16" :class="stepImport == 2 ? '' : 'text-grey'" :elevation="stepImport == 2 ? '2' : '0' " @click="stepImport = 2" large :color="stepImport == 1 ? '#fff' : '#FFBC15' " :outlined="stepImport == 2 ? true : false">
                                ตรวจสอบคุณภาพวัตถุดิบ
                            </v-btn>
                        </div>
                    </div>
                    
                </v-col>
            </v-row>
            <v-stepper-items >
                <v-row>

                    <v-stepper-content step="1">

                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="px-1">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex justify-space-between align-items-center">
                                    <div>
                                        <v-avatar color="#34230F" size="32">
                                            <v-icon dark>
                                                mdi-package-variant-closed
                                            </v-icon>
                                        </v-avatar>
                                        <span class="ml-3 text-font-title">รายการวัตถุดิบ</span>
                                    </div>
                                    <div>
                                        <h3 style="color:#88B358;">รวมเงินทั้งสิ้น {{formatPrice(total)}}</h3>
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="mt-5">
                                            <v-form-group>
                                                <v-autocomplete color="#686868" :items="select_box" item-text="name"
                                                    item-value="id" v-model="id" @change="addMaterial()" ref="selectMaterial"
                                                    :rules="[v => !!v || 'กรุณาเลือกวัตถุดิบที่ต้องการเพิ่มเข้าคลัง']"
                                                    label="เลือกวัตถุดิบที่ต้องการเพิ่มเข้าคลัง" hide-details="auto" dense
                                                    outlined rounded required></v-autocomplete>
                                            </v-form-group>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                                        <v-card color="basil" flat>
                                            <v-card-text>
                                                <div class="table-header-import-data display-pc mt-0"
                                                    style="color: #b1adad;">
                                                    <div class="table-header-import-data-text text-center">#</div>
                                                    <div class="table-header-import-data-text">
                                                        วัตถุดิบ
                                                    </div>
                                                    <div class="table-header-import-data-text">
                                                        วันที่หมดอายุ
                                                    </div>
                                                    <div class="table-header-import-data-text">
                                                        จำนวน
                                                    </div>
                                                    <div class="table-header-import-data-text">
                                                        ราคาต่อหน่วย
                                                    </div>
                                                    <div class="table-header-import-data-text text-center">
                                                        ราคารวม
                                                    </div>
                                                </div>
                                            </v-card-text>
                                            <div v-for="(header, index) in data" :key="header">
                                            <v-card-title class="padding-text-title align-items-center"
                                                style="background: rgba(255, 188, 21, 0.1);border: 1px solid #FFBC15;border-radius: 20px;margin: 15px;">
                                                <span class="ml-3 text-font-title" style="font-size: 14px;">{{header.mat_type_name}}</span>
                                            </v-card-title>
                                            <v-card-text v-for="(detail, i) in header.List" :key="detail">
                                                <v-card elevation="0" class="card-table-radius">
                                                    <div class="grid-import-items">
                                                        <div v-if="detail.new == 0" class="text-center">{{i+1}}</div>
                                                        <v-icon v-else dark color="error" size="32" @click='remove(index, i)'>
                                                    mdi-delete-forever
                                                </v-icon>
                                                        <div>{{detail.mat_name}}</div>
                                                        <div style="padding: 10px;">
                                                            <v-menu v-model="detail.menu" :close-on-content-click="false"
                                                                :nudge-right="40" transition="scale-transition" offset-y
                                                                min-width="auto">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field v-model="detail.lot_exp" label="วันที่หมดอายุ"
                                                                        prepend-inner-icon="mdi-calendar"
                                                                        hide-details="auto"
                                                                        :rules="[v => !!v || 'กรุณาเลือกวันที่หมดอายุ']"
                                                                        v-bind="attrs" dense outlined rounded required readonly
                                                                        v-on="on"></v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="detail.lot_exp" @input="detail.menu = false"
                                                                    no-title scrollable></v-date-picker>
                                                            </v-menu>
                                                        </div>
                                                        <div style="padding: 10px;">
                                                            <div class="d-flex">
                                                                <v-autocomplete v-if="detail.id != null" style="border-radius:20px 0 0 20px;"
                                                                    :items="detail.unit_list" color="#FFBC15" label="จำนวน"
                                                                    :rules="[v => !!v || 'กรุณาเลือกจำนวน']" @change="calculate(detail.qty, i ,index)"
                                                                    hide-details="auto" item-text="name" item-value="id" disabled
                                                                    v-model="detail.unit_defualt_id" dense outlined required>
                                                                </v-autocomplete>

                                                                <v-text-field v-if="detail.id != null" class="input-radius" color="#FFBC15"
                                                                    style="border-radius:0 20px 20px 0;" @change="calculate_input(i, index)"
                                                                    :rules="[v => !!v || 'กรุณากรอกจำนวน']" v-model="detail.qty"
                                                                    @keypress="isOnlyNumberStock($event, detail.qty)" disabled
                                                                    hide-details="auto" dense outlined required>
                                                                </v-text-field>
                                                                <v-autocomplete v-if="detail.id == null" style="border-radius:20px 0 0 20px;"
                                                                    :items="detail.unit_list" color="#FFBC15" label="จำนวน"
                                                                    :rules="[v => !!v || 'กรุณาเลือกจำนวน']" @change="calculate(detail.qty, i ,index)"
                                                                    hide-details="auto" item-text="name" item-value="id" 
                                                                    v-model="detail.unit_defualt_id" dense outlined required>
                                                                </v-autocomplete>

                                                                <v-text-field v-if="detail.id == null" class="input-radius" color="#FFBC15"
                                                                    style="border-radius:0 20px 20px 0;" @change="calculate_input(i, index)"
                                                                    :rules="[v => !!v || 'กรุณากรอกจำนวน']" v-model="detail.qty"
                                                                    @keypress="isOnlyNumberStock($event, detail.qty)" 
                                                                    hide-details="auto" dense outlined required>
                                                                </v-text-field>
                                                            </div>
                                                        </div>
                                                        <div style="padding: 10px;">
                                                            <v-form-group>
                                                                <v-text-field class="input-radius" color="#FFBC15"
                                                                    label="ราคาต่อหน่วย" placeholder="ราคาต่อหน่วย"
                                                                    v-model="detail.price" minlength="1"
                                                                    :rules="[v => !!v || 'กรุณากรอกราคาต่อหน่วย']" @change="calculate_input(i, index)"
                                                                    @keypress="isOnlyNumber($event, detail.price)"
                                                                    hide-details="auto" suffix="บาท" dense outlined rounded
                                                                    required></v-text-field>
                                                            </v-form-group>
                                                        </div>
                                                        <div>{{formatPrice(detail.total_price)}}</div>
                                                    </div>
                                                </v-card>
                                            </v-card-text>
                                             </div>
                                             <div class="d-flex justify-end ">
                                                    <div class="d-flex justify-space-between me-2 mb-4"
                                                        style="padding:20px;background: rgba(136, 179, 88, 0.15);border: 1px solid #88B358;border-radius: 15px;">
                                                        <h2 style="color:#000;margin-right:10px;">รวมเงิน</h2>
                                                        <h2 style="color:#88B358;">{{formatPrice(total)}}</h2>
                                                    </div>
                                                </div>
                                        </v-card>

                            </v-card>
                            <div class="d-flex justify-center">
                                
                                <div class="mt-3 div-submit-data">
                                    <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large @click="saveData(1)">
                                        <v-icon left>mdi-content-save</v-icon> นำเข้าคลัง
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                           <v-form ref="input" @submit.prevent="saveData(2)">
                             <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-shield-check
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <div class="table-header-import-step3-data display-pc mt-3"
                                                style="color: #b1adad;">
                                                <div class="table-header-import-step3-data-text">
                                                    ชื่อวัตถุดิบ
                                                </div>
                                                <div class="table-header-import-step3-data-text">
                                                    รายละเอียดการตรวจสอบ
                                                </div>
                                                <div class="table-header-import-step3-data-text">
                                                    Value
                                                </div>
                                                <div class="table-header-import-step3-data-text">
                                                    หมายเหตุ/รายละเอียดการแก้ไข
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text>
                                    <v-card elevation="0" class="card-table-radius" style="border: 1px solid #D9D9D9;" v-for="item in product_query" :key="item">
                                        <div class="grid-import-step3-items py-5">
                                            <div style="padding: 10px;">
                                                <p style="color:#000;font-weight:600;">{{item.mat_name}}</p>
                                                <p style="color:#000;font-weight:600;">Lot : {{item.material_lot}}</p>
                                                <p style="color:#000;font-weight:600;">{{item.sup_name}}</p>
                                            </div>

                                            <v-row class="">
                                                <v-col cols="12">

                                                    <v-row v-for="detail in item.list" :key="detail">
                                                        <v-col cols="6 py-0">
                                                        <div style="padding: 10px">
                                                            <p class="mb-0" style="color: #000; font-weight: 600">{{detail.pq_list_thai}}</p>
                                                        </div>
                                                        </v-col>
                                                        <v-col cols="6 py-0">
                                                        <div style="padding: 10px">
                                                            <p class="mb-0"><v-icon @click="detail.value = '0'" color="#88B358" v-if="detail.pq_list_type == 0 && detail.value == '1'">mdi-check-circle</v-icon></p>
                                                                <p class="mb-0"><v-icon @click="detail.value = '1'" color="#F28080" v-if="detail.pq_list_type == 0 && detail.value == '0'">mdi-close-circle</v-icon></p>
                                                                <v-text-field
                                                                    class="input-radius"
                                                                    color="#FFBC15"
                                                                    label=" Value"
                                                                    placeholder="Value"
                                                                    v-if="detail.pq_list_type == 1"
                                                                    v-model="detail.value"
                                                                    hide-details="auto"
                                                                    :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                                                                    dense
                                                                    outlined
                                                                    rounded
                                                                ></v-text-field>
                                                        </div>
                                                        </v-col>
                                                    </v-row>
                                                
                                                </v-col>
                                            </v-row>

                                            <div style="padding: 10px;">
                                                <v-textarea class="input-radius" label="หมายเหตุ/รายละเอียดการแก้ไข"
                                                    color="#FFBC15" outlined rounded rows="3" row-height="25" v-model="item.note"></v-textarea>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                            <div class="mt-3 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> นำเข้าคลัง
                                </v-btn>
                            </div>
                           </v-form>
                        </v-col>
                    </v-stepper-content>
                </v-row>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>
<script>
import { isTelephone, formatPrice , onlyForCurrency , onlyForCurrencyStock } from "@/helper/handler";
import importorder from "@/api/importrawmaterials/importorder.js";
import meterial from "@/api/warehouse/materials.js";
import importPO from "@/api/import_po/importpo.js";
import Swal from "sweetalert2";
export default {
    data() {
        return {
            loading_page: false,
            stepImport: 1,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'นำเข้าวัตถุดิบ',
                    disabled: false,
                    href: '/stock/material-import',
                },
                {
                    text: 'แก้ไขรายการนำเข้า',
                    disabled: false,
                    href: '#',
                }
            ],
            unit: [
                { id: 1, name: 'แพ็ค 5' },
                { id: 2, name: 'แพ็ค 10' },
                { id: 3, name: 'แพ็ค 15' },
            ],
            tab: null,
            items: [
                'สยามแม็คโคร', 'นิทานการข้าว'
            ],
            menu: false,
            modal: false,
            menu2: false,
            toggle_exclusive: 2,
            page: 1,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,

            select_box: [],
            data_list: [],
            data: [],
            header: {},
            id: null,
            total: 0,
            product_query: [],
            test: {},

        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
        formatPrice(number) {
            return formatPrice(number)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        isOnlyNumberStock: function (evt, number) {
            return onlyForCurrencyStock(evt, number);
        },
        remove(index ,i){
            if( this.data[index].List.length ==1){
                 this.data.splice(index, 1);
            }else{
                this.data[index].List.splice(i, 1);
            }
           
        },
        calculate(qty=null, i=null, index=null){
      let data = this.data[index].List[i].unit_list.find(o => o.id === this.data[index].List[i].unit_defualt_id);
      if(data != null){
        switch(data.id){
          case 1 : 
                  this.data[index].List[i].qty = 0;
                  this.data[index].List[i].qty = this.data[index].List[i].unit_other_qty * qty;
                   this.data[index].List[i].total_price = this.data[index].List[i].qty * this.data[index].List[i].price;
          break;
          case 2 : 
                  this.data[index].List[i].qty = 0;
                  this.data[index].List[i].qty =qty / this.data[index].List[i].unit_other_qty;
                   this.data[index].List[i].total_price = this.data[index].List[i].qty * this.data[index].List[i].price;
          break;
        }
        this.total = 0;
        for(let x =0; x < this.data.length; x++){
            for(let j =0; j < this.data[x].List.length; j++){
          this.total += parseFloat(this.data[x].List[j].total_price);
        }
        
        }
      }
    },
    calculate_input(i=null, index=null){
        this.data[index].List[i].total_price = this.data[index].List[i].qty * this.data[index].List[i].price;
        this.total = 0;
        for(let x =0; x < this.data.length; x++){
            for(let j =0; j < this.data[x].List.length; j++){
          this.total += parseFloat(this.data[x].List[j].total_price);
        }
        }
    },
        getImportDetail(){
            let id  = this.$route.params.id;
            importorder.getImportDetail(id).then((response) => {
                if(response.data.successful === true){
                    let list = response.data.importDetails.list;
                    this.data_list = response.data.importDetails.list;
                    this.header = response.data.importDetails;
                    this.total = this.header.total_price;
                    for(let i = 0; i < list.length; i++){
                         let type = list[i]
                        for(let j = 0; j < type.material_list.length; j++){
                            let material = type.material_list[j]
                          let check = this.data.find(o => o.materials_type_id === material.materials_type_id)
                        if(check == null){
                             this.data.push({
                       mat_type_name: type.mat_type_name,
                        materials_type_id: type.materials_type_id,
                         index_group: this.data.length,
                        List:[{
                             id: material.id,
                            im_id: material.im_id,
                            material_lot: material.material_lot,
                            material_id: material.material_id,
                            mat_name: material.mat_name,
                            materials_type_id: material.materials_type_id,
                            mat_type_name: material.mat_type_name,
                            sup_id: material.sup_id,
                            sup_name: material.sup_name,
                            qty:  material.unit_id == material.unit_main_id ? material.qty : (parseFloat(material.qty) / parseFloat(material.unit_other_qty)).toFixed(2)+'',
                            unit_id: material.unit_id,
                            unit_defualt_id: material.unit_id == material.unit_main_id ? 1 : 2,
                            unit_peritem: material.unit_peritem,
                            unit_gram: material.unit_gram,
                            unit_main_gram: material.unit_main_gram,
                            price: material.price,
                            material_amount: material.material_amount,
                            cost_pergram: material.cost_pergram,
                            total_price: material.total_price,
                            lot_exp: material.lot_exp,
                            note: material.note,
                            unit_main_id: material.unit_main_id,
                            unit_main_name: material.unit_main_name,
                            unit_other_id: material.unit_other_id,
                            unit_other_name: material.unit_other_name,
                            unit_list: material.unit_other_name == null ? [{id: 1, name: material.unit_main_name}]  : [{id: 1, name: material.unit_main_name},{id: 2, name: material.unit_other_name}],
                            unit_other_qty: material.unit_other_qty,
                            new: 0,
                            pq_list: material.pq_list
                        }]
                    });
                        }else{
                             let select =  this.data.find(o => o.mat_type_name === type.mat_type_name)
                            this.data[select.index_group].List.push({
                            id: material.id,
                            im_id: material.im_id,
                            material_lot: material.material_lot,
                            material_id: material.material_id,
                            mat_name: material.mat_name,
                            materials_type_id: material.materials_type_id,
                            mat_type_name: material.mat_type_name,
                            sup_id: material.sup_id,
                            sup_name: material.sup_name,
                            qty:  material.unit_id == material.unit_main_id ? material.qty : (parseFloat(material.qty) / parseFloat(material.unit_other_qty)).toFixed(2)+'',
                            unit_id: material.unit_id,
                            unit_defualt_id: material.unit_id == material.unit_main_id ? 1 : 2,
                            unit_peritem: material.unit_peritem,
                            unit_gram: material.unit_gram,
                            unit_main_gram: material.unit_main_gram,
                            price: material.price,
                            material_amount: material.material_amount,
                            cost_pergram: material.cost_pergram,
                            total_price: material.total_price,
                            lot_exp: material.lot_exp,
                            note: material.note,
                            unit_main_id: material.unit_main_id,
                            unit_main_name: material.unit_main_name,
                            unit_other_id: material.unit_other_id,
                            unit_other_name: material.unit_other_name,
                            unit_list: material.unit_other_name == null ? [{id: 1, name: material.unit_main_name}]  : [{id: 1, name: material.unit_main_name},{id: 2, name: material.unit_other_name}],
                            unit_other_qty: material.unit_other_qty,
                            new: 0,
                            pq_list: material.pq_list
                    });   
                        }
                        }
                }
                this.getProductQuery()
                }
            })
        },
         addMaterial(){
             let select = this.select_box.find(o => o.id === this.id)
            const type = this.data_list[select.index_group];
            const material = type.List[select.count_select];
                        let check = this.data.find(o => o.materials_type_id === material.materials_type_id)
                        const dt = new Date();
                            const hours = dt.getHours().toString().padStart(2, '0');
                            const minutes = dt.getMinutes().toString().padStart(2, '0');
                            let material_lot = `${dt.getFullYear()}${String(dt.getMonth()+1).padStart(2, '0')}${String(dt.getDate()).padStart(2,'0')}${hours}${minutes}`;
                        if(check == null){
                             this.data.push({
                        mat_type_name: type.typeName,
                        materials_type_id: material.materials_type_id,
                         index_group: this.data.length,
                        List:[{
                            id: null,
                            material_id: material.id,
                            mat_name: material.mat_name,
                            sup_id: material.supplier_id,
                            sup_name: material.sup_name,
                            qty: 0,
                            lot_exp: new Date().toISOString().substr(0, 10),
                            materials_type_id: material.materials_type_id,
                            material_lot: material.id+'-'+material_lot,
                            mat_instock: material.mat_instock,
                            unit_defualt_id:1,
                            unit_main_id: material.unit_main_id,
                            unit_main: material.unit_main,
                            unit_main_gram: material.unit_main_gram,
                            price: material.retail_price,
                            total_price:0,
                            unit_other_id: material.unit_other_id,
                            unit_other: material.unit_other,
                            unit_list: [{id:1, name:material.unit_main},{id:2, name:material.unit_other}],
                            unit_other_qty: material.unit_other_qty,
                            other_price: material.other_price,
                            note: null,
                            unit_id: 1,
                            new: null,
                            menu:false,
                            pq_list:material.pq_list
                        }]
                    });
                        }else{
                             let select =  this.data.find(o => o.mat_type_name === type.typeName)
                             let list = select.List.find(o => o.material_id === material.id+'')
                             if(list == null){
                            this.data[select.index_group].List.push({
                            id: null,
                            material_id: material.id,
                            mat_name: material.mat_name,
                            sup_id: material.supplier_id,
                            qty: 0,
                            lot_exp: new Date().toISOString().substr(0, 10),
                            materials_type_id: material.materials_type_id,
                            material_lot: material.materials_type_id+'-'+material_lot,
                            mat_instock: material.mat_instock,
                            unit_main_id: material.unit_main_id,
                            unit_main: material.unit_main,
                            unit_main_gram: material.unit_main_gram,
                            price: material.retail_price,
                            total_price:0,
                            unit_defualt_id:1,
                            unit_other_id: material.unit_other_id,
                            unit_other: material.unit_other,
                            unit_list: [{id:1, name:material.unit_main},{id:2, name:material.unit_other}],
                            unit_other_qty: material.unit_other_qty,
                            other_price: material.other_price,
                            note: null,
                            unit_id: 1,
                            menu:false,
                            pq_list:material.pq_list
                    });
                             }
                        }
                 this.$refs.selectMaterial.reset();
        },
        getMeterialSelectbox(){
            this.loading_page = true;
             meterial.getMeterialSelectbox().then((response) => {
                if(response.data.successful === true){
                    let select_box = response.data.list;
                    this.data_list = response.data.list;
                    for(let i = 0; i < select_box.length; i++){
                        for(let x = 0; x < select_box[i].List.length; x++){
                        this.select_box.push({
                            id: select_box[i].List[x].id,
                            name: select_box[i].List[x].mat_name,
                            materials_type_id: select_box[i].List[x].materials_type_id ,
                            count_select: x,
                            index_group: i
                        })
                    }
                    }
                    this.loading_page = false;
                }
            })
        },
         saveData(i){
          if(i == 1){
              let list_array = []
            let pq_list = []
            for(let i =0; i < this.data.length; i++){
                for(let x =0; x < this.data[i].List.length; x++){
                    pq_list = []
                    for(let j =0; j < this.data[i].List[x].pq_list.length; j++){

                        let id = null
                        let value = null
                        let pq_list_id = null
                        if( this.data[i].List[x].id == null){
                            id = null;
                            value = this.data[i].List[x].pq_list[j].pq_value == '' ? '1' : this.data[i].List[x].pq_list[j].pq_value
                            pq_list_id = this.data[i].List[x].pq_list[j].id
                        }else{
                            id = this.data[i].List[x].pq_list[j].id
                            value = this.data[i].List[x].pq_list[j].value == '' ? '1' : this.data[i].List[x].pq_list[j].value
                            pq_list_id = this.data[i].List[x].pq_list[j].pq_list_id
                    }

                pq_list.push({
                    id: id,
                    pq_list_id: pq_list_id,
                    pq_list_type: this.data[i].List[x].pq_list[j].pq_list_type,
                    value: value
                })
             }
             if(this.data[i].List[x].id == null){
                list_array.push({
            im_id: this.$route.params.id,
            material_id: this.data[i].List[x].material_id,
            material_lot: this.data[i].List[x].material_lot,
            sup_id: this.data[i].List[x].sup_id,
           qty: this.data[i].List[x].unit_defualt_id == 2 ? (parseFloat((this.data[i].List[x].qty+'').replace(/,/g, '')) *  parseFloat((this.data[i].List[x].unit_other_qty+'').replace(/,/g, '')))+'' : (this.data[i].List[x].qty+'').replace(/,/g, ''),
            unit_id: this.data[i].List[x].unit_defualt_id == 1 ? this.data[i].List[x].unit_main_id : this.data[i].List[x].unit_other_id,
            unit_peritem: this.data[i].List[x].unit_id == 1 ? 1 :this.data[i].List[x].unit_other_qty,
            unit_gram: this.data[i].List[x].unit_main_gram,
            price: this.data[i].List[x].price,
            material_amount: parseFloat(this.data[i].List[x].unit_main_gram) == 0 || parseFloat(this.data[i].List[x].unit_main_gram) == null ? this.data[i].List[x].qty : parseFloat(this.data[i].List[x].qty) * parseFloat(this.data[i].List[x].unit_defualt_id == 1 ? 1 :this.data[i].List[x].unit_other_qty) * parseFloat(this.data[i].List[x].unit_main_gram),
            cost_pergram: parseFloat(this.data[i].List[x].unit_main_gram) == 0 || parseFloat(this.data[i].List[x].unit_main_gram) == null ? this.data[i].List[x].price : parseFloat(this.data[i].List[x].price) / parseFloat(this.data[i].List[x].unit_defualt_id == 1 ? 1 :this.data[i].List[x].unit_other_qty) / parseFloat(this.data[i].List[x].unit_main_gram),
             total_price: this.data[i].List[x].total_price,
             lot_exp: this.data[i].List[x].lot_exp,
             note: this.data[i].List[x].note,
             list: pq_list,
        })
             }else{
                list_array.push({
            id: this.data[i].List[x].id,
            material_id: this.data[i].List[x].material_id,
            material_lot: this.data[i].List[x].material_lot,
            sup_id: this.data[i].List[x].sup_id,
            qty: this.data[i].List[x].qty,
            unit_id: this.data[i].List[x].unit_main_id,
            unit_peritem: this.data[i].List[x].unit_id == 1 ? 1 :this.data[i].List[x].unit_other_qty,
            unit_gram: this.data[i].List[x].unit_main_gram,
            price: this.data[i].List[x].price,
            material_amount: parseFloat(this.data[i].List[x].unit_main_gram) == 0 || parseFloat(this.data[i].List[x].unit_main_gram) == null ? this.data[i].List[x].qty : parseFloat(this.data[i].List[x].qty) * parseFloat(this.data[i].List[x].unit_defualt_id == 1 ? 1 :this.data[i].List[x].unit_other_qty) * parseFloat(this.data[i].List[x].unit_main_gram),
            cost_pergram: parseFloat(this.data[i].List[x].unit_main_gram) == 0 || parseFloat(this.data[i].List[x].unit_main_gram) == null ? this.data[i].List[x].price : parseFloat(this.data[i].List[x].price) / parseFloat(this.data[i].List[x].unit_defualt_id == 1 ? 1 :this.data[i].List[x].unit_other_qty) / parseFloat(this.data[i].List[x].unit_main_gram),
             total_price: this.data[i].List[x].total_price,
             lot_exp: this.data[i].List[x].lot_exp,
             note: this.data[i].List[x].note,
             list: pq_list,
        })
             }
             }
            }
            let data = {
                total_price: this.total,
                list:list_array
            }
            this.test = data;
            let id = this.$route.params.id
             importPO.edit(id, data).then((response) => {
                if(response.data.successful === true){
                   let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/stock/material-import");
            }
          });
                }
            })
          }else{
            if(this.$refs.input.validate()){
                         let list_array = []
            let pq_list = []
            for(let i =0; i < this.product_query.length; i++){
                pq_list = []
            for(let j =0; j < this.product_query[i].list.length; j++){
                pq_list.push({
            id: this.product_query[i].list[j].id,
            value: this.product_query[i].list[j].value == '' ? '1' : this.product_query[i].list[j].value,
            pq_list_id: this.product_query[i].list[j].pq_list_id,
            pq_list_type: this.product_query[i].list[j].pq_list_type,
          })
             }
                list_array.push({
            id: this.product_query[i].id,
            material_id: this.product_query[i].material_id,
            material_lot: this.product_query[i].material_lot,
            sup_id: this.product_query[i].sup_id,
            qty: this.product_query[i].qty,
            unit_id: this.product_query[i].unit_main_id,
            unit_peritem: this.product_query[i].unit_id == 1 ? 1 :this.product_query[i].unit_other_qty,
            unit_gram: this.product_query[i].unit_main_gram,
            price: this.product_query[i].price, 
            material_amount: parseFloat(this.product_query[i].unit_main_gram) == 0 || parseFloat(this.product_query[i].unit_main_gram) == null ? this.product_query[i].qty : parseFloat(this.product_query[i].qty) * parseFloat(this.product_query[i].unit_defualt_id == 1 ? 1 :this.product_query[i].unit_other_qty) * parseFloat(this.product_query[i].unit_main_gram),
            cost_pergram: parseFloat(this.product_query[i].price) / parseFloat(this.product_query[i].unit_id == 1 ? 1 :this.product_query[i].unit_other_qty) / parseFloat(this.product_query[i].unit_main_gram),
             total_price: this.product_query[i].total_price,
             lot_exp: this.product_query[i].lot_exp,
             note: this.product_query[i].note,
             list: pq_list,
        })
            }
            let data = {
                total_price: this.total,
                list:list_array
            }
            this.test = data;
            let id = this.$route.params.id
             importPO.edit(id, data).then((response) => {
                if(response.data.successful === true){
                   let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/stock/material-import");
            }
          });
                }
            })
            }
          }
        },
 getProductQuery(){
            for(let i =0; i < this.data.length; i++){
            for(let x =0; x < this.data[i].List.length; x++){
                this.product_query.push({
                            id: this.data[i].List[x].id,
                            material_id: this.data[i].List[x].material_id,
                            mat_name: this.data[i].List[x].mat_name,
                            sup_id: this.data[i].List[x].sup_id,
                            qty: this.data[i].List[x].qty,
                            lot_exp: this.data[i].List[x].lot_exp,
                            materials_type_id: this.data[i].List[x].materials_type_id,
                            material_lot: this.data[i].List[x].material_lot,
                            mat_instock: this.data[i].List[x].mat_instock,
                            unit_main_id: this.data[i].List[x].unit_main_id,
                            unit_main: this.data[i].List[x].unit_main,
                            unit_main_gram: this.data[i].List[x].unit_main_gram,
                            price: this.data[i].List[x].price,
                            total_price:this.data[i].List[x].total_price,
                            unit_defualt_id:this.data[i].List[x].unit_defualt_id,
                            unit_other_id: this.data[i].List[x].unit_other_id,
                            unit_other: this.data[i].List[x].unit_other,
                            unit_list: [{id:1, name:this.data[i].List[x].unit_main},{id:2, name:this.data[i].List[x].unit_other}],
                            unit_other_qty: this.data[i].List[x].unit_other_qty,
                            other_price: this.data[i].List[x].other_price,
                            note: this.data[i].List[x].note,
                            unit_id: this.data[i].List[x].unit_id,
                            list:this.data[i].List[x].pq_list
        })
             }
            }
        }
    },
    mounted() {
        this.getImportDetail();
        this.getMeterialSelectbox();
    }
}
</script>
<style scoped>
.input-group {
    display: flex;
    gap: 10px;
}

.form-select {
    width: 150px;
}

.form-control {
    flex: 1;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.padding-text-title {
    padding: 8px !important;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 20px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-import-data {
    display: grid;
    grid-template-columns: 2% 20% 20% 20% 20% 18%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-items {
    display: grid;
    grid-template-columns: 5% 20% 19% 20% 26% 10%;
    align-items: center;
}

.table-header-import-step3-data {
    display: grid;
    grid-template-columns: 21% 25% 24% 30%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}
.table-header-import-step3-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-step3-items {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  align-items: center;
}

.grid-import-step3-items-sub {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
</style>