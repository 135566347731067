const state = {
  access_token: null,
  userProfile: null,
  stockOrderList: {
    po_list: [],
    total_price: 0
  },
  profileDetail: {}, // Initialize an empty object
};
export default state;
