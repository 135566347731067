import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    addSupplier: (data) => {
        return HTTP.post('/supplier/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    dataList: (page, size, search) => {
        return HTTP.get(`/supplier/list?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    detail: (id) => {
        return HTTP.get('/supplier/detail/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    edit: (id, data) => {
        return HTTP.post('/supplier/edit/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    delete: (id) => {
        return HTTP.post('/supplier/delete/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    list: () => {
        return HTTP.get('/supplier/list/all',{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
}