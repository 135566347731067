<template>
    <div>
      <ProcessingPackagingDetail/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingDetail from "@/components/production_process/procressing_packaging/ProcressingPackagingDetail";
  export default {
    name: "Processing_packaging_detail",
    components: {
      ProcessingPackagingDetail
    }
  }
  </script>
  
  <style scoped>
  
  </style>