<template>
    <div>
      <ProcessingPackagingStepProduction/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepProduction from "@/components/production_process/procressing_packaging/ProcressingPackagingStepProduction";
  export default {
    name: "Processing_packaging_step_create",
    components: {
      ProcessingPackagingStepProduction
    }
  }
  </script>
  
  <style scoped>
  
  </style>