<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OF-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <div class="d-flex justify-space-between ma-5">
          <h3>
            <b>ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ</b>
          </h3>
          <div class="d-flex">
            <div class="d-flex mr-3">
              <div class="mr-2" style="color: #686868">ผู้แก้ไขล่าสุด :</div>
              <div class="black--text">ชลิตวรรณ นาคทอง</div>
            </div>
            <div class="d-flex">
              <div class="mr-2" style="color: #686868">วันที่ :</div>
              <div class="black--text">02/01/2023 09:40</div>
            </div>
          </div>
        </div>
        <v-stepper class="ml-10 mr-10" v-model="e1" alt-labels color="#ffbc15" elevation="0">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1" color="#ffbc15">
              <span style="font-size: 14px">ข้อมูลความพร้อมการแปรรูป</span>
            </v-stepper-step>
            <v-stepper-step :complete="e1 > 2" step="2" color="#ffbc15">
              <span style="font-size: 14px">กำหนดรอบการแปรรูป</span>
            </v-stepper-step>
            <v-stepper-step :complete="e1 > 3" step="3" color="#ffbc15">
              <span style="font-size: 14px">ยืนยันการแปรรูป</span>
            </v-stepper-step>
          </v-stepper-header>
          <v-progress-linear color="#ffbc15" background-color="#d9d9d9" v-model="progress_value" height="10" rounded />
          <v-stepper-items class="ml-n5 mr-n5">
            <v-stepper-content step="1">
              <v-card-text>
                <v-card elevation="4" class="py-2" style="border-radius:20px">
                  <v-card-title>
                    <v-avatar color="#34230F" size="36">
                      <v-icon dark>
                        mdi-note-text-outline
                      </v-icon>
                    </v-avatar>
                    <span class="ml-3 title-card-text">ข้อมูลความพร้อมการเตรียมวัตถุดิบ</span>
                  </v-card-title>
                  <v-card-text>
                    <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                    <div class="table-header-processing ml-15" style="color: #b1adad;">
                      <div class="table-sub-header-processing">
                        วัตถุดิบ
                      </div>
                      <div class="table-sub-header-processing">
                        จำนวนวัตถุดิบที่ต้องใช้ (กรัม (g))
                      </div>
                      <div class="table-sub-header-processing">
                        เปรียบเทียบกับวัตถุดิบในคลัง
                      </div>
                      <div class="table-sub-header-processing text-center ml-n5">
                        ผลลัพธ์
                      </div>
                    </div>
                    <v-divider class="ml-15 my-3" dark color="#a3a3a3"></v-divider>
                    <v-card elevation="0" class="grid-processing-items ma-4 ml-15 black--text" v-for="(detail, index) in dataList" :key="index">
                      <div class="ml-3">{{ detail.product }}</div>
                      <div class="ml-5">{{ detail.totalInUse }}</div>
                      <div v-if="detail.statusReady === false" class="ml-5" style="color: #f28080">{{ detail.stockPrepare }}</div>
                      <div v-else class="ml-5">{{ detail.stockPrepare }}</div>
                      <div v-if="detail.statusReady === false" style="color: #f28080;margin-left: 157px">ไม่พอ</div>
                      <div v-else style="margin-left: 159px">พอ</div>
                    </v-card>
                    <v-divider class="ml-15 my-1" dark color="#e9e7e7"></v-divider>
                    <div class="grid-processing-items ma-4 ml-15 black--text">
                      <div class="ml-3">รวม (FG)</div>
                      <div class="ml-5">71,984.64</div>
                      <div></div>
                      <div class="text-right" style="font-size: 24px"><b style="color: #f28080">ไม่พร้อมแปรรูป</b></div>
                    </div>
                  </v-card-text>
                  <div class="text-right ma-2 mt-n3">
                    <v-btn class="mt-2" large rounded outlined color="#88B358" @click="add_dialog = true">
                      <v-icon left>mdi-text-box-check-outline</v-icon>สั่งซื้อวัตถุดิบ
                    </v-btn>
                  </div>
                </v-card>
              </v-card-text>
              <div class="my-3 text-center">
                <v-btn color="#88B358" elevation="4" rounded large outlined  @click="progress(50, 2)">
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <section>
                <v-card-text class="mb-5">
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <v-icon dark>
                          mdi-cog
                        </v-icon>
                      </v-avatar>
                      <span class="ml-3 title-card-text">การกำหนดรอบและการคำนวนวัตถุดิบ</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                      <div class="ma-4 ml-8 mb-n7">
                        <b class="ml-3 black--text">จำนวน Batch ที่ต้องการแปรรูป</b>
                      </div>
                      <v-row class="ma-4 ml-8">
                        <v-col xl="3" lg="3">
                          <v-select item-text="value" item-value="id" class="input-radius-search mt-3" label="จำนวน Batch ที่ต้องการแปรรูป" hide-details="auto" outlined dense></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-text>
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <img class="icon-image-logo" src="@/assets/value-chain.png">
                      </v-avatar>
                      <span class="ml-3 title-card-text">การกำหนดรอบและคำนวนวัตถุดิบ</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                      <div class="ml-15 mt-4">
                        <div class="tag-title-step">
                          <b class="black--text">ส่วนที่ 1</b>
                        </div>
                        <div class="divider-table my-2"></div>
                        <div class="scroll-menu mt-4">
                          <section>
                            <div class="w-border-header-first mb-n3"/>
                            <div class="card-main-table">
                              <div class="py-3 d-flex justify-space-between">
                                <div class="black--text font-weight-bold">วัตถุดิบ</div>
                                <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                              </div>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12" class="inline-box-specific">
                                  <div class="d-flex">
                                    <div class="mt-n6 mr-15">
                                      <v-checkbox hide-details="auto" color="#FFBC15" />
                                    </div>
                                    <div class="black--text font-weight-bold">การ weight % จากสููตร</div>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row class="pt-2">
                                <v-col xl="6" lg="6" class="mt-n5">
                                  <v-checkbox label="ข้าวสารขาว" hide-details="auto" color="#FFBC15" v-model="checked" />
                                </v-col>
                                <v-col xl="6" lg="6" style="margin-top: 2px">
                                  <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                                </v-col>
                              </v-row>
                              <v-row class="pt-2">
                                <v-col xl="6" lg="6" class="mt-n5">
                                  <v-checkbox label="น้ำกรอง" hide-details="auto" color="#FFBC15" />
                                </v-col>
                                <v-col xl="6" lg="6" style="margin-top: 2px">
                                  <div class="font-weight-bold">5,000.00 กรัม</div>
                                </v-col>
                              </v-row>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">รวม (FG)</div>
                                </v-col>
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">8,736.00 กรัม</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section>
                          <section v-for="(detail, index) in batchList" :key="index">
                            <div class="w-border-header-sub mb-n3"/>
                            <div class="card-sub-table">
                              <div class="py-3">
                                <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                              </div>
                              <div class="black--text font-weight-bold text-center">{{ detail.percent }}</div>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <v-text-field class="input-radius" color="#686868" label="จำนวนกรัม" placeholder="จำนวนกรัม" hide-details="auto" v-model="detail.gams" outlined dense rounded required></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center">{{ detail.gamsFilter }} หน่วย</div>
                                </v-col>
                              </v-row>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center">{{ detail.totalGams }} หน่วย</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section>
                        </div>
                        <div class="summarize my-5 pa-5">
                          <h1 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป 71,9847.64 กรัม หรือ 71.98 กิโลกรัม</h1>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </section>
              <div class="my-3 text-center">
                <v-btn color="#88B358" elevation="4" rounded large outlined  @click="progress(100, 3)">
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <section>
                <v-card-text class="mb-5">
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <img class="icon-image-logo" src="@/assets/standard.png">
                      </v-avatar>
                      <span class="ml-3 title-card-text">ยืนยันการใช้แผนแปรรูป</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                      <div class="ma-4 mb-n7">
                        <b class="ml-3 black--text">ข้อมูลการแปรรูป</b>
                      </div>
                      <v-row class="ma-4 mt-6 mb-0">
                        <v-col xl="4" lg="4">
                          <div class="d-flex">
                            <div class="mr-2">รายการแปรรูป :</div>
                            <div class="black--text">ข้าวแห้ง 72,000.00 กรัม</div>
                          </div>
                        </v-col>
                        <v-col xl="4" lg="4">
                          <div class="d-flex">
                            <div class="mr-2">วันที่ผลิต :</div>
                            <div class="black--text">17/02/2023</div>
                          </div>
                        </v-col>
                        <v-col xl="4" lg="4">
                          <div class="d-flex">
                            <div class="mr-2">ฝ่ายผลิต (ชื่อ) :</div>
                            <div class="black--text">เกียติศักดิ์</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-text>
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <img class="icon-image-logo" src="@/assets/value-chain.png">
                      </v-avatar>
                      <span class="ml-3 title-card-text">การกำหนดรอบและการกำหนดวัตถุดิบ</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3"/>
                      <div class="ml-15 mt-4">
                        <div class="tag-title-step">
                          <b class="black--text">ส่วนที่ 1</b>
                        </div>
                        <div class="divider-table my-2"></div>
                        <div class="scroll-menu-last mt-4">
                          <section>
                            <div class="w-border-header-first mb-n3"/>
                            <div class="card-main-table">
                              <div class="py-3 d-flex justify-space-between">
                                <div class="black--text font-weight-bold">วัตถุดิบ</div>
                                <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                              </div>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12" class="inline-box-specific">
                                  <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                                </v-col>
                              </v-row>
                              <v-row class="pt-2">
                                <v-col xl="6" lg="6" style="margin-top: 2px">
                                  <div class="black--text font-weight-bold">ข้าวสารขาว</div>
                                  <div class="font-weight-bold">Lot ข้าวสารขาว</div>
                                </v-col>
                                <v-col xl="6" lg="6" style="margin-top: 2px">
                                  <div class="black--text font-weight-bold">5,000.00 กรัม</div>
                                </v-col>
                              </v-row>
                              <v-row class="pt-2">
                                <v-col xl="6" lg="6" style="margin-top: 2px">
                                  <div class="black--text font-weight-bold">น้ำกรอง</div>
                                  <div class="font-weight-bold">Lot น้ำกรอง</div>
                                </v-col>
                                <v-col xl="6" lg="6" style="margin-top: 2px">
                                  <div class="font-weight-bold">5,000.00 กรัม</div>
                                </v-col>
                              </v-row>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">รวม (FG)</div>
                                </v-col>
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">8,736.00 กรัม</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section>
                          <section class="last" v-for="(detail, index) in batchList" :key="index">
                            <div class="w-border-header-sub-last mb-n3"/>
                            <div class="card-sub-table-last">
                              <div class="py-3">
                                <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                              </div>
                              <div class="black--text font-weight-bold text-center">{{ detail.percent }}</div>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center">{{ detail.gams }} กรัม</div>
                                  <div class="font-weight-bold text-center">1-22230923823</div>
                                </v-col>
                              </v-row>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center">{{ detail.gamsFilter }} กรัม</div>
                                  <div class="font-weight-bold text-center">5-22230923823</div>
                                </v-col>
                              </v-row>
                              <v-row v-if="index === 1" class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center">5,000.00 กรัม</div>
                                  <div class="font-weight-bold text-center">5-22230923823</div>
                                </v-col>
                              </v-row>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">{{ detail.totalGams }} กรัม</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section>
                        </div>
                        <div class="summarize my-5 pa-5">
                          <h1 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป 71,9847.64 กรัม หรือ 71.98 กิโลกรัม</h1>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </section>
              <div class="my-3 text-center">
                <v-btn color="#88B358" class="white--text" elevation="4" rounded large>
                  <v-icon left>mdi-content-save</v-icon> ยืนยันการแปรรูป
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </div>
    <v-dialog v-model="add_dialog" transition="dialog-top-transition" max-width="500" style="border-radius:20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="add_dialog = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="d-flex flex-column align-center">
            <img class="icon-image mb-3" src="@/assets/notice.png">
          </div>
          <div class="text-center">
            <h1 class="black--text">วัตถุดิบไม่เพียงพอ</h1>
            <div class="my-2">วัตถุดิบไม่เพียงพอต่อการแปรรูปกรุณานำเข้าวัตถุดิบ</div>
          </div>
          <div class="mt-3 text-center">
            <v-btn class="white--text" color="#88B358" elevation="4" rounded large>
              <v-icon left>mdi-text-box-check-outline</v-icon> สั่งซื้อวัตถุดิบ
            </v-btn>
            <div class="ma-4" style="cursor: pointer">ยกเลิก</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProcessingRawMaterialsEdit",
  data() {
    return {
      e1: 1,
      progress_value: 0,
      add_dialog: false,
      checked: true,
      dataList: [
        {
          product: 'ข้าวสารขาว',
          totalInUse: '41,200.00',
          stockPrepare: 'ขาด : 20,200 กรัม (g)',
          statusReady: false,
        },
        {
          product: 'น้ำกรอง',
          totalInUse: '41,200.00',
          stockPrepare: 'พอต่อการแปรรูป',
          statusReady: true,
        },
      ],
      batchList: [
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
      ],
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/processing_raw_materials')
    },
    progress(val, step) {
      this.e1 = step
      this.progress_value = val
    },
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: baseline;
}
.icon-image {
  width: 120px;
  height: 120px;
  text-align: center;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.tag-title-step {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}
.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: 110%;
}
.inline-box-specific {
  background: #fff8e8;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
}
.w-border-header-sub {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: 60%;
}
.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding: 15px;
  width: 110%;
  height: 99%;
}
.card-sub-table {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 60%;
  width: 60%;
  transition: 0.5s;
}
.card-sub-table:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu {
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu section {
  display: inline-block;
  margin-right: -73px;
}
.scroll-menu section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  margin-right: 40px;
  padding-right: 24px;
  background: #FFFFFF;
  z-index: 1;
}
.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}
.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 100%;
  width: 100%;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-last .last {
  display: inline-block;
  margin-right: 2%;
}
.scroll-menu-last section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  margin-right: 2%;
  padding-right: 20px;
  background: #FFFFFF;
  z-index: 1;
}
.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}
.v-stepper__step {
  padding: 7px;
}
</style>