<template>
    <div>
        <QualityInspectionProductionReportDetailPage />
    </div>
</template>
  
<script>
import QualityInspectionProductionReportDetailPage from '@/components/stock/quality_inspection_report/QualityInspectionProductionReportDetail'
export default {
    name: 'QualityInspectionproductionreportdetailpage',
    components: {
        QualityInspectionProductionReportDetailPage,
    }
}
</script>