<template>
    <div>
        <WarehouseLotSlodOutRawMaterialProcess_page />
    </div>
</template>
  
<script>
import WarehouseLotSlodOutRawMaterialProcess_page from '@/components/stock/warehouse_stock/WarehouseLotSlodOutRawMaterialProcess'
export default {
    name: 'WarehouseLotSlodOutRawMaterialProcess',
    components: {
        WarehouseLotSlodOutRawMaterialProcess_page,
    }
}
</script>