<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/prm/processing_materials')">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนการแปรรูป</b>
        </v-card-title>
        <div>
          <div class="ma-8 mb-0 d-flex">
            <div :class="status_process >= 1 ? 'card-container active-card' : 'card-container un-active-card'">
              <div class="header-status-logo">
                <v-avatar :color="status_process >= 1 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" src="@/assets/process (2).png">
                </v-avatar>
              </div>
              <div>
                <div :class="status_process >= 1 ? 'sub-card-container sub-active-card' : 'sub-card-container'"
                  @click="linkToCreate('/prm/processing_edit/' + route_id)">
                  <div class="pa-3">
                    <h3 class="white--text">1 : สร้างใบสั่งแปรรูป</h3>
                    <div class="white--text">กำหนดรอบการแปรรูป</div>
                  </div>
                </div>
                <div class="pa-3">
                  <div class="mb-3 text-center">
                    <v-btn v-if="status_process >= 1" color="#88B358" elevation="4" rounded outlined
                      @click="getPDF(base_url + 'process/pdf/stepone/' + route_id)">
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบสั่งแปรรูป
                    </v-btn>
                    <v-btn v-else color="#88B358" elevation="4" rounded outlined disabled>
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบสั่งแปรรูป
                    </v-btn>
                  </div>
                  <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_1.user_name }} </div>
                  <div v-if="step_1.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                    formatDate(step_1.date) }}</div>
                  <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
                </div>
              </div>
            </div>
            <v-divider :class="status_process >= 2 ? 'active-divider' : 'un-active-divider'" />
            <div :class="status_process >= 2 ? 'card-container active-card' : 'card-container un-active-card'">
              <div class="header-status-logo">
                <v-avatar :color="status_process >= 2 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" src="@/assets/flour (4).png">
                </v-avatar>
              </div>

              <div :class="status_process >= 2 ? 'sub-card-container sub-active-card' : 'sub-card-container'"
                @click="(status_process >= 1 ? status_process >= 2 ? linkToCreate('/prm/processing_raw_materials/' + route_id + '/' + 0) : linkToCreate('/prm/processing_raw_materials/' + route_id) : '' )">
                <div class="pa-3">
                  <h3 class="white--text">2 : สร้างใบเตรียมวัตถุดิบ</h3>
                  <div class="white--text">กำหนดปริมาณวัตถุดิบและวางแผนการแปรรูป</div>
                </div>
              </div>
              <div class="pa-3">
                <div class="d-lg-flex d-xl-flex justify-space-around">
                  <div class="mb-3 mr-1 ml-1 text-center">
                    <v-btn v-if="status_process >= 2" color="#6C7FD8" elevation="4"
                      @click="linkToCreate('/prm/processing_raw_materials/' + route_id + '/' + 0)" rounded outlined>
                      <v-icon left>mdi-alert-circle</v-icon> ดูข้อมูล
                    </v-btn>
                    <v-btn v-else color="#6C7FD8" elevation="4"
                      @click="linkToCreate('/prm/processing_raw_materials/' + route_id + '/' + 0)" rounded outlined
                      disabled>
                      <v-icon left>mdi-alert-circle</v-icon> ดูข้อมูล
                    </v-btn>
                  </div>
                  <div class="mb-3 mr-1 text-center">
                    <v-btn v-if="status_process >= 2" color="#88B358" elevation="4" rounded outlined
                      @click="getPDF(base_url + 'process/pdf/stepTwo/' + route_id)">
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบเตรียมวัตถุดิบ
                    </v-btn>
                    <v-btn v-else color="#88B358" elevation="4" rounded outlined
                      @click="getPDF(base_url + 'process/pdf/stepTwo/' + route_id)" disabled>
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบเตรียมวัตถุดิบ
                    </v-btn>
                  </div>
                </div>
                <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_2.user_name }}</div>
                <div v-if="step_2.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                  formatDate(step_2.date) }}</div>
                <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
              </div>
            </div>
            <v-divider :class="status_process >= 3 ? 'active-divider' : 'un-active-divider'" />
            <div :class="status_process >= 3 ? 'card-container active-card' : 'card-container un-active-card'">
              <div :class="status_process >= 3 ? 'sub-card-container sub-active-card' : 'sub-card-container'"
                @click="status_process >= 2 ? linkToCreate('/prm/production_result_create/' + route_id) : ''">
                <div class="header-status-logo">
                  <v-avatar :color="status_process >= 3 ? '#FFBC15' : '#000'" size="36">
                    <img class="icon-image-logo" src="@/assets/editing(2).png">
                  </v-avatar>
                </div>
                <div>
                  <div class="pa-3">
                    <h3 class="white--text">3 : บันทึกผลการแปรรูป</h3>
                    <div class="white--text">บันทึกผลการแปรรูปตามแผน</div>
                  </div>
                </div>
              </div>
              <div class="pa-3">
                <div class="mb-3 text-center">
                  <v-btn v-if="status_process >= 3" color="#88B358" elevation="4" rounded outlined
                    @click="getPDF(base_url + 'process/pdf/stepThree/' + route_id)">
                    <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบรายงานการแปรรูป
                  </v-btn>
                  <v-btn v-else color="#88B358" elevation="4" rounded outlined
                    @click="getPDF(base_url + 'process/pdf/stepThree/' + route_id)" disabled>
                    <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบรายงานการแปรรูป
                  </v-btn>
                </div>
                <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_3.user_name }}</div>
                <div v-if="step_3.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                  formatDate(step_3.date) }}</div>
                <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-end">
            <v-divider vertical :class="status_process >= 4 ? 'active-divider-right' : 'un-active-divider-right'" />
          </div>
          <div class="ma-8 mt-0 d-flex">
            <div :class="status_process >= 6 ? 'card-container active-card' : 'card-container un-active-card'"
              @click="status_process >= 5 ? linkToCreate('/prm/production_confirm/' + route_id) : ''">
              <div class="header-status-logo">
                <v-avatar :color="status_process >= 6 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" src="@/assets/approved.png">
                </v-avatar>
              </div>
              <div>
                <div :class="status_process >= 6 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                  <div class="pa-3">
                    <h3 class="white--text">6 : ยืนยันการแปรรูปสำเร็จ</h3>
                    <div class="white--text">อนุมัติตรวจสอบการแปรรูป</div>
                  </div>
                </div>
                <div class="pa-3">
                  <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_6.user_name }}</div>
                  <div v-if="step_6.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                    formatDate(step_6.date) }}</div>
                  <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
                </div>
              </div>
            </div>

            <v-divider :class="status_process >= 5 ? 'active-divider' : 'un-active-divider'" />
            <div :class="status_process >= 5 ? 'card-container active-card' : 'card-container un-active-card'"
              @click="status_process >= 4 ? linkToCreate('/prm/production_process_steps/' + route_id) : ''">
              <div class="header-status-logo">
                <v-avatar :color="status_process >= 5 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" src="@/assets/quality (1).png">
                </v-avatar>
              </div>
              <div>
                <div :class="status_process >= 5 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                  <div class="pa-3">
                    <h3 class="white--text">5 : การตรวจสอบวัตถุดิบที่แปรรูป</h3>
                    <div class="white--text">การตรวจสอบคุณภาพการผลิตการแปรรูป</div>
                  </div>
                </div>
                <div class="pa-3">
                  <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_5.user_name }}</div>
                  <div v-if="step_5.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                    formatDate(step_5.date) }}</div>
                  <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
                </div>
              </div>
            </div>
            <v-divider :class="status_process >= 4 ? 'active-divider' : 'un-active-divider'" />
            <div :class="status_process >= 4 ? 'card-container active-card' : 'card-container un-active-card'"
              @click="status_process >= 3 ? linkToCreate('/prm/production_process_recheck/' + route_id) : ''">
              <div :class="status_process >= 4 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                <div class="header-status-logo">
                  <v-avatar :color="status_process >= 4 ? '#FFBC15' : '#000'" size="36">
                    <img class="icon-image-logo" src="@/assets/quality.png">
                  </v-avatar>
                </div>
                <div>
                  <div class="pa-3">
                    <h3 class="white--text">4 : การตรวจสอบกระบวนการแปรรูป</h3>
                    <div class="white--text">การตรวจสอบคุณภาพการแปรรูป</div>
                  </div>
                </div>
              </div>
              <div class="pa-3">
                <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_4.user_name }}</div>
                <div v-if="step_4.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                  formatDate(step_4.date) }}</div>
                <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import process_data from '@/api/process/meterial_process.js'
export default {
  name: "ProcessingSteps",
  data() {
    return {
      route_id: this.$route.params.id,
      loading_page: true,
      step_1: {},
      step_2: {},
      step_3: {},
      step_4: {},
      step_5: {},
      step_6: {},
      status_process: null,
      base_url: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    getPDF(link) {
      window.open(link, '_blank')
    },
    linkToCreate(text) {
      window.location.href = text;
    },
    link(path) {
      this.$router.push(path)
    },
    getStepProcess() {
      this.loading_page = true;
      let id = this.$route.params.id;
      process_data.getStepProcess(id).then((response) => {
        if (response.data.successful === true) {
          this.step_1 = response.data.step_1;
          this.step_2 = response.data.step_2;
          this.step_3 = response.data.step_3;
          this.step_4 = response.data.step_4;
          this.step_5 = response.data.step_5;
          this.step_6 = response.data.step_6;
          this.status_process = response.data.status_process;

          this.loading_page = false;
        }
      })
    },
    formatDate(date) {
      const [datePart] = date.split(" ");
      const [day, month, year] = datePart.split("/");
      return `${day}-${month}-${year}`;
    },
  },
  mounted() {
    this.getStepProcess();

  },
  // computed: {
  //   formattedDate() {
  //     const [datePart] = this.step_1.date.split(" ");
  //     const [day, month, year] = datePart.split("/");
  //     return `${day}-${month}-${year}`;
  //   },
  // },

}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.card-container {
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 15px;
  inline-size: fit-content;
  width: 60%;
}

.active-card {
  cursor: pointer;
  border: 1px solid #FFBC15;
  border-radius: 15px;
  inline-size: 26%;
}

.un-active-card {
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 15px;
  inline-size: 26%;
}

.sub-card-container {
  cursor: pointer;
  background-color: #000000;
  border: 1px solid #000000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.sub-active-card {
  cursor: pointer;
  background-color: #FFBC15;
  border: 1px solid #FFBC15;
  border-radius: 15px;
}

.active-divider.v-divider {
  border: 5px solid #ffbc15;
  align-self: center;
}

.active-divider-right.v-divider {
  border: 5px solid #ffbc15;
  margin-right: 12%;
  height: 130px;
}

.un-active-divider-right.v-divider {
  border: 5px solid #D9D9D9;
  margin-right: 12%;
  height: 130px;
}

.un-active-divider.v-divider {
  border: 5px solid #D9D9D9;
  align-self: center;
}

.header-status-logo {
  position: absolute;
  margin-left: 16.5%;
  margin-top: -24px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  padding: 1px;
  background-color: #FFBC15;
}
</style>