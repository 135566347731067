import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    add: (data) => {
        return HTTP.post('/material/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => response)
    },
    dataList: (page, size, search) => {
        return HTTP.get(`/material/list?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    detail: (id) => {
        return HTTP.get('/material/detail/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    edit: (id, data) => {
        return HTTP.post('/material/edit/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => response)
    },
    delete: (id) => {
        return HTTP.post('/material/delete/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    search: (data) => {
        return HTTP.post('/material/search', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            },
        })
            .then(response => response)
    },
    updateStatus: (id, status) => {
        return HTTP.post('/material/updatestatus/'+id, status, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            },
        })
            .then(response => response)
    },
    list: () => {
        return HTTP.get('/material/list/all?mat_status=1',{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    list_all: (mat_id) => {
        return HTTP.get('/material/list/for/create/mat/formula?mat_id='+mat_id,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },

    list_status_two: (data) => {
        return HTTP.post('/material/list/by/mat_status',data,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    list_status_two_new: () => {
        return HTTP.get('/material/list/for/create/pro/mat/pack',{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },

    list_status_three: () => {
        return HTTP.get('/material/list/to/create/product',{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    meterialList(page, size, search, status) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials?page=${page}&size=${size}&search=${search}&mat_status=${status}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      meterialDetail(id, page, size, search, status) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/${id}?page=${page}&size=${size}&search=${search}&type=${status}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      meterialLot(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/lot/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      meterialLotOut(id, type) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/process/lot/detail/${id}?type=${type}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      meterialReport(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/lot/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getMeterialStock(id, year ,search ,page ) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/process/stock/${id}?year=${year}&search=${search}&page=${page}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getMeterialStock_normal(id, year ,search ,page) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/stock/${id}?year=${year}&search=${search}&page=${page}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      exportmeterial(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/inventory/materials/export`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getMeterialExport(search, page, size) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/export/list?search=${search}&page=${page}&size=${size}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getProductExport(search, page, size) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/product/export/list?search=${search}&page=${page}&size=${size}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getMeterialList() {
        return new Promise((resolve, reject) => {
          HTTP.get(`/material/list/process`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getUserList() {
        return new Promise((resolve, reject) => {
          HTTP.get(`/user/select/box`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getReport(id, search, year, month, page) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/report/quality/${id}?year=${year}&month=${month}&page=${page}&search=${search}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getReportInspection(id, year, month, type, page, search) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/inventory/materials/process/report/quality/${id}?year=${year}&month=${month}&type=${type}&page=${page}&search=${search}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      getMeterialSelectbox() {
        return new Promise((resolve, reject) => {
          HTTP.get(`/material/list/import`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
}