<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">แก้ไขสินค้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>

        <v-card elevation="4" class="card-radius">
            <v-card-title class="d-flex justify-space-between">
                <b>ขั้นตอนการแก้ไขข้อมูล</b>
                <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด :
                    <span class="text-fullname-title">{{ product_data_1.user_edit }}</span>
                    <span class="text-date-title ml-3">วันที่ : </span>
                    <span class="text-datetime-title">{{ product_data_1.updated_at }}</span>
                </div>
            </v-card-title>
            <v-card-text>
                <v-btn @click="material_change(1), getProductByid(1)" class="ma-2"
                    :class="material_step == 1 ? 'tab-button active' : 'tab-button-disable'" type="button"
                    :color="material_step == 1 ? '#FFBC15' : '#909090'" large outlined>ข้อมูลสินค้า</v-btn>

                <v-btn @click="material_change(2), getProductByid(2)" class="ma-2"
                    :class="material_step == 2 ? 'tab-button active' : 'tab-button-disable'" type="button" large outlined
                    :color="material_step == 2 ? '#FFBC15' : '#909090'">ข้อมูลขนาดสินค้าและบรรจุภัณฑ์</v-btn>

                <v-btn @click="material_change(3), getProductByid(3)" class="ma-2"
                    :class="material_step == 3 ? 'tab-button active' : 'tab-button-disable'" type="button" large outlined
                    :color="material_step == 3 ? '#FFBC15' : '#909090'">ข้อมูลการผลิต</v-btn>

                <v-btn @click="material_change(4), getProductByid(4)" class="ma-2"
                    :class="material_step == 4 ? 'tab-button active' : 'tab-button-disable'" type="button" large outlined
                    :color="material_step == 4 ? '#FFBC15' : '#909090'">เกณฑ์การตรวจสอบ</v-btn>

            </v-card-text>

            <!-- ข้อมูลสินค้า -->
            <v-card-text v-if="material_step == 1">
                <v-row>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <v-form @submit.prevent="saveProduct(1)">
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex align-items-center">
                                    <v-avatar color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-package-variant-closed
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">ข้อมูลสินค้า</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col cols="12" md="12" lg="12">
                                            <span class="ml-3 text-font-title">รูปภาพสินค้า</span>
                                        </v-col>

                                        <v-col cols="12" md="5" lg="5">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="image_preview text-center">
                                                        <v-avatar class="mt-2" style="border-radius: 10%;" size="210">
                                                            <img v-if="imagePreviewURL" :src="imagePreviewURL">
                                                            <div class="upload_image">
                                                                <!-- <v-btn v-if="!profile_picture" class="white--text" color="#FD8F16" depressed fab :loading="isSelectingImage" @click="handleFileImport"><v-icon>mdi-pencil</v-icon></v-btn> -->

                                                                <v-file-input class="d-none" id="uploader" accept="image/*"
                                                                    v-model="product_data_1.pro_pic" @change="onFileChange">
                                                                </v-file-input>
                                                            </div>
                                                        </v-avatar>
                                                        <v-file-input class="" id="uploader" accept="image/*" color="#FFBC15"
                                                            placeholder="เลือกรูปภาพสินค้า" v-model="product_data_1.pro_pic"
                                                            @change="onFileChange">
                                                        </v-file-input>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="7" lg="7">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-form-group>
                                                        <v-text-field type="text" class="input-radius" label="รหัสผลิตภัณฑ์"
                                                            placeholder="รหัสผลิตภัณฑ์" color="#FFBC15"
                                                            v-model="product_data_1.pro_code"
                                                            :rules="[v => !!v || 'กรุณากรอกรหัสผลิตภัณฑ์']"
                                                            hide-details="auto" dense outlined rounded required>
                                                        </v-text-field>
                                                    </v-form-group>
                                                </v-col>

                                                <v-col cols="12">

                                                    <v-form-group>
                                                        <v-text-field type="text" class="input-radius" label="ชื่อสินค้า"
                                                            placeholder="ชื่อสินค้า" color="#FFBC15"
                                                            v-model="product_data_1.pro_name"
                                                            :rules="[v => !!v || 'กรุณากรอกชื่อสินค้า']" hide-details="auto"
                                                            dense outlined rounded required>
                                                        </v-text-field>
                                                    </v-form-group>
                                                </v-col>

                                                <v-col cols="12" lg="7" md="7" sm="7">
                                                    <v-form-group>
                                                        <v-autocomplete :items="type_data" item-text="pro_type_name"
                                                            item-value="id" v-model="product_data_1.product_type_id"
                                                            label="ประเภทสินค้า" color="#FFBC15"
                                                            :rules="[v => !!v || 'กรุณาเลือกประเภทสินค้า']"
                                                            hide-details="auto" dense outlined rounded required></v-autocomplete>
                                                    </v-form-group>
                                                </v-col>
                                            </v-row>


                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="mt-5 div-submit-data">
                                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                    <v-icon left>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                            </div>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- ข้อมูลสินค้า -->

            <!-- ข้อมูลขนาดสินค้าและบรรจุภัณฑ์ -->
            <v-card-text v-if="material_step == 2">
                <v-row>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <v-form ref="size_list" @submit.prevent="saveProduct(2)">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">

                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-arrow-expand
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลขนาดสินค้า</span>

                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#FFBC15"></v-divider>
                                <v-row class="px-10">

                                    <v-col cols="12" md="6" lg="6">
                                        <v-form-group>
                                            <v-autocomplete :items="size_data" @change="addSize()" item-text="pro_size_name"
                                                item-value="id" v-model="size_id" label="ขนาดสินค้า"
                                                :rules="[v => product_data_2.length > 0 || 'กรุณาเลือกขนาดสินค้า']" hide-details="auto" dense
                                                outlined rounded required></v-autocomplete>
                                        </v-form-group>
                                    </v-col>

                                    <v-col cols="12" md="6" lg="6"></v-col>

                                    <v-col cols="12" v-for="(item, i) in product_data_2" :key="item">
                                        <v-card class="radius-20">
                                            <v-card-title class="">
                                                <v-row>
                                                    <v-col cols="6" lg="8">
                                                        <v-avatar color="#34230F" size="32">
                                                            <v-icon dark>
                                                                mdi-archive-search-outline
                                                            </v-icon>
                                                        </v-avatar>
                                                        <span class="ml-3 text-font-title">{{ item.pro_size_name }}</span>
                                                    </v-col>
                                                    <v-col class="text-right" cols="6" lg="4">
                                                        <div class="error--text" style="cursor:pointer;"
                                                            @click="parseFloat(item.instock) <= 0 ? setproductsize_delete(i) : no_delete()">
                                                            <v-icon dark color="error" size="28">
                                                                mdi-delete-forever
                                                            </v-icon>
                                                            <span class="ml-3 f-14">ลบขนาดสินค้า</span>
                                                        </div>
                                                    </v-col>
                                                </v-row>

                                            </v-card-title>
                                            <v-card-text>
                                                <v-divider class="mb-3" color="#686868"></v-divider>

                                                <v-row class="pl-10">

                                                    <v-col cols="12" md="6" lg="6">

                                                        <v-form-group>
                                                            <label for="">ราคาขาย</label>
                                                            <v-text-field type="text" class="input-radius mt-4"
                                                                label="ราคาขาย" placeholder="ราคาขาย" color="#FFBC15"
                                                                v-model="item.price"
                                                                :rules="[v => !!v || 'กรุณากรอก ราคาขาย']"
                                                                hide-details="auto" dense outlined rounded required>
                                                            </v-text-field>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col cols="12" md="6" lg="6"></v-col>

                                                    <v-col cols="12" md="6" lg="6">
                                                        <v-form-group>
                                                            <label for="">การผูกสูตรบรรจุภัณฑ์</label>
                                                            <v-autocomplete :items="package_data" class="mt-4"
                                                                item-text="mat_name" item-value="id" v-model="package_id" ref="package_data"
                                                                label="เลือกวัตถุดิบในการผูกสูตรบรรจุภัณฑ์" color="#FFBC15" :rules="[v => item.list.length > 0 || 'กรุณาเลือก']"
                                                                @change="addPackage(i)" hide-details="auto" dense outlined
                                                                rounded></v-autocomplete>
                                                        </v-form-group>
                                                    </v-col>
                                                    <v-col cols="12" md="6" lg="6"></v-col>

                                                </v-row>

                                                <v-row class="px-15">
                                                    <v-col cols="6">วัตถุดิบ</v-col>
                                                    <v-col cols="6">จำนวนที่ตัดวัตถุดิบต่อ 1 หน่วยสินค้า</v-col>
                                                </v-row>

                                                <v-row class="px-15" v-for="(list, index) in item.list" :key="list">
                                                    <v-col cols="6">{{ list.mat_name }}</v-col>
                                                    <v-col cols="6">
                                                        <v-form-group class="flex">
                                                            <v-text-field type="text" class="input-radius mr-2"
                                                                label="จำนวนที่ตัดวัตถุดิบต่อ 1 หน่วยสินค้า"
                                                                placeholder="จำนวนที่ตัดวัตถุดิบต่อ 1 หน่วยสินค้า"
                                                                color="#FFBC15" v-model="list.mat_cut_qty" :rules="[v => !!parseFloat(v) && parseFloat(v) > 0 || 'กรุณากรอก ราคาขาย']"
                                                                hide-details="auto" dense outlined rounded required>
                                                            </v-text-field>
                                                            <v-btn type="button" outlined color="#F28080" elevation="1"
                                                                class="radius-10" @click="removePackage(i, index)">
                                                                <v-icon dark color="error" size="28">
                                                                    mdi-delete-forever
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-form-group>
                                                    </v-col>
                                                </v-row>

                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>

                        <div class="mt-5 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large
                                >
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- ข้อมูลขนาดสินค้าและบรรจุภัณฑ์ -->

            <!-- ข้อมูลการผลิต -->
            <v-card-text v-if="material_step == 3">
                <v-row>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">

                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-cog
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลสูตรการผลิต</span>

                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row class="px-10">

                                    <v-col cols="12" md="6" lg="6">
                                        <v-form-group>
                                            <label for="">ปริมาณที่ได้จากสูตรการผลิต</label>
                                            <v-text-field type="text" class="input-radius mt-4"
                                                label="ปริมาณที่ได้จากสูตรการผลิต" placeholder="ปริมาณที่ได้จากสูตรการผลิต"
                                                color="#686868" v-model="product_data_3.pro_formula"
                                                :rules="[v => !!v || 'กรุณากรอก ปริมาณที่ได้จากสูตรการผลิต']"
                                                hide-details="auto" dense outlined rounded required>
                                            </v-text-field>
                                        </v-form-group>
                                    </v-col>

                                    <v-col cols="12" md="6" lg="6"></v-col>

                                    <!-- loop ขนาดสินค้า -->
                                    <v-col cols="12" v-for="(product_part_loop, index) in product_data_3.formula"
                                        :key="index">
                                        <v-card class="radius-20">
                                            <v-card-title class="">
                                                <v-row>
                                                    <v-col cols="6" lg="8">
                                                        <v-avatar color="#34230F" size="32">
                                                            <v-icon dark>
                                                                mdi-cog-sync
                                                            </v-icon>
                                                        </v-avatar>
                                                        <span class="ml-3 text-font-title">การผลิตส่วนที่ {{ index + 1
                                                        }}</span>
                                                    </v-col>
                                                    <v-col class="text-right" cols="6" lg="4">
                                                        <div class="error--text" style="cursor:pointer;"
                                                            @click="setproductsize_delete(index)" v-if="(index + 1) >= 1">
                                                            <v-icon dark color="error" size="28">
                                                                mdi-delete-forever
                                                            </v-icon>
                                                            <span class="ml-3 f-14">ลบรายการตรวจสอบการผลิต</span>
                                                        </div>
                                                    </v-col>
                                                </v-row>

                                            </v-card-title>
                                            <v-card-text>
                                                <v-divider class="mb-3" color="#686868"></v-divider>

                                                <v-row class="pl-10">

                                                    <v-col cols="12" md="12" lg="12">
                                                        <v-form-group>
                                                            <v-autocomplete :items="raw_material_product_List" class="mt-4"  :menu-props="{ top: true, offsetY: true }"
                                                                item-text="mat_name" item-value="id" v-model="package_3_id"
                                                                label="เลือกวัตถุดิบในการผลิต" @change="addPackage_2(index)"
                                                                hide-details="auto" dense outlined rounded></v-autocomplete>
                                                        </v-form-group>
                                                    </v-col>

                                                </v-row>

                                                <v-row class="px-15">
                                                    <v-col cols="6">วัตถุดิบ</v-col>
                                                    <v-col cols="6">จำนวนวัตถุดิบในสูตร</v-col>
                                                </v-row>

                                                <v-row class="px-15" v-for="(item, i) in product_part_loop.list" :key="i">
                                                    <v-col cols="6">{{ item.mat_name }}</v-col>
                                                    <v-col cols="6">
                                                        <v-form-group class="flex">
                                                            <v-text-field type="text" class="input-radius mr-2"
                                                                label="จำนวนวัตถุดิบในสูตร"
                                                                placeholder="จำนวนวัตถุดิบในสูตร" color="#686868"
                                                                v-model="item.materials_qty"
                                                                :rules="[v => !!v || 'กรุณากรอก ราคาขาย']"
                                                                hide-details="auto" dense outlined rounded required>
                                                            </v-text-field>
                                                            <v-btn type="button" @click="removePackage(i, index)" outlined
                                                                color="#F28080" elevation="1" class="radius-10">
                                                                <v-icon dark color="error" size="28">
                                                                    mdi-delete-forever
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-form-group>
                                                    </v-col>
                                                </v-row>

                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <!-- loop ขนาดสินค้า -->

                                </v-row>
                            </v-card-text>

                            <div class="mt-3 div-submit-data">
                                <v-btn outlined type="button" class="button-submit-data" @click="plusPPL()"
                                    style="color: rgb(136, 179, 88) !important;" elevation="4" rounded large>
                                    <v-icon left>mdi-plus</v-icon> เพิ่มส่วนการแปรรูป
                                </v-btn>
                            </div>
                            <br>
                        </v-card>

                        <div class="mt-5 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large
                                @click="saveProduct(3)">
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- ข้อมูลการผลิต -->

            <!-- เกณฑ์การตรวจสอบ -->
            <v-card-text v-if="material_step == 4">
                <v-row>
                    <v-form ref="step_3_list" @submit.prevent="saveProduct(4)">
                    <!-- เกณฑ์การตรวจสอบคุณภาพ -->
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">

                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-cog
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>

                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row class="px-10">

                                    <v-col cols="12" md="12" lg="12">
                                        <v-form-group>
                                            <v-autocomplete :items="product_quality_list" item-value="id"
                                                :item-text="concatenatedText" class="input-radius mt-4"
                                                label="เกณฑ์การตรวจสอบคุณภาพ" v-model="product_quality_id"
                                                @change="getProductQuality" placeholder="เกณฑ์การตรวจสอบคุณภาพ"
                                                color="#686868" :rules="[v => dataProductQualityList.length > 0 || 'กรุณากรอก เกณฑ์การตรวจสอบคุณภาพ']"
                                                dense filled multiple rounded solo chips clearable hide-details="">
                                            </v-autocomplete>
                                        </v-form-group>
                                    </v-col>

                                    <!-- loop ขนาดสินค้า -->
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <div class="table-header-setstep-4-product-box-data display-pc"
                                            style="color: #b1adad;">
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                ชื่อเกณฑ์ (English)
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                ชื่อเกณฑ์ (ภาษาไทย)
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Unit
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Aim
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Green
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Yellow
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Red
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Value
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                ค่าอัตโนมัติ
                                            </div>
                                        </div>
                                    </v-col>
                                    <!-- loop ขนาดสินค้า -->

                                    <v-col>
                                        <v-expansion-panels>

                                            <v-expansion-panel v-for="(quality_data, index) in dataProductQualityList"
                                                :key="index" :class="{ border_left_yellow: quality_data.isActive }"
                                                class="radius-10 mt-3" @click="toggleActive(index)">
                                                <v-expansion-panel-header class="radius-10 border-yellow">
                                                    {{ quality_data.pq_title.pq_name_eng }}     {{ quality_data.pq_title.pq_name_thai }}  
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="">
                                                    <div class="mt-4"
                                                        v-for="(quality_sub_data, index) in quality_data.pq_list"
                                                        :key="index">
                                                        <div class="table-header-setstep-4-product-box-data display-pc mt-3"
                                                            style="color: #b1adad;">
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_eng }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_thai }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.unit }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_aim }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_green }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_yellow }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_red }}
                                                            </div>
                                                            <div
                                                                class="table-header-setstep-4-product-box-data-text text-center">
                                                                <v-icon color="#88b358">mdi-check-circle</v-icon>
                                                            </div>
                                                            <div
                                                                class="table-header-setstep-4-product-box-data-text text-center">
                                                                <v-icon color="#FFBC15" v-if="quality_sub_data.pq_value_status === 1
                                                                    ">mdi-check-circle</v-icon>
                                                                {{
                                                                    quality_sub_data.pq_value_status === 0
                                                                    ? "-"
                                                                    : ""
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                        </v-expansion-panels>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- เกณฑ์การตรวจสอบการผลิต -->
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">

                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-cog
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบการผลิต</span>

                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row class="px-10">

                                    <v-col cols="12" md="12" lg="12">
                                        <v-form-group>
                                            <v-autocomplete :items="factory_quality_list" item-value="id"
                                                item-text="fq_name" class="input-radius mt-4"
                                                label="เกณฑ์การตรวจสอบการผลิต" v-model="factory_quality_id"
                                                placeholder="เกณฑ์การตรวจสอบการผลิต" color="#686868"
                                                :rules="[v => dataFactoryQualityList.length > 0 || 'กรุณากรอก เกณฑ์การตรวจสอบการผลิต']"
                                                @change="getFactoryQuality" dense filled multiple rounded solo chips
                                                clearable hide-details="">
                                            </v-autocomplete>
                                        </v-form-group>
                                    </v-col>

                                    <!-- loop ขนาดสินค้า -->
                                    <v-col class="mb-0 pb-0" xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <div class="table-header-setstep-4-product-quality-box-data display-pc"
                                            style="color: #b1adad;">
                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                ชื่อเกณฑ์
                                            </div>
                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                ระยะค่าในเกณฑ์
                                            </div>
                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                Value
                                            </div>
                                            <div class="table-header-setstep-4-product-quality-box-data-text text-center">
                                                ค่าอัตโนมัติ
                                            </div>
                                        </div>
                                    </v-col>

                                    <v-col>
                                        <v-expansion-panels>

                                            <v-expansion-panel v-for="(production_data, index) in dataFactoryQualityList"
                                                :key="index" :class="{ border_left_yellow: production_data.isActive }"
                                                class="radius-10 mt-3" @click="toggleActive_production(index)">
                                                <v-expansion-panel-header class="radius-10 border-yellow">
                                                    {{ production_data.fq_title.fq_name }}
                                                </v-expansion-panel-header>

                                                <v-expansion-panel-content>
                                                    <div class="mt-4"
                                                        v-for="(production_sub_data, index) in production_data.fq_list"
                                                        :key="index">
                                                        <div class="table-header-setstep-4-product-quality-box-data display-pc mt-3"
                                                            style="color: #b1adad;">
                                                            <div
                                                                class="table-header-setstep-4-product-quality-box-data-text">
                                                                {{ production_sub_data.fq_list_name }}
                                                            </div>
                                                            <div
                                                                class="table-header-setstep-4-product-quality-box-data-text">
                                                                {{ production_sub_data.fq_list_guide }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                                <span v-if="production_sub_data.fq_value_status === 1">
                                                                    <span v-if="production_sub_data.fq_list_type === 1">
                                                                        {{ production_sub_data.fq_value }}
                                                                    </span>
                                                                    <span v-else>
                                                                        <span v-if="production_sub_data.fq_value_boolean === 1">
                                                                            <v-icon color="#88b358">mdi-check-circle</v-icon>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div class="table-header-setstep-4-product-quality-box-data-text text-center">
                                                                <span v-if="production_sub_data.fq_value_status === 1">
                                                                    <span v-if="production_sub_data.fq_value_boolean === 1">
                                                                        <v-icon color="#FFBC15">mdi-check-circle</v-icon>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                        </v-expansion-panels>
                                    </v-col>

                                    <!-- loop ขนาดสินค้า -->

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large
                                >
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-col>
                        </v-form>
                </v-row>
            </v-card-text>
            <!-- เกณฑ์การตรวจสอบ -->

        </v-card>

        <!-- ลบข้อมูล -->
        <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูลขนาดสินค้า</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลขนาดสินค้าใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large
                                    @click="removeMapSize(get_delete_id)">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:14px;" text @click="confirm_delete_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->

    </div>
</template>
<script>
import before_image from '@/assets/groceries.png';
import productType from "@/api/warehouse/productType.js";
import product from "@/api/warehouse/product.js";
import productSize from "@/api/warehouse/productSize.js";
import { isTelephone } from "@/helper/handler";
import material from "@/api/warehouse/materials.js";
import qualityinspectioncriteria from "@/api/warehouse/qualityinspectioncriteria.js";
import inspectioncriteriaproduction from "@/api/warehouse/inspectioncriteriaproduction.js";
import Swal from "sweetalert2";
export default {
    data() {
        return {
            loading_page: false,
            stepImport: 1,
            imagePreviewURL: before_image,
            confirm_delete_dialog: false,
            color_step1: '#7D7D7D',
            color_step2: '#7D7D7D',
            color_step3: '#7D7D7D',
            color_step4: '#7D7D7D',
            material_step: 1,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลสินค้า',
                    disabled: false,
                    href: "/stock/setting-stock/set-product-component",
                },
                {
                    text: 'ตั้งสินค้า',
                    disabled: false,
                    href: "/stock/setting-stock/set-product-component",
                },
                {
                    text: 'แก้ไขสินค้า',
                    disabled: false,
                    href: '#',
                },
            ],
            unit: [
                { id: 1, name: 'แพ็ค 5' },
                { id: 2, name: 'แพ็ค 10' },
                { id: 3, name: 'แพ็ค 15' },
            ],
            type_data: [],
            size_data: [],
            product_data_1: {},
            product_data_2: {},
            product_data_3: {},
            product_data_4: {},
            package_data: [],
            size_id: null,
            package_id: null,
            package_3_id: null,
            get_delete_id: null,
            raw_material_product_List: [],
            product_quality_list: [],
            dataProductQualityList: [],
            factory_quality_list: [],
            dataFactoryQualityList: [],
            product_quality_id: [],
            factory_quality_id: [],
            arr_del_product_mapsize_id: [],
            arr_del_product_materials_pack_id: [],
            arr_del_product_formula_id: [],
            arr_del_product_formula_part: [],
            //// test variable
            test: {},
            items: [
                'ผงปรุงรส', 'ผลิตภัณฑ์เหลว'
            ],
            quality_list: [
                {
                    name_main: 'Strup coating',
                    isActive: false,
                    quality_sub: [
                        {
                            name_en: 'Homogeneous',
                            name_th: 'ความเข้ากัน(ไม่เป็นก้อน)',
                            unit: '-',
                            aim: '-',
                            green: 'เป็นเนื้อเดียวกัน',
                            yellow: 'เป็นก้อน',
                            red: '-',
                        },
                        {
                            name_en: 'Plain Crispy RiceWeight',
                            name_th: 'น้ำหนักความพองก่อนเคลือบ',
                            unit: 'Kg',
                            aim: '-',
                            green: '-',
                            yellow: '-',
                            red: '-',
                        },
                        {
                            name_en: 'Syrup weight',
                            name_th: 'น้ำหนักน้ำเคลือบ',
                            unit: 'Kg',
                            aim: '-',
                            green: '-',
                            yellow: '-',
                            red: '-',
                        },
                    ],
                },
                {
                    name_main: 'Drying 2',
                    isActive: false,
                    quality_sub: [
                        {
                            name_en: 'Plain Crispy RiceWeight',
                            name_th: 'น้ำหนักความพองก่อนเคลือบ',
                            unit: 'Kg',
                            aim: '-',
                            green: '-',
                            yellow: '-',
                            red: '-',
                        },
                    ],
                },
            ],

            production_list: [
                {
                    name_main_criterion: 'Fryer',
                    isActive: false,
                    production_sub: [
                        {
                            name_criterion: 'Time',
                            range_criterion: '200-220 C',
                            value_criterion: '',
                        },
                        {
                            name_criterion: 'Appearance',
                            range_criterion: 'ไม่ไหม้ สีสม่ำเสมอ',
                            value_criterion: 'ผ่าน',
                        },
                        {
                            name_criterion: 'Texture',
                            range_criterion: 'กรอบ ไม่แข็งเป็นไต',
                            value_criterion: 'ผ่าน',
                        },
                    ],
                },
                {
                    name_main_criterion: 'FG Spec',
                    isActive: false,
                    production_sub: [
                        {
                            name_criterion: 'Bulk Density Fried Rice (Jasmine BR)',
                            range_criterion: '0.23-0.29',
                            value_criterion: 'ผ่าน',
                        }
                    ],

                },
            ],
            tab: null,
            menu: false,
            modal: false,
            menu2: false,
            toggle_exclusive: 2,
            page: 1,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,


            // ตัวแปลข้อมูล step ๅ
            code_list: '',
            product_name_list: '',
            type_list: null,
            size_list: null,
            product_part_list: [
                {
                    no_ppl_id: 1,
                    ppl_list_select: '',
                    ppl_list_formula: '',
                }
            ],
            arr_del_product_product_quality: [],
            arr_del_product_factory_quality: [],
        }
    },

    methods: {
        no_delete(){
            Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'warning',
                            html: 'กรุณานำสินค้าออกจากคลัง!',
                            confirmButtonText:"ตกลง",
                        })
        },
        concatenatedText(item) {
        // Concatenate the two properties you want here for each item
        return item.pq_name_eng + '  ' + item.pq_name_thai;
        },
        link(link) {
            this.$router.push(link)
        },
        material_change(type) {
            this.material_step = type
        },
        toggleActive(index) {
            let clickedButton = this.product_quality_list[index];
            if (!clickedButton.isActive) {
                this.product_quality_list.forEach((loops) => {
                    loops.isActive = false;
                });
            }
            clickedButton.isActive = !clickedButton.isActive;
            if (this.product_quality_list.every((loops) => !loops.isActive)) {
                this.product_quality_list.forEach((loops) => {
                    loops.isActive = false;
                });
            }
        },

        toggleActive_production(index) {
            let click_pro = this.factory_quality_list[index];
            if (!click_pro.isActive) {
                this.factory_quality_list.forEach((loopro) => {
                    loopro.isActive = false;
                });
            }
            click_pro.isActive = !click_pro.isActive;
            if (this.factory_quality_list.every((loopro) => !loopro.isActive)) {
                this.factory_quality_list.forEach((loopro) => {
                    loopro.isActive = false;
                });
            }
        },
        async plusPPL() {
            let sum = 0;
            for (let i = 0; i < this.product_data_3.formula.length; i++) {
                sum = this.product_data_3.formula[i].list.length;
            }
            if (sum != 0) {
                this.product_data_3.formula.push({
                    list: [],
                    part_formula: this.product_data_3.formula.length + 1,
                    product_id: this.product_data_3.formula[0].list[0].product_id,
                })
            }
        },
        async deletePPL(no_ppl_id) {
            this.fq_list = this.product_part_list.filter((detail) => { return detail.no_ppl_id != no_ppl_id })
        },
        async setproductsize_delete(i = null) {
            switch (this.material_step) {
                case 1:
                    this.get_delete_id = i;
                    this.confirm_delete_dialog = true;
                    this.loading_detail_page = false
                    break;
                case 2:
                    this.get_delete_id = i;
                    this.confirm_delete_dialog = true;
                    this.loading_detail_page = false
                    break;
                case 3:
                    this.get_delete_id = i;
                    this.confirm_delete_dialog = true;
                    this.loading_detail_page = false
                    break;
                case 4:
                    this.get_delete_id = i;
                    this.confirm_delete_dialog = true;
                    this.loading_detail_page = false
                    break;
            }

        },
        async handleFileImport() {
            this.isSelectingImage = true
            window.addEventListener('focus', () => {
                this.isSelectingImage = false
            }, { once: true })
            document.getElementById('uploader').click()
        },
        async onFileChange(payload) {
            const file = payload
            let extall = "png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseFloat(extall.indexOf(ext)) < 0) {
                this.imagePreviewURL = before_image
                this.profile_picture = null
                this.alert_message_error = 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG) เท่านั้น'
                this.alert_error = true
                setTimeout(() => this.alert_error = false, 5000)
            } else {
                if (file) {
                    this.imagePreviewURL = URL.createObjectURL(file)
                    URL.revokeObjectURL(file)
                } else {
                    this.imagePreviewURL = before_image
                }
            }
        },
        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
        async listProductType() {
            await productType
                .dataList_all()
                .then((response) => {
                    if (response.data.successful === true) {
                        this.type_data = response.data.data;
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit("logOutSuccess");
                        this.$router.push("/login");
                    }
                });
        },
        async listproductSize() {
            await productSize
                 .dataListAll()
        .then((response) => {
          (this.size_data = response.data.data),
            (this.size_data.fullname_pro =
              response.data.pdSizeList.pro_amount +
              response.data.pdSizeList.unit_name);
        })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit("logOutSuccess");
                        this.$router.push("/login");
                    }
                });
        },
        async PackageList() {
            await material.list_status_two_new().then((response) => {
                this.package_data = response.data.materialList;
            });
        },
        async Raw_material_product_List() {
            await material.list_status_three().then((response) => {
                this.raw_material_product_List = response.data.materialList;
            });
        }, async product_factory_list() {
            await qualityinspectioncriteria.list_type(2).then((response) => {
                this.product_quality_list = response.data.data;
            });
            await inspectioncriteriaproduction.list_type(0).then((response) => {
                this.factory_quality_list = response.data.data;
            });
        },
        async getProductQuality(value) {
            this.dataProductQualityList = [];
            this.product_data_4.product_quality.splice(0);
            for (let index = 0; index < value.length; index++) {
                await qualityinspectioncriteria
                    .qualityInspectionCriteriaDetail(value[index])
                    .then((response) => {
                        this.dataProductQualityList.push(response.data.detail);
                        let check = this.product_data_4.data.product_quality.find(o => o.product_quality_id === value[index]);
                        if (check != null) {
                            let check_del = this.arr_del_product_product_quality.find(o => o === check.product_product_quality_id);
                            if (check_del == null) {
                                this.product_data_4.product_quality.push({
                                    id: check.product_product_quality_id,
                                    product_quality_id: check.product_quality_id
                                });
                            } else {
                                this.product_data_4.product_quality.push({
                                    id: null,
                                    product_quality_id: this.dataProductQualityList[index].pq_title.id
                                });
                            }
                        } else {
                            this.product_data_4.product_quality.push({
                                id: null,
                                product_quality_id: this.dataProductQualityList[index].pq_title.id
                            });
                        }
                    });
            }
            for (let i = 0; i < this.product_data_4.data.product_quality.length; i++) {
                let check = this.product_data_4.product_quality.find(o => o.product_quality_id === this.product_data_4.data.product_quality[i].product_quality_id);
                if (check == null) {
                    if (this.arr_del_product_product_quality.indexOf(this.product_data_4.data.product_quality[i].product_product_quality_id) == -1) {
                        this.arr_del_product_product_quality.push(
                            this.product_data_4.data.product_quality[i].product_product_quality_id
                        );
                    }
                }
            }
        },
        async getFactoryQuality(value) {
            this.dataFactoryQualityList = [];
            this.product_data_4.factory_quality.splice(0);
            for (let index = 0; index < value.length; index++) {
                await inspectioncriteriaproduction
                    .detailInspectionCriteriaProduction(value[index])
                    .then((response) => {
                        this.dataFactoryQualityList.push(response.data.detail);
                        let check = this.product_data_4.data.factory_quality.find(o => o.factory_quality_id === value[index]);
                        if (check != null) {
                            let check_del = this.arr_del_product_factory_quality.find(o => o === check.product_factory_quality_id);
                            if (check_del == null) {
                                this.product_data_4.factory_quality.push({
                                    id: check.product_factory_quality_id,
                                    factory_quality_id: check.factory_quality_id
                                });
                            } else {
                                this.product_data_4.factory_quality.push({
                                    id: null,
                                    factory_quality_id: this.dataFactoryQualityList[index].fq_title.id
                                });
                            }
                        } else {
                            this.product_data_4.factory_quality.push({
                                id: null,
                                factory_quality_id: this.dataFactoryQualityList[index].fq_title.id
                            });
                        }
                    });
            }
            for (let i = 0; i < this.product_data_4.data.factory_quality.length; i++) {
                let check = this.product_data_4.factory_quality.find(o => o.factory_quality_id === this.product_data_4.data.factory_quality[i].factory_quality_id);
                if (check == null) {
                    if (this.arr_del_product_factory_quality.indexOf(this.product_data_4.data.factory_quality[i].product_factory_quality_id) == -1) {
                        this.arr_del_product_factory_quality.push(
                            this.product_data_4.data.factory_quality[i].product_factory_quality_id
                        );
                    }
                }
            }
        },
        addSize() {
            let data = this.size_data.find(o => o.id === this.size_id);
            let data1 = this.product_data_2.find(o => o.product_size_id === data.id);
            if (data1 == null) {
                this.product_data_2.push({
                    product_size_id: data.id,
                    product_mapsize_id: null,
                    pro_size_name: data.pro_size_name,
                    price: 0,
                    list: [],
                })
            }

        },
        async addPackage(i) {
            let data = await this.package_data.find(o => o.id === this.package_id);
            let dupplicate = await this.product_data_2[i].list.find(o => o.materials_id === this.package_id);
            if (data != null && dupplicate == null) {
                this.product_data_2[i].list.push({
                    materials_id: data.id,
                    mat_name: data.mat_name,
                    mat_cut_qty: null
                })
            }
                this.package_id = null
        },
        addPackage_2(i) {
            let data = this.raw_material_product_List.find(o => o.id === this.package_3_id);
            let dupplicate = this.product_data_3.formula[i].list.find(o => o.materials_id === this.package_3_id);
            if (data != null && dupplicate == null) {
                this.product_data_3.formula[i].list.push({
                    id: null,
                    materials_id: data.id,
                    mat_name: data.mat_name,
                    materials_qty: null
                })
            }
        },
        removePackage(i, index) {
            switch (this.material_step) {
                case 1:

                    break;
                case 2:
                    this.arr_del_product_materials_pack_id.push(this.product_data_2[i].list[index].id);
                    this.product_data_2[i].list.splice(index, 1);
                    break;
                case 3:
                    this.arr_del_product_formula_id.push(this.product_data_3.formula[index].list[i].id);
                    this.product_data_3.formula[index].list.splice(i, 1);
                    break;
                case 4:

                    break;
            }
        },
        removeMapSize(i) {
            switch (this.material_step) {
                case 1:

                    break;
                case 2:
                    this.arr_del_product_mapsize_id.push(this.product_data_2[i].product_mapsize_id);
                    this.product_data_2.splice(i, 1);
                    break;
                case 3:
                    this.arr_del_product_formula_part.push(this.product_data_3.formula[i].part_formula);
                    this.product_data_3.formula.splice(i, 1);
                    break;
                case 4:
                    this.get_delete_id = i;
                    this.confirm_delete_dialog = true;
                    this.loading_detail_page = false
                    break;
            }
            this.confirm_delete_dialog = false;
        },
        async getProductByid(step_type_id) {
            let data = {
                type: step_type_id
            }
            await product
                .getProduct(this.$route.params.id, data)
                .then((response) => {
                    if (response.data.successful === true) {

                        this.loading_page = false;
                        switch (data.type) {
                            case 1:
                                this.product_data_1 = response.data.data;
                                this.imagePreviewURL = response.data.data.pro_pic != null ? response.data.data.pro_pic : this.imagePreviewURL;
                                break;
                            case 2:
                                this.product_data_2 = response.data.data;
                                this.material_change(data.type)
                                this.listproductSize();
                                this.PackageList()
                                break;
                            case 3:
                                this.product_data_3 = response.data.data;
                                this.Raw_material_product_List();
                                this.material_change(data.type)
                                break;
                            case 4:
                                this.product_data_4.data = response.data.data;
                                this.product_quality_id.splice(0);
                                this.factory_quality_id.splice(0);
                                for (let i = 0; i < this.product_data_4.data.product_quality.length; i++) {
                                    this.product_quality_id.push(this.product_data_4.data.product_quality[i].product_quality_id);
                                }
                                for (let i = 0; i < this.product_data_4.data.factory_quality.length; i++) {
                                    this.factory_quality_id.push(this.product_data_4.data.factory_quality[i].factory_quality_id);
                                }
                                this.product_data_4.product_quality = [];
                                this.product_data_4.factory_quality = [];
                                this.product_factory_list();
                                this.getProductQuality(this.product_quality_id);
                                this.getFactoryQuality(this.factory_quality_id);
                                this.material_change(data.type);
                                break;
                        }
                    }
                })
        },
        async saveProduct(i) {
            // var step_type_id = 4;
            let success = 0;
            var data = {};
            let data_1 = new FormData();
            switch (i) {
                case 1:
                    data_1.append("id", this.product_data_1.id);
                    data_1.append("type", 1);
                    data_1.append("pro_code", this.product_data_1.pro_code);
                    data_1.append("pro_name", this.product_data_1.pro_name);
                    data_1.append("pro_pic", this.product_data_1.pro_pic);
                    data_1.append("product_type_id", this.product_data_1.product_type_id);
                    this.test = data_1;
                    await product
                        .editProduct(data_1)
                        .then((response) => {
                            this.test = response.data.successful;
                            if (response.data.successful === true) {
                                success++;
                            }
                        })
                    break;
                case 2:
                        if(this.$refs.size_list.validate()){
                            var list = [];
                    for (let i = 0; i < this.product_data_2.length; i++) {
                        list.push({
                            id: this.product_data_2[i].product_mapsize_id,
                            product_size_id: this.product_data_2[i].product_size_id,
                            price: this.product_data_2[i].price,
                            list: this.product_data_2[i].list
                        });
                    }
                    data = {
                        id: parseFloat(this.$route.params.id),
                        type: 2,
                        mapsize_list: list,
                        arr_del_product_mapsize_id: this.arr_del_product_mapsize_id,
                        arr_del_product_materials_pack_id: this.arr_del_product_materials_pack_id,
                    }
                    this.test = data;
                    await product
                        .editProduct(data)
                        .then((response) => {
                            if (response.data.successful === true) {
                                success++;
                            }
                        })
                        }
                    break;
                case 3:
                    var list3 = [];
                    for (let i = 0; i < this.product_data_3.formula.length; i++) {
                        for (let a = 0; a < this.product_data_3.formula[i].list.length; a++) {
                            list3.push({
                                id: this.product_data_3.formula[i].list[a].id,
                                materials_id: this.product_data_3.formula[i].list[a].materials_id,
                                part_formula: this.product_data_3.formula[i].part_formula,
                                materials_qty: this.product_data_3.formula[i].list[a].materials_qty
                            });
                        }
                    }
                    data = {
                        id: parseFloat(this.$route.params.id),
                        type: 3,
                        pro_formula: this.product_data_3.pro_formula,
                        formula_list: list3,
                        arr_del_product_formula_id: this.arr_del_product_formula_id,
                        arr_del_product_formula_part: this.arr_del_product_formula_part
                    };
                    this.test = data;
                    await product
                        .editProduct(data)
                        .then((response) => {
                            if (response.data.successful === true) {
                                success++;
                            }
                        })
                    break;
                case 4:
                   if(this.$refs.step_3_list.validate()){
                     var list4_product = [];
                    for (let i = 0; i < this.product_data_4.product_quality.length; i++) {
                        if (this.product_data_4.product_quality[i].id == null) {
                            list4_product.push({
                                product_quality_id: this.product_data_4.product_quality[i].product_quality_id
                            });
                        }
                    }
                    var list4_factory = [];
                    for (let i = 0; i < this.product_data_4.factory_quality.length; i++) {
                        if (this.product_data_4.factory_quality[i].id == null) {
                            list4_factory.push({
                                factory_quality_id: this.product_data_4.factory_quality[i].factory_quality_id
                            });
                        }
                    }

                    data = {
                        id: parseFloat(this.$route.params.id),
                        type: 4,
                        product_quality_list: list4_product,
                        factory_quality_list: list4_factory,
                        arr_del_product_product_quality: this.arr_del_product_product_quality,
                        arr_del_product_factory_quality: this.arr_del_product_factory_quality,
                    };
                    this.test = data;
                    await product
                        .editProduct(data)
                        .then((response) => {
                            if (response.data.successful === true) {
                                success++;
                            }
                        })
                   }
                    break;
            }
            if (success == 1) {
                let timerInterval;
                Swal.fire({
                    title: "ทำรายการเสร็จสิ้น",
                    icon: "success",
                    html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector("b");
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        this.$router.push("/stock/setting-stock/set-product-detail/" + this.$route.params.id);
                        // window.location.reload();
                    }
                });
            }
        }
    },

    computed: {
        isStep1Valid() {
            return this.code_list && this.product_name_list && this.type_list;
        },

    },

    mounted() {
        this.listProductType();
        this.getProductByid(1);
    },
    created() {

    },
}
</script>
<style scoped>
.input-group {
    display: flex;
    gap: 10px;
}

.form-select {
    width: 150px;
}

.form-control {
    flex: 1;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.padding-text-title {
    padding: 8px !important;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 20px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
    box-shadow: 0px 2px 3px 1px #0000001a;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-create-data {
    display: grid;
    grid-template-columns: 4% 4% 16% 20% 20% 20% 16%;
    color: #686868 !important;
}

.table-header-create-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-create-items {
    display: grid;
    grid-template-columns: 4% 4% 17% 20% 20% 21% 14%;
    align-items: center;
}

.table-header-import-step3-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-step3-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-step3-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
}

.v-btn[disabled] {
    background-color: #f5f5f5;
    color: #a9a9a9;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 36px;
    padding: 0 6px;
}

.table-header-setstep-4-product-box-data {
    display: grid;
    grid-template-columns: 15% 15% 10% 10% 10% 10% 10% 10% 10%;
    padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.table-header-setstep-4-product-quality-box-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-quality-box-data-text {
    color: #888888;
    font-size: 14px;
    margin-left: 20px;
    font-weight: bold;
}

.border-yellow {
    border-bottom: 1px solid #FFBC15;
}

.border_left_yellow {
    border-left: 4px solid #FFBC15;
    border-top: 1px solid #FFBC15;
    border-bottom: 1px solid #FFBC15;
    border-right: 1px solid #FFBC15;
    border-radius: 10px;
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 0 10px 15px !important;
}
</style>