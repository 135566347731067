<template>
    <div>
      <ProcessingPackagingStepAll/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepAll from "@/components/production_process/procressing_packaging/ProcressingPackagingStepAll";
  export default {
    name: "Processing_packaging_step_all",
    components: {
      ProcessingPackagingStepAll
    }
  }
  </script>
  
  <style scoped>
  
  </style>