<template>
    <div>
        <OrderListPage />
    </div>
</template>
  
<script>
import OrderListPage from '@/components/sale/OrderList'
export default {
    name: 'Orderlist',
    components: {
        OrderListPage,
    }
}
</script>