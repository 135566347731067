<template>
    <div>
        <UnitPage />
    </div>
</template>
  
<script>
import UnitPage from '@/components/stock/setting_stock/UnitComponent'
export default {
    name: 'Unit',
    components: {
        UnitPage,
    }
}
</script>