<template>
  <div>
    <ProductionProductGoodProcessRecheckEdit/>
  </div>
</template>

<script>
import ProductionProductGoodProcessRecheckEdit from "@/components/production_process/processing_product_good/ProductionProductGoodProcessRecheckEdit";
export default {
  name: "ProductionProductGoodProcessRecheckEdit_Page",
  components: {ProductionProductGoodProcessRecheckEdit}
}
</script>

<style scoped>

</style>