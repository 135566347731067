<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">รายละเอียดการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OF-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">รายละเอียดการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>รายละเอียดการแปรรูปวัตถุดิบ เลขที่ {{firstStepList.process_code}}</b>
        </v-card-title>
       <v-card-text>
          <v-card elevation="4" class="py-2 my-5" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/process (2).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 1 : ใบสั่งแปรรูป</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <v-row>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">วันที่ใบสั่งแปรรูป :</div>
                        <div class="black--text font-weight-bold">{{firstStepList.processing_date}}</div>
                      </div>
                    </v-col>
                    <!-- <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">เลขที่ใบสั่งผลิต :</div>
                        <div class="black--text font-weight-bold">PO-23981273</div>
                      </div>
                    </v-col> -->
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">อนุมัติสั่งผลิต :</div>
                        <div class="black--text font-weight-bold">{{firstStepList.user_approve_name}}</div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="tag-title-step my-3">
                  <b class="black--text">รายการวัตถุดิบแปรรูป</b>
                </div>
                <div>
                  <div class="table-header-processing mt-4" style="color: #b1adad;">
                    <div class="table-sub-header-processing">วัตถุดิบแปรรูป</div>
                    <div class="table-sub-header-processing">จำนวนกรัม</div>
                    <div class="table-sub-header-processing">g FG/Batch</div>
                    <div class="table-sub-header-processing">No. of Batch</div>
                  </div>
                  <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items ml-3">
                    <div class="black--text font-weight-bold">{{firstStepList.mat_name}}</div>
                    <div class="black--text font-weight-bold">{{firstStepList.desired_qty}}</div>
                    <div class="black--text font-weight-bold">{{firstStepList.batch_qty}}</div>
                    <div class="black--text font-weight-bold">{{firstStepList.batch_count}}</div>
                  </div>
                </div>
                <div class="tag-title-step my-3">
                  <b class="black--text">ขั้นตอนการสั่งแปรรูปและผู้รับผิดชอบ</b>
                </div>
                <div>
                  <div class="table-header-processing-second mt-4" style="color: #b1adad">
                    <div class="table-sub-header-processing">No.</div>
                    <div class="table-sub-header-processing">ขั้นตอน</div>
                    <div class="table-sub-header-processing">ผู้รับผิดชอบ</div>
                    <div class="table-sub-header-processing">วัน/เดือน/ปี</div>
                    <div class="table-sub-header-processing">รายละเอียด</div>
                  </div>
                  <div class="grid-processing-items-second ml-3">
                      <div class="black--text font-weight-bold py-1">1.</div>
                    <div class="black--text font-weight-bold">สั่งแปรรูป</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.user_order_process_name }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.order_process_date }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.order_process_detail }}</div>
                  </div>
                  <div class="grid-processing-items-second ml-3">
                      <div class="black--text font-weight-bold py-1">2.</div>
                    <div class="black--text font-weight-bold">สั่งปริ้นใบเตรียมวัตถุดิบ</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.user_print_name }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.print_date }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.print_detail }}</div>
                  </div>
                  <div class="grid-processing-items-second ml-3">
                      <div class="black--text font-weight-bold py-1">3.</div>
                    <div class="black--text font-weight-bold">เตรียมวัตถุดิบในการผลิต</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.user_prepare_mat_name }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.prepare_mat_date }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.prepare_mat_detail }}</div>
                  </div>
                  <div class="grid-processing-items-second ml-3">
                      <div class="black--text font-weight-bold py-1">4.</div>
                    <div class="black--text font-weight-bold">แปรรูปวัตุดิบ</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.user_process_name }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.process_date }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.process_detail }}</div>
                  </div>
                  <div class="grid-processing-items-second ml-3">
                      <div class="black--text font-weight-bold py-1">5.</div>
                    <div class="black--text font-weight-bold">QA Process</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.user_qa_process_name }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.qa_process_date }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.qa_process_detail }}</div>
                  </div>
                  <div class="grid-processing-items-second ml-3">
                      <div class="black--text font-weight-bold py-1">6.</div>
                    <div class="black--text font-weight-bold">QA Material</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.user_qa_material_name }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.qa_material_date }}</div>
                    <div class="black--text font-weight-bold">{{ firstStepList.qa_material_detail }}</div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/flour (4).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 2 : ใบเตรียมวัตถุดิบ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <v-row>
                    <v-col xl="3" lg="3">
                      <div class="d-flex">
                        <div class="mr-2">วันที่ใบเตรียมวัตถุดิบ :</div>
                        <div class="black--text font-weight-bold">{{materail_list.prepare_mat_date}}</div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">รายละเอียดการแปรรูป</b>
                  </div>
                  <div>
                    <div class="table-header-processing-details mt-4" style="color: #b1adad;">
                      <div class="table-sub-header-processing">No.</div>
                      <div class="table-sub-header-processing">RM Code</div>
                      <div class="table-sub-header-processing">การ weight % จากสูตร</div>
                      <div class="table-sub-header-processing">จำนวน (สูตรต่อ Batch)</div>
                    </div>
                    <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                    <div class="grid-processing-items-details ml-3" v-for="(item, i) in materail_list.materail_list" :key="item">
                      <div class="black--text font-weight-bold">{{(i+1)}}</div>
                      <div class="black--text font-weight-bold">{{item.mat_name}}</div>
                      <div class="black--text font-weight-bold">100%</div>
                      <div class="black--text font-weight-bold">{{item.receipt_qty}}</div>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div></div>
                    <div class="summarize-header my-5 pa-3">
                      <h3 class="black--text font-weight-bold text-center" style="width: 300px">รวม (FG) {{materail_list.batch_qty}} กรัม</h3>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="divider-table my-2"></div>
                    <div class="scroll-menu-last mt-4">
                      <section>
                        <div class="w-border-header-first mb-n3"/>
                        <div class="card-main-table">
                          <div class="header-card">
                            <div class="py-3 d-flex justify-space-between">
                              <div class="black--text font-weight-bold">วัตถุดิบ</div>
                              <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                            </div>
                          </div>
                          <div class="content-card">
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12" class="inline-box-specific">
                                <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                              </v-col>
                            </v-row>
                            <v-row v-for="(item, i) in materail_list.materail_list" :key="item">
                              <v-col cols="12">
                                <v-row>
                                  <div class="tag-title-step-table mt-2">
                                  <b class="black--text">ส่วนที่ {{i+1}}</b>
                                </div>
                                </v-row>
                                <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">{{item.mat_name}}</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">{{item.receipt_qty}} กรัม</div>
                              </v-col>
                            </v-row>
                             <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">น้ำกรอง</div>
                                <div class="font-weight-bold">Lot น้ำกรอง</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="font-weight-bold">5,000.00 กรัม</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">น้ำกรอง</div>
                                <div class="font-weight-bold">Lot น้ำกรอง</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="font-weight-bold">5,000.00 กรัม</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">น้ำกรอง</div>
                                <div class="font-weight-bold">Lot น้ำกรอง</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="font-weight-bold">5,000.00 กรัม</div>
                              </v-col>
                            </v-row>
                            <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">น้ำกรอง</div>
                                <div class="font-weight-bold">Lot น้ำกรอง</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="font-weight-bold">5,000.00 กรัม</div>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-card my-5">
                            <v-row class="pt-2" style="align-self: flex-end">
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">รวม (FG)</div>
                              </v-col>
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">{{materail_list.batch_qty}} กรัม</div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                      <section class="last" v-for="(detail, index) in materail_list.batch" :key="index">
                        <div class="w-border-header-sub-last mb-n3"/>
                        <div class="card-sub-table-last">
                          <div>
                            <div class="py-3">
                              <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                            </div>
                            <div class="black--text font-weight-bold text-center">{{ detail.percent }} %</div>
                          </div>
                          <div>
                            <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                           <v-row v-for="item in detail.batch_list" :key="item">
                            <v-col cols="12">
                               <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center">{{ item.mat_qty }} กรัม</div>
                              </v-col>
                            </v-row>
                            </v-col>
                           </v-row>
                          </div>
                          <div class="footer-card-last">
                            <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">รวม {{ detail.batch_qty }} กรัม</div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div class="summarize my-5 pa-5">
                      <h3 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป {{ total_result }} กรัม หรือ {{ total_result/1000 }} กิโลกรัม</h3>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/editing(2).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 3 : บันทึกผลการผลิต (วันหมดอายุของสินค้า FG:{{timeList.exp_date}})</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">การบันทึกเวลาในการแปรรูป</b>
                  </div>
                  <div class="divider-table my-2"/>
                  <div class="scroll-menu-time mt-2">
                    <section class="mb-3">
                      <div class="w-border-header-first mb-n3"/>
                      <div class="card-main-table-time" style="padding: 15px">
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">เวลาที่ใช้ในการแปรรูป</div>
                        </v-col>
                        <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">เวลาเริ่มต้นการแปรรูป</div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">เวลาสิ้นสุดการแปรรูป</div>
                        </v-col>
                      </div>
                    </section>
                    <section class="mb-3 time" v-for="(detail, index) in timeList.batch" :key="index">
                      <div class="w-border-header-sub-last mb-n3"/>
                      <div class="card-sub-table-time" style="padding: 15px">
                        <div class="pt-3 pb-5">
                          <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                        </div>
                        <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">{{ detail.start_date }}</div>
                        </v-col>
                        <v-col xl="12" lg="12">
                          <div class="black--text font-weight-bold text-center ma-2 mt-0">{{ detail.end_date }}</div>
                        </v-col>
                      </div>
                    </section>
                  </div>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">การบันทึกผลการแปรรูป</b>
                  </div>
                  <div class="divider-table my-2"/>
                  <div class="ml-5">
                    <b class="black--text">ส่วนที่ 1</b>
                  </div>
                  <div class="scroll-menu-time mt-2">
                          <section>
                        <div class="w-border-header-first mb-n3"/>
                        <div class="card-main-table">
                          <div class="header-card">
                            <div class="py-3 d-flex justify-space-between">
                              <div class="black--text font-weight-bold">วัตถุดิบ</div>
                              <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                            </div>
                          </div>
                          <div class="content-card">
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12" class="inline-box-specific">
                                <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                              </v-col>
                            </v-row>
                            <v-row v-for="(item, i) in materail_list.materail_list" :key="item">
                              <v-col cols="12">
                                <v-row>
                                  <div class="tag-title-step-table mt-2">
                                  <b class="black--text">ส่วนที่ {{i+1}}</b>
                                </div>
                                </v-row>
                                <v-row class="pt-2">
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">{{item.mat_name}}</div>
                              </v-col>
                              <v-col xl="6" lg="6" style="margin-top: 2px">
                                <div class="black--text font-weight-bold">{{item.receipt_qty}} กรัม</div>
                              </v-col>
                            </v-row>
                             <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="footer-card my-5">
                            <v-row class="pt-2" style="align-self: flex-end">
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">รวม (FG)</div>
                              </v-col>
                              <v-col xl="6" lg="6">
                                <div class="black--text font-weight-bold">{{materail_list.batch_qty}} กรัม</div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                    <section class="last pe" style="margin-right: 2%;" v-for="(detail, index) in timeList.batch" :key="index">
                        <div class="w-border-header-sub-last mb-n3"/>
                        <div class="card-sub-table-last">
                          <div>
                            <div class="py-3">
                              <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                            </div>
                            <div class="black--text font-weight-bold text-center">{{ detail.percent }} %</div>
                          </div>
                          <div>
                            <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                           <v-row v-for="item in detail.batch_list" :key="item">
                            <v-col cols="12">
                              <v-row v-for="sub in item.stock_list" :key="sub">
                               <v-col cols="12">
                               <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center">{{ sub.quantity_cut }} กรัม</div>
                                <div class="font-weight-bold text-center">{{sub.material_lot}}</div>
                              </v-col>
                            </v-row>
                              <v-divider class="ml-2 mr-2 mt-2" dark color="#d9d9d9"></v-divider>
                            </v-col>
                            </v-row>
                            </v-col>
                           </v-row>
                          </div>
                          <div class="footer-card-last">
                            <v-row class="pt-2">
                              <v-col xl="12" lg="12">
                                <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">รวม {{ detail.qty_after_process }} กรัม</div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </section>
                  </div>
                </div>
                <div class="summarize my-5 pa-5">
                  <h3 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป {{ total_result_next }} กรัม หรือ {{ total_result_next/1000 }} กิโลกรัม</h3>
                </div>
                <div>
                  <div class="tag-title-step my-3">
                    <b class="black--text">วัตถุดิบที่เสียหายหรือทิ้งไปในรอบนี้</b>
                  </div>
                  <div class="divider-table my-2"/>
                  <div>
                    <div class="table-header-processing-details mt-4" style="color: #b1adad">
                      <div class="table-sub-header-processing">No.</div>
                      <div class="table-sub-header-processing">ชื่อวัตถุดิบ</div>
                      <div class="table-sub-header-processing">เลข Lot</div>
                      <div class="table-sub-header-processing">จำนวนกรัม</div>
                    </div>
                    <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                    <div v-for="(item, i) in timeList.material_drop_list" :key="item">
                    <div class="grid-processing-items-details ml-3">
                      <div class="black--text font-weight-bold py-1">{{i+1}}</div>
                      <div class="black--text font-weight-bold">{{item.mat_name}}</div>
                      <div class="black--text font-weight-bold">{{item.material_lot}}</div>
                      <div class="black--text font-weight-bold">{{item.drop_qty}} กรัม</div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/shield(2).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 4 : การตรวจสอบกระบวนการแปรรูป</span>
            </v-card-title>
              <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing mt-6" style="color: #b1adad">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์</div>
                  <div class="table-sub-header-processing">เกณฑ์คุณภาพ</div>
                  <div class="table-sub-header-processing">Value</div>
                </div>
                <v-card elevation="4" class="my-5 py-2" style="border-radius:20px" v-for="(item, i) in dataList" :key="item">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">{{item.fq_name}}</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items ma-5 pl-3 pr-3" v-for="(detail, index) in item.quality_list" :key="index">
                    <div class="black--text font-weight-bold">{{ detail.fq_list_name }}</div>
                    <div class="black--text font-weight-bold">{{ detail.fq_list_guide }}</div>    
                    <div>
                      <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == null" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#88B358">mdi-checkbox-blank-circle-outline</v-icon></p>
                    <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 0 && detail.fq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                    <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 0 && detail.fq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(i, index, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                    <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                    <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(i, index, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                    <v-text-field v-if="detail.fq_list_type == 1" class="input-radius" color="#FFBC15" label="value" v-model="detail.value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/quality (1).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 5 : การตรวจสอบวัตถุดิบที่แปรรูปสำเร็จ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing-step5 mt-6" style="color: #b1adad">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (English)</div>
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (ภาษาไทย)</div>
                  <div class="table-sub-header-processing">Unit</div>
                  <div class="table-sub-header-processing ml-3" style="color:#ffb98a">Aim</div>
                  <div class="table-sub-header-processing ml-n1" style="color:#13b65d">Green</div>
                  <div class="table-sub-header-processing ml-n2" style="color:#ddbb55">Yellow</div>
                  <div class="table-sub-header-processing ml-n3" style="color:#c00000">Red</div>
                  <div class="table-sub-header-processing ml-n3">Value</div>
                </div>
                <v-card elevation="4" class="my-10" style="border-radius:20px" v-for="(item, i) in qualityList" :key="item">
                  <div class="table-header-processing-step5 py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">{{item.pq_name_eng}}</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">{{item.pq_name_thai}}</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items-step5 mt-0 mb-n4 pl-3 pr-3" v-for="(detail, index) in item.quality_list" :key="index">
                    <div class="black--text font-weight-bold py-5">{{ detail.pq_list_eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.pq_list_thai }}</div>
                    <div>{{ detail.unit }}</div>
                    <div class="ml-3">{{ detail.pq_list_aim }}</div>
                    <div>{{ detail.pq_list_green }}</div>
                    <div>{{ detail.pq_list_yellow }}</div>
                    <div>{{ detail.pq_list_red }}</div>
                    <div>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == null" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#88B358">mdi-checkbox-blank-circle-outline</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 0 && detail.pq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 0 && detail.pq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(i, index, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(i, index, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                    <v-text-field v-if="detail.pq_list_type == 1" class="input-radius" color="#FFBC15" label="value" v-model="detail.value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="4" class="py-2 my-10" style="border-radius:20px">
            <div class="header-png">
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/approved(2).png">
              </v-avatar>
            </div>
            <v-card-title>
              <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 6 : ยืนยันการแปรรูปสำเร็จ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <v-row>
                  <v-col xl="4" lg="4">
                    <div class="d-flex">
                      <div class="ml-2">วันที่ดำเนินการ :</div>
                      <div class="black--text">{{step_6.success_date}}</div>
                    </div>
                  </v-col>
                  <v-col xl="4" lg="4">
                    <div class="d-flex">
                      <div class="ml-2">ผู้ดำเนินการ :</div>
                      <div class="black--text">{{step_6.user_edit}}</div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import process from '@/api/process/meterial_process.js'
// import Swal from "sweetalert2";
export default {
  name: "ProductionConfirmDetails",
  data() {
    return {
      total_result:0,
      total_result_next:0,
      firstStepList: [
        {
          step: 'สั่งแปรรูป',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'สั่งปริ้นใบเตรียมวัตถุดิบ',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'เตรียมวัตถุดิบในการผลิต',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'แปรรูปวัตุดิบ',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'QA Process',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
        {
          step: 'QA Material',
          author: 'พงศ์กมล',
          date: '25/01/23',
          detail: '-',
        },
      ],
      batchList: [
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
      ],
      timeList: [
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
        {
          sTime: 12.34,
          eTime: 23.30,
        },
      ],
      dataList: [
        {
          eng: 'Steaming temp',
          th: 'อุณหภูมิในการนึ่ง',
          unit: 'C',
          aim: '100',
          green: '90-110',
          yellow: '<90>110',
          red: '-',
          value: 324,
        },
        {
          eng: 'Steaming time',
          th: 'เวลาในการนึ่ง',
          unit: 'min',
          aim: '50',
          green: '45-55',
          yellow: '<45>55',
          red: '-',
          value: 50,
        },
      ],
      secondList: [
        {
          eng: 'Appearance',
          th: 'ข้าวเป็นเม็ดสวยไม่แฉะ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
        {
          eng: 'Texture',
          th: 'ไม่เละไม่เป็นไต',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: false,
        },
        {
          eng: 'Flavor',
          th: 'ไม่มีกลิ่นเหม็นอับ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
      ],
      qualityList: [
        {
          name: 'Rice: Water',
          quality: 'Jasmin 32:24/Berry 11:54',
          value: 23,
        },
        {
          name: 'จำนวนถาดที่หุ้ง',
          quality: '-',
          value: 23,
        },
        {
          name: 'Temperature',
          quality: '100C',
          value: 23,
        },
        {
          name: 'Time Start',
          quality: '-',
          value: 23,
        },
        {
          name: 'Time End',
          quality: '-',
          value: 23,
        },
        {
          name: 'Time',
          quality: '50 Min - 60 Min',
          value: 23,
        },
      ],
      solarList: [
        {
          name: 'Temp',
          detail: '45.0 - 70.0 C',
        },
        {
          name: 'DateStart',
          detail: '-',
        },
        {
          name: 'TimeStart',
          detail: '-',
        },
        {
          name: 'DateEnd',
          detail: '-',
        },
        {
          name: 'TimeEnd',
          detail: '-',
        },
        {
          name: 'Time',
          detail: '>6.0 hr',
        },
        {
          name: 'Moisture : Dried Rice',
          detail: '9.0 - 11.0',
        },
      ],
      step_6:{},
    }
  },
  methods: {
    linkTo() {
      this.$router.push('/prm/processing_materials')
    },
    //step 1
    getDetail_step1(){
      let id = parseFloat(this.$route.params.id);
            this.loading_page = true;
            process.getDetail_step1(id).then((response) => {
                if(response.data.successful === true){
                  this.firstStepList = response.data.step_1.process_data;
                  this.materail_list = response.data.step_2;
                  this.timeList = response.data.step_3;
                  this.dataList = response.data.step_4.quality_list;
                  this.qualityList = response.data.step_5.quality_list;
                  this.step_6 = response.data.step_6;
                   this.loading_page = false;
                }
            })
    },
  },
  mounted(){
    this.getDetail_step1();
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.header-png {
  position: absolute;
  top: -15px;
  left: 20px;
}
.icon-image {
  width: 120px;
  height: 120px;
  text-align: center;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.tag-title-step {
  background-color: #fff8e8;
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.tag-title-step-table {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.table-header-processing-step5 {
  display: grid;
  grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
}
.table-header-processing-second {
  display: grid;
  grid-template-columns: 5% 20% 25% 25% 25%;
}
.table-header-processing-details {
  display: grid;
  grid-template-columns: 10% 25% 25% 40%;
}
.table-header-processing-steps {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
}
.table-header-processing-recheck {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: baseline;
}
.grid-processing-items-step5 {
  display: grid;
  grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
  align-items: baseline;
}
.grid-processing-items-second {
  display: grid;
  grid-template-columns: 5% 20% 25% 25% 25%;
  align-items: baseline;
}
.grid-processing-items-details {
  display: grid;
  grid-template-columns: 10% 25% 25% 40%;
  align-items: baseline;
}
.grid-processing-items-steps {
  display: grid;
  grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
  align-items: baseline;
}
.grid-processing-items-recheck {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  align-items: baseline;
}
.summarize-header {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
  inline-size: fit-content;
}
.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}
.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}
/* Part 1 */
.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: auto;
}
.inline-box-specific {
  background: #fff8e8;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
}
.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}
.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding: 15px;
  width: auto;
  height: 99%;
}
.card-main-table .header-card {}
.card-main-table .content-card {}
.card-main-table .footer-card {
  position: inherit;
  bottom: 0;
  width: 85%;
}
.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 100%;
  width: 100%;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-last .last {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 3%;
}
.scroll-menu-last section:first-child {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: sticky;
  left: 0;
  margin-right: 3%;
  background: #FFFFFF;
  z-index: 1;
}
/* Part 1 */
/* Part 2 */
.card-main-table-time {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding-bottom: 15px;
  width: 100%;
  height: 99%;
}
.card-sub-table-time {
  border: 1px solid #34230F;
  border-radius: 15px;
  max-width: 100%;
  width: 200px;
  height: 99%;
  transition: 0.5s;
}
.card-sub-table-time:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0,0,0,0.75);
}
.scroll-menu-time {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.scroll-menu-time .time {
  display: inline-block;
  vertical-align: top;
  margin-right: 3%;
}
.scroll-menu-time section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  bottom: auto;
  margin-right: 3%;
  background: #FFFFFF;
  z-index: 1;
}
.inline-box-specific-time {
  background: #d6d3cf;
}
.footer-total {
  position: absolute;
  bottom: 1%;
  border-top: 2px solid #ffbc15;
  background-color: #fff2d0;
  padding: 11px;
}
.footer-gams {
  background-color: #000000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
/* Part 2 */
</style>