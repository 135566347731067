<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ยืนยันการส่งสินค้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>


        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายละเอียดข้อมูลการจัดส่งสินค้า</b>
                    </v-card-title>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center ">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-file-document
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">ข้อมูลคำสั่งซื้อ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 f-14">วันที่สั่งซื้อ :
                                            <span class="f-14 f-black" v-if="data.order_date != null"> {{
                                                `${data.order_date.split('/')[0]}-${data.order_date.split('/')[1]}-${data.order_date.split('/')[2]}`
                                            }}</span>
                                            <span class="f-14 f-black" v-else>-</span>
                                        </div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 f-14">กำหนดส่งสินค้า :
                                            <span class="f-14 f-black" v-if="data.order_deadline != null"> {{
                                                `${data.order_deadline.split('/')[0]}-${data.order_deadline.split('/')[1]}-${data.order_deadline.split('/')[2]}`
                                            }}</span>
                                            <span class="f-14 f-black" v-else>-</span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 f-14">ชื่อลูกค้า :
                                            <span class="f-14 f-black"> {{ data.customer_name }}</span>
                                        </div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 f-14">เบอร์โทร :
                                            <span class="f-14 f-black">{{ data.customer_tel }}</span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                        <div class="ml-3 f-14">ที่อยู่ :
                                            <span class="f-14 f-black"> {{ data.customer_address }}
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex justify-space-between align-items-center">
                                <div>
                                    <v-avatar class="top-to-card" color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-package-variant-closed
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">ข้อมูลสินค้า</span>
                                </div>
                                <div>
                                    <h3>ราคารวม <span style="color:#88B358;">{{
                                        data.order_total == null ? 0 : formatPrice(data.order_total) }}</span> บาท</h3>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <template>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                รหัสผลิตภัณฑ์
                                                            </th>
                                                            <th class="text-left">
                                                                ชื่อสินค้า
                                                            </th>
                                                            <th class="text-left">
                                                                Lot สินค้า
                                                            </th>
                                                            <th class="text-left">
                                                                จำนวน
                                                            </th>
                                                            <th class="text-left">
                                                                หน่วยนับ
                                                            </th>
                                                            <th class="text-left">
                                                                ราคาต่อหน่วย
                                                            </th>
                                                            <th class="text-left">
                                                                ราคารวม
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in order_detail" :key="index">
                                                            <td>{{ item.product_code }}</td>
                                                            <td>{{ item.product_name }}</td>
                                                            <td>
                                                        <tr v-for="detail in item.stock_list" :key="detail">
                                                            <td>{{ detail.product_lot }}</td>
                                                        </tr>
                                                        </td>
                                                        <td>{{ formatPrice(item.qty) }}</td>
                                                        <td>{{ item.unit_name }}</td>
                                                        <td>{{ formatPrice(item.unit_price) }}</td>
                                                        <td>{{ formatPrice(item.total_price) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12 pl-5">
                                        <div>
                                            หมายเหตุคำสั่งซื้อ :
                                        </div>
                                        <div style="color:#000000DE;">
                                            {{ data.order_note }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center">

                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-package-variant
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">การบรรจุสินค้าลงกล่องขนส่ง</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <template>
                                            <v-simple-table v-show="isVisible">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                กล่องขนส่ง
                                                            </th>
                                                            <th class="text-left">
                                                                จำนวนที่ใช้
                                                            </th>
                                                            <th v-if="box_list_edit.filter(item => item.lot).length > 0" class="text-left">
                                                                Lot กล่อง
                                                            </th>
                                                            <th v-else class="text-left">
                                                                Lot กล่อง
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item_box, index_box_list) in box_list_edit"
                                                            :key="index_box_list">
                                                            <td>{{ item_box.box_name }}</td>
                                                            <td>
                                                               {{item_box.qty}} กล่อง
                                                            </td>
                                                            <td v-if="item_box.lot">
                                                                <span class="d-block" v-for="item in item_box.lot" :key="item">
                                                                     {{item.lot}}
                                                                </span>
                                                            </td>
                                                             <td v-else>
                                                                <span class="d-block" v-for="detail in data.box_list_stock.filter(o => o.material_id === item_box.box_id && o.order_box_id === item_box.id && o.qty_cut != '' && parseFloat(o.qty_cut == '' ? 0 : o.qty_cut) > 0)" :key="detail">
                                                                    {{detail.material_lot}}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div v-if="data.order_status < 4"
                                                                    @click="getBox_Lot(item_box.box_id), dialo_lot = true"
                                                                    outlined style="color:#FEA06C;">
                                                                    <v-icon color="#FEA06C">mdi-pencil</v-icon>
                                                                    เลือกล็อตกล่องขนส่ง
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-simple-table v-show="isVisible2">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                กล่องขนส่ง
                                                            </th>
                                                            <th class="text-left">
                                                                จำนวนที่ใช้
                                                            </th>
                                                            <th class="text-left">
                                                                หน่วยนับ
                                                            </th>
                                                            <th class="text-left">
                                                                Lot กล่อง
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in box_list" :key="index">
                                                            <td>{{ item.box_name }}</td>
                                                            <td>{{ item.qty }}</td>
                                                            <td>กล่อง</td>
                                                            <td>
                                                                <span class="d-block" v-for="detail in data.box_list_stock.filter(o => o.material_id === item.box_id && o.order_box_id === item.id && o.qty_cut != '' && parseFloat(o.qty_cut == '' ? 0 : o.qty_cut) > 0)" :key="detail">
                                                                    {{detail.material_lot}}
                                                                </span>
                                                            </td>
                                                            <!-- <td>
                                                            <div v-if="data.order_status < 4" @click="getBox_Lot(item.box_id),dialo_lot = true" outlined
                                                                    style="color:#FEA06C;">
                                                                    <v-icon color="#FEA06C">mdi-pencil</v-icon> แก้ไขล็อต
                                                                </div>
                                                        </td> -->
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>
                                    </v-col>
                                    <v-col cols="12">
                                        <div v-show="isVisible2">
                                            <div class="mt-3 text-right">
                                                <v-btn color="#FEA06C" elevation="4" rounded large outlined
                                                    @click="change_edit();">
                                                    <v-icon left>mdi-pencil</v-icon> แก้ไขกล่องขนส่ง
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <div v-show="isVisible" class="text-center">
                        <v-btn class="white--text" color="#88B358" elevation="4" rounded large dark
                            @click="box_list_edit_save()">
                            <v-icon left>mdi-content-save</v-icon> บันทึก
                        </v-btn>
                    </div>
                    <div v-show="isVisible2" class="mt-3 text-center">
                        <v-btn class="white--text" color="#88B358" elevation="4" rounded large dark
                            @click="confirm_shipping = true">
                            <v-icon left>mdi-content-save</v-icon> ยืนยันการจัดส่งเสร็จสิ้น
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="confirm_shipping" transition="dialog-top-transition" max-width="383" style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_shipping = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/confirm_shipping.png">
                                <h2 style="color:#000000">ยืนยันการจัดส่งเสร็จสิ้น</h2>
                                <h3 class="mt-2">คุณต้องการยืนยันการจัดส่งเสร็จสิ้นใช่หรือไม่</h3>
                                <v-btn class="btn-reset-pasword" elevation="4" rounded x-large
                                    @click="confirm_shipping_save()">
                                    <v-icon style="font-size:28px;" left>mdi-content-save</v-icon>
                                    ตกลง
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:16px;" text @click="confirm_shipping = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-row justify="center">
            <v-dialog v-model="dialo_lot" persistent max-width="700px">
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col>
                                <span cols="6">แก้ไขล็อต</span>
                            </v-col>
                            <v-col cols="6 text-right">
                                <v-icon @click="dialo_lot = false">
                                    mdi-window-close
                                </v-icon>
                            </v-col>
                        </v-row>

                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_detail_edit_log" @submit.prevent="edit_lot_save()">
                                <v-row>

                                    <v-col cols="8">
                                        {{ product_lot.box_name }}
                                    </v-col>
                                    <v-col cols="4" class="text-right" v-if="data.order_status < 4 && product_lot.id">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="refresh_data(product_lot.id)" elevation="2" color="error"
                                                    v-bind="attrs" v-on="on" fab small>
                                                    <v-icon>mdi-refresh</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>จัดล็อตสินค้าใหม่</span>
                                        </v-tooltip>

                                    </v-col>
                                    <v-col cols="12">
                                        <v-card elevation="4" style="border-radius:20px;" class="">
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <!-- <th class="text-left">
                                                                วันที่นำเข้า/ผลิต
                                                            </th> -->
                                                            <th class="text-left">
                                                                LOT
                                                            </th>
                                                            <th class="text-left">
                                                                คงเหลือ
                                                            </th>
                                                            <th class="text-left">
                                                                จำนวนที่ต้องการเลือก
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in product_lot.stock_list" :key="index">
                                                            <!-- <td>{{
                                                                `${item.import_date.split('/')[0]}-${item.import_date.split('/')[1]}-${item.import_date.split('/')[2]}`
                                                            }}</td> -->
                                                            <td>{{ item.material_lot }}</td>
                                                            <td>{{ formatPrice(parseFloat(item.material_amount)) }}</td>
                                                            <td width="200" style="height: 65px;">
                                                                <v-form-group>
                                                                    <v-text-field type="number" class="input-radius"
                                                                        label="จำนวน" placeholder="จำนวน" color="#ffbc15"
                                                                        v-model="item.qty_cut" @input="check_lot(item.material_amount, item.qty_cut, item.material_lot_id)"
                                                                        hide-details="auto" dense outlined rounded required>
                                                                    </v-text-field>
                                                                </v-form-group>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12 text-center" v-if="product_lot.stock_list.length > 0">
                                        <v-btn type="submit" class="mt-2 mr-2  radius-15 text-16 text-white" style="color: white" width="150"
                                            elevation="2" large color="#88B358">
                                            <v-icon>mdi-content-save</v-icon> บันทึก
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { onlyForNumber, formatPrice, isTelephone } from "@/helper/handler";
import { dateFormatNoSeconds } from "@/helper/dateformats";
import order from '@/api/sale/order.js'
import Swal from "sweetalert2";
export default {
    data() {
        return {
            loading_page: false,
            sub_menu: [
                {
                    text: 'การขาย',
                    disabled: false,
                },
                {
                    text: 'จัดการคำสั่งซื้อ',
                    disabled: false,
                    href: '/sale/order-list',
                },
                {
                    text: 'OR202301004',
                    disabled: false,
                   
                },
                {
                    text: 'ยืนยันการส่งสินค้า',
                    disabled: false,
                },
            ],


            isVisible: true,
            isVisible2: false,
            confirm_shipping: false,
            dialo_lot: false,
            page: 1,
            data: [],
            product_list: [],
            order_detail: [],
            stock_list: [],
            box_list: [],
            box_list_edit: [],
            check_production: [],
            ProcessProductList: [],
            mock_lot: [],
            product_lot: {
                stock_list: [],
            },
        }
    },

    methods: {
        async refresh_data(id) {
            this.product_lot = {
                stock_list: [],
            }
            order.boxRefresh(id).then((response) => {
                if (response.data.successful === true) {
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้จัดล็อตสินค้าใหม่เรียบร้อยแล้ว",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: async () => {
                            await this.orderDetail()
                            this.product_lot = await this.data.box_list.find(item => item.box_id === id)
                            clearInterval(timerInterval);
                        },
                    }).then(async (result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            await this.orderDetail()
                            this.product_lot = await this.data.box_list.find(item => item.id === id)
                        }
                    });
                }
            })
        },
        getBox_Lot(id){
            let sum = 0
            this.data.order_detail.forEach(item => {
               sum += parseFloat(item.qty)
            })
            this.product_lot = this.box_list_edit.find(item => item.box_id === id)
             this.product_lot = {
                ...this.product_lot,
                stock_list: this.product_lot.stock_list.map(item => { return {...item, qty_cut: parseFloat(item.qty_cut) > 0 ? item.qty_cut : 0}}),
                total_qty: sum
             }
        },
       async check_lot(max, min, id){
        let data = await this.product_lot.stock_list.find(o => o.material_lot_id === id)
            if(parseFloat(min) < 0){
                data.qty_cut = 0
            }
              if(parseFloat(max) < parseFloat(min)){
                data.qty_cut = 0
                   Swal.fire({
                        title: "คำเตือน",
                        icon: "warning",
                        html: "จำนวนสินค้าต้องไม่เกิน "+max,
                        confirmButtonText: 'ตกลง'
                    })
                }
        },
        edit_lot_save(){
            this.loading_page = true
           
                let data  = this.box_list_edit.find(item => item.box_id === this.product_lot.box_id)
            if(data != null){
                let sum = 0
                let lot = []
                  data.lot = []
                this.product_lot.stock_list.forEach(item => {
                    item.qty_cut = item.qty_cut == '' ? 0 : item.qty_cut
                    sum += parseFloat(item.qty_cut == '' ? 0 : item.qty_cut)
                    if(parseFloat(item.qty_cut == '' ? 0 : item.qty_cut) > 0){
                        lot.push({lot:item.material_lot})
                    }
                })
                data.lot = lot
                data.qty = sum
                data.stock_list = this.product_lot.stock_list;
                this.dialo_lot = false;
                
            }
            
             this.loading_page = false
        },
        link(link) {
            this.$router.push(link)
        },
        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        dateFormatNoSeconds(dateTime) {
            const date = dateFormatNoSeconds(dateTime).split(" ");
            return date[0];
        },
        async orderDetail() {
            let id = parseInt(this.$route.params.id);
            let type = 1;
            await order.orderDetail(id, type).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.data
                    this.order_detail = response.data.data.order_detail
                    this.box_list = response.data.data.box_list
                    this.box_list_edit = response.data.data.box_list
                    const filteredArray = this.order_detail.filter(obj => obj.qty_need_to_production_more > 0)
                    this.check_production = filteredArray
                    if (this.data.status_save_box == 1) {
                        this.isVisible = false
                        this.isVisible2 = true
                    }
                }
                if (response.data.successful === false) {
                    setTimeout(() => this.alert_error = false, 5000) //need adjust
                }
            })
        },
        change_edit() {
            this.isVisible = true
            this.isVisible2 = false
        },
        change_show() {
            this.isVisible = false
            this.isVisible2 = true
        },
        box_list_edit_save() {
            this.loading_page = true
            let listData = [];
            for (let j = 0; j < this.box_list_edit.length; j++) {
                listData.push({
                    id: this.box_list_edit[j].id,
                    order_id: parseInt(this.$route.params.id),
                    box_id: parseInt(this.box_list_edit[j].box_id),
                    qty: parseInt(this.box_list_edit[j].qty),
                    stock_list: this.box_list_edit[j].stock_list
                })
            }

            const data = {
                order_id: parseInt(this.$route.params.id),
                box_list: listData,
            }
            order.orderUpdateBox(data).then((response) => {
                if (response.data.successful === true) {
                    this.loading_page = false
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้แก้ไขข้อมูลเรียบร้อยแล้ว.",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            this.change_show()
                            this.orderDetail()
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            this.change_show()
                            this.orderDetail()
                        }
                    });
                }
            })
        },
        confirm_shipping_save() {
            order.orderUpdateShipping(this.$route.params.id).then((response) => {
                if (response.data.successful === true) {
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้จัดส่งเรียบร้อยแล้ว.",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");

                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            window.location.href = '/sale/order-list';
                        }
                    });
                } else {
                    Swal.fire({
                        title: "แจ้งเตือน",
                        icon: "warning",
                        html: `${response.data.message}`,
                    });
                }
            })
        }
    },
    mounted() {
        this.orderDetail()
    },
    watch: {
        page() {
            this.orderDetail()
        }
    },
}
</script>
<style scoped>
.input-group {
    display: flex;
    gap: 10px;
}

.form-select {
    width: 150px;
}

.form-control {
    flex: 1;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.padding-text-title {
    padding: 8px !important;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 20px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-import-box-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
}

.table-header-import-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    color: #686868 !important;
}

.table-header-import-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
}

.table-header-import-step3-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-step3-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-step3-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
}</style>