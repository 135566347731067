<template>
    <div>
        <SupplierPage />
    </div>
</template>
  
<script>
import SupplierPage from '@/components/stock/setting_stock/SupplierComponent'
export default {
    name: 'Supplier',
    components: {
        SupplierPage,
    }
}
</script>