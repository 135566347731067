<template>
    <div>
        <InspectionCriteriaProductionAddPage />
    </div>
</template>
  
<script>
import InspectionCriteriaProductionAddPage from '@/components/stock/setting_stock/InspectionCriteriaProductionAddComponent'
export default {
    name: 'InspectionCriteriaProductionAdd',
    components: {
        InspectionCriteriaProductionAddPage,
    }
}
</script>