<template>
    <div>
      <ProcessingPackagingStepDamaged/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepDamaged from "@/components/production_process/procressing_packaging/ProcressingPackagingStepDamaged";
  export default {
    name: "Processing_packaging_step_damaged",
    components: {
      ProcessingPackagingStepDamaged
    }
  }
  </script>
  
  <style scoped>
  
  </style>