<template>
    <div>
        <QualityInspectionCriteriaDetailPage />
    </div>
</template>
  
<script>
import QualityInspectionCriteriaDetailPage from '@/components/stock/setting_stock/QualityInspectionCriteriaDetailComponent'
export default {
    name: 'QualityInspectionCriteriaDetail',
    components: {
        QualityInspectionCriteriaDetailPage,
    }
}
</script>