<template>
    <div>
        <WarehouseRawmaterialProductOut_page />
    </div>
</template>
  
<script>
import WarehouseRawmaterialProductOut_page from '@/components/stock/warehouse_stock/WarehouseRawmaterialProductOut'
export default {
    name: 'WarehouseRawmaterialProductOut',
    components: {
        WarehouseRawmaterialProductOut_page,
    }
}
</script>
