<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">รายการใบสั่งซื้อ</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card class="card-radius" elevation="4">
          <v-card-title>
            <p class="team-text-title-card">รายการใบสั่งซื้อ</p>
          </v-card-title>
          <v-card-text>
            <v-btn :class="type == 0 ? 'tab-button active ma-2' : 'tab-button-disable ma-2'
              " type="button" :color="type == 0 ? '#FFBC15' : '#909090'" :elevation="type == 0 ? 2 : 0" large outlined
              @click="type = 0">กำลังดำเนินการ
            </v-btn>
            <v-btn :class="type == 1 ? 'tab-button active ma-2' : 'tab-button-disable ma-2'
              " :elevation="type == 1 ? 2 : 0" type="button" large outlined :color="type == 1 ? '#FFBC15' : '#909090'"
              @click="type = 1">สำเร็จ</v-btn>
            <v-btn :class="type == 2 ? 'tab-button active ma-2' : 'tab-button-disable ma-2'
              " :elevation="type == 2 ? 2 : 0" type="button" large outlined :color="type == 2 ? '#FFBC15' : '#909090'"
              @click="type = 2">ยกเลิก</v-btn>
            <v-row>
              <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" prepend-inner-icon="mdi-magnify"
                  placeholder="ค้นหาที่นี่..." hide-details="auto" single-line outlined dense v-model="search"
                  @keyup="searchData()"></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                <div class="d-flex justify-end">
                  <v-btn class="mt-2" large rounded outlined color="#88B358" @click="link('/stock/order-list/add')" v-if="$store.state.profileDetail.per_po == 1 || $store.state.profileDetail.permission == 1">
                    <v-icon left>mdi-plus</v-icon>
                    สร้างใบสั่งซื้อ
                  </v-btn>
                  <v-btn class="mt-2" large rounded  color="#dcdcdc" v-else>
                    <v-icon left>mdi-plus</v-icon>
                    สร้างใบสั่งซื้อ
                  </v-btn>
                </div>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="table-header-data display-pc" style="color: #b1adad">
                  <div class="table-header-data-text"></div>
                  <div class="table-header-data-text">เลขที่ใบสั่งซื้อ</div>
                  <div class="table-header-data-text">
                    วันที่สร้างใบสั่งซื้อ
                  </div>
                  <div class="table-header-data-text">ราคารวม</div>
                  <div class="table-header-data-text">พิมพ์</div>
                  <div class="table-header-data-text text-center">จัดการ</div>
                </div>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in data" :key="index">
                  <div class="grid-items">
                    <div class="w-border"></div>
                    <div>{{ detail.po_code }}</div>
                    <div>{{
                      `${dateFormatNoSeconds(detail.created_at).split('/')[0]}-${dateFormatNoSeconds(detail.created_at).split('/')[1]}-${dateFormatNoSeconds(detail.created_at).split('/')[2]}`
                    }}</div>
                    <div>{{ formatPrice(detail.total_price) }} บาท</div>
                    <div>
                      <v-menu rounded="b-xl" offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn class="mr-2 radius-15" color="#52595D" v-bind="attrs" v-on="on" large outlined>
                            <v-icon left>mdi-printer</v-icon>
                            <div>พิมพ์</div>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item link @click="getPDF(base_url + 'po/pdf/' + detail.id + '?supplier_id=0')">
                            <v-list-item-title style="color: #52595d">
                              ทั้งหมด
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item link v-for="item in detail.supplier_list" :key="item"
                            @click="getPDF(base_url + 'po/pdf/' + detail.id + '?supplier_id=' + item.supplier_id)">
                            <v-list-item-title style="color: #52595d">
                              {{ item.sup_name }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div>
                      <v-menu rounded="b-xl" offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item link v-if="$store.state.profileDetail.per_po == 1 || $store.state.profileDetail.permission == 1">
                            <v-list-item-title style="color: #6c7fd8" @click="
                              link('/stock/order-list/detail/' + detail.id)
                              ">
                              <v-icon left style="color: #6c7fd8">mdi-information-variant</v-icon>
                              รายละเอียด
                            </v-list-item-title>
                          </v-list-item>
                          <span v-if="$store.state.profileDetail.per_po == 1 || $store.state.profileDetail.permission == 1">
                            <v-list-item link :href="'/stock/order-list/edit/' + detail.id" v-if="type === 0 " >
                              <v-list-item-title style="color: #fea06c">
                                <v-icon left style="color: #fea06c">mdi-pencil</v-icon>
                                แก้ไขข้อมูล
                              </v-list-item-title>
                            </v-list-item>
                          </span>
                          <v-list-item link v-if="type === 0">
                            <v-list-item-title style="color: #f28080" @click="confirm_order_delete(detail.id)">
                              <v-icon left style="color: #f28080">mdi-delete-forever</v-icon>
                              ยกเลิก
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </v-card>
                <div class="text-center mt-5">
                  <v-pagination v-model="page" :length="pages" color="#FFBC15"></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- ยกเลิกใบสั่งซื้อ -->
    <v-dialog v-model="open_cancel_order" transition="dialog-top-transition" max-width="667" style="border-radius: 20px">
      <v-card>
        <div class="d-flex justify-space-between">
          <v-card-title>
            <b>ยกเลิกใบสั่งซื้อ</b>
          </v-card-title>
          <v-btn type="button" icon class="ma-4" @click="open_cancel_order = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form ref="form_cancel" @submit.prevent="materialCancel">
          <v-card-text>
            <v-card elevation="4" style="border-radius: 20px">
              <v-card-title class="d-flex align-items-center">
                <v-avatar color="#34230F" size="32">
                  <v-icon dark> mdi-alert-circle </v-icon>
                </v-avatar>
                <span class="ml-3 f-18">เหตุผลการยกเลิกใบสั่งซื้อ</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <v-row style="padding: 15px 70px 0 70px !important">
                  <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <v-form-group>
                      <v-textarea class="input-radius" label="เหตุผลการยกเลิกใบสั่งซื้อ" hide-details="auto"
                        color="#686868" dense outlined rounded rows="8" row-height="25" :rules="[
                          (v) => !!v || 'กรุณากรอกเหตุผลการยกเลิกใบสั่งซื้อ',
                        ]" v-model="note">
                      </v-textarea>
                    </v-form-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <div class="mt-3 div-submit-data">
              <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                <v-icon left>mdi-content-save</v-icon> บันทึก
              </v-btn>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- ยกเลิกใบสั่งซื้อ -->
  </div>
</template>
<script>
import { dateFormatNoSeconds } from "@/helper/dateformats";
import { isTelephone, formatPrice } from "@/helper/handler";
import order from "@/api/order/order.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading_page: true,
      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "ใบสั่งซื้อ",
          disabled: false,
          href: "#",
        },
      ],
      type: 0,
      data: [],
      search: "",
      page: 1,
      size: 8,
      pages: 1,
      open_cancel_order: false,
      cancel_id: null,
      note: "",
      base_url: process.env.VUE_APP_BASE_URL,
    };
  },

  methods: {
    dateFormatNoSeconds(dateTime) {
      const date = dateFormatNoSeconds(dateTime).split(" ");
      return date[0];
    },
    link(link) {
      this.$router.push(link);
    },
    getPDF(link, link2 = null) {
            window.open(link, '_blank');
            if(link2 != null){
                window.open(link2, '_blank');
            }
            
        },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    async materialList() {
      this.loading_page = true;
      this.data = [];
      const response = await order.materialList(
        this.page,
        this.size,
        this.search,
        this.type
      );
      if (response.data.successful == true) {
        this.data = response.data.poList;
        this.pages = response.data.pages;
        this.loading_page = false;
      }
    },
    async searchData() {
      this.data = [];
      this.page = 1;
      const response = await order.materialList(
        this.page,
        this.size,
        this.search,
        this.type
      );
      if (response.data.successful == true) {
        this.data = response.data.poList;
        this.pages = response.data.pages;
      }
    },
    async confirm_order_delete(id) {
      this.cancel_id = id;
      this.open_cancel_order = true;
    },
    async materialCancel() {
      if (this.$refs.form_cancel.validate()) {
        this.loading_page = true;
        const data = {
          note: this.note,
        };
        const response = await order.materialCancel(this.cancel_id, data);
        if (response.data.successful == true) {
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ยกเลิกข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.open_cancel_order = false;
              this.cancel_id = null;
              this.note = "";
              this.page = 1;
              this.materialList();
            }
          });
        }
      }
    },
  },
  mounted() {
    this.materialList();
  },
  watch: {
    type() {
      this.materialList();
    },
    page() {
      this.materialList();
    },
  },
};
</script>
<style scoped>
.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 22px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}
</style>