<template>
  <div>
    <ProductionProcessRecheck/>
  </div>
</template>

<script>
import ProductionProcessRecheck
  from "@/components/production_process/processing_raw_materials/ProductionProcessRecheck";
export default {
  name: "Production_process_recheck",
  components: {ProductionProcessRecheck}
}
</script>

<style scoped>

</style>