<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">
            สร้างรายการนำเข้าวัตถุดิบจากใบสั่งซื้อ
          </h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>

    <v-stepper v-model="stepImport" style="padding: 30px">
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <p class="team-text-title-card">
            สร้างรายการนำเข้าวัตถุดิบจากใบสั่งซื้อ
          </p>
          <div class="d-flex justify-space-between">
            <div class="text-center">
              <v-btn color="#FFBC15" fab x-small outlined dark style="font-size: 14px;cursor:default;" :complete="stepImport > 1"
                step="1">
                1
              </v-btn>
              <p class="my-2" style="font-size: 16px; font-weight: 600; color: #ffbc15">
                ข้อมูลใบสั่งซื้อ
              </p>
            </div>
            <div class="text-center">
              <v-btn :color="stepImport == 1 ? '#7D7D7D' : '#ffbc15'" fab x-small outlined dark style="font-size: 14px;cursor:default;"
                :complete="stepImport > 2" step="2">
                2
              </v-btn>
              <p class="my-2" :style="stepImport == 1
                ? 'font-size:16px;font-weight:600;color:#D9D9D9;'
                : 'font-size:16px;font-weight:600;color:#FFBC15;'
                ">
                ตรวจสอบคุณภาพวัตถุดิบ
              </p>
            </div>
          </div>
          <v-progress-linear style="background-color: #d9d9d9" :color="stepImport == 1 ? '#D9D9D9' : '#ffbc15'" rounded
            height="9" :value="stepImport == 1 ? 0 : 80">
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-stepper-items>
        <v-row>
          <v-stepper-content step="1">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="36">
                    <v-icon dark> mdi-file-document </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">เลือกใบสั่งซื้อที่ต้องการนำเข้าวัตถุดิบ</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                      <v-form-group>
                        <v-autocomplete color="#686868" :items="importpoList" item-text="po_code" item-value="id"
                          v-model="po_id" :rules="[(v) => !!v || 'กรุณาเลือกใบสั่งซื้อ']" @change="selectImportpo"
                          label="ใบสั่งซื้อ" hide-details="auto" dense outlined rounded required></v-autocomplete>
                      </v-form-group>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-card elevation="4" style="border-radius: 20px" v-if="getImportpoDetail.length > 0">
                <v-card-title class="d-flex justify-space-between align-items-center">
                  <div>
                    <v-avatar color="#34230F" size="32">
                      <v-icon dark> mdi-package-variant-closed </v-icon>
                    </v-avatar>
                    <span class="ml-3 text-font-title">รายการวัตถุดิบ</span>
                  </div>
                  <div>
                    <h3 style="color: #88b358">รวมเงินทั้งสิ้น {{ formatPrice(total_all) }}</h3>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-tabs background-color="transparent" color="basil" grow>
                    <v-tab v-for="(sourceDetail, indexSource, i) in header" :key="indexSource" @click="check_tab = i">
                      {{ indexSource }}
                    </v-tab>
                  </v-tabs>
                </v-card-text>
                <v-card color="basil" flat>
                  <v-card-text>
                    <div class="table-header-import-data display-pc mt-3" style="color: #b1adad">
                      <div class="table-header-import-data-text text-center">
                        #
                      </div>
                      <div class="table-header-import-data-text">
                        วัตถุดิบ
                      </div>
                      <div class="table-header-import-data-text">
                        วันที่หมดอายุ
                      </div>
                      <div class="table-header-import-data-text">จำนวน</div>
                      <div class="table-header-import-data-text">
                        ราคาต่อหน่วย
                      </div>
                      <div class="table-header-import-data-text text-center">
                        ราคารวม
                      </div>
                    </div>
                  </v-card-text>
                   <v-form ref="step1" @input="checkValidate()">
                  <div v-for="(item, index) in getImportpoDetail" :key="index"
                    :class="check_tab != index ? 'd-none' : ''">
                    <div v-for="(header_sub, x) in item.header" :key="header_sub">
                      <v-card-title class="padding-text-title align-items-center" style="
                          background: rgba(255, 188, 21, 0.1);
                          border: 1px solid #ffbc15;
                          border-radius: 20px;
                          margin: 15px;
                        ">
                        <span class="ml-3 text-font-title" style="font-size: 14px">{{ header_sub }}</span>
                      </v-card-title>
                      <v-card-text>
                        <div>
                          <v-card elevation="0" class="card-table-radius" v-for="(detail, i) in item.list[x]"
                            :key="detail">
                            <div class="grid-import-items">
                              <div class="text-center">{{ i + 1 }}</div>
                              <div>{{ detail.mat_name }}</div>
                              <div style="padding: 10px 0px 10px 0px;">
                                <v-menu  v-model="detail.menu2" :nudge-right="40" :close-on-content-click="false"
                                  transition="scale-transition" offset-y min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="detail.date" label="วันที่หมดอายุ"
                                      prepend-inner-icon="mdi-calendar" hide-details="auto" :rules="[
                                        (v) => !!v || 'กรุณาเลือกวันที่หมดอายุ',
                                      ]" v-bind="attrs" dense outlined rounded required v-on="on" readonly></v-text-field>
                                  </template>
                                  <v-date-picker v-model="detail.date" @input="getLoading(detail)"
                                    no-title scrollable required></v-date-picker>
                                </v-menu>
                              </div>
                              <div style="padding: 0px 0px 0px 7px;">
                                <div class="d-flex">
                                  <v-autocomplete style="border-radius: 20px 0 0 20px" :items="[
                                  {
                                    id:detail.unit_main_id,
                                    name: detail.unit_main_name
                                  },
                                  {
                                    id:detail.unit_other_id,
                                    name: detail.unit_other_name
                                  }
                                  ]"
                                    color="#FFBC15" label="จำนวน" :rules="[(v) => !!v || 'กรุณาเลือกจำนวน']"
                                    hide-details="auto" item-text="name" item-value="id" v-model="detail.unit_id"
                                    @change="calculate(detail.qty, i, x, index)" dense outlined required>
                                  </v-autocomplete>

                                  <v-text-field class="input-radius" color="#FFBC15" style="border-radius: 0 20px 20px 0"
                                    :rules="[(v) => !!v || 'กรุณากรอกจำนวน']" v-model="detail.qty"
                                    @input="formatInput(i, x, index), calculate_input(i, x, index)" @keypress="isOnlyNumberStock($event, detail.qty)"
                                    hide-details="auto" dense outlined required>
                                  </v-text-field>
                                </div>
                              </div>
                              <div style="padding-left: 7px;">
                                <v-form-group>
                                  <v-text-field class="input-radius" color="#FFBC15" label="ราคาต่อหน่วย"
                                    placeholder="ราคาต่อหน่วย" v-model="detail.price" minlength="1" :rules="[
                                      (v) => !!v || 'กรุณากรอกราคาต่อหน่วย',
                                    ]" @keypress="isOnlyNumber($event, detail.price)" hide-details="auto"
                                    @input="formatInput(i, x, index), calculate_input(i, x, index)" suffix="บาท" dense outlined rounded
                                    required></v-text-field>
                                </v-form-group>
                              </div>
                              <div class="text-center">{{ formatPrice(detail.total_price) }}</div>
                            </div>
                          </v-card>
                        </div>
                      </v-card-text>
                    </div>
                    <div  style="padding: 20px;display:flex;justify-content:end;">
                      <div class="d-flex justify-space-between" style="
                              padding: 20px;
                              background: rgba(136, 179, 88, 0.15);
                              border: 1px solid #88b358;
                              border-radius: 15px;
                            " v-if="check_tab == index">
                        <h2 style="color: #000; margin-right: 100px">
                          รวมเงิน
                        </h2>
                        <h2 style="color: #88b358">{{ formatPrice(total[check_tab]) }}</h2>
                      </div>
                    </div>
                  </div>
                   </v-form>
                </v-card>
              </v-card>
              <div class="d-flex justify-center">
                <div class="mt-3 div-submit-data">
                  <v-btn v-if="step1_status == false" outlined type="button" class="button-submit-data"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large @click="alert_warning()"
                    >
                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                  </v-btn>
                  <v-btn v-if="step1_status == true" outlined type="button" class="button-submit-data" @click="stepImport = 2, getProductQuery()"
                    style="color: rgb(136, 179, 88) !important" elevation="4" rounded large>
                    <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <v-form ref="input" @submit.prevent="saveImport()">
                <v-card elevation="4" style="border-radius: 20px">
                <v-card-title class="d-flex align-items-center">
                  <v-avatar color="#34230F" size="32">
                    <v-icon dark> mdi-shield-check </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title">เกณฑ์การตรวจสอบคุณภาพ</span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-3" color="#686868"></v-divider>
                  <v-row>
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                      <div class="table-header-import-step3-data display-pc mt-3" style="color: #b1adad">
                        <div class="table-header-import-step3-data-text">
                          ชื่อวัตถุดิบ
                        </div>
                        <div class="table-header-import-step3-data-text">
                          รายละเอียดการตรวจสอบ
                        </div>
                        <div class="table-header-import-step3-data-text">
                          Value
                        </div>
                        <div class="table-header-import-step3-data-text">
                          หมายเหตุ/รายละเอียดการแก้ไข
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-card elevation="0" class="card-table-radius py-8" style="border: 1px solid #d9d9d9"
                    v-for="(item, index) in product_query" :key="item">
                    <div class="grid-import-step3-items">
                      <div style="padding: 10px">
                        <p style="color: #000; font-weight: 600">
                          {{ item.mat_name }}
                        </p>
                        <p style="color: #000; font-weight: 600">
                          Lot : {{ item.material_lot }}
                        </p>
                        <p style="color: #000; font-weight: 600">{{ item.sup_name }}</p>
                      </div>

                      <v-row class="">
                        <v-col cols="12">

                          <v-row v-for="detail in item.pq_list" :key="detail">
                            <v-col cols="6 py-0">
                              <div style="margin-top: 15px">
                                <p class="mb-0" style="color: #000; font-weight: 600">{{ detail.pq_list_thai }}</p>
                              </div>
                            </v-col>
                            <v-col cols="6 py-0">
                              <div style="margin-top: 15px">
                                <p class="mb-0"><v-icon @click="detail.pq_value_boolean = 0, detail.pq_value = 0"
                                    color="#88B358"
                                    v-if="detail.pq_list_type == 0 && detail.pq_value_boolean == 1">mdi-check-circle</v-icon>
                                </p>
                                <p class="mb-0"><v-icon @click="detail.pq_value_boolean = 1, detail.pq_value = 1"
                                    color="#F28080"
                                    v-if="detail.pq_list_type == 0 && detail.pq_value_boolean == 0">mdi-close-circle</v-icon>
                                </p>
                                <v-text-field class="input-radius" color="#FFBC15" label=" Value" placeholder="Value"
                                  v-if="detail.pq_list_type == 1" v-model="detail.pq_value" hide-details="auto" dense :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                                  outlined rounded></v-text-field>
                              </div>
                            </v-col>
                          </v-row>

                        </v-col>
                      </v-row>


                      <div style="padding: 10px">
                        <v-textarea class="input-radius" label="หมายเหตุ/รายละเอียดการแก้ไข" color="#FFBC15" outlined
                          rounded rows="3" row-height="25" v-model="note[index]"></v-textarea>
                      </div>
                    </div>
                  </v-card>
                </v-card-text>
              </v-card>
              <div class="mt-3 div-submit-data">
                <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4"
                  rounded large>
                  <v-icon left>mdi-content-save</v-icon> นำเข้าคลัง
                </v-btn>
              </div>
              </v-form>
            </v-col>
          </v-stepper-content>
        </v-row>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { isTelephone, formatPrice, onlyForCurrency ,onlyForCurrencyStock } from "@/helper/handler";
import importorder from "@/api/importrawmaterials/importorder.js";
import importPO from "@/api/import_po/importpo.js";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading_page: true,
      stepImport: 1,
      sub_menu: [
        {
          text: "คลัง",
        },
        {
          text: "นำเข้าวัตถุดิบ",
          href: '/stock/material-import',
        },
        {
          text: "นำเข้าจากใบสั่งซื้อ",
        },
        {
          text: "สร้างรายการนำเข้าวัตถุดิบจากใบสั่งซื้อ",
          disabled: true,
          href: "#",
        },
      ],
      unit: [],
      tab: null,
      importpoList: [],
      getImportpoDetail: [],
      check_tab: 0,
      header: {},
      supplier: ["สยามแม็คโคร", "นิทานการข้าว"],
      menu: false,
      modal: false,
      menu2: false,
      toggle_exclusive: 2,
      page: 1,
      add_dialog: false,
      edit_dialog: false,
      detail_dialog: false,
      unit_qty: [],
      date: [],
      total: [],
      product_query: [],
      import_list: null,
      po_id: null,
      note: [],
      test_data: [
        {
          list: 'สี',
          list_value: 1,
        },
        {
          list: 'กลิ่น',
          list_value: 1,
        },
      ],
      //ตัวแปร test
      test: {},
      dt: null,
      hours: null,
      minutes: null,
      material_lot: null,
      total_all: 0,
      step1_status: false,
    };
  },

  methods: {
    async getLoading(detail){
      this.loading_page = true
      detail.menu2 = await false
     this.loading_page = false
    },
    alert_warning(){
       Swal.fire({
            title: "คำเตือน",
            icon: "warning",
            html: "กรอกข้อมูลให้ครบทุกซัพพลายเออร์",
            confirmButtonText: 'ตกลง',
          })
    },
     checkValidate(){
            if(this.$refs.step1.validate()){
                if(this.getImportpoDetail.length != 0){
                    this.step1_status = true;
                }else{
                    this.step1_status = false;
                }
                
            }else{  
                this.step1_status = false;
            }
            

        },
    isOnlyNumber: function (evt, number) {
      return onlyForCurrency(evt, number);
    },
    isOnlyNumberStock: function (evt, number) {
      return onlyForCurrencyStock(evt, number);
    },
    
     formatInput(i = null, x = null, index = null) {
          this.loading_page = true;
          if( this.getImportpoDetail[index].list[x][i].qty.length > 3){
              this.getImportpoDetail[index].list[x][i].qty =  this.getImportpoDetail[index].list[x][i].qty.replace(/,/g, '');
               this.getImportpoDetail[index].list[x][i].qty =  this.getImportpoDetail[index].list[x][i].qty.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
          if( this.getImportpoDetail[index].list[x][i].price.length > 3){
              this.getImportpoDetail[index].list[x][i].price =  this.getImportpoDetail[index].list[x][i].price.replace(/,/g, '');
               this.getImportpoDetail[index].list[x][i].price =  this.getImportpoDetail[index].list[x][i].price.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
      this.loading_page = false;
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    link(link) {
      this.$router.push(link);
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    async importPOList() {
      this.loading_page = true;
      this.importpoList = [];
      const response = await importorder.importpoList();
      if (response.data.successful == true) {
        this.importpoList = response.data.list;
        this.loading_page = false;
      }
    },
    async selectImportpo(id) {
      this.getImportpoDetail.splice(0)
      const response = await importorder.detailImportpo(id);
      if (response.data.successful == true) {
        let data = response.data.detail;
        this.import_list = id;
        this.header = response.data.detail;
        const new_data = [];
        for (const company in data) {
          const header = data[company].map(item => (
            item.typeName
          ));
          const items = data[company].map(item => {
            return item.list.map(list => {
              return {
                ...list,
                menu2: false,
                date: null,
              }
            });
          });
          const new_item = {
            header: header,
            list: items
          };
          this.getImportpoDetail.push(new_item);
          for (let i = 0; i < this.getImportpoDetail.length; i++) {
            this.unit[i] = [];
            this.unit_qty[i] = [];
            this.total[i] = 0;
            for (let a = 0; a < this.getImportpoDetail[i].list.length; a++) {
              for (let x = 0; x < this.getImportpoDetail[i].list[a].length; x++) {
                this.getImportpoDetail[i].list[a][x] = {
                  ...this.getImportpoDetail[i].list[a][x],
                  qty_main: null,
                  retail_price: this.getImportpoDetail[i].list[a][x].price,
                  qty_retail: this.getImportpoDetail[i].list[a][x].qty,
                  qty_other: null
                }
                this.unit_qty[i][x] = parseFloat(this.getImportpoDetail[i].list[a][x].unit_id) == parseFloat(this.getImportpoDetail[i].list[a][x].unit_main_id) ? parseFloat(this.getImportpoDetail[i].list[a][x].unit_main_id) : parseFloat(this.getImportpoDetail[i].list[a][x].unit_other_id);
                this.unit[i][x] = this.getImportpoDetail[i].list[a][x].unit_other_name == null ? 
                  [
                  {
                    id: 1,
                    index_group: i,
                    name: this.getImportpoDetail[i].list[a][x].unit_main_name,
                    unit: this.getImportpoDetail[i].list[a][x].unit_other_qty
                  },
                ]
                : [
                  {
                    id: 1,
                    index_group: i,
                    name: this.getImportpoDetail[i].list[a][x].unit_main_name,
                    unit: this.getImportpoDetail[i].list[a][x].unit_other_qty
                  },
                  {
                    id: 2,
                    index_group: i,
                    name: this.getImportpoDetail[i].list[a][x].unit_other_name,
                    unit: this.getImportpoDetail[i].list[a][x].unit_other_qty
                  },
                ];
                this.total[i] += parseFloat(this.getImportpoDetail[i].list[a][x].total_price);
              }
            }
          }
        }

        const result = { detail: new_data };
        console.log(result)
        this.total_all = 0;
        for (let i = 0; i < this.total.length; i++) {
            this.total_all += parseFloat(this.total[i]);
        }
        this.loading_page = false;
      }
    },
    getProductQuery() {
      for (let i = 0; i < this.getImportpoDetail.length; i++) {
        for (let a = 0; a < this.getImportpoDetail[i].list.length; a++) {
          for (let x = 0; x < this.getImportpoDetail[i].list[a].length; x++) {
            this.product_query.push({
              ...this.getImportpoDetail[i].list[a][x],
              material_lot: this.getImportpoDetail[i].list[a][x].material_id + '-' + this.material_lot
            });
          }
        }
      }
    },
    calculate(qty = null, i = null, x = null, index = null) {
      let data = this.unit[index][i].find(o => o.id === this.unit_qty[index][i]);
      console.log(qty)
      if (data != null) {
        switch (data.id) {
          case 1:
            // this.getImportpoDetail[index].list[x][i].qty = 0;
            // this.getImportpoDetail[index].list[x][i].qty = data.unit * parseFloat((qty+'').replace(/,/g, ''));
            this.getImportpoDetail[index].list[x][i].price = this.getImportpoDetail[index].list[x][i].retail_price
            this.getImportpoDetail[index].list[x][i].qty = this.getImportpoDetail[index].list[x][i].qty_main != null && this.getImportpoDetail[index].list[x][i].qty_main != '' ? this.getImportpoDetail[index].list[x][i].qty_main : this.getImportpoDetail[index].list[x][i].unit_id == this.getImportpoDetail[index].list[x][i].unit_main_id ? this.getImportpoDetail[index].list[x][i].qty_retail : ''
            this.getImportpoDetail[index].list[x][i].total_price = (this.getImportpoDetail[index].list[x][i].qty+'').replace(/,/g, '') * this.getImportpoDetail[index].list[x][i].price;
            break;
          case 2:
            // this.getImportpoDetail[index].list[x][i].qty = 0;
            // this.getImportpoDetail[index].list[x][i].qty = parseFloat((qty+'').replace(/,/g, '')) / data.unit;
            this.getImportpoDetail[index].list[x][i].price = this.getImportpoDetail[index].list[x][i].other_price
            this.getImportpoDetail[index].list[x][i].qty = this.getImportpoDetail[index].list[x][i].qty_other != null && this.getImportpoDetail[index].list[x][i].qty_other != '' ? this.getImportpoDetail[index].list[x][i].qty_other : this.getImportpoDetail[index].list[x][i].unit_id == this.getImportpoDetail[index].list[x][i].unit_other_id ? this.getImportpoDetail[index].list[x][i].qty_retail : ''
            this.getImportpoDetail[index].list[x][i].total_price = (this.getImportpoDetail[index].list[x][i].qty+'').replace(/,/g, '') * this.getImportpoDetail[index].list[x][i].price;
            break;
        }
        this.total[index] = 0;
        for (let j = 0; j < this.getImportpoDetail[index].list.length; j++) {
            for (let b = 0; b < this.getImportpoDetail[i].list[j].length; b++) {
          this.total[index] += parseFloat(this.getImportpoDetail[index].list[j][b].total_price);
          }
        }
        this.total_all = 0;
        for (let i = 0; i < this.getImportpoDetail.length; i++) {
          for (let j = 0; j < this.getImportpoDetail[i].list.length; j++) {
            for (let b = 0; b < this.getImportpoDetail[i].list[j].length; b++) {
              this.total_all += parseFloat(this.getImportpoDetail[i].list[j][b].total_price);
            }
          }
        }

      }
    },
    calculate_input(i = null, x = null, index = null) {
      let data = this.unit[index][i].find(o => o.id === this.unit_qty[index][i]);
      if (data != null) {
        switch (data.id) {
          case 1:
            // this.getImportpoDetail[index].list[x][i].qty = this.getImportpoDetail[index].list[x][i].qty == '' ? 0 : this.getImportpoDetail[index].list[x][i].qty
            this.getImportpoDetail[index].list[x][i].qty_main = this.getImportpoDetail[index].list[x][i].qty == '' ? 0 : this.getImportpoDetail[index].list[x][i].qty
            this.getImportpoDetail[index].list[x][i].total_price = parseFloat((this.getImportpoDetail[index].list[x][i].qty == '' ? 0 : this.getImportpoDetail[index].list[x][i].qty+'').replace(/,/g, '')) * parseFloat((this.getImportpoDetail[index].list[x][i].price+'').replace(/,/g, ''));
            break;
          case 2:
            // this.getImportpoDetail[index].list[x][i].qty = this.getImportpoDetail[index].list[x][i].qty == '' ? 0 : this.getImportpoDetail[index].list[x][i].qty
            this.getImportpoDetail[index].list[x][i].qty_other = this.getImportpoDetail[index].list[x][i].qty == '' ? 0 : this.getImportpoDetail[index].list[x][i].qty
            this.getImportpoDetail[index].list[x][i].total_price = parseFloat((this.getImportpoDetail[index].list[x][i].qty == '' ? 0 : this.getImportpoDetail[index].list[x][i].qty+'').replace(/,/g, '')) * parseFloat((this.getImportpoDetail[index].list[x][i].price+'').replace(/,/g, ''));
            break;
        }
        this.total[index] = 0;
        for (let j = 0; j < this.getImportpoDetail[index].list.length; j++) {
            for (let b = 0; b < this.getImportpoDetail[index].list[j].length; b++) {
          this.total[index] += parseFloat(this.getImportpoDetail[index].list[j][b].total_price);
          }
        }
        this.total_all = 0;
        for (let i = 0; i < this.getImportpoDetail.length; i++) {
          for (let j = 0; j < this.getImportpoDetail[index].list.length; j++) {
            for (let b = 0; b < this.getImportpoDetail[index].list[j].length; b++) {
              this.total_all += parseFloat(this.getImportpoDetail[index].list[j][b].total_price);
            }
          }
        }
      }
    },
    saveImport() {
     if(this.$refs.input.validate()){
       let foundObject = [];
      for (let i = 0; i < this.getImportpoDetail.length; i++) {
        for (let x = 0; x < this.getImportpoDetail[i].list.length; x++) {
          for (let j = 0; j < this.getImportpoDetail[i].list[x].length; j++) {
            let object = this.getImportpoDetail[i].list[x][j]; // Assuming there is only one object in the list array
            if (object.po_id === this.import_list) {
            foundObject.push(object);
            }
          }
        }
      }
      let list_array = [];
      let pq_list = [];
      let keys = Object.keys(this.header)
      for (let index = 0; index < keys.length; index++) {
        for (let i = 0; i < foundObject.length; i++) {
          pq_list = []
          for (let x = 0; x < this.product_query[i].pq_list.length; x++) {

            pq_list.push({
              pq_list_id: this.product_query[i].pq_list[x].id,
              pq_list_type: this.product_query[i].pq_list[x].pq_list_type,
              value: this.product_query[i].pq_list[x].pq_value+'' == '' ? '1' : this.product_query[i].pq_list[x].pq_value+'',
            })
          }
          list_array[i] = {
            material_id: foundObject[i].material_id,
            material_lot: foundObject[i].material_id + '-' + this.material_lot,
            sup_id: foundObject[i].sup_id,


            qty: foundObject[i].unit_id == foundObject[i].unit_other_id ? (parseFloat((foundObject[i].qty+'').replace(/,/g, '')) *  parseFloat((foundObject[i].unit_other_qty+'').replace(/,/g, '')))+'' : (foundObject[i].qty+'').replace(/,/g, ''),
            unit_id: foundObject[i].unit_id == foundObject[i].unit_other_id ? foundObject[i].unit_other_id : foundObject[i].unit_main_id,
            unit_peritem: foundObject[i].unit_id == foundObject[i].unit_main_id ? 1 : foundObject[i].unit_other_qty,
            unit_gram: foundObject[i].unit_main_gram,
            price: (foundObject[i].price+'').replace(/,/g, ''),
            material_amount: parseFloat(foundObject[i].unit_main_gram) == 0 || parseFloat(foundObject[i].unit_main_gram) == null ? parseFloat((foundObject[i].qty+'').replace(/,/g, '')) : foundObject[i].unit_id == foundObject[i].unit_main_id ? parseFloat(foundObject[i].unit_main_gram) * parseFloat((foundObject[i].qty+'').replace(/,/g, '')) : (parseFloat((foundObject[i].qty+'').replace(/,/g, '')) * parseFloat((foundObject[i].unit_other_qty+'').replace(/,/g, ''))) * parseFloat(foundObject[i].unit_main_gram),
            cost_pergram: parseFloat(foundObject[i].unit_main_gram) == 0 || parseFloat(foundObject[i].unit_main_gram) == null ? foundObject[i].price : parseFloat(foundObject[i].price) / parseFloat(foundObject[i].unit_id == foundObject[i].unit_main_id ? 1 : foundObject[i].unit_other_qty) / parseFloat(foundObject[i].unit_main_gram),
            total_price: foundObject[i].total_price,
            lot_exp: foundObject[i].date,
            note: this.note[i],
            list: pq_list,
          }
          console.log(list_array)
        }
      }
      let sum = 0;
      for (let i = 0; i < this.total.length; i++) {
        sum += parseFloat(this.total[i]);
      }
      let check = this.importpoList.find(item => item.id === this.import_list)
      let data = {
        po_id: this.import_list,
        po_code: check.po_code,
        total_price: sum,
        list: list_array
      }
      this.test = data;
      importPO.add(data).then((response) => {
        if (response.data.successful === true) {
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/stock/material-import");
            }
          });
        }
      })
     }
    }
  },
  mounted() {
    this.dt = new Date();
    this.hours = this.dt.getHours().toString().padStart(2, '0');
    this.minutes = this.dt.getMinutes().toString().padStart(2, '0');
    this.material_lot = `${this.dt.getFullYear()}${String(this.dt.getMonth() + 1).padStart(2, '0')}${String(this.dt.getDate()).padStart(2, '0')}${this.hours}${this.minutes}`;
    this.importPOList();
    // this.selectImportpo(1);
  },
};
</script>
<style scoped>
.input-group {
  display: flex;
  gap: 10px;
}

.form-select {
  width: 150px;
}

.form-control {
  flex: 1;
}

.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.padding-text-title {
  padding: 8px !important;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 20px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-import-data {
  display: grid;
  grid-template-columns: 2% 20% 20% 20% 20% 18%;
  padding: 5px 0px 5px 30px;
  color: #686868 !important;
}

.table-header-import-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-items {
  display: grid;
  grid-template-columns: 5% 20% 19% 19% 20% 17%;
  align-items: center;
}

.table-header-import-step3-data {
  display: grid;
  grid-template-columns: 22% 27% 25% 29%;
  padding: 5px 0px 5px 0px;
  color: #686868 !important;
}

.table-header-import-step3-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-step3-items {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  align-items: center;
}

.grid-import-step3-items-sub {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
</style>