<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายละเอียดสินค้า </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายละเอียดสินค้า</b>
                    </v-card-title>

                    <!-- ข้อมูลสินค้า -->
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex justify-space-between align-items-center pb-1">
                                <div>
                                    <v-avatar class="top-to-card" color="#34230F" size="32">
                                        <v-icon dark>
                                            mdi-package-variant-closed
                                        </v-icon>
                                    </v-avatar>
                                    <span class="ml-3 text-font-title">ข้อมูลสินค้า</span>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="mt-2 ml-5" style="color:#000;font-size:18px;">
                                            รูปภาพสินค้า
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="image_preview text-center">
                                                    <img v-if="imagePreviewURL" :src="imagePreviewURL"
                                                        style="width:218px;max-width:218px;">
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="9" xl="9" class="title_page">
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <div class="ml-3 text-name-title">รหัสผลิตภัณฑ์ :
                                                    <span class="text-fullname-title">{{step_1_data.pro_code}}</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <div class="ml-3 text-name-title">ชื่อสินค้า :
                                                    <span class="text-fullname-title">{{step_1_data.pro_name}}</span>
                                                </div>
                                            </v-col> 
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">ประเภทสินค้า :
                                                    <span class="text-fullname-title">{{type_product.pro_type_name}}</span>
                                                </div>
                                            </v-col> 
                                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                                <div class="ml-3 text-name-title">ปริมาณที่ได้จากสูตรการผลิต :
                                                    <span class="text-fullname-title">{{formatPrice(step_1_data.pro_formula)}}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <!-- ข้อมูลสินค้า -->

                    <v-card-text >
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">จำนวนวัตถุดิบคงเหลือในสต๊อก</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row class="px-5">
                                    <v-col cols="12">
                                        <div class="f-16">จำนวนวัตถุดิบในสต๊อก (FG) : {{formatPrice(step_1_data.pro_instock)}} กรัม</div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text >
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">จำนวนสินค้าคงเหลือในสต๊อก</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row class="px-5" v-for="item in step_1_data.mapsize_list" :key="item">
                                    <v-col cols="6" lg="4" xl="4" md="4">
                                        <div class="f-16">{{item.pro_size_name}} : {{formatPrice(item.instock)}} {{item.unit_name}}</div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-currency-usd
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลขนาดสินค้าและบรรจุภัณฑ์</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>

                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12" v-for="item in step_1_data.mapsize_list" :key="item">
                                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                                            <v-card-title>
                                                <v-btn class="bold border-yellow-all" color="#34230F" rounded outlined dark>
                                                    {{formatPrice(item.pro_amount)}} กรัม
                                                </v-btn>
                                                <span class="ml-3 f-14">ราคาขาย : {{formatPrice(item.price)}} บาท</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <div
                                                    style="border:1px solid #FFBC15;background:#FFBC15;border-radius: 5px;height:5px;width:100%">
                                                </div>
                                                <div class="mt-3"
                                                    style="padding:10px;border: 1px solid #FFBC15;border-radius: 20px;">
                                                    <v-row class="mt-1">
                                                        <v-col cols="12 pt-1">
                                                            <p class="f-16 ma-0 bold">การผูกสูตรบรรจุภัณฑ์</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="7" md="7" lg="7" xl="7">
                                                            <p class="f-16 mb-2 bold" style="color:#686868;">วัตถุดิบ </p>
                                                            <div v-for="list in item.list" :key="list">
                                                                <p class="f-16 bold" style="color:#000;">{{list.mat_name}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                                                            <p class="f-16 mb-2 bold" style="color:#686868;">จำนวนที่ตัด</p>
                                                            <div v-for="list in item.list" :key="list">
                                                                <p class="f-16 bold" style="color:#000;">{{formatPrice(list.mat_cut_qty)}} {{ list.unit_name }} </p>
                                                            </div>
                                                        </v-col>

                                                        
                                                    </v-row>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-currency-usd
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลการผลิต</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col cols="12 pb-0">
                                        <div>ปริมาณที่ได้จากสูตรการผลิต : {{formatPrice(step_1_data.pro_formula)}} กรัม</div>
                                    </v-col>

                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12" v-for="(item, i) in step_1_data.formula_list" :key="item">
                                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                                            <v-card-title>
                                                <v-btn class="bold border-yellow-all" color="#34230F" rounded outlined dark>
                                                    ส่วนที่ {{i+1}}
                                                </v-btn>
                                                <span class="ml-3 f-14">ส่วนการผลิต</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <div
                                                    style="border:1px solid #FFBC15;background:#FFBC15;border-radius: 5px;height:5px;width:100%">
                                                </div>
                                                <div class="mt-3"
                                                    style="padding:10px;border: 1px solid #FFBC15;border-radius: 20px;">
                                                    <v-row class="mt-1">
                                                        <v-col cols="12" xs="12" sm="7" md="7" lg="7" xl="7">
                                                                <p class="f-16 mb-2 bold" style="color:#686868;">วัตถุดิบ </p>
                                                            <div v-for="list in item.list" :key="list">
                                                                <p class="f-16 bold" style="color:#000;">{{list.mat_name}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                                                              <p class="f-16 mb-2 bold" style="color:#686868;">จำนวนที่ตัด</p>
                                                            <div v-for="list in item.list" :key="list">
                                                                <p class="f-16 bold" style="color:#000;">{{formatPrice(list.materials_qty)}} กรัม</p>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>


                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลเกณฑ์การตรวจสอบคุณภาพ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                     
                                    <!-- loop ขนาดสินค้า -->
                                    <v-col class="pb-0" xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <div class="table-header-setstep-4-product-box-data display-pc" style="color: #b1adad;">
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                ชื่อเกณฑ์ (English)
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                ชื่อเกณฑ์ (ภาษาไทย)
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Unit
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Aim
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Green
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Yellow
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Red
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                Value
                                            </div>
                                            <div class="table-header-setstep-4-product-box-data-text">
                                                ค่าอัตโนมัติ
                                            </div>
                                        </div>
                                    </v-col>
                                    <!-- loop ขนาดสินค้า -->

                                    <v-col class="pt-0">
                                        <v-expansion-panels >

                                            <v-expansion-panel v-for="(quality_data, index) in step_1_data.product_quality" :key="index" :class="{ border_left_yellow: quality_data.isActive }" class="radius-10 mt-3" @click="toggleActive(index)">
                                                <v-expansion-panel-header class="radius-10 border-yellow">
                                                    {{ quality_data.pq_name_eng }}      {{ quality_data.pq_name_thai }}
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="" >
                                                    <div class="mt-4" v-for="(quality_sub_data, index) in quality_data.list" :key="index">
                                                        <div class="table-header-setstep-4-product-box-data display-pc mt-3" style="color: #b1adad;">
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_eng }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_thai }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.unit }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_aim }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_green }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_yellow }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text">
                                                                {{ quality_sub_data.pq_list_red }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text text-center">
                                                                <v-icon v-if="quality_sub_data.pq_list_type == 0 && quality_sub_data.pq_value_status == 1 && quality_sub_data.pq_value_boolean == 1" color="#88B358">mdi-check-circle</v-icon>
                                                                <v-icon v-else-if="quality_sub_data.pq_list_type == 0 && quality_sub_data.pq_value_status == 1 && quality_sub_data.pq_value_boolean == 0" color="error">mdi-close-circle</v-icon>
                                                                <div v-else-if="quality_sub_data.pq_list_type == 1">{{ quality_sub_data.pq_value }}</div>
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text text-center">
                                                                <v-icon v-if="quality_sub_data.pq_value_status == 1" color="#FFBC15">mdi-check-circle</v-icon>
                                                                <v-icon v-if="quality_sub_data.pq_value_status == 0" color="error">mdi-close-circle</v-icon>
                                                            </div>
                                                            <!-- <div class="table-header-setstep-4-product-box-data-text text-center">
                                                                <v-icon color="#88b358">mdi-check-circle</v-icon>
                                                            </div>
                                                            <div class="table-header-setstep-4-product-box-data-text text-center">
                                                                <v-icon color="#FFBC15">mdi-check-circle</v-icon>
                                                            </div> -->
                                                        </div> 
                                                    </div>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                        </v-expansion-panels>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="pb-1">
                                <v-avatar class="top-to-card" color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">ข้อมูลเกณฑ์การตรวจสอบการผลิต</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>

                                    <!-- loop ขนาดสินค้า -->
                                    <v-col class="mb-0 pb-0" xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <div class="table-header-setstep-4-product-quality-box-data display-pc" style="color: #b1adad;">
                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                ชื่อเกณฑ์
                                            </div>
                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                ระยะค่าในเกณฑ์
                                            </div>
                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                Value
                                            </div>
                                            <div class="table-header-setstep-4-product-quality-box-data-text text-center">
                                                ค่าอัตโนมัติ
                                            </div>
                                        </div>
                                    </v-col>

                                    <v-col class="pt-0">
                                        <v-expansion-panels >

                                            <v-expansion-panel v-for="(production_data, index) in step_1_data.factory_quality" :key="index" :class="{ border_left_yellow: production_data.isActive }" class="radius-10 mt-3" @click="toggleActive_production(index)">
                                                <v-expansion-panel-header class="radius-10 border-yellow">
                                                    {{ production_data.fq_name }}
                                                </v-expansion-panel-header>

                                                <v-expansion-panel-content >
                                                    <div class="mt-4" v-for="(production_sub_data, index) in production_data.list" :key="index">
                                                        <div class="table-header-setstep-4-product-quality-box-data display-pc mt-3" style="color: #b1adad;">
                                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                                {{ production_sub_data.fq_list_name }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                                {{ production_sub_data.fq_list_guide }}
                                                            </div>
                                                            <div class="table-header-setstep-4-product-quality-box-data-text">
                                                                <span v-if="production_sub_data.fq_value_status === 1">
                                                                    <span v-if="production_sub_data.fq_list_type === 1">
                                                                        {{ production_sub_data.fq_value }}
                                                                    </span>
                                                                    <span v-else>
                                                                        <span v-if="production_sub_data.fq_value_boolean === 1">
                                                                            <v-icon color="#88b358">mdi-check-circle</v-icon>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        
                                                            <div class="table-header-setstep-4-product-quality-box-data-text text-center">
                                                                <span v-if="production_sub_data.fq_value_status === 1">
                                                                    <span v-if="production_sub_data.fq_value_boolean === 1">
                                                                        <v-icon color="#FFBC15">mdi-check-circle</v-icon>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                        </v-expansion-panels>
                                    </v-col>

                                    <!-- loop ขนาดสินค้า -->

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                </v-card>
            </v-col>

        </v-row>

    </div>
</template>
<script>
import before_image from '@/assets/groceries.png'
import product from "@/api/warehouse/product.js";
import productType from "@/api/warehouse/productType.js";
import { isTelephone, formatPrice } from "@/helper/handler";
export default {
    data() {
        return {
            loading_page: false,
            stepImport: 1,
            imagePreviewURL: before_image,
            confirm_delete_dialog:false ,
            material_step: 1,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลสินค้า',
                    disabled: false,
                    href: "/stock/setting-stock/set-product-component",
                },
                {
                    text: 'ตั้งสินค้า',
                    disabled: false,
                    href: "/stock/setting-stock/set-product-component",
                },
                {
                    text: 'รายละเอียดสินค้า',
                    disabled: false,
                    href: '#',
                },
            ],

            quality_list:[
                {
                    name_main:'Strup coating',
                    isActive: false,
                    quality_sub: [
                        { 
                            name_en:'Homogeneous',
                            name_th:'ความเข้ากัน(ไม่เป็นก้อน)',
                            unit:'-',
                            aim:'-',
                            green:'เป็นเนื้อเดียวกัน',
                            yellow:'เป็นก้อน',
                            red:'-',
                        },
                        { 
                            name_en:'Plain Crispy RiceWeight',
                            name_th:'น้ำหนักความพองก่อนเคลือบ',
                            unit:'Kg',
                            aim:'-',
                            green:'-',
                            yellow:'-',
                            red:'-',
                        },
                        { 
                            name_en:'Syrup weight',
                            name_th:'น้ำหนักน้ำเคลือบ',
                            unit:'Kg',
                            aim:'-',
                            green:'-',
                            yellow:'-',
                            red:'-',
                        },
                    ],
                },
                {
                    name_main:'Drying 2',
                    isActive: false,
                    quality_sub: [
                        { 
                            name_en:'Plain Crispy RiceWeight',
                            name_th:'น้ำหนักความพองก่อนเคลือบ',
                            unit:'Kg',
                            aim:'-',
                            green:'-',
                            yellow:'-',
                            red:'-',
                        },
                    ],
                },
            ],

            production_list:[
                {
                    name_main_criterion:'Fryer',
                    isActive: false,
                    production_sub: [
                        {
                            name_criterion:'Time',
                            range_criterion:'200-220 C',
                            value_criterion:'',
                        },
                        {
                            name_criterion:'Appearance',
                            range_criterion:'ไม่ไหม้ สีสม่ำเสมอ',
                            value_criterion:'ผ่าน',
                        },
                        {
                            name_criterion:'Texture',
                            range_criterion:'กรอบ ไม่แข็งเป็นไต',
                            value_criterion:'ผ่าน',
                        },
                    ],
                },
                {
                    name_main_criterion:'FG Spec',
                    isActive: false,
                    production_sub: [
                        {
                            name_criterion:'Bulk Density Fried Rice (Jasmine BR)',
                            range_criterion:'0.23-0.29',
                            value_criterion:'ผ่าน',
                        }
                    ],
                   
                },
            ],
            tab: null,
            menu: false,
            modal: false,
            menu2: false,
            toggle_exclusive: 2,
            page: 1,
            add_dialog: false,
            edit_dialog: false,
            detail_dialog: false,
            

            // ตัวแปลข้อมูล step ๅ
            code_list: '',
            product_name_list:'' ,
            type_list:null,
            size_list:null,
            product_part_list: [
                {
                    no_ppl_id: 1,
                    ppl_list_select: '',
                    ppl_list_formula: '',
                }
            ],
            /// ตัวแปรต่อ api
            step_1_data: {},
            type_product: {},
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
          formatPrice(number) {
      return formatPrice(number);
    },
        material_change(type) {
            this.material_step = type
        },
        toggleActive(index) {
            let clickedButton = this.quality_list[index];
            if (!clickedButton.isActive) {
                this.quality_list.forEach((loops) => {
                    loops.isActive = false;
                });
            }
            clickedButton.isActive = !clickedButton.isActive;
            if (this.quality_list.every((loops) => !loops.isActive)) {
                this.quality_list.forEach((loops) => {
                loops.isActive = false;
                });
            }
        },

        toggleActive_production(index) {
            let click_pro = this.production_list[index];
            if (!click_pro.isActive) {
                this.production_list.forEach((loopro) => {
                    loopro.isActive = false;
                });
            }
            click_pro.isActive = !click_pro.isActive;
            if (this.production_list.every((loopro) => !loopro.isActive)) {
                this.production_list.forEach((loopro) => {
                loopro.isActive = false;
                });
            }
        },

        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
       async getProductDetail(){
        let id = parseFloat(this.$route.params.id);
             await product
          .getProductDetail(id)
          .then((response) => {
                if(response.data.successful === true){
                    this.step_1_data = response.data.data;
                    this.listProductType(this.step_1_data.product_type_id)
                    this.imagePreviewURL = this.step_1_data.pro_pic != null ?  this.step_1_data.pro_pic : this.imagePreviewURL;
                }
          });
        },
        async listProductType(id) {
      await productType
        .detail(id)
        .then((response) => {
          if (response.data.successful === true) {
            this.type_product = response.data.detail;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$store.commit("logOutSuccess");
            this.$router.push("/login");
          }
        });
    },
    },

    computed: {
        isStep1Valid() {
        return this.code_list && this.product_name_list && this.type_list;
        },

    },

    mounted() {
        this.getProductDetail();
    },
    created() {

    },
}
</script>
<style scoped>
.input-group {
    display: flex;
    gap: 10px;
}

.form-select {
    width: 150px;
}

.form-control {
    flex: 1;
}

.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.padding-text-title {
    padding: 8px !important;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 20px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
    box-shadow: 0px 2px 3px 1px #0000001a;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-create-data {
    display: grid;
    grid-template-columns: 4% 4% 16% 20% 20% 20% 16%;
    color: #686868 !important;
}

.table-header-create-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-create-items {
    display: grid;
    grid-template-columns: 4% 4% 17% 20% 20% 21% 14%;
    align-items: center;
}

.table-header-import-step3-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 5px 0px 5px 30px;
    color: #686868 !important;
}

.table-header-import-step3-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-import-step3-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
}

.v-btn[disabled] {
  background-color: #f5f5f5;
  color: #a9a9a9 ;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 36px;
    padding: 0 6px;
}

.table-header-setstep-4-product-box-data {
    display: grid;
    grid-template-columns: 15% 15% 10% 10% 10% 10% 10% 10% 10%;
    padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.table-header-setstep-4-product-quality-box-data {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 0px 0px 5px 5px;
}

.table-header-setstep-4-product-quality-box-data-text {
    color: #888888;
    font-size: 14px;
    margin-left: 20px;
    font-weight: bold;
}

.border-yellow{
    border-bottom: 1px solid #FFBC15;
}

.border_left_yellow{
    border-left: 4px solid #FFBC15;
    border-top: 1px solid #FFBC15;
    border-bottom: 1px solid #FFBC15;
    border-right:1px solid #FFBC15 ;
    border-radius: 10px;
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 0 10px 15px !important;
}

</style>