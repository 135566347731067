import Vue from 'vue'
// import Vuex from 'Vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routers/index.js'
import store from './store/index.js'
import profile from '@/api/user_profile/profile.js'
// Vue color picker
import InputColorPicker from "vue-native-color-picker";
Vue.use(InputColorPicker);
Vue.config.productionTip = false

Vue.mixin({
  data(){
    return {
      profile_detail: {},
      first_get: 1
    }
  },
  methods:{
    async profileDetail() {
       // this.loading_page = true
       await profile.profileDetail().then(async (response) => {
        if (response.data.successful === true) {
          if(response.data.profileDetail.is_delete == 1 || response.data.profileDetail.status == 0){
            this.$store.commit('logOutSuccess')
            this.$router.push('/login')
          }
          const profileDetail = response.data.profileDetail;
          this.$store.commit('setProfileDetail', profileDetail);

          this.profile_detail.id = await response.data.profileDetail.id
          this.profile_detail.name = await response.data.profileDetail.name
          this.profile_detail.name_edit = await response.data.profileDetail.name
          this.profile_detail.tel_edit = await response.data.profileDetail.tel
          this.profile_detail.permission = await response.data.profileDetail.permission

          //บัญชี
          // this.profile_detail.per_account = await response.data.profileDetail.per_account //บันทึกบัญชี
          // this.profile_detail.per_account_report = await response.data.profileDetail.per_account_report //รายงานบัญชี
          // this.profile_detail.per_account_setting = await response.data.profileDetail.per_account_setting //ตั้งค่าประเภทบัญชี

          //การขาย
          // this.profile_detail.per_customer = await response.data.profileDetail.per_customer  //การขาย -> ข้อมูลลูกค้า
          // this.profile_detail.per_order = await response.data.profileDetail.per_order //จัดการคำสั่งซื้อ

          this.profile_detail.per_dashboard = await response.data.profileDetail.per_dashboard //Dashboard
          this.profile_detail.per_edit_company = await response.data.profileDetail.per_edit_company //ตั้งค่าข้อมูลบริษัท
          this.profile_detail.per_po = await response.data.profileDetail.per_po  //สร้างใบสั่งซื้อวัตถุดิบ

          this.profile_detail.per_product_formula = await response.data.profileDetail.per_product_formula  //ดู/แก้ไข สูตรการผลิต
          this.profile_detail.per_materials_formula = await response.data.profileDetail.per_materials_formula  //ดู/แก้ไข สูตรการแปรรูป

          this.profile_detail.per_production = await response.data.profileDetail.per_production  //จัดการสั่งผลิต/แปรรูป
          this.profile_detail.per_production_history = await response.data.profileDetail.per_production_history //ดูประวัติการผลิต

          this.profile_detail.per_stock = await response.data.profileDetail.per_stock  //ตั้งค่าข้อมูลคลัง
          this.profile_detail.per_stock_report = await response.data.profileDetail.per_stock_report  //ดูรายงานคลัง
          this.profile_detail.per_stock_in = await response.data.profileDetail.per_stock_in  //นำวัตถุดิบเข้าคลัง

          this.profile_detail.per_stock_history = await response.data.profileDetail.per_stock_history  //ดูประวัติเข้าออกคลัง

          // เมนูจัดการพนักงาน
          this.profile_detail.per_user = await response.data.profileDetail.per_user  //จัดการข้อมูลทีมงาน
          // this.loading_page = false
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$store.commit('logOutSuccess')
          this.$router.push('/login')
        }
        if (error.response.status === 502 && this.$store.state.access_token != null || this.$store.state.access_token != '') {
          window.location.reload()
        }
      })
    },
  },
  created(){
    // this.profileDetail()
  }
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

