<template>
    <div>
      <ProcessingPackagingStepCreate/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepCreate from "@/components/production_process/procressing_packaging/ProcressingPackagingStepCreate";
  export default {
    name: "Processing_packaging_step_create",
    components: {
      ProcessingPackagingStepCreate
    }
  }
  </script>
  
  <style scoped>
  
  </style>