<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{
        alert_message_success
      }}</v-alert>
      <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{
        alert_message_error
      }}</v-alert>
    </v-slide-x-reverse-transition>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">รายการสินค้าที่นำออกจากคลัง</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card elevation="4" class="card-radius">
          <v-card-title>
            <v-row>
              <v-col cols="6"><b>รายการสินค้าที่นำออกจากคลัง</b></v-col>
              <v-col cols="6 text-right"><b>รวมมูลค่าทิ้ง <span style="color:#F28080">{{ formatPrice(total_drop_price)
              }}</span> บาท</b></v-col>

            </v-row>

          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-btn @click="link('/stock/warehouse-stock/WarehouseRawmaterial')"
                  class="mt-2 mr-2  radius-15 text-grey  text-16" elevation="0" large color="#fff">
                  วัตถุดิบ
                </v-btn>
                <v-btn @click="link('/stock/warehouse-stock/WarehouseRawmaterialProductOut')"
                  class="mt-2 mr-2 radius-15  text-16" elevation="2" large outlined color="#FFBC15">
                  สินค้า
                </v-btn>
              </v-col>
              <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <v-text-field color="#D9D9D9" class="input-radius-search" prepend-inner-icon="mdi-magnify"
                  v-model="search" placeholder="ค้นหาที่นี่..." @keyup="searchList()" hide-details="auto" single-line
                  outlined dense>
                </v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="table-header-WarehouseRawmaterial-box-data display-pc" style="color: #686868">
                  <div class="table-header-WarehouseRawmaterial-box-data-text"></div>
                  <div class="table-header-WarehouseRawmaterial-box-data-text">
                    วันที่นำออก
                  </div>
                  <div class="table-header-WarehouseRawmaterial-box-data-text">
                    ชื่อวัตถุดิบ/สินค้า
                  </div>

                  <div class="table-header-WarehouseRawmaterial-box-data-text">จำนวน</div>
                  <div class="table-header-WarehouseRawmaterial-box-data-text">
                    หน่วยนับ
                  </div>
                  <div class="table-header-WarehouseRawmaterial-box-data-text">
                    มูลค่าทิ้ง
                  </div>
                </div>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="0" class="card-table-radius" v-for="item in ware_house_data" :key="item">
                  <div class="grid-WarehouseRawmaterial-box-items">
                    <div class="w-border"></div>
                    <div> {{
                      `${item.create_date.split('/')[0]}-${item.create_date.split('/')[1]}-${item.create_date.split('/')[2]}`
                    }}</div>
                    <div>{{ item.pro_name }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="#A3A3A3" dark v-bind="attrs" v-on="on">
                            mdi-message-text
                          </v-icon>
                        </template>
                        <v-row style="width:450px;">
                          <v-col cols="12 py-1 pt-5">LOT : {{ item.product_lot }}</v-col>
                          <v-col cols="12 py-1">หมายเหตุการทิ้ง : {{ item.note }}</v-col>
                          <v-col cols="12 py-1 pb-5 text-right">โดย {{ item.user_edit }}</v-col>
                        </v-row>
                      </v-tooltip>
                    </div>
                    <div><span style="color:#F28080">{{ formatPrice(item.count_export) }}</span></div>
                    <div>{{ item.unit_name }}</div>
                    <div><span style="color:#F28080">{{ formatPrice(item.total_price) }}</span></div>
                  </div>
                </v-card>
                <div class="text-center mt-5">
                  <v-pagination v-model="page" :length="pageSize" :total-visible="7" color="#FFBC15"></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from "@/components/menu/VerticalMenu.vue";
import meterial from '@/api/warehouse/materials.js'
export default {
  inject: {
    theme: {
      default: {
        isDark: false,
      },
    },
  },
  data() {
    return {
      loading_page: true,
      loading_detail_page: true,
      alert_success: false,
      alert_message_success: "",
      alert_error: false,
      alert_message_error: "",
      sub_menu: [
        {
          text: "คลังสินค้า",
          disabled: false,
        },
        {
          text: "รายการสินค้าที่นำออกจากคลัง",
          disabled: false,
          href: "#",
        },
      ],
      search: '',
      page: 1,
      pageSize: 1,
      data: [],
    };
  },

  methods: {
    link(link) {
      this.$router.push(link);
    },
    formatPrice(number) {
      return formatPrice(number);
    },

    isOnlyNumber: function (evt, number) {
      return onlyForCurrency(evt, number);
    },
    getProductExport() {
      meterial.getProductExport(this.search, this.page, '').then((response) => {
        if (response.data.successful === true) {
          this.ware_house_data = response.data.data;
          this.total_drop_price = response.data.total_drop_price;
          this.pageSize = response.data.pages;
          this.page = parseFloat(response.data.currentPage);
          this.loading_page = false;
        }
      })
    },
    async searchList() {
      await meterial.getProductExport(this.search, this.page, '',).then((response) => {
        this.ware_house_data = response.data.data;
        this.total_drop_price = response.data.total_drop_price;
        this.pageSize = response.data.pages;
        this.page = parseFloat(response.data.currentPage);
      })
    },

  },
  mounted() {
    this.getProductExport();
  },
  components: {
    navigationmenu,
  },
  created() {
    this.getProductExport();
  },
  watch: {
    page() {
      this.getProductExport();
    }
  },
};
</script>
<style scoped>
.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 22px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.text-name-title,
.text-date-title {
  color: #686868;
}

.text-fullname-title,
.text-datetime-title {
  color: #000000;
}

.text-name-title,
.text-fullname-title {
  font-size: 14px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-WarehouseRawmaterial-box-data {
  display: grid;
  grid-template-columns: 3% 15% 36% 17% 17% 16%;
  padding: 5px 0px 5px 30px;
}

.table-header-warehouse-box-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-WarehouseRawmaterial-box-items {
  display: grid;
  grid-template-columns: 5% 15% 35% 17% 16% 12%;
  align-items: center;
}
</style>
