<template>
    <div>
        <SetRawMaterialAddPage />
    </div>
</template>
  
<script>
import SetRawMaterialAddPage from '@/components/stock/setting_stock/SetRawMaterialAdd'
export default {
    name: 'SetRawMaterialAdd',
    components: {
        SetRawMaterialAddPage,
    }
}
</script>