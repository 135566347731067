<template>
    <div>
        <Warehouse_page_packaged_detail_raw_material_auditInspection />
    </div>
</template>
  
<script>
import Warehouse_page_packaged_detail_raw_material_auditInspection from '@/components/stock/warehouse_stock/WarehousePackagedRawMaterialAuditInspection'
export default {
    name: 'WarehousePackagedRawMaterialAuditInspection',
    components: {
        Warehouse_page_packaged_detail_raw_material_auditInspection,
    }
}
</script>