<template>
    <div>
        <Warehouse_page_packaged_detail_lot_used />
    </div>
</template>
  
<script>
import Warehouse_page_packaged_detail_lot_used from '@/components/stock/warehouse_stock/WarehousePackagedDetailLotUsed'
export default {
    name: 'WarehousePackagedDetailLotUsed',
    components: {
        Warehouse_page_packaged_detail_lot_used,
    }
}
</script>