<template>
  <div>
    <ProcessingProductGoodProductionDetail/>
  </div>
</template>

<script>
import ProcessingProductGoodProductionDetail from "@/components/production_process/processing_product_good/ProcessingProductGoodProductionDetail";
export default {
  name: "ProcessingProductGoodProductionDetail_Page",
  components: {ProcessingProductGoodProductionDetail}
}
</script>

<style scoped>

</style>