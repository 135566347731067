import Admin from '@/views/teams/Admin.vue'
import Teams from '@/views/teams/Teams.vue'

export default [
    {
        path: '/teams/admin',
        name: 'admin',
        component: Admin,
    },
    {
        path: '/teams/teams',
        name: 'teams',
        component: Teams,
    },
]

