<template>
    <div>
        <InspectionCriteriaProductionPage />
    </div>
</template>
  
<script>
import InspectionCriteriaProductionPage from '@/components/stock/setting_stock/InspectionCriteriaProductionComponent'
export default {
    name: 'InspectionCriteriaProduction',
    components: {
        InspectionCriteriaProductionPage,
    }
}
</script>