import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
  reportList(page, search) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/ReportQuality/facqualityReport/list?page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  pdreportqualityreport(page, search) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/ReportQuality/pdreportqualityreport/list?page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getReport(id, fq_type) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/ReportQuality/facqualityReport/get/product/or/material/select/box/${id}?fq_type=${fq_type}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getDetail(id, item_id, start_date, end_date) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/ReportQuality/listfq_detail/${id}?item_id=${item_id}&start_date=${start_date}&end_date=${end_date}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
}

