<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <navigationmenu />

    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการผลิตสินค้า</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/pro/processing_product_good')">การผลิตสินค้า</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการผลิตสินค้า</div>
    </div>
    <div class="mt-3">
      <v-form ref="form_add_or_edit" @submit.prevent="saveData()">
        <v-card elevation="2" class="card-radius">
          <v-card-title>
            <b>ขั้นตอนที่ 4 : การบรรจุภัณฑ์</b>
          </v-card-title>
          <v-card-text>
            <v-card elevation="4" class="py-2 px-3" style="border-radius:20px;">

              <v-card-title>
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>
                    mdi-notebook-edit-outline
                  </v-icon>
                </v-avatar>
                <span class="ml-3 title-card-text">การบันทึกผลการบรรจุภัณฑ์</span>
              </v-card-title>

              <v-card-text>
                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>

                <v-row class="px-10">

                  <v-col v-for="(loop_header, index_header) in dataList" :key="index_header" class="card-table-radius-sub"
                    cols="12" lg="6" xl="6">
                    <v-card elevation="2" class="border-card">
                      <v-row class="ma-1 go-to-card">

                        <v-col cols="6 flex-center-header">
                          <div class="tag-title-step">
                            <b class="black--text">{{ formatPrice(loop_header.pro_amount) }} กรัม</b>
                          </div>
                          <span> {{ loop_header.pro_size_name }} </span>
                        </v-col>

                        <v-col cols="6 text-right flex-center-header-two">
                          <span>จำนวน {{ formatPrice(loop_header.qty) }} {{ loop_header.unit_name }}</span>
                        </v-col>

                        <v-col cols="12 py-1 col-harder">
                          <v-divider class="border-main pt-1" dark color=""></v-divider>
                        </v-col>

                        <v-col cols="4 pb-0">
                          <span>วัตถุดิบ </span>
                        </v-col>
                        <v-col cols="4 pb-0">
                          <span>เลข Lot </span>
                        </v-col>
                        <v-col cols="4 px-0 pb-0 ">
                          <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                        </v-col>

                      </v-row>

                      <v-row class="ma-1 " v-for="(loop_sub, index) in loop_header.material_list" :key="index">
                        <v-col cols="4 ">
                          <span> {{ loop_sub.mat_name }} </span>
                        </v-col>

                        <v-col cols="8">
                          <v-row v-for="(loop_sub_list, index_sub) in loop_sub.stock_list" :key="index_sub">
                            <!-- <v-col cols="2 px-0" v-if="index_sub != 0">
                              <v-btn class="" color="#F28080" elevation="2" outlined rounded >
                                <v-icon >mdi-content-save</v-icon> 
                              </v-btn>
                            </v-col>
                            <v-col v-else cols="2"></v-col> -->

                            <v-col cols="6">
                              <!-- <v-select
                                  :items="mock_lot"
                                  color="#ffbc15"
                                  item-text="lot"
                                  item-value="lot_id"
                                  label="เลข Lot"
                                  :rules="[(v) => !!v || 'กรุณาเลือก เลข Lot']"
                                  hide-details="auto"
                                  dense
                                  outlined
                                  rounded
                                  required
                                ></v-select> -->

                                <p>{{ loop_sub_list.material_lot }}</p>
                              <!-- <v-text-field type="text" class="input-radius" label="เลข Lot" placeholder="ชื่อสินค้า"
                                v-model="loop_sub_list.material_lot" color="#ffbc15"
                                :rules="[(v) => !!v || 'กรุณากรอกบรรจุภัณฑ์']" hide-details="auto" dense outlined rounded
                                required disabled>
                              </v-text-field> -->

                            </v-col>
                            <v-col class="pl-0" cols="6">
                              <p >{{ loop_sub_list.quantity_cut }}</p>
                              <!-- <v-form-group>
                                <v-text-field type="text" class="input-radius" label="วัตถุดิบที่ใช้ในการบรรจุภัณฑ์"
                                  placeholder="ชื่อสินค้า" v-model="loop_sub_list.quantity_cut" color="#ffbc15"
                                  :rules="[(v) => !!v || 'กรุณากรอกบรรจุภัณฑ์']" hide-details="auto" dense outlined
                                  rounded required disabled>
                                </v-text-field>
                              </v-form-group> -->
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="4 text-center py-0"></v-col>
                        <!-- <v-col cols="8 py-0 pb-2" >
                          <btn type="button" @click="plusLot(index_header, index)" class="text-green" > <v-icon color="#88B358">mdi-plus</v-icon> เพิ่ม Lot วัตถุดิบ</btn>
                        </v-col> -->

                      </v-row>

                    </v-card>
                  </v-col>

                </v-row>

              </v-card-text>

            </v-card>
          </v-card-text>

          <v-card-text>
            <v-card elevation="4" class="py-2 px-5" style="border-radius:20px;">

              <v-card-title>
                <v-avatar color="#34230F" size="36">
                  <v-icon dark>
                    mdi-account-supervisor
                  </v-icon>
                </v-avatar>
                <span class="ml-3 title-card-text">สินค้า FG คงเหลือจากการบรรจุภัณฑ์</span>
              </v-card-title>

              <v-card-text>
                <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                <v-row class="px-10">
                  <v-col cols="12">
                    <div>จำนวนสินค้า FG ที่คงเหลือจากการบรรจุภัณฑ์</div>
                  </v-col>
                  <v-col cols="12" lg="6" xl="6" md="6">
                    <v-form-group>
                      <v-text-field type="text" class="input-radius" label="จำนวนสินค้า FG ที่คงเหลือจากการบรรจุภัณฑ์"
                        suffix="กรัม" v-model="dataListFg" clear-icon="text-right" color="#ffbc15"
                        :rules="[(v) => !!v && v <= parseFloat(qty_after_production) || 'กรุณากรอกจำนวนสินค้า']"
                        hide-details="auto" dense outlined rounded required>
                      </v-text-field>
                    </v-form-group>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>
          </v-card-text>

          <div class="mt-3 text-center">
            <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="saveData">
              <v-icon left>mdi-content-save</v-icon> บันทึก
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import process from '@/api/product_good/product_process_step_four.js'
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import Swal from "sweetalert2";
import { formatPrice } from "@/helper/handler";
export default {
  name: "ProductionProductGoodPackage",
  data() {
    return {
      loading_page: true,
      route_id: this.$route.params.id,
      sTime: '00 : 00',
      mock_lot: [
        {
          lot_id: 1,
          lot: '20-202302201500'
        },
        {
          lot_id: 2,
          lot: '21-202302201500'
        },
      ],
      qty_after_production: null,
      dataList: [],
      dataList_fg: null,
      no_lot: 1,
      lot_list: [
        {
          no_lot: 1,
          lot_number: [],
          lot_qty: null,
        }
      ]
    }
  },
  methods: {
    formatPrice(number) {
      return formatPrice(number)
    },
    link(link) {
      this.$router.push(link)
    },
    getStepFourList() {
      process.getStepFourList(this.route_id).then((response) => {
        this.loading_page = true
        if (response.data.successful === true) {
          this.qty_after_production = response.data.qty_after_production
          this.dataList = response.data.size_list;
          this.dataList = this.dataList.map(item => {
            return {
              ...item,
              material_list: item.material_list.map(detail => {
                return {
                  ...detail,
                  stock_list: detail.stock_list.map(o => {
                    return {
                      ...o,
                      quantity_cut: formatPrice(o.quantity_cut)
                    }
                  })
                }
              }
              )
            }
          }
          );

          this.dataListFg = response.data.product_qty_remain;
          this.loading_page = false
        }
      }).catch((error) => {
        if (error.response.status === 502) {
          window.location.reload()
        }
      })
    },

    saveData() {
      if (this.$refs.form_add_or_edit.validate()) {
        this.loading_page = true;
        const data = {
          production_id: this.route_id,
          product_qty_remain: this.dataListFg,
        }
        process.getStepFourAdd(data).then((response) => {
          if (response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                window.location.href = "/pro/processing_product_good_steps/" + this.route_id;
              }
            });
          }
        })
      }
    },

    plusLot(indexHeader, index) {
      this.mock_header[indexHeader].mock_data[index].data_lot_list.push({
        data_lot_list_lot: null,
        data_lot_list_qty: 0
      });
    },

    linkToSteps() {
      this.$router.push('/prm/processing_raw_materials')
    }
  },
  components: {
    navigationmenu
  },
  mounted() {
    this.getStepFourList()
  },
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 10% 36% 29% 15%;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 23% 23% 29% 15%;
  align-items: baseline;
}

.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}

.text-green {
  color: #88B358;
}



.flex-center-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-header-two {
  display: flex;
  align-items: center;
  justify-content: end;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.flex-center-header>.tag-title-step {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.flex-center-header>.tag-title-step {
  border: 1px solid #34230F;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.card-table-radius-sub:nth-child(even)>.border-card {
  border-radius: 20px;
  border: 1px solid #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card {
  border-radius: 20px;
  border: 1px solid #34230F;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.col-harder>.border-main {
  background-color: #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.col-harder>.border-main {
  background-color: #34230F;
}
</style>