<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">สรุปรายการสั่งซื้อ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-form ref="form" @submit.prevent="materialAdd()">
                    <v-card class="card-radius" elevation="4">
                        <v-card-title>
                            <p class="team-text-title-card">สรุปรายการสั่งซื้อ</p>
                        </v-card-title>
                        <v-card-text>
                            <v-card elevation="4" style="border-radius:20px;">
                                <v-card-title class="d-flex justify-space-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <v-avatar color="#34230F" size="32">
                                            <v-icon dark>
                                                mdi-shield-check
                                            </v-icon>
                                        </v-avatar>
                                        <span class="ml-3 text-font-title">กรุณาเลือกวัตถุดิบที่ต้องการซื้อ</span>
                                    </div>
                                    <div>
                                        <!-- <v-btn type="submit" class="button-cancel-data" elevation="4" rounded large
                                            style="color:#88B358; border: 1px #88B358 solid;"
                                            @click="link('/stock/order-list/add')">
                                            <v-icon color="#88B358" left>mdi-arrow-left</v-icon> ย้อนกลับ
                                        </v-btn> -->
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider class="mb-3" color="#686868"></v-divider>
                                    <v-row>
                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                            <div class="table-header-import-data display-pc mt-3"
                                                style="color: #b1adad; padding-left: 10px;">
                                                <div class="table-header-import-data-text">ลบ</div>
                                                <div class="table-header-import-data-text">
                                                    วัตถุดิบ
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    คงเหลือ
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    หน่วยนับ
                                                </div>
                                                <div class="table-header-import-data-text">
                                                    จำนวน
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    ราคาต่อหน่วย
                                                </div>
                                                <div class="table-header-import-data-text text-center">
                                                    ราคารวม
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <div v-for="(detail, index) in data.po_list" :key="index">
                                    <v-card-title class="padding-text-title align-items-center"
                                        style="background: rgba(255, 188, 21, 0.1);border: 1px solid #FFBC15;border-radius: 20px;margin: 0 15px 15px 15px;">
                                        <span class="ml-3 text-font-title" style="font-size: 14px;">{{ detail.typeName
                                        }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-card elevation="0" class="card-table-radius"
                                            v-for="(mat_detail, index_list) in detail.List" :key="index_list">
                                            <div class="grid-import-items py-4">
                                                <div class="d-flex justify-center">
                                                    <v-icon color="#F28080" left
                                                        @click="confirmDeleteMatDetail(index, mat_detail.material_id)">mdi-trash-can</v-icon>
                                                </div>
                                                <div>{{ mat_detail.mat_name }}</div>
                                                <div class="text-center">{{ mat_detail.mat_instock }}</div>
                                                <div class="text-center">{{ mat_detail.unit_name }}</div>
                                                <div>
                                                    <v-text-field class="input-radius" color="#FFBC15"
                                                        style="border-radius:20px;" label="จำนวนที่ต้องการซื้อ"
                                                        :rules="[v => !!v || 'กรุณากรอกจำนวน']" v-model="mat_detail.qty"
                                                        @keypress="onlyForCurrency($event, mat_detail.qty)"
                                                        hide-details="auto" dense outlined required>
                                                    </v-text-field>
                                                </div>
                                                <div class="text-center">{{
                                                    formatPrice(parseFloat(mat_detail.price).toFixed(2)) }}</div>
                                                <div class="text-center">{{ formatPrice((parseFloat(mat_detail.price) *
                                                    mat_detail.qty == '' ? 0 : parseFloat(mat_detail.price) *
                                                parseFloat(mat_detail.qty))) }}</div>
                                            </div>
                                        </v-card>
                                        <div class="d-flex justify-end" v-if="(data.po_list.length - 1) == index">
                                            <div class="d-flex justify-space-between"
                                                style="padding:20px;background: rgba(136, 179, 88, 0.15);border: 1px solid #88B358;border-radius: 15px;">
                                                <h2 style="color:#000;margin-right:100px;">รวมเงิน</h2>
                                                <h2 style="color:#88B358;">{{ formatPrice(total_price ? total_price : 0) }}
                                                </h2>
                                            </div>
                                        </div>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </v-card-text>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data mr-1" color="#88B358" elevation="4" rounded
                                large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                            <v-btn type="button" class="button-cancel-data ml-1" elevation="4" rounded large
                                style="color:#F28080; border: 1px #F28080 solid;" @click="open_cancel_order = true">
                                <v-icon color="#F28080" left>mdi-trash-can</v-icon> ยกเลิก
                            </v-btn>
                        </div>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <!-- ลบข้อมูล -->
        <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="deleteMatDetail()">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:16px;" text @click="confirm_delete_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->

        <!-- ยกเลิกใบสั่งซื้อ -->
        <v-dialog v-model="open_cancel_order" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="open_cancel_order = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <!-- <img src="@/assets/bin.png"> -->
                                <h2 style="color:#000000">ยกเลิกใบสั่งซื้อ</h2>
                                <h3 class="mt-2">คุณต้องกายกเลิกใบสั่งซื้อใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large @click="clearStockOrderAll()">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ยกเลิกใบสั่งซื้อ
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:16px;" text @click="open_cancel_order = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ยกเลิกใบสั่งซื้อ -->
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import order from '@/api/order/order.js'
import Swal from 'sweetalert2'
export default {
    data() {
        return {
            loading_page: true,
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ใบสั่งซื้อ',
                    disabled: false,
                    href: '/stock/order-list',
                },
                {
                    text: 'สร้างใบสั่งซื้อ',
                    disabled: false,
                    href: '#',
                },
            ],
            data: [],
            confirm_delete_dialog: false,
            index_delete: null,
            material_id_delete: null,
            open_cancel_order: false,
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        onlyForCurrency: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        async materialBeforeAdd() {
            this.data = this.$store.state.stockOrderList
            console.log(this.data)
            this.loading_page = false
        },
        async confirmDeleteMatDetail(index, material_id) {
            this.index_delete = index
            this.material_id_delete = material_id
            this.confirm_delete_dialog = true

        },
        async deleteMatDetail() {
            this.data.po_list[this.index_delete].List = this.data.po_list[this.index_delete].List.filter((detail) => {
                return detail.material_id != this.material_id_delete
            })
            if (this.data.po_list[this.index_delete].List.length <= 0) {
                this.data.po_list = this.data.po_list.filter((detail, index) => {
                    console.log(detail)
                    return index != this.index_delete
                })
            }
            this.index_delete = null
            this.material_id_delete = null
            this.confirm_delete_dialog = false
        },
        async clearStockOrderAll() {
            this.$store.commit('clearStockOrder')
            this.$router.push('/stock/order-list/add')
        },
        async materialAdd() {
            if (this.$refs.form.validate()) {

                let po_list = []
                let total_price = 0
                this.data.po_list.forEach((all) => {
                    if (all.List.length > 0) {
                        all.List.forEach((detail) => {
                            po_list.push({
                                material_id: detail.material_id,
                                sup_id: detail.sup_id,
                                qty: parseFloat(detail.qty),
                                unit_id: detail.unit_id,
                                price: parseFloat(detail.price),
                                total_price: parseFloat(detail.price) * parseFloat(detail.qty),
                            })
                            total_price += parseFloat(detail.price) * parseFloat(detail.qty)
                        })
                    }
                })
                if (po_list.length > 0) {
                    const data = {
                        po_list: po_list,
                        total_price: total_price
                    }
                    const response = await order.materialAdd(data)
                    if (response.data.successful == true) {
                        this.$store.commit('clearStockOrder')
                        let timerInterval
                        Swal.fire({
                            title: 'ทำรายการเสร็จสิ้น',
                            icon: 'success',
                            html: 'ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.',
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft()
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval)
                            }
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                this.$router.push('/stock/order-list/detail/' + response.data.id)
                            }
                        })
                    }
                }
            }
        }
    },
    computed: {
        total_price() {
            let price = 0
            this.data.po_list.forEach((all) => {
                all.List.forEach((detail) => {
                    price += parseFloat(detail.price) * parseFloat(detail.qty)
                })
            })
            return price
        }
    },
    created() {
        this.materialBeforeAdd()
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-import-data {
    display: grid;
    grid-template-columns: 5% 10% 19% 10% 20% 26% 10%;
    color: #686868 !important;
}

.table-header-import-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-import-items {
    display: grid;
    grid-template-columns: 5% 10% 19% 10% 20% 26% 10%;
    align-items: center;
}
</style>