<template>
    <div>
      <MaterialPurchaseOrderEdit/>
    </div>
  </template>
  
  <script>
  import MaterialPurchaseOrderEdit from '@/components/stock/material_import/MaterialPurchaseOrderEditComponent'
  export default {
    name: 'materialpurchaseorderedit_Page',
    components:{
      MaterialPurchaseOrderEdit,
    }
  }
  </script>