<template>
    <div>
      <MaterialImportPage/>
    </div>
  </template>
  
  <script>
  import MaterialImportPage from '@/components/stock/material_import/MaterialImportListComponent'
  export default {
    name: 'materialimage',
    components:{
      MaterialImportPage,
    }
  }
  </script>