<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div v-else class="responsive-content">
        <navigationmenu />
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">จัดการคำสั่งซื้อ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card class="card-radius" elevation="4">
                    <v-card-title>
                        <p class="team-text-title-card">รายการคำสั่งซื้อของลูกค้า</p>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3"
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                <div class="d-flex justify-end">
                                    <v-btn class="mt-2" large rounded outlined color="#88B358"
                                        v-if="$store.state.profileDetail.per_sell_add_order == 1 || $store.state.profileDetail.permission == 1"
                                        @click="link('/sale/order-add')">
                                        <v-icon left>mdi-plus</v-icon>
                                        สร้างคำสั่งซื้อใหม่
                                    </v-btn>
                                    <v-btn class="mt-2" large rounded color="#D9D9D9" v-else>
                                        <v-icon left>mdi-plus</v-icon>
                                        สร้างคำสั่งซื้อใหม่
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-order-list-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-order-list-text"></div>
                                    <div class="table-header-order-list-text">
                                        รหัสคำสั่งซื้อ
                                    </div>
                                    <div class="table-header-order-list-text">
                                        ชื่อลูกค้า
                                    </div>
                                    <div class="table-header-order-list-text">
                                        วันที่สั่งซื้อ
                                    </div>
                                    <div class="table-header-order-list-text">
                                        ยอดสั่งซื้อ
                                    </div>
                                    <div class="table-header-order-list-text">
                                        สถานะคำสั่งซื้อ
                                    </div>
                                    <div class="table-header-order-list-text">
                                        พิมพ์
                                    </div>
                                    <div class="table-header-order-list-text">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in data"
                                    :key="index">
                                    <div class="grid-order-list-box-items">
                                        <div class="w-border"></div>
                                        <div>{{ detail.order_code }}</div>
                                        <div>{{ detail.customer_name }}</div>
                                        <div>{{ detail.order_date == null ? '-' :
                                            `${detail.order_date.split('-')[2]}-${detail.order_date.split('-')[1]}-${detail.order_date.split('-')[0]}`
                                        }}</div>
                                        <div>{{ detail.order_total == null ? 0 : formatPrice(detail.order_total) }} บาท
                                        </div>
                                        <div>
                                            <v-chip v-if="detail.order_status == 0" color="#FFBC15" text-color="white"
                                                small>
                                                รอดำเนินการ
                                            </v-chip>
                                            <v-chip v-if="detail.order_status == 1" color="#FEA06C" text-color="white"
                                                small>
                                                กำลังผลิต
                                            </v-chip>
                                            <v-chip v-if="detail.order_status == 2" color="blue" text-color="white" small>
                                                กำลังบรรจุ
                                            </v-chip>
                                            <v-chip v-if="detail.order_status == 3" color="#6C7FD8" text-color="white"
                                                small>
                                                พร้อมจัดส่ง
                                            </v-chip>
                                            <v-chip v-if="detail.order_status == 4" color="#88B358" text-color="white"
                                                small>
                                                เสร็จสิ้น
                                            </v-chip>
                                            <v-chip v-if="detail.order_status == 5" color="#F28080" text-color="white"
                                                small>
                                                ยกเลิกคำสั่งซื้อ
                                            </v-chip>
                                        </div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn class="mr-2 radius-15" color="#52595D" v-bind="attrs" v-on="on"
                                                        large outlined v-if="detail.order_status == 5" disabled>
                                                        <v-icon left>mdi-printer</v-icon>
                                                        <div>พิมพ์</div>
                                                    </v-btn>
                                                    <v-btn class="mr-2 radius-15" color="#52595D" v-bind="attrs" v-on="on"
                                                        large outlined v-else>
                                                        <v-icon left>mdi-printer</v-icon>
                                                        <div>พิมพ์</div>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item v-if="detail.order_status != 5" link>
                                                        <v-list-item-title style="color: #52595d"
                                                            @click="getPDF(base_url + 'order/pdf/invoice/' + detail.id)">
                                                            ใบคำสั่งซื้อ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="detail.order_status == 3 || detail.order_status == 4"
                                                        link>
                                                        <v-list-item-title style="color: #52595d"
                                                            @click="getPDF(base_url + 'order/pdf/delivery/' + detail.id, base_url + 'order/pdf/box/' + detail.id)">
                                                            ใบส่งของ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="detail.order_status == 3 || detail.order_status == 4"
                                                        link>
                                                        <v-list-item-title style="color: #52595d"
                                                            @click="getPDF(base_url + 'order/pdf/receipt/' + detail.id)">
                                                            ใบเสร็จ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link
                                                        v-if="detail.order_status < 4 && detail.qty_need_to_production_more <= 0">
                                                        <v-list-item-title style="color:#88B358"
                                                            @click="link('/sale/order-shipping-detail/' + detail.id)">
                                                            <v-icon left style="color:#88B358">mdi-truck</v-icon>
                                                            จัดส่งสินค้า
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#6C7FD8"
                                                            @click="link('/sale/order-detail/' + detail.id)">
                                                            <v-icon left
                                                                style="color:#6C7FD8">mdi-information-variant</v-icon>
                                                            รายละเอียด
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <span
                                                        v-if="$store.state.profileDetail.per_sell_edit_order == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item
                                                            v-if="detail.order_status == 0 || detail.order_status == 1 || detail.order_status == 2"
                                                            link>
                                                            <v-list-item-title style="color:#FEA06C"
                                                                @click="link('/sale/order-edit/' + detail.id);">
                                                                <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                                แก้ไขข้อมูล
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </span>
                                                    <span
                                                        v-if="$store.state.profileDetail.per_sell_cancel_order == 1 || $store.state.profileDetail.permission == 1">
                                                        <v-list-item
                                                            v-if="detail.order_status == 0 || detail.order_status == 1 || detail.order_status == 2 || detail.order_status == 3"
                                                            link @click="show_cancel_dialog(detail.id)">
                                                            <v-list-item-title style="color:#F28080">
                                                                <v-icon left
                                                                    style="color:#F28080">mdi-delete-forever</v-icon>
                                                                ยกเลิกคำสั่งซื้อ
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </span>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- ยกเลิกรายการบัญชี -->
        <v-dialog v-model="cancel_dialog" transition="dialog-top-transition" max-width="737" style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b class="ml-5">ยกเลิกคำสั่งซื้อ</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="cancel_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-form ref="form_add_or_edit" @submit.prevent="cancel_save()">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-folder-information-outline
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 text-font-title">เหตุผลการยกเลิกคำสั่งซื้อ {{ showDetail.order_code
                                }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-textarea class="input-radius"
                                                :label="'เหตุผลการยกเลิกคำสั่งซื้อ ' + showDetail.order_code"
                                                color="#FFBC15" v-model="order_cancel"
                                                :rules="[v => !!v || 'กรุณากรอกเหตุผลการยกเลิกคำสั่งซื้อ ' + showDetail.order_code]"
                                                outlined rounded rows="6" row-height="25"></v-textarea>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดยกเลิกรายการบัญชี -->
    </div>
</template>
<script>
import { isTelephone, formatPrice } from "@/helper/handler";
import { dateFormatNoSeconds } from "@/helper/dateformats";
import order from '@/api/sale/order.js'
import Swal from "sweetalert2";
// import Swal from 'sweetalert2'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'การขาย',
                    disabled: false,
                },
                {
                    text: 'จัดการคำสั่งซื้อ',
                    disabled: false,
                    href: '#',
                }
            ],
            data: [],
            showDetail: [],
            page: 1,
            pageSize: 1,
            search: '',
            cancel_dialog: false,
            base_url: process.env.VUE_APP_BASE_URL,
        }
    },

    methods: {
        getPDF(link, link2 = null) {
            window.open(link, '_blank');
            if (link2 != null) {
                window.open(link2, '_blank');
            }

        },
        dateFormatNoSeconds(dateTime) {
            const date = dateFormatNoSeconds(dateTime).split(" ");
            return date[0];
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isNumber: function (evt, number) {
            return isTelephone(evt, number)
        },
        link(link) {
            this.$router.push(link)
        },
        async orderList() {
            order.orderList(this.page, '', this.search,).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.orderList
                    this.pageSize = response.data.pages;
                    this.page = parseFloat(response.data.currentPage);
                }
                if (response.data.successful === false) {
                    setTimeout(() => this.alert_error = false, 5000) //need adjust
                }
            })
        },
        show_cancel_dialog(id) {
            let getProduct = this.data.find(o => o.id === id)
            this.showDetail = getProduct
            this.cancel_dialog = true
        },
        cancel_save() {
            if (this.$refs.form_add_or_edit.validate()) {
                const data = {
                    id: this.showDetail.id,
                    order_cancel: this.order_cancel,
                    order_status: 5,
                }
                order.orderCancel(data).then((response) => {
                    if (response.data.successful === true) {
                        this.cancel_dialog = false
                        this.orderList()
                        let timerInterval;
                        Swal.fire({
                            title: "ทำรายการเสร็จสิ้น",
                            icon: "success",
                            html: "ระบบยกเลิกคำสั่งซื้อเรียบร้อยแล้ว.",
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading();
                                const b = Swal.getHtmlContainer().querySelector("b");
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft();
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                this.cancel_dialog = false
                                this.orderList()
                            }
                        });
                    }
                })
            }
        }
    },

    mounted() {
        this.orderList();
    },
    components: {

    },
    watch: {
        page() {
            this.orderList();
        },
        search() {
            this.page = 1
            this.orderList();
        }
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}


.table-header-order-list-box-data {
    display: grid;
    grid-template-columns: 2% 15% 16% 15% 14% 15% 12% 11%;
    padding: 5px 0px 5px 30px;
}

.table-header-order-list-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-order-list-box-items {
    display: grid;
    grid-template-columns: 5% 15% 16% 14% 14% 14% 13% 9%;
    align-items: center;
}</style>