import { render, staticRenderFns } from "./ProcressingPackagingDetail.vue?vue&type=template&id=14b38f69&scoped=true&"
import script from "./ProcressingPackagingDetail.vue?vue&type=script&lang=js&"
export * from "./ProcressingPackagingDetail.vue?vue&type=script&lang=js&"
import style0 from "./ProcressingPackagingDetail.vue?vue&type=style&index=0&id=14b38f69&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b38f69",
  null
  
)

export default component.exports