<template>
    <div>
        <div class="d-flex">
            <div class="topic_processing_header border_right">สร้างรอบการบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2">การบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
            <div class="topic_processing_content my-2">สร้างรอบการบรรจุภัณฑ์</div>
        </div>
        <div class="mt-3">
            <v-card elevation="2" class="card-radius">
                <v-card-title>
                    <b>ขั้นตอนที่ 7 : ยืนยันการบรรจุภัณฑ์สำเร็จ</b>
                </v-card-title>
                <v-card-text>

                    <!-- ขั้นตอนที่ 1 : สร้างใบสั่งบรรจุภัณฑ์ -->
                    <v-card v-if="firstStepList != {}" elevation="4" class="py-2 my-5" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/process (2).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <v-row>
                                <v-col cols="10">
                                    <span class="ml-3 title-card-text">ขั้นตอนที่ 1 : สร้างใบสั่งบรรจุภัณฑ์</span>
                                </v-col>
                                <v-col v-if="firstStepList.packing_date != null" cols="2" class="d-right-flex">
                                    <v-btn v-if="$route.params.status" color="#88B358" elevation="4"
                                        @click="getPDF(base_url + 'packing/pdf/stepOne/' + $route.params.id)" rounded outlined>
                                        <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบสั่งบรรจุภัณฑ์
                                    </v-btn>
                                </v-col>
                                <v-col v-else cols="2" class="d-right-flex">
                                    <v-btn v-if="$route.params.status" color="#88B358" elevation="4"
                                        @click="getPDF(base_url + 'packing/pdf/stepOne/' + $route.params.id)" rounded outlined disabled>
                                        <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบสั่งบรรจุภัณฑ์
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="ml-15">
                                <div>
                                    <v-row>
                                        <v-col xl="3" lg="3">
                                            <div class="d-flex">
                                                <div class="mr-2">วันที่ใบสั่งบรรจุภัณฑ์ :</div>
                                                <div class="black--text font-weight-bold">{{ firstStepList.packing_date ?
                                                    `${firstStepList.packing_date.split('-')[2]}-${firstStepList.packing_date.split('-')[1]}-${firstStepList.packing_date.split('-')[0]}`
                                                    : '-' }}</div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="">
                                        <v-col xl="12" lg="12">
                                            <b class="mb-n5 black--text">รายละเอียดการบรรจุภัณฑ์สินค้า</b>
                                        </v-col>
                                        <v-col xl="3" lg="3">
                                            <div class="tag-title-step my-3">
                                                <b class="black--text">ล็อตสินค้า: {{firstStepList.product_lot}}</b>
                                            </div>
                                        </v-col>
                                        <v-col xl="3" lg="3">
                                            <div class="my-3">
                                                <b class="black--text">จำนวน : {{ formatPrice(firstStepList.pro_amount) }} กรัม</b>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col xl="12" lg="12">
                                            <div class="divider-table my-2"></div>
                                        </v-col>
                                        <v-col xl="12" lg="12">
                                            <div class="table-header-processing mt-4" style="color: #b1adad">
                                                <div class="table-sub-header-processing">
                                                    ลำดับ
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    รายละเอียด
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    จำนวน
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    ปริมาณที่ใช้บรรจุภัณฑ์
                                                </div>
                                            </div>
                                            <v-card elevation="0" class="grid-processing-items ma-4"
                                                v-for="(detail, index) in firstStepList.size_list" :key="index">
                                                <div>{{ index + 1 }}</div>
                                                <div>{{ detail.pro_name }} {{ detail.pro_size_name }}</div>
                                                <div>{{ detail.qty }} {{ detail.unit_name }}</div>
                                                <div>{{ formatPrice((parseFloat(detail.pro_amount) * parseFloat(detail.qty)) + ((parseFloat(detail.pro_amount) * parseFloat(detail.qty)) * 0.03)) }} กรัม</div>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col xl="12" lg="12">
                                            <div class="divider-table-total my-2"></div>
                                        </v-col>
                                                <v-col cols="5" class="text-center ps-15"></v-col>
                                                <v-col cols="3" class="text-left ps-15" style="font-size:15px; color: black;">รวม</v-col>
                                                <v-col cols="4" class="text-left" style="font-size:15px; color: black;">{{formatPrice(total)}} กรัม</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>

                    <!-- ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ -->
                    <v-card v-if="batchList.find(item => item.material_list.length > 0)" elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/seed-bag.png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <v-row>
                                <v-col cols="10">
                                    <span class="ml-3 title-card-text">ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ</span>
                                </v-col>
                                <v-col cols="2" class="d-right-flex">
                                    <v-btn v-if="$route.params.status" color="#88B358" elevation="4"
                                        @click="getPDF(base_url + 'packing/pdf/stepTwo/' + $route.params.id)" rounded outlined>
                                        <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบเตรียมวัตถุดิบ
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>

                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>

                            <v-row class="ml-15">
                                <v-col v-for="(loop_header, index_header) in batchList" :key="index_header"
                                    class="card-table-radius-sub" cols="12" lg="6" xl="6">
                                    <v-card elevation="2" class="border-card">
                                        <v-row class="ma-1 go-to-card">

                                            <v-col cols="6 flex-center-header">
                                                <div class="tag-title-step-card">
                                                    <b class="black--text">{{ loop_header.pro_size_name }}</b>
                                                </div>
                                                <span> {{ loop_header.pro_name }} </span>
                                            </v-col>
                                            <v-col cols="6 text-right flex-center-header-two">
                                                <span>จำนวน {{ loop_header.qty }} {{
                                                    loop_header.unit_name }}</span>
                                            </v-col>

                                            <v-col cols="12 py-1 col-harder">
                                                <v-divider class="border-main pt-1" dark color=""></v-divider>
                                            </v-col>

                                            <v-col cols="4 pb2">
                                                <span>วัตถุดิบ </span>
                                            </v-col>
                                            <v-col cols="4 pb-2">
                                                <span>เลข Lot </span>
                                            </v-col>
                                            <v-col cols="4 px-0 pb-2 ">
                                                <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                                            </v-col>

                                        </v-row>

                                        <v-row class="ma-1 " v-for="(loop_sub, index) in loop_header.material_list"
                                            :key="index">
                                            <v-col cols="4 pt-0 ">
                                                <span> {{ loop_sub.mat_name }}</span>
                                            </v-col>

                                            <v-col cols="8  pt-0">
                                                <v-row v-for="detail in loop_sub.stock_list" :key="detail">
                                                    <v-col cols="6">
                                                        <div>{{ detail.material_lot }}</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div>{{ detail.quantity_cut }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <!-- ขั้นตอนที่ 3 : บันทึกผลการบรรจุภัณฑ์ -->
                    <v-card v-if="step3.find(item => item.material_list.length > 0)" elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/confirm.png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <v-row>
                                <v-col cols="10">
                                    <span class="ml-3 title-card-text">ขั้นตอนที่ 3 : บันทึกผลการบรรจุภัณฑ์</span>
                                </v-col>
                                <v-col cols="2" class="d-right-flex">
                                    <v-btn v-if="$route.params.status" color="#88B358" elevation="4"
                                        @click="getPDF(base_url + 'packing/pdf/stepThree/' + $route.params.id)" rounded outlined>
                                        <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบรายงานการบรรจุภัณฑ์
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>

                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>

                            <v-row class="ml-15">
                                <v-col v-for="(loop_header, index_header) in step3" :key="index_header"
                                    class="card-table-radius-sub" cols="12" lg="6" xl="6">
                                    <v-card elevation="2" class="border-card">
                                        <v-row class="ma-1 go-to-card">

                                            <v-col cols="6 flex-center-header">
                                                <div class="tag-title-step-card">
                                                    <b class="black--text">{{ loop_header.pro_size_name }}</b>
                                                </div>
                                                <span> {{ loop_header.pro_name }} </span>
                                            </v-col>
                                            <v-col cols="6 text-right flex-center-header-two">
                                                <span>จำนวน {{ loop_header.qty }} {{
                                                    loop_header.unit_name }}</span>
                                            </v-col>

                                            <v-col cols="12 py-1 col-harder">
                                                <v-divider class="border-main pt-1" dark color=""></v-divider>
                                            </v-col>

                                            <v-col cols="4 pb2">
                                                <span>วัตถุดิบ </span>
                                            </v-col>
                                            <v-col cols="4 pb-2">
                                                <span>เลข Lot </span>
                                            </v-col>
                                            <v-col cols="4 px-0 pb-2 ">
                                                <span>วัตถุดิบใช้ในการบรรจุภัณฑ์(ชิ้น)</span>
                                            </v-col>

                                        </v-row>

                                        <v-row class="ma-1 " v-for="(loop_sub, index) in loop_header.material_list"
                                            :key="index">
                                            <v-col cols="4 pt-0 ">
                                                <span> {{ loop_sub.mat_name }}</span>
                                            </v-col>

                                            <v-col cols="8  pt-0">
                                                <v-row v-for="detail in loop_sub.stock_list" :key="detail">
                                                    <v-col cols="6">
                                                        <div>{{ detail.material_lot }}</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div>{{ detail.quantity_cut }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <!-- ขั้นตอนที่ 4 : สินค้า FG คงเหลือและบรรจุภัณฑ์ที่เสียหาย -->
                    <v-card v-if="timeList != {}" elevation="4" class="py-2 px-3" style="border-radius:20px;">

                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/evaluation.png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 4 : สินค้า FG
                                คงเหลือและบรรจุภัณฑ์ที่เสียหาย</span>
                        </v-card-title>

                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <v-row class="ma-4 ml-15">
                                <v-col xl="12" lg="12">
                                    <div class="black--text font-weight-bold ma-2">
                                        จำนวนสินค้า FG ที่คงเหลือจากการบรรจุภัณฑ์ : {{ formatPrice(timeList.product_qty_remain) }} กรัม
                                    </div>
                                    <v-divider class="mb-3" dark></v-divider>
                                    <div>
                                        <div class="tag-title-step my-3">
                                            <b class="black--text">วัตถุดิบที่เสียหายหรือทิ้งไปจากการบรรจุภัณฑ์รอบนี้</b>
                                        </div>
                                        <div class="divider-table my-2" />
                                        <div>
                                            <div class="table-header-processing-details mt-4" style="color: #b1adad">
                                                <div class="table-sub-header-processing">No.</div>
                                                <div class="table-sub-header-processing">ชื่อวัตถุดิบ</div>
                                                <div class="table-sub-header-processing">เลข Lot</div>
                                                <div class="table-sub-header-processing">
                                                    จำนวน{{ timeList.material_drop_lsit.length == 0 ? '' :
                                                        timeList.material_drop_lsit[0].unit_name }}</div>
                                            </div>
                                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                                            <div v-for="(item, i) in timeList.material_drop_lsit" :key="item">
                                                <div class="grid-processing-items-details ml-3">
                                                    <div class="black--text font-weight-bold py-1">{{ i + 1 }}</div>
                                                    <div class="black--text font-weight-bold">{{ item.mat_name }}</div>
                                                    <div class="black--text font-weight-bold">{{ item.material_lot }}</div>
                                                    <div class="black--text font-weight-bold">{{ item.drop_qty }}
                                                        {{ item.unit_name }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>

                    <!-- ขั้นตอนที่ 5 : การตรวจสอบบรรจุภัณฑ์ -->
                    <v-card v-if="dataList.length > 0" elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/shield(2).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 5 : การตรวจสอบบรรจุภัณฑ์</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="ml-15">
                                <div class="table-header-processing mt-6" style="color: #b1adad">
                                    <div class="table-sub-header-processing">ชื่อเกณฑ์</div>
                                    <div class="table-sub-header-processing">เกณฑ์คุณภาพ</div>
                                    <div class="table-sub-header-processing">Value</div>
                                </div>
                                <v-card elevation="4" class="my-5 py-2" style="border-radius:20px"
                                    v-for="(item, i) in dataList" :key="item">
                                    <div class="table-header-processing py-4 pb-0">
                                        <div class="table-sub-header-processing black--text font-weight-bold">{{
                                            item.fq_name }}</div>
                                    </div>
                                    <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                                    <div class="grid-processing-items ma-5 pl-3 pr-3"
                                        v-for="(detail, index) in item.quality_list" :key="index">
                                        <div class="black--text font-weight-bold">{{ detail.fq_list_name }}</div>
                                        <div class="black--text font-weight-bold">{{ detail.fq_list_guide }}</div>
                                        <div class="pl-4">
                                            <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == null"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 1)"
                                                    color="#88B358">mdi-checkbox-blank-circle-outline</v-icon></p>
                                            <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 0 && detail.fq_value_boolean == 0"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 1)"
                                                    color="#F28080">mdi-close-circle</v-icon></p>
                                            <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 0 && detail.fq_value_boolean == 1"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 0)"
                                                    color="#88B358">mdi-check-circle</v-icon></p>
                                            <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == 0"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 1)"
                                                    color="#F28080">mdi-close-circle</v-icon></p>
                                            <p v-if="detail.fq_list_type == 0 && detail.fq_value_status == 1 && detail.fq_value_boolean == 1"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 0)"
                                                    color="#88B358">mdi-check-circle</v-icon></p>
                                            <p v-if="detail.fq_list_type == 1" class=""
                                                color="#FFBC15" >{{ detail.value }}</p>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </v-card-text>
                    </v-card>

                    <v-card v-if="qualityList.length > 0" elevation="4" class="py-2 my-10" style="border-radius:20px">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/quality (1).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">ขั้นตอนที่ 6 : การตรวจสอบสินค้าที่บรรจุภัณฑ์สำเร็จ</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="ml-15">
                                <div class="table-header-processing-step6 mt-6" style="color: #b1adad">
                                    <div class="table-sub-header-processing">ชื่อเกณฑ์ (English)</div>
                                    <div class="table-sub-header-processing">ชื่อเกณฑ์ (ภาษาไทย)</div>
                                    <div class="table-sub-header-processing">Unit</div>
                                    <div class="table-sub-header-processing ml-3" style="color:#ffb98a">Aim</div>
                                    <div class="table-sub-header-processing ml-n1" style="color:#13b65d">Green</div>
                                    <div class="table-sub-header-processing ml-n2" style="color:#ddbb55">Yellow</div>
                                    <div class="table-sub-header-processing ml-n3" style="color:#c00000">Red</div>
                                    <div class="table-sub-header-processing ml-n3">Value</div>
                                </div>
                                <v-card elevation="4" class="my-10" style="border-radius:20px"
                                    v-for="(item, i) in qualityList" :key="item">
                                    <div class="table-header-processing-step6 py-4 pb-0">
                                        <div class="table-sub-header-processing black--text font-weight-bold">{{
                                            item.pq_name_eng }}</div>
                                        <div class="table-sub-header-processing black--text font-weight-bold">{{
                                            item.pq_name_thai }}</div>
                                    </div>
                                    <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                                    <div class="grid-processing-items-step6 mt-0 mb-n4 pl-3 pr-3"
                                        v-for="(detail, index) in item.quality_list" :key="index">
                                        <div class="black--text font-weight-bold py-5">{{ detail.pq_list_eng }}</div>
                                        <div class="black--text font-weight-bold">{{ detail.pq_list_thai }}</div>
                                        <div>{{ detail.unit }}</div>
                                        <div class="ml-3">{{ detail.pq_list_aim }}</div>
                                        <div>{{ detail.pq_list_green }}</div>
                                        <div>{{ detail.pq_list_yellow }}</div>
                                        <div>{{ detail.pq_list_red }}</div>
                                        <div>
                                            <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == null"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 1)"
                                                    color="#88B358">mdi-checkbox-blank-circle-outline</v-icon></p>
                                            <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 0 && detail.pq_value_boolean == 0"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 1)"
                                                    color="#F28080">mdi-close-circle</v-icon></p>
                                            <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 0 && detail.pq_value_boolean == 1"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 0)"
                                                    color="#88B358">mdi-check-circle</v-icon></p>
                                            <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == 0"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 1)"
                                                    color="#F28080">mdi-close-circle</v-icon></p>
                                            <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == 1"
                                                class="mb-0"><v-icon @click="getcheck(i, index, 0)"
                                                    color="#88B358">mdi-check-circle</v-icon></p>
                                            <p v-if="detail.pq_list_type == 1" 
                                                color="#FFBC15" label="value"> {{ detail.value }}</p>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="4" class="py-2 my-10" style="border-radius:20px" v-if="step7.user_edit != null">
                        <div class="header-png">
                            <v-avatar color="#34230F" size="36">
                                <img class="icon-image-logo" src="@/assets/approved(2).png">
                            </v-avatar>
                        </div>
                        <v-card-title>
                            <span class="ml-3 title-card-text">รายละเอียดขั้นตอนที่ 7 : ยืนยันการผลิตสำเร็จ</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <div class="ml-15">
                                <v-row>
                                    <v-col xl="4" lg="4">
                                        <div class="d-flex">
                                            <div class="ml-2">วันที่ดำเนินการ :</div>
                                            <div class="black--text">{{ step7.user_edit }}</div>
                                        </div>
                                    </v-col>
                                    <v-col xl="4" lg="4">
                                        <div class="d-flex">
                                            <div class="ml-2">ผู้ดำเนินการ :</div>
                                            <div class="black--text">
                                                {{
                                                    `${step7.success_date.split('/')[0]}-${step7.success_date.split('/')[1]}-${step7.success_date.split('/')[2]}`
                                                }}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-card>

                </v-card-text>
                <div class="mt-3 text-center">
                    <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="modal = true"
                        v-if="!$route.params.status">
                        <v-icon left>mdi-content-save</v-icon> ยืนยันการบรรจุภัณฑ์สำเร็จ
                    </v-btn>
                </div>
            </v-card>
        </div>

        <v-dialog v-model="modal" transition="dialog-top-transition" max-width="400" style="border-radius:20px">
            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="modal = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <div class="d-flex flex-column align-center">
                        <img class="icon-image mb-3" src="@/assets/buying.png">
                    </div>
                    <div class="text-center">
                        <h1 class="black--text">ยืนยันการบรรจุภัณฑ์สำเร็จ</h1>
                        <div class="my-2">คุณต้องการยืนยันการบรรจุภัณฑ์ใช่หรือไม่</div>
                    </div>
                    <div class="mt-3 text-center">
                        <v-btn class="white--text" color="#88B358" elevation="4" rounded large @click="saveData()">
                            <v-icon left>mdi-content-save</v-icon> ตกลง
                        </v-btn>
                        <div class="ma-4" style="cursor: pointer">ยกเลิก</div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
  
<script>
import Swal from "sweetalert2";
import process_package from '@/api/process_package/package.js'
import { formatPrice } from "@/helper/handler";
export default {
    name: "ProductionConfirm",
    data() {
        return {
            modal: false,
            firstStepList: [],
            batchList: [],
            timeList: {},
            dataList: [],
            secondList: [
                {
                    eng: 'Appearance',
                    th: 'ข้าวเป็นเม็ดสวยไม่แฉะ',
                    unit: '-',
                    aim: '-',
                    green: '-',
                    yellow: '-',
                    red: '-',
                    value: true,
                },
                {
                    eng: 'Texture',
                    th: 'ไม่เละไม่เป็นไต',
                    unit: '-',
                    aim: '-',
                    green: '-',
                    yellow: '-',
                    red: '-',
                    value: false,
                },
                {
                    eng: 'Flavor',
                    th: 'ไม่มีกลิ่นเหม็นอับ',
                    unit: '-',
                    aim: '-',
                    green: '-',
                    yellow: '-',
                    red: '-',
                    value: true,
                },
            ],
            qualityList: [],
            solarList: [
                {
                    name: 'Temp',
                    detail: '45.0 - 70.0 C',
                    value: 'ok',
                },
                {
                    name: 'DateStart',
                    detail: '-',
                    value: 'Pass',

                },
                {
                    name: 'TimeStart',
                    detail: '-',
                    value: 'ไม่รั่ว',

                },
                {
                    name: 'DateEnd',
                    detail: '-',
                    value: '500.2',

                },
                {
                    name: 'TimeEnd',
                    detail: '-',
                    value: '500',
                },
                {
                    name: 'Time',
                    detail: '>6.0 hr',
                    value: '500',

                },
                {
                    name: 'Moisture : Dried Rice',
                    detail: '9.0 - 11.0',
                    value: '689',

                },
            ],
            mock_header: [],
            step7: {},
            base_url: process.env.VUE_APP_BASE_URL,
            total: 0
        }
    },
    methods: {
        getPDF(text) {
      window.open(text, '_blank');
    },
        linkTo() {
            this.$router.push('/prm/processing_materials')
        },
        formatPrice(number) {
      return formatPrice(number);
    },
      async getDetail_step1() {
            let id = parseFloat(this.$route.params.id);
            this.loading_page = true;
           await process_package.getDetail_step1(id).then((response) => {
                if (response.status == 502) {
                    window.location.reload()
                }
                if (response.data.successful === true) {
                    this.firstStepList = response.data.step_1;
                    this.batchList = response.data.step_2;
                    this.step3 = response.data.step_3;
                    this.timeList = response.data.step_4;
                    this.mock_header = response.data.step_5;
                    this.dataList = response.data.step_5;
                    this.qualityList = response.data.step_6;
                    this.step7 = response.data.step_7;
                    this.loading_page = false;

                    // this.getPrepare()
                    // alert('A')
                }
            }).catch((error) => {
                if (error.response.status === 502) {
                    window.location.reload()
                }
            })
        },
        saveData() {
            this.loading_page = true;
            let id = parseFloat(this.$route.params.id);
            this.loading_page = true;
            process_package.saveStep6(id).then((response) => {
                if (response.data.successful === true) {
                    let timerInterval;
                    Swal.fire({
                        title: "ทำรายการเสร็จสิ้น",
                        icon: "success",
                        html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector("b");
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            window.location.href = "/pac/processing-packaging";
                        }
                    });
                    this.loading_page = false;
                }
            })
        },
       async total_price(){
            let sum = 0
           await this.firstStepList.size_list.forEach(detail => {
                sum += (parseFloat(detail.pro_amount) * parseFloat(detail.qty == '' ? 0 : detail.qty)) + ((parseFloat(detail.pro_amount) * parseFloat(detail.qty == '' ? 0 : detail.qty)) * 0.03)
            })
            this.total = sum
        },
       async getMounted(){
           await this.getDetail_step1();
           await this.total_price();
        }
    },
    mounted() {
        this.getMounted();
    }
}
</script>
  
<style scoped>
.border_right {
    border-right: 2px solid #000000;
    padding-right: 10px;
}

.topic_processing_header {
    font-size: 24px;
    color: #000000;
}

.topic_processing_content {
    color: #7E7E7E;
    padding-left: 10px;
}

.header-png {
    position: absolute;
    top: -15px;
    left: 20px;
}

.icon-image {
    width: 120px;
    height: 120px;
    text-align: center;
}

.icon-image-logo {
    width: 20px;
    height: 24px;
    vertical-align: center;
}

.tag-title-step {
    background-color: #FFF;
    border: 1px solid #000000;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.tag-title-step-table {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.table-header-processing {
    display: grid;
    grid-template-columns: 21% 23% 22% 20% 
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 21% 24% 23% 20%;
    align-items: baseline;
}

.table-header-processing-step6 {
    display: grid;
    grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
}

.table-header-processing-second {
    display: grid;
    grid-template-columns: 5% 20% 25% 25% 25%;
}

.table-header-processing-details {
    display: grid;
    grid-template-columns: 10% 25% 25% 40%;
}

.table-header-processing-steps {
    display: grid;
    grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
}

.table-header-processing-recheck {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}



.grid-processing-items-step6 {
    display: grid;
    grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
    align-items: baseline;
}

.grid-processing-items-second {
    display: grid;
    grid-template-columns: 5% 20% 25% 25% 25%;
    align-items: baseline;
}

.grid-processing-items-details {
    display: grid;
    grid-template-columns: 10% 25% 25% 40%;
    align-items: baseline;
}

.grid-processing-items-steps {
    display: grid;
    grid-template-columns: 15% 20% 5% 10% 10% 10% 10% 20%;
    align-items: baseline;
}

.grid-processing-items-recheck {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    align-items: baseline;
}

.summarize-header {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
    inline-size: fit-content;
}

.summarize {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
}

.divider-table {
    border: 3px solid #34230F;
    border-radius: 3px;
}
.divider-table-total {
    border: 1px solid #34230F;
    border-radius: 3px;
}

/* Part 1 */
.w-border-header-first {
    background: #ffbc15;
    border-top: 15px solid #ffbc15;
    border-radius: 36px;
    width: 120%;
}

.inline-box-specific {
    background: #fff8e8;
    border-top: 1px solid #ffbc15;
    border-bottom: 1px solid #ffbc15;
}

.w-border-header-sub-last {
    background: #34230F;
    border: 7px solid #34230F;
    border-radius: 36px;
    width: auto;
}

.card-main-table {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding: 15px;
    width: 120%;
    height: 99%;
}

.card-main-table .header-card {}

.card-main-table .content-card {}

.card-main-table .footer-card {
    position: absolute;
    bottom: 0;
    width: 85%;
}

.card-sub-table-last {
    border: 1px solid #34230F;
    border-radius: 15px;
    padding: 15px;
    max-width: 100%;
    width: 100%;
    height: 99%;
    transition: 0.5s;
}

.card-sub-table-last:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-last {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu-last .last {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-right: 3%;
}

.scroll-menu-last section:first-child {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: sticky;
    left: 0;
    margin-right: 6%;
    background: #FFFFFF;
    z-index: 1;
}

/* Part 1 */

/* Part 2 */
.card-main-table-time {
    border: 1px solid #ffbc15;
    border-radius: 15px;
    padding-bottom: 15px;
    width: 120%;
    height: 99%;
}

.card-sub-table-time {
    border: 1px solid #34230F;
    border-radius: 15px;
    max-width: 100%;
    width: 200px;
    height: 99%;
    transition: 0.5s;
}

.card-sub-table-time:hover {
    transition: 0.5s;
    box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-time {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
}

.scroll-menu-time .time {
    display: inline-block;
    vertical-align: top;
    margin-right: 3%;
}

.scroll-menu-time section:first-child {
    display: inline-block;
    position: sticky;
    left: 0;
    bottom: auto;
    margin-right: 6%;
    background: #FFFFFF;
    z-index: 1;
}

.inline-box-specific-time {
    background: #d6d3cf;
}

.footer-total {
    position: absolute;
    bottom: 1%;
    border-top: 2px solid #ffbc15;
    background-color: #fff2d0;
    padding: 11px;
    width: 120%;
}

.footer-gams {
    background-color: #000000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

/* Part 2 */

.text-green {
    color: #88B358;
}


.flex-center-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-header-two {
    display: flex;
    align-items: center;
    justify-content: end;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.flex-center-header>.tag-title-step-card {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.flex-center-header>.tag-title-step-card {
    border: 1px solid #34230F;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.card-table-radius-sub:nth-child(even)>.border-card {
    border-radius: 20px;
    border: 1px solid #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card {
    border-radius: 20px;
    border: 1px solid #34230F;
}

.card-table-radius-sub:nth-child(even)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #FFBC15;
}

.card-table-radius-sub:nth-child(odd)>.border-card>.go-to-card>.col-harder>.border-main {
    background-color: #34230F;
}
.d-right-flex{
        display: flex !important;
    justify-content: flex-end;
}
</style>