<template>
    <div>
        <SettingRawMaterialAddPage />
    </div>
</template>
  
<script>
import SettingRawMaterialAddPage from '@/components/stock/setting_stock/SettingRawMaterialAddComponent'
export default {
    name: 'SettingRawMaterialAdd',
    components: {
        SettingRawMaterialAddPage,
    }
}
</script>