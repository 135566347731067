<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular
      indeterminate
      size="70"
      :width="8"
      color="#FFBC15"
    ></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div class="responsive-content" v-else>
    <navigationmenu />
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <div class="title_page">
          <h4 class="breadcrumb_title_page">รายละเอียดรายการนำเข้า</h4>
          <v-breadcrumbs :items="sub_menu">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card elevation="4" class="card-radius">
          <v-card-title>
            <b>รายละเอียดรายการนำเข้ารหัส {{ importDetail.im_code }}</b>
          </v-card-title>
          <v-card-text>
            <v-card elevation="4" style="border-radius: 20px" class="mt-3">
              <v-card-title class="d-flex align-items-center">
                <v-avatar class="top-to-card" color="#34230F" size="32">
                  <v-icon dark> mdi-file-document </v-icon>
                </v-avatar>
                <span class="ml-3 mt-3 text-font-title"
                  >ข้อมูลการนำเข้าจากใบสั่งซื้อ</span
                >
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <v-row>
                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <div class="ml-3 f-14">
                      ผู้ดำเนินการ :
                      <span class="f-14 f-black">{{ importDetail.name }}</span>
                    </div>
                  </v-col>
                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <div class="ml-3 f-14">
                      วันที่นำเข้า :
                      <span class="f-14 f-black">{{
                       `${ importDetail.updated_at.split('-')[2].split(' ')[0]}-${ importDetail.updated_at.split('-')[1]}-${ importDetail.updated_at.split('-')[0]}`
                      }}</span>
                    </div>
                  </v-col>

                  <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <div class="ml-3 f-14">
                      วันที่ตรวจสอบ :
                      <span class="f-14 f-black">{{
                       `${ importDetail.updated_at.split('-')[2].split(' ')[0]}-${ importDetail.updated_at.split('-')[1]}-${ importDetail.updated_at.split('-')[0]}`
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-card elevation="4" style="border-radius: 20px" class="mt-3">
              <v-card-title
                class="d-flex justify-space-between align-items-center"
              >
                <div>
                  <v-avatar class="top-to-card" color="#34230F" size="32">
                    <v-icon dark> mdi-package-variant-closed </v-icon>
                  </v-avatar>
                  <span class="ml-3 text-font-title"
                    >ข้อมูลสินค้านำเข้าคลัง</span
                  >
                </div>
                <div>
                  <h3 style="color: #88b358">รวมเงินทั้งสิ้น {{formatPrice(parseFloat(importDetail.total_price))}}</h3>
                </div>
              </v-card-title>
                  <v-card color="basil" flat>
                    <v-card-text>
                      <div
                        class="table-header-import-data display-pc mt-3"
                        style="color: #b1adad"
                      >
                        <div class="table-header-import-data-text">
                          ชื่อสินค้า
                        </div>
                        <div class="table-header-import-data-text">
                          รายละเอียดการตรวจสอบ
                        </div>
                        <div class="table-header-import-data-text text-center ml-0">Value</div>
                        <div class="table-header-import-data-text">
                          หมายเหตุ/รายละเอียดการแก้ไข
                        </div>
                      </div>
                    </v-card-text>
                    <div v-for="detail in data" :key="detail">
                    <v-card-title
                      class="padding-text-title align-items-center"
                      style="
                        background: rgba(255, 188, 21, 0.1);
                        border: 1px solid #ffbc15;
                        border-radius: 20px;
                        margin: 15px;
                      "
                    >
                      <span class="ml-3 text-font-title" style="font-size: 14px"
                        >{{detail.pro_type_name}}</span
                      >
                    </v-card-title>
                    <v-card-text>
                      <v-card elevation="0" class="card-table-radius" v-for="item in detail.product_list" :key="item">
                        <div class="grid-import-step3-items">
                          <div style="padding: 10px">
                            <p style="color: #000; font-weight: 600">
                              {{item.pro_name}} {{item.pro_size_name}} (Lot : {{item.product_lot}})
                            </p>
                            <p style="margin-left: 20px">
                              จำนวน :
                              <span style="color: #000; font-weight: 600"
                                >{{formatPrice(item.qty)}} {{item.unit_name}}</span>
                            </p>
                            <p style="margin-left: 20px">
                              ราคาต่อหน่วย :
                              <span style="color: #000; font-weight: 600"
                                >{{formatPrice(item.price)}} บาท</span
                              >
                            </p>
                            <p style="margin-left: 20px">
                              ราคารวม :
                              <span style="color: #000; font-weight: 600"
                                >{{formatPrice(item.total_price)}} บาท</span
                              >
                            </p>
                            <p style="margin-left: 20px">
                              วันหมดอายุ :
                              <span style="color: #000; font-weight: 600"
                                >{{`${item.lot_exp.split('-')[2]}-${item.lot_exp.split('-')[1]}-${item.lot_exp.split('-')[0]}`}}</span
                              >
                            </p>
                          </div>

                          <v-row>
                            <v-col cols="12">

                              <v-row v-for="query in item.pq_list" :key="query">
                                <v-col cols="6 py-0">
                                  <div style="padding: 10px">
                                    <p>{{query.pq_list_thai}}</p>
                                  </div>
                                </v-col>
                                <v-col cols="6 py-0 text-center">
                                  <div style="padding: 10px">
                                    <p>
                                      <v-icon class="ms-5" v-if="query.pq_list_type == 0 && query.value == '1'" color="#88B358">mdi-check-circle</v-icon>
                                      <v-icon class="ms-5" v-if="query.pq_list_type == 0 && query.value == '0'" color="#F28080">mdi-close-circle</v-icon>
                                      <span class="ms-5" v-if="query.pq_list_type == 1">{{query.value}}</span>
                                    </p>
                                  </div>
                                </v-col>
                              </v-row>

                            </v-col>
                          </v-row>
                          
                          <div style="padding: 10px; text-align:center;">{{item.note}}</div>
                        </div>
                      </v-card>                      
                    </v-card-text>
                    </div>
                     <div class="d-flex justify-end">
                        <div
                          class="d-flex justify-space-between ma-3"
                          style="
                            padding: 20px;
                            background: rgba(136, 179, 88, 0.15);
                            border: 1px solid #88b358;
                            border-radius: 15px;
                          "
                        >
                          <h2 style="color: #000; margin-right: 100px">
                            รวมเงิน
                          </h2>
                          <h2 style="color: #88b358">{{formatPrice(parseFloat(importDetail.total_price))}}</h2>
                        </div>
                      </div>
                  </v-card>             
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { dateFormatNoSeconds } from "@/helper/dateformats";
import { isTelephone, formatPrice } from "@/helper/handler";
import product from "@/api/import_product/productDetail.js";
export default {
  data() {
    return {
      loading_page: false,
      stepImport: 1,
      sub_menu: [
        {
          text: "คลัง",
          disabled: false,
        },
        {
          text: "นำเข้าสินค้า",
          disabled: false,
          href: '/stock/product-import',
          
        },
        {
          text: "รายละเอียดรายการนำเข้า",
          disabled: false,
        },
      ],
      unit: [
        { id: 1, name: "แพ็ค 5" },
        { id: 2, name: "แพ็ค 10" },
        { id: 3, name: "แพ็ค 15" },
      ],
      tab: null,
      items: [
        "PO20220321-001",
        "PO20220321-002",
        "PO20220321-003",
        "PO20220321-004",
      ],
      supplier: ["สยามแม็คโคร", "นิทานการข้าว"],
      menu: false,
      modal: false,
      menu2: false,
      toggle_exclusive: 2,
      page: 1,
      add_dialog: false,
      edit_dialog: false,
      detail_dialog: false,
      importDetail: {
        id: null,
        im_code: null,
        total_price: null,
        updated_at: null,
        name: null,
      },
      data: [],
      header: [],
      check_tab: 0,
      total:[],
      test:{}
    };
  },

  methods: {
    dateFormatNoSeconds(dateTime) {
      const date = dateFormatNoSeconds(dateTime).split(" ");
      return date[0];
    },
    link(link) {
      this.$router.push(link);
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    formatPrice (number) {
      return formatPrice(number);
    },
    async detailImportpoList() {
      this.loading_page = true;
      this.importDetail = null;
      this.data = [];

      const response = await product.detailImportpoList(
        this.$route.params.id
      );
      if (response.data.successful == true) {
       if(this.$route.params.status == 0){
         this.importDetail = {
          id: response.data.importDetails.id,
          im_code: response.data.importDetails.im_code,
          total_price: response.data.importDetails.total_price,
          updated_at: response.data.importDetails.updated_at,
          name: response.data.importDetails.name,
        };
        let list = response.data.importDetails.list;
        this.test = list;
        let index_group = 0;
                   for(let i = 0; i < list.length; i++){
                         let type = list[i]
                        for(let j = 0; j < type.material_list.length; j++){
                            let material = type.material_list[j]
                            let check_data = this.data.find(o => o.sup_name === material.sup_name)
                            if(check_data != null){
                               this.data[check_data.index_group].List.push({
                             id: material.id,
                            im_id: material.im_id,
                            material_lot: material.material_lot,
                            material_id: material.material_id,
                            mat_name: material.mat_name,
                            materials_type_id: material.materials_type_id,
                            mat_type_name: material.mat_type_name,
                            sup_id: material.sup_id,
                            sup_name: material.sup_name,
                            qty: material.qty,
                            unit_id: material.unit_id,
                            unit_defualt_id: 1,
                            unit_peritem: material.unit_peritem,
                            unit_gram: material.unit_gram,
                            unit_main_gram: material.unit_main_gram,
                            price: material.price,
                            material_amount: material.material_amount,
                            cost_pergram: material.cost_pergram,
                            total_price: material.total_price,
                            lot_exp: material.lot_exp,
                            note: material.note,
                            unit_main_id: material.unit_main_id,
                            unit_main_name: material.unit_main_name,
                            unit_other_id: material.unit_other_id,
                            unit_other_name: material.unit_other_name,
                            unit_list: [{id: 1, name: material.unit_main_name},{id: 2, name: material.unit_other_name}],
                            unit_other_qty: material.unit_other_qty,
                            pq_list: material.pq_list
                        })
                            }else{
                              let check = this.data.find(o => o.sup_name === material.sup_name)
                              if(check == null){
                                            this.data.push({
                        mat_type_name: type.mat_type_name,
                        sup_name: material.sup_name,
                        materials_type_id: type.materials_type_id,
                         index_group: index_group,
                        List:[{
                             id: material.id,
                            im_id: material.im_id,
                            material_lot: material.material_lot,
                            material_id: material.material_id,
                            mat_name: material.mat_name,
                            materials_type_id: material.materials_type_id,
                            mat_type_name: material.mat_type_name,
                            sup_id: material.sup_id,
                            sup_name: material.sup_name,
                            qty: material.qty,
                            unit_id: material.unit_id,
                            unit_defualt_id: 1,
                            unit_peritem: material.unit_peritem,
                            unit_gram: material.unit_gram,
                            unit_main_gram: material.unit_main_gram,
                            price: material.price,
                            material_amount: material.material_amount,
                            cost_pergram: material.cost_pergram,
                            total_price: material.total_price,
                            lot_exp: material.lot_exp,
                            note: material.note,
                            unit_main_id: material.unit_main_id,
                            unit_main_name: material.unit_main_name,
                            unit_other_id: material.unit_other_id,
                            unit_other_name: material.unit_other_name,
                            unit_list: [{id: 1, name: material.unit_main_name},{id: 2, name: material.unit_other_name}],
                            unit_other_qty: material.unit_other_qty,
                            pq_list: material.pq_list
                        }]
                    });  
                     this.header.push({header: material.sup_name ,index_group: index_group});
                     index_group++
                              }else{
                                 this.data.push({
                        mat_type_name: type.mat_type_name,
                        sup_name: material.sup_name,
                        materials_type_id: type.materials_type_id,
                         index_group: check.index_group,
                        List:[{
                             id: material.id,
                            im_id: material.im_id,
                            material_lot: material.material_lot,
                            material_id: material.material_id,
                            mat_name: material.mat_name,
                            materials_type_id: material.materials_type_id,
                            mat_type_name: material.mat_type_name,
                            sup_id: material.sup_id,
                            sup_name: material.sup_name,
                            qty: material.qty,
                            unit_id: material.unit_id,
                            unit_defualt_id: 1,
                            unit_peritem: material.unit_peritem,
                            unit_gram: material.unit_gram,
                            unit_main_gram: material.unit_main_gram,
                            price: material.price,
                            material_amount: material.material_amount,
                            cost_pergram: material.cost_pergram,
                            total_price: material.total_price,
                            lot_exp: material.lot_exp,
                            note: material.note,
                            unit_main_id: material.unit_main_id,
                            unit_main_name: material.unit_main_name,
                            unit_other_id: material.unit_other_id,
                            unit_other_name: material.unit_other_name,
                            unit_list: [{id: 1, name: material.unit_main_name},{id: 2, name: material.unit_other_name}],
                            unit_other_qty: material.unit_other_qty,
                            pq_list: material.pq_list
                        }]
                    });  
                    this.header[check.index_group] = {header: material.sup_name ,index_group: check.index_group};
                              }
                            }
                }
              }
              for(let i = 0; i < this.data.length; i++){
                this.total[i] = 0
                for(let x = 0; x < this.data[i].List.length; x++){
                  this.total[this.data[i].index_group] += parseFloat(this.data[i].List[x].total_price)
                }
              }
        this.loading_page = false;
       }else{
        this.importDetail = {
          id: response.data.importDetails.id,
          im_code: response.data.importDetails.im_code,
          total_price: response.data.importDetails.total_price,
          updated_at: response.data.importDetails.updated_at,
          name: response.data.importDetails.name,
        };
        this.data = response.data.importDetails.list;
        this.loading_page = false;
       }
      }
    },
  },
  mounted() {
    this.detailImportpoList();
  },
};
</script>
<style scoped>
.input-group {
  display: flex;
  gap: 10px;
}

.form-select {
  width: 150px;
}

.form-control {
  flex: 1;
}

.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.padding-text-title {
  padding: 8px !important;
}

.team-text-title-card {
  font-weight: 600;
  font-size: 20px;
  color: #34230f;
}

.tab-button.active {
  border-radius: 15px;
  font-size: 16px;
}

.tab-button-disable {
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.checkbox-access {
  margin-top: 0;
  padding-top: 0;
}

.top-to-card {
  position: absolute;
  top: -18px;
}

.btn-reset-pasword {
  font-size: 18px;
  margin-top: 20px;
  background-color: #88b358 !important;
  color: #ffffff;
}

.btn-delete-data {
  font-size: 18px;
  margin-top: 20px;
  background-color: #f28080 !important;
  color: #ffffff;
}

.table-header-import-box-data {
  display: grid;
  grid-template-columns: 30% 25% 20% 25%;
  padding: 5px 0px 5px 30px;
  color: #686868 !important;
}

.table-header-import-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-items {
  display: grid;
  grid-template-columns: 30% 25% 20% 25%;
  align-items: center;
}

.table-header-import-data {
  display: grid;
  grid-template-columns: 30% 25% 20% 25%;

  color: #686868 !important;
}

.table-header-import-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-items {
  display: grid;
  grid-template-columns: 30% 25% 20% 25%;
  align-items: center;
}
.table-header-import-step3-data {
  display: grid;
  grid-template-columns: 30% 25% 20% 25%;
  padding: 5px 0px 5px 30px;
  color: #686868 !important;
}

.table-header-import-step3-data-text {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-import-step3-items {
  display: grid;
  grid-template-columns: 32% 43% 23%;
  align-items: center;
  padding:30px;
}
</style>