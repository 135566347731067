<template>
    <div>
      <ProductImportPurchaseOrderDetailPage/>
    </div>
  </template>
  
  <script>
  import ProductImportPurchaseOrderDetailPage from '@/components/stock/product_import/ProductImportPurchaseOrderDetailComponent'
  export default {
    name: 'productimagepurchaseorderdetail',
    components:{
      ProductImportPurchaseOrderDetailPage,
    }
  }
  </script>