import CustomerList from '@/views/sale/Customer.vue'
import OrderList from '@/views/sale/OrderList.vue'
import OrderAdd from '@/views/sale/OrderAdd.vue'
import OrderDetail from '@/views/sale/OrderDetail.vue'
import OrderEdit from '@/views/sale/OrderEdit.vue'
import OrderShippingDetail from '@/views/sale/OrderShippingDetail.vue'

export default [
    {
        path: '/sale/customer-list',
        name: 'customer',
        component: CustomerList,
    },
    {
        path: '/sale/order-list',
        name: 'orderlist',
        component: OrderList,
    },
    {
        path: '/sale/order-add',
        name: 'orderadd',
        component: OrderAdd,
    },
    {
        path: '/sale/order-edit/:id',
        name: 'orderedit',
        component: OrderEdit,
    },
    {
        path: '/sale/order-detail/:id',
        name: 'orderdetail',
        component: OrderDetail,
    },
    {
        path: '/sale/order-shipping-detail/:id',
        name: 'ordershippingdetail',
        component: OrderShippingDetail,
    },
]

