<template>
  <div>
    <ProductionProductGoodConfirm/>
  </div>
</template>

<script>
import ProductionProductGoodConfirm from "@/components/production_process/processing_product_good/ProductionProductGoodConfirm";
export default {
  name: "ProductionProductGoodConfirm_Page",
  components: {ProductionProductGoodConfirm}
}
</script>

<style scoped>

</style>