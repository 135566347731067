<template>
    <div>
        <SettingRawMaterialEditPage />
    </div>
</template>
  
<script>
import SettingRawMaterialEditPage from '@/components/stock/setting_stock/SettingRawMaterialEditComponent'
export default {
    name: 'SettingRawMaterialEdit',
    components: {
        SettingRawMaterialEditPage,
    }
}
</script>