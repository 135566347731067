<template>
    <div>
        <WarehouseDetailHistoryRawMaterialAuditProcess_page />
    </div>
</template>
  
<script>
import WarehouseDetailHistoryRawMaterialAuditProcess_page from '@/components/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialAuditProcess'
export default {
    name: 'WarehouseDetailHistoryRawMaterialAuditProcess',
    components: {
        WarehouseDetailHistoryRawMaterialAuditProcess_page,
    }
}
</script>