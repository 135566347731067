<template>
    <div>
        <RawMaterialTypePage />
    </div>
</template>
  
<script>
import RawMaterialTypePage from '@/components/stock/setting_stock/RawMaterialTypeComponent'
export default {
    name: 'RawMaterialType',
    components: {
        RawMaterialTypePage,
    }
}
</script>