<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">OF-202301005</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">แก้ไขรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <div class="d-flex justify-space-between ma-5">
          <h3>
            <b>ขั้นตอนที่ 4 : การตรวจสอบกระบวนการแปรรูป</b>
          </h3>
          <div class="d-flex">
            <div class="d-flex mr-3">
              <div class="mr-2" style="color: #686868">ผู้แก้ไขล่าสุด :</div>
              <div class="black--text">ชลิตวรรณ นาคทอง</div>
            </div>
            <div class="d-flex">
              <div class="mr-2" style="color: #686868">วันที่ :</div>
              <div class="black--text">02/01/2023 09:40</div>
            </div>
          </div>
        </div>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/quality (1).png">
              </v-avatar>
              <span class="ml-3 title-card-text">การตรวจสอบคุณภาพการผลิตการแปรรูป</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing mt-6" style="color: #b1adad">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์</div>
                  <div class="table-sub-header-processing ml-1">เกณฑ์คุณภาพ</div>
                  <div class="table-sub-header-processing ml-n2">Value</div>
                </div>
                <v-card elevation="4" class="my-5" style="border-radius:20px">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Steamer</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items ma-2 pl-3 pr-3" v-for="(detail, index) in dataList" :key="index">
                    <div class="black--text font-weight-bold">{{ detail.name }}</div>
                    <div>{{ detail.quality }}</div>
                    <div>
                      <v-text-field class="input-radius" color="#FFBC15" label="value" v-model="detail.value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
                <v-card elevation="4" class="my-5" style="border-radius:20px">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">Solar Dome</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items ma-2 pl-3 pr-3" v-for="(detail, index) in solarList" :key="index">
                    <div class="black--text font-weight-bold">{{ detail.name }}</div>
                    <div>{{ detail.detail }}</div>
                    <div>
                      <v-text-field class="input-radius" color="#FFBC15" label="value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
                <div class="my-3">
                  <div class="black--text font-weight-bold">หมายเหตุ (Remark)</div>
                  <v-textarea class="input-radius py-2" label="หมายเหตุ (Remark)" color="#FFBC15" outlined rounded rows="3" row-height="25"></v-textarea>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn class="white--text" color="#88B358" elevation="4" rounded large>
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductionProcessRecheckEdit",
  data() {
    return {
      dataList: [
        {
          name: 'Rice: Water',
          quality: 'Jasmin 32:24/Berry 11:54',
          value: 23,
        },
        {
          name: 'จำนวนถาดที่หุ้ง',
          quality: '-',
          value: 23,
        },
        {
          name: 'Temperature',
          quality: '100C',
          value: 23,
        },
        {
          name: 'Time Start',
          quality: '-',
          value: 23,
        },
        {
          name: 'Time End',
          quality: '-',
          value: 23,
        },
        {
          name: 'Time',
          quality: '50 Min - 60 Min',
          value: 23,
        },
      ],
      solarList: [
        {
          name: 'Temp',
          detail: '45.0 - 70.0 C',
        },
        {
          name: 'DateStart',
          detail: '-',
        },
        {
          name: 'TimeStart',
          detail: '-',
        },
        {
          name: 'DateEnd',
          detail: '-',
        },
        {
          name: 'TimeEnd',
          detail: '-',
        },
        {
          name: 'Time',
          detail: '>6.0 hr',
        },
        {
          name: 'Moisture : Dried Rice',
          detail: '9.0 - 11.0',
        },
      ],
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/production_confirm')
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  align-items: baseline;
}
</style>