<template>
    <div>
        <WarehouseDetailHistoryRawMaterialAudit_page />
    </div>
</template>
  
<script>
import WarehouseDetailHistoryRawMaterialAudit_page from '@/components/stock/warehouse_stock/WarehouseDetailHistoryRawMaterialAudit'
export default {
    name: 'WarehouseDetailHistoryRawMaterialAudit',
    components: {
        WarehouseDetailHistoryRawMaterialAudit_page,
    }
}
</script>