<template>
    <div>
        <AccountingSettingPage />
    </div>
</template>
  
<script>
import AccountingSettingPage from '@/components/accounting/AccountingSettingComponent'
export default {
    name: 'accountingsetting',
    components: {
        AccountingSettingPage,
    }
}
</script>