
function validateEmail(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (((charCode >= 64 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 48 && charCode <= 57)  || charCode === 46 || charCode === 95)) {
        return true
    }
    else {
        evt.preventDefault();
    }
}

function validateCharacter(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (((charCode >= 31 && charCode <= 47) || (charCode >= 58 && charCode <= 64) || (charCode >= 91 && charCode <= 96) || (charCode >= 123 && charCode <= 254))) {
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onPaste(evt) {
    // evt = evt.clipboardData.getData('text')
    // evt = evt.replace(/[^a-zA-Z 0-9]/g, '')
    // console.log(evt)
    evt.preventDefault()
}

function isTelephone(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    // restrict to length of number
    if(number != null && number.length > 9){
        evt.preventDefault();
    }
    else {
        return true
    }
}

function taxNumber(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    // restrict to length of number
    if(number != null && number.length > 12){
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onlyForNumber(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onlyForNumberCredit(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    if (number.length > 6) {
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onlyForCurrency(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    }
    // restrict to 2 decimal places
    if(number !== null && number.indexOf(".") > -1 && (number.split('.')[1].length >= 2)){
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onlyForCurrencyStock(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    }
    // restrict to 2 decimal places
    if(number !== null && number.indexOf(".") > -1 && (number.split('.')[1].length >= 3)){
        evt.preventDefault();
    }
    else {
        return true
    }
}

function formatPrice(value) {
    if (value !== '' && value !== null && value !== 'undefined') {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else {
        return 0.00
    }
}

function formatPriceStock(value) {
    if (value !== '' && value !== null && value !== 'undefined') {
        let val = (value/1).toFixed(3).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else {
        return 0.000
    }
}

function formatNumber(value) {
    if (value !== '' && value !== null && value !== 'undefined' && value !== 0) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else if (value === 0) {
        return 0
    }
    else {
        return 0
    }
}

function diffDateTH(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[2]
        const month = diff[1]
        const year = parseInt(diff[0])+ 543
        return day+'/'+month+'/'+year
    }
    else {
        value = ''
        return value
    }
}

function diffChangeFormat(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[2]
        const month = diff[1]
        const year = parseInt(diff[0])
        return day+'-'+month+'-'+year
    }
    else {
        value = ''
        return value
    }
}
function diffChangePosition(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[0]
        const month = diff[1]
        const year = parseInt(diff[2])
        return day+'/'+month+'/'+year
    }
    else {
        value = ''
        return value
    }
}

function diffChangeFormat_2(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[2]
        const month = diff[1]
        const year = parseInt(diff[0]) - 543
        return day+'-'+month+'-'+year
    }
    else {
        value = ''
        return value
    }
}

function diffDateEN(value) {
    const diff = value.split('-')
    const year = parseInt(diff[2]) - 543
    const month = diff[1]
    const day = diff[0]
    return year+'-'+month+'-'+day
}

function diffDateEN_Change(value) {
    if (value !== null) {
        const diff = value.split('/')
        const year = parseInt(diff[2]) - 543
        const month = diff[1]
        const day = diff[0]
        return year+'-'+month+'-'+day
    }
    else {
        value = ''
        return value
    }
}

function minDate() {
    const today = new Date();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    if (month < 10){
        month = '0'+ month
    }
    if (date < 10){
        date = '0'+ date
    }
    const result_date = today.getFullYear()+'-'+month+'-'+date;
    return result_date;
}

export {
    validateEmail,
    validateCharacter,
    onPaste,
    isTelephone,
    onlyForCurrency,
    taxNumber,
    onlyForNumber,
    formatPrice,
    formatPriceStock,
    formatNumber,
    diffDateTH,
    diffDateEN,
    minDate,
    diffChangeFormat,
    diffChangeFormat_2,
    diffChangePosition,
    diffDateEN_Change,
    onlyForNumberCredit,
    onlyForCurrencyStock
}
