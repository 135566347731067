<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>

        <navigationmenu />

        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ข้อมูลลูกค้า</h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการลูกค้า</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" @keyup="searchList()"
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                <div class="d-flex justify-end">
                                    <v-btn class="mt-2" large rounded outlined color="#88B358" v-if="$store.state.profileDetail.per_sell_add_cus == 1 || $store.state.profileDetail.permission == 1"
                                        @click="customer_add_dialog = true">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มลูกค้า
                                    </v-btn>
                                    <v-btn class="mt-2" large rounded color="#D9D9D9" v-else
                                        @click="customer_add_dialog = true">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มลูกค้า
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-customer-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-customer-box-data-text"></div>
                                    <div class="table-header-customer-box-data-text">
                                        ชื่อลูกค้า
                                    </div>
                                    <div class="table-header-customer-box-data-text">
                                        เบอร์โทร
                                    </div>
                                    <div class="table-header-customer-box-data-text">
                                        เลขที่เสียภาษี
                                    </div>
                                    <div class="table-header-customer-box-data-text">
                                        ที่อยู่
                                    </div>
                                    <div class="table-header-customer-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in customerList"
                                    :key="index">
                                    <div class="grid-customer-box-items">
                                        <div class="w-border"></div>
                                        <div>{{ detail.customer_name }}</div>
                                        <div>{{ detail.customer_tel }}</div>
                                        <div>{{ detail.customer_taxnumber == null ? '-' :  detail.customer_taxnumber}}</div>
                                        <div>{{ detail.customer_address }}</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }" v-if="$store.state.profileDetail.per_sell_edit_cus == 1 || $store.state.profileDetail.permission == 1">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:activator="{ onarr, onend }"  v-else>
                                                    <v-btn elevation="0" color="#D9D9D9" v-bind="onarr" v-on="onend" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list v-if="$store.state.profileDetail.per_sell_edit_cus == 1 || $store.state.profileDetail.permission == 1">
                                                    <v-list-item link>
                                                        <v-list-item-title class="f-14" style="color:#FEA06C"
                                                            @click="show_edit(detail.id)">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- เพิ่มข้อมูล -->
        <v-dialog v-model="customer_add_dialog" transition="dialog-top-transition" max-width="667"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>เพิ่มลูกค้า</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="customer_add_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-form ref="form_add" @submit.prevent="customerAdd">
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-account-edit
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ข้อมูลลูกค้า</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อลูกค้า"
                                                v-model="customerSet.customer_name" color="#FFBC15"
                                                :rules="[v => !!v || 'กรุณากรอกชื่อลูกค้า']" hide-details="auto" dense
                                                outlined rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius" @keypress="isOnlyNumber($event)"
                                                v-model="customerSet.customer_tel" color="#FFBC15" label="เบอร์โทร"
                                                minlength="10" maxlength="10" :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                                                placeholder="เบอร์โทร" hide-details="auto" dense outlined rounded required>
                                            </v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius" @keypress="isOnlyNumber($event)"
                                                v-model="customerSet.customer_taxnumber" color="#FFBC15" label="เลขที่เสียภาษี"
                                                minlength="13" maxlength="13"
                                                placeholder="เลขที่เสียภาษี" hide-details="auto" dense outlined rounded required>
                                            </v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-textarea class="input-radius" label="ที่อยู่" hide-details="auto"
                                                v-model="customerSet.customer_address" color="#FFBC15" dense outlined :rules="[v => !!v || 'กรุณากรอกที่อยู่']"
                                                rounded rows="3" row-height="25">
                                            </v-textarea>
                                        </v-form-group>
                                    </v-col>

                                    <!-- <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-autocomplete @change="select_province(province)" :items="province_list"
                                            item-text="name_th" item-value="id" v-model="province" color="#FFBC15"
                                            label="จังหวัด" :rules="[v => !!v || 'กรุณาเลือกจังหวัด']" hide-details="auto"
                                            dense outlined rounded required></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-autocomplete @change="select_amphure(amphures)" :items="amphures_list"
                                            color="#FFBC15" item-text="name_th" item-value="id" v-model="amphures"
                                            label="อำเภอ" :rules="[v => !!v || 'กรุณาเลือกอำเภอ']" hide-details="auto" dense
                                            outlined rounded required></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-autocomplete @change="select_district(districts)" :items="districts_list"
                                            item-text="name_th" item-value="id" v-model="districts" color="#FFBC15"
                                            label="ตำบล" :rules="[v => !!v || 'กรุณาเลือกตำบล']" hide-details="auto" dense
                                            outlined rounded required></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-form-group>
                                            <v-text-field class="input-radius" color="#FFBC15" label="รหัสไปรษณีย์"
                                                placeholder="รหัสไปรษณีย์" :rules="[v => !!v || 'กรุณาเลือกตำบล']"
                                                hide-details="auto" v-model="zipcode" dense outlined rounded required
                                                readonly></v-text-field>
                                        </v-form-group>
                                    </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการเพิ่ม -->
        <!-- แก้ไขข้อมูล -->
        <v-dialog v-model="customer_edit_dialog" transition="dialog-top-transition" max-width="667"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>แก้ไขลูกค้า</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="customer_edit_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-form ref="form_edit" @submit.prevent="customerEdit">
                    <v-card-text>
                        <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด :
                            <span class="text-fullname-title">{{ customerDetail.user_edit_name }}</span> <span
                                class="text-date-title">วันที่
                                :</span>
                            <span class="text-datetime-title"> {{ customerDetail.updated_at }}</span>
                        </div>
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-account-edit
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ข้อมูลลูกค้า</span>
                            </v-card-title>
                            <v-card-text>

                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อลูกค้า"
                                                color="#FFBC15" :rules="[v => !!v || 'กรุณากรอกชื่อลูกค้า']"
                                                v-model="customerDetail.customer_name" hide-details="auto" dense outlined
                                                rounded required></v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius"
                                                @keypress="isTelephone($event, customerDetail.customer_tel)"
                                                v-model="customerDetail.customer_tel" color="#FFBC15" label="เบอร์โทร"
                                                minlength="10" maxlength="10" :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                                                placeholder="เบอร์โทร" hide-details="auto" dense outlined rounded required>
                                            </v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius"
                                                @keypress="isTelephone($event, customerDetail.customer_tel)"
                                                v-model="customerDetail.customer_taxnumber" color="#FFBC15" label="เลขที่เสียภาษี"
                                                minlength="13" maxlength="13"
                                                placeholder="เลขที่เสียภาษี" hide-details="auto" dense outlined rounded required>
                                            </v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-textarea class="input-radius" label="ที่อยู่" hide-details="auto"
                                                v-model="customerDetail.customer_address" color="#FFBC15" dense outlined :rules="[v => !!v || 'กรุณากรอกที่อยู่']"
                                                rounded rows="3" row-height="25">
                                            </v-textarea>
                                        </v-form-group>
                                    </v-col>
                                    <!-- {{ customerDetail }} -->
                                    <!-- <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-autocomplete @change="select_province(customerDetail.customer_province)"
                                            :items="province_list" item-text="name_th" item-value="id"
                                            v-model="customerDetail.customer_province" color="#FFBC15" label="จังหวัด"
                                            :rules="[v => !!v || 'กรุณาเลือกจังหวัด']" hide-details="auto" dense outlined
                                            rounded required></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-autocomplete @change="select_amphure(customerDetail.customer_district)"
                                            :items="amphures_list" color="#FFBC15" item-text="name_th" item-value="id"
                                            v-model="customerDetail.customer_district" label="อำเภอ"
                                            :rules="[v => !!v || 'กรุณาเลือกอำเภอ']" hide-details="auto" dense outlined
                                            rounded required></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-autocomplete @change="select_district(customerDetail.customer_sub_district)"
                                            :items="districts_list" color="#FFBC15" item-text="name_th" item-value="id"
                                            v-model="customerDetail.customer_sub_district" label="ตำบล"
                                            :rules="[v => !!v || 'กรุณาเลือกตำบล']" hide-details="auto" dense outlined
                                            rounded required></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <v-form-group>
                                            <v-text-field class="input-radius" label="รหัสไปรษณีย์" color="#FFBC15"
                                                placeholder="รหัสไปรษณีย์" hide-details="auto" dense
                                                v-model="customerDetail.customer_zip_code" outlined rounded required
                                                readonly></v-text-field>
                                        </v-form-group>
                                    </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดแก้ไขข้อมูล -->
    </div>
</template>
<script>
import navigationmenu from '@/components/menu/VerticalMenuSale.vue';
import customers from '@/api/sale/customers.js'
import company from '@/api/company/company.js'
import { onlyForCurrency} from "@/helper/handler";
import Swal from 'sweetalert2'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            loading_detail_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'การขาย',
                    disabled: false,
                },
                {
                    text: 'ข้อมูลลูกค้า',
                    disabled: false,
                    href: '#',
                },
            ],
            page: 1,
            pageSize: 1,
            customer_add_dialog: false,
            customer_edit_dialog: false,
            delete_id: null,
            customerList: [],
            customerSet: {
                customer_name: null,
                customer_tel: null,
                customer_address: null,
                customer_taxnumber: '',
            },
            customerDetail: {
                customer_id: null,
                customer_name: null,
                customer_tel: null,
                customer_address: null,
                customer_taxnumber: '',
                customer_sub_district: null,
                customer_district: null,
                customer_province: null,
                customer_zip_code: null,
                updated_at: null,
                user_edit_name: null,
            },
            search: '',
            province_list: [],
            province: null,
            amphures_list: [],
            amphures: null,
            districts_list: [],
            districts: null,
            zipcode: null,
            dataID: null,
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        parseFloatData(data) {
            return parseFloat(data);
        },
        async customer() {
            customers.customerList(this.page, '', this.search).then((response) => {
                if (response.data.successful === true) {
                    this.customerList = response.data.CustomerList
                    this.pageSize = response.data.pages;
                    this.page = parseFloat(response.data.currentPage);
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async searchList() {
            this.data = []
            this.page = 1
            await customers.customerList(this.page, '', this.search).then((response) => {
                this.customerList = response.data.CustomerList
                this.pageSize = response.data.pages;
                this.page = parseFloat(response.data.currentPage);
            })
        },
        async provinceList() {
            company.provinceList().then((response) => {
                if (response.data.successful === true) {
                    this.province_list = response.data.list
                }
                if (response.data.successful === false) {
                    setTimeout(() => this.alert_error = false, 5000) //need adjust
                }
            })
        },
        async select_province(id) {
            await company.amphuresList(id).then((response) => {
                if (response.data.successful === true) {
                    this.amphures_list = response.data.list
                }
            })
        },
        async select_amphure(id) {
            await company.districtList(id).then((response) => {
                if (response.data.successful === true) {
                    this.districts_list = response.data.list
                }
            })
        },
        async select_amphure_onInit(id, resID) {
            await company.districtList(id).then((response) => {
                if (response.data.successful === true) {
                    this.districts_list = response.data.list
                    const result = this.districts_list.find(({ id }) => id === resID);
                    this.zipcode = result.zip_code
                }
            })
        },
        async select_district(district) {
            const result = this.districts_list.find(({ id }) => id === district);

            this.customerDetail.customer_zip_code = result.zip_code
            this.zipcode = result.zip_code

        },
        async customerAdd() {
            let bodyFormData = new FormData()
            if (this.$refs.form_add.validate()) {
                bodyFormData.append("customer_name", this.customerSet.customer_name)
                bodyFormData.append("customer_tel", this.customerSet.customer_tel)
                bodyFormData.append("customer_address", this.customerSet.customer_address)
                bodyFormData.append("customer_taxnumber", this.customerSet.customer_taxnumber == null ? '' : this.customerSet.customer_taxnumber)
                bodyFormData.append("customer_sub_district", 1)
                bodyFormData.append("customer_district", 1)
                bodyFormData.append("customer_province", 1)
                bodyFormData.append("customer_zip_code", 1)

                await customers.addCustomer(bodyFormData).then((response) => {
                    if (response.data.successful === true) {
                        let timerInterval
                        Swal.fire({
                            title: 'ทำรายการเสร็จสิ้น',
                            icon: 'success',
                            html: 'ระบบได้บันทึกข้อมูลเรียบร้อยแล้ว.',
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    this.customer()
                                    this.customer_add_dialog = false
                                    b.textContent = Swal.getTimerLeft()
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval)
                                this.customer()
                                this.customer_add_dialog = false

                            }
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                this.$router.push("/sale/customer-list");
                            }
                        });
                    } else if(response.data.successful === false){
                        Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'warning',
                            html: response.data.message === 'This tax number has already been taken' ? 'เลขที่เสียภาษีนี้มีผู้ใช้งานแล้ว' : '',
                            confirmButtonText: 'ตกลง'
                        })
                    }
                })
                this.$refs.form_add.reset()
            }
        },
        show_edit(id) {
            this.loading_page = true;
            customers.customerDetail(id).then((response) => {
                if (response.data.successful === true) {
                    this.customer_edit_dialog = true
                    // let province_id = this.province_list.find(o => o.name_th === response.data.detail.customer_province);
                    // this.select_province(province_id.id)
                    // let amphures_id = this.amphures_list.find(o => o.name_th === response.data.detail.customer_district)
                    // if (amphures_id == null) {
                    //     this.show_edit(id)
                    // }
                    // this.select_amphure(amphures_id.id)
                    // let district_id = this.districts_list.find(o => o.name_th === response.data.detail.customer_sub_district)
                    // if (district_id == null) {
                    //     this.show_edit(id)
                    // }


                    this.customerDetail = {
                        customer_id: response.data.detail.id,
                        customer_name: response.data.detail.customer_name,
                        customer_tel: response.data.detail.customer_tel,
                        customer_address: response.data.detail.customer_address,
                        customer_taxnumber: response.data.detail.customer_taxnumber,
                        customer_sub_district: '1',
                        customer_district: parseFloat(1),
                        customer_province: parseFloat(1),
                        customer_zip_code: response.data.detail.customer_zip_code,
                        updated_at: response.data.detail.updated_at,
                        user_edit_name: response.data.detail.user_edit_name,
                    }
                    this.select_province(this.customerDetail.customer_province)
                    this.select_amphure(this.customerDetail.customer_district)
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async customerEdit() {
            let bodyFormData = new FormData()
            if (this.$refs.form_edit.validate()) {
                bodyFormData.append("customer_name", this.customerDetail.customer_name)
                bodyFormData.append("customer_tel", this.customerDetail.customer_tel)
                bodyFormData.append("customer_taxnumber", this.customerDetail.customer_taxnumber == null ? '' : this.customerDetail.customer_taxnumber)
                bodyFormData.append("customer_address", this.customerDetail.customer_address == null ? null : this.customerDetail.customer_address)
                bodyFormData.append("customer_sub_district", this.customerDetail.customer_sub_district)
                bodyFormData.append("customer_district", 1)
                bodyFormData.append("customer_province", 1)
                bodyFormData.append("customer_zip_code", 1)

                    await customers.EditCustomer(bodyFormData, this.customerDetail.customer_id).then((response) => {
                if (response.data.successful === true) {
                    let timerInterval
                    Swal.fire({
                        title: 'ทำรายการเสร็จสิ้น',
                        icon: 'success',
                        html: 'ระบบได้บันทึกข้อมูลเรียบร้อยแล้ว.',
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                this.customer()
                                b.textContent = Swal.getTimerLeft()
                                this.customer_edit_dialog = false
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                            this.customer()
                            this.customer_edit_dialog = false
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            this.$router.push("/sale/customer-list");
                        }
                    });
                } else {
                      Swal.fire({
                            title: 'แจ้งเตือน',
                            icon: 'warning',
                            html: response.data.message === 'This tax number has already been taken' ? 'เลขที่เสียภาษีนี้มีผู้ใช้งานแล้ว' : '',
                            confirmButtonText: 'ตกลง'
                        })
                }
            })
            this.$refs.form_edit.reset()
            }
        },
    },
    components: {
        navigationmenu
    },
    watch: {
        page() {
            this.customer()
        }
    },
    mounted() {
        this.customer()
        this.provinceList()
    },
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-customer-box-data {
    display: grid;
    grid-template-columns: 2% 20% 10% 20% 25% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-customer-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-customer-box-items {
    display: grid;
    grid-template-columns: 6% 19% 10% 20% 33% 12%;
    align-items: center;
}
</style>