<template>
  <div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการบรรจุภัณฑ์สินค้า</div>
      <div class="topic_processing_content my-2">การบรรจุภัณฑ์สินค้า</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการบรรจุภัณฑ์สินค้า</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนการบรรจุภัณฑ์ : {{ pro_name }} รหัส {{ packing_code }}</b>
        </v-card-title>
        <div>

          <div class="ma-8 mb-0 d-flex">

            <div :class="status >= 1 ? 'card-container active-card' : 'card-container un-active-card'">
              <div class="header-status-logo">
                <v-avatar :color="status >= 1 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" src="@/assets/process (2).png">
                </v-avatar>
              </div>
              <div>
                <!-- sub-active-card -->
                <div :class="status >= 1 ? 'sub-card-container sub-active-card' : 'sub-card-container'"
                  @click="linkToCreate('/pac/processing-packaging-step-create/' + route_id)">
                  <div class="pa-3">
                    <h3 class="white--text">1 : สร้างใบสั่งบรรจุภัณฑ์</h3>
                    <div class="white--text">กำหนดขนาดสินค้าที่ต้องการบรรจุภัณฑ์</div>
                  </div>
                </div>
                <div class="pa-3">
                  <div class="mb-3 text-center">
                    <v-btn v-if="status >= 1" @click="getPDF(base_url + 'packing/pdf/stepOne/' + route_id)"
                      color="#88B358" elevation="4" rounded outlined>
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบสั่งบรรจุภัณฑ์
                    </v-btn>
                    <v-btn v-else color="#88B358" elevation="4" rounded outlined disabled>
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบสั่งบรรจุภัณฑ์
                    </v-btn>
                  </div>
                  <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_1.user_name }} </div>
                  <div v-if="step_1.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                    formatDate(step_1.date) }}</div>
                  <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
                </div>
              </div>
            </div>
            <v-divider :class="status >= 1 ? 'active-divider' : 'un-active-divider'" />
            <div :class="status >= 2 ? 'card-container active-card' : 'card-container un-active-card'">
              <div class="header-status-logo">
                <v-avatar :color="status >= 2 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" style="max-width: 100%;width: 90%;height: 90%" src="@/assets/rice_01.png">
                </v-avatar>
              </div>

              <!-- sub-active-card -->
              <div :class="status >= 2 ? 'sub-card-container sub-active-card' : 'sub-card-container'"
                @click="status >= 1 ? status >= 2 ? linkToCreate('/pac/processing-packaging-step-production/' + route_id + '/' + 0) : linkToCreate('/pac/processing-packaging-step-production/' + route_id) : ''">
                <div class="pa-3">
                  <h3 class="white--text">2 : สร้างใบเตรียมวัตถุดิบ</h3>
                  <div class="white--text">กำหนดปริมาณวัตถุดิบและวางแผนการบรรจุภัณฑ์</div>
                </div>
              </div>
              <div class="pa-3">
                <div class="d-lg-flex d-xl-flex justify-space-around">
                  <!-- <div class="mb-3 mr-1 ml-1 text-center">
                      <v-btn color="#6C7FD8" @click="linkToCreate('/pro/processing_product_good_packing/'+route_id+'/'+0)"  elevation="4" rounded outlined>
                        <v-icon left>mdi-alert-circle</v-icon> ดูข้อมูล
                      </v-btn>
                    </div> -->
                  <div class="mb-3 mr-1 text-center">
                    <v-btn v-if="status >= 2" color="#88B358"
                      @click="getPDF(base_url + 'packing/pdf/stepTwo/' + route_id)" elevation="4" rounded outlined>
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบเตรียมวัตถุดิบ
                    </v-btn>
                    <v-btn v-else color="#88B358" elevation="4" rounded outlined disabled>
                      <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบเตรียมวัตถุดิบ
                    </v-btn>
                  </div>
                </div>
                <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_2.user_name }}</div>
                <div v-if="step_2.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                  formatDate(step_2.date) }}</div>
                <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
              </div>
            </div>

            <v-divider :class="status >= 2 ? 'active-divider' : 'un-active-divider'" />

            <div :class="status >= 3 ? 'card-container active-card' : 'card-container un-active-card'">
              <!-- sub-active-card -->
              <div :class="status >= 3 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                <div class="header-status-logo">
                  <v-avatar :color="status >= 3 ? '#FFBC15' : '#000'" size="36">
                    <img class="icon-image-logo" src="@/assets/editing(2).png" style="max-width: 80%;height: 80%;">
                  </v-avatar>
                </div>
                <div @click="status >= 2 ? linkToCreate('/pac/processing-packaging-step-package/' + route_id) : ''">
                  <div class="pa-3">
                    <h3 class="white--text">3 : บันทึกผลการบรรจุภัณฑ์</h3>
                    <div class="white--text">บันทึกผลการบรรจุภัณฑ์ตามแผน</div>
                  </div>
                </div>
              </div>
              <div class="pa-3">
                <div class="mb-3 text-center">
                  <v-btn v-if="status >= 3" color="#88B358" elevation="4"
                    @click="getPDF(base_url + 'packing/pdf/stepThree/' + route_id)" rounded outlined>
                    <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบรายงานการบรรจุภัณฑ์
                  </v-btn>
                  <v-btn v-else color="#88B358" elevation="4" rounded outlined disabled>
                    <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบรายงานการบรรจุภัณฑ์
                  </v-btn>
                </div>
                <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_3.user_name }}</div>
                <div v-if="step_3.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                  formatDate(step_3.date) }}</div>
                <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
              </div>
            </div>

          </div>

          <div class="d-flex flex-wrap justify-end">
            <v-divider vertical :class="status >= 3 ? 'active-divider-right' : 'un-active-divider-right'" />
          </div>

          <div class="mx-8 mt-0 d-flex">

            <div :class="status >= 6 ? 'card-container active-card' : 'card-container un-active-card'">
              <div class="header-status-logo">
                <v-avatar :color="status >= 6 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" src="@/assets/quality (1).png" style="max-width: 80%;height: 80%;">
                </v-avatar>
              </div>
              <div @click="status >= 5 ? linkToCreate('/pac/processing-packaging-step-procress/' + route_id) : ''">
                <div :class="status >= 6 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                  <div class="pa-3">
                    <h3 class="white--text">6 : การตรวจสอบสินค้าที่บรรจุภัณฑ์สำเร็จ</h3>
                    <div class="white--text">การตรวจสอบคุณภาพการบรรจุภัณฑ์สินค้า</div>
                  </div>
                </div>
                <div class="pa-3">
                  <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_6.user_name }}</div>
                  <div v-if="step_6.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                    formatDate(step_6.date) }}</div>
                  <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
                </div>
              </div>
            </div>

            <v-divider :class="status >= 6 ? 'active-divider' : 'un-active-divider'" />

            <div :class="status >= 5 ? 'card-container active-card' : 'card-container un-active-card'">
              <div class="header-status-logo">
                <v-avatar :color="status >= 5 ? '#FFBC15' : '#000'" size="36"
                  @click="status >= 4 ? linkToCreate('/pac/processing-packaging-step-recheck/' + route_id) : ''">
                  <img class="icon-image-logo" src="@/assets/quality.png" style="max-width: 80%;height: 80%;">
                </v-avatar>
              </div>
              <div @click="linkToCreate('/pac/processing-packaging-step-recheck/' + route_id)">
                <!-- sub-active-card -->
                <div :class="status >= 5 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                  <div class="pa-3">
                    <h3 class="white--text">5 : การตรวจสอบกระบวนการบรรจุภัณฑ์</h3>
                    <div class="white--text">การตรวจสอบคุณภาพการบรรจุภัณฑ์</div>
                  </div>
                </div>
                <div class="pa-3">
                  <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_5.user_name }}</div>
                  <div v-if="step_5.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                    formatDate(step_5.date) }}</div>
                  <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
                </div>
              </div>
            </div>

            <v-divider :class="status >= 5 ? 'active-divider' : 'un-active-divider'" />

            <div :class="status >= 4 ? 'card-container active-card' : 'card-container un-active-card'"
              @click="status >= 3 ? linkToCreate('/pac/processing-packaging-step-damaged/' + route_id) : ''">
              <!-- sub-active-card -->
              <div :class="status >= 4 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                <div class="header-status-logo">
                  <v-avatar :color="status >= 4 ? '#FFBC15' : '#000'" size="36">
                    <img class="icon-image-logo" src="@/assets/note_pd.png" style="max-width: 80%;height: 80%;">
                  </v-avatar>
                </div>
                <div>
                  <div class="pa-3">
                    <h3 class="white--text">4 : การบรรจุภัณฑ์</h3>
                    <div class="white--text">สินค้าที่บรรจุภัณฑ์สำเร็จ</div>
                  </div>
                </div>
              </div>
              <div class="pa-3">
                <!-- <div class="mb-3 text-center">
                  <v-btn color="#88B358" elevation="4" @click="linkToCreate('/pro/processing_product_good_package_edit/'+route_id)" rounded outlined>
                    <v-icon left>mdi-printer-outline</v-icon> พิมพ์ใบรายงานการบรรจุภัณฑ์
                  </v-btn>
                </div> -->
                <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_4.user_name }}</div>
                <div v-if="step_4.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                  formatDate(step_4.date) }}</div>
                <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-start">
            <v-divider vertical :class="status >= 6 ? 'active-divider-left' : 'un-active-divider-left'" />
          </div>

          <div class="ma-8 mt-0 d-flex">

            <div :class="status >= 7 ? 'card-container active-card' : 'card-container un-active-card'">
              <div class="header-status-logo">
                <v-avatar :color="status >= 7 ? '#FFBC15' : '#000'" size="36">
                  <img class="icon-image-logo" src="@/assets/approved.png" style="max-width: 80%;height: 80%;">
                </v-avatar>
              </div>
              <div @click="status >= 6 ? linkToCreate('/pac/processing-packaging-step-confirm/' + route_id) : ''">
                <div :class="status >= 7 ? 'sub-card-container sub-active-card' : 'sub-card-container'">
                  <div class="pa-3">
                    <h3 class="white--text">7 : ยืนยันการบรรจุภัณฑ์สำเร็จ</h3>
                    <div class="white--text">อนุมัติตรวจสอบการบรรจุภัณฑ์สินค้า</div>
                  </div>
                </div>
                <div class="pa-3">
                  <div class="text-center" style="font-size: 14px">ผู้ดำเนินการ : {{ step_7.user_name }}</div>
                  <div v-if="step_7.date != null" class="text-center" style="font-size: 14px">วันที่ : {{
                    formatDate(step_7.date) }}</div>
                  <div v-else class="text-center" style="font-size: 14px">วันที่ : -</div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import process_package from '@/api/process_package/package.js'
export default {
  name: "ProcessingSteps",
  data() {
    return {
      route_id: this.$route.params.id,
      status: null,
      status_check_process_quality: null,
      status_check_material_quality: null,
      step_1: [],
      step_2: [],
      step_3: [],
      step_4: [],
      step_5: [],
      step_6: [],
      step_7: [],
      type_packing: null,
      base_url: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    linkToCreate(text) {
      window.location.href = text;
    },
    getPDF(text) {
      window.open(text, '_blank');
    },
    linkPage(link) {
      this.$router.push(link)
    },
    async getSteppacking() {
      await process_package.getSteppacking(this.route_id).then((response) => {
        this.loading_page = false
        this.pro_name = response.data.pro_name
        this.packing_code = response.data.packing_code
        this.type_packing = response.data.type_packing
        this.status = response.data.status
        this.step_1 = response.data.step_1
        this.step_2 = response.data.step_2
        this.step_3 = response.data.step_3
        this.step_4 = response.data.step_4
        this.step_5 = response.data.step_5
        this.step_6 = response.data.step_6
        this.step_7 = response.data.step_7
      })
    },
    formatDate(date) {
      const [datePart] = date.split(" ");
      const [day, month, year] = datePart.split("/");
      return `${day}-${month}-${year}`;
    },
  },
  mounted() {
    this.getSteppacking();
  },
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.card-container {
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 15px;
  inline-size: fit-content;
  width: 60%;
}

.active-card {
  cursor: pointer;
  border: 1px solid #FFBC15;
  border-radius: 15px;
  inline-size: 25%;
}

.un-active-card {
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 15px;
  inline-size: 26%;
}

.sub-card-container {
  cursor: pointer;
  background-color: #000000;
  border: 1px solid #000000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.sub-active-card {
  cursor: pointer;
  background-color: #FFBC15;
  border: 1px solid #FFBC15;
  border-radius: 15px;
}

.active-divider.v-divider {
  border: 5px solid #ffbc15;
  align-self: center;
}

.active-divider-right.v-divider {
  border: 5px solid #ffbc15;
  margin-right: 12%;
  height: 130px;
}

.un-active-divider-right.v-divider {
  border: 5px solid #D9D9D9;
  margin-right: 12%;
  height: 130px;
}

.active-divider-left.v-divider {
  border: 5px solid #ffbc15;
  margin-left: 12%;
  height: 130px;
}

.un-active-divider-left.v-divider {
  border: 5px solid #D9D9D9;
  margin-left: 12%;
  height: 130px;
}

.un-active-divider.v-divider {
  border: 5px solid #D9D9D9;
  align-self: center;
}

.header-status-logo {
  position: absolute;
  margin-left: 16.5%;
  margin-top: -24px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  padding: 1px;
  background-color: #FFBC15;
}
</style>