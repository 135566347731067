<template>
  <div>
    <ProcessingProductGoodEdit/>
  </div>
</template>

<script>
import ProcessingProductGoodEdit from "@/components/production_process/processing_product_good/ProcessingProductGoodEdit";
export default {
  name: "ProcessingProductGoodEdit_Page",
  components: {ProcessingProductGoodEdit}
}
</script>

<style scoped>

</style>