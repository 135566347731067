<template>
  <div>
    <ProcessingRawMaterialDetails/>
  </div>
</template>

<script>
import ProcessingRawMaterialDetails
  from "@/components/production_process/processing_raw_materials/ProcessingRawMaterialDetails";
export default {
  name: "Processing_raw_material_details",
  components: {ProcessingRawMaterialDetails}
}
</script>

<style scoped>

</style>