<template>
  <div>
    <ProcessingProductGoodSteps/>
  </div>
</template>

<script>
import ProcessingProductGoodSteps from "@/components/production_process/processing_product_good/ProcessingProductGoodSteps";
export default {
  name: "ProcessingProductGoodSteps_Page",
  components: {ProcessingProductGoodSteps}
}
</script>

<style scoped>

</style>