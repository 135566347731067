<template>
  <div>
    <ProcessingRawMaterialsEdit/>
  </div>
</template>

<script>
import ProcessingRawMaterialsEdit
  from "@/components/production_process/processing_raw_materials/ProcessingRawMaterialsEdit";
export default {
  name: "Processing_raw_materials_edit",
  components: {ProcessingRawMaterialsEdit}
}
</script>

<style scoped>

</style>