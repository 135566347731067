<template>
    <div>
        <div class="d-flex">
            <div class="topic_processing_header border_right">สร้างรอบการบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2">การบรรจุภัณฑ์</div>
            <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
            <div class="topic_processing_content my-2">สร้างรอบการบรรจุภัณฑ์</div>
        </div>
        <div class="mt-3">
            <v-card elevation="2" class="card-radius">
                <v-card-title>
                    <b>ขั้นตอนที่ 1 : สร้างใบสั่งบรรจุภัณฑ์</b>
                </v-card-title>
               <v-form ref="inputform" @submit.prevent="saveData()">
                 <v-card-text>
                    <v-card elevation="4" class="py-2 px-3" style="border-radius:20px;">
                        <v-card-title>
                            <v-avatar color="#34230F" size="36">
                                <v-icon dark>
                                    mdi-note-text-outline
                                </v-icon>
                            </v-avatar>
                            <span class="ml-3 title-card-text">ข้อมูลการบรรจุภัณฑ์</span>
                        </v-card-title>
                        <v-card-text>
                            <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                            <v-row class="ma-4">
                                <v-col cols="12" xl="12" lg="12">
                                    <v-row>
                                        <v-col xl="3" lg="3" md="3" v-if="dataList.status_packing == 0 || dataList.status_packing == null ">
                                           <v-menu v-model="menu1" :close-on-content-click="false" class="input-radius-search "
                                                transition="scale-transition" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="package_date" label="วันที่ใบสั่งบรรจุภัณฑ์" color="#FFBC15"
                                                    prepend-inner-icon="mdi-calendar" hide-details="auto"
                                                    :rules="[v => !!v || 'กรุณาเลือกวันที่ใบสั่งบรรจุภัณฑ์']" v-bind="attrs" dense outlined rounded readonly
                                                    required v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="package_date" @input="menu1 = false" :min="minDate" no-title
                                                scrollable></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col xl="3" lg="3" md="3" v-else>
                                            <b class="mb-0 black--text">วันที่ใบสั่งบรรจุภัณฑ์</b>
                                            <p>{{ dataList.packing_date }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row class="">
                                        <v-col cols="12" xl="12" lg="12">
                                            <b class="mb-n5 black--text">รายละเอียดการบรรจุภัณฑ์สินค้า</b>
                                        </v-col>
                                        <v-col cols="6" xl="3" lg="3">
                                            <div class="tag-title-step my-3">
                                                <b class="black--text">ล็อตสินค้า: {{dataList.product_lot}} </b>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" xl="3" lg="3">
                                            <div class="my-3">
                                                <b class="black--text">จำนวน : {{formatPrice(dataList.pro_amount)}} กรัม</b>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" xl="12" lg="12">
                                            <div class="divider-table my-2"></div>
                                        </v-col>
                                        <v-col cols="12" xl="12" lg="12">
                                            <div class="table-header-processing mt-4" style="color: #b1adad">
                                                <div class="table-sub-header-processing">
                                                    ลำดับ
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    รายละเอียด
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    ขนาด
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    จำนวน
                                                </div>
                                                <div class="table-sub-header-processing">
                                                    ปริมาณที่ใช้บรรจุภัณฑ์
                                                </div>
                                            </div>
                                            <v-card elevation="0" class="grid-processing-items ma-4"
                                                v-for="(detail, index) in dataList.size_list" :key="index">
                                                <div>{{ index+1 }}</div>
                                                <div>{{ detail.pro_name }}</div>
                                                <div>{{ detail.pro_size_name }}</div>
                                                <div class="pa-2 pt-0 mt-n5" v-if="dataList.status_packing == 0 || dataList.status_packing == null">
                                                    <v-text-field  class="input-radius" color="#FFBC15" label="จำนวน" suffix="ถุง" :rules="[v => !!v || 'กรุณาป้อน']"
                                                        v-model="detail.qty" dense outlined rounded @keypress="isOnlyNumber($event, detail.qty)" @input="total_pro_amount(), total_price()"
                                                        required></v-text-field>
                                                </div>
                                                <div class="pa-2 pt-0 mt-n5 ml-7" v-else>
                                                    <p>{{ detail.qty }}</p>
                                                </div>
                                                <div class="text-center">{{formatPrice((parseFloat(detail.pro_amount) * parseFloat(detail.qty == '' ? 0 : detail.qty)) + ((parseFloat(detail.pro_amount) * parseFloat(detail.qty == '' ? 0 : detail.qty)) * 0.03))}} กรัม</div>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col cols="12" xl="12" lg="12">
                                                    <div class="divider-table-total my-2"></div>
                                                </v-col>
                                                <v-col cols="4" class="text-center ps-15"></v-col>
                                                <v-col cols="3" class="text-left" style="font-size:15px; color: black;">รวม</v-col>
                                                <v-col cols="5" class="text-center ps-10" style="font-size:15px; color: black;">{{formatPrice(total)}} กรัม</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="mt-3 div-submit-data">
                        <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                            <v-icon left>mdi-content-save</v-icon> บันทึก
                        </v-btn>
                    </div>
                </v-card-text>
               </v-form>
            </v-card>
        </div>
    </div>
</template>
  
<script>
import process_package from '@/api/process_package/package.js'
import { formatPrice, onlyForNumber } from "@/helper/handler";
import Swal from "sweetalert2";
export default {
    name: "ProcessingCreate",
    data() {
        return {
            menu1: false,
            menu3: false,
            dataList: {},
            package_date: null,
            check_pro_amount: true,
            total: 0,
        }
    },
    methods: {
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
        formatPrice(number) {
            return formatPrice(number)
        },
        linkToSteps() {
            this.$router.push('/prm/processing_raw_materials')
        },
       async getStepOne(){
        let id = this.$route.params.id
             await process_package.getStepOne(id).then(async (response) => {
                if (response.data.successful === true) {
                   this.dataList = response.data.data
                   this.dataList.size_list = this.dataList.size_list.map(o => {
                    if(o.qty){
                        this.package_date = this.dataList.packing_date
                        return {
                        ...o,
                        }
                    }else{
                        return {
                        ...o,
                        qty: '0',
                        qty_gram:0
                        }
                    }
                   })
                  await this.total_price()
                }
            })
        },
        async saveData(){
            if(this.check_pro_amount == false){
                 Swal.fire({
            title: "ทำรายการไม่สำเร็จ",
            icon: "warning",
            html: "จำนวนสินค้าที่ยังไม่ได้บรรจุภัณฑ์ไม่เพียงพอ.",
            confirmButtonText:'ตกลง'
          })
            }else{
                 if(this.$refs.inputform.validate()){
                    this.loading_page = true;
             let id = this.$route.params.id
            let data = {
                "id": id,
                "packing_date": this.package_date,
                "size_list": this.dataList.size_list.map(item => {
                   if(item.id){
                     return {
                        "id": item.id,
                        "packing_id": item.packing_id,
                        "product_size_id":item.product_size_id,
                        "qty" : parseFloat(item.qty),
                        "qty_gram" : parseFloat(item.qty) * parseFloat(item.pro_amount)
                    }
                   }else{
                     return {
                        "packing_id": item.packing_id,
                        "product_size_id":item.product_size_id,
                        "qty" : parseFloat(item.qty),
                        "qty_gram" : parseFloat(item.qty) * parseFloat(item.pro_amount)
                    }
                   }
                })
            }
             await process_package.saveStepOne(data).then((response) => {
                if (response.data.successful === true) {
                  let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/pac/processing-packaging-step/"+response.data.id);
            }
          });
                }
            })
           }
            }
        },
        total_pro_amount(){
            let sum = 0; 
            this.dataList.size_list.forEach(item => {
                sum += item.qty == null ||  item.qty == '' ? 0 : parseFloat(item.pro_amount) * parseFloat(item.qty) 
            });
            let vat = sum * 0.03
            sum = sum + vat
            if(parseFloat(sum) > parseFloat(this.dataList.pro_amount)){
                this.check_pro_amount = false
            }else{
                this.check_pro_amount = true
            }
        },
        total_price(){
            let sum = 0
            this.dataList.size_list.forEach(detail => {
                sum += (parseFloat(detail.pro_amount) * parseFloat(detail.qty == '' ? 0 : detail.qty)) + ((parseFloat(detail.pro_amount) * parseFloat(detail.qty == '' ? 0 : detail.qty)) * 0.03)
            })
            this.total = sum
        }
    },
    mounted(){
        this.getStepOne()
    },
}
</script>
  
<style scoped>
.divider-table {
    border: 3px solid #34230F;
    border-radius: 3px;
}
.divider-table-total {
    border: 1px solid #34230F;
    border-radius: 3px;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 33% 30% 30% 30% 10%;
    align-items: baseline;
}

.tag-title-step {
    background-color: #FFF;
    border: 1px solid #000000;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.tag-title-step-table {
    border: 1px solid #ffbc15;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
    inline-size: fit-content;
}

.border_right {
    border-right: 2px solid #000000;
    padding-right: 10px;
}

.topic_processing_header {
    font-size: 24px;
    color: #000000;
}

.topic_processing_content {
    color: #7E7E7E;
    padding-left: 10px;
}

.table-header-processing {
    display: grid;
    grid-template-columns: 12% 20% 19% 25% 15%;
}

.table-sub-header-processing {
    color: #686868;
    font-size: 14px;
    margin-left: 15px;
}

.grid-processing-items {
    display: grid;
    grid-template-columns: 12% 21% 17% 20% 22%;
    align-items: baseline;
}

.summarize {
    border: 1px solid #b0cc90;
    border-radius: 25px;
    background-color: #f3f7ee;
}

.text-green {
    color: #88B358;
}
</style>