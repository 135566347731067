import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    userList: (type, page, size, search) => {
        return HTTP.get(`/user/list/${type}?page=${page}&size=${size}&search=${search}` , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    addUser: (data) => {
        return HTTP.post('/user/add' , data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    editUser: (id,data) => {
        return HTTP.post('/user/edit/'+id , data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    userDetail: (id) => {
        return HTTP.get('/user/detail/'+id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    deleteUser: (id) => {
        return HTTP.post('/user/delete/'+id, null,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    changeStatus: (id) => {
        return HTTP.post('/user/updatestatus/'+id, null,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    resetPasswordSuccess: (id) => {
        return HTTP.post('/user/resetpassword/'+id, null,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
}