<template>
    <div>
        <ProductTypePage />
    </div>
</template>
  
<script>
import ProductTypePage from '@/components/stock/setting_stock/ProductTypeComponent'
export default {
    name: 'ProductType',
    components: {
        ProductTypePage,
    }
}
</script>