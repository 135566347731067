<template>
<div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/prm/processing_materials')">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
          <v-form ref="input" @submit.prevent="saveData()">
             <v-card-title>
          <b>ขั้นตอนที่ 5 : การตรวจสอบกระบวนการแปรรูป</b>
        </v-card-title>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <img class="icon-image-logo" src="@/assets/quality.png">
              </v-avatar>
              <span class="ml-3 title-card-text">การตรวจสอบคุณภาพ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ml-15">
                <div class="table-header-processing mt-6" style="color: #b1adad">
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (English)</div>
                  <div class="table-sub-header-processing">ชื่อเกณฑ์ (ภาษาไทย)</div>
                  <div class="table-sub-header-processing ml-1">Unit</div>
                  <div class="table-sub-header-processing ml-3" style="color:#ffb98a">Aim</div>
                  <div class="table-sub-header-processing ml-n1" style="color:#13b65d">Green</div>
                  <div class="table-sub-header-processing ml-n2" style="color:#ddbb55">Yellow</div>
                  <div class="table-sub-header-processing ml-n3" style="color:#c00000">Red</div>
                  <div class="table-sub-header-processing ml-n3">Value</div>
                </div>
                <v-card elevation="4" class="my-10" style="border-radius:20px" v-for="(item, i) in dataList" :key="item">
                  <div class="table-header-processing py-4 pb-0">
                    <div class="table-sub-header-processing black--text font-weight-bold">{{item.pq_name_eng}}</div>
                    <div class="table-sub-header-processing black--text font-weight-bold">{{item.pq_name_thai}}</div>
                  </div>
                  <v-divider class="ma-3" dark color="#a3a3a3"></v-divider>
                  <div class="grid-processing-items mt-0 mb-n4 pl-3 pr-3" v-for="(detail, index) in item.quality_list" :key="index">
                    <div class="black--text font-weight-bold py-5">{{ detail.pq_list_eng }}</div>
                    <div class="black--text font-weight-bold">{{ detail.pq_list_thai }}</div>
                    <div>{{ detail.unit }}</div>
                    <div class="ml-3">{{ detail.pq_list_aim }}</div>
                    <div>{{ detail.pq_list_green }}</div>
                    <div>{{ detail.pq_list_yellow }}</div>
                    <div>{{ detail.pq_list_red }}</div>
                    <div>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == null" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#88B358">mdi-checkbox-blank-circle-outline</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 0 && detail.pq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 0 && detail.pq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(i, index, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == 0" class="mb-0"><v-icon @click="getcheck(i, index, 1)" color="#F28080">mdi-close-circle</v-icon></p>
                    <p v-if="detail.pq_list_type == 0 && detail.pq_value_status == 1 && detail.pq_value_boolean == 1" class="mb-0"><v-icon @click="getcheck(i, index, 0)" color="#88B358">mdi-check-circle</v-icon></p>
                    <v-text-field v-if="detail.pq_list_type == 1" class="input-radius" color="#FFBC15" label="value" :rules="[v => !!v || 'กรุณากรอกข้อมูล']" v-model="detail.value" dense outlined rounded required></v-text-field>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn type="submit" class="white--text" color="#88B358" elevation="4" rounded large>
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
          </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import process from '@/api/process/meterial_process.js'
import Swal from "sweetalert2";
export default {
  name: "ProductionProcessSteps",
  data() {
    return {
      loading_page: true,
      dataList: [],
      secondList: [
        {
          eng: 'Appearance',
          th: 'ข้าวเป็นเม็ดสวยไม่แฉะ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
        {
          eng: 'Texture',
          th: 'ไม่เละไม่เป็นไต',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: false,
        },
        {
          eng: 'Flavor',
          th: 'ไม่มีกลิ่นเหม็นอับ',
          unit: '-',
          aim: '-',
          green: '-',
          yellow: '-',
          red: '-',
          value: true,
        },
      ],
    }
  },
  methods: {
    linkToSteps() {
      this.$router.push('/prm/production_process_recheck')
    },
    link(link) {
      this.$router.push(link)
    },
     getDetailStep5(){
        let id = parseFloat(this.$route.params.id);
            this.loading_page = true;
            process.getDetailStep5(id).then((response) => {
              if(response.status == 502){
                window.location.reload()
              }
          if(response.data.successful === true){
            this.dataList = response.data.quality;
              this.loading_page = false;
          }
      })
    },
    getcheck(i, index, num){
      this.loading_page = true;
      this.dataList[i].quality_list[index].pq_value_boolean = num;
      this.loading_page = false;
    },
    saveData(){
      if(this.$refs.input.validate()){
        this.loading_page = true;
        let quality_list_array = [];
        for(let i = 0; i < this.dataList.length; i++){
          for(let x = 0; x < this.dataList[i].quality_list.length; x++){
            if(this.dataList[i].quality_list[x].id){
               quality_list_array.push({
              process_id: (this.$route.params.id),
              id: this.dataList[i].quality_list[x].id,
              material_id: this.dataList[i].quality_list[x].material_id,
              pq_id: this.dataList[i].quality_list[x].pq_id,
              pq_list_id: this.dataList[i].quality_list[x].pq_list_id,
              pq_list_type: this.dataList[i].quality_list[x].pq_list_type,
              pq_value_status: this.dataList[i].quality_list[x].pq_value_status,
              value: this.dataList[i].quality_list[x].value,
              pq_value_boolean: this.dataList[i].quality_list[x].pq_value_boolean
            })
            }else{
              quality_list_array.push({
              process_id: (this.$route.params.id),
              material_id: this.dataList[i].quality_list[x].material_id,
              pq_id: this.dataList[i].quality_list[x].pq_id,
              pq_list_id: this.dataList[i].quality_list[x].pq_list_id,
              pq_list_type: this.dataList[i].quality_list[x].pq_list_type,
              pq_value_status: this.dataList[i].quality_list[x].pq_value_status,
              value: this.dataList[i].quality_list[x].value,
              pq_value_boolean: this.dataList[i].quality_list[x].pq_value_boolean
            })
            }
           
          }
        }

        let data = {
          process_id: (this.$route.params.id),
          quality_list:quality_list_array,
        }
        
        this.loading_page = true;
        process.saveStep5(data).then((response) => {
          if(response.data.successful === true){
            let timerInterval;
            Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                 this.$router.push("/prm/processing_steps/"+this.$route.params.id);
                // window.location.reload(); 
              }
            });
            this.loading_page = false;
          }
      })
      }
    }
  },
  mounted(){
    this.getDetailStep5();
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}
.topic_processing_header {
  font-size: 24px;
  color: #000000;
}
.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}
.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}
.table-header-processing {
  display: grid;
  grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
}
.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}
.grid-processing-items {
  display: grid;
  grid-template-columns: 20% 20% 15% 5% 5% 5% 5% 15%;
  align-items: baseline;
}
</style>