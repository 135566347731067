import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    add(data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/importpo/save`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
    edit(id, data) {
        return new Promise((resolve, reject) => {
          HTTP.post(`/importpo/edit/${id}`, data, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
}
     
     