<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายงานการตรวจสอบคุณภาพวัตถุดิบ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <b>รายงานการตรวจสอบคุณภาพวัตถุดิบ</b>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;" class="mt-3">
                            <v-card-title class="d-flex align-items-center ">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-file
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title">รายละเอียด
                                    {{ meterial_data.mat_name }} Lot {{ meterial_data.material_lot }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">เลขที่การนำเข้า :
                                            <span class="text-fullname-title"><a
                                                target="_blank"  :href="meterial_data.po_code == null ? `/stock/material-import-purchase-order-detail/${meterial_data.im_id}/1` : `/stock/material-import-purchase-order-detail/${meterial_data.im_id}/0`">{{meterial_data.im_code}}</a></span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">เลขที่ใบสั่งซื้อ :
                                            <span class="text-fullname-title">{{ meterial_data.po_code }}</span>
                                        </div>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                        <div class="ml-3 text-name-title">ผู้ดำเนินการ :
                                            <span class="text-fullname-title">{{ meterial_data.user_name }}</span>
                                        </div>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card class="mt-8" elevation="4" style="border-radius:20px;overflow-wrap: unset !important;">
                            <v-card-title class="d-flex align-items-center pb-2">
                                <v-avatar class="top-to-card" color="#34230F" size="36">
                                    <v-icon dark>
                                        mdi-shield-check
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 mt-3 text-font-title bold">รายการตรวจสอบคุณภาพ</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row class="px-9">
                                    <v-col xs="12" sm="12" md="12" lg="4" xl="4" cols="4" class="pb-0">
                                        <v-row>
                                            <v-col cols="12 pb-2">
                                                ข้อมูลวัตถุดิบ
                                            </v-col>
                                            <v-col cols="12 pb-2">
                                                จำนวน : <span class="bold">{{ formatPrice(meterial_data.qty) }}
                                                    {{ meterial_data.unit_main_name }} </span>
                                                    <span class="bold" v-if="meterial_data.unit_other_qty != 0">({{ formatPrice(meterial_data.qty / meterial_data.unit_other_qty) }} {{ meterial_data.unit_other_name }}) </span>
                                            </v-col>
                                            <v-col cols="12 pb-2">
                                                ราคาต่อหน่วย : <span class="bold">{{ formatPrice(meterial_data.price) }} บาท</span>
                                            </v-col>
                                            <v-col cols="12 pb-2">
                                                ราคารวม : <span class="bold">{{ formatPrice(meterial_data.total_price) }} บาท</span>
                                            </v-col>
                                            <v-col cols="12 pb-2">
                                                Supplier : <span class="bold">{{ meterial_data.sup_name }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>


                                    <v-col xs="12" sm="12" md="12" lg="8" xl="8" cols="8">
                                        <v-row>
                                            <v-col cols="6 pb-2"> รายละเอียดการตรวจสอบ </v-col>

                                            <v-col cols="6 pb-2"> ราคาต่อหน่วย </v-col>
                                        </v-row>
                                        <v-row v-for="item in meterial_data.quality_check_list" :key="item">
                                            <v-col cols="6 pb-2">{{ item.pq_list_thai }}</v-col>
                                            <v-col cols="6 pb-2" v-if="item.pq_list_type == 0 && item.value == '1'">
                                                <v-icon color="#88B358">mdi-check-circle</v-icon></v-col>
                                            <v-col cols="6 pb-2" v-else-if="item.pq_list_type == 0 && item.value == '0'">
                                                <v-icon color="#F28080">mdi-close-circle</v-icon>
                                            </v-col>
                                            <v-col cols="6 pb-2" v-else>{{ item.value }}</v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12 pt-0">
                                        <v-divider class="my-4" color="#686868"></v-divider>
                                        <div class="">
                                            หมายเหตุ/รายละเอียดการแก้ไข : {{meterial_data.note}}
                                        </div>
                                    </v-col>


                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import meterial from '@/api/warehouse/materials.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',

            sub_menu: [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังวัตถุดิบ',
                    disabled: false,
                },
                {
                    text: '',
                    disabled: false,
                },
                {
                    text: 'รายละเอียดวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
                {
                    text: '',
                    disabled: false,
                    href: '#',
                },
                {
                    text: 'รายงานการตรวจสอบคุณภาพวัตถุดิบ',
                    disabled: false,
                    href: '#',
                },
            ],
            items: [
                {
                    id: 1,
                    name: 'กล่องไปรษณีย์',
                },
                {
                    id: 2,
                    name: 'กล่องของบริษัท',
                },
                {
                    id: 3,
                    name: 'กล่องลูกค้า',
                }
            ],
            page: 1,

            meterial_data: [],
            ware_house_data: [
                {
                    name: 'นมข้นจืด',
                    price: '796 กรัม',
                    unit: 'วัตถุดิบปกติ',
                },
                {
                    name: 'ข้าวขาวแห้ง',
                    price: '123,000 กรัม',
                    unit: 'วัตถุดิบแปรรูป',
                },
                {
                    name: 'น้ำเปล่า',
                    price: '35,000 กรัม',
                    unit: 'วัตถุดิบปกติ',
                },
            ],
            quality_list: [

                {
                    mat_name: 'จำนวน : 8 กระป๋อง',
                    mat_name_detail: 'สี',
                },
                {
                    mat_name: 'ราคาต่อหน่วย : 22.00 บาท',
                    mat_name_detail: 'กลิ่น',
                },
                {
                    mat_name: 'ราคารวม : 176.00 บาท',
                    mat_name_detail: 'ความสะอาดไม่มีสิ่งปลอมปน',
                },
                {
                    mat_name: 'Supplier : สยามแมคโคร',
                    mat_name_detail: 'น้ำหนัก (จำนวน)',
                },
                {
                    mat_name: '',
                    mat_name_detail: 'COA',
                }

            ]
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        meterialLot() {
            let id = parseFloat(this.$route.params.id);
            meterial.meterialLot(id).then((response) => {
                if (response.data.successful === true) {
                    this.meterial_data = response.data.data;
                    this.sub_menu[2].text = this.meterial_data.mat_name
                    this.sub_menu[4].text = 'LOT - ' + this.meterial_data.material_lot
                }
            })

        },
    },
    mounted() {
        this.meterialLot();
    },
    components: {
        navigationmenu
    },
    created() {
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}


.table-header-lotinstock-data {
    display: grid;
    grid-template-columns: 10% 30% 30% 30%;
    padding: 10px;
}

.table-header-lotinstock-data-text {
    color: #686868;
    font-size: 14px;
}

.grid-lotinstock-items {
    display: grid;
    grid-template-columns: 10% 30% 30% 30%;
    align-items: center;
    padding: 15px;
}</style>