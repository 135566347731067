<template>
    <div>
      <MaterialImportPurchaseOrderAddPage/>
    </div>
  </template>
  
  <script>
  import MaterialImportPurchaseOrderAddPage from '@/components/stock/material_import/MaterialImportPurchaseOrderAddComponent'
  export default {
    name: 'materialimagepurchaseorderadd',
    components:{
      MaterialImportPurchaseOrderAddPage,
    }
  }
  </script>