import { HTTP } from "@/axios.js";
import store from "@/store/index.js";
export default {
  materialList(page = "", size = "", search = "", type = 0) {
    return new Promise((resolve, reject) => {
      HTTP.get(
        `/po/list?page=${page}&size=${size}&search=${search}&type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.access_token}`,
          },
        }
      )
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  materialBeforeAdd() {
    return new Promise((resolve, reject) => {
      HTTP.get("/material/list/po", {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  materialAdd(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("/po/create", data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  materialDetail(id) {
    return new Promise((resolve, reject) => {
      HTTP.get("/po/detail/" + id, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  materialDetailEdit(id) {
    return new Promise((resolve, reject) => {
      HTTP.get("/po/details/" + id, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  materialEdit(id, data) {
    return new Promise((resolve, reject) => {
      HTTP.post("/po/edit/" + id, data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  materialCancel(id, data) {
    return new Promise((resolve, reject) => {
      HTTP.post("/po/cancel/" + id, data, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
};
