<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">การบรรจุภัณฑ์</div>
      <div class="topic_processing_content my-2">การบรรจุภัณฑ์</div>
    </div>
    <v-row class="mt-3">
      <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card elevation="2" class="card-radius">
          <v-card-title>
            <b>รายการบรรจุภัณฑ์</b>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <div class="search-input">
                <v-text-field color="#D9D9D9" class="input-radius-search  mt-3" @keyup="getpackingList()"
                  prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..." hide-details="auto"
                  single-line outlined dense>
                </v-text-field>
              </div>
              <div class="d-flex justify-end">
                <v-btn class="mt-2" large rounded outlined color="#88B358" @click="dialog_product = true" v-if="$store.state.profileDetail.per_pack_add == 1 || $store.state.profileDetail.permission == 1">
                  <v-icon left>mdi-plus</v-icon>
                  สร้างรอบการบรรจุภัณฑ์
                </v-btn>
                <v-btn class="mt-2" large rounded color="#D9D9D9" v-else>
                  <v-icon left>mdi-plus</v-icon>
                  สร้างรอบการบรรจุภัณฑ์
                </v-btn>
              </div>
            </div>
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="table-header-processing mt-5" style="color: #b1adad;">
                  <div class="table-sub-header-processing"></div>
                  <div class="table-sub-header-processing">
                    รหัสการบรรจุภัณฑ์
                  </div>
                  <div class="table-sub-header-processing">
                    วันที่บรรจุภัณฑ์
                  </div>
                  <div class="table-sub-header-processing">
                    สินค้าที่ต้องการบรรจุภัณฑ์
                  </div>
                  <div class="table-sub-header-processing ml-n5">
                    สถานะการบรรจุภัณฑ์
                  </div>
                  <div class="table-sub-header-processing text-center">
                    ผู้ทำการบรรจุภัณฑ์
                  </div>
                  <div class="table-sub-header-processing text-center">
                    จัดการ
                  </div>
                </div>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in dataList" :key="index">
                  <div class="grid-processing-items">
                    <div class="w-border"></div>
                    <div class="d-flex ml-8">
                      <div>{{ detail.packing_code }}</div>
                      <div v-if="detail.note_msg" class="ml-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="#a3a3a3" dark v-bind="attrs" v-on="on">
                              mdi-message-bulleted
                            </v-icon>
                          </template>
                          <div class="grid-msg">
                            <div class="text--left">{{ detail.note_msg.time_stamp }}</div>
                            <div class="text--center">หมายเหตุการยกเลิกการบรรจุภัณฑ์: {{ detail.note_msg.note }}</div>
                            <div style="text-align: end">โดย {{ detail.note_msg.user_create }}</div>
                          </div>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="" v-if="detail.packing_date != null">{{
                      `${detail.packing_date.split('/')[0]}-${detail.packing_date.split('/')[1]}-${detail.packing_date.split('/')[2]}`
                    }}</div>
                    <div v-else>-</div>
                    <div class="">{{ detail.pro_name }}</div>
                    <div v-if="detail.status_packing != 8" class="ml-2">
                      <v-progress-linear class="progress-color" color="#3f9629" rounded
                        v-model="detail.status_packing_percent" height="25"><strong style="color: #FFFFFF">{{
                          Math.ceil(detail.status_packing_percent)
                        }}%</strong></v-progress-linear>
                    </div>
                    <div v-else class="ml-2">
                      <v-chip class="white--text" color="#f28080">ยกเลิกการบรรจุภัณฑ์</v-chip>
                    </div>
                    <div class="text-center">{{ detail.user_approve_name }}</div>

                    <div class="text-center">
                      <v-menu rounded="b-xl" offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <!-- @click="linkPage('/prm/processing_raw_material_details')" -->
                            <v-list-item-title class="f-14" style="color: #6c7fd8"
                              @click="linkPage('/pac/processing-packaging-step-confirm/' + detail.id + '/0')">
                              <v-icon left style="color:#6c7fd8">mdi-alert-circle-outline</v-icon>
                              ดูรายละเอียด
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="detail.status_packing <= 6">
                            <v-list-item-title class="f-14" style="color:#FEA06C"
                              @click="linkPage('/pac/processing-packaging-step/' + detail.id)">
                              <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                              แก้ไขข้อมูล
                            </v-list-item-title>
                          </v-list-item>
                          <span v-if="$store.state.profileDetail.per_pack_cancel == 1 || $store.state.profileDetail.permission == 1">
                            <v-list-item v-if="detail.status_packing <= 3"
                              @click="open_model_cancel(detail.id, detail.packing_code)">
                              <v-list-item-title class="f-14" style="color:#F28080">
                                <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                ลบ
                              </v-list-item-title>
                            </v-list-item>
                          </span>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </v-card>
                <div class="text-center mt-5">
                  <v-pagination v-model="page" :length="pageSize" :total-visible="7" color="#FFBC15"></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_cancel" transition="dialog-top-transition" max-width="800" style="border-radius:20px">
      <v-form class="" ref="form_cancel" @submit.prevent="getPackingCancel()">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title><b>ยกเลิกรายการบรรจุภัณฑ์</b></v-card-title>
            <v-btn type="button" icon class="ma-4" @click="dialog_cancel = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-card elevation="4" style="border-radius:20px">
              <v-card-title class="d-flex align-items-center">
                <v-avatar color="#34230F" size="36">
                  <img class="icon-image-logo" src="@/assets/warning1.png">
                </v-avatar>
                <span class="ml-3 f-18">เหตุผลการยกเลิกการบรรจุภัณฑ์ {{ cancel_producttion_code }}</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <div class="ma-4">
                  <v-textarea v-model="cancel_reason" color="#686868" placeholder="เหตุผลการยกเลิกการบรรจุภัณฑ์" :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                    hide-details="auto" outlined rounded required></v-textarea>
                </div>
              </v-card-text>
            </v-card>
            <div class="mt-3 text-center">
              <v-btn type="submit" class="white--text" color="#88B358" elevation="4" rounded large
                @click="getPackingCancel()">
                <v-icon left>mdi-content-save</v-icon> บันทึก
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialog_product" scrollable max-width="710">
      <v-card>
        <v-card-title class="">
          <v-row class="">
            <v-col cols="10">
              เลือกสินค้าที่ต้องการสั่งบรรจุภัณฑ์เข้าคลัง
            </v-col>
            <v-col cols="2 text-right">
              <v-btn type="button" icon class="" @click="dialog_product = false">
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="" style="height: 500px;">
          <v-row v-row class=" my-1">
            <v-col cols="12 pa-0 pb-1">
              <v-text-field color="#D9D9D9" class="input-radius-search mt-3"
                prepend-inner-icon="mdi-magnify" v-model="searchPO" placeholder="ค้นหาที่นี่..." hide-details="auto"
                single-line outlined dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-radio-group v-model="dialogm1" column>
            <v-row class="border-radio mb-1 lot_product" v-for="item in data" :key="item">
              <v-col cols="7 flex-center py-1" style="border-right: 1px solid #D9D9D9 !important;">
                <div>เลขล็อตสินค้า</div>
                  <v-radio v-for="(detail) in item.product_lot" :key="detail" @click="product_id = detail.product_id, product_lot_id = detail.id, radioPoValue = 1"
                    :value="detail.id" color="#FFBC15" :label="detail.product_lot" class="mb-2"></v-radio>
              </v-col>
              <v-col cols="5" class="flex-center-radio text-center">
                <v-img :src="item.pro_pic == null ? img_mock : item.pro_pic" max-height="220"></v-img>
                <div>{{ item.pro_name }}</div>
                <div>รหัสบรรจุภัณฑ์ภัณฑ์ : {{ item.pro_code }}</div>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="flex-center-button div-submit-data">
          <v-btn class="white--text my-3 " width="140" color="#88B358" @click="saveLot();" elevation="4" rounded large
            :disabled="radioPoValue === null">
            <v-icon left>mdi-content-save</v-icon> ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import img_mock from "@/assets/logo_sticky.png";
import process_package from '@/api/process_package/package.js'
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading_page: true,
      page: 1,
      pageSize: 1,
      dialog_product: false,
      cancel_id: null,
      cancel_status_process: null,
      cancel_reason: null,
      cancel_producttion_code: null,
      dialog_cancel: false,
      img_mock: img_mock,
      radioPoValue: null,
      search: '',
      searchPO: '',
      sub_menu: [
        {
          text: 'การบรรจุภัณฑ์',
          disabled: false,
          href: '#',
        },
      ],
      dataList: [],
      data: [],
      product_id: null,
      product_lot_id: null,

    }
  },
  methods: {
    linkToCreate() {
      this.$router.push('/pac/processing-packaging-step')
    },
    linkPage(link) {
      this.$router.push(link)
    },
    open_model_cancel(id, name) {
      this.dialog_cancel = true
      this.cancel_id = id
      this.cancel_status_process = 8
      this.cancel_producttion_code = name;
    },
    async getPackingCancel() {

      if (this.$refs.form_cancel.validate()) {
        this.loading_page = true

        let data = {
          id: this.cancel_id,
          status_packing: this.cancel_status_process,
          cancel_reason: this.cancel_reason,
        };

        this.test = data

        process_package.getPackingCancel(data).then((response) => {

          if (response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                  this.getpackingList()
                  this.dialog_cancel = false
                  // this.$router.push('/pro/processing_product_good/')
                  window.location.reload();

                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.getpackingList()
                this.dialog_cancel = false
                // this.$router.push('/pro/processing_product_good/')
                window.location.reload();
              }
            });
          }
        })
      }
    },
    async getpackingList() {
      await process_package.getpackingList(this.page, this.search).then((response) => {
        this.loading_page = false
        this.dataList = response.data.data
        this.dataList = this.dataList.map(item => {
          return {
            ...item,
            status_process_percent: item.status_process_percent == 'เสร็จสิ้น' ? 100 : item.status_process_percent
          }
        })
        this.pageSize = response.data.pages
        this.page = parseFloat(response.data.currentPage)
      })
    },
    async getProduct() {
      await process_package.getProduct(this.searchPO).then((response) => {
        if (response.data.successful === true) {
          this.data = response.data.data
        }
      })
    },
    async saveLot() {
      this.loading_page = true;
      let data = {
        product_id: this.product_id,
        product_lot_id: this.product_lot_id
      }
      await process_package.saveLot(data).then((response) => {
        if (response.data.successful === true) {
          this.$router.push('/pac/processing-packaging-step-create/' + response.data.id)
        }
      })
    }
  },
  mounted() {
    this.getProduct()
    this.getpackingList()
  },
  watch: {
    page() {
      this.getpackingList()
    },
    searchPO() {
      this.getProduct()
    }
  }
}
</script>

<style scoped>
.lot_product {
  padding: 10px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
}

.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.search-input {
  width: 25%;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 0% 12% 16% 23% 17% 16% 16%;
  padding: 5px 0 5px 30px;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 1% 14% 15% 20% 20% 15% 15%;
  align-items: center;
}

.w-border-pre-black {
  height: 67px;
  background: #34230F;
  border: 1px solid #34230F;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.w-border-pre-yellow {
  height: 67px;
  background: #FFBC15;
  border: 1px solid #FFBC15;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.v-progress-linear--rounded {
  border-radius: 20px;
}

.progress-color {
  border: 1px solid #3f9629;
}

.grid-msg {
  display: grid;
  grid-template-columns: 100%;
}

.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}

.flex-center-radio {
  display: flex;
  flex-direction: column;

  align-items: center;
}
</style>