<template>
    <div>
      <OrderPage/>
    </div>
  </template>
  
  <script>
  import OrderPage from '@/components/stock/order/OrderListComponent'
  export default {
    name: 'orderList',
    components:{
      OrderPage,
    }
  }
  </script>