<template>
    <div>
        <Warehouse_page_unpackaged_raw_material_procress />
    </div>
</template>
  
<script>
import Warehouse_page_unpackaged_raw_material_procress from '@/components/stock/warehouse_stock/WarehouseUnpackagedRawMaterialProcress'
export default {
    name: 'WarehouseunpackagedRawMaterial',
    components: {
        Warehouse_page_unpackaged_raw_material_procress,
    }
}
</script>