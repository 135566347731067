<template>
  <div>
    <ProductionResultCreate/>
  </div>
</template>

<script>
import ProductionResultCreate from "@/components/production_process/processing_raw_materials/ProductionResultCreate";
export default {
  name: "Production_result_create",
  components: {ProductionResultCreate}
}
</script>

<style scoped>

</style>