<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">ซัพพลายเออร์ </h4>
                    <v-breadcrumbs :items="sub_menu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <b>รายการซัพพลายเออร์</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" @keyup="searchList()"
                                    prepend-inner-icon="mdi-magnify" v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                                <div class="d-flex justify-end">
                                    <v-btn class="mt-2" large rounded outlined color="#88B358"
                                        @click="openSupplierAddDialog">
                                        <v-icon left>mdi-plus</v-icon>
                                        เพิ่มซัพพลายเออร์
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="table-header-supplier-box-data display-pc" style="color: #b1adad;">
                                    <div class="table-header-supplier-box-data-text"></div>
                                    <div class="table-header-supplier-box-data-text">
                                        ชื่อซัพพลายเออร์
                                    </div>
                                    <div class="table-header-supplier-box-data-text">
                                        เบอร์โทร
                                    </div>
                                    <div class="table-header-supplier-box-data-text">
                                        รายละเอียด
                                    </div>
                                    <div class="table-header-supplier-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius"
                                    v-for="(supplierList, index) in supplierList " :key="index">
                                    <div class="grid-supplier-box-items">
                                        <div class="w-border"></div>
                                        <div>{{ supplierList.sup_name }}</div>
                                        <div>{{ supplierList.sup_tel }}</div>
                                        <div>{{ supplierList.sup_detail }}</div>
                                        <div>
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>

                                                    <v-list-item link @click="detailSupplier(supplierList.id)">
                                                        <v-list-item-title class="f-14" style="color:#FEA06C">
                                                            <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                                                            แก้ไขข้อมูล
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item link @click="deleteSupplier(supplierList.id)">
                                                        <v-list-item-title class="f-14" style="color:#F28080">
                                                            <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                                                            ลบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                        color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- เพิ่มข้อมูล -->
        <v-dialog v-model="supplier_add_dialog" transition="dialog-top-transition" max-width="667"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>เพิ่มซัพพลายเออร์</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="supplier_add_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-form ref="form_add" @submit.prevent="supplierAdd()">
                    <v-card-text>
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-domain
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ข้อมูลซัพพลายเออร์</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อซัพพลายเออร์"
                                                color="#FFBC15" :rules="[v => !!v || 'กรุณากรอกชื่อซัพพลายเออร์']"
                                                v-model="supplierDetail.name" dense outlined rounded
                                                required></v-text-field>
                                        </v-form-group>
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius" v-model="supplierDetail.telephone"
                                                @keypress="isTelephone($event)" color="#FFBC15" label="เบอร์โทร"
                                                minlength="10" maxlength="10" :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                                                placeholder="เบอร์โทร" dense outlined rounded
                                                required></v-text-field>
                                        </v-form-group>
                                        <v-form-group>
                                            <v-textarea class="input-radius" label="รายละเอียด" color="#FFBC15" dense
                                                outlined v-model="supplierDetail.details" rounded rows="3"
                                                row-height="25"></v-textarea>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>


                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการเพิ่ม -->
        <!-- แก้ไขข้อมูล -->
        <v-dialog v-model="supplier_edit_dialog" transition="dialog-top-transition" max-width="667"
            style="border-radius:20px;">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>แก้ไขซัพพลายเออร์</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="supplier_edit_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-form ref="form_add" @submit.prevent="supplierEdit()">
                    <v-card-text>
                        <div class="ml-3 text-name-title">ผู้แก้ไขล่าสุด :
                            <span class="text-fullname-title">{{ supplierDetail.name }}</span> <span
                                class="text-date-title">วันที่
                                :</span>
                            <span class="text-datetime-title" v-if="supplierDetail.updated_at == null">
                                {{ supplierDetail.updated_at }}
                            </span>
                            <span v-else>
                                {{`${(supplierDetail.updated_at.split('-')[2]).split(' ')[0]}-${supplierDetail.updated_at.split('-')[1]}-${supplierDetail.updated_at.split('-')[0]} ${(supplierDetail.updated_at.split('-')[2]).split(' ')[1]}`}}
                            </span>
                        </div>
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32 ">
                                    <v-icon dark>
                                        mdi-domain
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">ข้อมูลซัพพลายเออร์</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-text-field type="text" class="input-radius" label="ชื่อซัพพลายเออร์"
                                                v-model="supplierDetail.sup_name" color="#FFBC15"
                                                :rules="[v => !!v || 'กรุณากรอกชื่อซัพพลายเออร์']" dense outlined rounded required></v-text-field>
                                        </v-form-group>
                                        <v-form-group>
                                            <v-text-field type="tel" class="input-radius" @keypress="isTelephone($event)"
                                                color="#FFBC15" label="เบอร์โทร" v-model="supplierDetail.sup_tel"
                                                minlength="10" maxlength="10" :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                                                placeholder="เบอร์โทร" dense outlined rounded
                                                required></v-text-field>
                                        </v-form-group>
                                        <v-form-group>
                                            <v-textarea class="input-radius" label="รายละเอียด" color="#FFBC15" dense
                                                outlined rounded rows="3" row-height="25"
                                                v-model="supplierDetail.sup_detail"></v-textarea>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการแก้ไข -->
        <!-- ลบข้อมูล -->
        <v-dialog v-model="confirm_delete_dialog" transition="dialog-top-transition" max-width="383"
            style="border-radius:20px;">

            <v-card>
                <div class="d-flex justify-end">
                    <v-btn type="button" icon class="ma-4" @click="confirm_delete_dialog = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <center>
                                <img src="@/assets/bin.png">
                                <h2 style="color:#000000">ลบข้อมูล</h2>
                                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                                <v-btn class="btn-delete-data" elevation="4" rounded x-large
                                    @click="confirmDeleteSupplier()">
                                    <v-icon style="font-size:28px;" left>mdi-delete-forever</v-icon>
                                    ลบข้อมูล
                                </v-btn>
                            </center>
                            <div class="text-center mt-3">
                                <v-btn style="font-size:14px;" text @click="confirm_delete_dialog = false">
                                    ยกเลิก
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ลบข้อมูล -->
    </div>
</template>
<script>
import { onlyForNumber, isTelephone } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import supplier from '@/api/warehouse/supplier.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            loading_detail_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            sub_menu: [
                {
                    text: 'คลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลเกี่ยวกับคลัง',
                    disabled: false,
                },
                {
                    text: 'ตั้งค่าข้อมูลวัตถุดิบ',
                    disabled: false,
                },
                {
                    text: 'ซัพพลายเออร์',
                    disabled: false,
                    href: '#',
                },
            ],
            list: null,
            page: 1,
            pageSize: 1,
            supplier_add_dialog: false,
            supplier_edit_dialog: false,
            confirm_delete_dialog: false,
            supplierList: [],
            supplierDetail: [],
            search: '',
            supplier_delete_id: null,
            data: [],
        }
    },

    methods: {
        link(link) {
            this.$router.push(link)
        },
        isOnlyNumber: function (evt, number) {
            return onlyForNumber(evt, number)
        },
        isTelephone(evt) {
            return isTelephone(evt)
        },
        openSupplierAddDialog() {
            this.supplierDetail.name = ''
            this.supplierDetail.telephone = ''
            this.supplierDetail.details = ''
            this.supplier_add_dialog = true; // เปิด dialog
        },
        async supplierAdd() {
            if (this.$refs.form_add.validate()) {
                this.loading_page = true
                const data = {
                    "sup_name": this.supplierDetail.name,
                    "sup_tel": this.supplierDetail.telephone,
                    "sup_detail": this.supplierDetail.details
                }

                supplier.addSupplier(data).then((response) => {
                    if (response.data.successful === true) {
                        this.supplierDetail.name = ''
                        this.supplierDetail.telephone = ''
                        this.supplierDetail.details = ''
                        this.supplier_add_dialog = false
                        this.loading_page = false
                        this.alert_message_success = 'เพิ่มซัพพลายเออร์ เรียบร้อยแล้ว'
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.dataList_onInit()
                    }
                })
            }
        },
        async dataList_onInit() {
            supplier.dataList(this.page, '', this.search).then((response) => {
                if (response.data.successful === true) {
                    this.supplierList = response.data.supplierList
                    this.pageSize = response.data.pages
                    this.page = parseInt(response.data.currentPage)
                }
            })
        },
        async detailSupplier(id) {
            this.supplierDetail.name = ''
            this.supplierDetail.telephone = ''
            this.supplierDetail.details = ''
            supplier.detail(id).then((response) => {
                if (response.data.successful === true) {
                    this.supplierDetail = response.data.detail
                    this.supplier_edit_dialog = true
                }
            })
        },
        async supplierEdit() {
            this.loading_page = true
            if (this.$refs.form_add.validate()) {
                const data = {
                    "sup_name": this.supplierDetail.sup_name,
                    "sup_tel": this.supplierDetail.sup_tel,
                    "sup_detail": this.supplierDetail.sup_detail
                }

                supplier.edit(this.supplierDetail.id, data).then((response) => {
                    if (response.data.successful === true) {
                        this.supplierDetail.name = ''
                        this.supplierDetail.telephone = ''
                        this.supplierDetail.details = ''
                        this.supplier_edit_dialog = false
                        this.loading_page = false
                        this.alert_message_success = 'แก้ไขข้อมูลเรียบร้อย'
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                        this.dataList_onInit()
                    }
                })
            }
        }
        ,
        async deleteSupplier(id) {
            this.supplier_delete_id = id
            this.confirm_delete_dialog = true
        },
        async confirmDeleteSupplier() {
            this.loading_page = true
            supplier.delete(this.supplier_delete_id).then((response) => {
                if (response.data.successful === true) {
                    this.confirm_delete_dialog = false
                    this.loading_page = false
                    this.supplier_delete_id = null
                    this.dataList_onInit()
                    this.alert_message_success = 'ลบข้อมูลเรียบร้อย'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            })
        },
        async searchList() {
            this.page = 1
            this.supplierList = []
            await supplier.dataList(this.page, '', this.search).then((response) => {
                this.supplierList = response.data.supplierList
                this.pageSize = response.data.pages
                this.page = parseInt(response.data.currentPage)
            })
        }
    },
    components: {
        navigationmenu
    },
    mounted() {
        this.dataList_onInit()
    },
    watch: {
        page() {
            this.dataList_onInit()
        }
    }
}
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.table-header-supplier-box-data {
    display: grid;
    grid-template-columns: 2% 25% 25% 25% 23%;
    padding: 5px 0px 5px 30px;
}

.table-header-supplier-box-data-text {
    color: #686868;
    font-size: 14px;
    margin-left: 20px;
}

.grid-supplier-box-items {
    display: grid;
    grid-template-columns: 5% 25% 24% 34% 12%;
    align-items: center;
}
</style>