<template>
  <div>
    <ProductionConfirm/>
  </div>
</template>

<script>
import ProductionConfirm from "@/components/production_process/processing_raw_materials/ProductionConfirm";
export default {
  name: "Production_confirm",
  components: {ProductionConfirm}
}
</script>

<style scoped>

</style>