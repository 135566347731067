import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
      //ส้วนที่ 6
      getDetail_step1(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/production/get/detail/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
      saveStep6(id) {
        return new Promise((resolve, reject) => {
          HTTP.get(`/production/confirm/production/sucess/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
      },
}
     
     