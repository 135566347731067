<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">การผลิตสินค้า</div>
      <div class="topic_processing_content my-2">การผลิตสินค้า</div>
    </div>

    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>รายการรอบการผลิตสินค้า</b>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div class="search-input">
              <v-text-field color="#D9D9D9" class="input-radius-search mt-3" prepend-inner-icon="mdi-magnify"
                placeholder="ค้นหาที่นี่..." @keyup="searchList()" v-model="search" hide-details="auto" single-line
                outlined dense></v-text-field>
            </div>
            <div>

              <v-menu transition="slide-y-transition" offset-y>

                <template v-slot:activator="{ on, attrs }" v-if="$store.state.profileDetail.per_production == 1 || $store.state.profileDetail.permission == 1">
                  <v-btn class="mt-2" v-bind="attrs" v-on="on" large rounded outlined color="#88B358" Dropdown>
                    <v-icon left>mdi-plus</v-icon>สร้างรอบการผลิตสินค้า <v-icon right
                      class="text-30">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <template v-slot:activator="{ not, onarr }" v-else>
                  <v-btn class="mt-2" v-bind="onarr" v-on="not" large rounded color="#D9D9D9" Dropdown>
                    <v-icon left>mdi-plus</v-icon>สร้างรอบการผลิตสินค้า <v-icon right
                      class="text-30">mdi-menu-down</v-icon>
                  </v-btn>
                </template>

                <v-list v-if="$store.state.profileDetail.per_production == 1 || $store.state.profileDetail.permission == 1">
                  <v-list-item @click="dialog_product_in = true">
                    <v-list-item-title>ผลิตสินค้าเข้าคลัง</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="dialog_product_in_po = true">
                    <v-list-item-title>ผลิตสินค้าตามคำสั่งซื้อ</v-list-item-title>
                  </v-list-item>
                </v-list>

              </v-menu>

            </div>
          </div>
          <div class="table-header-processing mt-5" style="color: #b1adad;">
            <div class="table-sub-header-processing"></div>
            <div class="table-sub-header-processing">
              รหัสการผลิต
            </div>
            <div class="table-sub-header-processing pl-3">
              วันที่ผลิต
            </div>
            <div class="table-sub-header-processing ">
              สินค้าที่ต้องการผลิต
            </div>
            <div class="table-sub-header-processing ">
              สถานะการผลิต
            </div>
            <div class="table-sub-header-processing ">
              ผู้สั่งผลิต
            </div>
            <div class="table-sub-header-processing text-center">
              จัดการ
            </div>
          </div>
          <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in dataList" :key="index">
            <div class="grid-processing-items">
              <div class="w-border"></div>
              <div class="d-flex ml-8">
                <div>{{ detail.production_code }}</div>
                <div v-if="detail.note_msg" class="ml-5">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#a3a3a3" dark v-bind="attrs" v-on="on">
                        mdi-message-bulleted
                      </v-icon>
                    </template>
                    <div class="grid-msg">
                      <div class="text--left">{{ detail.note_msg.time_stamp }}</div>
                      <div class="text--center">หมายเหตุการยกเลิกการผลิต: {{ detail.note_msg.note }}</div>
                      <div style="text-align: end">โดย {{ detail.note_msg.user_create }}</div>
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="" v-if="detail.production_date != '-'">{{
                `${detail.production_date.split('/')[0]}-${detail.production_date.split('/')[1]}-${detail.production_date.split('/')[2]}`
              }}</div>
              <div v-else>-</div>
              <div class="">{{ detail.product_name }}</div>
              <div v-if="detail.status_process != 8" class="ml-2">
                <v-progress-linear v-if="detail.status_process_percent == 'เสร็จสิ้น'" class="progress-color" color="#3f9629" rounded v-model="successStatus"
                  height="25"><strong style="color: #FFFFFF">100%</strong></v-progress-linear>
                  <v-progress-linear v-else class="progress-color" color="#3f9629" rounded v-model="detail.status_process_percent"
                  height="25"><strong style="color: #FFFFFF">{{ Math.ceil(detail.status_process_percent)
                  }}%</strong></v-progress-linear>
              </div>
              <div v-else class="ml-2">
                <v-chip class="white--text" color="#f28080">ยกเลิกการผลิตสินค้า</v-chip>
              </div>
              <div class="text-center">{{ detail.user_order_production }}</div>

              <div class="text-center">
                <v-menu rounded="b-xl" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item >
                      <!-- @click="linkPage('/prm/processing_raw_material_details')" -->
                      <v-list-item-title class="f-14" style="color: #6c7fd8"
                        @click="linkPage('/pro/processing_product_good_confirm/' + detail.id + '/0')">
                        <v-icon left style="color:#6c7fd8">mdi-alert-circle-outline</v-icon>
                        ดูรายละเอียด
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="detail.status_process <= 6">
                      <v-list-item-title class="f-14" style="color:#FEA06C"
                        @click="linkPage('/pro/processing_product_good_steps/' + detail.id)">
                        <v-icon left style="color:#FEA06C">mdi-pencil</v-icon>
                        แก้ไขข้อมูล
                      </v-list-item-title>
                    </v-list-item>
                    <span v-if="$store.state.profileDetail.per_production_cancel == 1 || $store.state.profileDetail.permission == 1">
                      <v-list-item v-if="detail.status_process <= 3"
                        @click="open_model_cancel(detail.id, detail.production_code)">
                        <v-list-item-title class="f-14" style="color:#F28080">
                          <v-icon left style="color:#F28080">mdi-delete-forever</v-icon>
                          ลบ
                        </v-list-item-title>
                      </v-list-item>
                    </span>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-card>
          <div class="text-center mt-5">
            <v-pagination v-model="page" :length="pageSize" :total-visible="7" color="#FFBC15"></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <v-dialog v-model="dialog_cancel" transition="dialog-top-transition" max-width="800" style="border-radius:20px">
      <v-form class="" ref="form_cancel" @submit.prevent="getProductionCancel()">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title><b>ยกเลิกรายการผลิต</b></v-card-title>
            <v-btn type="button" icon class="ma-4" @click="dialog_cancel = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-card elevation="4" style="border-radius:20px">
              <v-card-title class="d-flex align-items-center">
                <v-avatar color="#34230F" size="36">
                  <img class="icon-image-logo" src="@/assets/warning1.png">
                </v-avatar>
                <span class="ml-3 f-18">เหตุผลการยกเลิกการผลิต {{ cancel_producttion_code }}</span>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-3" color="#686868"></v-divider>
                <div class="ma-4">
                  <v-textarea v-model="cancel_reason" color="#686868" placeholder="เหตุผลการยกเลิกการผลิต" :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                    hide-details="auto" outlined rounded required></v-textarea>
                </div>
              </v-card-text>
            </v-card>
            <div class="mt-3 text-center">
              <v-btn type="submit" class="white--text" color="#88B358" elevation="4" rounded large
                @click="getProductionCancel()">
                <v-icon left>mdi-content-save</v-icon> บันทึก
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>


    <v-dialog v-model="dialog_product_in" scrollable max-width="500px">
      <v-form class="" ref="form_add" @submit.prevent="getProductionAdd()">
        <v-card>
          <v-card-title class="">
            <v-row class="">
              <v-col cols="10">
                เลือกสินค้าที่ต้องการสั่งผลิตเข้าคลัง
              </v-col>
              <v-col cols="2 text-right">
                <v-btn type="button" icon class="" @click="dialog_product_in = false">
                  <v-icon color="grey">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="" style="height: 500px;">
            <v-row v-row class=" my-1">
              <v-col cols="12 pa-0 pb-1">
                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" prepend-inner-icon="mdi-magnify"
                  @keyup="searchListPO()" v-model="searchPO" placeholder="ค้นหาที่นี่..." hide-details="auto" single-line
                  outlined dense>
                </v-text-field>
              </v-col>
            </v-row>

            <v-radio-group v-model="radio_product" column>
              <v-row class="border-radio mb-1" v-for="(listProductList, index) in dataProductList" :key="index">
                <v-col cols="3 flex-center py-1">
                  <v-radio :value="listProductList.id" color="#FFBC15" class="mb-0" @click="checkRadio()"></v-radio>
                  <v-img width="60" :src="listProductList.pro_pic == null ? image_logo : listProductList.pro_pic"></v-img>
                </v-col>
                <v-col cols="9 flex-center-radio">
                  <div>{{ listProductList.pro_name }}</div>
                  <div>รหัสผลิตภัณฑ์ : {{ listProductList.pro_code }} </div>
                </v-col>
              </v-row>

            </v-radio-group>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="flex-center-button">
            <v-btn type="submit" class="white--text my-3 " width="140" color="#88B358" elevation="4" rounded large
              :disabled="radioValue === null">
              <v-icon left>mdi-content-save</v-icon> ตกลง
            </v-btn>
            <!-- @click="linkPage('/pro/processing_product_good_steps')" -->
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>


    <v-dialog v-model="dialog_product_in_po" scrollable max-width="700px">
      <v-form ref="form_add_po" @submit.prevent="getProductionAddPO()">
        <v-card>
          <v-card-title class="">
            <v-row class="">
              <v-col cols="10">
                เลือกสินค้าที่ต้องการสั่งผลิตเข้าคลัง
              </v-col>
              <v-col cols="2 text-right">
                <v-btn type="button" icon class="" @click="dialog_product_in_po = false">
                  <v-icon color="grey">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="" style="height: 500px;">
            <v-row v-row class=" my-1">
              <v-col cols="12 pa-0 pb-1">
                <v-text-field color="#D9D9D9" class="input-radius-search mt-3" prepend-inner-icon="mdi-magnify"
                  @keyup="getPO()" v-model="searchGetPO" placeholder="ค้นหาที่นี่..."
                  hide-details="auto" single-line outlined dense>
                </v-text-field>
              </v-col>
            </v-row>

            <v-radio-group v-model="dialogm1" column v-for="item in PO_list" :key="item">
              <v-row class="border-radio mb-1">
                <v-col cols="3 flex-center py-1">
                  <v-radio :value="item.id" color="#FFBC15" class="mb-0" @click="checkPoRadio()"></v-radio>
                  <v-img width="60" :src="item.pro_pic == null ? image_logo : item.pro_pic"></v-img>
                </v-col>
                <v-col cols="9 flex-center-radio">
                  <div>{{ item.pro_name }}</div>
                  <div>รหัสผลิตภัณฑ์ :{{ item.pro_code }}</div>
                </v-col>
                <v-col cols="12">
                  <v-divider class="" dark color="#a3a3a3"></v-divider>
                </v-col>

                <v-row>

                  <v-col cols="12 px-10 pb-5">
                    <v-row v-for="detail in item.list_size" :key="detail">
                      <v-col cols="3 text-center"> {{ detail.pro_amount }} กรัม</v-col>
                      <v-col cols="9">
                        <v-row v-for="sub in detail.order_list" :key="sub">
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="8">หมายเลขคำสั่งซื้อ : {{ sub.order_code }}</v-col>
                              <v-col cols="4">จำนวน : {{ sub.qty }} {{ sub.unit_name }}</v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>

              </v-row>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="flex-center-button">
            <v-btn type="submit" class="white--text my-3 " width="140" color="#88B358" elevation="4" rounded large
              :disabled="radioPoValue === null">
              <v-icon left>mdi-content-save</v-icon> บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import img_mock from "@/mock/img/mock1.png";
import production from "@/api/product_good/product_process.js";
// import { formatPrice } from "@/helper/handler";
import Swal from "sweetalert2";
import image_logo from "@/assets/logo_sticky.png";
export default {
  name: "ProcessingMaterials",
  data() {
    return {
      alert_success: false,
      loading_page: false,
      cancel_id: null,
      cancel_status_process: null,
      cancel_reason: null,
      cancel_producttion_code: null,
      radioValue: null,
      radioPoValue: null,
      successStatus:100,
      page: 1,
      pageSize: 1,
      search: '',
      searchPO: '',
      searchGetPO: '',
      dialog_cancel: false,
      dialog_product_in: false,
      dialog_product_in_po: false,
      img_mock: img_mock,
      radio_product: null,
      dataList: [],
      dataProductList: [],
      PO_list: [],
      dialogm1: null,
      image_logo: image_logo,

      test: []
    }
  },
  methods: {
    linkToCreate() {
      this.$router.push('/prm/processing_create')
    },
    linkPage(link) {
      this.$router.push(link)
    },
    checkRadio() {
      this.radioValue = 1
    },
    checkPoRadio() {
      this.radioPoValue = 1
    },
    open_model_cancel(id, name) {
      this.dialog_cancel = true
      this.cancel_id = id
      this.cancel_status_process = 8
      this.cancel_producttion_code = name;
    },

    async getProductionList() {
      production.getProductionList(this.page, '', this.search).then((response) => {
        this.loading_page = false
        this.dataList = response.data.data
        this.dataList = this.dataList.map(item => {
          return {
            ...item,
            status_process_percent: item.status_process_percent == 'เสร็จสิ้น' ? item.status_process_percent=100 : item.status_process_percent
          }
        })
        this.pageSize = response.data.pages
        this.page = parseFloat(response.data.currentPage)
      })
    },

    async getProductDialogList() {
      production.getProductDialogList(this.search).then((response) => {
        this.loading_page = false
        this.dataProductList = response.data.data
      })
    },

    async getProductionCancel() {

      if (this.$refs.form_cancel.validate()) {
        this.loading_page = true

        let data = {
          id: this.cancel_id,
          status_process: this.cancel_status_process,
          cancel_reason: this.cancel_reason,
        };

        this.test = data

        production.getProductionCancel(data).then((response) => {

          if (response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                  this.getProductionList()
                  this.dialog_cancel = false
                  // this.$router.push('/pro/processing_product_good/')
                  window.location.reload();

                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.getProductionList()
                this.dialog_cancel = false
                this.$router.push('/pro/processing_product_good/')
                window.location.reload();
              }
            });
          }
        })
      }
    },

    async getProductionAdd() {

      if (this.$refs.form_add.validate()) {
        this.loading_page = true

        production.getProductionAdd(null, this.radio_product).then((response) => {

          if (response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                  this.getProductionList()
                  this.dialog_product_in = false
                  this.$router.push('/pro/processing_product_good_steps/' + response.data.id)

                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.getProductionList()
                this.dialog_product_in = false
                this.$router.push('/pro/processing_product_good_steps/' + response.data.id)
              }
            });
          }
        })
      }
    },
    async getProductionAddPO() {

      if (this.$refs.form_add_po.validate()) {
        this.loading_page = true
        let check = this.PO_list.find(o => o.id === this.dialogm1);
        let data = {};
        if (check != null) {
          data = {
            list_size: check.list_size,
          }
        }
        production.getProductionAddPO(this.dialogm1, data).then((response) => {

          if (response.data.successful === true) {
            let timerInterval;
            Swal.fire({
              title: "ทำรายการเสร็จสิ้น",
              icon: "success",
              html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                  this.getProductionList()
                  this.dialog_product_in = false
                  this.$router.push('/pro/processing_product_good_steps/' + response.data.id)

                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.getProductionList()
                this.dialog_product_in = false
                this.$router.push('/pro/processing_product_good_steps/' + response.data.id)
              }
            });
          }
        })
      }
    },
    async searchList() {
      await production.getProductionList(this.page, '', this.search).then((response) => {
        this.loading_page = false
        this.dataList = response.data.data
        this.pageSize = response.data.pages
        this.page = parseFloat(response.data.currentPage)
      })
    },
    async searchListPO() {
      await production.getProductDialogList(this.searchPO).then((response) => {
        this.loading_page = false
        this.dataProductList = response.data.data
      })
    },
    getPO() {
      production.getPO().then((response) => {
        if (response.data.successful === true) {
          this.PO_list = response.data.data;
          const pattern = new RegExp(this.searchGetPO, 'i');
          this.PO_list = this.PO_list.filter((item) => pattern.test(item.pro_name));
        }


      })
    }
  },
  mounted() {
    this.getProductionList();
    this.getProductDialogList();
    this.getPO();
  },
  watch: {
    page() {
      this.getProductionList();
    }
  },
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.input-radius-search {
  border-radius: 15px;
  font-weight: 500;
}

.search-input {
  width: 25%;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 1% 14% 16% 19% 25% 11% 14%;
  padding: 5px 0 5px 30px;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 20px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 2% 16% 15% 18% 20% 16% 13%;
  align-items: center;
}

.w-border-pre-black {
  height: 67px;
  background: #34230F;
  border: 1px solid #34230F;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.w-border-pre-yellow {
  height: 67px;
  background: #FFBC15;
  border: 1px solid #FFBC15;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 36px;
  width: 20px;
}

.v-progress-linear--rounded {
  border-radius: 20px;
}

.progress-color {
  border: 1px solid #3f9629;
}

.grid-msg {
  display: grid;
  grid-template-columns: 100%;
}

.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}

.text-30 {
  font-size: 30px !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-button {
  display: flex;
  justify-content: center;
}

.flex-center-radio {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.border-radio {
  border: 1px solid #cfcfcf;
  border-radius: 15px;
}
</style>