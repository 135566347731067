<template>
    <div class="loading_all_page" v-if="loading_page === true">
        <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
        <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="responsive-content" v-else>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <navigationmenu />
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <div class="title_page">
                    <h4 class="breadcrumb_title_page">รายละเอียดสินค้า </h4>
                    <v-breadcrumbs :items="computedSubMenu">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <v-card elevation="4" class="card-radius">
                    <v-card-title>
                        <v-row>
                            <v-col cols="5">
                                <b>รายละเอียดสินค้า</b>
                            </v-col>
                            <v-col cols="7 text-right">
                                <b>{{ product_data.pro_name }} <span class="ml-3 mr-3">{{
                                    formatPrice(product_data.pro_instock) }}</span>
                                   </b>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div class="">
                                    <v-btn class="mt-2 ml-0 mx-5 radius-15 text-16" elevation="2" large outlined
                                        color="#FFBC15">
                                        LOT คงเหลือ
                                    </v-btn>
                                    <v-btn class="mt-2 mx-5 radius-15 text-grey text-16" elevation="" large color="#fff"
                                        @click="link('/stock/warehouse-stock/warehouse-packaged-detail-lot-used/' + parseFloat($route.params.id))">
                                        LOT ที่ใช้หมดแล้ว
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-text-field color="#D9D9D9" class="input-radius-search" prepend-inner-icon="mdi-magnify"
                                     v-model="search" placeholder="ค้นหาที่นี่..."
                                    hide-details="auto" single-line outlined dense>
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <div :class="'table-header-warehousedetail-box-data-' + mapsize.length + ' display-pc'"
                                    style="color: #b1adad;">
                                    <div class="table-header-warehousedetail-box-data-text"></div>
                                    <div class="table-header-warehousedetail-box-data-text">
                                        วันที่นำเข้า/แปรรูป
                                    </div>
                                    <div class="table-header-warehousedetail-box-data-text">
                                        LOT
                                    </div>
                                    <div class="table-header-warehousedetail-box-data-text"
                                        v-for="(detail, index_mapsize) in mapsize" :key="index_mapsize">
                                        {{ detail.pro_size_name }}
                                    </div>
                                    <div class="table-header-warehousedetail-box-data-text text-center">
                                        จัดการ
                                    </div>
                                </div>
                            </v-col>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                <v-card elevation="0" class="card-table-radius" v-for="(detail, index) in data"
                                    :key="index">
                                    <div
                                        :class="'grid-warehousedetail-box-items-' + mapsize.length + ' grid-warehousedetail-box-loop'">
                                        <div class="w-border"></div>
                                        <div>{{
                                            `${detail.import_date.split('/')[0]}-${detail.import_date.split('/')[1]}-${detail.import_date.split('/')[2]}`
                                        }}</div>
                                        <div>{{ detail.product_lot }}</div>
                                        <div v-for="(items, index_items) in detail.size_list" :key="index_items">
                                            {{ (items.pro_amount > 0) ? formatPrice(items.pro_amount) : '-' }}
                                        </div>
                                        <div class="text-center">
                                            <v-menu rounded="b-xl" offset-y>
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-btn elevation="0" color="normal" v-bind="attrs" v-on="on" fab small>
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#6C7FD8"
                                                            @click="link('/stock/warehouse-stock/warehouse-packaged-detail-raw-material/' + detail.id)">
                                                            <v-icon left style="color:#6C7FD8">mdi-table-large</v-icon>
                                                            รายงานการตรวจสอบ
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item link>
                                                        <v-list-item-title style="color:#FFBC15"
                                                            @click="detail.mat_status == 2 ? link('/stock/warehouse-stock/WarehouseLotSlodOutRawMaterial/'+detail.id) : link('/stock/warehouse-stock/WarehouseLotInStockRawMaterial/'+detail.id)">
                                                            <v-icon left style="color:#FFBC15">mdi-package-variant</v-icon>
                                                            บรรจุภัณฑ์
                                                        </v-list-item-title>
                                                    </v-list-item> -->
                                                    <v-list-item link>
                                                        <v-list-item-title style="color:#F28080"
                                                            @click="export_product_stock_dialog(detail.id)">
                                                            <v-icon left style="color:#F28080">mdi-arrow-right-top</v-icon>
                                                            นำออกจากคลัง
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </v-card>
                                <div class="text-center mt-5">
                                    <v-pagination v-model="page" :length="pageSize" :total-visible="7"
                                         color="#FFBC15"></v-pagination>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- นำสินค้าออกจากคลัง -->
        <v-dialog v-model="export_product_stock" transition="dialog-top-transition" max-width="667"
            style="border-radius:20px;">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card v-if="loading_detail_page === false">
                <div class="d-flex justify-space-between">
                    <v-card-title>
                        <b>นำสินค้าออกจากคลัง</b>
                    </v-card-title>
                    <v-btn type="button" icon class="ma-4" @click="export_product_stock = false">
                        <v-icon color="grey">mdi-close</v-icon>
                    </v-btn>
                </div>
                    <v-card-text>
                <v-form ref="form_add" @submit.prevent="exportproduct()">
                        <v-card elevation="4" style="border-radius:20px;">
                            <v-card-title class="d-flex align-items-center">
                                <v-avatar color="#34230F" size="32">
                                    <v-icon dark>
                                        mdi-archive
                                    </v-icon>
                                </v-avatar>
                                <span class="ml-3 f-18">{{ product_data.pro_name }} (จำนวน {{ pro_amount_remain }})</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider class="mb-3" color="#686868"></v-divider>
                                <v-row style="padding: 15px 70px 0 70px !important;">
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-select :items="export_data" item-text="name"  item-value="id" class="input-radius"  @change="check_pro_amount_remain()" rounded dense v-model="product_mapsize_id" outlined></v-select>
                                            <v-text-field class="input-radius" color="#FFBC15" label="จำนวนที่ต้องการนำออก"
                                                suffix="g" :rules="[v => !!v || 'กรุณากรอกจำนวนที่ต้องการนำออก']" @input="check_pro_amount_remain()"
                                                v-model="count_export" @keypress="isOnlyNumber($event, count_export)"
                                                hide-details="auto" rounded dense outlined required>
                                            </v-text-field>
                                        </v-form-group>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                                        <v-form-group>
                                            <v-textarea class="input-radius" label="เหตุผลการนำออก"
                                                :rules="[v => !!v || 'กรุณากรอกเหตุผลการนำออก']" color="#FFBC15" dense
                                                v-model="note" outlined rounded required rows="3"
                                                row-height="25"></v-textarea>
                                        </v-form-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div class="mt-3 div-submit-data">
                            <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large
                                >
                                <v-icon left>mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </div>
                </v-form>
                    </v-card-text>
            </v-card>
        </v-dialog>
        <!-- สิ้นสุดการนำสินค้าออกจากคลัง -->
    </div>
</template>
<script>
import { onlyForCurrency, formatPrice } from "@/helper/handler";
import navigationmenu from '@/components/menu/VerticalMenu.vue';
import product from '@/api/warehouse/product.js'
import Swal from 'sweetalert2'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: false,
            loading_detail_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            export_product_stock: false,
            test: {},
            count_export: 0,
            note: null,
            page: 1,
            pageSize: 1,
            search: '',
            status: 0,
            type: 0,
            product_data: null,
            header_name: `test test` ,
            data: [],
            mapsize: [],
            export_data: [],
            product_mapsize_id: null,
            pro_amount_remain: 0

            // sub_menu: [
            //     {
            //         text: 'คลังสินค้า',
            //         disabled: false,
            //     },
            //     {
            //         text: 'คลังสินค้าที่บรรจุภัณฑ์แล้วคงเหลือ',
            //         disabled: false,
            //     },
            //     {
            //         text: this.header_name,
            //         disabled: false,
            //     },
            //     {
            //         text: 'รายละเอียดสินค้า',
            //         disabled: false,
            //         href: '#',
            //     },
            // ],

        }
    },

    methods: {
       async check_pro_amount_remain(){
            this.pro_amount_remain = parseFloat(await this.export_data.find(item => item.id === this.product_mapsize_id).pro_amount_remain) < 0 ? '0.00' : await this.export_data.find(item => item.id === this.product_mapsize_id).pro_amount_remain
            if(parseFloat(this.pro_amount_remain) < this.count_export == '' ? 0 : this.count_export){
                this.count_export = parseFloat(this.pro_amount_remain) < 0 ? 0 : parseFloat(this.pro_amount_remain)
            }
        },
        link(link) {
            this.$router.push(link)
        },
        formatPrice(number) {
            return formatPrice(number);
        },
        isOnlyNumber: function (evt, number) {
            return onlyForCurrency(evt, number)
        },
        async getProductLotList() {
            this.loading_page = true
            let id = parseFloat(this.$route.params.id);
           await product.getProductPackagingList(id, this.page, '', this.search, this.type).then((response) => {
                if (response.data.successful === true) {
                    this.product_data = response.data.product;
                    // alert(response.data.product.pro_name)
                    this.header_name = response.data.product.pro_name;
                    this.data = response.data.data
                    this.pageSize = response.data.pages
                    this.page = parseFloat(response.data.currentPage)
                    this.loading_page = false
                }
            })

        },
        getMapSizeList() {
            product.getMapSizeList(this.$route.params.id).then((response) => {
                if (response.data.successful === true) {
                    this.mapsize = response.data.mapsize;
                }
            })
        },
        async export_product_stock_dialog(id) {
            let data = await this.data.find(item => item.id  === id)
            this.export_data = await data.size_list.map(detail => {
               if(detail.pro_amount_remain != null){
                 return {
                    id: detail.product_mapsize_id,
                    name: detail.pro_size_name,
                    product_lot_id: detail.product_lot_id,
                    pro_amount_remain: detail.pro_amount_remain
                }
               }
            })
               this.product_mapsize_id = this.export_data[0].id
                this.pro_amount_remain = parseFloat(await this.export_data.find(item => item.id === this.product_mapsize_id).pro_amount_remain) < 0 ? '0.00' : await this.export_data.find(item => item.id === this.product_mapsize_id).pro_amount_remain
            this.export_product_stock = true
        },
        async exportproduct() {
            let data = {
                product_id: parseFloat(this.$route.params.id),
                product_lot_id: await this.export_data.find(item => item.id === this.product_mapsize_id).product_lot_id,
                product_mapsize_id: this.product_mapsize_id,
                count_export: parseFloat(this.count_export),
                note: this.note
            }
            if (this.$refs.form_add.validate()) {
                product.exportProductPacked(data).then((response) => {
                    if (response.data.successful === true) {
                        let timerInterval
                        Swal.fire({
                            title: 'ทำรายการเสร็จสิ้น',
                            icon: 'success',
                            html: 'ระบบได้บันทึกข้อมูลเรียบร้อยแล้ว.',
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft()
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval)
                            }
                        }).then(() => {
                            this.getProductLotList()
                            this.export_product_stock = false
                        });
                    }
                    this.$refs.form_add.reset()
                })
            }
        },
    },
    mounted() {
        this.getProductLotList();
        this.getMapSizeList();
        console.log('header_name:', this.header_name);
    },
    components: {
        navigationmenu
    },
    watch: {
        page() {
            this.getProductLotList()
            this.getMapSizeList();
        },
        search() {
            this.getProductLotList()
            this.getMapSizeList();
        }
    },
    created() {
    },
    computed: {
        computedSubMenu() {
            return [
                {
                    text: 'คลังสินค้า',
                    disabled: false,
                },
                {
                    text: 'คลังสินค้าที่บรรจุภัณฑ์แล้วคงเหลือ',
                    disabled: false,
                    href: '/stock/warehouse-stock/warehouse-packaged',
                },
                {
                    text: this.header_name || 'Default Text', // Use header_name or a default text
                    disabled: false,
                },
                {
                text: 'รายละเอียดสินค้า',
                    disabled: false,
                    href: '#',
                },
            ];
        },
    },
 }
</script>
<style scoped>
.input-radius-search {
    border-radius: 15px;
    font-weight: 500;
}

.team-text-title-card {
    font-weight: 600;
    font-size: 22px;
    color: #34230F;
}

.tab-button.active {
    border-radius: 15px;
    font-size: 16px;
}

.tab-button-disable {
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
}

.checkbox-access {
    margin-top: 0;
    padding-top: 0;
}

.top-to-card {
    position: absolute;
    top: -18px;
}

.text-name-title,
.text-date-title {
    color: #686868;
}

.text-fullname-title,
.text-datetime-title {
    color: #000000;
}

.text-name-title,
.text-fullname-title {
    font-size: 14px;
}

.btn-reset-pasword {
    font-size: 18px;
    margin-top: 20px;
    background-color: #88B358 !important;
    color: #FFFFFF;
}

.btn-delete-data {
    font-size: 18px;
    margin-top: 20px;
    background-color: #F28080 !important;
    color: #FFFFFF;
}

.table-header-warehousedetail-box-data-1 {
    display: grid;
    grid-template-columns: 3% 27% 25% 33% 12%;
    padding: 5px 0px 5px 30px;
}

.grid-warehousedetail-box-items-1 {
    display: grid;
    grid-template-columns: 5% 27% 24% 32% 12%;
    align-items: center;
}

.table-header-warehousedetail-box-data-2 {
    display: grid;
    grid-template-columns: 2% 20% 21% 21% 23% 13%;
    padding: 5px 0px 5px 30px;
}

.grid-warehousedetail-box-items-2 {
    display: grid;
    grid-template-columns: 5% 20% 22% 20% 21% 12%;
    align-items: center;
}

.table-header-warehousedetail-box-data-3 {
    display: grid;
    grid-template-columns: 2% 18% 18% 17% 17% 15% 13%;
    padding: 5px 0px 5px 30px;
}

.grid-warehousedetail-box-items-3 {
    display: grid;
    grid-template-columns: 4% 18% 17% 17% 17% 16% 11%;
    align-items: center;
}

.table-header-warehousedetail-box-data-4 {
    display: grid;
    grid-template-columns: 2% 17% 15% 13% 13% 13% 13% 14%;
    padding: 5px 0px 5px 30px;
}

.grid-warehousedetail-box-items-4 {
    display: grid;
    grid-template-columns: 5% 17% 16% 13% 13% 13% 12% 11%;
    align-items: center;
}


.table-header-warehousedetail-box-data-5 {
    display: grid;
    grid-template-columns: 2% 15% 13% 11% 11% 11% 11% 14% 12%;
    padding: 5px 0px 5px 30px;
}

.grid-warehousedetail-box-items-5 {
    display: grid;
    grid-template-columns: 5% 15% 14% 11% 11% 11% 10% 13% 10%;
    align-items: center;
}

.table-header-warehousedetail-box-data-text {
    color: #686868;
    font-size: 14px;
}
</style>