<template>
    <div>
        <OrderDetail_Page />
    </div>
</template>
  
<script>
import OrderDetail_Page from '@/components/sale/OrderDetail'
export default {
    name: 'OrderDetail',
    components: {
        OrderDetail_Page,
    }
}
</script>