<template>
    <div>
        <WarehouseDetail_LotUsed_page />
    </div>
</template>
  
<script>
import WarehouseDetail_LotUsed_page from '@/components/stock/warehouse_stock/WarehouseDetailLotUsed'
export default {
    name: 'WarehouseDetailLotUsed',
    components: {
        WarehouseDetail_LotUsed_page,
    }
}
</script>