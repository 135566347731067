<template>
    <div>
      <ProcessingPackagingStepRecheck/>
    </div>
  </template>
  
  <script>
  import ProcessingPackagingStepRecheck from "@/components/production_process/procressing_packaging/ProcressingPackagingStepRecheck";
  export default {
    name: "Processing_packaging_step_recheck",
    components: {
      ProcessingPackagingStepRecheck
    }
  }
  </script>
  
  <style scoped>
  
  </style>