<template>
    <div>
        <Warehouse_page_unpackaged />
    </div>
</template>
  
<script>
import Warehouse_page_unpackaged from '@/components/stock/warehouse_stock/WarehouseUnpackaged'
export default {
    name: 'Warehouseunpackaged',
    components: {
        Warehouse_page_unpackaged,
    }
}
</script>