<template>
  <div>
    <ProductionConfirmDetails/>
  </div>
</template>

<script>
import ProductionConfirmDetails
  from "@/components/production_process/processing_raw_materials/ProductionConfirmDetails";
export default {
  name: "Production_confirm_details",
  components: {ProductionConfirmDetails}
}
</script>

<style scoped>

</style>