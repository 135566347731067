<template>
  <div class="loading_all_page" v-if="loading_page === true">
    <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
    <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success }}</v-alert>
    </v-slide-x-reverse-transition>
    <div class="loading_all_page" v-if="loading === true">
      <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
      <h3>กำลังโหลดข้อมูล...</h3>
    </div>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/prm/processing_materials')">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนที่ 2 : สร้างใบเตรียมวัตถุดิบ</b>
        </v-card-title>
        <v-stepper class="ml-10 mr-10" v-model="e1" alt-labels color="#ffbc15" elevation="0">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1" color="#ffbc15">
              <span style="font-size: 14px">ข้อมูลความพร้อมการแปรรูป</span>
            </v-stepper-step>
            <v-stepper-step :complete="e1 > 2" step="2" color="#ffbc15">
              <span style="font-size: 14px">กำหนดรอบการแปรรูป</span>
            </v-stepper-step>
            <v-stepper-step :complete="e1 > 3" step="3" color="#ffbc15">
              <span style="font-size: 14px">ยืนยันการแปรรูป</span>
            </v-stepper-step>
          </v-stepper-header>
          <v-progress-linear color="#ffbc15" background-color="#d9d9d9" v-model="progress_value" height="10" rounded />
          <v-stepper-items class="ml-n5 mr-n5">
            <v-stepper-content step="1">
              <v-card-text>
                <v-card elevation="4" class="py-2" style="border-radius:20px">
                  <v-card-title>
                    <v-avatar color="#34230F" size="36">
                      <v-icon dark>
                        mdi-note-text-outline
                      </v-icon>
                    </v-avatar>
                    <span class="ml-3 title-card-text">ข้อมูลความพร้อมการเตรียมวัตถุดิบ</span>
                  </v-card-title>
                  <v-card-text>
                    <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                    <div class="table-header-processing ml-15" style="color: #b1adad;">
                      <div class="table-sub-header-processing">
                        วัตถุดิบ
                      </div>
                      <div class="table-sub-header-processing">
                        จำนวนวัตถุดิบที่ต้องใช้ (กรัม (g))
                      </div>
                      <div class="table-sub-header-processing">
                        เปรียบเทียบกับวัตถุดิบในคลัง
                      </div>
                      <div class="table-sub-header-processing text-center ml-n5">
                        ผลลัพธ์
                      </div>
                    </div>
                    <v-divider class="ml-15 my-3" dark color="#a3a3a3"></v-divider>
                    <v-card elevation="0" class="grid-processing-items ma-4 ml-15 black--text"
                      v-for="(detail, index) in dataList" :key="index">
                      <div class="ml-3">{{ detail.mat_name }}</div>
                      <div class="ml-5">{{ formatPrice(parseFloat(detail.qty_need_to_use)) }}</div>
                      <div v-if="detail.status_enough === 1" class="ml-5">พอต่อการแปรรูป</div>
                      <div v-else class="ml-5" style="color: #f28080">ขาด: {{
                        formatPrice(parseFloat(detail.qty_need_more)) }} {{detail.mat_status == 3 ? detail.unit_name : 'กรัม(g)' }}</div>
                      <div v-if="detail.status_enough === 0" class="text-center" style="color: #f28080">ไม่พอ</div>
                      <div v-else class="text-center">พอ</div>
                    </v-card>
                    <v-divider class="ml-15 my-1" dark color="#e9e7e7"></v-divider>
                    <div class="grid-processing-items ma-4 ml-15 black--text">
                      <div class="ml-3">รวม (FG)</div>
                      <div class="ml-5">{{ formatPrice(parseFloat(fg)) }}</div>
                      <div></div>
                      <div class="text-right" style="font-size: 24px" v-if="status == 1 && status1 == 1"><b
                          style="color: #88B358">พร้อมแปรรูป</b></div>
                      <div class="text-right" style="font-size: 24px" v-if="status == 0 || status1 == 0"><b
                          style="color: #f28080">ไม่พร้อมแปรรูป</b></div>
                    </div>
                  </v-card-text>
                  <div class="text-right ma-2 mt-n3">
                    <v-btn class="mt-2 mr-2" v-if="status == 0" large rounded outlined color="#88B358"
                      @click="add_dialog = true">
                      <v-icon left>mdi-text-box-check-outline</v-icon>สั่งซื้อวัตถุดิบ
                    </v-btn>
                     <v-btn v-if="status1 == 0" class="mt-2 mr-2" large rounded outlined color="#6C7FD8" @click="add_dialog = true">
                      <v-icon left>mdi-store-cog</v-icon>แปรรูปวัตถุดิบ
                    </v-btn>
                  </div>
                </v-card>
              </v-card-text>
              <div class="my-3 text-center">
                <v-btn color="#88B358" elevation="4" rounded large outlined v-if="status == 0" @click="progress(50, 2)"
                  disabled>
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
                <v-btn color="#88B358" elevation="4" rounded large outlined v-if="status == 1" @click="progress(50, 2)">
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <section>
                <v-card-text class="mb-5">
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <v-icon dark>
                          mdi-cog
                        </v-icon>
                      </v-avatar>
                      <span class="ml-3 title-card-text">การกำหนดรอบและการคำนวนวัตถุดิบ</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                      <div class="ma-4 ml-8 mb-n7">
                        <b class="ml-3 black--text">จำนวน Batch ที่ต้องการแปรรูป</b>
                      </div>
                      <v-row class="ma-4 ml-8">
                        <v-col xl="3" lg="3">
                          <v-select item-text="value" item-value="id" v-model="batch_count" :items="batch"
                            @change="addBatch()" class="input-radius-search mt-3" label="จำนวน Batch ที่ต้องการแปรรูป"
                            hide-details="auto" outlined dense></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-text>
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <img class="icon-image-logo" src="@/assets/value-chain.png">
                      </v-avatar>
                      <span class="ml-3 title-card-text">การกำหนดรอบแลคำนวนวัตถุดิบ</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                      <div class="ml-15 mt-4">
                        <div class="divider-table my-2"></div>
                        <div class="scroll-menu mt-4">
                          <section>
                            <div class="w-border-header-first mb-n3" />
                            <div class="card-main-table">
                              <div class="py-3 d-flex justify-space-between">
                                <div class="black--text font-weight-bold">วัตถุดิบ</div>
                                <div class="black--text font-weight-bold">จำนวน (สูตรต่อ Batch)</div>
                              </div>
                              <v-radio-group v-if="part_id != ''" v-model="part_id" hide-details="auto">
                                <v-row class="pt-2">
                                  <v-col xl="12" lg="12" class="inline-box-specific">
                                    <div class="d-flex">
                                      <div class=" mr-15">
                                        <v-radio hide-details="auto" value="0" color="#FFBC15" />
                                      </div>
                                      <div class="black--text font-weight-bold">การ weight % จากสููตร</div>
                                    </div>
                                  </v-col>
                                </v-row>
                                <div v-for="(menu, index) in dataList_2" :key="menu">
                                  <v-row class="ps-2 pt-8">
                                    <div class="tag-title-step">
                                      <b class="black--text">ส่วนที่ {{ index + 1 }}</b>
                                    </div>
                                  </v-row>
                                  <v-row class="pt-2" v-for="(item, i) in menu.material_list" :key="i">
                                    <v-col xl="6" lg="6" class="" style="white-space: initial;">
                                      <v-radio  :label="item.mat_name" :value="(item.count_number) + ''" hide-details="auto"
                                        color="#FFBC15" />
                                    </v-col>
                                    <v-col xl="6" lg="6" style="margin-top: 2px">
                                      <div class="black--text font-weight-bold">{{ formatPrice(item.receipt_qty) }} กรัม
                                      </div>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-radio-group>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">รวม (FG)</div>
                                </v-col>
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">{{ formatPrice(fg_2) }} กรัม</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section>
                          <section v-for="(detail, index) in batchList" :key="index">
                            <div class="w-border-header-sub mb-n3" />
                            <div class="card-sub-table">
                              <div class="py-6 ">
                                <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                              </div>

                              <div v-if="part_id != '0'" class="black--text font-weight-bold text-center pt-1 pb-5">{{
                                formatPrice(detail.percent) }} %</div>
                              <div v-if="part_id == '0'" class="black--text font-weight-bold text-center  pt-1 pb-5">
                                <v-text-field @change="calculate(index, null)" class="input-radius" color="#686868"
                                  label="เปอร์เซ็น" placeholder="เปอร์เซ็น" hide-details="auto" v-model="detail.percent"
                                  outlined dense rounded required></v-text-field>
                              </div>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>

                              <!-- <v-row class="ps-2 pt-5">
                                <div class="tag-title-step-hidden">
                                  <b class="whith--text">ส่วนที่ {{index + 1}}</b>
                                </div>
                              </v-row> -->

                              <!-- {{ detail }} -->

                              <v-row class="pt-4 pb-4" v-for=" (item, i) in detail.batch_list" :key="item">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center" v-if="part_id != item.count_number">{{
                                    formatPrice(item.mat_qty) }} กรัม</div>
                                  <div class="black--text font-weight-bold text-center" v-if="part_id == item.count_number">
                                    <v-text-field @change="calculate(index, i)" class="input-radius" color="#686868"
                                      label="กรัม" placeholder="กรัม" hide-details="auto" v-model="item.mat_qty" outlined
                                      dense rounded required></v-text-field>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>

                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center">{{ formatPrice(detail.batch_qty)
                                  }} กรัม</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section>
                        </div>
                        <div class="summarize my-5 pa-5">
                          <h1 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป {{
                            formatPrice(total_result) }} กรัม หรือ {{ formatPrice(total_result / 1000) }} กิโลกรัม
                          </h1>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </section>
              <div class="my-3 text-center">
                <v-btn v-if="status_process > 2" color="#88B358" elevation="4" rounded large outlined
                  @click="progress(50, 3)">
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
                <v-btn v-else color="#88B358" elevation="4" rounded large outlined @click="savePrepare()">
                  <v-icon left>mdi-arrow-right</v-icon> ถัดไป
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <section>
                <v-card-text class="mb-5">
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <img class="icon-image-logo" src="@/assets/standard.png">
                      </v-avatar>
                      <span class="ml-3 title-card-text">ยืนยันการใช้แผนแปรรูป</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
                      <div class="ma-4 mb-n7">
                        <b class="ml-3 black--text">ข้อมูลการแปรรูป</b>
                      </div>
                      <v-row class="ma-4 mt-6 mb-0">
                        <v-col xl="4" lg="4">
                          <div class="d-flex">
                            <div class="mr-2">รายการแปรรูป :</div>
                            <div class="black--text">{{ header_3.mat_name }} {{ formatPrice(header_3.desired_qty) }} กรัม
                            </div>
                          </div>
                        </v-col>
                        <v-col xl="4" lg="4">
                          <div class="d-flex">
                            <div class="mr-2">วันที่ผลิต :</div>
                            <div class="black--text" v-if="header_3.process_date == null">
                              -
                            </div>
                            <div class="black--text" v-else>
                              {{
                                `${header_3.process_date.split('/')[0]}-${header_3.process_date.split('/')[1]}-${header_3.process_date.split('/')[2]}`
                              }}
                            </div>
                          </div>
                        </v-col>
                        <v-col xl="4" lg="4">
                          <div class="d-flex">
                            <div class="mr-2">ฝ่ายผลิต (ชื่อ) :</div>
                            <div class="black--text">{{ header_3.user_process }}</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-text>
                  <v-card elevation="4" class="py-2" style="border-radius:20px">
                    <v-card-title>
                      <v-avatar color="#34230F" size="36">
                        <img class="icon-image-logo" src="@/assets/value-chain.png">
                      </v-avatar>
                      <span class="ml-3 title-card-text">การกำหนดรอบและคำนวนวัตถุดิบ</span>
                    </v-card-title>
                    <v-card-text>
                      <v-divider class="mb-3" dark color="#a3a3a3" />
                      <div class="ml-15 mt-4">
                        <div class="divider-table my-2"></div>
                        <div class="scroll-menu-last mt-4">
                          <section>
                            <div class="w-border-header-first mb-n3" />
                            <div class="card-main-table">
                              <div class="py-3 d-flex justify-space-between">
                                <div class="black--text font-weight-bold">วัตถุดิบ</div>
                                <div class="black--text font-weight-bold">จำนวน (สููตรต่อ Batch)</div>
                              </div>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12" class="inline-box-specific">
                                  <div class="black--text font-weight-bold text-center">การ weight % จากสููตร</div>
                                </v-col>
                              </v-row>

                              <v-row class="pt-2" v-for="(item, i) in meterial_group" :key="item">
                                <v-col cols="12">
                                  <v-row class="">
                                    <v-col xl="12" lg="12">
                                      <div class="tag-title-step">
                                        <b class="black--text">ส่วนที่ {{ (i + 1) }}</b>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row class="pb-2" v-for="detail in item.material_list" :key="detail">

                                    <v-col xl="6" lg="6" style="white-space: initial;">
                                      <div class="black--text font-weight-bold">{{ detail.mat_name }}</div>
                                    </v-col>
                                    <v-col xl="6" lg="6">
                                      <div class="black--text font-weight-bold">{{ formatPrice(detail.receipt_qty) }} กรัม
                                      </div>
                                    </v-col>

                                  </v-row>
                                </v-col>
                              </v-row>

                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>

                              <v-row class="pt-2">
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">รวม (FG)</div>
                                </v-col>
                                <v-col xl="6" lg="6">
                                  <div class="black--text font-weight-bold">{{ formatPrice(header_3.batch_qty) }}</div>
                                </v-col>
                              </v-row>

                            </div>
                          </section>

                          <!-- {{ get_data_process_3.process_detail }}
                              {{ get_data_process_3.mat_group_list }}
                              {{ get_data_process_3.batch }} -->

                          <!-- batch -->
                          <section class="last" v-for="(detail, index) in get_data_process_3.batch" :key="index">
                            <div class="w-border-header-sub-last mb-n3" />
                            <div class="card-sub-table-last">
                              <div class="py-3">
                                <div class="black--text font-weight-bold">Batch {{ detail.batch_round }}</div>
                              </div>

                              <div class="black--text font-weight-bold text-center">{{ detail.percent }}</div>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              
                              <!-- batch_list -->
                              <div class="pt-2" v-for="(detail_list, i) in detail.batch_list" :key="i">

                                <!-- stock_list -->
                                <div class="mb-4" v-for="(detail_stock, j) in detail_list.stock_list" :key="j">

                                  <div class="d-flex align-center">
                                    <div class="mr-4">
                                      <v-btn v-if="parseInt(detail_stock.type) != 0 && check_status_process == 1" class="px-2" @click="open_model_delete(detail_stock.id)" style="min-width:auto;" outlined color="#F28080">
                                        <v-icon style="color:#F28080">mdi-delete-forever</v-icon>
                                      </v-btn>
                                      <div v-else class="px-5"></div>
                                    </div>

                                    <div class="d-flex flex-column justify-start align-start">
                                      <div class="black--text font-weight-bold">{{ detail_list.length != 0 ?
                                        formatPrice(detail_stock.quantity_cut) : '0.00' }} กรัม</div>
                                        <!-- {{ detail_list }} -->
                                      <div class="font-weight-bold">{{ detail_list.length != 0 ?
                                        detail_stock.material_lot : 'No Lot' }}</div>
                                        <!-- {{ meterial_group[index].material_list[i] }} -->
                                    </div>
                                    <div class="px-5"></div>
                                  </div>

                                  <div v-if="detail_stock.is_last == 1 && check_status_process == 1" class="text-center">
                                    <v-btn class="mt-2" @click="open_model_add_batch(detail_list.id, detail_list.material_id)" block rounded text color="#88B358">
                                      <v-icon left>mdi-plus</v-icon>เพิ่ม
                                    </v-btn>
                                  </div>
                                </div>
                              </div>

                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">{{
                                    formatPrice(detail.batch_qty) }} กรัม</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section>

                          <!-- <section class="last" v-for="(detail, index) in batchList_3" :key="index">
                            <div class="w-border-header-sub-last mb-n3" />
                            <div class="card-sub-table-last">
                              <div class="py-3">
                                <div class="black--text font-weight-bold">Batch {{ index + 1 }}</div>
                              </div>

                              <div class="black--text font-weight-bold text-center">{{ detail.percent }}</div>
                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              
                              <v-row class="pt-2" v-for="(detail_list, i) in detail.batch_list" :key="i">
                                <v-col xl="12" lg="12">
                                  <div class="d-flex align-center">
                                    <div class="mr-4">
                                      <v-btn class="px-2" @click="open_model_delete(detail.id)" style="min-width:auto;" outlined color="#F28080">
                                        <v-icon style="color:#F28080">mdi-delete-forever</v-icon>
                                      </v-btn>
                                    </div>
                                    <div>
                                      <div class="black--text font-weight-bold text-center">{{ detail_list.length != 0 ?
                                        formatPrice(detail_list.quantity_cut) : '0.00' }} กรัม</div>
                                        {{ detail_list }}
                                      <div class="font-weight-bold text-center">{{ detail_list.length != 0 ?
                                        detail_list.material_lot : 'No Lot' }}</div>
                                        {{ meterial_group[index].material_list[i] }}
                                    </div>
                                  </div>
                                  <div class="text-center">
                                    <v-btn class="mt-2" @click="open_model_add_batch(detail.id, meterial_group[index].material_list[i].mat_name)" block rounded text color="#88B358">
                                      <v-icon left>mdi-plus</v-icon>เพิ่ม
                                    </v-btn>
                                  </div>
                                </v-col>
                              </v-row>

                              <v-divider class="ml-2 mr-2 mt-5" dark color="#d9d9d9"></v-divider>
                              <v-row class="pt-2">
                                <v-col xl="12" lg="12">
                                  <div class="black--text font-weight-bold text-center" style="vertical-align: bottom">{{
                                    formatPrice(detail.batch_qty) }} กรัม</div>
                                </v-col>
                              </v-row>
                            </div>
                          </section> -->
                        </div>
                        <div class="summarize my-5 pa-5">
                          <h1 class="black--text font-weight-bold text-center">ประมาณการผลลัพธ์ที่จะได้รับจากการแปรรูป {{
                            formatPrice(total_result) }} หรือ {{ formatPrice(total_result / 1000) }} กิโลกรัม </h1>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </section>
              <div class="my-3 text-center">
                <v-btn v-if="check_status_process == 1" color="#88B358" class="white--text" elevation="4" rounded large
                  @click="saveStepThree()">
                  <v-icon left>mdi-content-save</v-icon> ยืนยันการแปรรูป
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </div>
    <v-dialog v-model="add_dialog" transition="dialog-top-transition" max-width="500" style="border-radius:20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="add_dialog = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="d-flex flex-column align-center">
            <img class="icon-image mb-3" src="@/assets/notice.png">
          </div>
          <div class="text-center">
            <h1 class="black--text">วัตถุดิบไม่เพียงพอ</h1>
            <div class="my-2">วัตถุดิบไม่เพียงพอต่อการแปรรูปกรุณานำเข้าวัตถุดิบ</div>
          </div>
          <div class="mt-3 text-center">
            <v-btn v-if="status == 0" class="white--text" color="#88B358" elevation="4" rounded large
              @click="openMaterialImport('/stock/material-import')">
              <v-icon left>mdi-text-box-check-outline</v-icon> สั่งซื้อวัตถุดิบ
            </v-btn>
             <v-btn v-if="status1 == 0" @click="openMaterialImport('/prm/processing_materials')" class="white--text mr-3" color="#6C7FD8"
              elevation="4" rounded large>
              <v-icon left>mdi-store-cog</v-icon> แปรรูปวัตถุดิบ
            </v-btn>
            <div class="ma-4" style="cursor: pointer">ยกเลิก</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog Add -->
    <v-dialog v-model="add_batch_dialog" transition="dialog-top-transition" max-width="667" style="border-radius:20px;">
        <v-card>
            <div class="d-flex justify-space-between">
                <v-card-title>
                    <b>{{ data_lot_process_3.mat_name }}</b>
                    <!-- <b>เพิ่มขนาดสินค้า</b> -->
                </v-card-title>
                <v-btn type="button" icon class="ma-4" @click="add_batch_dialog = false">
                    <v-icon color="grey">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-form ref="form_add_lot_batch" @submit.prevent="saveAddLotBatch()">
                <v-card-text>
                  <v-card elevation="4" style="border-radius:20px;">
                    <v-card-title class="d-flex align-items-center">
                        <v-avatar color="#34230F" size="32">
                            <v-icon dark>
                                mdi-package-variant-closed
                            </v-icon>
                        </v-avatar>
                        <span class="ml-3 f-18">ข้อมูล Lot</span>
                    </v-card-title>
                    <v-card-text>
                        <v-divider class="mb-3" color="#686868"></v-divider>
                        <v-row style="padding: 15px 0 !important;">
                            <v-col class="text-center" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                              <p class="mb-0 pb-0">จำนวนคงเหลือ</p>
                              <span>{{ add_batch_dialog_materials_lot_amount_remain ? formatPrice(add_batch_dialog_materials_lot_amount_remain) : '0.00' }} กรัม</span>
                            </v-col>

                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                              <v-autocomplete color="#FFBC15" 
                                v-model="add_batch_dialog_materials_lot_id"
                                :items="data_lot_process_3.materials_lot_list" 
                                item-text="material_lot"
                                item-value="id"
                                :rules="[v => !!v || 'กรุณาเลือก Lot']" 
                                label="เลือก Lot"
                                hide-details="auto" dense outlined rounded required>
                              </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                              <v-text-field class="input-radius" color="#FFBC15" label="ปริมาณการบรรจุ"
                                v-model="add_batch_dialog_materials_lot_amount"
                                placeholder="ปริมาณการบรรจุ"
                                :rules="[v => !!v || 'กรุณากรอกปริมาณการบรรจุ', v => validateAmount(v)]"
                                @keypress="isOnlyNumber($event, wide)" 
                                minlength="1" hide-details="auto" suffix="g" dense outlined rounded required>
                              </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                  </v-card>
                  <div class="mt-5 div-submit-data">
                      <v-btn type="submit" class="button-submit-data" color="#88B358" elevation="4" rounded large>
                          <v-icon left>mdi-content-save</v-icon> บันทึก
                      </v-btn>
                  </div>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Dialog Delete -->
    <v-dialog v-model="delete_dialog" transition="dialog-top-transition" max-width="383" style="border-radius: 20px">
      <v-card>
        <div class="d-flex justify-end">
          <v-btn type="button" icon class="ma-4" @click="delete_dialog = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <center>
                <img src="@/assets/bin.png" />
                <h2 style="color: #000000">ลบข้อมูล</h2>
                <h3 class="mt-2">คุณต้องการลบข้อมูลใช่หรือไม่</h3>
                <v-btn @click="deleteLotBatch" class="btn-delete-data"  elevation="4" rounded x-large>
                  <v-icon style="font-size: 28px" left>mdi-delete-forever</v-icon>
                  ลบข้อมูล
                </v-btn>
              </center>
              <div class="text-center mt-3">
                <v-btn style="font-size: 14px" text @click="delete_dialog = false"> ยกเลิก </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { onlyForCurrency, formatPrice} from "@/helper/handler";
import process from '@/api/process/meterial_process.js'
import Swal from "sweetalert2";
import store from '@/store/index.js'
export default {
  name: "ProcessingRawMaterials",
  data() {
    return {
      loading_page: true,
      alert_success: false,
      alert_message_success: null,
      loading: false,
      e1: 1,
      progress_value: 0,
      add_dialog: false,
      checked: true,
      dataList: [],
      batchList: [
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },
        {
          percent: '100%',
          gams: 5000,
          gamsFilter: '5,000.00',
          totalGams: '8,736.00'
        },

      ],
      status: null,
      status1: null,
      fg: 0,

      //step 2
      dataList_2: [],
      batch: [],
      fg_2: 0,
      part_id: '',
      batch_count: 0,
      old_batch_count: 0,
      sum: 0,
      status_process: 0,
      check_status_process: 0,
      total_result: 0,
      remainder: 0,

      //step 3
      header_3: {},
      meterial_group: [],
      batchList_3: [],
      sum_3: [],

      //test
      testt: {},

      
      delete_dialog: false,
      delete_dialog_id: null,

      add_batch_dialog: false,
      add_batch_dialog_id: null,
      add_batch_dialog_name: null,
      add_batch_dialog_materials_lot_id: null,
      add_batch_dialog_materials_lot_amount: null,
      add_batch_dialog_materials_lot_amount_remain: null,

      data_lot_process_3: {},
      get_data_process_3: [],
      
    }
  },
  methods: {
    validateAmount(value) {
      const amount = parseFloat(value);
      if (amount > parseFloat(this.add_batch_dialog_materials_lot_amount_remain)) {
        return 'ปริมาณสูงสุด ' + formatPrice(this.add_batch_dialog_materials_lot_amount_remain) + ' กรัม';
      }
      return true;
    },
    open_model_delete(id){
      this.delete_dialog = true
      this.delete_dialog_id = id
    },
    async open_model_add_batch(id, material_id){
      this.add_batch_dialog_materials_lot_id = null;
      this.add_batch_dialog_materials_lot_amount = null;
      this.add_batch_dialog_materials_lot_amount_remain = null;

      this.add_batch_dialog = true;
      this.add_batch_dialog_id = id;
      this.add_batch_dialog_material_id = material_id;

      const response = await process.getListLot(material_id);
      this.data_lot_process_3 = await response.data.data;

    },
    async saveAddLotBatch(){
      if (this.$refs.form_add_lot_batch.validate()) {
        let process_id = parseFloat(this.$route.params.id);

        let data = {
          "process_id" : process_id,
          "process_batch_list_id": this.add_batch_dialog_id,
          "material_id": this.add_batch_dialog_material_id,
          "material_lot_id": this.add_batch_dialog_materials_lot_id,
          "quantity_cut": parseFloat(this.add_batch_dialog_materials_lot_amount)
      };

        const response = await process.saveAddLot(data);
        console.log(response);
        if(response.data.successful == true){
          this.add_batch_dialog = false;
          this.add_batch_dialog_materials_lot_id = null;
          this.add_batch_dialog_materials_lot_amount = null;
          this.add_batch_dialog_materials_lot_amount_remain = null;

          this.getDetail()
          
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
      }
    },

    async deleteLotBatch(){
      const response = await process.deleteLot(this.delete_dialog_id);
      if (response.data.successful === true) {
        this.getDetail()
        this.delete_dialog = false
        this.alert_message_success = "ลบรายการเสร็จสิ้น"
        this.alert_success = true
        setTimeout(() => this.alert_success = false, 5000)
        this.productSizeList()
      }
    },

// getListLot
// saveAddLot
// deleteLot
    async getLotMaterial(){
      await process.getListLot()
    },

    openMaterialImport(text) {
      window.open(text, '_blank')
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    isOnlyNumber: function (evt, number) {
      return onlyForCurrency(evt, number)
    },
    linkToSteps() {
      this.$router.push('/prm/processing_raw_materials')
    },
    link(link) {
      this.$router.push(link)
    },
    async progress(val, step) {
      this.e1 = step
      this.progress_value = val
      switch (step) {
        case 1:

          break;
        case 2:
          this.getPrepare();
          break;
        case 3:

          break;
        case 4:

          break;
      }
    },
    async addBatch() {
      this.loading_page =true;
       let value = [];
      if (this.old_batch_count <= this.batch_count) {
        let count = this.batch_count - this.old_batch_count;
        for (let i = 0; i < count; i++) {
          this.batchList.push({
            batch_round: (i + 1),
            percent: '100.00',
            batch_qty: 0,
            batch_list: []
          });
          value.push((this.batchList.length - 1));
        }
        for (let v = 0; v < value.length; v++) {
          this.sum = 0;
            let count_number = 1;
          for (let a = 0; a < this.dataList_2.length; a++) {
            for (let i = 0; i < this.dataList_2[a].material_list.length; i++) {
              this.batchList[value[v]].batch_list.push({
                count_number:count_number,
                material_id: this.dataList_2[a].material_list[i].material_id,
                mat_qty: this.dataList_2[a].material_list[i].receipt_qty,
                mat_qty_old: this.dataList_2[a].material_list[i].receipt_qty
              });
              this.sum += parseFloat(this.fg_2);
              this.batchList[value[v]].batch_qty = this.fg_2;
              count_number++
            }
          }
        }
        this.old_batch_count = this.batch_count;
      } else {
        let count = this.old_batch_count - this.batch_count;
        for (let i = 0; i < count; i++) {
          this.batchList.splice(this.batchList.length - 1);
        }
        this.old_batch_count = this.batch_count;
        value.push((this.batchList.length - 1));
      }
      let sum = 0
      this.total_result = 0
        for (let v = 0; v < this.batchList.length; v++) {
        sum = parseFloat(this.batchList[v].batch_qty)
        // sum = this.batchList[index].batch_qty += this.batchList[index].batch_list[i].mat_qty;
        this.total_result += sum
      }
      this.loading_page =false;
    },
    async calculate(index, i = null,) {
      this.total_result = 0
      this.batchList[index].batch_qty = 0;
      let sum = 0;
      if (i != null) {
        this.batchList[index].percent = (100 + ((this.batchList[index].batch_list[i].mat_qty - this.batchList[index].batch_list[i].mat_qty_old) / this.batchList[index].batch_list[i].mat_qty_old) * 100).toFixed(2);
      }
      for (let i = 0; i < this.batchList[index].batch_list.length; i++) {
        this.batchList[index].batch_list[i].mat_qty = ((this.batchList[index].batch_list[i].mat_qty_old / 100) * parseFloat(this.batchList[index].percent)).toFixed(2);
        this.batchList[index].batch_qty = ((this.fg_2 * parseFloat(this.batchList[index].percent)) / 100).toFixed(2);
        // sum = this.batchList[index].batch_qty += this.batchList[index].batch_list[i].mat_qty;
      }
      
       for (let v = 0; v < this.batchList.length; v++) {
        sum = parseFloat(this.batchList[v].batch_qty)
        // sum = this.batchList[index].batch_qty += this.batchList[index].batch_list[i].mat_qty;
        this.total_result += sum
      }
      // for(let x = 0 x < ){

      // }
      // for (let ei = 0; this.batchList.length; ei++) {
      //   sum = parseFloat(this.batchList[ei].batch_qty)
      //   alert(sum)

      // }

    },
    async saveProduction() {
      this.$router.push('/prm/production_result_create')
    },
    async getMeterialFormula() {
      let id = parseFloat(this.$route.params.id);
      let token = store.state.access_token;
      this.loading_page = true;
      await process.getMeterialFormula(id, token).then((response) => {
        if (response.status == 502) {
          window.location.reload()
        }
        if (response.data.successful === true) {
           if(response.data.status_process >= 2){
             this.getDetail()
          }else{
             this.dataList = response.data.material_list;
            this.fg = response.data.fg;
            let check = this.dataList.filter(o => o.status_enough === 0 && o.mat_status == 1)
            if (check.length > 0) {
              this.status = 0;
            } else {
              this.status = 1;
            }
            let check1 = this.dataList.filter(o => o.status_enough === 0 && o.mat_status == 2)
            if (check1.length > 0) {
              this.status1 = 0;
            } else {
              this.status1 = 1;
            }
            this.loading_page = false;
          }
        }
      })

    },
    async getPrepare() {
      let id = parseFloat(this.$route.params.id);
      let token = store.state.access_token;
      this.loading_page = true;
      await process.getPrepare(id, token).then((response) => {
        if (response.data.successful === true) {
          this.dataList_2 = response.data.mat_group_list;
          this.fg_2 = response.data.fg;
          this.batchList.splice(0);
          let totalall = 0;
          let check_baatch = this.fg / this.fg_2;
          this.remainder = this.fg % this.fg_2;

          // var batch = [];
          this.sum = 0;
          this.check_status_process = response.data.status_process
          var batch = [];
          batch = response.data.batch;
          if (this.check_status_process <= 1) {
            let old_batch_count = response.data.batch_count;
            let batch_count = response.data.batch_count;
            // this.status_process = response.data.status_process;
            this.old_batch_count = Math.ceil(old_batch_count) < 1 ? 1 : Math.ceil(old_batch_count);
            this.batch_count = Math.ceil(batch_count) < 1 ? 1 : Math.ceil(batch_count);

            for (let i = 0; i < this.batch_count; i++) {
              this.batchList.push({
                batch_round: (i + 1),
                percent: '100.00',
                batch_qty: 0,
                batch_list: []
              });
            }

            const lastIndex = this.batchList.length - 1;
            for (let index = 0; index < this.batchList.length; index++) {
              let count_number = 1;
              for (let i = 0; i < this.dataList_2.length; i++) {
                for (let a = 0; a < this.dataList_2[i].material_list.length; a++) {
                  this.dataList_2[i].material_list[a] = {
                    ...this.dataList_2[i].material_list[a],
                    count_number: count_number
                  }
                  this.batchList[index].batch_list.push({
                    count_number: count_number,
                    material_id: this.dataList_2[i].material_list[a].material_id,
                    mat_qty: this.dataList_2[i].material_list[a].receipt_qty,
                    // mat_qty: this.dataList_2[i].material_list[a].receipt_qty * (check_baatch < 1 ? check_baatch : 1),
                    mat_qty_old: this.dataList_2[i].material_list[a].receipt_qty
                  });
                  this.sum += parseFloat(this.dataList_2[i].material_list[a].receipt_qty);

                  if (index === lastIndex && this.remainder != 0) {
                    this.batchList[index].batch_list[a].mat_qty = parseFloat(this.batchList[index].batch_list[a].mat_qty * (this.remainder / this.fg_2))
                  }
                  count_number++
                }

                // คำนวณ Bacth ก่อนตัวสุดท้ายทั้งหมด
                if (index !== lastIndex) {
                  this.batchList[index].batch_qty = parseFloat(this.fg_2)
                  this.batchList[index].percent = check_baatch < 1 ? parseFloat((this.fg / this.fg_2) * 100).toFixed(2) : '100.00'
                }

              }
              // คำนวณเศษ Batch ตัวสุดท้าย
              if (index === lastIndex && this.remainder != 0) {
                this.batchList[index].batch_qty = parseFloat(this.remainder);
                this.batchList[index].percent = check_baatch < 1 ? parseFloat((this.fg / this.fg_2) * 100).toFixed(2) : parseFloat((this.remainder / this.fg_2) * 100).toFixed(2);
              } else {
                this.batchList[index].batch_qty = parseFloat(this.fg_2);

              }

            }

            totalall += parseFloat(this.fg)

          } else {
            // for(let i = 0; i< batch.length; i++){
            //     this.batchList.push({
            //       batch_qty:
            //     })
            // }
            this.batchList = batch;
            let old_batch_count = response.data.batch_count_by_user;
            let batch_count = response.data.batch_count_by_user;
            this.batch_count = batch_count;
            this.old_batch_count = Math.round(old_batch_count) < 1 ? 1 : Math.round(old_batch_count);
            this.batch_count = Math.round(batch_count) < 1 ? 1 : Math.round(batch_count);
            for (let i = 0; i < this.batchList.length; i++) {
              for (let a = 0; a < this.batchList[i].batch_list.length; a++) {
                this.batchList[i].batch_list[a] = {
                  mat_qty_old: this.batchList[i].batch_list[a].mat_qty,
                  mat_qty: this.batchList[i].batch_list[a].mat_qty,
                  material_id: this.batchList[i].batch_list[a].material_id
                }
              }
              totalall += parseFloat(this.batchList[i].batch_qty)
            }
          }

          for (let i = 1; i <= parseFloat(this.batch_count) + 5; i++) {
            this.batch.push({
              id: i,
              value: i
            });
          }

          this.total_result = totalall;
          this.part_id = '0';
          this.loading_page = false;
        }
      })
    },
    async savePrepare() {
      this.loading = true;
      let material_list = [];
      var batchList = [];
      for (let i = 0; i < this.dataList_2.length; i++) {
        for (let a = 0; a < this.dataList_2[i].material_list.length; a++) {
          material_list.push({
            material_id: this.dataList_2[i].material_list[a].material_id,
            mat_group: this.dataList_2[i].material_list[a].mat_group,
            receipt_qty: this.dataList_2[i].material_list[a].receipt_qty
          });
          for (let a = 0; a < material_list.length; a++) {
            material_list[a].status_receipt_main = this.part_id == (a + 1) ? 1 : 0;
          }
        }
      }
      for (let i = 0; i < this.batchList.length; i++) {
        batchList.push({
          percent: this.batchList[i].percent,
          batch_qty: this.batchList[i].batch_qty,
          batch_round: (i + 1),
          batch_list: []
        })
        for (let a = 0; a < this.batchList[i].batch_list.length; a++) {
          batchList[i].batch_list.push({
            mat_qty: this.batchList[i].batch_list[a].mat_qty,
            material_id: this.batchList[i].batch_list[a].material_id
          });
        }
      }
      let data = {
        process_id: parseFloat(this.$route.params.id),
        batch_count_by_user: this.batch_count,
        status_receipt_percent: this.part_id == '0' ? 1 : 0,
        material_list: material_list,
        batch: batchList
      }

      this.testt = data;
      await process.addPrepare(data).then((response) => {
        if (response.data.successful === true) {
          this.getPrepareDeatail()
          this.progress(100, 3);
          this.loading = false;
        }
      })

      // this.progress(100, 3)
    },
    async getPrepareDeatail() {
      this.loading_page = true;
      let id = this.$route.params.id;
      await process.getPrepareDeatail(id).then((response) => {
        if (response.data.successful === true) {
          this.get_data_process_3 = response.data;

          this.header_3 = response.data.process_detail;
          this.meterial_group = response.data.mat_group_list;
          let batch = response.data.batch;
          for (let i = 0; i < batch.length; i++) {
            this.sum_3[i] = 0;
            this.batchList_3[i] = {
              id: batch[i].id,
              percent: batch[i].percent,
              batch_qty: batch[i].batch_qty,
              batch_list: [],
            };
            for (let a = 0; a < batch[i].batch_list.length; a++) {
              for (let x = 0; x < batch[i].batch_list[a].stock_list.length; x++) {
                this.sum_3[i] = parseFloat(this.sum_3[i]) + parseFloat(batch[i].batch_list[a].stock_list[x].quantity_cut)
                this.batchList_3[i].batch_list.push(batch[i].batch_list[a].stock_list[x]);

              }

            }
          }
          this.loading_page = false;
        }
      })
    },
    async saveStepThree() {
      this.loading_page = true;
      let id = this.$route.params.id;
      await process.saveStepThree(id).then((response) => {
        if (response.data.successful === true) {
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/prm/processing_steps/" + this.$route.params.id);
              // window.location.reload(); 
            }
          });
        }
        this.loading_page = false;
      })
    },
  async getDetail(){
     await this.getPrepare()
     await this.getPrepareDeatail()
     await this.progress(100, 3);
  }
  },
  mounted() {
    
    this.getMeterialFormula();
    if (this.$route.params.status) {
      this.getDetail()
    }
  },
  watch: {
    add_batch_dialog_materials_lot_id(){
      this.add_batch_dialog_materials_lot_amount_remain = this.data_lot_process_3.materials_lot_list.find(v => v.id == this.add_batch_dialog_materials_lot_id).material_amount
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: baseline;
}

.icon-image {
  width: 120px;
  height: 120px;
  text-align: center;
}

.icon-image-logo {
  width: 24px;
  height: 24px;
  vertical-align: center;
}

.tag-title-step {
  border: 1px solid #ffbc15;
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
}

.divider-table {
  border: 3px solid #ffbc15;
  border-radius: 3px;
}

.w-border-header-first {
  background: #ffbc15;
  border-top: 15px solid #ffbc15;
  border-radius: 36px;
  width: 110%;
}

.inline-box-specific {
  background: #fff8e8;
  border-top: 1px solid #ffbc15;
  border-bottom: 1px solid #ffbc15;
}

.w-border-header-sub {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: 60%;
}

.card-main-table {
  border: 1px solid #ffbc15;
  border-radius: 15px;
  padding: 15px;
  width: 110%;
  height: 99%;
}

.card-sub-table {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 60%;
  width: 60%;
  transition: 0.5s;
}

.card-sub-table:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu {
  overflow-x: scroll;
  white-space: nowrap;
}

.scroll-menu section {
  display: inline-block;
  margin-right: -73px;
}

.scroll-menu section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  margin-right: 40px;
  padding-right: 24px;
  background: #FFFFFF;
  z-index: 1;
}

.w-border-header-sub-last {
  background: #34230F;
  border: 7px solid #34230F;
  border-radius: 36px;
  width: auto;
}

.card-sub-table-last {
  border: 1px solid #34230F;
  border-radius: 15px;
  padding: 15px;
  max-width: 100%;
  width: 100%;
  height: 99%;
  transition: 0.5s;
}

.card-sub-table-last:hover {
  transition: 0.5s;
  box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 10px 13px -4px rgba(0, 0, 0, 0.75);
}

.scroll-menu-last {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}

.scroll-menu-last .last {
  display: inline-block;
  margin-right: 2%;
}

.scroll-menu-last section:first-child {
  display: inline-block;
  position: sticky;
  left: 0;
  margin-right: 2%;
  padding-right: 20px;
  background: #FFFFFF;
  z-index: 1;
}

.summarize {
  border: 1px solid #b0cc90;
  border-radius: 25px;
  background-color: #f3f7ee;
}

.v-stepper__step {
  padding: 7px;
}


.tag-title-step-hidden {
  border: 1px solid #FFFFFF;
  padding: 4px 20px 4px 20px;
  inline-size: fit-content;
  color: #FFFFFF;
}
</style>