<template>
  <div>
    <ProcessingSteps/>
  </div>
</template>

<script>
import ProcessingSteps from "@/components/production_process/processing_raw_materials/ProcessingSteps";
export default {
  name: "Processing_steps",
  components: {ProcessingSteps}
}
</script>

<style scoped>

</style>