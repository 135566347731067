<template>
  <div class="loading_all_page" v-if="loading_page === true">
      <v-progress-circular indeterminate size="70" :width="8" color="#FFBC15"></v-progress-circular>
      <h3>กำลังโหลดข้อมูล...</h3>
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="topic_processing_header border_right">สร้างรอบการแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2 link-pointer" @click="link('/prm/processing_materials')">การแปรรูปวัตถุดิบ</div>
      <div class="topic_processing_content my-2"><v-icon>mdi-chevron-right</v-icon></div>
      <div class="topic_processing_content my-2">สร้างรอบการแปรรูปวัตถุดิบ</div>
    </div>
    <div class="mt-3">
      <v-form ref="form_add_step_one" @submit.prevent="saveProduct()">
      <v-card elevation="2" class="card-radius">
        <v-card-title>
          <b>ขั้นตอนที่ 1 : สร้างใบสั่งแปรรูป</b>
        </v-card-title>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px;">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <v-icon dark>
                  mdi-note-text-outline
                </v-icon>
              </v-avatar>
              <span class="ml-3 title-card-text">ข้อมูลการแปรรูปวัตถุดิบ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <v-row class="ma-4 mt-n3">
                <v-col xl="12" lg="12">
                  <v-row>
                    <v-col xl="3" lg="3">
                      <v-menu v-model="menu1" :close-on-content-click="false" class="input-radius-search"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="processing_date" label="วันที่ใบสั่งแปรรูป" color="#FFBC15"
                            prepend-inner-icon="mdi-calendar" hide-details="auto"
                            :rules="[v => !!v || 'กรุณาเลือกวันที่ใบสั่งแปรรูป']" v-bind="attrs" dense outlined rounded readonly
                            required v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="processing_date" @input="menu1 = false, setDate()" :min="minDate" no-title
                          scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <!-- <v-select required item-text="value" item-value="id" class="input-radius-search " label="เลขที่ใบสั่งผลิต" hide-details="auto" outlined dense></v-select> -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="ma-4">
                <b class="ml-3 mb-n5 black--text">รายการวัตถุดิบแปรรูป</b>
                <v-col xl="12" lg="12">
                  <v-row>
                    <v-col xl="3" lg="3">
                      <v-select required  item-text="name" item-value="id" :items="unit_list" v-model="unit_id" color="#FFBC15"
                        class="input-radius-search mt-3" label="หน่วย" hide-details="auto" outlined dense></v-select>
                    </v-col>
                    <v-col xl="9" lg="9">
                      <v-select required item-text="mat_name" @change="addProduct()" v-model="meterial_id" item-value="id" color="#FFBC15"
                        :items="dataList" class="input-radius-search mt-3" label="เลือกวัตถุดิบแปรรูป" hide-details="auto" ref="dataList"
                        outlined dense></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <div class="table-header-processing mt-n6" style="color: #b1adad;">
                <div class="table-sub-header-processing pl-3">
                  วัตถุดิบแปรรูป
                </div>
                <div class="table-sub-header-processing text-center">
                  {{ unit_id == 0 ? 'กรัม g' : unit_id == 1 ? 'กิโลกรัม kg' : '-' }}
                </div>
                <div class="table-sub-header-processing text-center">
                  g FG/Batch
                </div>
                <div class="table-sub-header-processing text-center">
                  No.of Batch
                </div>
                <!-- <div class="table-sub-header-processing text-center">
                  ลบ
                </div> -->
              </div>
              <v-card elevation="0" class="grid-processing-items ma-4" v-for="(detail, index) in data" :key="index">
                <div class="pl-3">{{ detail.mat_name }}</div>
                <div>
                  <v-text-field class="input-radius" color="#FFBC15" v-model="detail.grams" dense outlined rounded
                    required></v-text-field>
                </div>
                <div class="text-center">{{ formatPrice(detail.batch_qty) }}</div>
                <div class="text-center">{{ detail.grams == 0 ? 0 : unit_id == 0 ? parseFloat(detail.batch_qty).toFixed(2) == 0 ? 0 : (parseFloat(detail.grams) /
                  parseFloat(detail.batch_qty)).toFixed(2) : unit_id == 1 ? parseFloat(detail.batch_qty).toFixed(2) == 0 ? 0 : ((parseFloat(detail.grams) / parseFloat(detail.batch_qty)) * 1000).toFixed(2)
                  : 0 }}</div>
                <!-- <div style="text-align: center">
                  <v-btn class="ml-6 radius-15" color="#f2abab" outlined @click="removeProduct(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div> -->
              </v-card>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text>
          <v-card elevation="4" class="py-2" style="border-radius:20px;">
            <v-card-title>
              <v-avatar color="#34230F" size="36">
                <v-icon dark>
                  mdi-account-supervisor
                </v-icon>
              </v-avatar>
              <span class="ml-3 title-card-text">ข้อมูลผู้รับผิดชอบ</span>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-3" dark color="#a3a3a3"></v-divider>
              <div class="ma-4 mb-n7">
                <b class="ml-3 black--text">ผู้อนุมัติสั่งแปรรูป</b>
              </div>
              <v-row class="ma-4">
                <v-col xl="3" lg="3">
                  <v-select required :rules="[v => !!v || 'กรุณาเลือกผู้อนุมัติสั่งแปรรูป']" item-text="name" item-value="id" v-model="user_approve" :items="userList" color="#FFBC15"
                    class="input-radius-search mt-3" label="ผู้อนุมัติสั่งแปรรูป" hide-details="auto" outlined
                    dense></v-select>
                </v-col>
              </v-row>
              <div class="ma-4 mb-n5">
                <b class="ml-3 black--text">ขั้นตอนการสั่งแปรรูปและผู้รับผิดชอบ</b>
              </div>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">สั่งแปรรูป</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select required :rules="[v => !!v || 'กรุณาเลือกสั่งแปรรูป']" item-text="name" item-value="id" :items="userList" v-model="user_order_process" color="#FFBC15"
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu2" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="order_process_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded required readonly
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="order_process_date" @input="menu2 = false" :min="minDate" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="order_process_detail" hide-details="auto" outlined rounded  color="#FFBC15"
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">สั่งปริ้นใบเตรียมวัตถุดิบ</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select required :rules="[v => !!v || 'กรุณาเลือกสั่งปริ้นใบเตรียมวัตถุดิบ']" item-text="name" item-value="id" v-model="user_print" :items="userList" color="#FFBC15"
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="print_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded required readonly
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="print_date" @input="menu3 = false" :min="minDate" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="print_detail" hide-details="auto" outlined rounded color="#FFBC15"
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">เตรียมวัตถุดิบในการผลิต</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select required :rules="[v => !!v || 'กรุณาเลือกเตรียมวัตถุดิบในการผลิต']" item-text="name" item-value="id" v-model="user_prepare_mat" :items="userList" color="#FFBC15"
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu4" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="prepare_mat_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded required readonly
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="prepare_mat_date" @input="menu4 = false" :min="minDate" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="prepare_mat_detail" hide-details="auto" outlined rounded color="#FFBC15"
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">แปรรูปวัตถุดิบ</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select required :rules="[v => !!v || 'กรุณาเลือกแปรรูปวัตถุดิบ']" item-text="name" item-value="id" v-model="user_process" :items="userList" color="#FFBC15"
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu5" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="process_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded required  readonly
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="process_date" @input="menu5 = false" :min="minDate" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="process_detail" hide-details="auto" outlined rounded color="#FFBC15"
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">QA Process</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select required :rules="[v => !!v || 'กรุณาเลือก QA Process']" item-text="name" item-value="id" v-model="user_qa_process" :items="userList" color="#FFBC15"
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu6" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="qa_process_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded required readonly
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="qa_process_date" @input="menu6 = false" :min="minDate" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="qa_process_detail" hide-details="auto" outlined rounded color="#FFBC15"
                      dense></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="ml-14 mr-6 mb-3" dark color="#a3a3a3"></v-divider>
              </section>
              <section>
                <v-row class="ma-4 ml-10">
                  <v-col xl="3" lg="3" style="align-self: center">
                    <b class="ml-3 black--text">QA Material</b>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-select required :rules="[v => !!v || 'กรุณาเลือก QA Material']" item-text="name" item-value="id" v-model="user_qa_material" :items="userList" color="#FFBC15"
                      class="input-radius-search" label="ผู้รับผิดชอบ" hide-details="auto" outlined dense></v-select>
                  </v-col>
                  <v-col xl="3" lg="3">
                    <v-menu v-model="menu7" :close-on-content-click="false" class="input-radius-search "
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="qa_material_date" label="วัน/เดือน/ปี" color="#FFBC15"
                          prepend-inner-icon="mdi-calendar" hide-details="auto"
                          :rules="[v => !!v || 'กรุณาเลือกวัน/เดือน/ปี']" v-bind="attrs" dense outlined rounded required readonly
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="qa_material_date" @input="menu7 = false" :min="minDate" no-title
                        scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="ma-4 mt-n3 ml-10">
                  <v-col xl="3" lg="3"></v-col>
                  <v-col xl="9" lg="9">
                    <v-text-field label="รายละเอียด" v-model="qa_material_detail" hide-details="auto" outlined rounded color="#FFBC15"
                      dense></v-text-field>
                  </v-col>
                </v-row>
              </section>
            </v-card-text>
          </v-card>
        </v-card-text>
        <div class="mt-3 text-center">
          <v-btn type="submit" class="white--text" color="#88B358" elevation="4" rounded large>
            <v-icon left>mdi-content-save</v-icon> บันทึก
          </v-btn>
        </div>
      </v-card>
       </v-form>
    </div>
  </div>
</template>

<script>
import meterial from '@/api/warehouse/materials.js'
import process from '@/api/process/meterial_process.js'
import Swal from "sweetalert2";
import { formatPrice } from "@/helper/handler";
export default {
  name: "ProcessingCreate",
  data() {
    return {
      loading_page: false,
      menu1: false,
      menu2: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      unit_list: [
        {
          id: 0,
          name: 'กรัม'
        },
        {
          id: 1,
          name: 'กิโลกรัม'
        },
      ],
      dataList: [],
      userList: [],
      data: [],
      unit_id: null,
      meterial_id: null,
      meterial_id_save: null,
      processing_date: null,
      user_approve: null,

      user_order_process: null,
      order_process_date: null,
      order_process_detail: null,

      user_print: null,
      print_date: null,
      print_detail: null,

      user_prepare_mat: null,
      prepare_mat_date: null,
      prepare_mat_detail: null,

      user_process: null,
      process_date: null,
      process_detail: null,

      user_qa_process: null,
      qa_process_date: null,
      qa_process_detail: null,

      user_qa_material: null,
      qa_material_date: null,
      qa_material_detail: null,

      //test
      test: {},
    }
  },
  methods: {
    async setDate(){
          this.loading_page = true
          this.order_process_date = await this.order_process_date == null ? this.processing_date : this.order_process_date
          this.print_date = await this.print_date == null ? this.processing_date : this.print_date
          this.prepare_mat_date = await this.prepare_mat_date == null ? this.processing_date : this.prepare_mat_date
          this.process_date = await this.process_date == null ? this.processing_date : this.process_date
          this.qa_process_date = await this.qa_process_date == null ? this.processing_date : this.qa_process_date
          this.qa_material_date = await this.qa_material_date == null ? this.processing_date : this.qa_material_date
          this.loading_page = false
    },
    formatPrice(number) {
      return formatPrice(number);
    },
    linkToSteps() {
      this.$router.push('/prm/processing_raw_materials')
    },
    link(link) {
      this.$router.push(link)
    },
   async getMeterialList() {
     await meterial.getMeterialList().then((response) => {
      if(response.status == 502){
        window.location.reload()
      }
        if (response.data.successful === true) {
          this.dataList = response.data.data;
        }
      })
    },
    async getUserList() {
     await meterial.getUserList().then((response) => {
        if (response.data.successful === true) {
          this.userList = response.data.data;
        }
      })
    },
    addProduct() {
      let check = this.dataList.find(o => o.id === this.meterial_id);
      if (check != null) {
        this.data.splice(0)
        this.data.push(
          {
            id: check.id,
            mat_name: check.mat_name,
            batch_qty: check.batch_qty,
            grams: 0
          }
        );
      }
      this.meterial_id_save = this.meterial_id
      this.$refs.dataList.reset()
    },
    async saveProduct() {
      if (this.$refs.form_add_step_one.validate()) {
        this.loading_page = true;
      let data = {
        processing_date: this.processing_date,
        production_id: null,
        weight_type: this.unit_id,
        material_id: this.meterial_id_save,

        desired_qty: this.unit_id == 1 ? (parseFloat(this.data[0].grams) * 1000)+'' : this.unit_id == 0 ? this.data[0].grams : 0,
        batch_qty: this.data[0].batch_qty,
        batch_count: this.unit_id == 0 ? parseFloat(this.data[0].grams) / parseFloat(this.data[0].batch_qty) : this.unit_id == 1 ? parseFloat(this.data[0].grams * 1000) / (parseFloat(this.data[0].batch_qty)) : 0,

        user_approve: this.user_approve,

        user_order_process: this.user_order_process,
        order_process_date: this.order_process_date,
        order_process_detail: this.order_process_detail,

        user_print: this.user_print,
        print_date: this.print_date,
        print_detail: this.print_detail,

        user_prepare_mat: this.user_prepare_mat,
        prepare_mat_date: this.prepare_mat_date,
        prepare_mat_detail: this.prepare_mat_detail,

        user_process: this.user_process,
        process_date: this.process_date,
        process_detail: this.process_detail,

        user_qa_process: this.user_qa_process,
        qa_process_date: this.qa_process_date,
        qa_process_detail: this.qa_process_detail,

        user_qa_material: this.user_qa_material,
        qa_material_date: this.qa_material_date,
        qa_material_detail: this.qa_material_detail
      }
      this.test = data;
      await process.add(data).then((response) => {
        if (response.data.successful === true) {
          let timerInterval;
          Swal.fire({
            title: "ทำรายการเสร็จสิ้น",
            icon: "success",
            html: "ระบบได้เพิ่มข้อมูลเรียบร้อยแล้ว.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.$router.push("/prm/processing_steps/"+response.data.process_id);
            }
          });
        }
      })
      }
    },
    removeProduct(index) {
      this.data.splice(index, 1);
    }
  },
  mounted() {
    this.getMeterialList();
    this.getUserList();
  }
}
</script>

<style scoped>
.border_right {
  border-right: 2px solid #000000;
  padding-right: 10px;
}

.topic_processing_header {
  font-size: 24px;
  color: #000000;
}

.topic_processing_content {
  color: #7E7E7E;
  padding-left: 10px;
}

.table-header-processing {
  display: grid;
  grid-template-columns: 10% 36% 20% 24% 10%;
}

.table-sub-header-processing {
  color: #686868;
  font-size: 14px;
  margin-left: 15px;
}

.grid-processing-items {
  display: grid;
  grid-template-columns: 27% 22% 15% 27% 10%;
  align-items: baseline;
}</style>