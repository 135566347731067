<template>
    <div>
        <ProductSizePage />
    </div>
</template>
  
<script>
import ProductSizePage from '@/components/stock/setting_stock/ProductSizeComponent'
export default {
    name: 'ProductSize',
    components: {
        ProductSizePage,
    }
}
</script>