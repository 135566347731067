<template>
    <div>
      <MaterialCreatePurchaseOrderAddPage/>
    </div>
  </template>
  
  <script>
  import MaterialCreatePurchaseOrderAddPage from '@/components/stock/material_import/MaterialCreatePurchaseOrderAddComponent'
  export default {
    name: 'materialcreatepurchaseorderadd',
    components:{
      MaterialCreatePurchaseOrderAddPage,
    }
  }
  </script>