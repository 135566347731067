<template>
    <div>
        <DashboardPage />
    </div>
</template>
  
<script>
import DashboardPage from '@/components/dashboard/DashboardComponent'
export default {
    name: 'Dashboard',
    components: {
        DashboardPage,
    }
}
</script>