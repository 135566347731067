<template>
    <div>
        <InspectionCriteriaProductionEditPage />
    </div>
</template>
  
<script>
import InspectionCriteriaProductionEditPage from '@/components/stock/setting_stock/InspectionCriteriaProductionEditComponent'
export default {
    name: 'InspectionCriteriaProductionEdit',
    components: {
        InspectionCriteriaProductionEditPage,
    }
}
</script>